import type { IKeyboardShortcut } from './IKeyboardShortcut'
import { keyCodeValues } from './e_keyCode'

export const getKeyCodeFromKey = (key: string) => {
	const mapping = Object.entries(keyCodeValues).find((entry) => entry[1] === key)

	return mapping ? mapping[0] : undefined
}

export const createKeyboardShortcutFromEvent = (
	key: string,
	ctrlOrMetaKey: boolean,
	shiftKey: boolean,
	altKey: boolean
): IKeyboardShortcut | undefined => {
	if (!key) {
		return undefined
	}

	const keyCode = keyCodeValues[key]

	if (!keyCode) {
		return undefined
	}

	return {
		keyCode: keyCode,
		altKey: altKey,
		shiftKey: shiftKey,
		ctrlOrMetaKey: ctrlOrMetaKey,
	}
}
