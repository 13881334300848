import jss from 'jss'
import preset from 'jss-preset-default'
import { cssVarDeclareFormat, cssVarNameFormat, cssVarUseFormat } from '../../theming/utils/cssVarStringUtils'
import type { CSSValues, CSSVarRef, InitializedTheme, ThemeDeclaration, ThemeVariation } from './themingManager.types'
import { getCSSVariablesFromTheme } from '../../theming/utils/getCSSVariablesFromTheme'

jss.setup(preset())

export let initializedTheme: InitializedTheme<ThemeDeclaration> | undefined
export const registeredCSSVars = new Map<string, CSSValues>()
export let themeDeclarationSheet: ReturnType<typeof jss.createStyleSheet>
export let themingConfig: ThemingConfig

/**
 * Declares theme structure and sets default values for all generated CSS variables
 *
 */
export const initTheming = <T extends ThemeDeclaration>(theme: T, config?: Partial<ThemingConfig>) => {
	if (initializedTheme) {
		throw Error('Theming should only be initialized once')
	}

	const keyPrefixLookup = new Map<ThemeDeclaration, string>()
	const themeValues: Record<string, CSSValues> = {}

	// CSS references resolver utils
	const cssVarRefs = new Set<CSSVarRef>()
	const refMapping = new Map<CSSVarRef, { target: ThemeDeclaration; key: string }>()
	const themeToProcessedThemeMapping = new Map<ThemeDeclaration, ThemeDeclaration>()

	// Set default theme variables & transform theme to reference CSS variables
	const transformThemeValuesToCSSVars = (theme: ThemeDeclaration, keyPrefix = '') => {
		const transformedTheme = Object.entries(theme).reduce((transformedTheme, [key, value]) => {
			const cssVarName = keyPrefix === '' ? cssVarNameFormat(key) : cssVarNameFormat(keyPrefix, key)
			if (typeof value === 'object') {
				transformedTheme[key] = transformThemeValuesToCSSVars(value, cssVarName)
			}
			// Store references CSS variable declaration
			else if (typeof value === 'function') {
				cssVarRefs.add(value)
				refMapping.set(value, { target: transformedTheme, key })
			} else {
				themeValues[cssVarDeclareFormat(cssVarName)] = value
				transformedTheme[key] = cssVarUseFormat(cssVarName)
				registeredCSSVars.set(cssVarDeclareFormat(cssVarName), String(value))
			}
			return transformedTheme
		}, {} as ThemeDeclaration)

		keyPrefixLookup.set(transformedTheme, keyPrefix)
		themeToProcessedThemeMapping.set(theme, transformedTheme)
		return transformedTheme
	}
	const transformedTheme = transformThemeValuesToCSSVars(theme) as InitializedTheme<T>

	const themeValueCSSVars: Record<string, CSSValues> = {}
	const resolveCSSVarRef = (varRef: CSSVarRef, stack: Set<CSSVarRef>) => {
		const ref = varRef()
		const transformedTarget = themeToProcessedThemeMapping.get(ref.target) // TODO error if not registered??
		if (!transformedTarget) {
			throw Error(`Referenced object for rule property "${ref.key}" does not exist in theme declaration`)
		}

		const refValue = transformedTarget[ref.key] as CSSVarRef | string | undefined
		const targetRef = refMapping.get(varRef)!
		const cssVarName = cssVarNameFormat(keyPrefixLookup.get(targetRef.target)!, targetRef.key)

		if (typeof refValue === 'function') {
			if (stack.has(refValue)) {
				throw Error('CSS variable references cannot have circular dependencies')
			}
			stack.add(refValue)
			targetRef.target[targetRef.key] = cssVarUseFormat(cssVarName, resolveCSSVarRef(refValue, stack))
			stack.delete(refValue)
		} else if (refValue !== undefined) {
			targetRef.target[targetRef.key] = cssVarUseFormat(cssVarName, refValue)
			themeValueCSSVars[cssVarDeclareFormat(cssVarName)] = refValue
			registeredCSSVars.set(cssVarDeclareFormat(cssVarName), refValue)
		} else {
			registeredCSSVars.set(cssVarDeclareFormat(cssVarName), undefined)
		}

		return targetRef.target[ref.key] as string
	}
	cssVarRefs.forEach((ref) => resolveCSSVarRef(ref, new Set()))

	const _config = ThemingConfig(config)
	const valuesToCommit = _config.commitReferencedValues ? { ...themeValues, ...themeValueCSSVars } : themeValues
	const defaultThemeValueStyleSheet = {
		'@global': {
			[_config.selector]: valuesToCommit,
		},
	}
	themeDeclarationSheet = jss.createStyleSheet(defaultThemeValueStyleSheet, { meta: _config.meta })
	themeDeclarationSheet.attach()

	initializedTheme = transformedTheme as InitializedTheme<ThemeDeclaration>
	themingConfig = _config
	return transformedTheme
}

/**
 * Initiate theme manager config
 *
 */
export const ThemingConfig = (config?: Partial<ThemingConfig>): ThemingConfig => ({
	selector: config?.selector || ':root',
	commitReferencedValues: config?.commitReferencedValues || false,
	meta: config?.meta || 'theme-declaration',
})
export type ThemingConfig = { selector: string; commitReferencedValues: boolean; meta: string }

/**
 * Overwrite initialized theme values / theme declaration
 *
 */
export const updateThemeDeclaration = <T extends ThemeVariation>(theme: T) => {
	if (!initializedTheme) {
		throw Error('Theming must be initialized before updating its declaration')
	}

	const updatedThemeValues = getCSSVariablesFromTheme(theme)
	registeredCSSVars.forEach((value, cssVar) => {
		if (cssVar in updatedThemeValues && updatedThemeValues[cssVar] !== value) {
			registeredCSSVars.set(cssVar, updatedThemeValues[cssVar])
		} else {
			updatedThemeValues[cssVar] = value
		}
	})

	const styleSheetDefinition = {
		'@global': {
			[themingConfig.selector]: updatedThemeValues,
		},
	}
	const newThemeDeclarationSheet = jss.createStyleSheet(styleSheetDefinition, { meta: themingConfig.meta })
	newThemeDeclarationSheet.attach()
	themeDeclarationSheet.detach()
	themeDeclarationSheet = newThemeDeclarationSheet
}
