import React from 'react'
import './theming'
import { createRoot } from 'react-dom/client'

// Import global CSS
import '@genusbiz/web-ui/theming/style.css'
import '@genusbiz/icon-set/dist/fonts'

import { OperatorFrontendRouter } from './features/OperatorFrontend/OperatorFrontendRouter'
import { Provider } from 'react-redux'

import { store } from './store'
import { operatorFrontendActions } from './features/OperatorFrontend/duck/operatorFrontendActions'
import { registerStore } from './utils/store'

import './utils/agGridLicense'

import { SharedContextMenuProvider } from '@genusbiz/web-ui/controls'

// Translations for web-ui
import { TranslationProvider as WebUITranslationProvider } from '@genusbiz/web-ui/translation'
import { translator } from './utils/locale'
import { createBrowserRouter } from 'react-router-dom'

registerStore(store)

export const router = createBrowserRouter([{ path: '*', Component: OperatorFrontendRouter }])

const App = () => (
	<Provider store={store}>
		<WebUITranslationProvider value={{ tcvi: translator.translate, tcvvi: translator.translate }}>
			<SharedContextMenuProvider>
				<OperatorFrontendRouter />
			</SharedContextMenuProvider>
		</WebUITranslationProvider>
	</Provider>
)

// Function that render root cam component and dispatches start action
const startApp = () => {
	const container = document.getElementById('app')
	const root = createRoot(container!)
	root.render(<App />)
	store.dispatch(operatorFrontendActions.start())
}

startApp()
