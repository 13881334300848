import React, { useCallback, useState } from 'react'
import type { LexicalEditor } from 'lexical'
import { $getSelection, $isNodeSelection, $isRangeSelection } from 'lexical'
import { Icon } from '../../Icon'
import { NumberInput } from '../../NumberInput'
import { ToggleButton } from '../../ToggleButton'
import { $isImageNode, e_ImageWidthMode } from '../nodes'
import { createStyle } from '../../../theming'
import { getSelectedNode } from '../utils'
import { useTranslation } from '../../../translation'
import clsx from 'clsx'

const classes = createStyle((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
	},
	percentageInput: {
		width: '42px',
	},
	linkIcon: {
		height: '24px',
		width: '30px',
		margin: 'auto',
	},
	linkButton: {
		padding: '0px',
		minWidth: '30px',
		borderRadius: theme.controls.button.borderRadius ?? theme.borderRadius,
		'&:focus-visible': `1px solid ${theme.colors.body.focusBorder}`,
		'&:focus': `1px solid ${theme.colors.body.focusBorder}`,
	},
	dimensionLabel: {
		marginRight: '4px',
	},
	disabled: {
		color: theme.colors.body.disabledText,
	},
}))

const updateImageSize = (
	editor: LexicalEditor,
	nextWidth: 'inherit' | number | null,
	nextHeight: 'inherit' | number | null
) => {
	editor.update(() => {
		const selection = $getSelection()
		if (!selection) {
			return false
		}
		if (!($isRangeSelection(selection) || $isNodeSelection(selection))) {
			return false
		}
		const node = getSelectedNode(selection)
		if (!$isImageNode(node)) {
			return false
		}

		if (nextWidth && nextHeight) {
			node.setWidthAndHeight(nextWidth, nextHeight)
		} else if (nextWidth) {
			node.setWidth(nextWidth)
		} else if (nextHeight) {
			node.setHeight(nextHeight)
		}
	})
}

const parseValueFromKeyEvent = (
	e: React.KeyboardEvent<HTMLInputElement>,
	parser: (string: string) => number,
	min?: number,
	max?: number
) => {
	if (e.key !== 'Enter') {
		return
	}
	let newWidth = parser((e.target as HTMLInputElement).value)
	if (typeof newWidth !== 'number' || Number.isNaN(newWidth)) {
		return
	}
	if (min !== undefined) {
		newWidth = Math.max(newWidth, min)
	}
	if (max !== undefined) {
		newWidth = Math.min(newWidth, max)
	}
	return newWidth
}

export function useImageSizeRenderFn(
	editor: LexicalEditor,
	width: number | 'inherit',
	height: number | 'inherit',
	imageWidthMode: e_ImageWidthMode,
	isDisabled: boolean
) {
	const [isLinkedResize, setIsLinkedResize] = useState(true)

	const { tcvi } = useTranslation()

	return useCallback(() => {
		const updateWidth = (newWidth: number | null) => {
			if (width === 'inherit' || typeof newWidth !== 'number') {
				return
			}
			const newHeight = getLinkedLength(isLinkedResize, newWidth, width, height)
			updateImageSize(editor, newWidth, newHeight)
		}
		const updateHeight = (newHeight: number | null) => {
			if (height === 'inherit' || typeof newHeight !== 'number') {
				return
			}
			const newWidth = getLinkedLength(isLinkedResize, newHeight, height, width)
			updateImageSize(editor, newWidth, newHeight)
		}
		const minWidth = 0
		const maxWidth = imageWidthMode === 'percent' ? 100 : undefined

		return (
			<span className={classes.container}>
				<span className={clsx(classes.dimensionLabel, { [classes.disabled]: isDisabled })}>
					{tcvi('GENERAL:WIDTH_SHORT')}:
				</span>
				{width === 'inherit' ? null : (
					<NumberInput
						value={parseFloat(width.toPrecision(3))}
						type={imageWidthMode === 'percent' ? 'float' : 'int'}
						className={classes.percentageInput}
						onBlur={updateWidth}
						onKeyDown={(e) => {
							const newWidth = parseValueFromKeyEvent(e, parseFloat, minWidth, maxWidth)
							if (newWidth !== undefined) {
								updateWidth(newWidth)
							}
						}}
						min={0}
						max={imageWidthMode === 'percent' ? 100 : undefined}
						disabled={isDisabled}
					/>
				)}
				{imageWidthMode === e_ImageWidthMode.px && (
					<>
						<ToggleButton
							isActive={isLinkedResize}
							onClick={function () {
								setIsLinkedResize(!isLinkedResize)
							}}
							className={classes.linkButton}
							disableBorderRight
							disabled={isDisabled}
						>
							<Icon iconClassName="Fluent-MiniLink" className={classes.linkIcon} />
						</ToggleButton>
						{height === 'inherit' ? null : (
							<>
								<span className={clsx(classes.dimensionLabel, { [classes.disabled]: isDisabled })}>
									{tcvi('GENERAL:HEIGHT_SHORT')}:
								</span>
								<NumberInput
									value={Math.round(height)}
									type="int"
									className={classes.percentageInput}
									onBlur={updateHeight}
									onKeyDown={(e) => {
										const newHeight = parseValueFromKeyEvent(e, parseInt)
										if (newHeight !== undefined) {
											updateHeight(newHeight)
										}
									}}
									disabled={isDisabled}
								/>
							</>
						)}
					</>
				)}
			</span>
		)
	}, [
		classes.container,
		classes.dimensionLabel,
		classes.linkButton,
		classes.linkIcon,
		classes.percentageInput,
		editor,
		height,
		imageWidthMode,
		isDisabled,
		isLinkedResize,
		tcvi,
		width,
	])
}
function getLinkedLength(
	isLinkedResize: boolean,
	newSourceLength: 'inherit' | number,
	originalSourceLength: number,
	originalLinkedValueLength: 'inherit' | number
) {
	return isLinkedResize && newSourceLength !== 'inherit' && originalLinkedValueLength !== 'inherit'
		? (newSourceLength / originalSourceLength) * originalLinkedValueLength
		: null
}
