import { Dialog } from '@genusbiz/web-ui/controls/Dialog'

import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { e_ConfirmationButtons } from 'src/enums/e_ButtonSet'
import { modalManagerActions } from './duck'
import { modalTypes } from './modalTypes'

export let ConfirmationModal = (props) => {
	// handleConfirm - close modal and execute onConfirm if confirmed

	const handleConfirm = (buttonType) => () => {
		if (props.hideModal) {
			props.hideModal(modalTypes.CONFIRMATION)
		}

		if (buttonType === e_ConfirmationButtons.yes) {
			props.onConfirm()
		} else if (buttonType === e_ConfirmationButtons.no) {
			props.decline()
		} else {
			props.cancel()
		}
	}

	const buttonDescriptions = [
		{
			title: props.confirmText,
			onClick: handleConfirm(e_ConfirmationButtons.yes),
			isEnabled: !props.disableConfirmButton,
			isDefault: true,
		},
	]

	if (props.declineText) {
		buttonDescriptions.push({
			title: props.declineText,
			onClick: handleConfirm(e_ConfirmationButtons.no),
			isEnabled: true,
			isDefault: false,
		})
	}

	if (props.cancelText || (!props.declineText && !props.cancelText)) {
		buttonDescriptions.push({
			title: props.cancelText,
			onClick: handleConfirm(e_ConfirmationButtons.cancel),
			isEnabled: true,
			isCancel: true,
			isDefault: false,
		})
	}

	return (
		<Dialog
			isOpen
			width={600}
			title={props.title}
			buttonDescriptions={buttonDescriptions}
			disableCancelOnClickOutside
			disableCancelOnEscape
		>
			<div style={{ whiteSpace: 'pre-wrap' }}>{props.message}</div>
		</Dialog>
	)
}

ConfirmationModal.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string.isRequired,
	onConfirm: PropTypes.func,
	cancel: PropTypes.func,
	decline: PropTypes.func,
	declineText: PropTypes.string,
	confirmText: PropTypes.string,
	focus: PropTypes.string,
	cancelText: PropTypes.string,
	hideModal: PropTypes.func,
}

const mapDispatchToProps = (dispatch, props) => ({
	onConfirm:
		props.onConfirm ||
		function () {
			return dispatch(modalManagerActions.confirm())
		},
	cancel:
		props.cancel ||
		function () {
			dispatch(modalManagerActions.cancel())
		},
	decline:
		props.decline ||
		function () {
			dispatch(modalManagerActions.decline())
		},
})

ConfirmationModal = connect(null, mapDispatchToProps)(ConfirmationModal)
