import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import { e_SubTextVariant } from '../../enums/e_SubTextVariant'
import { e_SubTextType } from '../../enums/e_SubTextType'
import { useStyleContext } from '../utils/Style.context'

const classes = createStyle((theme) => ({
	subText: {
		color: theme.controls.input.subText.colors.color,
		fontSize: theme.typography.styles.caption.fontSize,
		marginTop: theme.controls.input.subText.spacing,
	},
	subTextBox: {
		color: theme.controls.input.subText.colors.color,
		background: theme.controls.input.subText.colors.background,
		borderColor: theme.controls.input.subText.colors.border,
		borderStyle: 'solid',
		borderWidth: theme.controls.input.subText.borderWidth,
		borderRadius: theme.controls.input.borderRadius,
		boxShadow: theme.controls.input.dropShadow,
		padding: theme.controls.input.subText.padding,
		marginTop: theme.controls.input.subText.spacing,
	},

	subTextAbove: {
		marginBottom: theme.controls.input.subText.spacing,
		marginTop: 'unset',
	},

	errorSubTextBox: {
		color: theme.controls.input.subText.errorColors.color,
		background: theme.controls.input.subText.errorColors.background,
		borderColor: theme.controls.input.subText.errorColors.border,
	},
	warningSubTextBox: {
		color: theme.controls.input.subText.warningColors.color,
		background: theme.controls.input.subText.warningColors.background,
		borderColor: theme.controls.input.subText.warningColors.border,
	},

	error: {
		color: theme.controls.input.subText.errorColors.color,
	},
	warning: {
		color: theme.controls.input.subText.warningColors.color,
	},
}))

interface IFormControlHelperTextProps {
	id?: string
	subText?: string
	warning?: boolean
	error?: boolean
	type?: e_SubTextType
}

export const FormControlSubText = (props: IFormControlHelperTextProps) => {
	const subTextVariant = useStyleContext().style?.input?.subTextVariant

	const subTextClassName = classNames({
		[classes.subText]: subTextVariant === e_SubTextVariant.inline,
		[classes.subTextBox]: subTextVariant === e_SubTextVariant.box,
		[classes.subTextAbove]: props.type === e_SubTextType.validationTop,
		[classes.warning]: props.warning && !props.error,
		[classes.error]: props.error,
		[classes.warningSubTextBox]: subTextVariant === e_SubTextVariant.box && props.warning && !props.error,
		[classes.errorSubTextBox]: subTextVariant === e_SubTextVariant.box && props.error,
	})

	const subText = props.subText || '\xA0'

	return (
		<div
			id={props.id ? props.id + '-' + (props.type || e_SubTextType.subtext) : undefined}
			className={subTextClassName}
			aria-live={'polite'}
			role={props.warning || props.error ? 'alert' : undefined}
		>
			{subText}
		</div>
	)
}
