import React, { type CSSProperties } from 'react'
import type { Value } from '../Table.types'
import { createStyle } from '../../../theming'

const classes = createStyle((theme) => ({
	// Custom checkbox
	checkbox: {
		display: 'flex',
		position: 'relative',
		height: '100%',
		flexShrink: 0,
		alignItems: 'center',
		justifyContent: 'center',
		border: '1px solid transparent',
		outline: 'none',
		'&:focus': { border: '1px solid ' + theme.colors.body.focusBorder },
		'&[aria-disabled="true"]': { cursor: 'default' },
		'&[aria-disabled="false"]': { cursor: 'pointer' },
	},
	check: {
		width: 18,
		height: 18,
		lineHeight: 1,
		verticalAlign: 'top',
		position: 'relative',
		userSelect: 'none',
		'&::before': {
			content: "''",
			position: 'absolute',
			top: 1,
			right: 1,
			bottom: 1,
			left: 1,
			opacity: 0,
			borderRadius: '50%',
			background: theme.palette.primary.themePrimary,
			'$checkbox[aria-checked="true"] &, $checkbox[aria-checked="mixed"] &': {
				opacity: 1,
			},
			'$checkbox[aria-disabled="true"]&': {
				background: theme.colors.input.disabledBackground,
			},
			'$checkbox[aria-disabled="true"][aria-checked="true"] &, $checkbox[aria-disabled="true"][aria-checked="true"] &':
				{
					background: theme.colors.input.disabledText,
				},
		},
	},
	checkMark: {
		fontSize: 16,
		position: 'absolute',
		left: 0.5,
		top: 0,
		width: 18,
		height: 18,
		textAlign: 'center',
		verticalAlign: 'middle',
		opacity: 0,
		color: theme.palette.background.white,
		'$checkbox[aria-disabled="false"][aria-checked="true"] &': {
			opacity: 1,
			color: theme.palette.background.white + ' !important',
		},
		'$checkbox[aria-disabled="true"][aria-checked="true"] &': {
			opacity: 1,
			color: theme.colors.input.disabledBackground,
		},
		'$checkbox:hover[aria-disabled="false"][aria-checked="false"] &': {
			opacity: 1,
			color: theme.colors.list.text,
		},
	},
	circle: {
		display: 'inline-block',
		fontSize: 18,
		left: 0,
		top: 0,
		width: 18,
		height: 18,
		textAlign: 'center',
		verticalAlign: 'middle',
		color: theme.colors.body.focusBorder,
		'$checkbox[aria-disabled="false"][aria-checked="true"] &, $checkbox[aria-disabled="false"][aria-checked="mixed"] &':
			{
				color: theme.palette.background.white,
			},
		'$checkbox[disabled] &': {
			color: theme.colors.input.disabledText,
		},
		'$checkbox[aria-disabled="true"][aria-checked="true"] &, $checkbox[aria-disabled="true"][aria-checked="mixed"] &': {
			color: theme.colors.input.disabledBackground,
		},
	},
	mixedMark: {
		fontSize: 17,
		position: 'absolute',
		left: 0.2,
		top: 0,
		width: 18,
		height: 18,
		textAlign: 'center',
		verticalAlign: 'middle',
		opacity: 0,
		color: theme.palette.background.white,
		'$checkbox[aria-disabled="false"][aria-checked="mixed"] &': {
			opacity: 1,
			color: theme.palette.background.white + ' !important',
		},
		'$checkbox[aria-disabled="true"][aria-checked="mixed"] &': {
			opacity: 1,
			color: theme.colors.input.disabledBackground,
		},
	},
}))

interface ISelectionCheckMarkCircle {
	value: Value | 'mixed'
	style?: CSSProperties
	disableSelection?: boolean
	onClick?: (event: { originalEvent: React.KeyboardEvent | React.MouseEvent }) => void
}

export const SelectionCheckMarkCircle = (props: ISelectionCheckMarkCircle) => {
	const selected = props.value === 'mixed' ? props.value : !!props.value
	const onClick = (e: React.MouseEvent) => {
		props.onClick?.({ originalEvent: e })
	}
	return (
		<div
			role="checkbox"
			aria-checked={selected}
			aria-disabled={props.disableSelection ?? false}
			className={classes.checkbox}
			style={props.style}
			onClick={!props.disableSelection ? onClick : undefined}
			onDoubleClick={
				!props.disableSelection
					? (e) => {
							e.preventDefault()
							e.stopPropagation()
					  }
					: undefined
			}
		>
			<div className={classes?.check}>
				<i aria-hidden="true" className={classes.checkMark + ' Fluent-StatusCircleCheckmark'} />
				<i aria-hidden="true" className={classes.mixedMark + ' Fluent-StatusCircleBlock2'} />
				<i aria-hidden="true" className={classes.circle + ' Fluent-CircleRing'} />
			</div>
		</div>
	)
}
