import type { AgGridReact } from '@ag-grid-community/react'
import { useCallback, useEffect } from 'react'
import { SCROLL_BAR_SIZE } from '../consts'
import type { TData } from '../Table.types'

export const useSetTableWidth = (
	autoWidth: boolean,
	wrapperRef: React.RefObject<HTMLDivElement>,
	gridRef: React.RefObject<AgGridReact<TData>>
) => {
	const setTableWidth = useCallback(() => {
		if (autoWidth) {
			if (!wrapperRef.current || !gridRef.current?.api) {
				return
			}

			const totalWidth = gridRef.current.api.getColumnState().reduce((total, column) => {
				return total + (column.width || 0)
			}, SCROLL_BAR_SIZE)

			wrapperRef.current.style.width = totalWidth.toString() + 'px'
		}
	}, [autoWidth, gridRef, wrapperRef])

	useEffect(() => {
		if (autoWidth) {
			// Wrap in timeout to ensure that gridRef is initialized
			window.setTimeout(() => {
				setTableWidth()
			})
		} else if (wrapperRef.current) {
			wrapperRef.current.style.width = 'unset'
		}
	}, [autoWidth, setTableWidth, wrapperRef])

	return useCallback(setTableWidth, [setTableWidth])
}
