import type { IKeyboardShortcut } from '../utils/keyboard/IKeyboardShortcut'
import { getKeyboardShortcutText } from '../utils/keyboard/getKeyboardShortcutText'

export const generateMenuScreenTip = (
	screenTip: string | undefined,
	keyboardShortcut: IKeyboardShortcut | undefined
) => {
	const screenTipItems: string[] = []
	if (screenTip) {
		screenTipItems.push(screenTip)
	}

	if (keyboardShortcut) {
		const shortcutText = getKeyboardShortcutText(keyboardShortcut)

		if (shortcutText) {
			screenTipItems.push(shortcutText)
		}
	}

	return screenTipItems.join('\n')
}
