import { e_OperatorApiService } from '../types/e_OperatorApiService'

/**
 * Decide the base URLs, ie. endpoints, for each Operator API service given a mode
 * @returns  object with a base URL for each Genus API service
 */
export const getBaseURLs = () => {
	return {
		[e_OperatorApiService.probe]: '/api/up/',
		[e_OperatorApiService.config]: '/api/config',
		[e_OperatorApiService.metaModelManagerEnvVariables]: '/api/m3/config',
		[e_OperatorApiService.k8sRuntime]: '/api/k8sruntime/',
		[e_OperatorApiService.workload]: '/api/workloads/',
		[e_OperatorApiService.authentication]: '/api/authentication',
		[e_OperatorApiService.account]: '/api/account',
		[e_OperatorApiService.endpoint]: '/api/endpoint/',
		[e_OperatorApiService.dataPartition]: '/api/datapartition/',
		[e_OperatorApiService.controlLoopReport]: '/api/controlloopreport',
		[e_OperatorApiService.environmentAvailability]: '/api/environmentavailability',
		[e_OperatorApiService.runtimeReporting]: '/api/runtimereporting',
		[e_OperatorApiService.resourceOverview]: '/api/resourceoverview',
		[e_OperatorApiService.workloadsResourcePlan]: '/api/workloadsResourcePlan',
		[e_OperatorApiService.kubernetesInfo]: '/api/kubernetesinfo',
	}
}
