export const transitions = {
	easing: {
		easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
		easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
		easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
		rotate: 'cubic-bezier(0.53, 0.21, 0.29, 0.67)',
		slide: 'cubic-bezier(0.1, 0.9, 0.2, 1)',
		fade: 'cubic-bezier(0.1, 0.25, 0.75, 0.9)',
	},
	duration: {
		veryShort: '80ms',
		shorter: '167ms',
		short: '267ms',
		standard: '376ms',
		long: '467ms',
		veryLong: '950ms',
	},
}
