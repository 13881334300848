import { getClusterDiagramLinkType } from 'src/enums/e_ClusterDiagramLinkType'
import { getClusterDiagramNodeType, e_ClusterDiagramNodeType } from 'src/enums/e_ClusterDiagramNodeType'
import { ID3ClusterDiagramNode, ID3ClusterDiagramGraph, IClusterDiagramNode } from 'src/interfaces/IClusterDiagram'
import { IPod } from 'src/interfaces/IPod'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'

export const clusterDiagramDataToD3Format = (
	nodes: IClusterDiagramNode[],
	pods: IPod[],
	workloadControllers: IWorkloadController[],
	k8sRuntimeName: string
): ID3ClusterDiagramGraph => {
	// Mapping from neighbour list to nodes
	const d3Nodes = nodes.map((node) =>
		nodeToD3Node(node, workloadControllers, pods, k8sRuntimeName /* , selectedScaleableResource, selectedPods */)
	)

	// Mapping from neighbour list to relational edges
	const d3Edges = nodes
		.flatMap((node) => nodeToD3Edges(node, d3Nodes))
		.filter((edge) => edge.source !== undefined && edge.target !== undefined)

	return { d3Nodes, d3Edges }
}

function nodeToD3Node(
	node: IClusterDiagramNode,
	scaleableResources: IWorkloadController[],
	pods: IPod[],
	k8sRuntimeName: string
): ID3ClusterDiagramNode {
	const nodeType = getClusterDiagramNodeType(node.nodeType)!
	if (!nodeType) {
		console.error('Received unknown node type') // eslint-disable-line no-console
	}

	const podsInNode = pods.filter((pod) => node.podIds.includes(pod.id))
	const nodeChildren = podsInNode.map((pod) => ({
		podStatus: 1,
		r: 18,
		xOffset: 0,
		yOffset: 0,
		pod: pod,
	}))

	const isNotNamespace =
		nodeType === e_ClusterDiagramNodeType.deployment || nodeType === e_ClusterDiagramNodeType.statefulSet
	const resource = scaleableResources.find((dep) => dep.id === node.id)!
	const workload = isNotNamespace ? resource : null

	return {
		id: node.id,
		deploymentName: node.nodeName.replace(k8sRuntimeName ? `${k8sRuntimeName}-` : '', ''),
		nodeType: nodeType,
		children: nodeChildren,
		r: nodeType === e_ClusterDiagramNodeType.namespace ? 40 : 15,
		workload,
	}
}

function nodeToD3Edges(node: IClusterDiagramNode, nodes: ID3ClusterDiagramNode[]) {
	return node.neighbors.map((neighbor) => {
		const edgeType = getClusterDiagramLinkType(neighbor.edgeType)!
		if (!edgeType) {
			console.error('Received unknown edge type') // eslint-disable-line no-console
		}

		const target = nodes.find((n) => neighbor.to === n.id)!
		if (!target) {
			console.error('Received edge with unknown target') // eslint-disable-line no-console
		}

		const source = nodes.find((n) => node.id === n.id)!
		if (!edgeType) {
			console.error('Received edge with unknown source') // eslint-disable-line no-console
		}

		const nodeTargetType = target.nodeType
		if (!nodeTargetType) {
			console.error('Received node type with unknown target') // eslint-disable-line no-console
		}

		return {
			source: source,
			target: target,
			edgeType: edgeType,
			nodeTargetType: nodeTargetType,
		}
	})
}
