import React, { useRef } from 'react'
import { createStyle } from '../../../../../theming'
import { e_LabelContentLayout } from '../../../../FormControl'
import { Link } from '../../../../Link'
import { CELL_PADDING } from '../../../consts'

const classes = createStyle({
	viewer: {
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
	},
	content: {
		height: '100%',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		padding: `0px ${CELL_PADDING}px`,
	},
})

interface ICellRendererViewerViewer {
	value: string | null | undefined
	onClick?: (targetEl: React.RefObject<HTMLElement>) => void
}

export const CellRendererViewer = (props: ICellRendererViewerViewer) => {
	const ref = useRef<HTMLAnchorElement>(null)

	if (props.onClick) {
		return (
			<Link
				ref={ref}
				url={'#'}
				displayName={props.value ?? ''}
				contentLayout={e_LabelContentLayout.text}
				onActivate={() => props.onClick?.(ref)}
			/>
		)
	}

	return (
		<span className={classes.viewer}>
			<span className={classes.content}>{props.value}</span>
		</span>
	)
}
