import type { SerializedCodeNode } from '@lexical/code'
import { CodeNode } from '@lexical/code'
import type { DOMExportOutput, LexicalEditor, LexicalNode } from 'lexical'
import { $isLineBreakNode } from 'lexical'
import { addClassNamesToElement } from '@lexical/utils'

export class CustomCodeNode extends CodeNode {
	static getType(): string {
		return 'custom-code'
	}
	static clone(node: CustomCodeNode) {
		return new CustomCodeNode(node.__language, node.__key)
	}

	exportJSON(): SerializedCodeNode {
		return {
			...super.exportJSON(),
			type: 'custom-code',
		}
	}

	// @ts-ignore
	exportDOM(editor: LexicalEditor): DOMExportOutput {
		const { element } = super.exportDOM()
		if (element instanceof HTMLElement) {
			addClassNamesToElement(element, editor._config.theme.code)
		}
		addGutter(this, element)
		return { element }
	}
}

export function $isCustomCodeNode(node: LexicalNode | null | undefined): node is CustomCodeNode {
	return node instanceof CustomCodeNode
}

function addGutter(node: CustomCodeNode, codeElement: HTMLElement | Text | null): void {
	if (codeElement === null || codeElement instanceof Text) {
		return
	}
	const children = node.getChildren()
	const childrenLength = children.length
	let gutter = '1'
	let count = 1
	for (let i = 0; i < childrenLength; i++) {
		if ($isLineBreakNode(children[i])) {
			gutter += '\n' + ++count
		}
	}
	codeElement.setAttribute('data-gutter', gutter)
}
