export const extractNumericValueWithPixelUnit = (pixelString: string) => {
	// Use a regular expression to match the numeric value with "px" unit
	const match = /^(\d+(\.\d+)?)px$/.exec(pixelString)

	// If a match is found, return the numeric value as a number
	if (match) {
		return parseFloat(match[1])
	}

	// If no match is found, return null or handle the case as needed
	return -1
}
