import { createStyle } from '@genusbiz/web-ui/theming'
import React, { useState } from 'react'
import { Dialog, TabControlHeader, TabControlHeaderItem } from '@genusbiz/web-ui/controls'
import { useTranslation } from 'react-i18next'
import { IPod } from 'src/interfaces/IPod'
import { PodDescription } from './PodDescription'
import { PodEvents } from './PodEvents'
import { PodLog } from './PodLog'
import { e_PodPhaseName } from 'src/enums/e_PodPhase'

const classes = createStyle({
	dialog: {
		height: '90%',
		width: '90%',
	},
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		height: '100%',
		backgroundColor: 'white',
	},
	topContent: {
		padding: '4px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	podContainerSelector: {
		width: '300px',
		marginTop: '12px',
	},
})

interface IProps {
	isOpen: boolean
	onClose: () => void
	pod: IPod | undefined
}

enum e_PodDetailsContent {
	description = 'description',
	logs = 'logs',
	events = 'events',
}

export const PodDetails = (props: IProps) => {
	const { t } = useTranslation()
	const [detailsContent, setDetailsContent] = useState<e_PodDetailsContent>(e_PodDetailsContent.logs)

	const displayLogsEnabled = () => {
		if (props.pod) {
			if (
				props.pod.status.phaseName === e_PodPhaseName.running ||
				props.pod.status.phaseName === e_PodPhaseName.succeeded ||
				props.pod.status.phaseName === e_PodPhaseName.failed
			) {
				return true
			}
			return false
		}
	}

	return (
		<>
			{props.pod !== undefined && (
				<Dialog
					isOpen={props.isOpen}
					modalClassName={classes.dialog}
					title={props.pod.name}
					handleClose={props.onClose}
				>
					<TabControlHeader>
						<TabControlHeaderItem
							text={t('GENERAL:DESCRIPTION')}
							onActivate={() => {
								setDetailsContent(e_PodDetailsContent.description)
							}}
							isActive={detailsContent === e_PodDetailsContent.description}
						/>
						<TabControlHeaderItem
							text={t('GENERAL:LOGS')}
							onActivate={() => {
								setDetailsContent(e_PodDetailsContent.logs)
							}}
							isActive={detailsContent === e_PodDetailsContent.logs}
							disabled={!displayLogsEnabled}
						/>
						<TabControlHeaderItem
							text={t('GENERAL:EVENTS')}
							onActivate={() => {
								setDetailsContent(e_PodDetailsContent.events)
							}}
							isActive={detailsContent === e_PodDetailsContent.events}
						/>
					</TabControlHeader>
					{detailsContent === e_PodDetailsContent.description && <PodDescription pod={props.pod} />}
					{detailsContent === e_PodDetailsContent.logs && <PodLog pod={props.pod} />}
					{detailsContent === e_PodDetailsContent.events && <PodEvents pod={props.pod} />}
				</Dialog>
			)}
		</>
	)
}
