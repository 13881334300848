import type { IIconProps } from '../Icon'

export enum e_ListNode {
	item = 'item',
	section = 'section',
}

interface IListNodeBase {
	id?: string
	type: e_ListNode
	disabled?: boolean
}

interface IListItemBase extends IListNodeBase {
	id: string
	type: e_ListNode.item
	header?: string
	caption?: string
	captionTrail?: string
	subcaption?: string
	text?: string
	footer?: string
	screenTip?: string
	leftSlot?: IItemSlot
	rightSlot?: IItemSlot
}
export type IListItem<T = never> = IListItemBase & ([T] extends [never] ? { data?: any } : { data: T })

export interface IListSection<T = never> extends IListNodeBase {
	type: e_ListNode.section
	caption?: string
	children: IListItem<T>[]
}

export type IItemSlot =
	| string
	| number
	| boolean
	| null
	| undefined
	| ({ type: 'icon' } & IIconProps)
	| {
			type: 'avatar'
			imageUrl?: string
			initials?: string
			style?: React.CSSProperties
			className?: string
			size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge'
	  }
	| ((item: IListNode) => JSX.Element)

export type IListNode<T = never> = IListItem<T> | IListSection<T>
