import { type IDetailItemSurfaceProps } from '../../MasterDetail/types/IDetailItemSurfaceProps'
import type {
	IEditableDetailComponentProps,
	IEditableDetailComponent,
	EditableDetailMode,
	IEditableEntry,
} from '../types/detailConfigTypes'
import { mapObjectValues } from './mapObjectValues'

const presetDetailComponentProps = <T>(
	Component: (props: T & IDetailItemSurfaceProps) => React.JSX.Element,
	curriedProps: T,
	displayName?: string
) => {
	const baseComponent = (props: IDetailItemSurfaceProps) => Component({ ...props, ...curriedProps })
	baseComponent.displayName = displayName ?? (Component as React.FunctionComponent).displayName ?? Component.name
	return baseComponent
}

export const presetAdditionalDetailSurfaceProps = <T>(
	Component: (props: T & IEditableDetailComponentProps) => React.JSX.Element,
	additionalProps: T,
	displayName?: string
) => {
	const baseComponent = (props: IEditableDetailComponentProps) => Component({ ...props, ...additionalProps })
	baseComponent.displayName = displayName ?? (Component as React.FunctionComponent).displayName ?? Component.name
	return baseComponent
}

export const addUpdateChangesToComponents = (
	updateCurryComponentGetters: { [mode in EditableDetailMode]: IEditableDetailComponent },
	updateLocalChanges: (value: IEditableEntry) => void
) => {
	const addUpdateEdit = (editableComponent: IEditableDetailComponent) =>
		presetDetailComponentProps(editableComponent, { updateLocalChanges })

	const components = mapObjectValues(updateCurryComponentGetters, addUpdateEdit)
	return components
}
