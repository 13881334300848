import React, { useRef, useEffect, useState } from 'react'

import { Button } from '../../controls/Button'
import { Icon } from '../../controls/Icon'
import classNames from 'clsx'
import { createStyle } from '../../theming'

const TRANSITIONTIMEOUT = 300

const classes = createStyle((theme) => ({
	content: {
		flex: 1,
		marginRight: 8,
	},
	snackbarContent: {
		display: 'flex',
		flexDirection: 'row',
		padding: 20,
		minWidth: 275,
		alignItems: 'center',
	},
	snackbar: {
		border: '1px solid ' + theme.colors.notificationPopup.background,
		background: theme.colors.notificationPopup.background,
		boxShadow: theme.shadows.light + ' !important',
		color: theme.colors.notificationPopup.text,
		padding: 8,
		opacity: 0,
		transform: 'translateX(200%)',
		transition: `opacity ${TRANSITIONTIMEOUT}ms ease-in-out, transform ${TRANSITIONTIMEOUT}ms ease-in`,
	},

	snackbarVisible: {
		opacity: 1,
		transform: 'translateX(0%)',
	},

	messageAlignment: { display: 'flex', alignItems: 'center' },
	textAlignment: { marginLeft: '10px' },
}))

interface ISnackbarProps {
	onClose: () => void
	iconClassName?: string
	message?: string
	showCloseButton?: boolean
	autoHideDuration?: number // the dialog will close after the specified amount of time (in milliseconds)
}
enum TransitionStage {
	initial = 'initial',
	initShowing = 'initShowing',
	showing = 'showing',
	visible = 'visible',
	hiding = 'hiding',
	hidden = 'hidden',
}

export const Snackbar = (props: ISnackbarProps) => {
	const closeTimer = useRef<NodeJS.Timeout>()

	const [transitionStage, setTransitionStage] = useState(TransitionStage.initial)

	useEffect(() => {
		if (closeTimer.current) {
			clearInterval(closeTimer.current)
		}

		if (!props.showCloseButton) {
			closeTimer.current = setInterval(handleClose, props.autoHideDuration || 3000)
		}
	}, [props.autoHideDuration, props.showCloseButton])

	useEffect(() => {
		switch (transitionStage) {
			case TransitionStage.initial: {
				setTransitionStage(TransitionStage.initShowing)
				break
			}
			case TransitionStage.initShowing: {
				setTransitionStage(TransitionStage.showing)

				setTimeout(() => {
					setTransitionStage(TransitionStage.visible)
				}, TRANSITIONTIMEOUT + 100)

				break
			}

			case TransitionStage.hiding: {
				setTimeout(() => {
					setTransitionStage(TransitionStage.hidden)
				}, TRANSITIONTIMEOUT + 100)

				break
			}
			case TransitionStage.hidden: {
				setTimeout(() => {
					props.onClose()
				}, 0)

				break
			}
		}
	}, [props, transitionStage])

	const handleClose = () => {
		if (closeTimer.current) {
			clearInterval(closeTimer.current)
		}
		setTransitionStage(TransitionStage.hiding)
	}

	const content = props.iconClassName ? (
		<div className={classes.messageAlignment}>
			<Icon iconClassName={props.iconClassName} /> <div className={classes.textAlignment}>{props.message}</div>{' '}
		</div>
	) : (
		props.message
	)

	const contentDelayed = useRef<JSX.Element | string>()

	useEffect(() => {
		//The ref is set in a use effect because screen readers need the content element to be empty on first render to announce change
		contentDelayed.current = content
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (transitionStage === TransitionStage.initial || transitionStage === TransitionStage.hidden) {
		return null
	}

	return (
		<div
			className={classNames(
				classes.snackbar,
				transitionStage === TransitionStage.showing ||
					(transitionStage === TransitionStage.visible && classes.snackbarVisible)
			)}
		>
			<div className={classes.snackbarContent}>
				<div className={classes.content} aria-live="polite" role={'alert'}>
					{contentDelayed.current}
				</div>
				{props.showCloseButton ? <Button icon="Fluent-ChromeClose" variant="inline" onClick={handleClose} /> : <div />}
			</div>
		</div>
	)
}
