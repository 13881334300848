import type React from 'react'
import { useEffect } from 'react'

export const useFocusSelected = (isSelected: boolean, menuItemRef: React.RefObject<HTMLElement>) => {
	useEffect(() => {
		if (isSelected && menuItemRef.current) {
			menuItemRef.current.focus()
		}
	}, [isSelected, menuItemRef])
}
