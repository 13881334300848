import type React from 'react'
import { useRef } from 'react'

export function useForwardedRef<T>(inheritedRef?: React.MutableRefObject<T>): React.MutableRefObject<T>
export function useForwardedRef<T>(inheritedRef?: React.Ref<T>): React.RefObject<T>
export function useForwardedRef<T>(
	inheritedRef?: React.Ref<T> | React.MutableRefObject<T>
): typeof inheritedRef extends React.MutableRefObject<T> ? React.MutableRefObject<T> : React.RefObject<T> {
	const ref = useRef<T>(null)

	if (inheritedRef && Object.prototype.hasOwnProperty.call(inheritedRef, 'current')) {
		return inheritedRef as React.RefObject<T>
	}

	return ref
}
