import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { Icon } from '@genusbiz/web-ui/controls/Icon'
import { Dialog } from '@genusbiz/web-ui/controls/Dialog'
import { createStyle } from '@genusbiz/web-ui/theming'
import { modalTypes } from './modalTypes'
import { modalManagerActions } from './duck'
import { useTranslation } from 'react-i18next'
import { operatorApi } from 'src/modules/operatorApi'
import { IMetaModelManagerEnvVariables } from 'src/interfaces/IMetaModelManagerEnvVariables'
import { TabControlHeader, TabControlHeaderItem, Text } from '@genusbiz/web-ui/controls'
import { CodeSnippet } from 'src/components/CodeSnippet/CodeSnippet'
import { e_M3RunMode } from 'src/enums/e_M3RunModes'

const classes = createStyle({
	notificationIcon: {
		display: 'table-cell',
		verticalAlign: 'top',
		paddingRight: 8,
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		padding: '1px 0px',
	},
	messageArea: {
		whiteSpace: 'pre-wrap',
		display: 'flex',
		flexDirection: 'column',
		margin: '12px',
	},
	codeSnippet: {
		backgroundColor: '#e3e3e3',
		padding: '8px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'start',
	},
	codeSnippetText: {
		fontFamily: 'monospace',
		whiteSpace: 'pre-line',
		margin: '0px',
	},
})

interface IMetaModelManagerModal {
	title?: string
	icon?: string
	iconColor?: string
	hideModal: (type: modalTypes) => void
}
const generateDockerLoginCommand = (envVariables: IMetaModelManagerEnvVariables) => {
	return `docker login genus.azurecr.io --username ${envVariables.dockerUserName} --password ${envVariables.dockerPassword}`
}
const generateRunM3DockerCommand = (envVariables: IMetaModelManagerEnvVariables, linebreak: string) => {
	return `docker run -it --entrypoint=Genus.MetaModelManager.CLI ${linebreak}
--pull always ${linebreak}
-e "appModelConnectionString=${envVariables.appModelConnectionString}" ${linebreak}
-e "appModelDatabase=${envVariables.appModelDatabase}" ${linebreak}
-e "appModelSchema=${envVariables.appModelSchema}" ${linebreak}
-e "vendorVersion=${envVariables.vendorVersion}" ${linebreak}
-e "directoryName=${envVariables.directoryName}" ${linebreak}
-e "clusterName=${envVariables.clusterName}" ${linebreak}
-e "domainName=${envVariables.domainName}" ${linebreak}
-e "scriptOutput=script.sql" ${linebreak}
-e "traceOutput=trace.txt" ${linebreak}
genus.azurecr.io/genus/images/genus-meta-model-manager:<VERSION> -i`
}
const generateM3EnvVariables = (envVariables: IMetaModelManagerEnvVariables) => {
	return `appModelConnectionString=${envVariables.appModelConnectionString}
appModelDatabase=${envVariables.appModelDatabase}
appModelSchema=${envVariables.appModelSchema}
vendorVersion=${envVariables.vendorVersion}
directoryName=${envVariables.directoryName}
clusterName=${envVariables.clusterName}
domainName=${envVariables.domainName}
scriptOutput=script.sql
traceOutput=trace.txt`
}

export const MetaModelManagerModal = (props: IMetaModelManagerModal) => {
	const dispatch = useDispatch()

	const { t } = useTranslation()

	const [selectedRunMode, setSelectedRunMode] = useState<e_M3RunMode>(e_M3RunMode.exe)
	const onClose = () => dispatch(modalManagerActions.confirm())

	const [dockerLoginCommand, setDockerLoginCommand] = useState('')
	const [runM3CommandWindows, setRunM3CommandWindows] = useState('')
	const [runM3CommandLinux, setRunM3CommandLinux] = useState('')
	const [runM3exe, setrunM3exe] = useState('')

	useEffect(() => {
		operatorApi
			.fetchMetaModelManagerEnvVariables()
			.then((data) => {
				const metaModelEnvVariables = {
					...data,
					directoryName: (window.env.MODEL_IDENTIFIER as string) || '',
				}
				setDockerLoginCommand(generateDockerLoginCommand(metaModelEnvVariables))
				setRunM3CommandWindows(generateRunM3DockerCommand(metaModelEnvVariables, '`'))
				setRunM3CommandLinux(generateRunM3DockerCommand(metaModelEnvVariables, '\\'))
				setrunM3exe(generateM3EnvVariables(metaModelEnvVariables))
			})
			.catch((err) => {
				dispatch(modalManagerActions.showErrorModal(err as Error, t('GENERAL:OK'), () => {}))
				setDockerLoginCommand('Something went wrong')
				setRunM3CommandWindows('Something went wrong')
				setRunM3CommandLinux('Something went wrong')
				setrunM3exe('Something went wrong')
			})
	}, [])

	return (
		<Dialog
			isOpen
			title={t('META_MODEL_MANAGER:TITLE')}
			width={800}
			height={'auto'}
			buttonDescriptions={[
				{
					title: 'OK',
					isEnabled: true,
					isDefault: true,
					isCancel: false,
					onClick: () => {
						props.hideModal(modalTypes.NOTIFICATION)
						onClose()
					},
				},
			]}
			disableCancelOnClickOutside
			disableCancelOnEscape
		>
			<div className={classes.content}>
				{props.icon && (
					<Icon iconClassName={props.icon + ' ' + classes.notificationIcon} size="medium" color={props.iconColor} />
				)}
				<div className={classes.messageArea}>
					<Text variant="body1">{t('META_MODEL_MANAGER:CHOSE_RUN_MODE')}</Text>
					<TabControlHeader>
						<TabControlHeaderItem
							onActivate={() => {
								setSelectedRunMode(e_M3RunMode.exe)
							}}
							isActive={selectedRunMode === e_M3RunMode.exe}
							text={t('META_MODEL_MANAGER:EXE_FILE')}
						/>
						<TabControlHeaderItem
							onActivate={() => {
								setSelectedRunMode(e_M3RunMode.dockerWindows)
							}}
							isActive={selectedRunMode === e_M3RunMode.dockerWindows}
							text={t('META_MODEL_MANAGER:DOCKER_ON_WINDOWS')}
						/>
						<TabControlHeaderItem
							onActivate={() => {
								setSelectedRunMode(e_M3RunMode.dockerLinux)
							}}
							isActive={selectedRunMode === e_M3RunMode.dockerLinux}
							text={t('META_MODEL_MANAGER:DOCKER_ON_LINUX')}
						/>
					</TabControlHeader>
					<div style={{ margin: '8px' }}>
						{(selectedRunMode === e_M3RunMode.dockerWindows || selectedRunMode === e_M3RunMode.dockerLinux) && (
							<>
								<Text>{t('META_MODEL_MANAGER:DOCKER_DESCRIPTION')}</Text>
								<div style={{ marginTop: '4px' }}>
									<a href="https://docs.docker.com/get-docker/" rel="noreferrer" target="_blank">
										{t('META_MODEL_MANAGER:INSTALL_DOCKER')}
									</a>
								</div>
								<Text style={{ marginTop: '8px' }} variant="h4">
									{t('META_MODEL_MANAGER:DOCKER_LOGIN')}
								</Text>
								<CodeSnippet code={dockerLoginCommand} />
								<Text style={{ marginTop: '8px' }} variant="h4">
									{t('META_MODEL_MANAGER:RUN_META_MODEL_MANAGER')}
								</Text>
								<Text variant="caption">{t('META_MODEL_MANAGER:SPECIFY_VERSION_REMINDER')}</Text>
							</>
						)}
						{selectedRunMode === e_M3RunMode.dockerWindows && <CodeSnippet code={runM3CommandWindows} />}
						{selectedRunMode === e_M3RunMode.dockerLinux && <CodeSnippet code={runM3CommandLinux} />}
						{selectedRunMode === e_M3RunMode.exe && (
							<>
								<Text variant="body1">
									{t('META_MODEL_MANAGER:EXE_DOWNLOAD')}
									<a href=" https://versioning.genus.net/Versioning/M3/." rel="noreferrer" target="_blank">
										{t('GENERAL:HERE')}
									</a>
								</Text>
								<Text variant="body1" style={{ marginBottom: '8px' }}>
									{t('META_MODEL_MANAGER:EXE_DESCRIPTION1')}
								</Text>
								<Text variant="body1" style={{ marginBottom: '8px' }}>
									{t('META_MODEL_MANAGER:EXE_DESCRIPTION2')}
								</Text>
								<CodeSnippet code={runM3exe} />
							</>
						)}
					</div>
				</div>
			</div>
		</Dialog>
	)
}
