import { useEffect } from 'react'
import { getFirstFocusable } from '../../utils/getFirstFocusable'
import { CLASS_NAME_BUTTONS_LEFT, CLASS_NAME_BUTTONS_RIGHT, CLASS_NAME_CONTENT } from '../DialogTypes'
import { findMapped } from '../../../utils/findMapped'

const getElementsByClassName = (
	domRef: React.RefObject<HTMLDivElement>,
	className: string
): HTMLElement | undefined => {
	if (!domRef.current) {
		return undefined
	}

	const elements = domRef.current.getElementsByClassName(className)

	if (!elements) {
		return undefined
	}

	if (elements.length !== 1) {
		return undefined
	}

	return elements[0] as HTMLElement
}

const getFirstFocusableOfElementbyClassName = (domRef: React.RefObject<HTMLDivElement>, className: string) => {
	const parentElement = getElementsByClassName(domRef, className)
	if (parentElement) {
		const focusElement = getFirstFocusable(parentElement)
		return focusElement
	}
}

const selectFocusElementHierarchically = (domRef: React.RefObject<HTMLDivElement>, sortedClassNames: string[]) =>
	findMapped(sortedClassNames, (className: string) => getFirstFocusableOfElementbyClassName(domRef, className))

export const useDialogInitialFocus = (
	domRef: React.RefObject<HTMLDivElement>,
	isOpen: boolean,
	dialogIsTarget?: boolean
) => {
	const setInitialFocusOnCallout = () => {
		const focusedElement = document.activeElement
		const dialogPageControlHasAutoFocus = domRef.current?.contains(focusedElement)

		if (!dialogPageControlHasAutoFocus) {
			domRef.current?.focus()
		}
	}

	const setInitialFocusOnFirstInteractiveDescendent = () => {
		// find first focusable element and set focus

		// find first focusable element inside content, or as a fallback focus first enabled button

		const sortedClassNames = [CLASS_NAME_CONTENT, CLASS_NAME_BUTTONS_LEFT, CLASS_NAME_BUTTONS_RIGHT]
		const focusElement = selectFocusElementHierarchically(domRef, sortedClassNames)

		if (focusElement !== undefined) {
			'select' in focusElement && focusElement.select && typeof focusElement.select === 'function'
				? focusElement.select()
				: focusElement.focus()
		}
	}

	useEffect(() => {
		if (!isOpen) {
			return
		}
		const setFocusOnCorrectTarget = () =>
			dialogIsTarget ? setInitialFocusOnCallout() : setInitialFocusOnFirstInteractiveDescendent()

		callFunctionAfterCallstack(setFocusOnCorrectTarget)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])
}

const callFunctionAfterCallstack = (callback: () => void) => window.setTimeout(callback, 0)
