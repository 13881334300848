export const getLogicalFocusAndHoverHandlers = (
	setLogicalHover?: (focus: boolean) => void,
	setLogicalFocus?: (focus: boolean) => void
) => {
	const onMouseOver = () => {
		setLogicalHover?.(true)
	}

	const onMouseOut = () => {
		setLogicalHover?.(false)
	}

	const onFocus = () => {
		setLogicalFocus?.(true)
	}

	return { onMouseOver, onMouseOut, onFocus }
}

export const getEventHandlers = (
	openedByTouch: React.MutableRefObject<boolean>,
	isDropdownOpen: boolean,
	incrementCurrentValue: (decrement: boolean) => void,
	readOnly?: boolean,
	disabled?: boolean,
	defaultAction?: () => void,
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
) => {
	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (readOnly || disabled) {
			return
		}

		switch (event.key) {
			case 'Enter':
				if (defaultAction) {
					event.preventDefault()
					event.stopPropagation()

					defaultAction()
				}

				break
			case 'ArrowUp':
			case 'ArrowDown': {
				event.preventDefault()

				if (!isDropdownOpen) {
					const decrement = event.key === 'ArrowUp'

					incrementCurrentValue(decrement)
				}

				break
			}
		}
		openedByTouch.current = false

		onKeyDown?.(event)
	}

	return { handleKeyDown }
}
