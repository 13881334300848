/**
 * Storage service primarily used for data with small footprint,
 * such as boolean settings, strings etc.
 *
 */
export class StorageService {
	private prefixKey = ''
	private onError: ((err: string) => void) | undefined = undefined

	constructor(private storage: 'localStorage' | 'sessionStorage' = 'sessionStorage') {
		if (typeof Storage === 'undefined') {
			throw new Error('No session storage')
		}
	}

	/**
	 * Set key to use in session storage
	 * @param { string } key
	 * @param { (err: string) => void } onError
	 *
	 */
	setPrefixKey(prefixKey: string, onError?: (err: string) => void) {
		this.prefixKey = prefixKey
		this.onError = onError
	}

	/**
	 * Read key from session storage
	 * @param { string } key
	 *
	 */
	read<T>(key: string, ignoreStorageKeyPrefix?: boolean) {
		const storageKeyPrefix = ignoreStorageKeyPrefix ? '' : this.prefixKey

		try {
			const encodedValue = window[this.storage].getItem(storageKeyPrefix + key)
			if (!encodedValue) {
				return
			}

			return JSON.parse(decodeURIComponent(window.atob(encodedValue))) as T
		} catch (err) {
			this.onError?.('SessionStorageService.read error: ' + (err as Error).message)
		}
		return undefined
	}

	/**
	 * Write key/value pair to session storage
	 * @param { string } key
	 * @param { unknown } value
	 *
	 */
	write(key: string, value: unknown, ignoreStorageKeyPrefix?: boolean): void {
		const storageKeyPrefix = ignoreStorageKeyPrefix ? '' : this.prefixKey
		const encodedValue = window.btoa(encodeURIComponent(JSON.stringify(value)))

		try {
			window[this.storage].setItem(storageKeyPrefix + key, encodedValue)
		} catch (err) {
			this.onError?.('SessionStorageService.write error: ' + (err as Error).message)
		}
	}

	/**
	 * Delete key/value pair from storage
	 * @param { string } key
	 *
	 */
	delete(key: string, ignoreStorageKeyPrefix?: boolean): void {
		const storageKeyPrefix = ignoreStorageKeyPrefix ? '' : this.prefixKey

		try {
			window[this.storage].removeItem(storageKeyPrefix + key)
		} catch (err) {
			this.onError?.('SessionStorageService.delete error: ' + (err as Error).message)
		}
	}

	/**
	 * Delete all values from session storage
	 *
	 */
	clear(): void {
		//window.sessionStorage.clear()
	}
}

export const sessionStorageService = new StorageService('sessionStorage')
export const localStorageService = new StorageService('localStorage')
