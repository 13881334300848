export const defaultColors = {
	errorBackground: '#fde7e9',
	errorText: '#b92919',
	warningBackground: '#fff4ce',
	warningText: '#b15406',
	successBackground: '#dff6dd',
	successText: '#307440',
	primary: '#186',
	grayBackground: '#E5E5E5',
	purpleBackground: '#CBC3E3',
	signInButton: '#696969',
}
