import type { CellData, TData, Value } from '../Table.types'
import type { IRowNode, ColDef } from '@ag-grid-community/core'
import type { FormatterTValue } from './colDefUtils'
import { getCellDataFromGroupNodeChild } from './colDefUtils'
import { hasCellAggregatedData } from './hasCellAggregatedData'

export function formatGroupCellValue(
	data: TData | undefined,
	value: FormatterTValue,
	node: IRowNode<TData> | null,
	colDef: ColDef<TData, FormatterTValue> | ColDef<TData, CellData | string>,
	tcvi: (t: string) => string
): string {
	if (data?.rowName) {
		return data.rowName
	}

	if (node && hasCellAggregatedData(node, colDef)) {
		if (colDef.cellRendererParams?.formatCellValue) {
			return colDef.cellRendererParams.formatCellValue(typeof value === 'object' ? value?.value : value)
		}
		if (node.footer) {
			return ''
		}
		if (colDef.field && node.aggData[colDef.field] !== undefined && node.expanded === false) {
			return node.aggData[colDef.field].toString()
		}
		return ''
	}
	const formattedValue = getCellDataFromGroupNodeChild(node)?.formattedValue

	if (formattedValue === '') {
		return addDescriptors(getNullValue(node), node, tcvi)
	}

	const _formattedValue = getStringFromValidValue(formattedValue)
	if (_formattedValue) {
		return addDescriptors(_formattedValue, node, tcvi)
	}

	const _value = getStringFromValidValue(typeof value === 'object' ? value?.value : value)
	if (_value) {
		return addDescriptors(_value, node, tcvi)
	}

	return addDescriptors(getNullValue(node), node, tcvi)
}

/**
 * Adds text around the final string value.
 * @example Total: <value>
 * @param value final string value
 * @param node cell node
 */
function addDescriptors(value: string, node: IRowNode<TData> | null, tcvi: (t: string) => string) {
	if (!node) {
		return value
	}
	if (node.footer) {
		if (!value) {
			return tcvi('CONTROL:AG_PIVOTCOLUMNGROUPTOTALS')
		}
		return `${tcvi('CONTROL:AG_PIVOTCOLUMNGROUPTOTALS')}: ${value}`
	}
	return value
}

function getNullValue(node: IRowNode<TData> | null) {
	if (!node) {
		return ''
	}
	if (node.footer) {
		return ''
	}
	if (!node.group) {
		return ''
	}
	return '---'
}

function getStringFromValidValue(value: Value) {
	if (value === undefined || value === null) {
		return null
	}
	const trimmedStringValue = value.toString().trim()
	if (trimmedStringValue) {
		return trimmedStringValue
	}
	return null
}
