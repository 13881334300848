export const translations = {
	// General
	// AppBar
	'GENERAL:GO_TO_START_PAGE': 'Go to start page',
	'GENERAL:SWITCH_TO_ANOTHER_APP': 'Switch to another app',
	'GENERAL:SETTINGS': 'Settings',
	'GENERAL:HELP': 'Help',
	'GENERAL:USER_INFORMATION': 'User information',
	'GENERAL:CLIENT_INSPECTOR': 'CLient Inspector',
	'GENERAL:DISPLAY_UX_DEVELOPMENT_FEATURES': 'Display UX Development Features',
	'GENERAL:SITEMAP': 'Sitemap',
	'GENERAL:MORE_COMMANDS': 'More Commands',

	// Dialog
	'COMMAND:OK': 'OK',
	'COMMAND:CANCEL': 'CANCEL',
	'COMMAND:YES': 'YES',
	'COMMAND:NO': 'NO',

	// Dropdown
	'GENERAL:LOADING': 'Loading',
	'GENERAL:NO_ITEMS_FOUND': 'No items found',
	'GENERAL:SELECT': 'Select',

	// Label
	'GENERAL:THIS_FIELD_IS_READ_ONLY_AND_CANNOT_BE_MODIFIED': 'This field is read-only and cannot be modified',
	'GENERAL:THIS_FIELD_IS_REQUIRED': 'This field is required',

	// List
	'GENERAL:EMPTY': 'Empty',

	// Lookup
	'GENERAL:ENTER_AT_LEAST_CHARACTERS_TO_SEARCH': 'Enter at least {0} characters to search',
	'GENERAL:SEARCHING_FOR': 'Searching for {0} ...',
	'GENERAL:RESULTS_FOR': 'Results for {0}',

	// Lookup with dialog
	'GENERAL:NO_MATCHES_FOUND_FOR_SEARCH_CRITERIA': 'No matches found for search criteria',

	// Navigation pane
	'GENERAL:TOGGLE_COMPACT_MODE': 'Toggle Compact Mode',

	// TabControl
	'GENERAL:CLOSE': 'Close',

	// RichTextEditor
	'GENERAL:UNDO': 'Undo',
	'GENERAL:REDO': 'Redo',
	'GENERAL:LINK': 'Link',
	'GENERAL:TEXT': 'Text',
	'GENERAL:REMOVE_LINK': 'Remove link',
	'GENERAL:NORMAL': 'Normal',
	'GENERAL:BOLD': 'Bold',
	'GENERAL:ITALIC': 'Italic',
	'GENERAL:UNDERLINE': 'Underline',
	'GENERAL:STRIKETHROUGH': 'Strikethrough',
	'GENERAL:REMOVE_FORMATTING': 'Remove formatting',
	'GENERAL:ALIGN_LEFT': 'Align left',
	'GENERAL:ALIGN_CENTER': 'Align center',
	'GENERAL:ALIGN_RIGHT': 'Align right',
	'GENERAL:INDENT_LEFT': 'Indent left',
	'GENERAL:INDENT_RIGHT': 'Indent right',
	'GENERAL:TABLE': 'Table',
	'GENERAL:LIST_NUMBERS': 'Number list',
	'GENERAL:LIST_BULLETS': 'Bullet list',
	'GENERAL:TYPOGRAPHY_H1': 'Heading 1',
	'GENERAL:TYPOGRAPHY_H2': 'Heading 2',
	'GENERAL:TYPOGRAPHY_H3': 'Heading 3',
	'GENERAL:TYPOGRAPHY_H4': 'Heading 4',
	'GENERAL:TYPOGRAPHY_H5': 'Heading 5',
	'GENERAL:TYPOGRAPHY_H6': 'Heading 6',
	'GENERAL:TYPOGRAPHY_CODE': 'Code Block',
	'GENERAL:HEIGHT_SHORT': 'H',
	'GENERAL:WIDTH_SHORT': 'W',
	'GENERAL:INHERIT': 'Inherit',

	// Table
	'TABLE:INSERT_TABLE': 'Insert table',
	'TABLE:ROWS': 'Rows',
	'TABLE:COLUMNS': 'Columns',
	'TABLE:INSERT_COLUMN_LEFT': 'Insert column left',
	'TABLE:INSERT_COLUMNS_LEFT': 'Insert {0} columns left',
	'TABLE:INSERT_COLUMN_RIGHT': 'Insert column right',
	'TABLE:INSERT_COLUMNS_RIGHT': 'Insert {0} columns right',
	'TABLE:INSERT_ROW_ABOVE': 'Insert row above',
	'TABLE:INSERT_ROWS_ABOVE': 'Insert {0} rows above',
	'TABLE:INSERT_ROW_BELOW': 'Insert row below',
	'TABLE:INSERT_ROWS_BELOW': 'Insert {0} rows below',
	'TABLE:DELETE_TABLE': 'Delete table',
	'TABLE:DELETE_COLUMN': 'Delete column',
	'TABLE:DELETE_ROW': 'Delete row',
	'TABLE:ADD_ROW_HEADER': 'Add row header',
	'TABLE:REMOVE_ROW_HEADER': 'Remove row header',
	'TABLE:ADD_COLUMN_HEADER': 'Add column header',
	'TABLE:REMOVE_COLUMN_HEADER': 'Remove column header',
	'CONTROL:AG_TEXTFILTER': 'Text Filter',
	'CONTROL:AG_DATEFILTER': 'Date Filter',
	'CONTROL:AG_NUMBERFILTER': 'Number Filter',
	'CONTROL:AG_EQUALS': 'Equals',
	'CONTROL:AG_NOTEQUAL': 'Not equal',
	'CONTROL:AG_GREATERTHAN': 'Greater than',
	'CONTROL:AG_LESSTHAN': 'Less than',
	'CONTROL:AG_INRANGE': 'In range',
	'CONTROL:AG_BLANK': 'Blank',
	'CONTROL:AG_NOTBLANK': 'Not blank',
	'CONTROL:AG_CONTAINS': 'Contains',
	'CONTROL:AG_NOTCONTAINS': 'Not contains',
	'CONTROL:AG_STARTSWITH': 'Starts with',
	'CONTROL:AG_ENDSWITH': 'Ends with',
	'CONTROL:AG_LESSTHANOREQUAL': 'Less than or equal',
	'CONTROL:AG_GREATERTHANOREQUAL': 'Greater than or equal',
	'CONTROL:AG_SEARCHOOO': 'Search...',
	'CONTROL:AG_SELECTALL': 'Select All',
	'CONTROL:AG_APPLYFILTER': 'Apply Filter',
	'CONTROL:AG_RESETFILTER': 'Reset Filter',
	'CONTROL:AG_PINCOLUMN': 'Pin Column',
	'CONTROL:AG_NOPIN': 'No Pin',
	'CONTROL:AG_PINLEFT': 'Pin Left',
	'CONTROL:AG_PINRIGHT': 'Pin Right',
	'CONTROL:AG_AUTOSIZETHISCOLUMN': 'Auto-size this column',
	'CONTROL:AG_AUTOSIZEALLCOLUMNS': 'Auto-size all columns',
	'CONTROL:AG_GROUPBY': 'Group by',
	'GENERAL:RESET_VIEW': 'Reset view',
	'GENERAL:GO_TO_TODAY': 'Go to today',
	'CONTROL:AG_ARIALABELSUBMENU': 'SubMenu',
	'CONTROL:AG_ANDCONDITION': 'And',
	'CONTROL:AG_ORCONDITION': 'Or',
	'CONTROL:AG_COLUMNCHOOSER': 'Choose columns',
	'CONTROL:AG_CHOOSECOLUMNS': 'Choose columns',
	'CONTROL:AG_SORTASCENDING': 'Sort Ascending',
	'CONTROL:AG_SORTDESCENDING': 'Sort Descending',
	'CONTROL:AG_COLUMNFILTER': 'Column Filter',
	'CONTROL:AG_EXPANDALL': 'Expand All',
	'CONTROL:AG_COLLAPSEALL': 'Collapse All',
	'CONTROL:AG_PIVOTCOLUMNGROUPTOTALS': 'Total',
	'GENERAL:MERGE_CELLS': 'Merge cells',
	'GENERAL:UNMERGE_CELL': 'Unmerge cell',
	'GENERAL:GROUP': 'Group',
	'GENERAL:DOWNLOAD_AS_EXCEL': 'Download as Excel',
} as { [t: string]: string }
