import { useCallback, useContext, useEffect, useMemo } from 'react'
import { KeyboardShortcutListenerContext } from '../../utils/keyboard'
import type { IKeyboardShortcut } from '../../utils/keyboard/IKeyboardShortcut'
import {
	registerKeyboardShortcut,
	removeRegisteredKeyboardShortcut,
} from '../../utils/keyboard/registerKeyboardShortcut'
import { generateGuid } from '../../utils/generateGuid'

export const useKeyboardShortcut = (
	buttonRef: React.RefObject<HTMLButtonElement>,
	disabled = false,
	keyboardShortcut?: IKeyboardShortcut
) => {
	const keyboardShortcutContext = useContext(KeyboardShortcutListenerContext)

	const id = useMemo(() => generateGuid(), [])

	const handleShortcut = useCallback(() => {
		if (disabled) {
			return
		}

		buttonRef.current?.click()
	}, [disabled])

	useEffect(() => {
		if (keyboardShortcutContext && keyboardShortcut) {
			registerKeyboardShortcut(keyboardShortcut, keyboardShortcutContext.contextId, id, handleShortcut)
		}
		return () => {
			if (keyboardShortcutContext && keyboardShortcut) {
				removeRegisteredKeyboardShortcut(keyboardShortcutContext.contextId, id)
			}
		}
	}, [keyboardShortcutContext, keyboardShortcut, handleShortcut, id])
}
