import isUndefined from 'lodash/isUndefined'
import { router } from '..'
import { compareIgnoreCase } from './compareIgnoreCase'

const parseWindowsLocationSearchString = (search?: string) => {
	if (!isUndefined(search)) {
		return parseQueryString(search)
	} else {
		return parseQueryString(window.location.search)
	}
}

/**
 * @param {string} input string to parse
 * @return parsed query string as an object with key value pairs.
 */
const parseQueryString = (input: string) => {
	// window.location.search will start with a ? if anything
	const queryString = input.substring(1)

	const pairs: { [key: string]: string } = {}

	// Handle no query string
	if (!queryString) {
		return pairs
	}

	const items = queryString.split('&')

	items.forEach((item) => {
		// Ignore all items without a =
		// This assumes the query consists of key=value pairs
		if (!item.includes('=')) {
			return
		}

		const pair = item.split('=')
		const key = decodeURIComponent(pair[0])
		const value = decodeURIComponent(pair[1])
		pairs[key] = value
	})

	return pairs
}

/**
 * Finds and removes provided key and its value from the query string
 * The history is replaced
 *
 * @param {string} keyToRemove Query key to remove from query string
 * @return
 */
const removeQueryStringItem = (keyToRemove: string) => {
	const pairs = parseWindowsLocationSearchString()

	const lookupKeyToRemove = lookupQueryParamKeyIgnoreCase(keyToRemove, pairs)

	if (lookupKeyToRemove) {
		delete pairs[lookupKeyToRemove]
		const newQueryString = createQueryStringFromKeyValuePairs(pairs)
		replaceQueryInHistory(newQueryString)
	}

	return pairs
}

const replaceQueryInHistory = (newSearch: string | null) => {
	try {
		router.navigate(window.location.pathname + (newSearch ? newSearch : ''), { replace: true })
	} catch (err) {
		window.traceLogger.error(err as Error)
	}
}

/**
 * Creates a query string.
 *
 * @return {String} The result will be null if there are no key value pairs in the input
 * The result will start with a ? if there are key value pairs in the input
 */
const createQueryStringFromKeyValuePairs = (keyValuePairs: { [key: string]: string | undefined }) => {
	if (!keyValuePairs) {
		return null
	}

	const keys = Object.keys(keyValuePairs)

	// Handle no key value pair case
	if (keys.length === 0) {
		return null
	}

	const queryString =
		'?' +
		keys
			.map((key) => {
				return encodeURIComponent(key) + '=' + encodeURIComponent(keyValuePairs[key]!)
			})
			.join('&')

	return queryString
}

/**
 * Looks for the provided key in the query string and returns its value if found.
 *
 * @param {string} key Query key
 * @return {string} Value of provided key
 */
const getQueryStringValue = (key: string) => {
	const queryParams = parseWindowsLocationSearchString()

	return getQueryParamValue(key, queryParams)
}

const getQueryParamValue = (key: string, queryParams: { [key: string]: string }) => {
	for (const queryParam in queryParams) {
		if (compareIgnoreCase(queryParam, key)) {
			return queryParams[queryParam]
		}
	}
}

const lookupQueryParamKeyIgnoreCase = (key: string, queryParams: { [key: string]: string }) => {
	for (const queryParam in queryParams) {
		if (compareIgnoreCase(queryParam, key)) {
			return queryParam
		}
	}
}

const queryStringUtils = {
	removeQueryStringItem,
	getQueryStringValue,
	getQueryParamValue,
	parseQueryString,
	createQueryStringFromKeyValuePairs,
	parseWindowsLocationSearchString,
	replaceQueryInHistory,
}

export { queryStringUtils }
