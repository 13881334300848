export enum e_ClusterDiagramNodeType {
	deployment = 'DEPLOYMENT',
	statefulSet = 'STATEFUL_SET',
	namespace = 'NAMESPACE',
}

const ClusterDiagramNodeTypeMapper = new Map<number, e_ClusterDiagramNodeType>([
	[10, e_ClusterDiagramNodeType.deployment],
	[20, e_ClusterDiagramNodeType.statefulSet],
	[30, e_ClusterDiagramNodeType.namespace],
])

export const getClusterDiagramNodeType = (nodeTypeValue: number) => ClusterDiagramNodeTypeMapper.get(nodeTypeValue)
