import React, { useState, useEffect } from 'react'
import CodeMirror from 'codemirror'
import 'codemirror/keymap/sublime'

import 'codemirror/addon/edit/matchbrackets'

import 'codemirror/addon/search/matchesonscrollbar'
import 'codemirror/addon/search/matchesonscrollbar.css'
import 'codemirror/addon/selection/active-line'
import 'codemirror/addon/scroll/annotatescrollbar'

import 'codemirror/addon/selection/mark-selection'

import 'codemirror/lib/codemirror.css' // NB! This should be imported BEFORE custom css to make highlighting/marking work properly
import './codemirror-operator-theme.css'

import { UnControlled as ReactCodeMirror2UncontrolledEditor } from 'react-codemirror2'
import { createStyle } from '@genusbiz/web-ui/theming'

interface IProps {
	log: string
}
const classes = createStyle({
	cmEditor: {
		flex: '1',
		overflow: 'auto',
	},
	logContainer: { flex: 1, display: 'flex', overflow: 'auto', flexDirection: 'column' },
})

export const LogContainer = (props: IProps) => {
	const [codeMirrorInstance, setCodeMirrorInstance] = useState<CodeMirror.Editor | null>(null)

	useEffect(() => {
		if (!codeMirrorInstance) {
			return
		}
		codeMirrorInstance?.swapDoc(generateDocFromString(props.log))
	}, [codeMirrorInstance, props.log])

	const generateDocFromString = (log: string) => {
		return CodeMirror.Doc(log)
	}

	const initializeEditor = (editor: CodeMirror.Editor) => {
		editor.setSize('100%', '100%')
		setCodeMirrorInstance(editor)
	}

	const options = {
		lineNumbers: true,
		mode: { name: 'operator', backdrop: 'text' },
		theme: 'operator',
		keyMap: 'sublime',
		readOnly: true,
	}

	return (
		<div className={classes.logContainer}>
			<ReactCodeMirror2UncontrolledEditor
				className={classes.cmEditor}
				editorDidMount={initializeEditor}
				options={options}
			/>
		</div>
	)
}
