import type {
	DOMConversionMap,
	DOMConversionOutput,
	DOMExportOutput,
	EditorConfig,
	LexicalEditor,
	LexicalNode,
	NodeKey,
	SerializedEditor,
	SerializedLexicalNode,
	Spread,
} from 'lexical'

import { $applyNodeReplacement, createEditor, DecoratorNode } from 'lexical'
import * as React from 'react'
import { Suspense } from 'react'

export const e_ImageWidthMode = {
	px: 'px',
	percent: 'percent',
} as const

export type e_ImageWidthMode = (typeof e_ImageWidthMode)[keyof typeof e_ImageWidthMode]

const ImageComponent = React.lazy(() =>
	import('./ImageComponent').then((module) => ({ default: module.ImageComponent }))
)

export interface ImagePayload {
	altText: string
	caption?: LexicalEditor
	height?: number
	key?: NodeKey
	maxWidth?: number
	showCaption?: boolean
	src: string
	width?: number
	captionsEnabled?: boolean
	unit?: e_ImageWidthMode
	aspectRatio?: number
}

const parseCSSWidth = (domNode: HTMLImageElement): { width: number; unit: e_ImageWidthMode } | null => {
	const width = domNode.style.width
	if (width.endsWith('%')) {
		return { width: parseFloat(width.slice(0, -1)), unit: e_ImageWidthMode.percent }
	}
	if (domNode.width) {
		return { width: domNode.width, unit: e_ImageWidthMode.px }
	}
	const computedWidth = getComputedStyle(domNode).width
	if (computedWidth) {
		return { width: parseFloat(computedWidth.slice(0, -2)), unit: e_ImageWidthMode.px }
	}
	const display = domNode.style.display
	domNode.style.display = 'none'
	const parent = domNode.parentElement
	const sibling = domNode.nextSibling
	document.body.appendChild(domNode)
	const computedWidth2 = getComputedStyle(domNode).width
	if (parent) {
		parent.insertBefore(domNode, sibling)
	} else {
		document.body.removeChild(domNode)
	}
	domNode.style.display = display
	if (computedWidth2.endsWith('px')) {
		return { width: parseFloat(computedWidth2.slice(0, -2)), unit: e_ImageWidthMode.px }
	}
	return null
}

const parseAspectRatio = (domNode: HTMLImageElement): number | undefined => {
	const aspectRatioString = domNode.style.aspectRatio
	if (!aspectRatioString) {
		return undefined
	}
	const values = aspectRatioString.split('/')
	if (values.length !== 2) {
		return undefined
	}
	return parseFloat(values[0]) / parseFloat(values[1])
}

function convertImageElement(domNode: Node): null | DOMConversionOutput {
	if (domNode instanceof HTMLImageElement) {
		const parsedCSSWidth = parseCSSWidth(domNode)
		const aspectRatio = parseAspectRatio(domNode)
		const { alt, src, height } = domNode
		const node = $createImageNode({ altText: alt, height, src, ...parsedCSSWidth, aspectRatio })
		return { node }
	}
	return null
}

export type SerializedImageNode = Spread<
	{
		altText: string
		caption: SerializedEditor
		height?: number
		maxWidth?: number
		showCaption: boolean
		src: string
		width?: number
		widthMode: e_ImageWidthMode
	},
	SerializedLexicalNode
>

export class ImageNode extends DecoratorNode<JSX.Element> {
	__src: string
	__altText: string
	__width: 'inherit' | number
	__height: 'inherit' | number
	__maxWidth: number | undefined
	__widthMode: e_ImageWidthMode
	__showCaption: boolean
	__caption: LexicalEditor
	__aspectRatio: number | null
	// Captions cannot yet be used within editor cells
	__captionsEnabled: boolean
	__isDisabled = false

	static getType(): string {
		return 'image'
	}

	static clone(node: ImageNode): ImageNode {
		return new ImageNode(
			node.__src,
			node.__altText,
			{
				maxWidth: node.__maxWidth,
				width: node.__width,
				height: node.__height,
				widthMode: node.__widthMode,
				aspectRatio: node.__aspectRatio,
			},
			node.__showCaption,
			node.__caption,
			node.__captionsEnabled,
			node.__key
		)
	}

	static importJSON(serializedNode: SerializedImageNode): ImageNode {
		const { altText, height, width, maxWidth, caption, src, showCaption } = serializedNode
		const node = $createImageNode({
			altText,
			height,
			maxWidth,
			showCaption,
			src,
			width,
		})
		const nestedEditor = node.__caption
		const editorState = nestedEditor.parseEditorState(caption.editorState)
		if (!editorState.isEmpty()) {
			nestedEditor.setEditorState(editorState)
		}
		return node
	}

	exportDOM(): DOMExportOutput {
		const element = document.createElement('img')
		element.setAttribute('src', this.__src)
		element.setAttribute('alt', this.__altText)
		const widthSuffix = this.__widthMode === 'percent' ? '%' : 'px'
		element.style.width = this.__width.toString() + widthSuffix
		if (this.__widthMode === 'percent' && this.__aspectRatio) {
			element.style.aspectRatio = `${this.__aspectRatio.toString()} / 1`
		} else {
			element.setAttribute('height', this.__height.toString() + 'px')
		}
		return { element }
	}

	static importDOM(): DOMConversionMap | null {
		return {
			img: () => ({
				conversion: convertImageElement,
				priority: 0,
			}),
		}
	}

	constructor(
		src: string,
		altText: string,
		size: {
			maxWidth?: number
			width?: 'inherit' | number
			height?: 'inherit' | number
			widthMode?: e_ImageWidthMode
			aspectRatio?: number | null
		},
		showCaption?: boolean,
		caption?: LexicalEditor,
		captionsEnabled?: boolean,
		key?: NodeKey
	) {
		super(key)
		this.__src = src
		this.__altText = altText
		this.__maxWidth = size.maxWidth
		this.__width = size.width || 'inherit'
		this.__height = size.height || 'inherit'
		this.__showCaption = showCaption || false
		this.__caption = caption || createEditor()
		this.__captionsEnabled = captionsEnabled || captionsEnabled === undefined
		this.__widthMode = size.widthMode || e_ImageWidthMode.px
		this.__aspectRatio = size.aspectRatio || null
	}

	exportJSON(): SerializedImageNode {
		return {
			altText: this.getAltText(),
			caption: this.__caption.toJSON(),
			height: this.__height === 'inherit' ? 0 : this.__height,
			maxWidth: this.__maxWidth,
			showCaption: this.__showCaption,
			src: this.getSrc(),
			type: 'image',
			version: 1,
			width: this.__width === 'inherit' ? 0 : this.__width,
			widthMode: this.__widthMode,
		}
	}

	setWidthAndHeight(width: 'inherit' | number, height: 'inherit' | number): void {
		const writable = this.getWritable()
		writable.__width = width
		writable.__height = height
	}

	setWidth(width: 'inherit' | number): void {
		const writable = this.getWritable()
		writable.__width = width
	}

	setHeight(height: 'inherit' | number): void {
		const writable = this.getWritable()
		writable.__height = height
	}

	setIsDisabled(isDisabled: boolean): void {
		const writable = this.getWritable()
		writable.__isDisabled = isDisabled
	}

	setAspectRatio(aspectRatio: number): void {
		const writable = this.getWritable()
		writable.__aspectRatio = aspectRatio
	}

	setShowCaption(showCaption: boolean): void {
		const writable = this.getWritable()
		writable.__showCaption = showCaption
	}

	setWidthMode(widthMode: e_ImageWidthMode, element: HTMLElement | null, parentWidth: number): void {
		if (this.__widthMode === widthMode) {
			return
		}
		const writable = this.getWritable()
		let newWidth = this.__width
		if (widthMode === e_ImageWidthMode.percent) {
			const currentWidth = this.__width === 'inherit' ? parentWidth : this.__width
			if (this.__height !== 'inherit') {
				writable.__aspectRatio = currentWidth / this.__height
			}
			newWidth = (currentWidth / parentWidth) * 100
		} else if (widthMode === e_ImageWidthMode.px) {
			const currentWidth = this.__width === 'inherit' ? parentWidth : this.__width
			newWidth = (currentWidth * parentWidth) / 100
			const currentHeight = this.getHeight(element, newWidth)
			writable.__aspectRatio = null
			writable.__height = currentHeight
		}
		writable.__width = newWidth
		writable.__widthMode = widthMode
	}

	toggleWidthMode(element: HTMLElement | null, parentWidth: number) {
		this.setWidthMode(
			this.__widthMode === e_ImageWidthMode.percent ? e_ImageWidthMode.px : e_ImageWidthMode.percent,
			element,
			parentWidth
		)
	}

	// View

	createDOM(config: EditorConfig): HTMLElement {
		const span = document.createElement('span')
		const theme = config.theme
		const className = theme.image
		if (className !== undefined) {
			span.className = className
		}
		if (this.__widthMode === 'percent' && this.__width !== 'inherit') {
			span.style.width = `${this.__width}%`
		}
		span.toggleAttribute('is-image')
		return span
	}

	updateDOM(prevNode: ImageNode) {
		return prevNode.__widthMode !== this.__widthMode || prevNode.__width !== this.__width
	}

	private getHeight(domNode: HTMLElement | null, width: number) {
		if (this.__aspectRatio) {
			return width / this.__aspectRatio
		}
		const computedStyles = domNode && getComputedStyle(domNode)
		if (computedStyles) {
			return parseFloat(computedStyles.height.slice(0, -2))
		}
		return this.__height
	}

	getSrc(): string {
		return this.__src
	}

	getAltText(): string {
		return this.__altText
	}

	decorate(): JSX.Element {
		return (
			<Suspense fallback={null}>
				<ImageComponent
					src={this.__src}
					altText={this.__altText}
					width={this.__width}
					height={this.__height}
					maxWidth={this.__maxWidth}
					nodeKey={this.getKey()}
					showCaption={this.__showCaption}
					caption={this.__caption}
					widthMode={this.__widthMode}
					aspectRatio={this.__aspectRatio}
					isResizable={!this.__isDisabled}
				/>
			</Suspense>
		)
	}
}

export function $createImageNode(params: ImagePayload): ImageNode {
	const { altText, height, maxWidth, captionsEnabled, src, width, showCaption, caption, key, unit, aspectRatio } =
		params
	return $applyNodeReplacement(
		new ImageNode(
			src,
			altText,
			{
				maxWidth,
				width,
				height,
				widthMode: unit,
				aspectRatio,
			},
			showCaption,
			caption,
			captionsEnabled,
			key
		)
	)
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
	return node instanceof ImageNode
}
