import type { BackendFactory, DragDropManager } from 'dnd-core'

import { HTML5BackendImpl } from 'react-dnd-html5-backend/dist/HTML5BackendImpl'
import { type ISetDataMonitor } from './DragDropTypes'

import type { HTML5BackendContext, HTML5BackendOptions } from 'react-dnd-html5-backend/dist/types'
export * as NativeTypes from 'react-dnd-html5-backend/dist/NativeTypes'
export type { HTML5BackendContext, HTML5BackendOptions } from 'react-dnd-html5-backend/dist/types'

//Custom backend to expose necesarry dataTransfer methods
export class CustomHTML5BackendImpl extends HTML5BackendImpl {
	// Super() does not work on arrow functions
	private baseHandleTopDragStart = this.handleTopDragStart

	override handleTopDragStart = (e: DragEvent) => {
		//@ts-expect-error Monitor is not a public member of HTML5BackendImpl
		;(this.monitor as ISetDataMonitor).setData = (format: string, data: string) => e.dataTransfer?.setData(format, data)

		this.baseHandleTopDragStart(e)
	}
}

export const CustomHTML5Backend: BackendFactory = function createBackend(
	manager: DragDropManager,
	context?: HTML5BackendContext,
	options?: HTML5BackendOptions
): HTML5BackendImpl {
	return new CustomHTML5BackendImpl(manager, context, options)
}
