import { createStyle } from '../../../theming'
import React from 'react'
import type { CellData } from '../Table.types'

interface IToolTip {
	value: CellData
}

const classes = createStyle((theme) => ({
	wrapper: {
		background: theme.colors.input.text,
		color: theme.colors.input.background,
		padding: 4,
	},
}))

export const Tooltip = (props: IToolTip) => {
	return props.value?.value && <span className={classes.wrapper}>{props.value?.value}</span>
}
