import React from 'react'

// AG-grid
import type { ICellRendererParams } from '@ag-grid-community/core'

// Components
import { CellSelectionCheckMark } from '../CellCheckMark'

// Interfaces and enums
import type { CellData, ICellRendererProps, ITableContext, TData } from '../../Table.types'

// Utils
import { createStyle } from '../../../../theming'
import { CellRenderer } from './CellRenderer'
import clsx from 'clsx'

export interface IGroupCellRendererProps {
	multiSelect: boolean
}

const classes = createStyle((theme) => ({
	autoGroupColumnCell: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		gap: 4,
	},
	groupCell: {
		fontWeight: theme.typography.styles.button.fontWeight,
	},
}))

export const GroupCellRenderer = (
	props: ICellRendererParams<TData, CellData, ITableContext> & IGroupCellRendererProps
) => {
	const { node, valueFormatted } = props

	if (node.footer) {
		return props.valueFormatted
	}
	const groupingFieldId = node.field ?? 'value'

	const colDef = props.api.getColumn(groupingFieldId)?.getColDef()
	const cellRendererParams = colDef?.cellRendererParams as ICellRendererProps | undefined

	const headerName = colDef?.headerName

	const groupName =
		cellRendererParams?.prefixGroup && headerName !== undefined ? headerName + ': ' + valueFormatted : valueFormatted

	if (node.group === false) {
		return (
			<div className={classes.autoGroupColumnCell}>
				<CellRenderer {...props} {...props.colDef?.cellRendererParams} />
			</div>
		)
	}

	return (
		<div
			className={clsx(classes.autoGroupColumnCell, classes.groupCell)}
			title={`${groupName} ${node.allChildrenCount ?? 0})`}
		>
			{props.multiSelect && (
				<CellSelectionCheckMark
					api={props.api}
					node={props.node}
					type={props.context.utilizeSquareSelectionCheckmarks ? 'square' : 'circle'}
					newCheckmark={props.context.utilizeNewCheckmarks}
				/>
			)}
			<div>{groupName}</div>
			{node.allChildrenCount !== null && <div>({node.allChildrenCount ?? 0})</div>}
		</div>
	)
}
