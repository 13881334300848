import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IPod } from 'src/interfaces/IPod'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'
import { ID3ClusterDiagramEdge, ID3ClusterDiagramGraph, ID3ClusterDiagramNode } from 'src/interfaces/IClusterDiagram'
import { e_ClusterDiagramLinkType } from 'src/enums/e_ClusterDiagramLinkType'
import { e_ClusterDiagramNodeType } from 'src/enums/e_ClusterDiagramNodeType'
import { Button, Menu, MenuItem, e_MenuItemType } from '@genusbiz/web-ui/controls'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { e_ButtonVariant } from 'src/enums/e_ButtonVariant'
import { defaultIcons } from 'src/consts/defaultIcons'
import { e_GraphOptions } from 'src/enums/e_GraphOptions'
import { useTranslation } from 'react-i18next'
import { operatorFrontendSelectors } from 'src/features/OperatorFrontend/duck/operatorFrontendSelectors'
import { e_Placement } from '@genusbiz/web-ui/enums/e_Placement'
import { useDiagramD3 } from './ClusterDiagramHelpers/useDiagramD3'
import { Callout } from '@genusbiz/web-ui/surfaces'
import { WorkloadControllerDescription } from '../Workloads/WorkloadControllers/WorkloadControllerDescription'
import { createStyle } from '@genusbiz/web-ui/theming'
import { PodDescription } from '../Workloads/Pods/PodDescription'

export const ClusterDiagram = (props: IClusterDiagramProps) => {
	const { d3Nodes, d3Edges } = props.graphData

	const svgRef = useRef<SVGSVGElement | null>(null)
	const dispatch = useDispatch()

	const [graphOptions, setGraphOptions] = useState(defaultGraphOptions)
	const [filteredNodes, setFilteredNodes] = useState<ID3ClusterDiagramNode[] | null>(null)
	const [filteredEdges, setFilteredEdges] = useState<ID3ClusterDiagramEdge[] | null>(null)
	const [showSettings, setShowSettings] = useState(false)
	const toggleSettings = () => setShowSettings(!showSettings)

	const needRefresh = useRef(true)
	const animateRefresh = useRef(false)

	const selectedScaleableResource = useSelector(operatorFrontendSelectors.selectSelectedWorkloadController)
	const selectedPods = useSelector(operatorFrontendSelectors.selectSelectedPods)

	const { t } = useTranslation()

	const setSelectedPods = (pods: IPod[]) => dispatch(operatorFrontendActions.setSelectedPods(pods))

	const setSelectedWorkloadController = (resource: IWorkloadController | null) =>
		dispatch(operatorFrontendActions.setSelectedWorkloadController(resource))

	const setOptions = (option: e_GraphOptions) => {
		setGraphOptions(
			isInOptions(option)
				? {
						...graphOptions,
						options: graphOptions.options.filter((opt) => opt !== option),
						edges: graphOptions.edges.filter((opt) => !optionToEdgeTypeDictionary[option].edges.includes(opt)),
						nodes: graphOptions.nodes.filter((opt) => !optionToEdgeTypeDictionary[option].nodes.includes(opt)),
					}
				: {
						...graphOptions,
						options: [...graphOptions.options, option],
						edges: graphOptions.edges.concat(optionToEdgeTypeDictionary[option].edges),
						nodes: graphOptions.nodes.concat(optionToEdgeTypeDictionary[option].nodes),
					}
		)
	}

	const isInOptions = (option: e_GraphOptions) => graphOptions.options.includes(option)

	const { containerElementRef, hoveredPodRef, hoveredPod, hoveredNodeRef, hoveredNode, isDragging } = useDiagramD3(
		filteredNodes,
		filteredEdges,
		selectedPods,
		setSelectedPods,
		selectedScaleableResource,
		setSelectedWorkloadController,
		needRefresh,
		animateRefresh
	)

	useEffect(() => {
		const newFilteredNodes = d3Nodes.filter((node) => graphOptions.nodes.includes(node.nodeType))
		const newFilteredEdges = d3Edges.filter((edge) => {
			const edgeTypeInOptions = graphOptions.edges.includes(edge.edgeType)
			const sourceInFilteredNodes = newFilteredNodes.includes(edge.source)
			const targetInFilteredNodes = newFilteredNodes.includes(edge.target)
			return edgeTypeInOptions && sourceInFilteredNodes && targetInFilteredNodes
		})
		setFilteredEdges((filteredEdges) => {
			if (filteredEdges && filteredEdges.length !== newFilteredEdges.length) {
				animateRefresh.current = true
			}
			return newFilteredEdges
		})
		setFilteredNodes((filteredNodes) => {
			if (filteredNodes && filteredNodes.length !== newFilteredNodes.length) {
				animateRefresh.current = true
			}
			return newFilteredNodes
		})
		needRefresh.current = true
	}, [graphOptions, d3Nodes, d3Edges])

	const menuItems: MenuItem[] = [
		{
			type: e_MenuItemType.action,
			name: t('GENERAL:SHOW_MUTUAL_SELECTION'),
			isChecked: isInOptions(e_GraphOptions.mutualSelection),
			onClick: () => setOptions(e_GraphOptions.mutualSelection),
		},
		{
			type: e_MenuItemType.action,
			name: t('GENERAL:SHOW_BROKEN_SELECTION'),
			isChecked: isInOptions(e_GraphOptions.brokenSelection),
			onClick: () => setOptions(e_GraphOptions.brokenSelection),
		},
		{
			type: e_MenuItemType.action,
			name: t('GENERAL:SHOW_NAMESPACE'),
			isChecked: isInOptions(e_GraphOptions.namespace),
			onClick: () => setOptions(e_GraphOptions.namespace),
		},
		{
			type: e_MenuItemType.action,
			name: t('GENERAL:SHOW_UNIVERSAL_EDGES'),
			isChecked: isInOptions(e_GraphOptions.universalEdges),
			onClick: () => setOptions(e_GraphOptions.universalEdges),
		},
	]
	const menuButtonRef = useRef<HTMLButtonElement>(null)

	return (
		<>
			<Callout
				anchorElement={hoveredPodRef as React.MutableRefObject<HTMLElement | null>}
				isOpen={!isDragging && !!hoveredPod}
				showBeak
				onClose={() => {}}
				placement={hoveredPod?.parent?.isInTopHalf ? e_Placement.bottom : e_Placement.top}
				padding={0}
			>
				{hoveredPod && <PodDescription pod={hoveredPod.pod} />}
			</Callout>
			<Callout
				anchorElement={hoveredNodeRef as React.MutableRefObject<HTMLElement | null>}
				isOpen={!isDragging && !hoveredPod && !!hoveredNode}
				showBeak
				onClose={() => {}}
				placement={hoveredNode?.isInTopHalf ? e_Placement.bottom : e_Placement.top}
				padding={0}
				key={hoveredNode?.deploymentName}
			>
				{hoveredNode?.workload && <WorkloadControllerDescription workloadController={hoveredNode.workload} />}
			</Callout>
			<div className={classes.clusterDiagramContainer}>
				<div ref={containerElementRef} style={{ width: '100%', height: '100%' }}>
					<svg>
						<defs />
						<g ref={svgRef}>
							<g id="edge-group" />
							<g id="node-group" />
						</g>
					</svg>
				</div>
				<div className={classes.rowContainer}>
					<div className={classes.buttonContainer}>
						<Button
							ref={menuButtonRef}
							icon={defaultIcons.menu}
							variant={e_ButtonVariant.default}
							size="medium"
							onClick={toggleSettings}
							style={{ padding: 10 }}
						/>
						<Menu
							open={showSettings}
							items={menuItems}
							anchorEl={menuButtonRef}
							placement={e_Placement.bottomEnd}
							onClose={() => setShowSettings(false)}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

const defaultGraphOptions = {
	edges: [
		e_ClusterDiagramLinkType.mutualSpecificSelection,
		e_ClusterDiagramLinkType.specificEgressNoIngress,
		e_ClusterDiagramLinkType.specificIngressNoEgress,
	],
	nodes: [e_ClusterDiagramNodeType.deployment, e_ClusterDiagramNodeType.statefulSet],
	options: [e_GraphOptions.mutualSelection, e_GraphOptions.brokenSelection],
}

const optionToEdgeTypeDictionary: IGraphOptionsDictionary = {
	MUTUAL_SELECTION: { edges: [e_ClusterDiagramLinkType.mutualSpecificSelection], nodes: [] },
	NAMESPACE: {
		edges: [e_ClusterDiagramLinkType.toNameSpace, e_ClusterDiagramLinkType.fromNameSpace],
		nodes: [e_ClusterDiagramNodeType.namespace],
	},
	BROKEN_SELECTION: {
		edges: [e_ClusterDiagramLinkType.specificEgressNoIngress, e_ClusterDiagramLinkType.specificIngressNoEgress],
		nodes: [],
	},
	SHOW_UNIVERSAL_EDGES: {
		edges: [
			e_ClusterDiagramLinkType.universalEgressSpecificIngress,
			e_ClusterDiagramLinkType.universalIngressSpecificEgress,
			e_ClusterDiagramLinkType.mutualUniversalSelection,
		],
		nodes: [],
	},
}

const classes = createStyle((theme) => ({
	buttonText: {
		marginLeft: '4px',
	},
	lowerButtonPosition: {
		marginTop: '8px',
	},
	buttonContainer: {
		marginTop: '8px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
		marginRight: '8px',
	},
	rowContainer: {
		height: 'fit-content',
		display: 'flex',
		flexDirection: 'row',
		position: 'fixed',
		right: 0,
	},
	clusterDiagramContainer: {
		display: 'flex',
		flexDirection: 'row',
		borderTop: '1px solid #dadada',
		height: '100%',
		overflow: 'hidden',
	},
	infoContainer: {
		position: 'fixed',
		right: 0,
		marginRight: '8px',
		bottom: 0,
		marginBottom: '8px',
	},
	labelTextContainer: {
		display: 'flex',
		flexDirection: 'column',
		border: '1px solid ' + theme.colors.panel.border,
		background: theme.colors.panel.background,
		padding: '8px 8px 8px 12px',
		marginBottom: '4px',
	},

	columnContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	innerRowContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	textRowContainerLeft: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
		width: '100px',
		marginLeft: '4px',
	},
	textRowContainerRight: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
		width: '200px',
	},
	textStyle: {
		fontSize: 16,
		paddingLeft: '4px',
	},
	labelHeader: {
		display: 'flex',
		fontSize: 20,
		marginBottom: '2px',
	},
	colorBox: {
		height: '18px',
		width: '18px',
		border: '1px solid black',
		borderRadius: '50%',
		margin: '1px',
	},
}))

interface IClusterDiagramProps {
	graphData: ID3ClusterDiagramGraph
}

interface IGraphOptionsDictionary {
	MUTUAL_SELECTION: {
		edges: e_ClusterDiagramLinkType[]
		nodes: e_ClusterDiagramNodeType[]
	}
	NAMESPACE: {
		edges: e_ClusterDiagramLinkType[]
		nodes: e_ClusterDiagramNodeType[]
	}
	BROKEN_SELECTION: {
		edges: e_ClusterDiagramLinkType[]
		nodes: e_ClusterDiagramNodeType[]
	}
	SHOW_UNIVERSAL_EDGES: {
		edges: e_ClusterDiagramLinkType[]
		nodes: e_ClusterDiagramNodeType[]
	}
}
