import { useCallback, useEffect, useRef, useState } from 'react'
import { getInternalFormatFromTicksMS } from './formatUtils'
import type { IUpdateInternalValue } from './useUpdateInternalValue'

export const useDropdownPanel = (
	inputRef: React.RefObject<HTMLInputElement>,
	updateInternalValue: IUpdateInternalValue,
	onClose?: () => void,
	dropdownOpen?: boolean
) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const openedByTouch = useRef(false)

	useEffect(() => {
		window.setTimeout(() => {
			dropdownOpen && setIsDropdownOpen(dropdownOpen)
		}, 0)
	}, [dropdownOpen])

	const openDropdown = useCallback(() => {
		setIsDropdownOpen(true)

		inputRef.current?.focus()
	}, [inputRef])

	const closeDropdown = useCallback(() => {
		setIsDropdownOpen(false)

		!openedByTouch.current && inputRef.current?.focus()

		onClose?.()
	}, [inputRef, onClose])

	const toggleDropdownPanel = () => {
		if (isDropdownOpen) {
			closeDropdown()
		} else {
			openDropdown()
		}
	}

	const onOptionClick = (value: number | null) => {
		const duration = value !== null ? getInternalFormatFromTicksMS(value) : undefined

		updateInternalValue(duration, { updateDisplayValue: true, raiseValueChanged: true })

		openedByTouch.current = false

		closeDropdown()
	}

	return { isDropdownOpen, openedByTouch, closeDropdown, toggleDropdownPanel, onOptionClick }
}
