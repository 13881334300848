/**
 * Available Operator API endpoints/services
 */
export enum e_OperatorApiService {
	config = 'config',
	metaModelManagerEnvVariables = 'metaModelManagerEnvVariables',
	probe = 'probe',
	k8sRuntime = 'k8sRuntime',
	workload = 'workload',
	authentication = 'authentication',
	account = 'account',
	endpoint = 'endpoint',
	dataPartition = 'datapartition',
	controlLoopReport = 'controlloopreport',
	environmentAvailability = 'environmentavailability',
	runtimeReporting = 'runtimereporting',
	resourceOverview = 'resourceoverview',
	kubernetesInfo = 'kubernetesinfo',
	workloadsResourcePlan = 'workloadsResourcePlan',
}
