import React from 'react'
import { IContainerStatus } from 'src/interfaces/IPod'
import { createStyle } from '@genusbiz/web-ui/theming'
import { e_PodPhaseName } from 'src/enums/e_PodPhase'
import { convertDateTime } from 'src/utils/dateTimeUtils'

const classes = createStyle({
	containerStatus: {
		display: 'flex',
		flexDirection: 'column',
		margin: '6px',
	},
	label: {
		width: '105px',
		display: 'inline-block',
	},
})

interface IProps {
	containerStatus: IContainerStatus
	phaseName: e_PodPhaseName
}
export const WorkloadDetailsContainerStatus = (props: IProps) => {
	const containerStatus = props.containerStatus

	return (
		<div className={classes.containerStatus} key={containerStatus.name}>
			<b>{containerStatus.name}</b>
			<div className={classes.containerStatus}>
				<div>
					<label className={classes.label}>State:</label>
					<span>
						{containerStatus.state.stateName}{' '}
						{containerStatus.state.exitCode !== 0 && `(${containerStatus.state.exitCode})`}
					</span>
				</div>
				{props.phaseName !== e_PodPhaseName.pending && (
					<div>
						<label className={classes.label}>Started:</label>
						<span>{containerStatus.state.startedAt && convertDateTime(containerStatus.state.startedAt, 'LT L')}</span>
					</div>
				)}
				{props.phaseName !== e_PodPhaseName.running && (
					<>
						<div>
							<label className={classes.label}>Message:</label>
							<span>{containerStatus.state.message}</span>
						</div>
						<div>
							<label className={classes.label}>Reason:</label>
							<span>{containerStatus.state.reason}</span>
						</div>
					</>
				)}
			</div>
			{containerStatus.lastState && (
				<div className={classes.containerStatus}>
					<div>
						<label className={classes.label}>Last State:</label>
						<span>
							{containerStatus.lastState.stateName}{' '}
							{containerStatus.lastState.exitCode !== 0 && `(${containerStatus.lastState.exitCode})`}
						</span>
					</div>
					{containerStatus.lastState.finishedAt && (
						<div>
							<label className={classes.label}>Finished at:</label>
							<span>{new Date(containerStatus.lastState.finishedAt).toUTCString()}</span>
						</div>
					)}
					<div>
						<label className={classes.label}>Message:</label>
						<span>{containerStatus.lastState.message}</span>
					</div>
					<div>
						<label className={classes.label}>Reason:</label>
						<span>{containerStatus.lastState.reason}</span>
					</div>
				</div>
			)}
		</div>
	)
}
