import { getTypedThemeUtils } from './themingManager'
import type { generateTheme } from './generateTheme'
import { create } from 'jss'
import preset from 'jss-preset-default'

export const createJss = create
export const jssPreset = preset

export { initTheming, cssVar } from './themingManager'

export type ITheme = ReturnType<typeof generateTheme>

export const {
	createStyle,
	createThemeVariation,
	createThemeVariations,
	getThemeVariation,
	useDynamicThemeVariation,
	useGetComputedThemeVariation,
} = getTypedThemeUtils<ITheme>()
