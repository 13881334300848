export enum e_Overflow {
	showScrollbars = 'showScrollbars',
	noScrollbars = 'noScrollbars',
	showEllipsis = 'showEllipsis',
}

export enum e_ChipOverflow {
	wrap = 'wrap',
	showScrollbars = 'scroll',
	summary = 'summary',
}
