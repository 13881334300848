import moment from 'moment'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { e_LifespanName } from 'src/enums/e_LifespanName'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { IEnvironmentAvailabilitySchedule } from 'src/interfaces/IAvailabilityWindow'
import { IEndpoint } from 'src/interfaces/IEndpoint'
import { operatorApi } from 'src/modules/operatorApi'
import { IOperatorRootState } from 'src/reducers'
import { getCurrentWeekday, getWeekDayTimeFromDuration } from 'src/utils/dateTimeUtils'
import { deserializeEnvironmentAvailabilitySchedule } from 'src/utils/deserializeEnvironmentAvailabilitySchedule'
import { generateGuid } from 'src/utils/generateGuid'
import { serializeEnvironmentAvailabilitySchedule } from 'src/utils/serializeEnvironmentAvailabilitySchedule'
import { translator } from 'src/utils/locale'
import { kubernetesActions } from './kubernetesActions'
import { kubernetesTypes } from './kubernetesTypes'
import { ISetAlwaysUp } from './IKubernetesActions'
import { AxiosError } from 'axios'

function* fetchEndpoints() {
	const endpoints: IEndpoint[] = yield call(operatorApi.fetchOperatorEndpoints)

	if (!endpoints || !endpoints.length) {
		return
	}

	yield put(kubernetesActions.setEndpoints(endpoints))
}

function* fetchEnvironmentAvailabilitySchedule() {
	try {
		const serializedEnvironmentAvailabilitySchedule: IEnvironmentAvailabilitySchedule = yield call(
			operatorApi.fetchEnvironmentAvailabilitySchedule
		)
		if (
			!serializedEnvironmentAvailabilitySchedule ||
			!serializedEnvironmentAvailabilitySchedule.environmentAvailabilities
		) {
			return
		}

		serializedEnvironmentAvailabilitySchedule.environmentAvailabilities.forEach((environmentAvailability) => {
			// Filter out old temporary windows. Only show continuous windows, or current temporary ones
			environmentAvailability.availabilityWindows = environmentAvailability.availabilityWindows.filter((window) => {
				return (
					window.windowType.lifespanName === e_LifespanName.continuous ||
					moment.utc().isBetween(window.windowType.effectiveFromUtc, window.windowType.effectiveToUtc)
				)
			})
		})

		const environmentAvailabilitySchedule = deserializeEnvironmentAvailabilitySchedule(
			serializedEnvironmentAvailabilitySchedule
		)
		yield put(kubernetesActions.setEnvironmentAvailabilitySchedule(environmentAvailabilitySchedule))
	} catch (err) {
		const error = err as Error
		yield put(modalManagerActions.showErrorModal(error))
	}
}

function* onChangeEnvironmentAvailabilitySchedule() {
	const state = (yield select()) as IOperatorRootState

	const serializedEnvironmentAvailabilitySchedule = (yield call(
		serializeEnvironmentAvailabilitySchedule,
		state
	)) as ReturnType<typeof serializeEnvironmentAvailabilitySchedule>

	try {
		yield call(operatorApi.saveEnvironmentAvailabilitySchedule, serializedEnvironmentAvailabilitySchedule)
	} catch (err) {
		let message = translator.translate('AVAILABILITY_WINDOW:FAILED_TO_SAVE') + ':\n'
		const error = err as AxiosError
		message += error.message
		error.message = message
		yield put(modalManagerActions.showErrorModal(error))
	}

	const environmentAvailabilitySchedule = deserializeEnvironmentAvailabilitySchedule(
		serializedEnvironmentAvailabilitySchedule
	)
	yield put(kubernetesActions.setEnvironmentAvailabilitySchedule(environmentAvailabilitySchedule))
}

function* onSetAlwaysUp(action: ISetAlwaysUp) {
	const state = (yield select()) as IOperatorRootState
	const selectedEnvironment = action.environmentOperatingType
	const currentEnvironmentSchedule =
		state.kubernetes.environmentAvailabilitySchedule.environmentAvailabilities[selectedEnvironment]

	if (!currentEnvironmentSchedule.isAlwaysUp) {
		// If no availability windows exists, add a temporary window so that the environment does not immediately go down
		if (currentEnvironmentSchedule.availabilityWindows.length < 1) {
			const from = {
				weekDayName: getCurrentWeekday(),
				time: moment.utc().hours() * 100 + moment.utc().minutes(), //now
				timezone: 'UTC',
			}
			const duration = 1800000 // Half an hour
			const durationInMinutes = duration / 1000 / 60

			const to = getWeekDayTimeFromDuration(from, durationInMinutes)

			yield put(
				kubernetesActions.addOrUpdateAvailabilityGroup(
					{
						comment: translator.translate('AVAILABILITY_WINDOW:TEMP_WINDOW'),
						fromDays: [from.weekDayName],
						fromTime: from.time,
						toDay: to.weekDayName,
						toTime: to.time,
						id: generateGuid(),
						enabled: true,
						windowIds: { [from.weekDayName]: generateGuid() },
						timezone: 'UTC',
						windowType: {
							lifespanName: e_LifespanName.temporary,
							effectiveFromUtc: moment.utc().format(),
							effectiveToUtc: moment.utc().add(duration).format(),
						},
					},
					selectedEnvironment
				)
			)
		} else {
			yield put(kubernetesActions.saveEnvironmentAvailabilitySchedule())
		}
	} else {
		yield put(kubernetesActions.saveEnvironmentAvailabilitySchedule())
	}
}

function* watcherSagas() {
	yield all([
		takeEvery(kubernetesTypes.FETCH_OPERATOR_ENDPOINTS, fetchEndpoints),
		takeEvery(kubernetesTypes.SET_ALWAYS_UP, onSetAlwaysUp),
		takeEvery(kubernetesTypes.FETCH_DEPLOYMENT_AVAILABILITY_SCHEDULE, fetchEnvironmentAvailabilitySchedule),
		takeEvery(kubernetesTypes.ADD_OR_UPDATE_AVAILABILITY_GROUP, onChangeEnvironmentAvailabilitySchedule),
		takeEvery(kubernetesTypes.DELETE_AVAILABILITY_GROUP, onChangeEnvironmentAvailabilitySchedule),
		takeEvery(kubernetesTypes.TURN_OFF_ENVIRONMENT, onChangeEnvironmentAvailabilitySchedule),
		takeEvery(kubernetesTypes.SAVE_ENVIRONMENT_AVAILABILITY_SCHEDULE, onChangeEnvironmentAvailabilitySchedule),
		takeEvery(kubernetesTypes.SET_DEPLOYMENT_AVAILABILITY_SCHEDULE_BUFFER, onChangeEnvironmentAvailabilitySchedule),
	])
}

export const kubernetesSagas = {
	watcherSagas,
	fetchEndpoints,
	fetchEnvironmentAvailabilitySchedule,
}
