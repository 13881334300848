import React, { useCallback, useRef, useState } from 'react'
import { createStyle } from '../../theming'
import { useResizeObserver } from '../utils/useResizeObserver'
import { useTranslation } from '../../translation'
import { GenericButton } from '../GenericButton'
import { useDataAttributes } from '../utils/useDataAttributes'

const classes = createStyle((theme) => ({
	pagination: {
		alignSelf: 'stretch',
		display: 'flex',
		justifyContent: 'space-between',
		height: 42,
		background: theme.colors.panel.background,
		color: theme.colors.body.text,
	},
	label: {
		background: 'transparent',
		border: '1px solid transparent',
		alignSelf: 'center',
		paddingRight: 10,
	},
	centerRegion: {
		display: 'flex',
	},
}))

interface IPaginationBar {
	objectCount: number
	objectFrom: number
	objectTo: number
	showTotal?: boolean
	firstEnabled?: boolean
	nextEnabled?: boolean
	previousEnabled?: boolean
	lastEnabled?: boolean
	style?: React.CSSProperties
	dataAttributes?: Record<string, string>
	onNextClick?: () => void
	onPreviousClick?: () => void
	onFirstClick?: () => void
	onLastClick?: () => void
}

export const PaginationBar = (props: IPaginationBar) => {
	const { objectCount, objectFrom, objectTo } = props

	const { tcvi, tcvvi } = useTranslation()

	const [showText, setShowText] = useState(true)

	const onResize = useCallback((rect: DOMRectReadOnly) => {
		setShowText(rect.width > 500 ? true : false)
	}, [])

	const rootElementRef = useRef<HTMLDivElement>(null)

	// Potential memory leak
	useResizeObserver(onResize, rootElementRef)

	const countLabel = tcvvi('GENERAL:SHOWING_OBJECT', Number(objectFrom + 1), objectTo)

	const dataAttributesPageBar = useDataAttributes(props.dataAttributes, 'pag-bar')
	const dataAttributesPageBarFirst = useDataAttributes(props.dataAttributes, 'pag-bar-first')
	const dataAttributesPageBarPrev = useDataAttributes(props.dataAttributes, 'pag-bar-prev')
	const dataAttributesPageBarNext = useDataAttributes(props.dataAttributes, 'pag-bar-next')
	const dataAttributesPageBarLast = useDataAttributes(props.dataAttributes, 'pag-bar-last')

	return (
		<div className={classes.pagination} ref={rootElementRef} style={props.style} {...dataAttributesPageBar}>
			<div className={classes.centerRegion}>
				{props.onFirstClick && (
					<GenericButton
						label={showText ? tcvi('GENERAL:FIRST') : undefined}
						onClick={props.onFirstClick}
						disabled={!props.firstEnabled}
						icon={'Fluent-DoubleChevronLeft'}
						screenTip={tcvi('GENERAL:FIRST')}
						largePadding
						dataAttributes={dataAttributesPageBarFirst}
					/>
				)}
				{props.onPreviousClick && (
					<GenericButton
						label={showText ? tcvi('GENERAL:PREVIOUS') : undefined}
						onClick={props.onPreviousClick}
						disabled={!props.previousEnabled}
						icon={'Fluent-ChevronLeft'}
						screenTip={tcvi('GENERAL:PREVIOUS')}
						largePadding
						dataAttributes={dataAttributesPageBarPrev}
					/>
				)}

				{props.onNextClick && (
					<GenericButton
						iconPosition={'right'}
						label={showText ? tcvi('GENERAL:NEXT') : undefined}
						onClick={props.onNextClick}
						disabled={!props.nextEnabled}
						icon={'Fluent-ChevronRight'}
						screenTip={tcvi('GENERAL:NEXT')}
						largePadding
						dataAttributes={dataAttributesPageBarNext}
					/>
				)}

				{props.onLastClick && (
					<GenericButton
						iconPosition={'right'}
						label={showText ? tcvi('GENERAL:LAST') : undefined}
						onClick={props.onLastClick}
						disabled={!props.lastEnabled}
						icon={'Fluent-DoubleChevronRight'}
						screenTip={tcvi('GENERAL:LAST')}
						largePadding
						dataAttributes={dataAttributesPageBarLast}
					/>
				)}
			</div>
			{objectCount > 0 && (
				<label className={classes.label}>
					{props.showTotal ? tcvvi('GENERAL:N_OF_M', countLabel, objectCount) : countLabel}
				</label>
			)}
		</div>
	)
}
