import moment from 'moment'
import { setFormattingLocale } from '@genusbiz/web-ui/controls/utils/setFormattingLocale'

export const guessFormattingCulture = () => {
	// Determine language and formatting locales
	let formattingCulture = (window.navigator.language as string | undefined) || 'en'

	// We treat 'en' as 'en-US'
	if (formattingCulture.toLowerCase() === 'en-us') {
		formattingCulture = 'en'
	}

	if (formattingCulture) {
		// Set moment + numeral locales
		moment.locale(formattingCulture)
		setFormattingLocale(formattingCulture)
	}

	// Return formattingCulture settings = current locale settings
	return formattingCulture as 'en' | 'nb'
}
