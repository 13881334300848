import { useCallback, useContext } from 'react'
import type { MenuItem } from '../../Menu'
import { SharedContextMenuContext } from '../../ContextMenu'

export const useOnContextMenu = (
	fn?: (id: string, columnId: string, contextMenuCallback: (contextMenuItems: MenuItem[]) => void) => void
) => {
	const contextMenuContext = useContext(SharedContextMenuContext)

	return useCallback(
		(e: React.MouseEvent, id: string | undefined, columnId: string | undefined) => {
			if (!id || !columnId) {
				return
			}

			const openContextMenu = contextMenuContext?.openContextMenu
			if (!openContextMenu) {
				return
			}

			const contextCallback = (items: MenuItem[]) => openContextMenu(e, items)

			fn?.(id, columnId, contextCallback)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fn]
	)
}
