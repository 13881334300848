import { useEventListener } from '../../utils/useEventListener'
import { MOBILE_WIDTH, type DialogDimension } from '../DialogTypes'
import throttle from 'lodash/throttle'
import { topWindow } from '../../utils/topWindow'
import { useState } from 'react'

export const useDialogResize = (
	propWidth: DialogDimension | undefined,
	propHeight: DialogDimension | undefined,
	fullScreen: React.MutableRefObject<boolean>,
	onResize?: (width: number, height: number) => void
) => {
	const [shallFitInnerSize, setShallFitInnerSize] = useState({ width: true, height: true })
	const allowHorizontalResize = propWidth !== 'auto'
	const allowVerticalResize = propHeight !== 'auto'

	const resizeOptions = {
		corner: allowVerticalResize && allowHorizontalResize,
		right: allowHorizontalResize,
		left: allowHorizontalResize,
		top: allowVerticalResize,
		bottom: allowVerticalResize,
	}

	const handleBrowserResize = throttle(() => {
		fullScreen.current = topWindow.innerWidth <= MOBILE_WIDTH
	}, 25)

	//Listening to resizing of the browser window
	useEventListener('resize', handleBrowserResize)

	const handleResize = (resizedWidth: number, resizedHeight: number) => {
		setShallFitInnerSize((prev) => ({ width: prev.width && !resizedWidth, height: prev.height && !resizedHeight }))

		onResize?.(resizedWidth, resizedHeight)
	}

	return { shallFitInnerSize, resizeOptions, handleResize }
}
