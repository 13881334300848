import { generateTheme } from '@genusbiz/web-ui/theming/generateTheme'
import { defaultColors } from './consts/defaultColors'
import { initTheming } from '@genusbiz/web-ui/theming/themingManager'
import { DEFAULT_FONT_FAMILY, DEFAULT_FONT_SIZE, defaultTypography } from '@genusbiz/web-ui/theming/defaults'
import { ITypographicStyleEntries } from '@genusbiz/web-ui/theming/themeProperties/typography'

const customColors = {
	primary: defaultColors.primary,
}
const webUITheme = generateTheme(customColors)
initTheming(webUITheme)

const sheetElement = document.createElement('style')
sheetElement.setAttribute('data-meta', 'genus-globals')
document.head.append(sheetElement)

function applyTypographyToStyleSheet(
	headerStyles = defaultTypography,
	fontFamily = DEFAULT_FONT_FAMILY,
	fontSize = DEFAULT_FONT_SIZE
) {
	document.body.style.fontFamily = fontFamily
	document.body.style.fontSize = `${fontSize}px`
	const styleSheet = sheetElement.sheet
	if (styleSheet) {
		for (let i = 1; i <= 6; i++) {
			const tag = `h${i}` as keyof Partial<ITypographicStyleEntries>
			const tg = headerStyles[tag]
			if (tg) {
				styleSheet.insertRule(`
					${tag} {
						font-size: ${tg.fontSize}px;
						font-weight: ${tg.fontWeight};
					}
				`)
			}
		}
	}
}

applyTypographyToStyleSheet()
