import React from 'react'
import { FieldContainer } from '@genusbiz/web-ui/surfaces'
import { e_TextAlignment } from '@genusbiz/web-ui/enums/e_TextAlignment'
import { Dropdown, TextInput } from '@genusbiz/web-ui/controls'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { useTranslation } from 'react-i18next'
import { IEditableDetailComponentProps } from '@genusbiz/web-ui/surfaces/EditableMasterDetail/types/detailConfigTypes'
import { e_EnvironmentType } from 'src/enums/e_EnvironmentType'
import { isValidUrl } from 'src/utils/urlUtils'

interface OperatorEndpointDetailEditProps extends IEditableDetailComponentProps {
	k8sRuntimes: IK8sRuntime[]
}

export const OperatorEndpointDetailEdit = (props: OperatorEndpointDetailEditProps) => {
	const { k8sRuntimes } = props
	const item = props.dataItem

	const { t } = useTranslation()

	const EMPTY_CONTENT = '---'

	const hostName = item['hostName'] as string

	const blueK8sRuntimeId = item['blueK8sRuntimeId'] as string
	const greenK8sRuntimeId = item['greenK8sRuntimeId'] as string

	const updateHostName = (hostName: string) => {
		const newValue = { hostName }
		if (!newValue || !isValidUrl(hostName)) {
			setError(t('ENDPOINTS:INVALID_HOSTNAME'))
			props.updateLocalChanges(newValue)
			return
		}
		setError(undefined)
		props.updateLocalChanges(newValue)
	}

	const updateBlueK8sRuntimeId = (blueK8sRuntimeId: string | null) => {
		const newValue = { blueK8sRuntimeId }
		props.updateLocalChanges(newValue)
	}

	const updateGreenK8sRuntimeId = (greenK8sRuntimeId: string | null) => {
		const newValue = { greenK8sRuntimeId }
		props.updateLocalChanges(newValue)
	}

	const [error, setError] = React.useState<string | undefined>(undefined)

	return (
		<FieldContainer groupId="all" data-gridname={'general-properties-grid'} padding={14} gap={4}>
			<TextInput
				label={t('ENDPOINTS:HOSTNAME')}
				textAlignment={e_TextAlignment.left}
				value={hostName}
				onChange={updateHostName}
				placeholder={EMPTY_CONTENT}
				required
				labelBold
				validationText={error}
				error={error !== undefined}
			/>
			<Dropdown
				label={t('ADMINISTRATION:BLUE_RUNTIME')}
				value={blueK8sRuntimeId}
				options={k8sRuntimes
					.filter((blueRuntime) => {
						return blueRuntime.environmentType === e_EnvironmentType.blue
					})
					.map((runtime) => {
						return { label: runtime.name, value: runtime.id }
					})}
				onChange={updateBlueK8sRuntimeId}
				allowNull
				labelBold
			/>
			<Dropdown
				label={t('ADMINISTRATION:GREEN_RUNTIME')}
				value={greenK8sRuntimeId}
				options={k8sRuntimes
					.filter((greenRuntime) => {
						return greenRuntime.environmentType === e_EnvironmentType.green
					})
					.map((runtime) => {
						return { label: runtime.name, value: runtime.id }
					})}
				onChange={updateGreenK8sRuntimeId}
				allowNull
				labelBold
			/>
		</FieldContainer>
	)
}
