import React, { useEffect, useState } from 'react'
import { ClusterDiagram } from './ClusterDiagram'
import { clusterDiagramDataToD3Format } from './ClusterDiagramHelpers/ClusterDiagramDataToD3Format'
import { ID3ClusterDiagramGraph } from 'src/interfaces/IClusterDiagram'
import { operatorApi } from 'src/modules/operatorApi'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'
import { IPod } from 'src/interfaces/IPod'

const ClusterDiagramContainer = (props: IClusterDiagramContainerProps) => {
	const { pods, workloadControllers, k8sRuntimeName } = props

	const [graphData, setGraphData] = useState<ID3ClusterDiagramGraph | null>(null)

	const [newWorkloadsFetched, setNewWorkloadsFetched] = useState<boolean>(false)
	const [previousK8sRuntimeName, setPreviousK8sRuntimeName] = useState('')

	useEffect(() => {
		setNewWorkloadsFetched(false)
	}, [pods, workloadControllers])

	useEffect(() => {
		if (!newWorkloadsFetched && pods && workloadControllers && k8sRuntimeName) {
			operatorApi
				.fetchClusterDiagramNodes(k8sRuntimeName)
				.then((nodes) => {
					setGraphData(clusterDiagramDataToD3Format(nodes, pods, workloadControllers, k8sRuntimeName))
					setNewWorkloadsFetched(true)
					setPreviousK8sRuntimeName(k8sRuntimeName)
				})
				.catch((error) => {
					console.log(error) // eslint-disable-line no-console
				})
		}
	}, [k8sRuntimeName, previousK8sRuntimeName, pods, workloadControllers, newWorkloadsFetched])

	if (pods && workloadControllers && previousK8sRuntimeName === k8sRuntimeName && graphData) {
		return <ClusterDiagram graphData={graphData} />
	}

	return null
}

interface IClusterDiagramContainerProps {
	pods: IPod[]
	workloadControllers: IWorkloadController[]
	k8sRuntimeName: string | null
}

export default ClusterDiagramContainer
