import { Store } from '@reduxjs/toolkit'
import { IOperatorRootState } from 'src/reducers'

interface IStore extends Store {
	getState: () => IOperatorRootState
}

let store: IStore

export const registerStore = (storeToRegister: IStore) => {
	if (!storeToRegister) {
		throw new Error('registerStore: storeToRegister is required')
	}

	store = storeToRegister
}

export const getStore = () => {
	if (!store) {
		throw new Error('getStore: store not registered')
	}

	return store
}
