import type { IKeyboardShortcut } from './IKeyboardShortcut'

export const isEqualKeyboardShortcut = (keyEvent1: IKeyboardShortcut, keyEvent2: IKeyboardShortcut) => {
	return (
		keyEvent1.keyCode === keyEvent2.keyCode &&
		(keyEvent1.ctrlOrMetaKey === true) === (keyEvent2.ctrlOrMetaKey === true) &&
		(keyEvent1.shiftKey === true) === (keyEvent2.shiftKey === true) &&
		(keyEvent1.altKey === true) === (keyEvent2.altKey === true)
	)
}
