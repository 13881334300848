import React from 'react'
import { Icon } from '../../../Icon'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils'
import { CELL_PADDING } from '../../consts'

interface ICellIconProps {
	displayName: string
	hideLabel: boolean
	iconName: IIconNames | undefined
	iconPlacement: 'before' | 'after' | undefined
}

export const ColumnHeaderContent = (props: ICellIconProps) => {
	const { iconName } = props

	if (!iconName) {
		return (
			<>
				{!props.hideLabel && <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.displayName}</span>}
			</>
		)
	}

	const style = props.iconPlacement === 'before' ? { paddingRight: CELL_PADDING } : { paddingLeft: CELL_PADDING }

	return (
		<>
			{props.iconPlacement === 'before' && <Icon size="small" iconName={iconName} style={style} />}
			{!props.hideLabel && <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.displayName}</span>}
			{props.iconPlacement === 'after' && <Icon size="small" iconName={iconName} style={style} />}
		</>
	)
}
