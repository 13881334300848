import React from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'

const classes = createStyle((theme) => ({
	toggleButtonGroup: {
		display: 'flex',
		borderRadius: theme.borderRadius,
		borderWidth: 1,
		background: 'white',
		borderStyle: 'solid',
		borderColor: theme.colors.button.border,
	},
}))

interface IToggleButtonGroupProps {
	children: React.ReactElement | React.ReactElement[]
	className?: string
}

export const ToggleButtonGroup = (props: IToggleButtonGroupProps) => {
	return <div className={classNames(classes.toggleButtonGroup, props.className)}>{props.children}</div>
}
