import React, { useCallback, useMemo, useState } from 'react'
import { SharedContextMenu } from './SharedContextMenu'
import type { IContextMenuContext } from './IContextMenu'
import type { MenuItem } from '../Menu/MenuItem.types'

interface ISharedContextMenuProviderProps {
	children?: React.ReactNode | React.ReactNode[]
}

interface ICursorPos {
	x: number
	y: number
}
export const SharedContextMenuContext = React.createContext<Partial<IContextMenuContext>>({})

export const SharedContextMenuProvider = (props: ISharedContextMenuProviderProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const [position, setPosition] = useState<ICursorPos>()
	const [menuContent, setMenuContent] = useState<MenuItem[]>()

	const openContextMenu = useCallback((e: React.MouseEvent | MouseEvent, content?: MenuItem[]) => {
		e.preventDefault()

		setMenuContent(content)
		setPosition({
			x: e.clientX,
			y: e.clientY,
		})
		setIsOpen(true)
	}, [])

	const closeContextMenu = useCallback(() => {
		setIsOpen(false)
		setMenuContent(undefined)
	}, [])

	const providerValue: IContextMenuContext = useMemo(() => {
		return {
			contextMenuIsOpen: isOpen,
			contextMenuContent: menuContent,
			position: position,
			openContextMenu: openContextMenu,
			closeContextMenu: closeContextMenu,
		}
	}, [isOpen, menuContent, position, openContextMenu, closeContextMenu])

	return (
		<SharedContextMenuContext.Provider value={providerValue}>
			{props.children}
			<SharedContextMenu />
		</SharedContextMenuContext.Provider>
	)
}

export const SharedContextMenuConsumer = SharedContextMenuContext.Consumer
