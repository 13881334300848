export enum kubernetesTypes {
	FETCH_K8S_RUNTIMES = 'KUBERNETES/FETCH_K8S_RUNTIMES',
	FETCH_OPERATOR_ENDPOINTS = 'KUBERNETES/FETCH_OPERATOR_ENDPOINTS',
	FETCH_DEPLOYMENT_AVAILABILITY_SCHEDULE = 'KUBERNETES/FETCH_DEPLOYMENT_AVAILABILITY_SCHEDULE',
	FETCH_CLUSTER_EVENTS = 'KUBERNETES/FETCH_CLUSTER_EVENTS',
	FETCH_OPERATOR_ERROR_LOG = 'KUBERNETES/FETCH_OPERATOR_ERROR_LOG',
	SET_RUNTIME_CONFIGURATIONS = 'KUBERNETES/SET_RUNTIME_CONFIGURATIONS',
	SET_ENDPOINTS_CONFIGURATIONS = 'KUBERNETES/SET_ENDPOINTS_CONFIGURATIONS',
	SET_ALWAYS_UP = 'KUBERNETES/SET_ALWAYS_UP',
	SET_ENVIRONMENT_RESOURCE_OVERVIEW = 'KUBERNETES/SET_ENVIRONMENT_RESOURCE_OVERVIEW',
	SET_DEPLOYMENT_AVAILABILITY_SCHEDULE = 'KUBERNETES/SET_DEPLOYMENT_AVAILABILITY_SCHEDULE',
	SET_DEPLOYMENT_AVAILABILITY_SCHEDULE_BUFFER = 'KUBERNETES/SET_DEPLOYMENT_AVAILABILITY_SCHEDULE_BUFFER',
	SET_SAVED_DEPLOYMENT_AVAILABILITY_SCHEDULE = 'KUBERNETES/SET_SAVED_DEPLOYMENT_AVAILABILITY_SCHEDULE',
	ADD_OR_UPDATE_AVAILABILITY_GROUP = 'KUBERNETES/ADD_OR_UPDATE_AVAILABILITY_GROUP',
	DELETE_AVAILABILITY_GROUP = 'KUBERNETES/DELETE_AVAILABILITY_GROUP',
	TURN_OFF_ENVIRONMENT = 'KUBERNETES/TURN_OFF_ENVIRONMENT',
	SAVE_ENVIRONMENT_AVAILABILITY_SCHEDULE = 'KUBERNETES/ON_CHANGE_ENVIRONMENT_AVAILABILITY_SCHEDULE',
	SET_CLUSTER_EVENTS = 'KUBERNETES/SET_CLUSTER_EVENTS',
}
