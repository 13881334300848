import { FlagsLanguagesFlagAfganistan } from './FlagsLanguagesFlagAfganistan'
import { FlagsLanguagesFlagAlandIsland } from './FlagsLanguagesFlagAlandIsland'
import { FlagsLanguagesFlagAlbania } from './FlagsLanguagesFlagAlbania'
import { FlagsLanguagesFlagAlgeria } from './FlagsLanguagesFlagAlgeria'
import { FlagsLanguagesFlagAmericanSamoa } from './FlagsLanguagesFlagAmericanSamoa'
import { FlagsLanguagesFlagAndorra } from './FlagsLanguagesFlagAndorra'
import { FlagsLanguagesFlagAngola } from './FlagsLanguagesFlagAngola'
import { FlagsLanguagesFlagAntartica } from './FlagsLanguagesFlagAntartica'
import { FlagsLanguagesFlagAntiguaBarbuda } from './FlagsLanguagesFlagAntiguaBarbuda'
import { FlagsLanguagesFlagArabEmirates } from './FlagsLanguagesFlagArabEmirates'
import { FlagsLanguagesFlagArgentina } from './FlagsLanguagesFlagArgentina'
import { FlagsLanguagesFlagArmenia } from './FlagsLanguagesFlagArmenia'
import { FlagsLanguagesFlagAruba } from './FlagsLanguagesFlagAruba'
import { FlagsLanguagesFlagAustralia } from './FlagsLanguagesFlagAustralia'
import { FlagsLanguagesFlagAustria } from './FlagsLanguagesFlagAustria'
import { FlagsLanguagesFlagAzerbaijan } from './FlagsLanguagesFlagAzerbaijan'
import { FlagsLanguagesFlagBahamas } from './FlagsLanguagesFlagBahamas'
import { FlagsLanguagesFlagBahrain } from './FlagsLanguagesFlagBahrain'
import { FlagsLanguagesFlagBangladesh } from './FlagsLanguagesFlagBangladesh'
import { FlagsLanguagesFlagBarbados } from './FlagsLanguagesFlagBarbados'
import { FlagsLanguagesFlagBelarus } from './FlagsLanguagesFlagBelarus'
import { FlagsLanguagesFlagBelgium } from './FlagsLanguagesFlagBelgium'
import { FlagsLanguagesFlagBenin } from './FlagsLanguagesFlagBenin'
import { FlagsLanguagesFlagBermuda } from './FlagsLanguagesFlagBermuda'
import { FlagsLanguagesFlagBolivia } from './FlagsLanguagesFlagBolivia'
import { FlagsLanguagesFlagBosniaHerzegovina } from './FlagsLanguagesFlagBosniaHerzegovina'
import { FlagsLanguagesFlagBotswana } from './FlagsLanguagesFlagBotswana'
import { FlagsLanguagesFlagBrazil } from './FlagsLanguagesFlagBrazil'
import { FlagsLanguagesFlagBritishVirginIsland } from './FlagsLanguagesFlagBritishVirginIsland'
import { FlagsLanguagesFlagBulgaria } from './FlagsLanguagesFlagBulgaria'
import { FlagsLanguagesFlagBurkinaFaso } from './FlagsLanguagesFlagBurkinaFaso'
import { FlagsLanguagesFlagBurma } from './FlagsLanguagesFlagBurma'
import { FlagsLanguagesFlagBurundi } from './FlagsLanguagesFlagBurundi'
import { FlagsLanguagesFlagCambodia } from './FlagsLanguagesFlagCambodia'
import { FlagsLanguagesFlagCameroon } from './FlagsLanguagesFlagCameroon'
import { FlagsLanguagesFlagCanada } from './FlagsLanguagesFlagCanada'
import { FlagsLanguagesFlagCanaryIslands } from './FlagsLanguagesFlagCanaryIslands'
import { FlagsLanguagesFlagCapeVerde } from './FlagsLanguagesFlagCapeVerde'
import { FlagsLanguagesFlagCaymanIsland } from './FlagsLanguagesFlagCaymanIsland'
import { FlagsLanguagesFlagCentralAfricanRepublic } from './FlagsLanguagesFlagCentralAfricanRepublic'
import { FlagsLanguagesFlagChad } from './FlagsLanguagesFlagChad'
import { FlagsLanguagesFlagChile } from './FlagsLanguagesFlagChile'
import { FlagsLanguagesFlagChina } from './FlagsLanguagesFlagChina'
import { FlagsLanguagesFlagColombia } from './FlagsLanguagesFlagColombia'
import { FlagsLanguagesFlagComoros } from './FlagsLanguagesFlagComoros'
import { FlagsLanguagesFlagCongo } from './FlagsLanguagesFlagCongo'
import { FlagsLanguagesFlagCookIslands } from './FlagsLanguagesFlagCookIslands'
import { FlagsLanguagesFlagCostaRica } from './FlagsLanguagesFlagCostaRica'
import { FlagsLanguagesFlagCroatia } from './FlagsLanguagesFlagCroatia'
import { FlagsLanguagesFlagCuba } from './FlagsLanguagesFlagCuba'
import { FlagsLanguagesFlagCuracao } from './FlagsLanguagesFlagCuracao'
import { FlagsLanguagesFlagCyprus } from './FlagsLanguagesFlagCyprus'
import { FlagsLanguagesFlagCzechRepublic } from './FlagsLanguagesFlagCzechRepublic'
import { FlagsLanguagesFlagDemocraticCongo } from './FlagsLanguagesFlagDemocraticCongo'
import { FlagsLanguagesFlagDenmark } from './FlagsLanguagesFlagDenmark'
import { FlagsLanguagesFlagDjibouti } from './FlagsLanguagesFlagDjibouti'
import { FlagsLanguagesFlagDominica } from './FlagsLanguagesFlagDominica'
import { FlagsLanguagesFlagDominicanRepublic } from './FlagsLanguagesFlagDominicanRepublic'
import { FlagsLanguagesFlagEastTimor } from './FlagsLanguagesFlagEastTimor'
import { FlagsLanguagesFlagEcuador } from './FlagsLanguagesFlagEcuador'
import { FlagsLanguagesFlagEgypt } from './FlagsLanguagesFlagEgypt'
import { FlagsLanguagesFlagEngland } from './FlagsLanguagesFlagEngland'
import { FlagsLanguagesFlagEquatorialGuinea } from './FlagsLanguagesFlagEquatorialGuinea'
import { FlagsLanguagesFlagEritrea } from './FlagsLanguagesFlagEritrea'
import { FlagsLanguagesFlagEstonia } from './FlagsLanguagesFlagEstonia'
import { FlagsLanguagesFlagEthiopia } from './FlagsLanguagesFlagEthiopia'
import { FlagsLanguagesFlagEurope } from './FlagsLanguagesFlagEurope'
import { FlagsLanguagesFlagFalklandIsland } from './FlagsLanguagesFlagFalklandIsland'
import { FlagsLanguagesFlagFaroeIslands } from './FlagsLanguagesFlagFaroeIslands'
import { FlagsLanguagesFlagFiji } from './FlagsLanguagesFlagFiji'
import { FlagsLanguagesFlagFinland } from './FlagsLanguagesFlagFinland'
import { FlagsLanguagesFlagFrance } from './FlagsLanguagesFlagFrance'
import { FlagsLanguagesFlagFrenchGuiana } from './FlagsLanguagesFlagFrenchGuiana'
import { FlagsLanguagesFlagFrenchPolynesia } from './FlagsLanguagesFlagFrenchPolynesia'
import { FlagsLanguagesFlagFrenchSouthernAntarctic } from './FlagsLanguagesFlagFrenchSouthernAntarctic'
import { FlagsLanguagesFlagGabon } from './FlagsLanguagesFlagGabon'
import { FlagsLanguagesFlagGambia } from './FlagsLanguagesFlagGambia'
import { FlagsLanguagesFlagGeorgia } from './FlagsLanguagesFlagGeorgia'
import { FlagsLanguagesFlagGermany } from './FlagsLanguagesFlagGermany'
import { FlagsLanguagesFlagGhana } from './FlagsLanguagesFlagGhana'
import { FlagsLanguagesFlagGibraltar } from './FlagsLanguagesFlagGibraltar'
import { FlagsLanguagesFlagGreece } from './FlagsLanguagesFlagGreece'
import { FlagsLanguagesFlagGreenland } from './FlagsLanguagesFlagGreenland'
import { FlagsLanguagesFlagGrenada } from './FlagsLanguagesFlagGrenada'
import { FlagsLanguagesFlagGuadeloupe } from './FlagsLanguagesFlagGuadeloupe'
import { FlagsLanguagesFlagGuam } from './FlagsLanguagesFlagGuam'
import { FlagsLanguagesFlagGuatemala } from './FlagsLanguagesFlagGuatemala'
import { FlagsLanguagesFlagGuernsey } from './FlagsLanguagesFlagGuernsey'
import { FlagsLanguagesFlagGuineaBissau } from './FlagsLanguagesFlagGuineaBissau'
import { FlagsLanguagesFlagGuinea } from './FlagsLanguagesFlagGuinea'
import { FlagsLanguagesFlagGuyana } from './FlagsLanguagesFlagGuyana'
import { FlagsLanguagesFlagHaiti } from './FlagsLanguagesFlagHaiti'
import { FlagsLanguagesFlagHonduras } from './FlagsLanguagesFlagHonduras'
import { FlagsLanguagesFlagHongKong } from './FlagsLanguagesFlagHongKong'
import { FlagsLanguagesFlagHungary } from './FlagsLanguagesFlagHungary'
import { FlagsLanguagesFlagIceland } from './FlagsLanguagesFlagIceland'
import { FlagsLanguagesFlagIndia } from './FlagsLanguagesFlagIndia'
import { FlagsLanguagesFlagIndonesia } from './FlagsLanguagesFlagIndonesia'
import { FlagsLanguagesFlagIran } from './FlagsLanguagesFlagIran'
import { FlagsLanguagesFlagIraq } from './FlagsLanguagesFlagIraq'
import { FlagsLanguagesFlagIreland } from './FlagsLanguagesFlagIreland'
import { FlagsLanguagesFlagIsleOfMan } from './FlagsLanguagesFlagIsleOfMan'
import { FlagsLanguagesFlagIsrael } from './FlagsLanguagesFlagIsrael'
import { FlagsLanguagesFlagItaly } from './FlagsLanguagesFlagItaly'
import { FlagsLanguagesFlagIvoryCoast } from './FlagsLanguagesFlagIvoryCoast'
import { FlagsLanguagesFlagJamaica } from './FlagsLanguagesFlagJamaica'
import { FlagsLanguagesFlagJapan } from './FlagsLanguagesFlagJapan'
import { FlagsLanguagesFlagJersey } from './FlagsLanguagesFlagJersey'
import { FlagsLanguagesFlagJordan } from './FlagsLanguagesFlagJordan'
import { FlagsLanguagesFlagKazakhstan } from './FlagsLanguagesFlagKazakhstan'
import { FlagsLanguagesFlagKenya } from './FlagsLanguagesFlagKenya'
import { FlagsLanguagesFlagKiribati } from './FlagsLanguagesFlagKiribati'
import { FlagsLanguagesFlagKosovo } from './FlagsLanguagesFlagKosovo'
import { FlagsLanguagesFlagKuwait } from './FlagsLanguagesFlagKuwait'
import { FlagsLanguagesFlagKyrgyzstan } from './FlagsLanguagesFlagKyrgyzstan'
import { FlagsLanguagesFlagLaos } from './FlagsLanguagesFlagLaos'
import { FlagsLanguagesFlagLatvia } from './FlagsLanguagesFlagLatvia'
import { FlagsLanguagesFlagLebanon } from './FlagsLanguagesFlagLebanon'
import { FlagsLanguagesFlagLesotho } from './FlagsLanguagesFlagLesotho'
import { FlagsLanguagesFlagLiberia } from './FlagsLanguagesFlagLiberia'
import { FlagsLanguagesFlagLibya } from './FlagsLanguagesFlagLibya'
import { FlagsLanguagesFlagLiechtenstein } from './FlagsLanguagesFlagLiechtenstein'
import { FlagsLanguagesFlagLithuania } from './FlagsLanguagesFlagLithuania'
import { FlagsLanguagesFlagLuxembourg } from './FlagsLanguagesFlagLuxembourg'
import { FlagsLanguagesFlagMacau } from './FlagsLanguagesFlagMacau'
import { FlagsLanguagesFlagMacedonia } from './FlagsLanguagesFlagMacedonia'
import { FlagsLanguagesFlagMadagascar } from './FlagsLanguagesFlagMadagascar'
import { FlagsLanguagesFlagMalawi } from './FlagsLanguagesFlagMalawi'
import { FlagsLanguagesFlagMalaysia } from './FlagsLanguagesFlagMalaysia'
import { FlagsLanguagesFlagMaldives } from './FlagsLanguagesFlagMaldives'
import { FlagsLanguagesFlagMali } from './FlagsLanguagesFlagMali'
import { FlagsLanguagesFlagMalta } from './FlagsLanguagesFlagMalta'
import { FlagsLanguagesFlagMarshallIslands } from './FlagsLanguagesFlagMarshallIslands'
import { FlagsLanguagesFlagMartinique } from './FlagsLanguagesFlagMartinique'
import { FlagsLanguagesFlagMauritania } from './FlagsLanguagesFlagMauritania'
import { FlagsLanguagesFlagMauritius } from './FlagsLanguagesFlagMauritius'
import { FlagsLanguagesFlagMexico } from './FlagsLanguagesFlagMexico'
import { FlagsLanguagesFlagMicronesia } from './FlagsLanguagesFlagMicronesia'
import { FlagsLanguagesFlagMoldova } from './FlagsLanguagesFlagMoldova'
import { FlagsLanguagesFlagMonaco } from './FlagsLanguagesFlagMonaco'
import { FlagsLanguagesFlagMongolia } from './FlagsLanguagesFlagMongolia'
import { FlagsLanguagesFlagMontenegro } from './FlagsLanguagesFlagMontenegro'
import { FlagsLanguagesFlagMontserrat } from './FlagsLanguagesFlagMontserrat'
import { FlagsLanguagesFlagMorocco } from './FlagsLanguagesFlagMorocco'
import { FlagsLanguagesFlagMozambique } from './FlagsLanguagesFlagMozambique'
import { FlagsLanguagesFlagMyanmar } from './FlagsLanguagesFlagMyanmar'
import { FlagsLanguagesFlagNamibia } from './FlagsLanguagesFlagNamibia'
import { FlagsLanguagesFlagNauruIsland } from './FlagsLanguagesFlagNauruIsland'
import { FlagsLanguagesFlagNepal } from './FlagsLanguagesFlagNepal'
import { FlagsLanguagesFlagNetherlandsAntilles } from './FlagsLanguagesFlagNetherlandsAntilles'
import { FlagsLanguagesFlagNetherlands } from './FlagsLanguagesFlagNetherlands'
import { FlagsLanguagesFlagNewCaledonia } from './FlagsLanguagesFlagNewCaledonia'
import { FlagsLanguagesFlagNewZealand } from './FlagsLanguagesFlagNewZealand'
import { FlagsLanguagesFlagNicaragua } from './FlagsLanguagesFlagNicaragua'
import { FlagsLanguagesFlagNiger } from './FlagsLanguagesFlagNiger'
import { FlagsLanguagesFlagNigeria } from './FlagsLanguagesFlagNigeria'
import { FlagsLanguagesFlagNiue } from './FlagsLanguagesFlagNiue'
import { FlagsLanguagesFlagNorfolkIsland } from './FlagsLanguagesFlagNorfolkIsland'
import { FlagsLanguagesFlagNorthKorea } from './FlagsLanguagesFlagNorthKorea'
import { FlagsLanguagesFlagNorway } from './FlagsLanguagesFlagNorway'
import { FlagsLanguagesFlagOman } from './FlagsLanguagesFlagOman'
import { FlagsLanguagesFlagPakistan } from './FlagsLanguagesFlagPakistan'
import { FlagsLanguagesFlagPalau } from './FlagsLanguagesFlagPalau'
import { FlagsLanguagesFlagPalestine } from './FlagsLanguagesFlagPalestine'
import { FlagsLanguagesFlagPanama } from './FlagsLanguagesFlagPanama'
import { FlagsLanguagesFlagPapuaNewGuinea } from './FlagsLanguagesFlagPapuaNewGuinea'
import { FlagsLanguagesFlagParaguay } from './FlagsLanguagesFlagParaguay'
import { FlagsLanguagesFlagPeru } from './FlagsLanguagesFlagPeru'
import { FlagsLanguagesFlagPhilipines } from './FlagsLanguagesFlagPhilipines'
import { FlagsLanguagesFlagPoland } from './FlagsLanguagesFlagPoland'
import { FlagsLanguagesFlagPortugal } from './FlagsLanguagesFlagPortugal'
import { FlagsLanguagesFlagPuertoRico } from './FlagsLanguagesFlagPuertoRico'
import { FlagsLanguagesFlagQatar } from './FlagsLanguagesFlagQatar'
import { FlagsLanguagesFlagRomania } from './FlagsLanguagesFlagRomania'
import { FlagsLanguagesFlagRussia } from './FlagsLanguagesFlagRussia'
import { FlagsLanguagesFlagRwanda } from './FlagsLanguagesFlagRwanda'
import { FlagsLanguagesFlagSaintKittsNevis } from './FlagsLanguagesFlagSaintKittsNevis'
import { FlagsLanguagesFlagSaintLucia } from './FlagsLanguagesFlagSaintLucia'
import { FlagsLanguagesFlagSaintVincentGrenadines } from './FlagsLanguagesFlagSaintVincentGrenadines'
import { FlagsLanguagesFlagSalvador } from './FlagsLanguagesFlagSalvador'
import { FlagsLanguagesFlagSamoa } from './FlagsLanguagesFlagSamoa'
import { FlagsLanguagesFlagSanMarino } from './FlagsLanguagesFlagSanMarino'
import { FlagsLanguagesFlagSaoTomePrincipe } from './FlagsLanguagesFlagSaoTomePrincipe'
import { FlagsLanguagesFlagSaudiArabia } from './FlagsLanguagesFlagSaudiArabia'
import { FlagsLanguagesFlagSenegal } from './FlagsLanguagesFlagSenegal'
import { FlagsLanguagesFlagSerbia } from './FlagsLanguagesFlagSerbia'
import { FlagsLanguagesFlagSeychelles } from './FlagsLanguagesFlagSeychelles'
import { FlagsLanguagesFlagSierraLeone } from './FlagsLanguagesFlagSierraLeone'
import { FlagsLanguagesFlagSingapore } from './FlagsLanguagesFlagSingapore'
import { FlagsLanguagesFlagSlovakia } from './FlagsLanguagesFlagSlovakia'
import { FlagsLanguagesFlagSlovenia } from './FlagsLanguagesFlagSlovenia'
import { FlagsLanguagesFlagSolomonIslands } from './FlagsLanguagesFlagSolomonIslands'
import { FlagsLanguagesFlagSomalia } from './FlagsLanguagesFlagSomalia'
import { FlagsLanguagesFlagSouthAfrica } from './FlagsLanguagesFlagSouthAfrica'
import { FlagsLanguagesFlagSouthKorea } from './FlagsLanguagesFlagSouthKorea'
import { FlagsLanguagesFlagSouthSudan } from './FlagsLanguagesFlagSouthSudan'
import { FlagsLanguagesFlagSpain } from './FlagsLanguagesFlagSpain'
import { FlagsLanguagesFlagSriLanka } from './FlagsLanguagesFlagSriLanka'
import { FlagsLanguagesFlagSudan } from './FlagsLanguagesFlagSudan'
import { FlagsLanguagesFlagSuriname } from './FlagsLanguagesFlagSuriname'
import { FlagsLanguagesFlagSwaziland } from './FlagsLanguagesFlagSwaziland'
import { FlagsLanguagesFlagSweden } from './FlagsLanguagesFlagSweden'
import { FlagsLanguagesFlagSwitzerlandSquare } from './FlagsLanguagesFlagSwitzerlandSquare'
import { FlagsLanguagesFlagSwitzerland } from './FlagsLanguagesFlagSwitzerland'
import { FlagsLanguagesFlagSyria } from './FlagsLanguagesFlagSyria'
import { FlagsLanguagesFlagTaiwan } from './FlagsLanguagesFlagTaiwan'
import { FlagsLanguagesFlagTajikistan } from './FlagsLanguagesFlagTajikistan'
import { FlagsLanguagesFlagTanzania } from './FlagsLanguagesFlagTanzania'
import { FlagsLanguagesFlagThailand } from './FlagsLanguagesFlagThailand'
import { FlagsLanguagesFlagTogo } from './FlagsLanguagesFlagTogo'
import { FlagsLanguagesFlagTonga } from './FlagsLanguagesFlagTonga'
import { FlagsLanguagesFlagTrinidadTobago } from './FlagsLanguagesFlagTrinidadTobago'
import { FlagsLanguagesFlagTunisia } from './FlagsLanguagesFlagTunisia'
import { FlagsLanguagesFlagTurkey } from './FlagsLanguagesFlagTurkey'
import { FlagsLanguagesFlagTurkmenistan } from './FlagsLanguagesFlagTurkmenistan'
import { FlagsLanguagesFlagTuvalu } from './FlagsLanguagesFlagTuvalu'
import { FlagsLanguagesFlagUganda } from './FlagsLanguagesFlagUganda'
import { FlagsLanguagesFlagUkraine } from './FlagsLanguagesFlagUkraine'
import { FlagsLanguagesFlagUnitedKingdom } from './FlagsLanguagesFlagUnitedKingdom'
import { FlagsLanguagesFlagUnitedNations } from './FlagsLanguagesFlagUnitedNations'
import { FlagsLanguagesFlagUnitedStates } from './FlagsLanguagesFlagUnitedStates'
import { FlagsLanguagesFlagUruguay } from './FlagsLanguagesFlagUruguay'
import { FlagsLanguagesFlagUzbekistan } from './FlagsLanguagesFlagUzbekistan'
import { FlagsLanguagesFlagVanuatu } from './FlagsLanguagesFlagVanuatu'
import { FlagsLanguagesFlagVatican } from './FlagsLanguagesFlagVatican'
import { FlagsLanguagesFlagVenezuela } from './FlagsLanguagesFlagVenezuela'
import { FlagsLanguagesFlagVietnam } from './FlagsLanguagesFlagVietnam'
import { FlagsLanguagesFlagWallisAndFutuna } from './FlagsLanguagesFlagWallisAndFutuna'
import { FlagsLanguagesFlagWesternSahara } from './FlagsLanguagesFlagWesternSahara'
import { FlagsLanguagesFlagYemen } from './FlagsLanguagesFlagYemen'
import { FlagsLanguagesFlagZambia } from './FlagsLanguagesFlagZambia'
import { FlagsLanguagesFlagZimbabwe } from './FlagsLanguagesFlagZimbabwe'

const icons = {
  'FlagsLanguages-FlagAfganistan': FlagsLanguagesFlagAfganistan,
  'FlagsLanguages-FlagAlandIsland': FlagsLanguagesFlagAlandIsland,
  'FlagsLanguages-FlagAlbania': FlagsLanguagesFlagAlbania,
  'FlagsLanguages-FlagAlgeria': FlagsLanguagesFlagAlgeria,
  'FlagsLanguages-FlagAmericanSamoa': FlagsLanguagesFlagAmericanSamoa,
  'FlagsLanguages-FlagAndorra': FlagsLanguagesFlagAndorra,
  'FlagsLanguages-FlagAngola': FlagsLanguagesFlagAngola,
  'FlagsLanguages-FlagAntartica': FlagsLanguagesFlagAntartica,
  'FlagsLanguages-FlagAntiguaBarbuda': FlagsLanguagesFlagAntiguaBarbuda,
  'FlagsLanguages-FlagArabEmirates': FlagsLanguagesFlagArabEmirates,
  'FlagsLanguages-FlagArgentina': FlagsLanguagesFlagArgentina,
  'FlagsLanguages-FlagArmenia': FlagsLanguagesFlagArmenia,
  'FlagsLanguages-FlagAruba': FlagsLanguagesFlagAruba,
  'FlagsLanguages-FlagAustralia': FlagsLanguagesFlagAustralia,
  'FlagsLanguages-FlagAustria': FlagsLanguagesFlagAustria,
  'FlagsLanguages-FlagAzerbaijan': FlagsLanguagesFlagAzerbaijan,
  'FlagsLanguages-FlagBahamas': FlagsLanguagesFlagBahamas,
  'FlagsLanguages-FlagBahrain': FlagsLanguagesFlagBahrain,
  'FlagsLanguages-FlagBangladesh': FlagsLanguagesFlagBangladesh,
  'FlagsLanguages-FlagBarbados': FlagsLanguagesFlagBarbados,
  'FlagsLanguages-FlagBelarus': FlagsLanguagesFlagBelarus,
  'FlagsLanguages-FlagBelgium': FlagsLanguagesFlagBelgium,
  'FlagsLanguages-FlagBenin': FlagsLanguagesFlagBenin,
  'FlagsLanguages-FlagBermuda': FlagsLanguagesFlagBermuda,
  'FlagsLanguages-FlagBolivia': FlagsLanguagesFlagBolivia,
  'FlagsLanguages-FlagBosniaHerzegovina': FlagsLanguagesFlagBosniaHerzegovina,
  'FlagsLanguages-FlagBotswana': FlagsLanguagesFlagBotswana,
  'FlagsLanguages-FlagBrazil': FlagsLanguagesFlagBrazil,
  'FlagsLanguages-FlagBritishVirginIsland': FlagsLanguagesFlagBritishVirginIsland,
  'FlagsLanguages-FlagBulgaria': FlagsLanguagesFlagBulgaria,
  'FlagsLanguages-FlagBurkinaFaso': FlagsLanguagesFlagBurkinaFaso,
  'FlagsLanguages-FlagBurma': FlagsLanguagesFlagBurma,
  'FlagsLanguages-FlagBurundi': FlagsLanguagesFlagBurundi,
  'FlagsLanguages-FlagCambodia': FlagsLanguagesFlagCambodia,
  'FlagsLanguages-FlagCameroon': FlagsLanguagesFlagCameroon,
  'FlagsLanguages-FlagCanada': FlagsLanguagesFlagCanada,
  'FlagsLanguages-FlagCanaryIslands': FlagsLanguagesFlagCanaryIslands,
  'FlagsLanguages-FlagCapeVerde': FlagsLanguagesFlagCapeVerde,
  'FlagsLanguages-FlagCaymanIsland': FlagsLanguagesFlagCaymanIsland,
  'FlagsLanguages-FlagCentralAfricanRepublic': FlagsLanguagesFlagCentralAfricanRepublic,
  'FlagsLanguages-FlagChad': FlagsLanguagesFlagChad,
  'FlagsLanguages-FlagChile': FlagsLanguagesFlagChile,
  'FlagsLanguages-FlagChina': FlagsLanguagesFlagChina,
  'FlagsLanguages-FlagColombia': FlagsLanguagesFlagColombia,
  'FlagsLanguages-FlagComoros': FlagsLanguagesFlagComoros,
  'FlagsLanguages-FlagCongo': FlagsLanguagesFlagCongo,
  'FlagsLanguages-FlagCookIslands': FlagsLanguagesFlagCookIslands,
  'FlagsLanguages-FlagCostaRica': FlagsLanguagesFlagCostaRica,
  'FlagsLanguages-FlagCroatia': FlagsLanguagesFlagCroatia,
  'FlagsLanguages-FlagCuba': FlagsLanguagesFlagCuba,
  'FlagsLanguages-FlagCuracao': FlagsLanguagesFlagCuracao,
  'FlagsLanguages-FlagCyprus': FlagsLanguagesFlagCyprus,
  'FlagsLanguages-FlagCzechRepublic': FlagsLanguagesFlagCzechRepublic,
  'FlagsLanguages-FlagDemocraticCongo': FlagsLanguagesFlagDemocraticCongo,
  'FlagsLanguages-FlagDenmark': FlagsLanguagesFlagDenmark,
  'FlagsLanguages-FlagDjibouti': FlagsLanguagesFlagDjibouti,
  'FlagsLanguages-FlagDominica': FlagsLanguagesFlagDominica,
  'FlagsLanguages-FlagDominicanRepublic': FlagsLanguagesFlagDominicanRepublic,
  'FlagsLanguages-FlagEastTimor': FlagsLanguagesFlagEastTimor,
  'FlagsLanguages-FlagEcuador': FlagsLanguagesFlagEcuador,
  'FlagsLanguages-FlagEgypt': FlagsLanguagesFlagEgypt,
  'FlagsLanguages-FlagEngland': FlagsLanguagesFlagEngland,
  'FlagsLanguages-FlagEquatorialGuinea': FlagsLanguagesFlagEquatorialGuinea,
  'FlagsLanguages-FlagEritrea': FlagsLanguagesFlagEritrea,
  'FlagsLanguages-FlagEstonia': FlagsLanguagesFlagEstonia,
  'FlagsLanguages-FlagEthiopia': FlagsLanguagesFlagEthiopia,
  'FlagsLanguages-FlagEurope': FlagsLanguagesFlagEurope,
  'FlagsLanguages-FlagFalklandIsland': FlagsLanguagesFlagFalklandIsland,
  'FlagsLanguages-FlagFaroeIslands': FlagsLanguagesFlagFaroeIslands,
  'FlagsLanguages-FlagFiji': FlagsLanguagesFlagFiji,
  'FlagsLanguages-FlagFinland': FlagsLanguagesFlagFinland,
  'FlagsLanguages-FlagFrance': FlagsLanguagesFlagFrance,
  'FlagsLanguages-FlagFrenchGuiana': FlagsLanguagesFlagFrenchGuiana,
  'FlagsLanguages-FlagFrenchPolynesia': FlagsLanguagesFlagFrenchPolynesia,
  'FlagsLanguages-FlagFrenchSouthernAntarctic': FlagsLanguagesFlagFrenchSouthernAntarctic,
  'FlagsLanguages-FlagGabon': FlagsLanguagesFlagGabon,
  'FlagsLanguages-FlagGambia': FlagsLanguagesFlagGambia,
  'FlagsLanguages-FlagGeorgia': FlagsLanguagesFlagGeorgia,
  'FlagsLanguages-FlagGermany': FlagsLanguagesFlagGermany,
  'FlagsLanguages-FlagGhana': FlagsLanguagesFlagGhana,
  'FlagsLanguages-FlagGibraltar': FlagsLanguagesFlagGibraltar,
  'FlagsLanguages-FlagGreece': FlagsLanguagesFlagGreece,
  'FlagsLanguages-FlagGreenland': FlagsLanguagesFlagGreenland,
  'FlagsLanguages-FlagGrenada': FlagsLanguagesFlagGrenada,
  'FlagsLanguages-FlagGuadeloupe': FlagsLanguagesFlagGuadeloupe,
  'FlagsLanguages-FlagGuam': FlagsLanguagesFlagGuam,
  'FlagsLanguages-FlagGuatemala': FlagsLanguagesFlagGuatemala,
  'FlagsLanguages-FlagGuernsey': FlagsLanguagesFlagGuernsey,
  'FlagsLanguages-FlagGuineaBissau': FlagsLanguagesFlagGuineaBissau,
  'FlagsLanguages-FlagGuinea': FlagsLanguagesFlagGuinea,
  'FlagsLanguages-FlagGuyana': FlagsLanguagesFlagGuyana,
  'FlagsLanguages-FlagHaiti': FlagsLanguagesFlagHaiti,
  'FlagsLanguages-FlagHonduras': FlagsLanguagesFlagHonduras,
  'FlagsLanguages-FlagHongKong': FlagsLanguagesFlagHongKong,
  'FlagsLanguages-FlagHungary': FlagsLanguagesFlagHungary,
  'FlagsLanguages-FlagIceland': FlagsLanguagesFlagIceland,
  'FlagsLanguages-FlagIndia': FlagsLanguagesFlagIndia,
  'FlagsLanguages-FlagIndonesia': FlagsLanguagesFlagIndonesia,
  'FlagsLanguages-FlagIran': FlagsLanguagesFlagIran,
  'FlagsLanguages-FlagIraq': FlagsLanguagesFlagIraq,
  'FlagsLanguages-FlagIreland': FlagsLanguagesFlagIreland,
  'FlagsLanguages-FlagIsleOfMan': FlagsLanguagesFlagIsleOfMan,
  'FlagsLanguages-FlagIsrael': FlagsLanguagesFlagIsrael,
  'FlagsLanguages-FlagItaly': FlagsLanguagesFlagItaly,
  'FlagsLanguages-FlagIvoryCoast': FlagsLanguagesFlagIvoryCoast,
  'FlagsLanguages-FlagJamaica': FlagsLanguagesFlagJamaica,
  'FlagsLanguages-FlagJapan': FlagsLanguagesFlagJapan,
  'FlagsLanguages-FlagJersey': FlagsLanguagesFlagJersey,
  'FlagsLanguages-FlagJordan': FlagsLanguagesFlagJordan,
  'FlagsLanguages-FlagKazakhstan': FlagsLanguagesFlagKazakhstan,
  'FlagsLanguages-FlagKenya': FlagsLanguagesFlagKenya,
  'FlagsLanguages-FlagKiribati': FlagsLanguagesFlagKiribati,
  'FlagsLanguages-FlagKosovo': FlagsLanguagesFlagKosovo,
  'FlagsLanguages-FlagKuwait': FlagsLanguagesFlagKuwait,
  'FlagsLanguages-FlagKyrgyzstan': FlagsLanguagesFlagKyrgyzstan,
  'FlagsLanguages-FlagLaos': FlagsLanguagesFlagLaos,
  'FlagsLanguages-FlagLatvia': FlagsLanguagesFlagLatvia,
  'FlagsLanguages-FlagLebanon': FlagsLanguagesFlagLebanon,
  'FlagsLanguages-FlagLesotho': FlagsLanguagesFlagLesotho,
  'FlagsLanguages-FlagLiberia': FlagsLanguagesFlagLiberia,
  'FlagsLanguages-FlagLibya': FlagsLanguagesFlagLibya,
  'FlagsLanguages-FlagLiechtenstein': FlagsLanguagesFlagLiechtenstein,
  'FlagsLanguages-FlagLithuania': FlagsLanguagesFlagLithuania,
  'FlagsLanguages-FlagLuxembourg': FlagsLanguagesFlagLuxembourg,
  'FlagsLanguages-FlagMacau': FlagsLanguagesFlagMacau,
  'FlagsLanguages-FlagMacedonia': FlagsLanguagesFlagMacedonia,
  'FlagsLanguages-FlagMadagascar': FlagsLanguagesFlagMadagascar,
  'FlagsLanguages-FlagMalawi': FlagsLanguagesFlagMalawi,
  'FlagsLanguages-FlagMalaysia': FlagsLanguagesFlagMalaysia,
  'FlagsLanguages-FlagMaldives': FlagsLanguagesFlagMaldives,
  'FlagsLanguages-FlagMali': FlagsLanguagesFlagMali,
  'FlagsLanguages-FlagMalta': FlagsLanguagesFlagMalta,
  'FlagsLanguages-FlagMarshallIslands': FlagsLanguagesFlagMarshallIslands,
  'FlagsLanguages-FlagMartinique': FlagsLanguagesFlagMartinique,
  'FlagsLanguages-FlagMauritania': FlagsLanguagesFlagMauritania,
  'FlagsLanguages-FlagMauritius': FlagsLanguagesFlagMauritius,
  'FlagsLanguages-FlagMexico': FlagsLanguagesFlagMexico,
  'FlagsLanguages-FlagMicronesia': FlagsLanguagesFlagMicronesia,
  'FlagsLanguages-FlagMoldova': FlagsLanguagesFlagMoldova,
  'FlagsLanguages-FlagMonaco': FlagsLanguagesFlagMonaco,
  'FlagsLanguages-FlagMongolia': FlagsLanguagesFlagMongolia,
  'FlagsLanguages-FlagMontenegro': FlagsLanguagesFlagMontenegro,
  'FlagsLanguages-FlagMontserrat': FlagsLanguagesFlagMontserrat,
  'FlagsLanguages-FlagMorocco': FlagsLanguagesFlagMorocco,
  'FlagsLanguages-FlagMozambique': FlagsLanguagesFlagMozambique,
  'FlagsLanguages-FlagMyanmar': FlagsLanguagesFlagMyanmar,
  'FlagsLanguages-FlagNamibia': FlagsLanguagesFlagNamibia,
  'FlagsLanguages-FlagNauruIsland': FlagsLanguagesFlagNauruIsland,
  'FlagsLanguages-FlagNepal': FlagsLanguagesFlagNepal,
  'FlagsLanguages-FlagNetherlandsAntilles': FlagsLanguagesFlagNetherlandsAntilles,
  'FlagsLanguages-FlagNetherlands': FlagsLanguagesFlagNetherlands,
  'FlagsLanguages-FlagNewCaledonia': FlagsLanguagesFlagNewCaledonia,
  'FlagsLanguages-FlagNewZealand': FlagsLanguagesFlagNewZealand,
  'FlagsLanguages-FlagNicaragua': FlagsLanguagesFlagNicaragua,
  'FlagsLanguages-FlagNiger': FlagsLanguagesFlagNiger,
  'FlagsLanguages-FlagNigeria': FlagsLanguagesFlagNigeria,
  'FlagsLanguages-FlagNiue': FlagsLanguagesFlagNiue,
  'FlagsLanguages-FlagNorfolkIsland': FlagsLanguagesFlagNorfolkIsland,
  'FlagsLanguages-FlagNorthKorea': FlagsLanguagesFlagNorthKorea,
  'FlagsLanguages-FlagNorway': FlagsLanguagesFlagNorway,
  'FlagsLanguages-FlagOman': FlagsLanguagesFlagOman,
  'FlagsLanguages-FlagPakistan': FlagsLanguagesFlagPakistan,
  'FlagsLanguages-FlagPalau': FlagsLanguagesFlagPalau,
  'FlagsLanguages-FlagPalestine': FlagsLanguagesFlagPalestine,
  'FlagsLanguages-FlagPanama': FlagsLanguagesFlagPanama,
  'FlagsLanguages-FlagPapuaNewGuinea': FlagsLanguagesFlagPapuaNewGuinea,
  'FlagsLanguages-FlagParaguay': FlagsLanguagesFlagParaguay,
  'FlagsLanguages-FlagPeru': FlagsLanguagesFlagPeru,
  'FlagsLanguages-FlagPhilipines': FlagsLanguagesFlagPhilipines,
  'FlagsLanguages-FlagPoland': FlagsLanguagesFlagPoland,
  'FlagsLanguages-FlagPortugal': FlagsLanguagesFlagPortugal,
  'FlagsLanguages-FlagPuertoRico': FlagsLanguagesFlagPuertoRico,
  'FlagsLanguages-FlagQatar': FlagsLanguagesFlagQatar,
  'FlagsLanguages-FlagRomania': FlagsLanguagesFlagRomania,
  'FlagsLanguages-FlagRussia': FlagsLanguagesFlagRussia,
  'FlagsLanguages-FlagRwanda': FlagsLanguagesFlagRwanda,
  'FlagsLanguages-FlagSaintKittsNevis': FlagsLanguagesFlagSaintKittsNevis,
  'FlagsLanguages-FlagSaintLucia': FlagsLanguagesFlagSaintLucia,
  'FlagsLanguages-FlagSaintVincentGrenadines': FlagsLanguagesFlagSaintVincentGrenadines,
  'FlagsLanguages-FlagSalvador': FlagsLanguagesFlagSalvador,
  'FlagsLanguages-FlagSamoa': FlagsLanguagesFlagSamoa,
  'FlagsLanguages-FlagSanMarino': FlagsLanguagesFlagSanMarino,
  'FlagsLanguages-FlagSaoTomePrincipe': FlagsLanguagesFlagSaoTomePrincipe,
  'FlagsLanguages-FlagSaudiArabia': FlagsLanguagesFlagSaudiArabia,
  'FlagsLanguages-FlagSenegal': FlagsLanguagesFlagSenegal,
  'FlagsLanguages-FlagSerbia': FlagsLanguagesFlagSerbia,
  'FlagsLanguages-FlagSeychelles': FlagsLanguagesFlagSeychelles,
  'FlagsLanguages-FlagSierraLeone': FlagsLanguagesFlagSierraLeone,
  'FlagsLanguages-FlagSingapore': FlagsLanguagesFlagSingapore,
  'FlagsLanguages-FlagSlovakia': FlagsLanguagesFlagSlovakia,
  'FlagsLanguages-FlagSlovenia': FlagsLanguagesFlagSlovenia,
  'FlagsLanguages-FlagSolomonIslands': FlagsLanguagesFlagSolomonIslands,
  'FlagsLanguages-FlagSomalia': FlagsLanguagesFlagSomalia,
  'FlagsLanguages-FlagSouthAfrica': FlagsLanguagesFlagSouthAfrica,
  'FlagsLanguages-FlagSouthKorea': FlagsLanguagesFlagSouthKorea,
  'FlagsLanguages-FlagSouthSudan': FlagsLanguagesFlagSouthSudan,
  'FlagsLanguages-FlagSpain': FlagsLanguagesFlagSpain,
  'FlagsLanguages-FlagSriLanka': FlagsLanguagesFlagSriLanka,
  'FlagsLanguages-FlagSudan': FlagsLanguagesFlagSudan,
  'FlagsLanguages-FlagSuriname': FlagsLanguagesFlagSuriname,
  'FlagsLanguages-FlagSwaziland': FlagsLanguagesFlagSwaziland,
  'FlagsLanguages-FlagSweden': FlagsLanguagesFlagSweden,
  'FlagsLanguages-FlagSwitzerlandSquare': FlagsLanguagesFlagSwitzerlandSquare,
  'FlagsLanguages-FlagSwitzerland': FlagsLanguagesFlagSwitzerland,
  'FlagsLanguages-FlagSyria': FlagsLanguagesFlagSyria,
  'FlagsLanguages-FlagTaiwan': FlagsLanguagesFlagTaiwan,
  'FlagsLanguages-FlagTajikistan': FlagsLanguagesFlagTajikistan,
  'FlagsLanguages-FlagTanzania': FlagsLanguagesFlagTanzania,
  'FlagsLanguages-FlagThailand': FlagsLanguagesFlagThailand,
  'FlagsLanguages-FlagTogo': FlagsLanguagesFlagTogo,
  'FlagsLanguages-FlagTonga': FlagsLanguagesFlagTonga,
  'FlagsLanguages-FlagTrinidadTobago': FlagsLanguagesFlagTrinidadTobago,
  'FlagsLanguages-FlagTunisia': FlagsLanguagesFlagTunisia,
  'FlagsLanguages-FlagTurkey': FlagsLanguagesFlagTurkey,
  'FlagsLanguages-FlagTurkmenistan': FlagsLanguagesFlagTurkmenistan,
  'FlagsLanguages-FlagTuvalu': FlagsLanguagesFlagTuvalu,
  'FlagsLanguages-FlagUganda': FlagsLanguagesFlagUganda,
  'FlagsLanguages-FlagUkraine': FlagsLanguagesFlagUkraine,
  'FlagsLanguages-FlagUnitedKingdom': FlagsLanguagesFlagUnitedKingdom,
  'FlagsLanguages-FlagUnitedNations': FlagsLanguagesFlagUnitedNations,
  'FlagsLanguages-FlagUnitedStates': FlagsLanguagesFlagUnitedStates,
  'FlagsLanguages-FlagUruguay': FlagsLanguagesFlagUruguay,
  'FlagsLanguages-FlagUzbekistan': FlagsLanguagesFlagUzbekistan,
  'FlagsLanguages-FlagVanuatu': FlagsLanguagesFlagVanuatu,
  'FlagsLanguages-FlagVatican': FlagsLanguagesFlagVatican,
  'FlagsLanguages-FlagVenezuela': FlagsLanguagesFlagVenezuela,
  'FlagsLanguages-FlagVietnam': FlagsLanguagesFlagVietnam,
  'FlagsLanguages-FlagWallisAndFutuna': FlagsLanguagesFlagWallisAndFutuna,
  'FlagsLanguages-FlagWesternSahara': FlagsLanguagesFlagWesternSahara,
  'FlagsLanguages-FlagYemen': FlagsLanguagesFlagYemen,
  'FlagsLanguages-FlagZambia': FlagsLanguagesFlagZambia,
  'FlagsLanguages-FlagZimbabwe': FlagsLanguagesFlagZimbabwe,
} as const

export type ISvgIcons = keyof typeof icons

export const getReactSvgPaths = (iconName?: ISvgIcons) => {
  if (iconName && iconName in icons) {
    return icons[iconName]
  }

  return null
}