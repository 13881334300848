import { e_Interpretation } from '../enums/e_Interpretation'
import { e_RenderType } from '../Table.types'

// Validate if Cell Renderer is needed.
// Cell Renders result in more DOM. More DOM means more CPU processing to render, regardless of what JavaScript / Framework is used to generate the DOM.
// https://www.ag-grid.com/javascript-data-grid/scrolling-performance/
export const createIsCellRendererNeeded =
	(props: {
		isGroup: boolean
		hasCustomCellRenderer: boolean
		hasFileDrag: boolean
		renderType: e_RenderType
		hasIcon: boolean
		multiSelect: boolean
		editable: boolean
		hasContextMenu: boolean
		interpretation: e_Interpretation
		fillVariant: 'pill' | 'circle' | 'default'
	}) =>
	(hasOnClick: boolean) =>
		props.isGroup ||
		props.hasCustomCellRenderer ||
		hasOnClick ||
		props.hasFileDrag ||
		[e_RenderType.avatar, e_RenderType.checkMark].includes(props.renderType) ||
		props.hasIcon ||
		props.multiSelect ||
		props.editable ||
		props.hasContextMenu ||
		['circle', 'pill'].includes(props.fillVariant) ||
		[e_Interpretation.email, e_Interpretation.internetUrl, e_Interpretation.phone].includes(props.interpretation)
