import React from 'react'
import type { CellData, ICellRendererProps, ITableContext, TData } from '../../Table.types'
import type { CustomCellRendererProps } from '@ag-grid-community/react'
import { GroupCellRenderer } from './GroupCellRenderer'
import { CellRenderer } from './CellRenderer'
import { createStyle } from '../../../../theming'

const classes = createStyle({
	masterRenderer: { display: 'flex', alignItems: 'center', gap: 4 },
})

const CellRendererWrapper = (props: ICellRendererProps & CustomCellRendererProps<TData, CellData, ITableContext>) => {
	const { column } = props
	if (column?.getColId() === 'ag-Grid-AutoColumn') {
		return <GroupCellRenderer {...props} />
	} else if (props.node.master) {
		return <div className={classes.masterRenderer}>{<CellRenderer {...props} />}</div>
	} else {
		return <CellRenderer {...props} />
	}
}

CellRendererWrapper.displayName = 'CellRendererWrapper'
export { CellRendererWrapper }
