export function convertToCase(value: string, casing: 'upperCase' | 'lowerCase' | undefined) {
	if (!value) {
		return ''
	}
	if (casing === 'upperCase') {
		return value.toUpperCase()
	}
	if (casing === 'lowerCase') {
		return value.toLowerCase()
	}

	return value
}
