import React from 'react'
import type { ICommandBarClasses } from './CommandBar'

interface ICommandBarCustomProps {
	classes: ICommandBarClasses
	hidden?: boolean
	id?: string
	contentRenderer: (renderedIn: 'CommandBar' | 'Menu') => React.ReactNode
	dataAttributes?: Record<string, string>
}

export const CommandBarCustom = (props: ICommandBarCustomProps) => {
	if (props.hidden) {
		return null
	}

	return (
		<div
			id={props.id}
			{...props.dataAttributes}
			className={props.classes.commandBarItemCustom}
			onClick={(e: any) => {
				e.stopPropagation() // prevent parent elements from receiving click events from command bar
			}}
		>
			{props.contentRenderer('CommandBar')}
		</div>
	)
}
