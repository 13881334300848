import React, { useCallback } from 'react'
import type { LexicalEditor } from 'lexical'
import { Dropdown } from '../../Dropdown'
import { getHandleChangeBlockType } from './getHandleChangeBlockType'
import { useTranslation } from '../../../translation'

const blockTags = ['custom-paragraph', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'custom-code'] as const

export function getBlockIndex(blockType: BlockType) {
	return blockTags.indexOf(blockType)
}

export type BlockType = (typeof blockTags)[number]

const blockTagsSet = new Set(blockTags)

const isBlockType = (tag: string): tag is BlockType => blockTagsSet.has(tag as BlockType)

const getBlockTranslation = (tag: BlockType, tcvi: (v: string) => string) => {
	switch (tag) {
		case 'custom-paragraph':
			return tcvi('GENERAL:NORMAL')
		case 'custom-code':
			return tcvi(`GENERAL:TYPOGRAPHY_CODE`)
		default:
			return tcvi(`GENERAL:TYPOGRAPHY_${tag.toUpperCase()}`)
	}
}

export function useBlockDropdownRenderFn(editor: LexicalEditor, currentBlockType: string, isDisabled?: boolean) {
	const { tcvi } = useTranslation()
	return useCallback(
		(renderedIn: 'CommandBar' | 'Menu') => {
			const blockOptions = blockTags.map((tag) => {
				const label = getBlockTranslation(tag, tcvi)
				return {
					label,
					value: tag,
					render: () => <span>{label}</span>,
				}
			})
			const handleChangeBlock = getHandleChangeBlockType(editor, currentBlockType)
			return (
				<span style={{ width: renderedIn === 'CommandBar' ? '128px' : '100%' }}>
					<Dropdown
						options={blockOptions}
						value={isBlockType(currentBlockType) ? currentBlockType : 'custom-paragraph'}
						onChange={handleChangeBlock}
						disabled={isDisabled}
						stretch
						dataAttributes={{ 'data-cy': 'block-dropdown' }}
					/>
				</span>
			)
		},
		[currentBlockType, editor, isDisabled, tcvi]
	)
}
