import React from 'react'
import { createStyle } from '../../../../theming'
import { Spinner } from '../../../Spinner'
import { Button } from '../../../Button'

import classNames from 'clsx'

interface IWorkingIndicatorProps {
	onCancel?: () => void
	message?: string
	isVisible: boolean
}

const classes = createStyle((theme) => ({
	spinnerPanel: {
		padding: '8px 16px',
		background: theme.colors.body.backgroundAlt,
		boxShadow: theme.shadows.medium,
		border: '1px solid ' + theme.palette.primary.themePrimary,
		display: 'flex',
		pointerEvents: 'auto',
		alignItems: 'center',
		height: '48px',
		justifyContent: 'center',
		opacity: 0,

		transition: 'opacity 0.3s, transform 0.3s',
		visibility: 'collapse',
	},

	visible: {
		opacity: 1,
		visibility: 'visible',
	},

	spinner: {
		margin: '8px',
	},

	message: {
		color: theme.colors.body.text,
	},

	button: {
		margin: '4px',
		pointerEvents: 'all',
	},
}))

export const WaitIndicator = (props: IWorkingIndicatorProps) => {
	return (
		<div className={classNames(classes.spinnerPanel, props.isVisible && classes.visible)}>
			<div className={classes.spinner}>
				<Spinner size="medium" />
			</div>

			{props.message && <div className={classes.message}>{props.message}</div>}
			{props.onCancel && (
				<div>
					<Button
						className={classes.button}
						onClick={props.onCancel}
						label="Cancel" //Hard coded, because this text might be displayed before code translations are loaded
					/>
				</div>
			)}
		</div>
	)
}
