// The raw javascript types that may be be contained in property values
export type MasterDetailDataType = string | number | boolean | null | undefined

export enum e_MasterDetailValueDataType {
	string = 'string',
	number = 'number',
	boolean = 'boolean',
}

/**
 * Interpretations used in Master-Detail to refine the raw data types
 */
export enum e_MasterDetailDataInterpretation {
	default = 'default',
	string = 'string',
	number = 'number',
	integer = 'integer',
	boolean = 'boolean',
	url = 'url',
	email = 'email',
	datetime = 'datetime',
	date = 'date',

	// reference | { id: string; value: string }
	// phone
	// duration
	// percentage
}
