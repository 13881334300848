import moment from 'moment'
import type { IDataWithIdentifier, IDataPropertyDescription } from '../types'
import { formatDate } from '../../../controls/utils/formatDate'
import type { FormatDataValueEventHandler } from '../types/MasterDetailEventHandlers'
import { e_MasterDetailTargetElement } from '../types/e_MasterDetailTargetElement'

export const isInterpretation = (value: string | undefined, interpretations: string[]) => {
	return value && interpretations.includes(value)
}

export const validateValue = (
	item: IDataWithIdentifier,
	propertyId: string,
	columnDescription: IDataPropertyDescription,
	action: 'raise' | 'returnValue'
) => {
	const value = item[propertyId]

	if (value === null || value === undefined) {
		return true
	}

	if (
		typeof item[propertyId] === 'string' &&
		isInterpretation(columnDescription.interpretation, ['date', 'datetime'])
	) {
		if (!moment(value as string).isValid()) {
			switch (action) {
				case 'raise':
					throw new Error(
						`Value ${value} is not a valid date or datetime. The date should be on the form ${moment().toISOString()} (time and timezone optional)`
					)
				case 'returnValue':
					return false
			}
		}
	}

	return true
}

export const formatPropertyValue = (
	item: IDataWithIdentifier,
	propertyId: string,
	columnDescription: IDataPropertyDescription,
	formatDataValue: FormatDataValueEventHandler | undefined,
	tcvi: (t: string) => string
) => {
	if (!columnDescription) {
		return item[propertyId] // unformatted
	}

	if (columnDescription.enableValueFormatting && formatDataValue) {
		// call external value formatting function
		// if undefined is returned, do not apply the result
		const externalFormattedValue = formatDataValue(item, propertyId, e_MasterDetailTargetElement.cell)

		if (externalFormattedValue !== undefined) {
			return externalFormattedValue
		}
	}

	if (
		typeof item[propertyId] === 'string' &&
		isInterpretation(columnDescription.interpretation, ['date', 'datetime'])
	) {
		// if a specific format is assigned, use it
		if (columnDescription.dateFormat && columnDescription.dateFormat !== 'default') {
			return formatDate(item[propertyId] as string, columnDescription.dateFormat, tcvi) as string
		}

		// if interpretation is date, return using a default date format
		if (columnDescription.interpretation === 'date') {
			return formatDate(item[propertyId] as string, 'shortDate', tcvi) as string
		}

		// if interpretation is datetime, return using a default datetime format
		if (columnDescription.interpretation === 'datetime') {
			return formatDate(item[propertyId] as string, 'shortDateTime', tcvi) as string
		}
	}

	// fallback to the raw value
	return item[propertyId]
}
