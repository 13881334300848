import React, { useRef } from 'react'

import { Popper } from '../utils/Popper'

import type { MenuItem } from './MenuItem.types'
import { e_Placement } from '../../enums/e_Placement'
import { PopupMenuContent } from './PopupMenuContent'

interface IPopupMenuControlProps {
	items: MenuItem[]
	placement?: e_Placement
	isTopLevelMenu?: boolean
	isOpen?: boolean
	/**
	 * Close event that is run whenever something happens that would close the component,
	 * i.e. click outside, activation of element, esc-click, etc.
	 *
	 * `PopupMenuControl` does not close automatically, so visibility is controlled by the parent component.
	 * `PopupMenuControl` should thus probably be hidden as a response to the onClose event.
	 */
	onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void
	/**
	 * `PopupMenuControl` will return focus automatically **if `anchorEl` is provided**.
	 *
	 * This can be overridden by setting `blockReturnFocus`.
	 */
	blockReturnFocus?: boolean
	position?: { x: number; y: number }
	anchorEl?: React.RefObject<HTMLElement | undefined>
	iconColorClass?: string
	shouldFocus?: boolean
	fade?: boolean
	popperClassName?: string
	popperStyle?: React.CSSProperties
	menuStyle?: React.CSSProperties
	popperOffset?: string
	dataAttributes?: Record<string, string>
}

export const PopupMenuControl = (props: IPopupMenuControlProps) => {
	const menuRef = useRef<HTMLDivElement>(null)
	const onCreated = () => {
		if (props.shouldFocus) {
			menuRef.current?.focus()
		}
	}

	return (
		<Popper
			enablePortal={props.isTopLevelMenu}
			open={props.isOpen}
			anchorElement={props.anchorEl}
			placement={props.placement || e_Placement.bottomStart}
			onOutsideClick={props.onClose}
			position={props.position}
			fade={props.fade}
			className={props.popperClassName}
			style={props.popperStyle}
			offset={props.popperOffset}
			dataAttributes={props.dataAttributes}
			onCreated={onCreated}
		>
			<PopupMenuContent
				items={props.items}
				anchorEl={props.anchorEl}
				blockReturnFocus={props.blockReturnFocus}
				iconColorClass={props.iconColorClass}
				menuStyle={props.menuStyle}
				onClose={props.onClose}
				ref={menuRef}
			/>
		</Popper>
	)
}
