import { Button } from '@genusbiz/web-ui/controls'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AvailabilityWindowEditor } from './AvailabilityWindowEditor'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { AvailabilityGroupCreating } from 'src/interfaces/IAvailabilityWindow'
import { createStyle } from '@genusbiz/web-ui/theming'
import { generateGuid } from 'src/utils/generateGuid'
import { e_LifespanName } from 'src/enums/e_LifespanName'
import { useSelector } from 'react-redux'
import { userSettingsSelectors } from 'src/features/UserSettings/duck/userSettingsSelectors'

const classes = createStyle({
	button: {
		marginLeft: '8px',
		width: '200px',
	},
})
interface IProps {
	selectedEnvironment: e_EnvironmentOperatingType
}

export const NewAvailabilityWindowGroup = (props: IProps) => {
	const { t } = useTranslation()

	const selectedTimezone = useSelector(userSettingsSelectors.selectSelecedTimezone)

	const createNewAvailablityGroup = (): AvailabilityGroupCreating => {
		return {
			comment: '',
			enabled: true,
			fromDays: [],
			fromTime: 0,
			id: generateGuid(),
			windowIds: {},
			timezone: selectedTimezone,
			windowType: {
				lifespanName: e_LifespanName.continuous, //Always continuous windows created in this editor
			},
		}
	}

	const [showEditor, setShowEditor] = useState(false)
	const [newAvailablityGroup, setNewAvailablityGroup] = useState<AvailabilityGroupCreating>(createNewAvailablityGroup())

	return (
		<>
			<Button
				className={classes.button}
				size="medium"
				variant="primary"
				onClick={() => {
					setNewAvailablityGroup(createNewAvailablityGroup())
					setShowEditor(true)
				}}
				screenTip={t('AVAILABILITY_WINDOW:NEW_WINDOW')}
				label={t('AVAILABILITY_WINDOW:NEW_WINDOW')}
			/>
			<AvailabilityWindowEditor
				isOpen={showEditor}
				onClose={() => setShowEditor(false)}
				availabilityGroup={newAvailablityGroup}
				selectedEnvironment={props.selectedEnvironment}
				availabilityWindows={[]}
			/>
		</>
	)
}
