import type { ReactNode } from 'react'
import React from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'
import { useLocalStorage } from '../utils/useLocalStorage'
import { Button } from '../Button/Button'
import { type IIconNames } from '@genusbiz/icon-set/dist/utils'

const classes = createStyle((theme) => ({
	header: {
		display: 'flex',
		alignItems: 'center',
		padding: '4px',
		borderBottom: '1px solid ' + theme.colors.panel.border,
		borderTop: '1px solid ' + theme.colors.panel.border,
		background: theme.colors.panel.background,
		justifyContent: 'space-between',
	},
	headerButton: {
		fontSize: theme.typography.styles.subtitle2.fontSize,
		fontWeight: theme.typography.styles.subtitle2.fontWeight,
		'& i': {
			fontSize: '9px',
			lineHeight: '9px',
			transform: 'rotate(0deg)',
			transition: `transform ${theme.transitions.duration.short}`,
		},
		'& button:disabled, & button:disabled:hover': {
			color: theme.colors.body.text,
		},
	},
	headerSuppressTopBorder: {
		borderTopWidth: 0,
	},
	headerButtonIsClosed: {
		'& i': {
			transform: 'rotate(-90deg)',
		},
	},
	isClosed: {
		'&:not(:last-child)': {
			borderBottomWidth: 0,
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		background: theme.palette.background.white,
	},
	contentRemainingHeight: {
		flexGrow: 1,
	},
	menuItemWrapper: {
		display: 'flex',
		'& i': {
			fontSize: '12px',
			lineHeight: '8px',
		},
	},
}))

export interface IContentPaneSectionMenuItems {
	id: string
	iconClassName: IIconNames
	onClick?: () => void
	screenTip?: string
	disabled?: boolean
}

interface IContentPaneSection {
	header?: string
	children?: ReactNode | ReactNode[]
	collapsible?: boolean
	takeRemainingHeight?: boolean
	isOpen?: boolean
	suppressTopBorder?: boolean
	persistKey?: string
	menuItems?: IContentPaneSectionMenuItems[]
}

export const ContentPaneSection = (props: IContentPaneSection) => {
	const [isOpen, setIsOpen] = useLocalStorage(props.persistKey || '', props.isOpen !== undefined ? props.isOpen : true)

	return (
		<>
			{props.header && (
				<div
					className={classNames(classes.header, {
						[classes.isClosed]: !isOpen,
						[classes.headerSuppressTopBorder]: props.suppressTopBorder,
					})}
				>
					<Button
						label={props.header}
						icon={props.collapsible ? 'Fluent-ChevronDown' : undefined}
						variant="inline"
						onClick={() => setIsOpen(!isOpen)}
						size="extraSmall"
						disabled={!props.collapsible}
						className={classNames(classes.headerButton, { [classes.headerButtonIsClosed]: !isOpen })}
					/>
					{props.menuItems && (
						<div className={classes.menuItemWrapper}>
							{props.menuItems.map((item) => {
								return (
									<Button
										id={item.id}
										key={item.id}
										variant="inline"
										icon={item.iconClassName}
										onClick={item.onClick}
										disabled={item.disabled || !item.onClick}
										screenTip={item.screenTip}
									/>
								)
							})}
						</div>
					)}
				</div>
			)}
			{isOpen && (
				<div className={classNames(classes.content, props.takeRemainingHeight && classes.contentRemainingHeight)}>
					{props.children}
				</div>
			)}
		</>
	)
}
