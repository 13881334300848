import { Dialog } from '@genusbiz/web-ui/controls'
import React from 'react'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'
import { WorkloadControllerDescription } from './WorkloadControllerDescription'
import { createStyle } from '@genusbiz/web-ui/theming'
import { WorkloadControllerScaler } from './WorkloadControllerScaler'

const classes = createStyle({
	dialog: {
		height: '50%',
		width: '600px',
	},
})
interface IWorkloadDetailsProps {
	isOpen: boolean
	onClose: () => void
	workloadController: IWorkloadController
	refresh: (emptyBeforeRefresh?: boolean) => Promise<void>
}
export const WorkloadControllerDetails = (props: IWorkloadDetailsProps) => {
	return (
		<Dialog
			isOpen={props.isOpen}
			handleClose={props.onClose}
			title={props.workloadController.name}
			modalClassName={classes.dialog}
		>
			<>
				<WorkloadControllerDescription workloadController={props.workloadController} />
				<hr />
				<WorkloadControllerScaler workloadController={props.workloadController} refresh={props.refresh} />
			</>
		</Dialog>
	)
}
