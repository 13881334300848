import { createStyle } from '../../../theming'

export const variantClasses = createStyle((theme) => ({
	default: {
		backgroundColor: theme.controls.button.colors.background,
		borderColor: theme.controls.button.colors.border,
		color: theme.controls.button.colors.color,
		'&:focus-visible': {
			backgroundColor: theme.controls.button.focusedColors.background,
			borderColor: theme.controls.button.focusedColors.border,
			color: theme.controls.button.focusedColors.color,
			outlineColor: theme.controls.button.focusedColors.outline,
		},
		'&[aria-checked="true"]': {
			backgroundColor: theme.controls.button.checkedColors.background,
			borderColor: theme.controls.button.checkedColors.border,
			color: theme.controls.button.checkedColors.color,
			'@media (any-hover: hover)': {
				'&:hover': {
					backgroundColor: theme.controls.button.checkedHoverColors.background,
					borderColor: theme.controls.button.checkedHoverColors.border,
					color: theme.controls.button.checkedHoverColors.color,
				},
			},
		},
		'@media (any-hover: hover)': {
			'&:hover': {
				backgroundColor: theme.controls.button.hoverColors.background,
				borderColor: theme.controls.button.hoverColors.border,
				color: theme.controls.button.hoverColors.color,
			},
		},
		'&:hover:active': {
			backgroundColor: theme.controls.button.pressedColors.background,
			borderColor: theme.controls.button.pressedColors.border,
			color: theme.controls.button.pressedColors.color,
		},
		'&:disabled, &:disabled:hover': {
			backgroundColor: theme.controls.button.disabledColors.background,
			borderColor: theme.controls.button.disabledColors.border,
			color: theme.controls.button.disabledColors.color,
		},
		'&:not(:disabled)': { boxShadow: theme.controls.button.boxShadow },
	},
	inline: {
		backgroundColor: theme.controls.button.colors.inlineBackground,
		borderColor: theme.controls.button.colors.inlineBorder,
		color: theme.controls.button.colors.inlineColor,
		'&:focus-visible': {
			backgroundColor: theme.controls.button.focusedColors.inlineBackground,
			borderColor: theme.controls.button.focusedColors.inlineBorder,
			color: theme.controls.button.focusedColors.inlineColor,
			outlineColor: theme.controls.button.focusedColors.inlineOutline,
		},
		'&[aria-checked="true"]': {
			backgroundColor: theme.controls.button.checkedColors.inlineBackground,
			borderColor: theme.controls.button.checkedColors.inlineBorder,
			color: theme.controls.button.checkedColors.inlineColor,
			'@media (any-hover: hover)': {
				'&:hover': {
					backgroundColor: theme.controls.button.checkedHoverColors.inlineBackground,
					borderColor: theme.controls.button.checkedHoverColors.inlineBorder,
					color: theme.controls.button.checkedHoverColors.inlineColor,
				},
			},
		},
		'@media (any-hover: hover)': {
			'&:hover': {
				backgroundColor: theme.controls.button.hoverColors.inlineBackground,
				borderColor: theme.controls.button.hoverColors.inlineBorder,
				color: theme.controls.button.hoverColors.inlineColor,
			},
		},
		'&:hover:active': {
			backgroundColor: theme.controls.button.pressedColors.inlineBackground,
			borderColor: theme.controls.button.pressedColors.inlineBorder,
			color: theme.controls.button.pressedColors.inlineColor,
		},

		'&:disabled, &:disabled:hover': {
			backgroundColor: theme.controls.button.disabledColors.inlineBackground,
			borderColor: theme.controls.button.disabledColors.inlineBorder,
			color: theme.controls.button.disabledColors.inlineColor,
		},
	},
	primary: {
		backgroundColor: theme.controls.button.colors.primaryBackground,
		borderColor: theme.controls.button.colors.primaryBorder,
		color: theme.controls.button.colors.primaryColor,
		'&:focus-visible': {
			backgroundColor: theme.controls.button.focusedColors.primaryBackground,
			borderColor: theme.controls.button.focusedColors.primaryBorder,
			color: theme.controls.button.focusedColors.primaryColor,
			outlineColor: theme.controls.button.focusedColors.primaryOutline,
		},
		'&[aria-checked="true"]': {
			backgroundColor: theme.controls.button.checkedColors.primaryBackground,
			borderColor: theme.controls.button.checkedColors.primaryBorder,
			color: theme.controls.button.checkedColors.primaryColor,
			'@media (any-hover: hover)': {
				'&:hover': {
					backgroundColor: theme.controls.button.checkedHoverColors.primaryBackground,
					borderColor: theme.controls.button.checkedHoverColors.primaryBorder,
					color: theme.controls.button.checkedHoverColors.primaryColor,
				},
			},
		},
		'@media (any-hover: hover)': {
			'&:hover': {
				backgroundColor: theme.controls.button.hoverColors.primaryBackground,
				borderColor: theme.controls.button.hoverColors.primaryBorder,
				color: theme.controls.button.hoverColors.primaryColor,
			},
		},
		'&:hover:active': {
			backgroundColor: theme.controls.button.pressedColors.primaryBackground,
			borderColor: theme.controls.button.pressedColors.primaryBorder,
			color: theme.controls.button.pressedColors.primaryColor,
		},

		'&:disabled, &:disabled:hover': {
			backgroundColor: theme.controls.button.disabledColors.primaryBackground,
			borderColor: theme.controls.button.disabledColors.primaryBorder,
			color: theme.controls.button.disabledColors.primaryColor,
		},
		'&:not(:disabled)': { boxShadow: theme.controls.button.boxShadow },
	},
}))
