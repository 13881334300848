import { registerCodeHighlighting, PrismTokenizer, DEFAULT_CODE_LANGUAGE } from '@lexical/code'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'

import 'prismjs/components/prism-yaml'

const CustomTokenizer = {
	defaultLanguage: DEFAULT_CODE_LANGUAGE,
	tokenize: (code: string, language?: string) => {
		const val = PrismTokenizer.tokenize(code, language)
		return val.map((v) => {
			if (language === 'yml' && typeof v === 'string') {
				return { alias: undefined, content: v, length: v.length, type: 'string' }
			} else {
				return v
			}
		})
	},
}

export default function CodeHighlightPlugin(): JSX.Element | null {
	const [editor] = useLexicalComposerContext()

	useEffect(() => {
		registerCodeHighlighting(editor, CustomTokenizer)
	}, [editor])

	return null
}
