export enum e_DateTimeFormat {
	// Locale dependant formats
	DTF_TP_LOCALE_LD = 'DTF_TP_LOCALE_LD',
	DTF_TP_LOCALE_SD = 'DTF_TP_LOCALE_SD',
	DTF_TP_LOCALE_LT = 'DTF_TP_LOCALE_LT',
	DTF_TP_LOCALE_ST = 'DTF_TP_LOCALE_ST',
	DTF_TP_LOCALE_LD_B_LT = 'DTF_TP_LOCALE_LD_B_LT',
	DTF_TP_LOCALE_LD_B_ST = 'DTF_TP_LOCALE_LD_B_ST',
	DTF_TP_LOCALE_SD_B_LT = 'DTF_TP_LOCALE_SD_B_LT',
	DTF_TP_LOCALE_SD_B_ST = 'DTF_TP_LOCALE_SD_B_ST',

	// Military date formats
	DTF_TP_MIL_YYYY_MM_DD = 'DTF_TP_MIL_YYYY_MM_DD',
	DTF_TP_MIL_YYYY_MM_DD_HH_NN = 'DTF_TP_MIL_YYYY_MM_DD_HH_NN',
	DTF_TP_MIL_YYYY_MM_DD_HH_NN_SS = 'DTF_TP_MIL_YYYY_MM_DD_HH_NN_SS',

	// Standard date formats
	DTF_TP_STD_YYYY_Z_MM_Z_DD = 'DTF_TP_STD_YYYY_Z_MM_Z_DD',
	DTF_TP_STD_YYYY_Z_MM_Z_DD_B_HH_C_NN = 'DTF_TP_STD_YYYY_Z_MM_Z_DD_B_HH_C_NN',
	DTF_TP_STD_YYYY_Z_MM_Z_DD_B_HH_C_NN_C_SS = 'DTF_TP_STD_YYYY_Z_MM_Z_DD_B_HH_C_NN_C_SS',

	// Date fractions
	DTF_TP_STD_YY = 'DTF_TP_STD_YY',
	DTF_TP_STD_YYYY = 'DTF_TP_STD_YYYY',
	DTF_TP_STD_MM = 'DTF_TP_STD_MM',
	DTF_TP_STD_MMM = 'DTF_TP_STD_MMM',
	DTF_TP_STD_D = 'DTF_TP_STD_D',
	DTF_TP_STD_DD = 'DTF_TP_STD_DD',
	DTF_TP_STD_DDD = 'DTF_TP_STD_DDD',
	DTF_TP_STD_DDDD = 'DTF_TP_STD_DDDD',
}
