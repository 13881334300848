import React, { useRef, useState } from 'react'
import { createStyle } from '../../theming'

import type { IFormControl } from '../FormControl'
import { FormControl, FormControlButton } from '../FormControl'
import { MultiValueInput } from '../MultiValueInput'
import { useStyleContext } from '../utils/Style.context'
import { LookupInputDialogManager } from './LookupInputDialogManager'
import { useId } from '../hooks/useId'
import type { IColumnProps } from '../Table'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'
import type { e_ChipOverflow } from '../../enums/e_Overflow'

const classes = createStyle({
	inputWrapper: {
		display: 'flex',
		width: '100%',
	},
	multiValueInputWrapper: {
		display: 'flex',
		width: '100%',
	},
	multiValueInput: {
		maxHeight: 128,
	},
})

export interface Option {
	value: string | number
	[colId: string]: string | number
}

interface ILookupWithDialogProps extends IFormControl {
	enableMultiSelect?: boolean
	values: (string | number)[]
	options: Option[]
	columnProps: IColumnProps[]
	displayValue?: string | number
	defaultLabels: { value: string; label: string }[]
	performLookup: (
		lookupQuery: string,
		lookupToken: number,
		onOptionsAvailable: (lookupToken: number, options: Option[]) => void
	) => void
	onSelection?: (itemIds: (string | number)[]) => void
	defaultAction?: () => void
	placeholder?: string
	chipOverflow?: e_ChipOverflow
}

enum Modes {
	display,
	lookupQuery,
}

export const LookupInputWithDialog = (props: ILookupWithDialogProps) => {
	const id = useId('lookup-input-with-dialog', props.id)
	const inputRef = useRef<HTMLInputElement>(null)
	const [dialogOptions, setDialogOptions] = useState<Option[]>([])
	const lookupToken = useRef<number>()

	const [mode, setMode] = useState<Modes>(Modes.display)
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [displayLoadIndicator, setDisplayLoadIndicator] = useState(false)
	const [lookupQuery, setLookupQuery] = useState('')
	const disableSearch = props.disabled || props.readOnly || mode !== Modes.lookupQuery || !lookupQuery.length

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value

		if (!value.length) {
			setMode(Modes.display)
			// Nullify selection if input is cleared and the control is used as a single select input
			!props.enableMultiSelect && onSelection([])
		} else {
			setMode(Modes.lookupQuery)
		}

		setLookupQuery(value)
	}

	const itemSelected = (itemId: string | number) => {
		inputRef.current?.focus()

		setIsDialogOpen(false)
		setMode(Modes.display)
		setLookupQuery('')
		setDialogOptions([])

		setSelection(itemId)
	}

	const setSelection = (selectedItemId: string | number) => {
		if (props.enableMultiSelect) {
			const newSelection = props.values
			if (!newSelection.includes(selectedItemId)) {
				newSelection.push(selectedItemId)
			}

			onSelection(newSelection)
		} else {
			onSelection([selectedItemId])
		}
	}

	const onSelection = (itemIds: (string | number)[]) => {
		props.onSelection?.(itemIds)
	}

	const focusInputField = () => {
		inputRef.current?.focus()
		mode === Modes.display && inputRef.current?.select()
	}

	const onCancel = () => {
		setIsDialogOpen(false)
	}

	const handleClick = (e: React.MouseEvent) => {
		if (e.ctrlKey || e.metaKey) {
			if (mode === Modes.display) {
				props.defaultAction?.()
			}
		} else {
			focusInputField()
		}
	}

	const performLookup = (withDialog = true) => {
		if (disableSearch) {
			return
		}

		lookupToken.current = Date.now()
		if (!withDialog && lookupQuery.trim() === '') {
			return
		}
		setDisplayLoadIndicator(true)
		setLookupQuery(lookupQuery.trim())

		if (withDialog) {
			props.performLookup(lookupQuery, lookupToken.current, getOnOptionsAvailable())
		} else {
			props.performLookup(lookupQuery, lookupToken.current, getOnOptionsAvailable(false))
		}
	}

	const getOnOptionsAvailable =
		(withDialog = true) =>
		(token: number, options: Option[]) => {
			if (token !== lookupToken.current) {
				return
			}

			setDisplayLoadIndicator(false)

			if (options.length === 0 && withDialog) {
				// Show "no result" Dialog
				setIsDialogOpen(true)
				setDialogOptions([])
			} else if (options.length === 1) {
				// select the value without displaying dialog
				setIsDialogOpen(false)
				setDialogOptions([])
				setMode(Modes.display)
				if (withDialog) {
					itemSelected(options[0].value)
				} else {
					setLookupQuery('')
					setSelection(options[0].value)
				}
			} else if (withDialog) {
				setIsDialogOpen(true)
				setDialogOptions(options)
			} else {
				revertToStoredValue(false)
			}
		}

	const revertToStoredValue = (selectValue: boolean) => {
		selectValue && inputRef.current?.select()

		setMode(Modes.display)
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (mode !== Modes.lookupQuery) {
			return
		}

		switch (e.key) {
			case 'k':
				if (e.ctrlKey) {
					e.preventDefault()
					e.stopPropagation()
					performLookup()
				}
				break
			case 'Enter':
				e.preventDefault()
				e.stopPropagation()
				performLookup()

				break
			case 'Escape': {
				e.preventDefault()
				e.stopPropagation()
				revertToStoredValue(true)
				break
			}
		}
	}

	const onFocus = () => {
		inputRef.current?.select()
	}

	const onBlur = () => {
		if (mode !== Modes.lookupQuery) {
			return
		}

		if (displayLoadIndicator) {
			return
		}

		if (isDialogOpen) {
			return
		}

		revertToStoredValue(false)
		performLookup(false)
	}

	const getSelectedItems = () => {
		const mergedOptions = props.options.concat(props.defaultLabels)
		const options: Option[] = []
		props.values.forEach((itemId) => {
			let selectedOption: Option | undefined
			mergedOptions.some((option) => {
				if (option.value === itemId) {
					selectedOption = option
					return true
				}
			})

			//SelectedOption is either an object with {value,name} or {value,label}.
			selectedOption &&
				options.push({
					value: selectedOption.value,
					label: selectedOption.name ? selectedOption.name : selectedOption.label,
				})
		})

		return options
	}

	const { placeholder, readOnlyIndicatorStyle } = { ...useStyleContext().style?.input }

	return (
		<FormControl
			id={id}
			label={props.label}
			labelIcon={props.labelIcon}
			labelIconColor={props.labelIconColor}
			labelBold={props.labelBold}
			labelContentLayout={props.labelContentLayout}
			hideLabel={props.hideLabel}
			labelProps={props.labelProps}
			labelPosition={props.labelPosition}
			labelSubText={props.labelSubText}
			validationText={props.validationText}
			validationTextPosition={props.validationTextPosition}
			subText={props.subText}
			reserveHelperTextSpace={props.reserveHelperTextSpace}
			disabled={props.disabled}
			readOnly={props.readOnly}
			required={props.required}
			error={props.error}
			warning={props.warning}
			margin={props.margin}
			className={props.className}
			disableBorder={props.disableBorder}
			screenTip={props.screenTip}
		>
			{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
				<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
			)}
			<div className={props.enableMultiSelect ? classes.multiValueInputWrapper : classes.inputWrapper}>
				{props.enableMultiSelect ? (
					<MultiValueInput
						ref={inputRef}
						id={id}
						placeholder={props.placeholder ?? placeholder}
						inputValue={mode === Modes.lookupQuery ? lookupQuery : ''}
						tags={getSelectedItems()}
						className={classes.multiValueInput}
						defaultAction={performLookup}
						onSelection={onSelection}
						onClick={handleClick}
						onInputFocus={onFocus}
						onKeyDown={onKeyDown}
						onInputBlur={onBlur}
						onInputChange={handleChange}
						disabled={props.disabled}
						chipOverflow={props.chipOverflow}
					/>
				) : (
					<input
						id={id}
						disabled={props.disabled}
						spellCheck={false}
						autoComplete="false"
						placeholder={props.placeholder ?? placeholder}
						value={mode === Modes.lookupQuery ? lookupQuery : props.displayValue}
						onChange={handleChange}
						onFocus={onFocus}
						onClick={handleClick}
						onKeyDown={onKeyDown}
						onBlur={onBlur}
						ref={inputRef}
						readOnly={props.readOnly}
						name={props.name}
					/>
				)}
			</div>

			{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
				<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
			)}

			<FormControlButton
				disabled={disableSearch}
				onMouseDown={() => performLookup()}
				iconClassName="Fluent-Search"
				showSpinner={displayLoadIndicator}
			/>
			{isDialogOpen && (
				<div onMouseDown={(e) => e.stopPropagation()}>
					<LookupInputDialogManager
						label={props.label}
						lookupQuery={lookupQuery}
						dialogOptions={dialogOptions}
						onItemSelected={itemSelected}
						onCancel={onCancel}
						isDialogOpen={isDialogOpen}
						columnProps={props.columnProps}
					/>
				</div>
			)}
		</FormControl>
	)
}
