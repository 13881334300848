const STORAGE_KEY_PREFIX = 'OPERATOR'

/**
 * Storage service primarily used for data with small footprint,
 * such as boolean settings, strings etc.
 *
 */
class SessionStorageService {
	constructor() {
		if (typeof Storage === 'undefined') {
			throw new Error('No session storage')
		}
	}

	/**
	 * Read key from session storage
	 * @param { string } key
	 *
	 */
	read(key: string, ignoreStorageKeyPrefix?: boolean) {
		const storageKeyPrefix = ignoreStorageKeyPrefix ? '' : STORAGE_KEY_PREFIX
		const encodedValue = window.sessionStorage.getItem(storageKeyPrefix + key)

		if (!encodedValue) {
			return
		}

		return JSON.parse(decodeURIComponent(atob(encodedValue)))
	}

	/**
	 * Write key/value pair to session storage
	 * @param { string } key
	 * @param { unknown } value
	 *
	 */
	write(key: string, value: unknown, ignoreStorageKeyPrefix?: boolean): void {
		const storageKeyPrefix = ignoreStorageKeyPrefix ? '' : STORAGE_KEY_PREFIX
		const encodedValue = btoa(encodeURIComponent(JSON.stringify(value)))

		window.sessionStorage.setItem(storageKeyPrefix + key, encodedValue)
	}

	/**
	 * Delete key/value pair from session storage
	 * @param { string } key
	 *
	 */
	delete(key: string, ignoreStorageKeyPrefix?: boolean): void {
		const storageKeyPrefix = ignoreStorageKeyPrefix ? '' : STORAGE_KEY_PREFIX
		window.sessionStorage.removeItem(storageKeyPrefix + key)
	}

	/**
	 * Delete all values from session storage
	 *
	 */
	clear(): void {
		//window.sessionStorage.clear()
	}
}

export const sessionStorageService = new SessionStorageService()
