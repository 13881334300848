import React from 'react'
import { Link, e_LinkTarget } from '../../../../Link'
import { e_LabelContentLayout } from '../../../../FormControl'
import type { ICellRenderer } from '../../../Table.types'
import { checkForInterpretation } from '../../../utils/checkForInterpretation'
import { e_Interpretation } from '../../../enums/e_Interpretation'

interface ICellRendererLink extends ICellRenderer {
	value?: string
	interpretation: e_Interpretation
}

export const CellRendererLink = (props: ICellRendererLink) => {
	const { value = '' } = props

	const linkType = checkForInterpretation(props.interpretation)

	return props.interpretation === e_Interpretation.internetUrl ? (
		<Link
			url={value.startsWith('http://') || value.startsWith('https://') ? value : '//' + value}
			displayName={value}
			contentLayout={e_LabelContentLayout.text}
			target={e_LinkTarget.self}
		/>
	) : (
		<Link
			url={linkType + value}
			displayName={value}
			contentLayout={e_LabelContentLayout.text}
			target={e_LinkTarget.self}
		/>
	)
}
