import React, { useState } from 'react'

import { createStyle } from '@genusbiz/web-ui/theming'
import { Button, Text } from '@genusbiz/web-ui/controls'
import { e_OperatorStorageKeys } from 'src/enums/e_OperatorStorageKeys'
import { sessionStorageService } from 'src/modules/sessionStorageService/sessionStorageService'
import { useTranslation } from 'react-i18next'

const classes = createStyle({
	marginBottom: { marginBottom: '32px !important' },
	buttons: { flex: 1 },
})

interface IPersistSessionProps {
	onValidSignIn: () => void
}

export const PersistSession = (props: IPersistSessionProps) => {
	const { t } = useTranslation()

	const [showSpinner, setShowSpinner] = useState(false)

	const setPersistSessionClick = () => {
		setShowSpinner(true)

		sessionStorageService.write(e_OperatorStorageKeys.persistSession, true)
		props.onValidSignIn()

		setShowSpinner(false)
	}

	return (
		<>
			<Text variant="h3" style={{ marginTop: 0 }}>
				{t('SIGN_IN_WINDOW:PERSIST_LOGIN_HEADER')}
			</Text>
			<Text className={classes.marginBottom}>{t('SIGN_IN_WINDOW:PERSIST_LOGIN_BODY')}</Text>
			<div className="horizontal spacing horizontal-center-items margin-bottom16">
				<Button variant="default" className={classes.buttons} onClick={props.onValidSignIn} label={t('GENERAL:NO')} />
				<Button
					variant="primary"
					className={classes.buttons}
					label={t('GENERAL:YES')}
					onClick={setPersistSessionClick}
					showSpinner={showSpinner}
					disabled={showSpinner}
				/>
			</div>
		</>
	)
}
