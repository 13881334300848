import { useEffect, useRef } from 'react'
import throttle from 'lodash/throttle'

import { useElementResizeObserverPolyfill, useResizeObserverPolyfill } from './useResizeObserverPolyfill'

function _useResizeObserver<T extends HTMLElement>(
	fn: (rect: DOMRectReadOnly) => void,
	ref: React.RefObject<T | undefined> | undefined,
	throttleTime = 100
) {
	const throttledFn = useRef<typeof fn>()
	const observer = useRef<ResizeObserver>()

	// New listener function, create new observer
	useEffect(() => {
		if (observer.current) {
			observer.current.disconnect()
			observer.current = undefined
		}

		if (!ref) {
			return
		}

		if (!ref.current) {
			return
		}

		throttledFn.current = throttle(fn, throttleTime)

		observer.current = new window.ResizeObserver((observerEntries) => {
			throttledFn.current!(observerEntries[0].contentRect)
		})

		observer.current.observe(ref.current)

		// Disconnect Observer when observed component unmounts
		return () => {
			observer.current?.disconnect()
		}
	}, [fn, ref, throttleTime])
}

export const useResizeObserver = window.ResizeObserver ? _useResizeObserver : useResizeObserverPolyfill

function _useElementResizeObserver<T extends HTMLElement>(
	fn: (rect: DOMRectReadOnly) => void,
	element: T | undefined,
	throttleTime = 100
) {
	const throttledFn = useRef<typeof fn>()
	const observer = useRef<ResizeObserver>()

	// New listener function, create new observer
	useEffect(() => {
		if (observer.current) {
			observer.current.disconnect()
			observer.current = undefined
		}

		if (!element) {
			return
		}

		if (!element) {
			return
		}

		throttledFn.current = throttle(fn, throttleTime)

		observer.current = new window.ResizeObserver((observerEntries) => {
			throttledFn.current!(observerEntries[0].contentRect)
		})

		observer.current.observe(element)

		// Disconnect Observer when observed component unmounts
		return () => {
			observer.current?.disconnect()
		}
	}, [fn, element, throttleTime])
}

export const useElementResizeObserver = window.ResizeObserver
	? _useElementResizeObserver
	: useElementResizeObserverPolyfill
