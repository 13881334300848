import React from 'react'
import { CLASS_NAME_BUTTONS_LEFT, CLASS_NAME_BUTTONS_RIGHT, type DialogButtonDescription } from '../DialogTypes'
import classNames from 'clsx'
import { createStyle } from '../../../theming'
import { Button } from '../../Button'

const classes = createStyle({
	dialogButtons: {
		display: 'flex',
		flexShrink: 0,
		overflow: 'auto',
		justifyContent: 'space-between',
	},
	dialogButtonsLeft: {
		display: 'flex',
		'&[data-variant-left="normal"]': {
			margin: '12px 0px 12px 12px',
		},
		'&[data-variant-left="small"]': {
			margin: '8px 0px 8px 8px',
		},
		'& button': {
			margin: 2,
			whiteSpace: 'nowrap',
		},
	},
	dialogButtonsRight: {
		display: 'flex',
		justifySelf: 'flex-end',
		justifyContent: 'flex-end',
		'&[data-variant-right="normal"]': {
			margin: '12px 12px 12px 0px',
		},
		'&[data-variant-right="small"]': {
			margin: '8px 8px 8px 0px',
		},
		'& button': {
			margin: 2,
			whiteSpace: 'nowrap',
		},
	},
})

interface IDialogButtonsProps {
	leftButtons: DialogButtonDescription[]
	rightButtons: DialogButtonDescription[]
	variant?: 'normal' | 'small'
}

export const DialogButtons = (props: IDialogButtonsProps) => {
	const { variant = 'normal' } = props
	const leftButtonsElement = props.leftButtons.length > 0 && (
		<div className={classNames(CLASS_NAME_BUTTONS_LEFT, classes.dialogButtonsLeft)} data-variant-left={variant}>
			{props.leftButtons.map((buttonDescription) => {
				if (!buttonDescription.isHidden) {
					return (
						<Button
							key={buttonDescription.title}
							label={buttonDescription.title}
							disabled={!buttonDescription.isEnabled}
							variant={buttonDescription.isDefault ? 'primary' : undefined}
							onClick={buttonDescription.onClick}
							dataAttributes={buttonDescription.dataAttributes}
						/>
					)
				}
			})}
		</div>
	)

	const rightButtonsElement = props.rightButtons.length > 0 && (
		<div className={classNames(CLASS_NAME_BUTTONS_RIGHT, classes.dialogButtonsRight)} data-variant-right={variant}>
			{props.rightButtons.map((buttonDescription) => {
				if (!buttonDescription?.isHidden) {
					return (
						<Button
							key={buttonDescription.title}
							label={buttonDescription.title}
							disabled={!buttonDescription.isEnabled}
							variant={buttonDescription.isDefault ? 'primary' : undefined}
							onClick={buttonDescription.onClick}
							dataAttributes={buttonDescription.dataAttributes}
						/>
					)
				}
			})}
		</div>
	)

	if (leftButtonsElement && rightButtonsElement) {
		return (
			<div className={classes.dialogButtons}>
				{leftButtonsElement}
				{rightButtonsElement}
			</div>
		)
	}

	if (leftButtonsElement) {
		return leftButtonsElement
	}

	if (rightButtonsElement) {
		return rightButtonsElement
	}
}
