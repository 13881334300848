import {
	$createParagraphNode,
	$getSelection,
	$isLineBreakNode,
	$isRangeSelection,
	$isTextNode,
	DEPRECATED_$isGridSelection,
} from 'lexical'
import type { ElementNode, GridSelection, LexicalEditor, LexicalNode, RangeSelection } from 'lexical'

import { $createCodeNode } from '@lexical/code'
import { $createHeadingNode } from '@lexical/rich-text'
import { $isCustomCodeNode } from '../nodes/CustomCodeNode'
import { $patchStyle } from '../utils'
import { $setBlocksType } from '@lexical/selection'
import type { BlockType } from './useBlockDropdownRenderFn'
import type { CustomCodeNode } from '../nodes/CustomCodeNode'
import type { HeadingTagType } from '@lexical/rich-text'

export const getHandleChangeBlockType = (editor: LexicalEditor, currentBlockType: string) => {
	return (blockType: BlockType) => {
		if (currentBlockType === blockType) {
			return
		}
		if (blockType === 'custom-paragraph') {
			handleChangeToParagraph(editor)
		} else if (blockType === 'custom-code') {
			handleChangeToCodeBlock(editor)
		} else {
			handleChangeToHeading(editor, blockType)
		}
	}
}

const generateHandleChangeToBlock = ($createBlockFn: () => ElementNode, $runAfter?: () => void) => {
	return (editor: LexicalEditor) => {
		editor.update(() => {
			const selection = $getSelection()

			if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
				const selectionFirstNode = selection.getNodes()[0]
				const parent = selectionFirstNode.getParent()
				if ($isCustomCodeNode(parent)) {
					$changeFromCodeBlock($createBlockFn, parent, selection)
				} else {
					$setBlocksType(selection, () => $createBlockFn())
				}
			}
			if ($runAfter) {
				$runAfter()
			}
		})
	}
}

function $changeFromCodeBlock(
	$createBlockFn: () => ElementNode,
	parent: CustomCodeNode,
	selection: RangeSelection | GridSelection
) {
	const selectionFirstNode = selection.getNodes().at(0)
	const selectionLastNode = selection.getNodes().at(-1)
	if (!selectionFirstNode) {
		$setBlocksType(selection, () => $createBlockFn())
		return
	}
	const { head, center, tail } = $splitCodeLine(selectionFirstNode, selectionLastNode)
	if (head.length === 0 && tail.length === 0) {
		$setBlocksType(selection, () => $createBlockFn())
	} else {
		const newBlockNode = $createBlockFn()
		newBlockNode.append(...center)
		parent.insertAfter(newBlockNode)
		if (tail.length > 0) {
			const tailCodeNode = $createCodeNode(parent.getLanguage())
			tailCodeNode.append(...tail)
			newBlockNode.insertAfter(tailCodeNode)
		}
		if (parent.getChildren().length === 0) {
			parent.remove()
		}
		newBlockNode.select()
	}
}

function $splitCodeLine(firstNode: LexicalNode, lastNode: LexicalNode | undefined) {
	const { prevNodes, head } = splitPrevSiblings(firstNode)
	const { nextNodes, tail } = splitNextSiblings(lastNode || firstNode)

	const selectedNodes = firstNode === lastNode ? [firstNode] : [firstNode].concat(getSiblingsUntil(firstNode, lastNode))
	$trimLineBreaks(head, { trimHead: false })
	$trimLineBreaks(selectedNodes)
	$trimLineBreaks(tail, { trimTail: false })
	const center = prevNodes.concat(selectedNodes).concat(nextNodes)

	return { head, center, tail }
}

function $trimLineBreaks(
	nodes: LexicalNode[],
	options: { trimHead?: boolean; trimTail?: boolean } = { trimHead: true, trimTail: true }
) {
	const { trimHead = true, trimTail = true } = options
	if (trimHead && $isLineBreakNode(nodes[0])) {
		nodes.shift()?.remove()
	}
	if (trimTail && $isLineBreakNode(nodes.at(-1))) {
		nodes.pop()?.remove()
	}
}

function getSiblingsUntil(from: LexicalNode, until: LexicalNode | undefined, reverse = false) {
	if (until === undefined) {
		return [from]
	}
	const nextSiblings = from.getNextSiblings()
	const isUntil = (node: LexicalNode) => node === until
	const untilIndex = reverse ? reversedFindIndex(nextSiblings, isUntil) : nextSiblings.findIndex(isUntil)
	if (untilIndex === -1) {
		return nextSiblings
	}
	return reverse ? nextSiblings.slice(untilIndex) : nextSiblings.slice(0, untilIndex + 1)
}

function splitPrevSiblings(currentNode: LexicalNode): { prevNodes: LexicalNode[]; head: LexicalNode[] } {
	const prevSiblings = currentNode.getPreviousSiblings()
	if ($isLineBreakNode(currentNode)) {
		return { prevNodes: [], head: prevSiblings }
	}
	const previousLineBreak = reversedFindIndex(prevSiblings, (n) => $isLineBreakNode(n))
	if (previousLineBreak !== -1) {
		prevSiblings[previousLineBreak].remove()
	}
	const prevNodes = previousLineBreak === -1 ? prevSiblings : prevSiblings.slice(previousLineBreak + 1)
	const head = previousLineBreak === -1 ? [] : prevSiblings.slice(0, previousLineBreak)
	return { prevNodes, head }
}

function splitNextSiblings(currentNode: LexicalNode): { nextNodes: LexicalNode[]; tail: LexicalNode[] } {
	const nextSiblings = currentNode.getNextSiblings()
	if ($isLineBreakNode(currentNode)) {
		return { nextNodes: [], tail: nextSiblings }
	}
	const nextLineBreak = nextSiblings.findIndex((n) => $isLineBreakNode(n))
	const nextNodes = nextLineBreak === -1 ? nextSiblings : nextSiblings.slice(0, nextLineBreak + 1)
	$trimLineBreaks(nextNodes)
	const tail = nextLineBreak === -1 ? [] : nextSiblings.slice(nextLineBreak + 1)
	return { nextNodes, tail }
}

function reversedFindIndex<E>(arr: E[], fn: (item: E) => boolean) {
	for (let i = arr.length; i >= 0; i--) {
		if (fn(arr[i])) {
			return i
		}
	}
	return -1
}

export const handleChangeToParagraph = generateHandleChangeToBlock($createParagraphNode)
export const handleChangeToCodeBlock = generateHandleChangeToBlock($createCodeNode)
export const handleChangeToHeading = (editor: LexicalEditor, blockType: HeadingTagType) => {
	return generateHandleChangeToBlock(
		() => $createHeadingNode(blockType),
		() => {
			const selection = $getSelection()
			const patch = {
				['font-size']: null,
			}
			selection?.getNodes().forEach((node) => {
				if ($isTextNode(node)) {
					$patchStyle(node, patch)
				}
			})
		}
	)(editor)
}
