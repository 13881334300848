import { DEFAULT_FONT_FAMILY, DEFAULT_FONT_SIZE, defaultTypography } from '../defaults'

export const getTypography = () => {
	return defaultTypography
}

export type ITypographicStyleEntries = ReturnType<typeof getTypography>
export type TypographicStyleKey = keyof ITypographicStyleEntries
interface ITypographyStyle {
	fontSize: string
	fontWeight: number
	fontFamily?: string
}

export const getTypographyStyles = (typographyStyles: ITypographicStyleEntries) => {
	const documentFontSize = 16 // This is the default for all major browsers. If the user has a different default, the fonts all over vill scale accordingly

	const styles = Object.keys(typographyStyles).reduce(
		(acc, key) => {
			const entryKey = key as TypographicStyleKey
			acc[entryKey] = {
				fontSize: `${typographyStyles[entryKey].fontSize / documentFontSize}rem`,
				fontWeight: typographyStyles[entryKey].fontWeight,
				fontFamily: DEFAULT_FONT_FAMILY,

				DBGfontSizeFixed: `${typographyStyles[entryKey].fontSize}px`,
				DBGfontSizeRelative: `${typographyStyles[entryKey].fontSize / documentFontSize}rem`,
			} as ITypographyStyle
			return acc
		},
		{} as {
			[key: string]: ITypographyStyle
		}
	) as unknown as {
		[key in TypographicStyleKey]: {
			fontSize: string
			fontWeight: number
			fontFamily?: string
		}
	}

	const legacyStyles = Object.keys(typographyStyles).reduce(
		(acc, key) => {
			const entryKey = key as TypographicStyleKey
			acc[entryKey] = {
				fontSize: `${typographyStyles[entryKey].fontSize}px`,
				fontWeight: typographyStyles[entryKey].fontWeight,
			} as ITypographyStyle
			return acc
		},
		{} as {
			[key: string]: ITypographyStyle
		}
	) as unknown as {
		[key in TypographicStyleKey]: {
			fontSize: string
			fontWeight: number
			fontFamily?: string
		}
	}

	return {
		...legacyStyles,
		styles,
		fontSize: `${DEFAULT_FONT_SIZE / documentFontSize}rem`,
		fontFamily: DEFAULT_FONT_FAMILY,
	}
}

export type ITypographicStyles = ReturnType<typeof getTypographyStyles>
