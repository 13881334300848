import { useState, useRef, useLayoutEffect, useCallback } from 'react'
import throttle from 'lodash/throttle'

// Static no-op toJSON function
/* eslint-disable @typescript-eslint/no-empty-function */
const toJSON = () => {}

export function useResizeObserverPolyfill<T extends HTMLElement>(
	fn: (rect: DOMRectReadOnly) => void,
	ref: React.RefObject<T | undefined> | undefined,
	throttleTime = 100
) {
	const listenerObject = useRef<HTMLObjectElement>()
	const [hasAttached, setHasAttached] = useState(false)

	const throttledFn = useCallback(
		throttle(() => {
			const rect = ref?.current?.getBoundingClientRect()
			if (rect) {
				fn({
					bottom: rect.bottom,
					height: rect.height,
					left: rect.left,
					right: rect.right,
					top: rect.top,
					width: rect.top,
					x: rect.x,
					y: rect.y,
					toJSON: toJSON,
				} as DOMRectReadOnly)
			}
		}, throttleTime),
		[fn, throttleTime]
	)

	useLayoutEffect(() => {
		if (hasAttached) {
			// Add resize listener to document either immediately or when element is loaded
			const htmlObjectElement = listenerObject.current!

			if (!htmlObjectElement.contentDocument) {
				htmlObjectElement.onload = () => {
					htmlObjectElement.contentDocument?.defaultView?.addEventListener('resize', throttledFn)
					throttledFn()
				}
			} else {
				htmlObjectElement.contentDocument?.defaultView?.addEventListener('resize', throttledFn)
				throttledFn()
			}
		}

		return () => {
			if (
				hasAttached &&
				listenerObject.current &&
				listenerObject.current.contentDocument &&
				listenerObject.current.contentDocument.defaultView
			) {
				listenerObject.current.contentDocument.defaultView.removeEventListener('resize', throttledFn)
			}
		}
	}, [throttledFn, hasAttached])

	useLayoutEffect(() => {
		if (ref?.current) {
			if (getComputedStyle(ref.current).position === 'static') {
				ref.current.style.position = 'relative'
			}

			listenerObject.current = ref.current.appendChild(createResizeListenerElement())
			setHasAttached(true)
		}
	}, [])

	return ref
}

function createResizeListenerElement(): HTMLObjectElement {
	const el = document.createElement('object')
	el.style.display = 'block'
	el.style.position = 'absolute'
	el.style.top = '0px'
	el.style.right = '0px'
	el.style.width = '100%'
	el.style.height = '100%'
	el.style.overflow = 'hidden'
	el.style.pointerEvents = 'none'
	el.style.zIndex = '-1'
	el.tabIndex = -1
	el.data = 'about:blank'
	return el
}

export function useElementResizeObserverPolyfill<T extends HTMLElement>(
	fn: (rect: DOMRectReadOnly) => void,
	element: T | undefined,
	throttleTime = 100
) {
	const listenerObject = useRef<HTMLObjectElement>()
	const [hasAttached, setHasAttached] = useState(false)

	const throttledFn = useCallback(
		throttle(() => {
			const rect = element?.getBoundingClientRect()
			if (rect) {
				fn({
					bottom: rect.bottom,
					height: rect.height,
					left: rect.left,
					right: rect.right,
					top: rect.top,
					width: rect.top,
					x: rect.x,
					y: rect.y,
					toJSON: toJSON,
				} as DOMRectReadOnly)
			}
		}, throttleTime),
		[fn, throttleTime]
	)

	useLayoutEffect(() => {
		if (hasAttached) {
			// Add resize listener to document either immediately or when element is loaded
			const htmlObjectElement = listenerObject.current!

			if (!htmlObjectElement.contentDocument) {
				htmlObjectElement.onload = () => {
					htmlObjectElement.contentDocument?.defaultView?.addEventListener('resize', throttledFn)
					throttledFn()
				}
			} else {
				htmlObjectElement.contentDocument?.defaultView?.addEventListener('resize', throttledFn)
				throttledFn()
			}
		}

		return () => {
			if (
				hasAttached &&
				listenerObject.current &&
				listenerObject.current.contentDocument &&
				listenerObject.current.contentDocument.defaultView
			) {
				listenerObject.current.contentDocument.defaultView.removeEventListener('resize', throttledFn)
			}
		}
	}, [throttledFn, hasAttached])

	useLayoutEffect(() => {
		if (element) {
			if (getComputedStyle(element).position === 'static') {
				element.style.position = 'relative'
			}

			listenerObject.current = element.appendChild(createResizeListenerElement())
			setHasAttached(true)
		}
	}, [])

	return element
}
