import React, { useEffect, useRef } from 'react'
import { createStyle } from '../../theming'

import classNames from 'clsx'
import type { IFormControl } from '../FormControl'
import { FormControl, FormControlButton } from '../FormControl'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useId } from '../hooks/useId'
import { useStyleContext } from '../utils/Style.context'
import { DropdownList } from '../Dropdown'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'
import {
	getEventHandlers,
	getLogicalFocusAndHoverHandlers,
	useAvailableOptions,
	useDropdownPanel,
	useIncrementCurrentValue,
	useMaxValueMinutes,
	useMinValueMinutes,
	useOptionResolutionMinutes,
	useUpdateInternalValue,
} from './utils'
import { type e_TextAlignment } from '../../enums/e_TextAlignment'
import { getParseValueAndUpdateInternal } from './utils/getParseTextValue'
import { useValidateValue } from './utils/useValidateValue'

const classes = createStyle({
	alignRight: {
		textAlign: 'right',
	},
	alignCenter: {
		textAlign: 'center',
	},
	input: { fontWeight: 'inherit' },
})

export type DurationValueType = number | null | undefined

interface IDurationProps extends IFormControl {
	value: DurationValueType
	placeholder?: string
	textAlignment?: e_TextAlignment
	initialValue?: string
	dropdownOpen?: boolean
	onChange: (value?: DurationValueType) => void
	onClose?: () => void
	onBlur?: (value?: DurationValueType) => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
	isIntegerTime?: boolean
	cannotBeBlank?: boolean
	enableOptionDropdown?: boolean
	optionsResolutionMinutes?: number
	minValueTicksMS?: number
	maxValueTicksMS?: number
	actionButtonRef?: React.Ref<HTMLButtonElement>
}

export const DurationInput = React.forwardRef((props: IDurationProps, forwardedRef: React.Ref<HTMLInputElement>) => {
	const { actionIcon = 'Fluent-Forward', onClose } = props

	const inputRef = useForwardedRef<HTMLInputElement>(forwardedRef)
	const isInputFocused = useRef(false)
	const id = useId('duration-input', props.id)
	const anchorElementRef = useRef<HTMLDivElement>(null)

	const maxValueMinutes = useMaxValueMinutes(props.maxValueTicksMS, props.isIntegerTime)
	const minValueMinutes = useMinValueMinutes(props.minValueTicksMS)
	const optionResolutionMinutes = useOptionResolutionMinutes(props.optionsResolutionMinutes)

	const { updateInternalValue, convertToExternalFormatFunc, displayValue, internalValue, setDisplayValue } =
		useUpdateInternalValue(inputRef, props.value, props.isIntegerTime, isInputFocused, props.onChange)

	const { options, displayOptionDropDown, dropdownIndex } = useAvailableOptions(
		internalValue,
		props.optionsResolutionMinutes,
		props.minValueTicksMS,
		props.maxValueTicksMS,
		props.isIntegerTime,
		props.enableOptionDropdown
	)

	const { isDropdownOpen, openedByTouch, closeDropdown, toggleDropdownPanel, onOptionClick } = useDropdownPanel(
		inputRef,
		updateInternalValue,
		onClose,
		props.dropdownOpen
	)

	const internalErrorMessage = useValidateValue(
		internalValue.current,
		displayValue,
		props.isIntegerTime,
		props.error,
		props.required,
		minValueMinutes,
		maxValueMinutes
	)

	const parseTextValueAndUpdateInternal = getParseValueAndUpdateInternal(
		updateInternalValue,
		setDisplayValue,
		props.isIntegerTime
	)

	useEffect(() => {
		if (props.initialValue) {
			isInputFocused.current = true
			parseTextValueAndUpdateInternal(props.initialValue)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleTextValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		parseTextValueAndUpdateInternal(event.target.value)
	}

	const onBlur = (e: React.FocusEvent) => {
		isInputFocused.current = false
		props.setLogicalFocus?.(false)
		if (anchorElementRef.current?.contains(e.relatedTarget as Node)) {
			return
		}

		if (internalValue.current === undefined && props.cannotBeBlank) {
			internalValue.current = 0
		}

		updateInternalValue(internalValue.current, { updateDisplayValue: true, raiseValueChanged: false })

		if (props.onBlur) {
			const externalFormat = convertToExternalFormatFunc(internalValue.current)
			props.onBlur(externalFormat)
		}
	}

	const incrementCurrentValue = useIncrementCurrentValue(
		internalValue,
		minValueMinutes,
		maxValueMinutes,
		optionResolutionMinutes,
		props.isIntegerTime,
		updateInternalValue
	)

	const { placeholder, readOnlyIndicatorStyle } = { ...useStyleContext().style?.input }

	const handleInputFocus = () => {
		props.setLogicalFocus?.(true)
		isInputFocused.current = true
	}

	const { onMouseOver, onMouseOut } = getLogicalFocusAndHoverHandlers(props.setLogicalHover, props.setLogicalFocus)
	const { handleKeyDown } = getEventHandlers(
		openedByTouch,
		isDropdownOpen,
		incrementCurrentValue,
		props.readOnly,
		props.disabled,
		props.defaultAction,
		props.onKeyDown
	)

	const contentCSS = classNames(
		{
			[classes.alignRight]: props.textAlignment === 'right',
			[classes.alignCenter]: props.textAlignment === 'center',
		},
		classes.input
	)

	return (
		<>
			<FormControl
				id={id}
				label={props.label}
				labelPosition={props.labelPosition}
				hideLabel={props.hideLabel}
				labelProps={props.labelProps}
				labelIcon={props.labelIcon}
				labelIconColor={props.labelIconColor}
				labelBold={props.labelBold}
				labelContentLayout={props.labelContentLayout}
				labelSubText={props.labelSubText}
				validationText={props.validationText}
				validationTextPosition={props.validationTextPosition}
				subText={internalErrorMessage || props.subText}
				reserveHelperTextSpace={props.reserveHelperTextSpace}
				disabled={props.disabled}
				readOnly={props.readOnly}
				required={props.required}
				ref={anchorElementRef}
				error={props.error || internalErrorMessage !== undefined}
				warning={props.warning}
				margin={props.margin}
				className={props.className}
				disableBorder={props.disableBorder}
				logicalHover={props.logicalHover}
				logicalFocus={props.logicalFocus}
				screenTip={props.screenTip}
			>
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
				)}
				<input
					disabled={props.disabled}
					className={contentCSS}
					id={id}
					{...props.dataAttributes}
					ref={inputRef}
					placeholder={props.placeholder ?? placeholder}
					value={displayValue}
					type="text"
					inputMode="decimal"
					onChange={handleTextValueChange}
					onKeyDown={handleKeyDown}
					onBlur={onBlur}
					readOnly={props.readOnly}
					name={props.name}
					spellCheck={false}
					autoComplete="off" // autoComplete="off" does now work with Chrome
					onMouseOver={onMouseOver}
					onMouseOut={onMouseOut}
					onFocus={handleInputFocus}
				/>
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
				)}

				{displayOptionDropDown && (
					<FormControlButton
						disabled={props.disabled || props.readOnly}
						onMouseDown={(e) => isDropdownOpen && e.preventDefault()}
						onClick={toggleDropdownPanel}
						iconClassName="Fluent-ChevronDown"
						isActive={isDropdownOpen}
						onTouchStart={() => (openedByTouch.current = true)}
						onBlur={onBlur}
						tabStop={false}
						iconSize="extraSmall" // to avoid massive chevron
					/>
				)}
				{props.defaultAction && !props.hideActionButton && (
					<FormControlButton
						iconClassName={actionIcon}
						screenTip={props.actionScreenTip}
						onClick={!props.disabled ? props.defaultAction : undefined}
						disabled={props.disabled}
						ref={props.actionButtonRef}
						dataAttributes={props.dataAttributes}
					/>
				)}
			</FormControl>
			{displayOptionDropDown && (
				<DropdownList
					disableCloseOnViewportChange={openedByTouch.current}
					anchorElement={anchorElementRef}
					maxHeight={200}
					isOpen={isDropdownOpen}
					options={options}
					onChange={onOptionClick}
					onClose={closeDropdown}
					multiSelect={false}
					typeJumping
					preselectedIndex={dropdownIndex}
				/>
			)}
		</>
	)
})

DurationInput.displayName = 'DurationInput'
