import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import type { StyleObject } from '../../controls/utils/useMergeStyles'
import { useMergeStyles } from '../../controls/utils/useMergeStyles'

const classes = createStyle((theme) => ({
	card: {
		background: theme.colors.body.background,
		boxShadow: theme.shadows.light,
	},
}))

interface ICardProps {
	id?: string
	children?: React.ReactNode
	margin?: StyleObject
	className?: string
}

export const Card = (props: ICardProps) => {
	const style = useMergeStyles({ margin: props.margin }, [props.margin])

	return (
		<div className={classNames(classes.card, props.className)} style={style} key={props.id} data-cy={props.id}>
			{props.children}
		</div>
	)
}
