import React from 'react'

import { createStyle } from '@genusbiz/web-ui/theming'

import { OperatorK8sRuntimeTable } from '../Runtimes/RuntimeTable'
import { OperatorEndpointTable } from '../Endpoints/OperatorEndpointTable'
import { EnvironmentAvailability } from '../EnvironmentAvailabilitySchedule/EnvironmentAvailability'

import { e_NavigationItem } from 'enums/e_NavigationItems'
import { Workloads } from '../Workloads/Workloads'
import { OperatorErrorLog } from '../OperatorErrorLog/OperatorErrorLog'
import { Navigate, Route, Routes } from 'react-router-dom'

const classes = createStyle({
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
})

export const OperatorMainPanel = () => {
	return (
		<div className={classes.container}>
			<Routes>
				<Route
					path={`/${e_NavigationItem.runtimes}/*`}
					element={
						<>
							<OperatorK8sRuntimeTable />
						</>
					}
				/>
				<Route
					path={`/${e_NavigationItem.endpoints}/*`}
					element={
						<>
							<OperatorEndpointTable />
						</>
					}
				/>
				<Route path={`/${e_NavigationItem.availability}/*`} element={<EnvironmentAvailability />} />
				<Route
					path={`/${e_NavigationItem.workloads}/*`}
					element={
						<div className={classes.container}>
							<Workloads />
						</div>
					}
				/>
				<Route path={`/${e_NavigationItem.errorLog}/*`} element={<OperatorErrorLog />} />
				{/*Default is runtimes*/}
				<Route path={'/'} element={<Navigate to={`/${e_NavigationItem.runtimes}`} replace />} />
			</Routes>
		</div>
	)
}
