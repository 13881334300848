import { useCallback, useContext } from 'react'
import { SharedContextMenuContext } from './SharedContextMenu.context'
import type { MenuItem } from '../Menu/MenuItem.types'

export function useContextMenu(menuEntries: MenuItem[] | (() => MenuItem[] | undefined) | undefined) {
	const contextMenuContext = useContext(SharedContextMenuContext)
	return useCallback(
		(e: React.MouseEvent | MouseEvent) => {
			const menuContent = menuEntries instanceof Function ? menuEntries() : menuEntries

			e.preventDefault()
			e.stopPropagation()
			contextMenuContext.openContextMenu?.(e, menuContent)
		},
		[menuEntries]
	)
}
