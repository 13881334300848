import { createStyle } from '@genusbiz/web-ui/theming'
import React from 'react'
import { IEnvironmentAvailability } from 'src/interfaces/IAvailabilityWindow'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { AvailabilityWindowGroup } from './AvailabilityWindowGroup'
import { Text } from '@genusbiz/web-ui/controls'
import { useTranslation } from 'react-i18next'
import { NewAvailabilityWindowGroup } from './AvailabilityWindowEditor/NewAvailabilityWindowGroup'

const classes = createStyle({
	container: {
		marginTop: '8px',
	},
	availabilitySchedule: {
		marginLeft: '8px',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: '8px',
		marginBottom: '8px',
	},
})
interface IProps {
	environmentAvailability: IEnvironmentAvailability
	selectedEnvironment: e_EnvironmentOperatingType
}

export const EnvironmentAvailabilitySchedule = (props: IProps) => {
	const { t } = useTranslation()
	return (
		<div className={classes.container}>
			<Text variant="h3">{t('AVAILABILITY_WINDOW:SCHEDULE')}</Text>
			<div className={classes.availabilitySchedule}>
				{props.environmentAvailability.availabilityGroups.map((availabilityGroup) => {
					return (
						<AvailabilityWindowGroup
							selectedEnvironment={props.selectedEnvironment}
							key={availabilityGroup.id}
							availabilityGroup={availabilityGroup}
						/>
					)
				})}
			</div>
			<NewAvailabilityWindowGroup selectedEnvironment={props.selectedEnvironment} />
		</div>
	)
}
