import { IOperatorRootState } from 'src/reducers'

const selectPreferredLanguge = (state: IOperatorRootState) => state.userSettings.language
const selectPreferredFormattingCulture = (state: IOperatorRootState) => state.userSettings.formattingCulture
const selectSelecedTimezone = (state: IOperatorRootState) => state.userSettings.timezone

export const userSettingsSelectors = {
	selectPreferredLanguge,
	selectPreferredFormattingCulture,
	selectSelecedTimezone,
}
