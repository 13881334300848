import { useRef } from 'react'
import { useEventListener } from '../../utils/useEventListener'

import type { AgGridReact } from '@ag-grid-community/react'
import type { TData } from '../Table.types'

export const useArrowSelectionOnKeyUp = (
	gridRef: React.RefObject<AgGridReact<TData>>,
	onSelectionChange?: ((ids: string[]) => void) | undefined
) => {
	const newSelectionFromArrowEvents = useRef(false)

	const timeout = useRef(0)
	const onKeyUp = (e: React.KeyboardEvent) => {
		clearTimeout(timeout.current)
		timeout.current = window.setTimeout(() => {
			if (!gridRef.current) {
				return
			}

			if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
				if (newSelectionFromArrowEvents.current) {
					const selectedNodes = gridRef.current.api.getSelectedNodes()

					const selectedIds: string[] = selectedNodes.map((row) => row.data?.id as string)

					onSelectionChange?.(selectedIds)

					newSelectionFromArrowEvents.current = false
				}
			}
		}, 200)
	}

	useEventListener('keyup', onKeyUp)

	return newSelectionFromArrowEvents
}
