import moment from 'moment'
import numeral from 'numeral'
import { TICKS_PER_MINUTE } from './consts'

export const getInternalFormatFromTicksMS = (valueTicksMs: number | undefined | null): number | undefined => {
	if (valueTicksMs === undefined || valueTicksMs === null) {
		return undefined
	}
	return valueTicksMs
}

const getInternalFormatFromIntegerTime = (integerTimeValue: number | undefined | null): number | undefined => {
	if (integerTimeValue === undefined || integerTimeValue === null) {
		return undefined
	}

	const hours = Math.floor(integerTimeValue / 100)
	const minutes = Math.floor(integerTimeValue % 100)

	return (hours * 60 + minutes) * TICKS_PER_MINUTE
}

export const getInternalFormatConverter = (isIntegerTime: boolean | undefined) => {
	return isIntegerTime ? getInternalFormatFromIntegerTime : getInternalFormatFromTicksMS
}

const getIntegerTimeFromInternalFormat = (durationTicksMS: number | undefined): number | undefined => {
	if (durationTicksMS === undefined) {
		return undefined
	}

	if (durationTicksMS === 0) {
		return 0
	}

	const duration = moment.duration(durationTicksMS)

	if (!duration.isValid()) {
		return undefined
	}

	return duration.hours() * 100 + duration.minutes()
}

const getTicksMSFromInternalFormat = (durationTicksMS: number | undefined): number | undefined => {
	return durationTicksMS
}

export const getExternalFormatConverter = (isIntegerTime: boolean | undefined) => {
	return isIntegerTime ? getIntegerTimeFromInternalFormat : getTicksMSFromInternalFormat
}

const formatIntegerTimeValue = (durationTicksMS: number | undefined) => {
	if (durationTicksMS === undefined) {
		return ''
	}

	const duration = moment.duration(durationTicksMS)

	if (!duration.isValid()) {
		return ''
	}

	const momentTimeOfDay = moment({
		hour: duration.hours(),
		minute: duration.minutes(),
	})

	return momentTimeOfDay.format('LT')
}

const formatDurationValue = (durationTicksMS: number | undefined) => {
	if (durationTicksMS === undefined) {
		return ''
	}

	if (durationTicksMS === 0) {
		return '00:00'
	}

	const duration = moment.duration(durationTicksMS)

	if (!duration.isValid()) {
		return ''
	}

	const hours = Math.floor(duration.asHours())
	const minutes = Math.floor(duration.minutes())

	return numeral(hours).format('00') + ':' + numeral(minutes).format('00')
}

export const getFormatDurationFunc = (isIntegerTime: boolean | undefined) => {
	return isIntegerTime ? formatIntegerTimeValue : formatDurationValue
}
