import React from 'react'
import type { ICommandBarClasses } from './CommandBar'

interface ICommandBarDividerProps {
	classes: ICommandBarClasses
	id?: string
}

export const CommandBarDivider = (props: ICommandBarDividerProps) => {
	return (
		<div className={props.classes.separator} id={props.id} onClick={(e: React.MouseEvent) => e.stopPropagation()} />
	)
}
