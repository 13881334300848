import React from 'react'
import { SharedContextMenuConsumer } from './SharedContextMenu.context'
import { Menu } from '../Menu/Menu'
import type { IContextMenuContext } from './IContextMenu'

export const SharedContextMenu = () => {
	return (
		<SharedContextMenuConsumer>
			{(consumerContext: IContextMenuContext) => {
				return (
					<Menu
						open={consumerContext.contextMenuIsOpen}
						onClose={consumerContext.closeContextMenu}
						items={consumerContext.contextMenuContent}
						position={consumerContext.position}
					/>
				)
			}}
		</SharedContextMenuConsumer>
	)
}
