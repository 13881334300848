import { parseISO, formatDistance } from 'date-fns'
import moment from 'moment-timezone'
import { e_Weekday } from 'src/enums/e_Weekday'
import { IWeekdayTime, e_RelativeWeekDay } from 'src/interfaces/IAvailabilityWindow'

const MINUTES_IN_HOUR = 60
const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24
const MINUTES_IN_WEEK = MINUTES_IN_DAY * 7

const weekDayNameArray = Object.values(e_Weekday)

const convertDateTime = (dateTimeString: string, format: string) => {
	const dateTimeInCorrectTimezone = moment(dateTimeString)
	return dateTimeInCorrectTimezone.format(format)
}

const formatIntegerTime = (integerTime: number): string => {
	const hours = Math.floor(integerTime / 100)
	const minutes = Math.floor(integerTime % 100)
	return moment.tz().set({ hour: hours, minute: minutes }).format('LT')
}

const convertIntegerToHoursMinutes = (int: number) => {
	const hours = Math.floor(int / 60)
	const minutes = Math.floor(int % 60)

	let h = hours.toString()
	if (hours >= 10) {
		h = `+${hours}`
	} else if (hours >= 0) {
		h = `+0${hours}`
	} else if (hours > -10) {
		h = `-0${hours * -1}`
	}

	const m = minutes < 10 ? '0' + minutes : minutes
	return `${h}:${m}`
}

const getCurrentWeekday = () => {
	return weekDayNameArray[moment.utc().isoWeekday() - 1]
}
const getWeekDayAfter = (weekDay: e_Weekday) => {
	const values = Object.values(e_Weekday)
	return values[(values.indexOf(weekDay) + 1) % values.length]
}
const convertUTCtoLocalTime = (value: string, seconds?: boolean) => {
	if (!value) {
		return ''
	}
	return moment(parseISO(value)).format(`LT${seconds ? 'S' : ''}`)
}

const convertUTCtoUTCString = (value: string, hideDate?: boolean, seconds?: boolean) => {
	if (!value) {
		return ''
	}
	return moment.utc(value).format(`LT${seconds ? 'S' : ''} ${hideDate ? '' : 'L'}`)
}

const convertUTCtoRelativeTime = (value: string, showSuffix: boolean) => {
	if (!value) {
		return ''
	}
	return formatDistance(parseISO(value), new Date(), { addSuffix: showSuffix })
}

const convertWeekdayTimeFromOneTimezoneToAnother = (
	weekDay: e_Weekday | e_RelativeWeekDay,
	intergerTime: number,
	sourceTimezone: string,
	targetTimezone: string
) => {
	const sourceHours = Math.floor(intergerTime / 100)
	const sourceMinutes = Math.floor(intergerTime % 100)

	const sourceOffset = moment.tz(sourceTimezone).utcOffset()
	const targetOffset = moment.tz(targetTimezone).utcOffset()

	const timezoneOffset = sourceOffset - targetOffset

	let targetHours =
		timezoneOffset < 0 ? sourceHours - Math.ceil(timezoneOffset / 60) : sourceHours - Math.floor(timezoneOffset / 60)
	let targetMinutes = timezoneOffset < 0 ? sourceMinutes + (timezoneOffset % 60) : sourceMinutes - (timezoneOffset % 60)

	if (targetMinutes >= 60) {
		targetHours = timezoneOffset <= 0 ? targetHours + 1 : targetHours - 1
		targetMinutes -= 60
	} else if (targetMinutes < 0) {
		targetHours = timezoneOffset <= 0 ? targetHours + 1 : targetHours - 1
		targetMinutes += 60
	}

	let newWeekday
	if (Object.values(e_Weekday).includes(weekDay as e_Weekday)) {
		if (targetHours < 0) {
			newWeekday = weekDayNameArray.at(weekDayNameArray.indexOf(weekDay as e_Weekday) - 1) as e_Weekday
			targetHours += 24
		} else if (targetHours >= 24) {
			newWeekday = weekDayNameArray.at(weekDayNameArray.indexOf(weekDay as e_Weekday) + 1) as e_Weekday
			targetHours -= 24
		} else {
			newWeekday = weekDay
		}
	} else {
		/**
		 * This is a simplification, as relativeWeekdays are only used for toDay, it can never be a "previous day".
		 * It can only stay the same, or go from "next day" to "same day", or from "same day" to "next day".
		 */
		if (targetHours < 0) {
			newWeekday = e_RelativeWeekDay.sameDay
			targetHours += 24
		} else if (targetHours >= 24) {
			newWeekday = e_RelativeWeekDay.nextDay
			targetHours -= 24
		} else {
			newWeekday = weekDay
		}
	}
	const newIntegerTime = targetHours * 100 + targetMinutes

	return { weekDayName: newWeekday, time: newIntegerTime }
}

const getWeekdayFromRelativeDay = (fromDay: e_Weekday, toDay: e_Weekday | e_RelativeWeekDay): e_Weekday => {
	if (Object.values(e_Weekday).includes(toDay as e_Weekday)) {
		return toDay as e_Weekday
	} else {
		return toDay === e_RelativeWeekDay.sameDay ? fromDay : getWeekDayAfter(fromDay)
	}
}

const convertDayAndIntegerTimeToLocaleLocalTime = (input: IWeekdayTime): { time: string; weekDayName: e_Weekday } => {
	let hours = Math.floor(input.time / 100)
	let minutes = Math.floor(input.time % 100)

	const timezoneOffset = moment().utcOffset()

	minutes = Math.floor(minutes + (timezoneOffset % 60))
	if (minutes >= 60) {
		hours += 1
		minutes -= 60
	}

	let newIntegerTime = Math.floor(hours + timezoneOffset / 60) * 100 + minutes
	let newWeekday = input.weekDayName
	if (newIntegerTime < 0) {
		newWeekday = weekDayNameArray.at(weekDayNameArray.indexOf(input.weekDayName) - 1) as e_Weekday
		newIntegerTime += 2400
	}
	if (newIntegerTime >= 2400) {
		newWeekday = weekDayNameArray.at(weekDayNameArray.indexOf(input.weekDayName) + 1) as e_Weekday
		newIntegerTime -= 2400
	}

	return { weekDayName: newWeekday, time: getFormattedTime(newIntegerTime) }
}

const getFormattedTime = (integerTime: number) => {
	if (integerTime < 100) {
		return `00:${integerTime.toString()}`
	} else if (integerTime >= 1000) {
		return `${integerTime.toString().slice(0, 2)}:${integerTime.toString().slice(2, 4)}`
	} else {
		return `0${integerTime.toString().slice(0, 1)}:${integerTime.toString().slice(1, 3)}`
	}
}

const convertIntegerTimeToLocaleUTCTime = (integerTime: number): string => {
	const hours = Math.floor(integerTime / 100)
	const minutes = Math.floor(integerTime % 100)

	return moment.utc(new Date().setUTCHours(hours, minutes)).format('LT')
}

const getMinutesSinceWeekStart = (weekdayTime: IWeekdayTime) => {
	const wholeDaysInMinutes = weekDayNameArray.indexOf(weekdayTime.weekDayName) * MINUTES_IN_DAY
	const hours = Math.floor(weekdayTime.time / 100)
	const wholeHoursInMinutes = hours * MINUTES_IN_HOUR
	const minutes = Math.floor(weekdayTime.time % 100)

	return wholeDaysInMinutes + wholeHoursInMinutes + minutes
}

const getDurationBetweenWeekdaysAndTimes = (from: IWeekdayTime, to: IWeekdayTime): number => {
	// If to is before from, wrap around the week
	if (getMinutesSinceWeekStart(from) > getMinutesSinceWeekStart(to)) {
		return MINUTES_IN_WEEK - (getMinutesSinceWeekStart(from) - getMinutesSinceWeekStart(to))
	} else {
		return getMinutesSinceWeekStart(to) - getMinutesSinceWeekStart(from)
	}
}
const getWeekDayTimeFromDuration = (from: IWeekdayTime, durationInMinutes: number): IWeekdayTime => {
	const days = Math.floor(durationInMinutes / MINUTES_IN_DAY)
	const hours = Math.floor((durationInMinutes % MINUTES_IN_DAY) / MINUTES_IN_HOUR)
	const minutes = durationInMinutes % MINUTES_IN_HOUR

	let weekDayIndex = weekDayNameArray.indexOf(from.weekDayName) + days

	// let newTime = from.time + (hours * 100 + minutes)
	let newHours = Math.floor((from.time + hours * 100) / 100)
	let newMinutes = Math.floor((from.time + minutes) % 100)

	if (newMinutes > 60) {
		newHours += 1
		newMinutes -= 60
	}
	let newTime = newHours * 100 + newMinutes
	if (newTime >= 2400) {
		weekDayIndex += 1
		newTime = newTime - 2400
	}
	const weekDay = weekDayNameArray[weekDayIndex % weekDayNameArray.length]
	return {
		weekDayName: weekDay,
		time: newTime,
		timezone: from.timezone,
	}
}

const getMomentFromWeekDayTime = (weekDayTime: IWeekdayTime) => {
	const fromIsoWeekday = weekDayNameArray.indexOf(weekDayTime.weekDayName) + 1
	const fromHours = Math.floor(weekDayTime.time / 100)
	const fromMinutes = Math.floor(weekDayTime.time % 100)

	const timezoneMoment = moment
		.tz(weekDayTime.timezone)
		.isoWeekday(fromIsoWeekday)
		.hour(fromHours)
		.minute(fromMinutes)
		.second(0)

	return timezoneMoment
}

window.moment = moment

export {
	getCurrentWeekday,
	getWeekDayAfter,
	formatIntegerTime,
	convertIntegerToHoursMinutes,
	convertDayAndIntegerTimeToLocaleLocalTime,
	convertIntegerTimeToLocaleUTCTime,
	convertUTCtoLocalTime,
	convertUTCtoRelativeTime,
	convertUTCtoUTCString,
	convertWeekdayTimeFromOneTimezoneToAnother,
	getDurationBetweenWeekdaysAndTimes,
	getWeekDayTimeFromDuration,
	getMomentFromWeekDayTime,
	convertDateTime,
	getFormattedTime,
	getWeekdayFromRelativeDay,
}
