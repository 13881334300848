import type { Measurer } from '../../controls/utils/Measurer'
import { e_Placement } from '../../enums/e_Placement'

export const PADDING = 5

export function getAlignment(placement: e_Placement) {
	return placement.endsWith('-start') ? 'start' : placement.endsWith('-end') ? 'end' : 'center'
}

export function getEdge(placement: e_Placement) {
	return placement.startsWith('top')
		? 'bottom'
		: placement.startsWith('right')
		  ? 'left'
		  : placement.startsWith('bottom')
		    ? 'top'
		    : 'right'
}

export function getFlippedEdge(edge: 'top' | 'right' | 'bottom' | 'left') {
	switch (edge) {
		case 'top':
			return 'bottom'
		case 'right':
			return 'left'
		case 'bottom':
			return 'top'
		case 'left':
			return 'right'
	}
}

export function getFlippedPlacement(placement: e_Placement) {
	switch (placement) {
		case e_Placement.topEnd:
			return e_Placement.bottomEnd
		case e_Placement.top:
			return e_Placement.bottom
		case e_Placement.topStart:
			return e_Placement.bottomStart
		case e_Placement.rightEnd:
			return e_Placement.leftEnd
		case e_Placement.right:
			return e_Placement.left
		case e_Placement.rightStart:
			return e_Placement.leftStart
		case e_Placement.bottomEnd:
			return e_Placement.topEnd
		case e_Placement.bottom:
			return e_Placement.top
		case e_Placement.bottomStart:
			return e_Placement.topStart
		case e_Placement.leftEnd:
			return e_Placement.rightEnd
		case e_Placement.left:
			return e_Placement.right
		case e_Placement.leftStart:
			return e_Placement.rightStart
	}
}

type edgeType = 'top' | 'right' | 'bottom' | 'left'
type alignmentType = 'center' | 'start' | 'end'

type fitCallout = 'ideal' | 'adjustPosition' | 'adjustSize'

const getPreferredEdgeOrder = (initialEdge: edgeType): edgeType[] => {
	switch (initialEdge) {
		case 'top':
			return ['top', 'bottom', 'right', 'left']
		case 'bottom':
			return ['bottom', 'top', 'right', 'left']
		case 'right':
			return ['right', 'top', 'bottom', 'left']
		case 'left':
			return ['left', 'bottom', 'top', 'right']
	}

	return []
}

const getPreferredAlignmentOrder = (initialEdge: alignmentType): alignmentType[] => {
	switch (initialEdge) {
		case 'center':
			return ['center', 'start', 'end']
		case 'start':
			return ['start', 'end', 'center']
		case 'end':
			return ['end', 'start', 'center']
	}

	return []
}

export interface ICalloutPosition {
	beakPos: number
	beakOffset: number
	edge: edgeType
	alignment: alignmentType
	placement: string
	maxWidth: number | undefined
	maxHeight: number | undefined
}
export function calcCalloutPosition(
	calloutContentMeasurer: Measurer,
	anchor: Measurer,
	placement: e_Placement,
	beakWidth: number,
	beakOffset: number,
	beakInsetFromCorner: number
): ICalloutPosition | undefined {
	const calloutSize = {
		width: calloutContentMeasurer.width,
		height: calloutContentMeasurer.height,
	}

	const windowBounds = {
		left: anchor.left - anchor.actualScreenSpaceLeft,
		top: anchor.top - anchor.actualScreenSpaceTop,
		right: anchor.right + anchor.actualScreenSpaceRight,
		bottom: anchor.bottom + anchor.actualScreenSpaceBottom,

		width: anchor.actualScreenSpaceRight + anchor.width + anchor.actualScreenSpaceLeft,
		height: anchor.actualScreenSpaceBottom + anchor.height + anchor.actualScreenSpaceTop,
	}

	// fallback order: edge: left <> top, right <> left, alignment: center <> start <> end
	// edge: top, bottom, right, left
	// alignment: center, start, end
	// that is, try to place on the opposide edge (top -> bottom -> right -> left) first,
	// then try to adjust alignment on the edge (center -> start -> end)
	// then try changing alignment on the orher edges as final resort

	const initialAlignment = placement.endsWith('-start') ? 'start' : placement.endsWith('-end') ? 'end' : 'center'

	const initialEdge = placement.startsWith('top')
		? 'top'
		: placement.startsWith('bottom')
		  ? 'bottom'
		  : placement.startsWith('left')
		    ? 'left'
		    : 'right'

	const alignments = getPreferredAlignmentOrder(initialAlignment)
	const edges = getPreferredEdgeOrder(initialEdge)

	// first iteration - try to position the callout with room for full content
	const idealCalloutPosition = calcPosition(
		anchor,
		calloutSize,
		windowBounds,
		edges,
		alignments,
		beakWidth,
		beakOffset,
		beakInsetFromCorner,
		'ideal'
	)

	if (idealCalloutPosition) {
		return idealCalloutPosition
	}

	// second iteration - try to position the callout with required size but position adjusted to fit
	const positionedCalloutPosition = calcPosition(
		anchor,
		calloutSize,
		windowBounds,
		edges,
		['start'],
		beakWidth,
		beakOffset,
		beakInsetFromCorner,
		'adjustPosition'
	)

	if (positionedCalloutPosition) {
		return positionedCalloutPosition
	}

	// third iteration, the size of the callout may also be adjusted
	const sizedCalloutPosition = calcPosition(
		anchor,
		calloutSize,
		windowBounds,
		edges,
		['start'],
		beakWidth,
		beakOffset,
		beakInsetFromCorner,
		'adjustSize'
	)

	if (sizedCalloutPosition) {
		return sizedCalloutPosition
	}

	return undefined
}

const calcPosition = (
	anchor: Measurer,
	calloutSize: { width: number; height: number },
	windowBounds: { left: number; top: number; right: number; bottom: number; width: number; height: number },
	edges: edgeType[],
	alignments: alignmentType[],
	beakWidth: number,
	beakOffset: number,
	beakInsetFromCorner: number,
	desiredFit: fitCallout
): ICalloutPosition | undefined => {
	const anchorCenter = {
		x: anchor.left + anchor.width / 2,
		y: anchor.top + anchor.height / 2,
	}

	const halfBeakSize = beakWidth / 2
	let maxWidth: number | undefined = undefined
	let maxHeight: number | undefined = undefined

	for (const currentEdge of edges) {
		// check if there is room for the callout on the edge. If there is, loop to the next edge
		if (calloutHasRoom(currentEdge, anchor, beakOffset, halfBeakSize, calloutSize, windowBounds)) {
			continue
		}

		for (const currentAlignment of alignments) {
			let insetRelativeToAnchorCenter = 0

			switch (currentEdge) {
				case 'top':
				case 'bottom': {
					switch (currentAlignment) {
						case 'center': {
							if (anchorCenter.x - calloutSize.width / 2 < windowBounds.left) {
								continue
							}

							if (anchorCenter.x + calloutSize.width / 2 >= windowBounds.right) {
								continue
							}

							break
						}

						case 'start': {
							let calloutLeft = beakWidth > 0 ? anchorCenter.x - (halfBeakSize + beakInsetFromCorner) : anchor.left

							if (desiredFit === 'adjustPosition' || desiredFit === 'adjustSize') {
								const maxCalloutLeft = Math.max(windowBounds.right - calloutSize.width, windowBounds.left)
								calloutLeft = Math.min(calloutLeft, maxCalloutLeft)
							}

							if (calloutLeft < windowBounds.left) {
								continue
							}

							if (calloutLeft + calloutSize.width > windowBounds.right) {
								if (desiredFit === 'adjustSize') {
									// we might have to adjust the size in width to make the height fit
									maxWidth = windowBounds.right - calloutLeft
								} else {
									continue
								}
							}

							insetRelativeToAnchorCenter = anchorCenter.x - calloutLeft

							break
						}

						case 'end': {
							const calloutRight = beakWidth > 0 ? anchorCenter.x + (halfBeakSize + beakInsetFromCorner) : anchor.right

							if (calloutRight > windowBounds.right) {
								continue
							}

							if (calloutRight - calloutSize.width < windowBounds.left) {
								continue
							}

							insetRelativeToAnchorCenter = calloutRight - anchorCenter.x

							break
						}
					}
					break
				}
				case 'left':
				case 'right': {
					switch (currentAlignment) {
						case 'center': {
							if (anchorCenter.y - calloutSize.height / 2 < windowBounds.top) {
								continue
							}

							if (anchorCenter.y + calloutSize.height / 2 >= windowBounds.bottom) {
								continue
							}

							break
						}

						case 'start': {
							let calloutTop = beakWidth > 0 ? anchorCenter.y - (halfBeakSize + beakInsetFromCorner) : anchor.top

							if (desiredFit === 'adjustPosition' || desiredFit === 'adjustSize') {
								const maxCalloutTop = Math.max(windowBounds.bottom - calloutSize.height, windowBounds.top)
								calloutTop = Math.min(calloutTop, maxCalloutTop)
							}

							if (calloutTop < windowBounds.top) {
								continue
							}

							if (calloutTop + calloutSize.height > windowBounds.bottom) {
								if (desiredFit === 'adjustSize') {
									// we might have to adjust the size in width to make the height fit
									maxHeight = windowBounds.bottom - calloutTop
								} else {
									continue
								}
							}

							insetRelativeToAnchorCenter = anchorCenter.y - calloutTop

							break
						}

						case 'end': {
							const calloutBottom =
								beakWidth > 0 ? anchorCenter.y + (halfBeakSize + beakInsetFromCorner) : anchor.bottom

							if (calloutBottom > windowBounds.bottom) {
								continue
							}

							if (calloutBottom - calloutSize.height < windowBounds.top) {
								continue
							}

							insetRelativeToAnchorCenter = calloutBottom - anchorCenter.y

							break
						}
					}
					break
				}
			}

			// if we manage to get here, we have found a spot where the callout may be placed
			return {
				beakPos: currentEdge === 'top' || currentEdge === 'bottom' ? anchorCenter.x : anchorCenter.y,
				beakOffset: insetRelativeToAnchorCenter,
				edge: currentEdge,
				maxWidth: maxWidth,
				maxHeight: maxHeight,
				alignment: currentAlignment,
				placement: `${currentEdge}${
					currentAlignment === 'start' || currentAlignment === 'end' ? '-' + currentAlignment : ''
				}`,
			}
		}
	}

	return undefined
}

const calloutHasRoom = (
	currentEdge: edgeType,
	anchor: Measurer,
	beakOffset: number,
	halfBeakSize: number,
	calloutSize: { width: number; height: number },
	windowBounds: { left: number; top: number; right: number; bottom: number; width: number; height: number }
) => {
	if (
		currentEdge === 'bottom' &&
		anchor.bottom + beakOffset + halfBeakSize + calloutSize.height >= windowBounds.bottom
	) {
		return true
	}
	if (currentEdge === 'top' && anchor.top - (beakOffset + halfBeakSize + calloutSize.height) < windowBounds.top) {
		return true
	}
	if (currentEdge === 'right' && anchor.right + beakOffset + halfBeakSize + calloutSize.width >= windowBounds.right) {
		return true
	}
	if (currentEdge === 'left' && anchor.left - (beakOffset + halfBeakSize + calloutSize.width) < windowBounds.left) {
		return true
	}
	return false
}
