import { getCodeLanguages, CODE_LANGUAGE_MAP, CODE_LANGUAGE_FRIENDLY_NAME_MAP } from '@lexical/code'

const CODE_LANGUAGE_MAP_EXTENDED: Record<string, string> = {
	...CODE_LANGUAGE_MAP,
	txt: 'plain',
	yaml: 'yml',
}

const CODE_LANGUAGE_FRIENDLY_NAME_MAP_EXTENDED: Record<string, string> = {
	...CODE_LANGUAGE_FRIENDLY_NAME_MAP,
	atom: 'Atom',
	markup: 'Markup',
	mathml: 'MathML',
	objectivec: 'Objective-C',
	rss: 'RSS',
	ssml: 'SSML',
	svg: 'SVG',
	yml: 'YAML',
}

export function getLanguageFriendlyName(language: string) {
	const normalizedLang = normalizeLanguage(language)
	return CODE_LANGUAGE_FRIENDLY_NAME_MAP_EXTENDED[normalizedLang] ?? normalizedLang
}

export function normalizeLanguage(language: string) {
	return CODE_LANGUAGE_MAP_EXTENDED[language] || language
}
export function getLanguageItems() {
	const langNames = new Set<string>()
	return getCodeLanguages()
		.map((l) => {
			const normalizedLang = normalizeLanguage(l)
			const name = getLanguageFriendlyName(l) ?? l
			if (langNames.has(normalizedLang)) {
				return null
			}
			langNames.add(normalizedLang)
			return { name, value: normalizedLang }
		})
		.filter((e): e is NonNullable<typeof e> => e !== null)
}
