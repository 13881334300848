import { Selection, packSiblings, packEnclose } from 'd3'
import { e_ClusterDiagramNodeType } from 'src/enums/e_ClusterDiagramNodeType'
import { ID3ClusterDiagramNode, ID3ClusterDiagramPod } from 'src/interfaces/IClusterDiagram'
import { universalStyling } from './ClusterDiagramD3Styling'
import { IPod } from 'src/interfaces/IPod'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'

const FONT_SIZE_NODE = 18
const LABEL_TO_NODE_GAP = 8
const NODE_BORDER_SIZE = 4
const SPACE_BETWEEN_PODS = 8
const CIRCLE_PADDING = 8
const NAMESPACE_WIDTH = 50
const NAMESPACE_HEIGHT = 50

const BORDER_PADDING = 16

const LEFT_BOUNDARY = 0
const TOP_BOUNDARY = 0

const NODE_LEFT_BOUNDARY = LEFT_BOUNDARY + NODE_BORDER_SIZE
const NODE_TOP_BOUNDARY = TOP_BOUNDARY + FONT_SIZE_NODE + LABEL_TO_NODE_GAP + NODE_BORDER_SIZE + BORDER_PADDING

export const drawNodes = (
	nodeGroup: Selection<SVGGElement, unknown, null, undefined>,
	nodeDataSet: ID3ClusterDiagramNode[],
	isNodeSelected: (d: ID3ClusterDiagramNode) => boolean,
	setSelectedWorkloadController: (resource: IWorkloadController | null) => void,
	isPodSelected: (d: ID3ClusterDiagramPod) => boolean,
	hoveredNode: ID3ClusterDiagramNode | null,
	hoveredNodeRef: React.MutableRefObject<SVGGElement | null>,
	setHoveredNode: (d: ID3ClusterDiagramNode | null) => void,
	selectedPods: IPod[],
	setSelectedPods: (pod: IPod[]) => void,
	hoveredPodRef: React.MutableRefObject<SVGGElement | null>,
	setHoveredPod: (d: ID3ClusterDiagramPod | null) => void
) => {
	const onNodeClick = (event: PointerEvent, d: ID3ClusterDiagramNode) => {
		event.stopPropagation()
		if (isNodeSelected(d) && event.ctrlKey) {
			setSelectedWorkloadController(null)
		} else {
			setSelectedWorkloadController(d.workload)
		}
	}
	const squareNode = nodeGroup
		.selectAll<SVGGElement, ID3ClusterDiagramNode>('g.squareNode')
		.data(
			nodeDataSet.filter(
				(d) => d.nodeType === e_ClusterDiagramNodeType.namespace,
				(d: ID3ClusterDiagramNode) => d.id
			)
		)
		.join((enter) =>
			enter
				.append('g')
				.attr('class', 'squareNode')
				.call((g) => g.append('rect').attr('class', 'node'))
				.call((g) => g.append('text'))
		)
		.on('mouseover', (_, d) => setHoveredNode(d))
		.on('mouseleave', () => setHoveredNode(null))
		.on('click', onNodeClick)

	squareNode
		.select('rect.node')
		.attr('width', NAMESPACE_WIDTH)
		.attr('height', NAMESPACE_HEIGHT)
		.attr('stroke-width', NODE_BORDER_SIZE)
		.attr('stroke', (d) => d.strokeColor!.color!)
		.attr('fill', universalStyling.nodeBackgroundColor)
		.style('cursor', 'pointer')

	const node = nodeGroup
		.selectAll<SVGGElement, ID3ClusterDiagramNode>('g.node')
		.data(
			nodeDataSet.filter((d) => d.nodeType !== e_ClusterDiagramNodeType.namespace),
			(d: ID3ClusterDiagramNode) => d.id
		)
		.join((enter) =>
			enter
				.append('g')
				.attr('class', 'node')
				.call((g) => g.append('circle').attr('class', 'node'))
				.call((g) => g.append('circle').attr('class', 'selectionIndicator'))
				.call((g) => g.append('text'))
		)
		.on('mouseover', function (_, d) {
			setHoveredNode(d)
			hoveredNodeRef.current = this
		})
		.on('mouseleave', function () {
			setHoveredNode(null)
			hoveredNodeRef.current = null
		})
		.on('click', onNodeClick)

	node.each(function (dNode) {
		if (dNode.children === null || dNode.children.length === 0) {
			return
		}

		packSiblings(dNode.children)

		dNode.r = packEnclose(dNode.children as d3.PackCircle[]).r + CIRCLE_PADDING
		dNode.children = dNode.children.map((pod) => ({
			...pod,
			parent: dNode,
			xOffset: pod.x || 0,
			yOffset: pod.y || 0,
		}))
	})

	node.style('cursor', 'pointer')

	const nodeStrokeColor = (node: ID3ClusterDiagramNode) =>
		isNodeSelected(node) || hoveredNode?.id === node.id ? node.strokeColor!.dark! : node.strokeColor!.color!

	node
		.select('circle.node')
		.attr('r', (d) => d.r)
		.attr('fill', universalStyling.nodeBackgroundColor)
		.style('opacity', 0.95)
		.attr('stroke', nodeStrokeColor)
		.attr('stroke-width', NODE_BORDER_SIZE)

	node
		.select('circle.selectionIndicator')
		.attr('r', (d) => d.r + 6)
		.attr('fill', 'transparent')
		.attr('stroke', (d) => (isNodeSelected(d) ? d.strokeColor!.dark! : 'transparent'))
		.attr('stroke-width', 2)
		.style('stroke-dasharray', '2 2')

	const fontWeight = (d: ID3ClusterDiagramNode) =>
		isNodeSelected(d) || d.id === hoveredNode?.id ? universalStyling.boldFontWeight : universalStyling.regularFontWeight
	const nodeLabels = node.select<SVGTextElement>('text')
	nodeLabels
		.attr('x', 0)
		.attr('y', (d) => -d.r)
		.text((d) => d.deploymentName)
		.style('user-select', 'none')
		.style('font-size', FONT_SIZE_NODE)
		.style('text-anchor', 'middle')
		.style('font-weight', fontWeight)
	const squareNodeLabels = squareNode.select<SVGTextElement>('text')
	squareNodeLabels
		.attr('x', 0)
		.attr('y', (d) => -d.r)
		.text((d) => d.deploymentName)
		.style('user-select', 'none')
		.style('font-size', FONT_SIZE_NODE)
		.style('text-anchor', 'middle')
		.style('font-weight', fontWeight)

	const pod = node
		.selectAll<SVGGElement, ID3ClusterDiagramPod>('g.pod')
		.data((d) => d.children)
		.join((enter) =>
			enter
				.append('g')
				.attr('class', 'pod')
				.call((g) => g.append('circle').attr('class', 'pod'))
				.call((g) => g.append('circle').attr('class', 'selectionIndicator'))
		)
		.on('click', (event: PointerEvent, d) => {
			event.stopPropagation()
			if (event.ctrlKey) {
				if (selectedPods.some((pod) => pod.id === d.pod.id)) {
					setSelectedPods(selectedPods.filter((pod) => pod.id !== d.pod.id))
				} else {
					setSelectedPods([...selectedPods, d.pod])
				}
			} else {
				setSelectedPods([d.pod])
			}
		})
		.on('mouseover', function (_, d) {
			setHoveredPod(d)
			hoveredPodRef.current = this
		})
		.on('mouseleave', function () {
			setHoveredPod(null)
			hoveredPodRef.current = null
		})
	pod.attr('transform', (d) => `translate(${d.x!},${d.y!})`)

	pod
		.select('circle.pod')
		.attr('r', (d) => d.r - SPACE_BETWEEN_PODS)
		.attr('fill', (d) => d.color!.color!)
		.attr('stroke', (d) => (isPodSelected(d) ? d.color!.veryDark! : d.color!.dark!))
		.attr('stroke-width', 2)

	pod
		.select('circle.selectionIndicator')
		.attr('r', (d) => d.r - SPACE_BETWEEN_PODS + 4)
		.attr('fill', 'transparent')
		.attr('stroke', (d) => (isPodSelected(d) ? d.color!.veryDark! : 'transparent'))
		.attr('stroke-width', 2)
		.attr('stroke-dasharray', '2 2')

	const updateNodePositions = (width: number, height: number) => {
		node.each((node) => {
			node.x = fitBetweenNodeXBoundaries(node.x!, node.r, node.deploymentName.length, width)
			node.y = fitBetweenNodeYBoundaries(node.y!, node.r, height)
		})
		node.attr('transform', (d) => `translate(${d.x!},${d.y!})`)
		squareNode.each((node) => {
			node.x = fitBetweenNodeXBoundaries(node.x!, node.r, node.deploymentName.length, width)
			node.y = fitBetweenNodeYBoundaries(node.y!, node.r, height)
		})
		squareNode.attr('transform', (d) => `translate(${d.x! - NAMESPACE_WIDTH / 2},${d.y! - NAMESPACE_HEIGHT / 2})`)

		nodeLabels.attr('y', (d) => {
			const isInTopHalf = d.y! < height / 2
			d.isInTopHalf = isInTopHalf
			return isInTopHalf
				? -(LABEL_TO_NODE_GAP + NODE_BORDER_SIZE + d.r)
				: FONT_SIZE_NODE + LABEL_TO_NODE_GAP + NODE_BORDER_SIZE + d.r - 2
		})
		squareNodeLabels.attr('y', (d) => {
			const isInTopHalf = d.y! < height / 2
			d.isInTopHalf = isInTopHalf
			return isInTopHalf
				? -(LABEL_TO_NODE_GAP + NODE_BORDER_SIZE + d.r)
				: FONT_SIZE_NODE + LABEL_TO_NODE_GAP + NODE_BORDER_SIZE + d.r - 2
		})
	}
	return { node, squareNode, updateNodePositions }
}

const fitBetweenNodeXBoundaries = (x: number, r: number, labelLength: number, width: number) =>
	Math.max(NODE_LEFT_BOUNDARY + r + labelLength * 3.5, Math.min(width - NODE_BORDER_SIZE - r - labelLength * 3.5, x))

const fitBetweenNodeYBoundaries = (y: number, r: number, height: number) =>
	Math.max(
		NODE_TOP_BOUNDARY + r,
		Math.min(height - FONT_SIZE_NODE - LABEL_TO_NODE_GAP - NODE_BORDER_SIZE - BORDER_PADDING - r, y)
	)
