let i = 0
export const stringifyValue = <T>(chipValue: T) => {
	if (!chipValue) {
		return 'chip-' + i++
	}

	if (['string', 'number', 'boolean'].includes(typeof chipValue)) {
		return chipValue.toString()
	}

	return JSON.stringify(chipValue)
}

export const getChipId = <T>(componentId: string, chipValue: T) => {
	const stringifyedValue = stringifyValue(chipValue)
	return `${componentId}-${stringifyedValue}`
}
