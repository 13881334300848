import { e_PodPhaseName } from 'src/enums/e_PodPhase'
import { e_ResourceKind } from 'src/enums/e_ResourceKinds'
import { e_StatusColorType } from 'src/enums/e_StatusColorType'
import { IPod } from 'src/interfaces/IPod'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'

export const getWorkloadResourceStatusColorType = (workload: IPod | IWorkloadController) => {
	switch (workload.kind) {
		case e_ResourceKind.pod:
			if (workload.status.phaseName === e_PodPhaseName.failed || workload.status.phaseName === e_PodPhaseName.unknown) {
				return e_StatusColorType.error
			}
			if (
				workload.status.containerStatuses.some((containerStatus) => containerStatus.ready !== true) ||
				workload.status.phaseName === e_PodPhaseName.pending
			) {
				return e_StatusColorType.warning
			}
			if (workload.status.phaseName === e_PodPhaseName.succeeded) {
				return e_StatusColorType.success
			}
			break
		case e_ResourceKind.job:
			// .failed, .succeeded
			break
		case e_ResourceKind.deployment:
		case e_ResourceKind.statefulSet:
			if (workload.unavailableReplicas > 0) {
				return e_StatusColorType.warning
			}
			if (workload.hasAutoScaler && workload.includedInWorkloadsResourcePlan) {
				return e_StatusColorType.warning
			}
			break
	}
}
