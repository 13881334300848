import type { DragObjectWithType } from 'react-dnd'

export interface IDragDropCoordinates {
	x: number
	y: number
}

export interface IDragObject extends DragObjectWithType {
	dragPreview?: string | React.ReactNode | ((data: any) => string | React.ReactNode)
}

export interface IDragSourceMonitor {
	getClientOffset: () => IDragDropCoordinates | null
	getInitialClientOffset: () => IDragDropCoordinates | null
	isDragging: () => boolean
	getItem: () => any
	didDrop: () => boolean
	internalMonitor?: ISetDataMonitor
}

export type IDataTransferSetData = (format: string, data: string) => void

export interface ISetDataMonitor {
	setData?: IDataTransferSetData
}

export interface ICustomMonitor extends IDragSourceMonitor {
	internalMonitor: ISetDataMonitor
}

export interface IDropTargetMonitor {
	isOver: boolean
}

export const FILE_NAME_PREVIEW_OFFSET = { offsetX: -15, offsetY: 9 }

export const DEFAULT_DRAG_ITEM_TYPE = 'GenusDragDropItem'

export const NativeDragDropTypes = {
	FILE: '__NATIVE_FILE__',
	URL: '__NATIVE_URL__',
	TEXT: '__NATIVE_TEXT__',
}
