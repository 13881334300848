import { useRef, useEffect } from 'react'
import classNames from 'clsx'

import { createStyle } from '../../../theming'
import { zIndexes } from '../../../theming/themeProperties/zIndexes'

const classes = createStyle({
	popupAnchor: {
		position: 'fixed',
		pointerEvents: 'none',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		'& > div': { pointerEvents: 'all' },
		zIndex: zIndexes.modal + 1,
	},
})

export const POPUP_ANCHOR_CLASS_NAME = 'popup-anchor-qgIJ6gO5'

export const usePopupAnchor = (className: string) => {
	const popupAnchorRef = useRef<HTMLDivElement>()
	useEffect(() => {
		if (!popupAnchorRef.current) {
			const anchorDiv = document.createElement('div')
			document.querySelector('body')?.appendChild(anchorDiv)
			popupAnchorRef.current = anchorDiv
		}

		popupAnchorRef.current.className = classNames(classes.popupAnchor, className, POPUP_ANCHOR_CLASS_NAME)
	}, [classes.popupAnchor, className])

	useEffect(() => () => popupAnchorRef.current?.remove(), []) // Remove on unmount

	return popupAnchorRef.current
}
