import React from 'react'
import type { Value } from '../../../Table.types'
import { e_RenderType } from '../../../Table.types'

export const getEarlyReturn = (
	renderType: e_RenderType,
	isCheckMarkCell: boolean,
	isSummaryCell: boolean,
	value: Value,
	formatCellValue: ((value: Value) => Value) | undefined,
	displayLabel: string
) => {
	if (renderType === e_RenderType.none) {
		return null
	}

	if (isCheckMarkCell) {
		return null
	}
	// PBU 15.12.2023: valueFormatted is for some reason not the proper formatted value in group summary cells.
	// This results in displayLabel having the wrong value. We therefore have to format the value ourselves.
	// Preferably we should figure out why valueFormatted is not correct.
	if (isSummaryCell) {
		return <>{formatCellValue?.(value) || displayLabel || value}</>
	}

	return <>{displayLabel ?? value}</>
}
