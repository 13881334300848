import isNil from 'lodash/isNil'
import moment from 'moment'

import { e_DataType } from '../enums/e_DataType'
import { getFormattingCultureLocale } from '../../utils/setFormattingLocale'

export enum e_ComparatorUsage {
	filtering = 'filtering',
	sorting = 'sorting',
}

export const makeComparator = (
	dataType: e_DataType | undefined,
	usage = e_ComparatorUsage.sorting,
	format?: string,
	ascending = true
) => {
	return (valueA: any, valueB: any) => {
		if (isNil(valueA) && isNil(valueB)) {
			return 0
		}

		if (isNil(valueA)) {
			return -1
		}

		if (isNil(valueB)) {
			return 1
		}

		if (dataType === e_DataType.date || dataType === e_DataType.dateTime) {
			const dateA = moment(valueA, format)
			const dateB = moment(valueB, format)

			const compareDateA = usage === e_ComparatorUsage.filtering ? dateA.startOf('day') : dateA
			const compareDateB = usage === e_ComparatorUsage.filtering ? dateB.startOf('day') : dateB

			if (compareDateA.isBefore(compareDateB)) {
				return -1 * (ascending ? 1 : -1)
			} else if (compareDateA.isAfter(compareDateB)) {
				return 1 * (ascending ? 1 : -1)
			} else {
				return 0
			}
		}

		if (typeof valueA === 'string' && typeof valueB === 'string') {
			if (ascending) {
				return valueA.toLowerCase().localeCompare(valueB.toLowerCase(), getFormattingCultureLocale())
			} else {
				return valueB.toLowerCase().localeCompare(valueA.toLowerCase(), getFormattingCultureLocale())
			}
		}
		if (ascending) {
			return valueA - valueB
		}
		return valueB - valueA
	}
}
