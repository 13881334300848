import type { RefObject, SyntheticEvent } from 'react'
import React, { useRef, useEffect } from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'

import { useEventListener } from '../utils/useEventListener'
import { Chip } from '../Chip'
import type { IChipOverflowRefs, MultiValueInputItem } from './MultiValueInput.types'
import { InputHeight } from '../utils/InputHeight'
import { e_ChipOverflow } from '../../enums/e_Overflow'
import { getChipId, stringifyValue } from './utils/stringifyValue'
import { useChipSummaryOverflow } from './hooks/useChipSummaryOverflow'
import { useId } from '../hooks/useId'
import { topWindow } from '../utils/topWindow'

const classes = createStyle({
	chipsList: {
		display: 'flex',
		alignItems: 'center',
		gap: 4,
		'&:focus': {
			outline: 'none',
		},
	},
	wrapChips: {
		flexWrap: 'wrap',
	},
	chipVisibility: {
		display: 'none',
	},
})

interface StyleProps {
	maxWidth?: string // So chips will ellipsing, and not go outside container
}

interface ChipListProps<T> extends StyleProps {
	id?: string
	chips: MultiValueInputItem<T>[]
	currentIndex: number
	disabled?: boolean
	readOnly?: boolean
	selectNewChip?: (index: number) => void
	onDeleteChip?: (deleteIndex: number) => void
	onBlur?: () => void
	onCtrlClick?: (e: React.MouseEvent, value: T) => void
	chipOverflow: e_ChipOverflow
	refs: IChipOverflowRefs
	onHiddenChips?: (numberOfHiddenChips: number) => void
}

export function MultiValueInputChipList<T>(props: ChipListProps<T>) {
	const chipListRef: RefObject<HTMLDivElement> = useRef(null)
	const id = useId(props.id)

	const handleMouseDown = (e: SyntheticEvent) => {
		if (e.defaultPrevented) {
			return
		}

		if (document.contains(e.target as Node)) {
			if (!chipListRef.current?.contains(e.target as Node)) {
				onBlur()
			}
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent<Element>) => {
		switch (e.key) {
			case 'Delete':
			case 'Backspace':
				e.preventDefault()
				onDeleteChip(props.currentIndex)
				break
			case 'Escape':
				e.stopPropagation()
				onBlur()
				break
			case 'ArrowUp':
			case 'ArrowLeft':
				e.preventDefault()
				selectNewChip(navigateLeft(props.currentIndex, props.chips.length))
				break
			case 'ArrowDown':
			case 'ArrowRight':
				e.preventDefault()
				selectNewChip(navigateRight(props.currentIndex, props.chips.length))
				break
			case 'Tab':
				if (e.shiftKey) {
					if (props.currentIndex === 0) {
						selectNewChip(-1)
					} else {
						e.preventDefault()
						selectNewChip(navigateLeft(props.currentIndex, props.chips.length))
					}
				} else {
					e.preventDefault()
					selectNewChip(navigateRight(props.currentIndex, props.chips.length))
				}
				break
			case 'Home':
				e.preventDefault()
				selectNewChip(0)
				break
			case 'End':
				e.preventDefault()
				selectNewChip(-1)
				break
		}
	}

	const navigateRight = (index: number, arrayLength: number) => {
		return index >= 0 && index < arrayLength - 1 ? index + 1 : -1
	}

	const navigateLeft = (index: number, arrayLength: number) => {
		return index === -1 ? arrayLength - 1 : Math.max(index - 1, 0)
	}

	const selectNewChip = (index: number) => {
		if (index !== props.currentIndex) {
			props.selectNewChip?.(index)
		}
	}

	const onClickChip = (e: React.MouseEvent, index?: number) => {
		if (index === undefined) {
			return
		}

		if (e.ctrlKey || e.metaKey) {
			props.onCtrlClick?.(e, props.chips[index].value)
		}

		selectNewChip(index)
	}

	const onDeleteChip = (deleteIndex?: number) => {
		if (deleteIndex === undefined) {
			return
		}

		props.onDeleteChip?.(deleteIndex)
	}

	const onBlur = () => {
		props.onBlur?.()
	}

	const calculateChipVisibility = useChipSummaryOverflow(
		id,
		props.chips,
		props.refs,
		props.chipOverflow,
		props.onHiddenChips
	)

	useEventListener('resize', calculateChipVisibility, topWindow)

	useEventListener('mousedown', handleMouseDown)

	useEventListener('keydown', handleKeyDown, chipListRef)

	useEffect(() => {
		if (props.currentIndex === -1) {
			return
		}

		if (chipListRef?.current) {
			chipListRef.current.focus()
		}
	}, [props.currentIndex])

	const maxWidthStyle = props.maxWidth ? { maxWidth: props.maxWidth } : undefined

	const chipListClasses = classNames(classes.chipsList, {
		[classes.wrapChips]: props.chipOverflow === e_ChipOverflow.wrap,
	})

	return (
		<div className={chipListClasses} ref={chipListRef} tabIndex={-1} data-cy={`${props.id || ''}-chip-list`}>
			<InputHeight />
			{props.chips.map((chip, index) => {
				const stringifyedValue = stringifyValue(chip.value)
				const label = chip.label ? chip.label : stringifyedValue
				return (
					<Chip
						id={getChipId(id, chip.value)}
						index={index}
						key={stringifyedValue}
						label={label}
						isActive={index === props.currentIndex}
						disabled={props.disabled}
						readOnly={props.readOnly}
						onClick={onClickChip}
						onKeyDown={handleKeyDown}
						onDelete={onDeleteChip}
						dataId={stringifyedValue}
						dataAttributes={{ 'data-cy': stringifyedValue }}
						style={maxWidthStyle}
						className={props.chipOverflow === e_ChipOverflow.summary ? classes.chipVisibility : undefined}
					/>
				)
			})}
		</div>
	)
}
