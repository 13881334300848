import { topWindow } from '../../utils/topWindow'
import { formatPixel } from '.'

let currentX = 0
let currentY = 0

export const getPointerDown = (domRef: React.RefObject<HTMLDivElement>, domDialog: React.RefObject<HTMLDivElement>) => {
	const completePointerOperation = (event: Event) => {
		setTimeout(() => {
			const pointerEvent = event as PointerEvent

			const dialogHeader = pointerEvent.target as Element

			dialogHeader.removeEventListener('pointerup', handlePointerUp)
			dialogHeader.removeEventListener('pointermove', handlePointerMove)
			dialogHeader.removeEventListener('pointercancel', handlePointerCancel)
		}, 0)
	}

	const handlePointerUp = (event: Event) => {
		completePointerOperation(event)

		event.preventDefault()
		event.stopPropagation()
	}

	const handlePointerMove = (event: Event) => {
		event.preventDefault()
		event.stopPropagation()

		// set the element's new position:
		if (domRef === null || domRef.current === null) {
			return
		}
		const element = domDialog.current
		if (!element) {
			return
		}

		const pointerEvent = event as PointerEvent

		// calculate the new cursor position:

		const elementBounds = element.getBoundingClientRect()

		// clamp the offset so that the dialog is not dragged outside the document
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		const mousePos = { x: pointerEvent.clientX, y: pointerEvent.clientY }
		const minPos = {
			x: 0,
			y: 0,
		}
		const maxPos = {
			x: topWindow.innerWidth - elementBounds.width,
			y: topWindow.innerHeight - elementBounds.height,
		}
		const offsetX = currentX - mousePos.x
		const offsetY = currentY - mousePos.y
		currentX = mousePos.x
		currentY = mousePos.y

		const nextDialogPos = {
			left: Math.max(minPos.x, Math.min(maxPos.x, element.offsetLeft - offsetX)),
			top: Math.max(minPos.y, Math.min(maxPos.y, element.offsetTop - offsetY)),
		}

		element.style.setProperty('--left', formatPixel(nextDialogPos.left))
		element.style.setProperty('--top', formatPixel(nextDialogPos.top))
	}

	const handlePointerCancel = (event: Event) => {
		completePointerOperation(event)
	}

	return (event: React.PointerEvent) => {
		const headerElement = event.target as Element

		if (!headerElement) {
			return
		}

		event.preventDefault()
		event.stopPropagation()

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		headerElement.setPointerCapture(event.pointerId)

		headerElement.addEventListener('pointerup', handlePointerUp)
		headerElement.addEventListener('pointermove', handlePointerMove)
		headerElement.addEventListener('pointercancel', handlePointerCancel)

		currentX = event.clientX
		currentY = event.clientY
	}
}
