import { Selection } from 'd3'
import { e_ClusterDiagramNodeType } from 'src/enums/e_ClusterDiagramNodeType'
import { ID3ClusterDiagramEdge, ID3ClusterDiagramNode } from 'src/interfaces/IClusterDiagram'

const NAMESPACE_WIDTH = 50

export const drawEdges = (
	edgeGroup: Selection<SVGGElement, unknown, null, undefined>,
	edgeDataSet: ID3ClusterDiagramEdge[],
	isNodeSelected: (d: ID3ClusterDiagramNode) => boolean,
	hoveredNode: ID3ClusterDiagramNode | null
) => {
	const edgeLine = edgeGroup
		.selectAll<SVGPathElement, ID3ClusterDiagramEdge>('line')
		.data(edgeDataSet)
		.join((enter) => enter.append('line').attr('class', 'edge'))

	const color = (d: ID3ClusterDiagramEdge) =>
		isNodeSelected(d.source) ||
		isNodeSelected(d.target) ||
		d.source.id === hoveredNode?.id ||
		d.target.id === hoveredNode?.id
			? d.color!.dark!
			: d.color!.light!

	edgeLine
		.attr('marker-end', (d) => `url(#${color(d)})`)
		.attr('stroke', color)
		.attr('stroke-width', 2)
		.attr('stroke-dasharray', (d) => (d.nodeTargetType === e_ClusterDiagramNodeType.namespace ? '10 4' : null))

	const updateEdgePositions = () => {
		edgeLine
			.attr('x1', (d) => d.source.x! + Math.cos(getEdgeAngle(d)) * d.source.r)
			.attr('y1', (d) => d.source.y! + Math.sin(getEdgeAngle(d)) * d.source.r)
			.attr(
				'x2',
				(d) =>
					d.target.x! -
					Math.cos(getEdgeAngle(d)) *
						((d.source.nodeType === e_ClusterDiagramNodeType.namespace ? NAMESPACE_WIDTH : d.target.r) + 2)
			)
			.attr(
				'y2',
				(d) =>
					d.target.y! -
					Math.sin(getEdgeAngle(d)) *
						((d.source.nodeType === e_ClusterDiagramNodeType.namespace ? NAMESPACE_WIDTH : d.target.r) + 2)
			)
	}
	return { edgePath: edgeLine, updateEdgePositions }
}

function getEdgeAngle(edge: ID3ClusterDiagramEdge) {
	const dx = edge.target.x! - edge.source.x!
	const dy = edge.target.y! - edge.source.y!
	return Math.atan2(dy, dx)
}
