import { e_FilterOperator } from '../../enums/e_FilterOperator'
import type {
	IDropdownOption,
	IDropdownDivider,
	IDropdownHeader,
	IDropdownItem,
	IDropdownCustom,
} from './Dropdown.types'
import { DropdownOptionType } from './Dropdown.types'

type DropdownNullOption = {
	type: 'null'
	value: null
	label: string
}

export type DropdownOptionWithNull<T> =
	| IDropdownDivider
	| IDropdownHeader
	| IDropdownItem<T>
	| IDropdownCustom<T>
	| DropdownNullOption

export type DropdownOptionSelectable<T> = DropdownNullOption | IDropdownItem<T> | IDropdownCustom<T>

type Callback = <T>(item: DropdownOptionWithNull<T>, i: number) => any

export function isSelectableOption<T>(option: DropdownOptionWithNull<T>): option is DropdownOptionSelectable<T> {
	return !(option.type === DropdownOptionType.divider || option.type === DropdownOptionType.header)
}

export class DropdownOptionsHandler<T> {
	private array: DropdownOptionWithNull<T>[]
	public loaded: boolean
	public hasOptions: boolean
	public length: number

	constructor(
		options?: IDropdownOption<T>[] | DropdownOptionWithNull<T>[],
		allowNull = false,
		translator?: (t: string) => string,
		emptyContentMessage?: string
	) {
		if (!options || options.length === 0) {
			this.array = []
			this.loaded = false
			this.hasOptions = options !== undefined // suble difference between has options and loaded. loaded if there are any data supplied while hasOptions is determined by if option list is supplied or not
			this.length = allowNull ? 1 : 0

			if (allowNull) {
				this.array.push({
					type: 'null',
					value: null,
					label: emptyContentMessage || (translator ? `--${translator('GENERAL:SELECT')}--` : '--Select--'),
				})
			}

			return
		}

		this.array = options
		this.loaded = true
		this.hasOptions = true
		this.length = options.length

		if (allowNull) {
			this.array = options.slice()
			this.array.unshift({
				type: 'null',
				value: null,
				label: String.fromCharCode(160),
			})
		}
	}

	get(index: number) {
		return this.array[index] as DropdownOptionWithNull<T> | undefined
	}

	forEach(callback: Callback) {
		this.array.forEach(callback)
	}

	map(callback: Callback) {
		return this.array.map(callback)
	}

	some(callback: Callback) {
		return this.array.some(callback)
	}

	getSelectedOptions(selectedValues: Set<T | null>) {
		return this.array.filter(
			(option) => isSelectableOption(option) && selectedValues.has(option.value)
		) as DropdownOptionSelectable<T>[]
	}

	filter(
		searchTerm: string,
		filterOperator: e_FilterOperator.startsWith | e_FilterOperator.includes = e_FilterOperator.startsWith
	) {
		searchTerm = searchTerm.toLowerCase()
		const filtered = []
		let titleHasFollowingOption = false
		for (let i = this.array.length - 1; i >= 0; i--) {
			const item = this.array[i]
			if (item.type === DropdownOptionType.header && titleHasFollowingOption) {
				titleHasFollowingOption = false
				filtered.unshift(item)
			} else if (isSelectableOption(item) && item.label.toLowerCase()[filterOperator](searchTerm)) {
				titleHasFollowingOption = true
				filtered.unshift(this.array[i])
			}
		}

		return new DropdownOptionsHandler<T>(filtered)
	}

	find(callback: Callback) {
		return this.array.find(callback)
	}

	findIndex(callback: Callback) {
		return this.array.findIndex(callback)
	}

	prevSelectableIndex(index: number) {
		return this.nextSelectableIndex(index, true)
	}

	nextSelectableIndex(index: number, reverse = false) {
		const array = this.array

		if (array.length === 0) {
			return index
		}

		function boundIndex(i: number) {
			i = i + (reverse ? -1 : 1)
			if (i === array.length) {
				return 0
			} else if (i < 0) {
				return array.length - 1
			}
			return i
		}

		index = boundIndex(index)
		let item = this.get(index)
		while (item && !isSelectableOption(item)) {
			index = boundIndex(index)
			item = this.get(index)
		}

		return index
	}

	prevValue(value: T | null) {
		return this.nextValue(value, true)
	}

	nextValue(value: T | null, reverse = false): T | null {
		let valueIndex = -1
		this.find(<T>(item: DropdownOptionWithNull<T>, i: number) => {
			if (isSelectableOption(item) && item.value === value) {
				valueIndex = i
				return true
			}
			return false
		})

		const nextValueIndex = this.nextSelectableIndex(valueIndex, reverse)
		const nextValue = this.get(nextValueIndex)

		return nextValue && isSelectableOption(nextValue) ? nextValue.value : null
	}
}
