export enum svgIconLookup {
	'FlagsLanguages-FlagAfganistan' = 'FlagsLanguages-FlagAfganistan',
	'FlagsLanguages-FlagAlandIsland' = 'FlagsLanguages-FlagAlandIsland',
	'FlagsLanguages-FlagAlbania' = 'FlagsLanguages-FlagAlbania',
	'FlagsLanguages-FlagAlgeria' = 'FlagsLanguages-FlagAlgeria',
	'FlagsLanguages-FlagAmericanSamoa' = 'FlagsLanguages-FlagAmericanSamoa',
	'FlagsLanguages-FlagAndorra' = 'FlagsLanguages-FlagAndorra',
	'FlagsLanguages-FlagAngola' = 'FlagsLanguages-FlagAngola',
	'FlagsLanguages-FlagAntartica' = 'FlagsLanguages-FlagAntartica',
	'FlagsLanguages-FlagAntiguaBarbuda' = 'FlagsLanguages-FlagAntiguaBarbuda',
	'FlagsLanguages-FlagArabEmirates' = 'FlagsLanguages-FlagArabEmirates',
	'FlagsLanguages-FlagArgentina' = 'FlagsLanguages-FlagArgentina',
	'FlagsLanguages-FlagArmenia' = 'FlagsLanguages-FlagArmenia',
	'FlagsLanguages-FlagAruba' = 'FlagsLanguages-FlagAruba',
	'FlagsLanguages-FlagAustralia' = 'FlagsLanguages-FlagAustralia',
	'FlagsLanguages-FlagAustria' = 'FlagsLanguages-FlagAustria',
	'FlagsLanguages-FlagAzerbaijan' = 'FlagsLanguages-FlagAzerbaijan',
	'FlagsLanguages-FlagBahamas' = 'FlagsLanguages-FlagBahamas',
	'FlagsLanguages-FlagBahrain' = 'FlagsLanguages-FlagBahrain',
	'FlagsLanguages-FlagBangladesh' = 'FlagsLanguages-FlagBangladesh',
	'FlagsLanguages-FlagBarbados' = 'FlagsLanguages-FlagBarbados',
	'FlagsLanguages-FlagBelarus' = 'FlagsLanguages-FlagBelarus',
	'FlagsLanguages-FlagBelgium' = 'FlagsLanguages-FlagBelgium',
	'FlagsLanguages-FlagBenin' = 'FlagsLanguages-FlagBenin',
	'FlagsLanguages-FlagBermuda' = 'FlagsLanguages-FlagBermuda',
	'FlagsLanguages-FlagBolivia' = 'FlagsLanguages-FlagBolivia',
	'FlagsLanguages-FlagBosniaHerzegovina' = 'FlagsLanguages-FlagBosniaHerzegovina',
	'FlagsLanguages-FlagBotswana' = 'FlagsLanguages-FlagBotswana',
	'FlagsLanguages-FlagBrazil' = 'FlagsLanguages-FlagBrazil',
	'FlagsLanguages-FlagBritishVirginIsland' = 'FlagsLanguages-FlagBritishVirginIsland',
	'FlagsLanguages-FlagBulgaria' = 'FlagsLanguages-FlagBulgaria',
	'FlagsLanguages-FlagBurkinaFaso' = 'FlagsLanguages-FlagBurkinaFaso',
	'FlagsLanguages-FlagBurma' = 'FlagsLanguages-FlagBurma',
	'FlagsLanguages-FlagBurundi' = 'FlagsLanguages-FlagBurundi',
	'FlagsLanguages-FlagCambodia' = 'FlagsLanguages-FlagCambodia',
	'FlagsLanguages-FlagCameroon' = 'FlagsLanguages-FlagCameroon',
	'FlagsLanguages-FlagCanada' = 'FlagsLanguages-FlagCanada',
	'FlagsLanguages-FlagCanaryIslands' = 'FlagsLanguages-FlagCanaryIslands',
	'FlagsLanguages-FlagCapeVerde' = 'FlagsLanguages-FlagCapeVerde',
	'FlagsLanguages-FlagCaymanIsland' = 'FlagsLanguages-FlagCaymanIsland',
	'FlagsLanguages-FlagCentralAfricanRepublic' = 'FlagsLanguages-FlagCentralAfricanRepublic',
	'FlagsLanguages-FlagChad' = 'FlagsLanguages-FlagChad',
	'FlagsLanguages-FlagChile' = 'FlagsLanguages-FlagChile',
	'FlagsLanguages-FlagChina' = 'FlagsLanguages-FlagChina',
	'FlagsLanguages-FlagColombia' = 'FlagsLanguages-FlagColombia',
	'FlagsLanguages-FlagComoros' = 'FlagsLanguages-FlagComoros',
	'FlagsLanguages-FlagCongo' = 'FlagsLanguages-FlagCongo',
	'FlagsLanguages-FlagCookIslands' = 'FlagsLanguages-FlagCookIslands',
	'FlagsLanguages-FlagCostaRica' = 'FlagsLanguages-FlagCostaRica',
	'FlagsLanguages-FlagCroatia' = 'FlagsLanguages-FlagCroatia',
	'FlagsLanguages-FlagCuba' = 'FlagsLanguages-FlagCuba',
	'FlagsLanguages-FlagCuracao' = 'FlagsLanguages-FlagCuracao',
	'FlagsLanguages-FlagCyprus' = 'FlagsLanguages-FlagCyprus',
	'FlagsLanguages-FlagCzechRepublic' = 'FlagsLanguages-FlagCzechRepublic',
	'FlagsLanguages-FlagDemocraticCongo' = 'FlagsLanguages-FlagDemocraticCongo',
	'FlagsLanguages-FlagDenmark' = 'FlagsLanguages-FlagDenmark',
	'FlagsLanguages-FlagDjibouti' = 'FlagsLanguages-FlagDjibouti',
	'FlagsLanguages-FlagDominica' = 'FlagsLanguages-FlagDominica',
	'FlagsLanguages-FlagDominicanRepublic' = 'FlagsLanguages-FlagDominicanRepublic',
	'FlagsLanguages-FlagEastTimor' = 'FlagsLanguages-FlagEastTimor',
	'FlagsLanguages-FlagEcuador' = 'FlagsLanguages-FlagEcuador',
	'FlagsLanguages-FlagEgypt' = 'FlagsLanguages-FlagEgypt',
	'FlagsLanguages-FlagEngland' = 'FlagsLanguages-FlagEngland',
	'FlagsLanguages-FlagEquatorialGuinea' = 'FlagsLanguages-FlagEquatorialGuinea',
	'FlagsLanguages-FlagEritrea' = 'FlagsLanguages-FlagEritrea',
	'FlagsLanguages-FlagEstonia' = 'FlagsLanguages-FlagEstonia',
	'FlagsLanguages-FlagEthiopia' = 'FlagsLanguages-FlagEthiopia',
	'FlagsLanguages-FlagEurope' = 'FlagsLanguages-FlagEurope',
	'FlagsLanguages-FlagFalklandIsland' = 'FlagsLanguages-FlagFalklandIsland',
	'FlagsLanguages-FlagFaroeIslands' = 'FlagsLanguages-FlagFaroeIslands',
	'FlagsLanguages-FlagFiji' = 'FlagsLanguages-FlagFiji',
	'FlagsLanguages-FlagFinland' = 'FlagsLanguages-FlagFinland',
	'FlagsLanguages-FlagFrance' = 'FlagsLanguages-FlagFrance',
	'FlagsLanguages-FlagFrenchGuiana' = 'FlagsLanguages-FlagFrenchGuiana',
	'FlagsLanguages-FlagFrenchPolynesia' = 'FlagsLanguages-FlagFrenchPolynesia',
	'FlagsLanguages-FlagFrenchSouthernAntarctic' = 'FlagsLanguages-FlagFrenchSouthernAntarctic',
	'FlagsLanguages-FlagGabon' = 'FlagsLanguages-FlagGabon',
	'FlagsLanguages-FlagGambia' = 'FlagsLanguages-FlagGambia',
	'FlagsLanguages-FlagGeorgia' = 'FlagsLanguages-FlagGeorgia',
	'FlagsLanguages-FlagGermany' = 'FlagsLanguages-FlagGermany',
	'FlagsLanguages-FlagGhana' = 'FlagsLanguages-FlagGhana',
	'FlagsLanguages-FlagGibraltar' = 'FlagsLanguages-FlagGibraltar',
	'FlagsLanguages-FlagGreece' = 'FlagsLanguages-FlagGreece',
	'FlagsLanguages-FlagGreenland' = 'FlagsLanguages-FlagGreenland',
	'FlagsLanguages-FlagGrenada' = 'FlagsLanguages-FlagGrenada',
	'FlagsLanguages-FlagGuadeloupe' = 'FlagsLanguages-FlagGuadeloupe',
	'FlagsLanguages-FlagGuam' = 'FlagsLanguages-FlagGuam',
	'FlagsLanguages-FlagGuatemala' = 'FlagsLanguages-FlagGuatemala',
	'FlagsLanguages-FlagGuernsey' = 'FlagsLanguages-FlagGuernsey',
	'FlagsLanguages-FlagGuineaBissau' = 'FlagsLanguages-FlagGuineaBissau',
	'FlagsLanguages-FlagGuinea' = 'FlagsLanguages-FlagGuinea',
	'FlagsLanguages-FlagGuyana' = 'FlagsLanguages-FlagGuyana',
	'FlagsLanguages-FlagHaiti' = 'FlagsLanguages-FlagHaiti',
	'FlagsLanguages-FlagHonduras' = 'FlagsLanguages-FlagHonduras',
	'FlagsLanguages-FlagHongKong' = 'FlagsLanguages-FlagHongKong',
	'FlagsLanguages-FlagHungary' = 'FlagsLanguages-FlagHungary',
	'FlagsLanguages-FlagIceland' = 'FlagsLanguages-FlagIceland',
	'FlagsLanguages-FlagIndia' = 'FlagsLanguages-FlagIndia',
	'FlagsLanguages-FlagIndonesia' = 'FlagsLanguages-FlagIndonesia',
	'FlagsLanguages-FlagIran' = 'FlagsLanguages-FlagIran',
	'FlagsLanguages-FlagIraq' = 'FlagsLanguages-FlagIraq',
	'FlagsLanguages-FlagIreland' = 'FlagsLanguages-FlagIreland',
	'FlagsLanguages-FlagIsleOfMan' = 'FlagsLanguages-FlagIsleOfMan',
	'FlagsLanguages-FlagIsrael' = 'FlagsLanguages-FlagIsrael',
	'FlagsLanguages-FlagItaly' = 'FlagsLanguages-FlagItaly',
	'FlagsLanguages-FlagIvoryCoast' = 'FlagsLanguages-FlagIvoryCoast',
	'FlagsLanguages-FlagJamaica' = 'FlagsLanguages-FlagJamaica',
	'FlagsLanguages-FlagJapan' = 'FlagsLanguages-FlagJapan',
	'FlagsLanguages-FlagJersey' = 'FlagsLanguages-FlagJersey',
	'FlagsLanguages-FlagJordan' = 'FlagsLanguages-FlagJordan',
	'FlagsLanguages-FlagKazakhstan' = 'FlagsLanguages-FlagKazakhstan',
	'FlagsLanguages-FlagKenya' = 'FlagsLanguages-FlagKenya',
	'FlagsLanguages-FlagKiribati' = 'FlagsLanguages-FlagKiribati',
	'FlagsLanguages-FlagKosovo' = 'FlagsLanguages-FlagKosovo',
	'FlagsLanguages-FlagKuwait' = 'FlagsLanguages-FlagKuwait',
	'FlagsLanguages-FlagKyrgyzstan' = 'FlagsLanguages-FlagKyrgyzstan',
	'FlagsLanguages-FlagLaos' = 'FlagsLanguages-FlagLaos',
	'FlagsLanguages-FlagLatvia' = 'FlagsLanguages-FlagLatvia',
	'FlagsLanguages-FlagLebanon' = 'FlagsLanguages-FlagLebanon',
	'FlagsLanguages-FlagLesotho' = 'FlagsLanguages-FlagLesotho',
	'FlagsLanguages-FlagLiberia' = 'FlagsLanguages-FlagLiberia',
	'FlagsLanguages-FlagLibya' = 'FlagsLanguages-FlagLibya',
	'FlagsLanguages-FlagLiechtenstein' = 'FlagsLanguages-FlagLiechtenstein',
	'FlagsLanguages-FlagLithuania' = 'FlagsLanguages-FlagLithuania',
	'FlagsLanguages-FlagLuxembourg' = 'FlagsLanguages-FlagLuxembourg',
	'FlagsLanguages-FlagMacau' = 'FlagsLanguages-FlagMacau',
	'FlagsLanguages-FlagMacedonia' = 'FlagsLanguages-FlagMacedonia',
	'FlagsLanguages-FlagMadagascar' = 'FlagsLanguages-FlagMadagascar',
	'FlagsLanguages-FlagMalawi' = 'FlagsLanguages-FlagMalawi',
	'FlagsLanguages-FlagMalaysia' = 'FlagsLanguages-FlagMalaysia',
	'FlagsLanguages-FlagMaldives' = 'FlagsLanguages-FlagMaldives',
	'FlagsLanguages-FlagMali' = 'FlagsLanguages-FlagMali',
	'FlagsLanguages-FlagMalta' = 'FlagsLanguages-FlagMalta',
	'FlagsLanguages-FlagMarshallIslands' = 'FlagsLanguages-FlagMarshallIslands',
	'FlagsLanguages-FlagMartinique' = 'FlagsLanguages-FlagMartinique',
	'FlagsLanguages-FlagMauritania' = 'FlagsLanguages-FlagMauritania',
	'FlagsLanguages-FlagMauritius' = 'FlagsLanguages-FlagMauritius',
	'FlagsLanguages-FlagMexico' = 'FlagsLanguages-FlagMexico',
	'FlagsLanguages-FlagMicronesia' = 'FlagsLanguages-FlagMicronesia',
	'FlagsLanguages-FlagMoldova' = 'FlagsLanguages-FlagMoldova',
	'FlagsLanguages-FlagMonaco' = 'FlagsLanguages-FlagMonaco',
	'FlagsLanguages-FlagMongolia' = 'FlagsLanguages-FlagMongolia',
	'FlagsLanguages-FlagMontenegro' = 'FlagsLanguages-FlagMontenegro',
	'FlagsLanguages-FlagMontserrat' = 'FlagsLanguages-FlagMontserrat',
	'FlagsLanguages-FlagMorocco' = 'FlagsLanguages-FlagMorocco',
	'FlagsLanguages-FlagMozambique' = 'FlagsLanguages-FlagMozambique',
	'FlagsLanguages-FlagMyanmar' = 'FlagsLanguages-FlagMyanmar',
	'FlagsLanguages-FlagNamibia' = 'FlagsLanguages-FlagNamibia',
	'FlagsLanguages-FlagNauruIsland' = 'FlagsLanguages-FlagNauruIsland',
	'FlagsLanguages-FlagNepal' = 'FlagsLanguages-FlagNepal',
	'FlagsLanguages-FlagNetherlandsAntilles' = 'FlagsLanguages-FlagNetherlandsAntilles',
	'FlagsLanguages-FlagNetherlands' = 'FlagsLanguages-FlagNetherlands',
	'FlagsLanguages-FlagNewCaledonia' = 'FlagsLanguages-FlagNewCaledonia',
	'FlagsLanguages-FlagNewZealand' = 'FlagsLanguages-FlagNewZealand',
	'FlagsLanguages-FlagNicaragua' = 'FlagsLanguages-FlagNicaragua',
	'FlagsLanguages-FlagNiger' = 'FlagsLanguages-FlagNiger',
	'FlagsLanguages-FlagNigeria' = 'FlagsLanguages-FlagNigeria',
	'FlagsLanguages-FlagNiue' = 'FlagsLanguages-FlagNiue',
	'FlagsLanguages-FlagNorfolkIsland' = 'FlagsLanguages-FlagNorfolkIsland',
	'FlagsLanguages-FlagNorthKorea' = 'FlagsLanguages-FlagNorthKorea',
	'FlagsLanguages-FlagNorway' = 'FlagsLanguages-FlagNorway',
	'FlagsLanguages-FlagOman' = 'FlagsLanguages-FlagOman',
	'FlagsLanguages-FlagPakistan' = 'FlagsLanguages-FlagPakistan',
	'FlagsLanguages-FlagPalau' = 'FlagsLanguages-FlagPalau',
	'FlagsLanguages-FlagPalestine' = 'FlagsLanguages-FlagPalestine',
	'FlagsLanguages-FlagPanama' = 'FlagsLanguages-FlagPanama',
	'FlagsLanguages-FlagPapuaNewGuinea' = 'FlagsLanguages-FlagPapuaNewGuinea',
	'FlagsLanguages-FlagParaguay' = 'FlagsLanguages-FlagParaguay',
	'FlagsLanguages-FlagPeru' = 'FlagsLanguages-FlagPeru',
	'FlagsLanguages-FlagPhilipines' = 'FlagsLanguages-FlagPhilipines',
	'FlagsLanguages-FlagPoland' = 'FlagsLanguages-FlagPoland',
	'FlagsLanguages-FlagPortugal' = 'FlagsLanguages-FlagPortugal',
	'FlagsLanguages-FlagPuertoRico' = 'FlagsLanguages-FlagPuertoRico',
	'FlagsLanguages-FlagQatar' = 'FlagsLanguages-FlagQatar',
	'FlagsLanguages-FlagRomania' = 'FlagsLanguages-FlagRomania',
	'FlagsLanguages-FlagRussia' = 'FlagsLanguages-FlagRussia',
	'FlagsLanguages-FlagRwanda' = 'FlagsLanguages-FlagRwanda',
	'FlagsLanguages-FlagSaintKittsNevis' = 'FlagsLanguages-FlagSaintKittsNevis',
	'FlagsLanguages-FlagSaintLucia' = 'FlagsLanguages-FlagSaintLucia',
	'FlagsLanguages-FlagSaintVincentGrenadines' = 'FlagsLanguages-FlagSaintVincentGrenadines',
	'FlagsLanguages-FlagSalvador' = 'FlagsLanguages-FlagSalvador',
	'FlagsLanguages-FlagSamoa' = 'FlagsLanguages-FlagSamoa',
	'FlagsLanguages-FlagSanMarino' = 'FlagsLanguages-FlagSanMarino',
	'FlagsLanguages-FlagSaoTomePrincipe' = 'FlagsLanguages-FlagSaoTomePrincipe',
	'FlagsLanguages-FlagSaudiArabia' = 'FlagsLanguages-FlagSaudiArabia',
	'FlagsLanguages-FlagSenegal' = 'FlagsLanguages-FlagSenegal',
	'FlagsLanguages-FlagSerbia' = 'FlagsLanguages-FlagSerbia',
	'FlagsLanguages-FlagSeychelles' = 'FlagsLanguages-FlagSeychelles',
	'FlagsLanguages-FlagSierraLeone' = 'FlagsLanguages-FlagSierraLeone',
	'FlagsLanguages-FlagSingapore' = 'FlagsLanguages-FlagSingapore',
	'FlagsLanguages-FlagSlovakia' = 'FlagsLanguages-FlagSlovakia',
	'FlagsLanguages-FlagSlovenia' = 'FlagsLanguages-FlagSlovenia',
	'FlagsLanguages-FlagSolomonIslands' = 'FlagsLanguages-FlagSolomonIslands',
	'FlagsLanguages-FlagSomalia' = 'FlagsLanguages-FlagSomalia',
	'FlagsLanguages-FlagSouthAfrica' = 'FlagsLanguages-FlagSouthAfrica',
	'FlagsLanguages-FlagSouthKorea' = 'FlagsLanguages-FlagSouthKorea',
	'FlagsLanguages-FlagSouthSudan' = 'FlagsLanguages-FlagSouthSudan',
	'FlagsLanguages-FlagSpain' = 'FlagsLanguages-FlagSpain',
	'FlagsLanguages-FlagSriLanka' = 'FlagsLanguages-FlagSriLanka',
	'FlagsLanguages-FlagSudan' = 'FlagsLanguages-FlagSudan',
	'FlagsLanguages-FlagSuriname' = 'FlagsLanguages-FlagSuriname',
	'FlagsLanguages-FlagSwaziland' = 'FlagsLanguages-FlagSwaziland',
	'FlagsLanguages-FlagSweden' = 'FlagsLanguages-FlagSweden',
	'FlagsLanguages-FlagSwitzerlandSquare' = 'FlagsLanguages-FlagSwitzerlandSquare',
	'FlagsLanguages-FlagSwitzerland' = 'FlagsLanguages-FlagSwitzerland',
	'FlagsLanguages-FlagSyria' = 'FlagsLanguages-FlagSyria',
	'FlagsLanguages-FlagTaiwan' = 'FlagsLanguages-FlagTaiwan',
	'FlagsLanguages-FlagTajikistan' = 'FlagsLanguages-FlagTajikistan',
	'FlagsLanguages-FlagTanzania' = 'FlagsLanguages-FlagTanzania',
	'FlagsLanguages-FlagThailand' = 'FlagsLanguages-FlagThailand',
	'FlagsLanguages-FlagTogo' = 'FlagsLanguages-FlagTogo',
	'FlagsLanguages-FlagTonga' = 'FlagsLanguages-FlagTonga',
	'FlagsLanguages-FlagTrinidadTobago' = 'FlagsLanguages-FlagTrinidadTobago',
	'FlagsLanguages-FlagTunisia' = 'FlagsLanguages-FlagTunisia',
	'FlagsLanguages-FlagTurkey' = 'FlagsLanguages-FlagTurkey',
	'FlagsLanguages-FlagTurkmenistan' = 'FlagsLanguages-FlagTurkmenistan',
	'FlagsLanguages-FlagTuvalu' = 'FlagsLanguages-FlagTuvalu',
	'FlagsLanguages-FlagUganda' = 'FlagsLanguages-FlagUganda',
	'FlagsLanguages-FlagUkraine' = 'FlagsLanguages-FlagUkraine',
	'FlagsLanguages-FlagUnitedKingdom' = 'FlagsLanguages-FlagUnitedKingdom',
	'FlagsLanguages-FlagUnitedNations' = 'FlagsLanguages-FlagUnitedNations',
	'FlagsLanguages-FlagUnitedStates' = 'FlagsLanguages-FlagUnitedStates',
	'FlagsLanguages-FlagUruguay' = 'FlagsLanguages-FlagUruguay',
	'FlagsLanguages-FlagUzbekistan' = 'FlagsLanguages-FlagUzbekistan',
	'FlagsLanguages-FlagVanuatu' = 'FlagsLanguages-FlagVanuatu',
	'FlagsLanguages-FlagVatican' = 'FlagsLanguages-FlagVatican',
	'FlagsLanguages-FlagVenezuela' = 'FlagsLanguages-FlagVenezuela',
	'FlagsLanguages-FlagVietnam' = 'FlagsLanguages-FlagVietnam',
	'FlagsLanguages-FlagWallisAndFutuna' = 'FlagsLanguages-FlagWallisAndFutuna',
	'FlagsLanguages-FlagWesternSahara' = 'FlagsLanguages-FlagWesternSahara',
	'FlagsLanguages-FlagYemen' = 'FlagsLanguages-FlagYemen',
	'FlagsLanguages-FlagZambia' = 'FlagsLanguages-FlagZambia',
	'FlagsLanguages-FlagZimbabwe' = 'FlagsLanguages-FlagZimbabwe',
}