import { configureStore as configureReduxStore, Store } from '@reduxjs/toolkit'
import { IOperatorRootState, rootReducer } from './reducers'

// Sagas
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from './sagas'

import { loadState, saveState } from './features/UserSettings/localStorage'
import throttle from 'lodash/throttle'
import { translator } from './utils/locale'
import { modalManagerActions } from './features/ModalManager/duck'

const sagaMiddleware = createSagaMiddleware()

const configureStore = (initialState = {}): Store<IOperatorRootState> => {
	const configuredStore = configureReduxStore({
		reducer: rootReducer(),
		preloadedState: initialState,
		middleware: [sagaMiddleware],
		devTools:
			process.env.NODE_ENV === 'production'
				? false
				: {
						stateSanitizer: (state) => ({ ...state, expressionLanguage: {} }),
					},
	})

	// Run root saga and global saga error handler
	sagaMiddleware.run(rootSaga)

	// Extensions

	return configuredStore
}

const persistedState = loadState()

// Configure/init store
const store = configureStore(persistedState)

// Initialise i18n and moment after loading potential saved user settings
const persistedLanguage = persistedState?.userSettings.language
const persistedFormattingCulture = persistedState?.userSettings.formattingCulture
const persistedTimezone = persistedState?.userSettings.timezone
translator.initialize(persistedLanguage, persistedFormattingCulture, persistedTimezone).catch((error: Error) => {
	store.dispatch(modalManagerActions.showErrorModal(error))
})

store.subscribe(
	throttle(() => {
		saveState({
			userSettings: store.getState().userSettings,
		})
	}, 1000)
)
// Export store
export { store }
