import { all, takeEvery, call, put } from 'redux-saga/effects'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { translator } from 'src/utils/locale'
import { ISetPreferredFormattingCulture, ISetPreferredLanguage, ISetTimezone } from './IUserSettingsActions'
import { userSettingsTypes } from './userSettingTypes'

function* onSetLanguage(action: ISetPreferredLanguage) {
	try {
		yield call(translator.changeLanguage, action.language)
	} catch (err) {
		yield put(modalManagerActions.showErrorModal(new Error('Failed to change language')))
	}
}

function* onSetFormattingCulture(action: ISetPreferredFormattingCulture) {
	yield call(translator.changeFormattingCulture, action.formattingCulture)
}

function* onSetTimezone(action: ISetTimezone) {
	yield call(translator.changeTimezone, action.selectedTimezone)
}

function* watcherSagas() {
	yield all([
		takeEvery(userSettingsTypes.SET_PREFERRED_LANGUAGE, onSetLanguage),
		takeEvery(userSettingsTypes.SET_PREFERRED_FORMATTING_CULTURE, onSetFormattingCulture),
		takeEvery(userSettingsTypes.SET_TIME_ZONE, onSetTimezone),
	])
}

export const userSettingsSagas = {
	watcherSagas,
}
