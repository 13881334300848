import React from 'react'
import { CommandBar } from '@genusbiz/web-ui/controls'
import { MenuItem } from '@genusbiz/web-ui/controls/Menu'
import { createStyle } from '@genusbiz/web-ui/theming'

interface IOperatorCommandBarProps {
	items: MenuItem[]
}
const classes = createStyle((theme) => ({
	operatorCommandBar: {
		backgroundColor: theme.colors.panel.background,
		display: 'flex',
		borderBottom: '1px solid ' + theme.colors.panel.border,
		justifyContent: 'space-between',
	},
	fullWidth: { float: 'left' },
	rightCommandBar: { float: 'right', marginRight: 16 },
}))

export const OperatorCommandBar = (props: IOperatorCommandBarProps) => {
	return (
		<div className={classes.operatorCommandBar}>
			<div className={classes.fullWidth}>
				<CommandBar items={props.items} />
			</div>
		</div>
	)
}
