import numeral from 'numeral'

import { e_TableColumnSummaryFunction } from '../enums/e_TableColumnSummaryFunction'
import { e_DataType } from '../enums/e_DataType'
import type { CellData } from '../Table.types'

export const getAggFunctionKey = (summaryFunctionKey: string | undefined, dataType?: e_DataType) => {
	if (!summaryFunctionKey || summaryFunctionKey === e_TableColumnSummaryFunction.none) {
		return undefined
	}

	if (!Object.values(e_TableColumnSummaryFunction).includes(summaryFunctionKey as e_TableColumnSummaryFunction)) {
		return undefined
	}

	if (summaryFunctionKey === e_TableColumnSummaryFunction.sum) {
		return 'g_sum' as const
	}

	if (summaryFunctionKey === e_TableColumnSummaryFunction.average) {
		return 'g_average' as const
	}

	if (summaryFunctionKey === e_TableColumnSummaryFunction.median) {
		return 'g_median' as const
	}

	if (summaryFunctionKey === e_TableColumnSummaryFunction.count) {
		return 'g_count' as const
	}

	if (summaryFunctionKey === e_TableColumnSummaryFunction.distinctCount) {
		return 'g_distinctCount' as const
	}

	if (summaryFunctionKey === e_TableColumnSummaryFunction.min) {
		if (dataType && [e_DataType.date, e_DataType.dateTime].includes(dataType)) {
			return 'g_minDate' as const
		}

		return 'g_min' as const
	}

	if (summaryFunctionKey === e_TableColumnSummaryFunction.max) {
		if (dataType && [e_DataType.date, e_DataType.dateTime].includes(dataType)) {
			return 'g_maxDate' as const
		}

		return 'g_max' as const
	}

	return summaryFunctionKey
}

const sum = (params: { values: CellData[] }) => aggregateValues(params.values, _sum)
const _sum = (values: number[]) => values.reduce((totalSum, value) => totalSum.add(value), numeral(0)).value()

const min = (params: { values: CellData[] }) => aggregateValues(params.values, _min)
const _min = (values: number[]) => Math.min(...values)

const max = (params: { values: CellData[] }) => aggregateValues(params.values, _max)
const _max = (values: number[]) => Math.max(...values)

const average = (params: { values: CellData[] }) => aggregateValues(params.values, _average)
const _average = (values: number[]) => numeral(_sum(values)).divide(values.length).value()

const median = (params: { values: CellData[] }) => aggregateValues(params.values, _median)
const _median = (unsortedValues: number[]) => {
	const sorted = [...unsortedValues].sort((a, b) => a - b)

	if (sorted.length === 0) {
		return null
	}

	if (sorted.length % 2 === 0) {
		const middleA = sorted[sorted.length / 2 - 1]
		const middleB = sorted[sorted.length / 2]

		return numeral(middleA).add(middleB).divide(2).value()
	} else {
		return sorted[Math.floor(sorted.length / 2)]
	}
}

const count = (params: { values: CellData[] }) => {
	const values = params.values.map((cellValue) => cellValue.value)
	const nonNullValues = values.filter((v) => v !== null && v !== undefined)

	return { value: nonNullValues.length }
}

const distinctCount = (params: { values: CellData[] }) => {
	const values = params.values.map((cellValue) => cellValue.value)
	const nonNullValues = values.filter((v) => v !== null && v !== undefined)

	return { value: Array.from(new Set(nonNullValues)).length }
}

// Generalized aggregator
type IAggGroup = { values: (null | number)[]; toString: () => string }
export function isAggGroup(group: (number | null)[] | IAggGroup[]): group is IAggGroup[] {
	return group[0] !== null && typeof group[0] === 'object'
}
const aggregateValues = (
	cellData: CellData[],
	handler: (values: number[]) => number | null,
	allowNumberOnly = true
) => {
	const values = cellData.map((cd) => cd?.value ?? null)

	const nonNullValues = values.filter(
		(v) => v !== null && v !== undefined && (!allowNumberOnly || (typeof v === 'number' && !isNaN(v)))
	) as number[]
	const result = nonNullValues.length !== 0 ? handler(nonNullValues) : null

	return {
		values: nonNullValues,
		value: result,
		toString: () => result,
	}
}

const maxDate = (params: { values: CellData[] }) => minMaxDate(params.values, 'max')
const minDate = (params: { values: CellData[] }) => minMaxDate(params.values, 'min')
const minMaxDate = (stringAndNullValues: CellData[], minMax: 'min' | 'max') => {
	const bla = stringAndNullValues.map((cd) => cd?.value ?? null)
	const values = bla.filter((v) => v !== null) as string[]

	if (values.length) {
		const dateValues = values.map((value) => ({ s: value, d: new Date(value) }))
		const minMaxValue =
			minMax === 'max'
				? dateValues.reduce(
						(acc, current) => {
							if (!acc) {
								return current
							}
							return acc?.d < current.d ? current : acc
						},
						null as null | (typeof dateValues)[0]
				  )
				: dateValues.reduce(
						(acc, current) => {
							if (!acc) {
								return current
							}
							return acc.d > current.d ? current : acc
						},
						null as null | (typeof dateValues)[0]
				  )
		return minMaxValue?.s
	}

	return null
}

export const aggFunctions = {
	g_sum: sum,
	g_average: average,
	g_median: median,
	g_count: count,
	g_distinctCount: distinctCount,
	g_min: min,
	g_minDate: minDate,
	g_max: max,
	g_maxDate: maxDate,
}

// Used for testing purposes
export const getAggFunction = (columnSummary?: e_TableColumnSummaryFunction, dataType?: e_DataType) => {
	const aggFunctionKey = getAggFunctionKey(columnSummary, dataType)

	if (!aggFunctionKey) {
		return undefined
	}

	if (aggFunctionKey in e_TableColumnSummaryFunction) {
		return undefined
	}

	return aggFunctions[aggFunctionKey as keyof typeof aggFunctions]
}
