import type { ReactElement } from 'react'
import React from 'react'
import { DropTarget } from '../utils/DropTarget'
import { createStyle } from '../../theming'
import type { XYCoord } from 'react-dnd'
import { TableHeader, TableCell } from './'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils/IIconNames'
import classNames from 'clsx'

interface ITableProps {
	children: ReactElement | ReactElement[]
	columns: (IColumnProps | undefined)[]
	columnSortOnClick?: (colId: string, multiSelect?: ITableMultiselect) => void
	dropTargetTypes?: string[]
	onDrop?: (elementId: string | null, dropBefore: boolean, itemType: string | symbol | null, item: any) => void
	onActivate?: (rowId: string) => void
	setSelection?: (rowIds: string[]) => void
	selectedRowIds?: string[] | string
	useDragHandle?: boolean
	selectionMode?: 'single' | 'multi'
	enableDragAndDrop?: boolean
	enableContextMenu?: boolean
	sortProperty?: string
	stickyHeader?: boolean
}

export interface IColumnProps {
	id: string
	displayName: string
	width: string
	columnSortOrder?: 'asc' | 'desc'
	iconName?: IIconNames
	iconColorClass?: string
	screenTip?: string
}

export interface ITableDropPosition {
	elementId: string | null
	dropBefore: boolean
}

export interface ITableMultiselect {
	ctrlMultiselect?: boolean
	shiftMultiselect?: boolean
}

const classes = createStyle((theme) => ({
	table: {
		textAlign: 'left',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		borderCollapse: 'collapse',
		width: '100%',
		borderSpacing: 0,
	},
	dropTarget: {
		height: '100%',
	},
	nonHighlightable: {
		MozUserSelect: 'none',
		WebkitUserSelect: 'none',
		msUserSelect: 'none',
	},
	sticky: {
		position: 'sticky',
		top: 0,
		zIndex: 1,
		background: theme.colors.body.background,
	},
	relativePosition: {
		position: 'relative',
		overflow: 'auto',
		height: '100%',
	},
}))

export const Table = (props: ITableProps) => {
	const [hoverPosition, setHoverPosition] = React.useState<ITableDropPosition | undefined>()

	const onActivate = (rowId: string) => {
		if (rowId !== undefined) {
			props.onActivate?.(rowId)
		}
	}

	const handleDropHover = (
		itemType: string | symbol | null,
		item: any,
		isOver: boolean,
		isDirectlyOver: boolean,
		clientOffset: XYCoord | null
	) => {
		const dropPosition = getDropPosition(clientOffset)
		setHoverPosition(dropPosition)
	}

	const getDropPosition = (clientOffset: XYCoord | null): ITableDropPosition | undefined => {
		if (!clientOffset) {
			return
		}

		// get element from dom being hovered if its a valid drop itme
		// DRE fix in future
		const htmlDomElementsFromPoint: any[] = document.elementsFromPoint(clientOffset.x, clientOffset.y)

		const validElement = htmlDomElementsFromPoint.filter((e) => {
			return e.dataset.rowid
		})

		const element = validElement[0]

		if (!element) {
			return { elementId: null, dropBefore: false }
		}

		const elementRect = element.getBoundingClientRect()

		//if in the top half of the drop element
		if (clientOffset.y < elementRect.y + elementRect.height / 2) {
			return { elementId: element.dataset.rowid, dropBefore: true }
		}

		// if in the bottom half of the element
		if (clientOffset.y >= elementRect.y + elementRect.height / 2) {
			return { elementId: element.dataset.rowid, dropBefore: false }
		}
	}

	const handleOnDrop = (
		itemType: string | symbol | null,
		item: any,
		isOver: boolean,
		isDirectlyOver: boolean,
		clientOffset: XYCoord | null
	) => {
		const dropPosition = getDropPosition(clientOffset)

		if (!dropPosition) {
			return
		}

		if (props.onDrop) {
			props.onDrop(dropPosition.elementId, dropPosition.dropBefore, itemType, item)
		}
		setHoverPosition(undefined)
	}

	return (
		<div
			className={classNames(
				classes.table,
				props.dropTargetTypes && classes.nonHighlightable,
				props.stickyHeader && classes.relativePosition
			)}
		>
			<DropTarget
				className={classes.dropTarget}
				dropTargetTypes={props.dropTargetTypes}
				onCanDrop={() => true}
				onHover={handleDropHover}
				disableDrop={!props.enableDragAndDrop}
				onDrop={handleOnDrop}
				onDragLeave={() => setHoverPosition(undefined)}
			>
				<div className={classNames(props.stickyHeader && classes.sticky)}>
					<TableHeader columnSortOnClick={props.columnSortOnClick}>
						{props.columns.map((column, i) => (
							<TableCell
								leftDivider={i !== 0}
								columnSortOrder={column?.columnSortOrder}
								key={column?.id}
								colId={column?.id}
								columnWidth={column?.width}
								label={column?.displayName}
								iconName={column?.iconName}
								iconColorClass={column?.iconColorClass}
								screenTip={column?.screenTip}
							/>
						))}
					</TableHeader>
				</div>

				{React.Children.map(props.children, (tableRow, rowIndex) =>
					React.cloneElement(tableRow as React.ReactElement<any>, {
						selectedRowIds: props.selectedRowIds,
						setSelection: props.setSelection,
						columns: props.columns,
						index: rowIndex,
						tableHoverPosition: hoverPosition,
						useDragHandle: props.useDragHandle,
						onActivate: onActivate,
					})
				)}
			</DropTarget>
		</div>
	)
}
