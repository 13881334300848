import React, { useEffect, useRef } from 'react'

//Web-UI
import { DateInput } from '../../../../DateInput'

//Enums and interfaces
import type { e_DataType } from '../../../enums/e_DataType'
import type { e_DateFormat } from '../../../enums/e_DateFormat'

import type { ICellRenderer, ITableContext, TData } from '../../../Table.types'
import type { GridApi } from '@ag-grid-community/core'

interface ICellRendererDate extends ICellRenderer {
	value: string | null | undefined
	dataType: e_DataType
	format: e_DateFormat
	formatValue?: (value: any) => any
	context: ITableContext
	api: GridApi<TData>
	initialKey?: string
}
export const CellRendererDate = (props: ICellRendererDate) => {
	const inputRef = useRef<HTMLInputElement>(null)
	const valueRef = useRef<string | null | undefined>(props.value)

	const onChange = (value: string | null | undefined) => {
		props.setValue?.(value)
		valueRef.current = value
	}

	const stopEditing = () => {
		props.setValue?.(valueRef.current)
		props.api.stopEditing()
	}

	useEffect(() => {
		if (!props.context.initEditorWithPopper) {
			inputRef.current?.focus()
		}
		props.context.initEditorWithPopper = false
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<DateInput
			ref={inputRef}
			value={props.value}
			placeholder={'DD.MM.YYYY'}
			type={props.dataType as 'date' | 'dateTime'}
			dateFormat={props.format}
			formatValue={props.formatValue}
			datePickerOpen={props.context.initEditorWithPopper}
			className={props.className}
			onChange={onChange}
			onClose={stopEditing}
			error={props.hasError}
			onBlur={stopEditing}
			initialInputValue={props.initialKey}
		/>
	)
}
