import clsx from 'clsx'
import React, { useState, useEffect, useRef } from 'react'
import { createStyle } from '../../../theming'
import { GenericButton } from '../../../controls/GenericButton'
import { useTranslation } from '../../../translation'

const classes = createStyle((theme) => ({
	filterTextBorder: {
		display: 'flex',
		border: '1px solid transparent',

		alignItems: 'center',
		maxWidth: '300px',
	},
	filterTextBorderActive: {
		flex: 1,
		borderColor: theme.controls.input.colors.border,
		borderRadius: theme.controls.input.colors.border,
		background: theme.controls.input.colors.background,
		overflow: 'hidden',
		paddingLeft: '8px',
	},

	minWidth: {
		minWidth: '200px',
	},

	filterTextInput: {
		flex: 1,
		minWidth: '0px',
		border: 'none',
		outline: 'none',
		padding: '1px 4px',
		background: 'unset',
	},

	filterTextButton: {
		padding: '7px',
	},
}))

interface IFilterTextPanelProps {
	filterText?: string
	setFilterText: (filterText: string) => void
	applyMinWidth?: boolean
}

export const FilterTextPanel = (props: IFilterTextPanelProps) => {
	const { tcvvi } = useTranslation()

	const inputFieldRef = useRef<HTMLInputElement>(null)

	const delayedFilterTextUpdate = useRef<number | undefined>()

	const [internalFilterText, setInternalFilterText] = useState(props.filterText || '')

	const [isFilterTextActive, setIsFilterTextActive] = useState(
		props.filterText !== '' && props.filterText !== undefined
	)

	useEffect(() => {
		setInternalFilterText(props.filterText || '')
	}, [props.filterText])

	const updateFilterText = (text: string, now: boolean) => {
		if (delayedFilterTextUpdate.current) {
			window.clearTimeout(delayedFilterTextUpdate.current)

			delayedFilterTextUpdate.current = undefined
		}

		if (!now) {
			delayedFilterTextUpdate.current = window.setTimeout(() => {
				props.setFilterText(text)
			}, 200)

			return
		}

		props.setFilterText(text)
	}

	const internalUpdateFilterText = (e: React.ChangeEvent) => {
		const text = (e.target as HTMLInputElement).value

		setInternalFilterText(text)

		updateFilterText(text, false)
	}

	const handleButtonClick = () => {
		const nextIsFilterTextActive = !isFilterTextActive
		setIsFilterTextActive(nextIsFilterTextActive)

		if (nextIsFilterTextActive) {
			setTimeout(() => {
				inputFieldRef.current && inputFieldRef.current.focus()
			}, 0)
		}

		if (!nextIsFilterTextActive) {
			updateFilterText('', true)
		}
	}

	return (
		<div
			className={clsx(
				classes.filterTextBorder,
				isFilterTextActive && classes.filterTextBorderActive,
				isFilterTextActive && props.applyMinWidth && classes.minWidth
			)}
		>
			{isFilterTextActive && (
				<input
					className={classes.filterTextInput}
					value={internalFilterText}
					onChange={internalUpdateFilterText}
					ref={inputFieldRef}
					spellCheck={false}
				/>
			)}
			<GenericButton
				icon={isFilterTextActive ? 'Fluent-Clear' : 'Fluent-Search'}
				onClick={handleButtonClick}
				screenTip={tcvvi(isFilterTextActive ? 'GENERAL:CLEAR' : 'GENERAL:FILTER')}
				dataAttributes={{ 'data-cy': 'toggle-filter-panel' }}
				className={classes.filterTextButton}
			/>
		</div>
	)
}
