import React from 'react'
import classNames from 'clsx'
import { Icon } from '../Icon'
import { Badge } from '../Badge'
import { e_ItemContentLayout, getContentLayout, type IContentLayout } from '../Menu/MenuItem.types'
import type { ICommandBarClasses } from './CommandBar'
import type { IKeyboardShortcut } from '../utils/keyboard/IKeyboardShortcut'
import { generateMenuScreenTip } from '../Menu/generateMenuScreenTip'

interface ICommandBarButtonProps {
	classes: ICommandBarClasses
	name?: string
	id?: string
	iconClassName?: string
	iconString?: string
	iconColorClass?: string
	onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void
	disabled?: boolean
	isChecked?: boolean
	hidden?: boolean
	screenTip?: string
	keyboardShortcut?: IKeyboardShortcut
	size?: 'small' | 'medium'
	badgeValue?: number
	contentLayout?: IContentLayout
	dataAttributes?: Record<string, string>
}

export const CommandBarButton = (props: ICommandBarButtonProps) => {
	if (props.hidden) {
		return null
	}

	const classes = classNames(props.classes.commandBarItem, {
		[props.classes.enabled]: !props.disabled,
		[props.classes.disabled]: props.disabled,
		[props.classes.isChecked]: props.isChecked,
		[props.classes.commandBarItemSmall]: props.size === 'small',
	})

	const contentLayout = getContentLayout(props.contentLayout, 'CommandBar')

	const renderIconValue =
		props.iconClassName &&
		(!contentLayout || contentLayout === e_ItemContentLayout.icon || contentLayout === e_ItemContentLayout.iconAndText)

	const renderTextValue =
		props.name &&
		(!contentLayout || contentLayout === e_ItemContentLayout.text || contentLayout === e_ItemContentLayout.iconAndText)

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key === ' ') {
			e.preventDefault()
			e.stopPropagation()

			!props.disabled && props.onClick?.(e)
		}
	}

	return (
		<Badge value={props.badgeValue}>
			<div
				title={generateMenuScreenTip(props.screenTip, props.keyboardShortcut)}
				className={classes}
				role="button"
				id={props.id}
				{...props.dataAttributes}
				aria-disabled={props.disabled}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()

					!props.disabled && props.onClick?.(e)
				}}
				onKeyDown={handleKeyDown}
				tabIndex={!props.disabled ? 0 : undefined}
			>
				{(renderIconValue || props.iconString) && (
					<span
						className={classNames(
							{
								[props.classes.buttonIcon]: !renderTextValue,
								[props.classes.buttonIconWithText]: renderTextValue,
							},
							props.iconColorClass
						)}
					>
						{renderIconValue ? (
							<Icon iconClassName={props.iconClassName} />
						) : (
							<span className={props.classes.iconString}>{props.iconString}</span>
						)}
					</span>
				)}
				{renderTextValue && <span>{props.name}</span>}
			</div>
		</Badge>
	)
}
