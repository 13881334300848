import clsx from 'clsx'
import React, { useRef, useState } from 'react'

import { createStyle } from '../../theming'

interface IToggleButtonGroupOption {
	id: string
	displayName: string
}

interface IToggleSwitchGroupProps {
	options: IToggleButtonGroupOption[]
	currentOptionId: string
	setCurrentOption: (id: string) => void
	enableTransitions?: boolean
}

const classes = createStyle((theme) => ({
	group: {
		display: 'flex',
		padding: '2px',
		border: '1px solid ' + theme.controls.input.colors.border,
		background: theme.controls.input.colors.background,
		borderRadius: '100px',
		alignSelf: 'flex-start',
		position: 'relative',
	},
	option: {
		padding: '4px 16px',
		border: '1px solid transparent',
		borderRadius: '100px',
		background: 'unset',
		outline: 'none',
		position: 'relative',

		'&:focus': {
			background: 'whiteSmoke',
		},
		'&:hover': {
			background: 'whiteSmoke',
		},

		'&:focus-visible::after': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			margin: '1px',
			borderRadius: '100px',
			border: `1px solid ${theme.controls.button.focusedColors.color}`,
		},
	},

	optionCurrent: {
		borderColor: 'transparent',
		background: theme.controls.button.colors.primaryBackground,
		color: theme.controls.button.colors.primaryColor,

		border: '1px solid transparent',
		'&:active': {
			background: theme.controls.button.pressedColors.primaryBackground,
			color: theme.controls.button.pressedColors.primaryColor,
			borderColor: theme.controls.button.pressedColors.primaryBorder,
		},

		'&:focus': {
			background: theme.controls.button.focusedColors.primaryBackground,
			color: theme.controls.button.focusedColors.primaryColor,
			borderColor: theme.controls.button.focusedColors.primaryBorder,
		},
		'&:focus-visible': {
			background: theme.controls.button.focusedColors.primaryBackground,
			color: theme.controls.button.focusedColors.primaryColor,
			borderColor: theme.controls.button.focusedColors.primaryBorder,
		},

		'&:focus-visible::after': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			margin: '1px',
			borderRadius: '100px',
			border: `1px solid ${theme.controls.button.focusedColors.primaryColor}`,
		},

		'&:hover': {
			background: theme.controls.button.hoverColors.primaryBackground,
			color: theme.controls.button.hoverColors.primaryColor,
			borderColor: theme.controls.button.hoverColors.primaryBorder,
		},
	},

	currentTransitionOverlay: {
		position: 'absolute',
		transition: 'left 100ms ease-in, 1idth 300ms ease-in',
		border: '1px solid ' + theme.controls.button.colors.primaryBackground,
		borderRadius: '100px',
	},
}))

export const ToggleSwitchGroup = (props: IToggleSwitchGroupProps) => {
	const [transitionId, setTransitionToId] = useState<string | undefined>()

	const selectedButtonRef = useRef<HTMLButtonElement>(null)
	const buttonGroupRef = useRef<HTMLDivElement>(null)

	const transitionTo = (id: string) => {
		if (!props.enableTransitions) {
			return
		}

		setTransitionToId(id)
	}

	const updateCurrentOption = (id: string) => {
		transitionTo(id)

		setTimeout(() => {
			props.setCurrentOption(id)

			const activeButtonElement = buttonGroupRef.current?.querySelector(`[data-option-id='${id}']`) as HTMLButtonElement

			if (activeButtonElement) {
				activeButtonElement.focus()
			}
		}, 0)
	}

	const activeButton =
		buttonGroupRef.current && props.currentOptionId && transitionId
			? buttonGroupRef.current.querySelector(`[data-option-id='${props.currentOptionId}']`)
			: undefined

	const activeButtonStyle = activeButton ? calcPositionStyle(activeButton, buttonGroupRef.current!) : undefined

	const selectNextOption = (currentId: string) => {
		//Click, space and enter handled through default click event

		const currentIndex = props.options.findIndex((option) => option.id === currentId)

		if (currentIndex >= 0) {
			const nextIndex = currentIndex < props.options.length - 1 ? currentIndex + 1 : 0

			if (nextIndex >= 0) {
				updateCurrentOption(props.options[nextIndex].id)
			}
		}
	}
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'ArrowRight') {
			event.preventDefault()
			event.stopPropagation()
			const nextOption = props.options.find((option) => option.id === props.currentOptionId)
			if (nextOption) {
				const nextIndex = props.options.indexOf(nextOption) + 1
				if (nextIndex < props.options.length) {
					updateCurrentOption(props.options[nextIndex].id)
				}
			}
		} else if (event.key === 'ArrowLeft') {
			event.preventDefault()
			event.stopPropagation()
			const nextOption = props.options.find((option) => option.id === props.currentOptionId)
			if (nextOption) {
				const nextIndex = props.options.indexOf(nextOption) - 1
				if (nextIndex >= 0) {
					updateCurrentOption(props.options[nextIndex].id)
				}
			}
		}
	}

	return (
		<div className={classes.group} ref={buttonGroupRef} onKeyDown={handleKeyDown}>
			{props.options.map((option) => (
				<button
					key={option.id}
					className={clsx(
						classes.option,
						option.id === props.currentOptionId && !transitionId && classes.optionCurrent
					)}
					data-option-id={option.id}
					ref={props.currentOptionId && classes.optionCurrent ? selectedButtonRef : undefined}
					onClick={() => {
						if (props.currentOptionId === option.id) {
							selectNextOption(option.id)
						} else if (props.currentOptionId !== option.id) {
							updateCurrentOption(option.id)
						}
					}}
				>
					{option.displayName}
				</button>
			))}

			{transitionId && (
				<div
					className={classes.currentTransitionOverlay}
					onTransitionEnd={() => {
						setTransitionToId(undefined)
					}}
					style={activeButtonStyle}
				/>
			)}
		</div>
	)
}

const calcPositionStyle = (activeButton: Element, parent: HTMLDivElement) => {
	const buttonRect = activeButton.getBoundingClientRect()
	const parentRect = parent.getBoundingClientRect()

	return {
		left: buttonRect.left - parentRect.left - 1,

		top: buttonRect.top - parentRect.top - 1,
		height: buttonRect.bottom - buttonRect.top - 2,
		width: buttonRect.right - buttonRect.left - 2,
	}
}
