import { useRef } from 'react'
import type { CellKeyDownEvent, IRowNode } from '@ag-grid-community/core'
import type { CellData, ICellRendererProps, TData } from '../Table.types'

let searchQuery = ''
let matchedNodeData = ''

export const useSearchForNode = (disableSelectionOnNavigation: boolean) => {
	const searchTimeout = useRef(0)

	return (e: CellKeyDownEvent<TData, CellData>) => {
		if (!e.event || (e.event as KeyboardEvent).ctrlKey) {
			return
		}

		const readOnlyCell = e.value?.readOnly ?? (e.colDef.cellRendererParams as ICellRendererProps).readOnly ?? true

		if (!readOnlyCell) {
			return
		}

		clearTimeout(searchTimeout.current)

		searchQuery += (e.event as KeyboardEvent).key
		findAndFocusNextNode(e, disableSelectionOnNavigation)

		searchTimeout.current = window.setTimeout(() => {
			searchQuery = ''
			matchedNodeData = ''
		}, 400)
	}
}

const findAndFocusNextNode = (e: CellKeyDownEvent<TData>, disableSelectionOnNavigation: boolean) => {
	let nodeFound = false
	let firstNode: IRowNode | undefined
	e.api.forEachNodeAfterFilterAndSort((node: IRowNode) => {
		if (e.colDef.editable) {
			return
		}

		if (nodeFound) {
			return
		}

		if (!e.colDef.field) {
			return
		}

		const rowIndex = node.rowIndex

		if (rowIndex === null || e.rowIndex === null) {
			return
		}

		const data = node.data?.[e.colDef.field]?.value
		if (data && data.toString().toLowerCase().startsWith(searchQuery.toLowerCase())) {
			if (matchedNodeData.startsWith(searchQuery.toLowerCase())) {
				return
			}

			if (rowIndex <= e.rowIndex) {
				if (!firstNode) {
					firstNode = node
				}

				return
			}

			matchedNodeData = data.toString().toLowerCase()
			e.api.ensureIndexVisible(rowIndex)

			if (!disableSelectionOnNavigation) {
				node.setSelected(true, true)
			}

			nodeFound = true

			window.setTimeout(() => {
				e.api.setFocusedCell(rowIndex, e.column)
			}, 50)
		}
	})

	if (firstNode && firstNode.rowIndex !== null && !nodeFound) {
		e.api.ensureIndexVisible(firstNode.rowIndex)

		if (!disableSelectionOnNavigation) {
			firstNode.setSelected(true, true)
		}

		window.setTimeout(() => {
			if (firstNode && firstNode.rowIndex !== null) {
				e.api.setFocusedCell(firstNode.rowIndex, e.column)
			}
		}, 50)
	}
}
