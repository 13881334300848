import { useRef } from 'react'

import type { CheckMarkValue, TData } from '../Table.types'
import type { GridApi, IRowNode } from '@ag-grid-community/core'
import { getCheckboxState } from '../utils'

export const useUpdateGroupCheckMark = () => {
	const groupCheckMarkState = useRef<{ id: string; state: CheckMarkValue }[]>([])

	const selectNodes = (nodesToSelect: IRowNode<TData>[], api: GridApi<TData>) => {
		nodesToSelect.forEach((parent) => {
			if (parent !== null) {
				const checkMarkState = groupCheckMarkState.current?.find((group) => group.id === parent.id)
				const selectableLeafChildren = parent.allLeafChildren.filter((child) => !child.data?.disableSelection)
				const newState = getCheckboxState(selectableLeafChildren?.length, api, parent)
				if (checkMarkState === undefined) {
					if (parent.id === undefined) {
						return
					}
					groupCheckMarkState.current.push({ id: parent.id, state: newState })
					api.refreshCells({ rowNodes: [parent] })
				} else if (checkMarkState.state !== newState) {
					groupCheckMarkState.current.forEach((mark) => {
						if (mark.id === parent.id) {
							mark.state = newState
						}
					})
					api.refreshCells({ rowNodes: [parent] })
				}
			}
		})

		const nodesToSelectWithParents = nodesToSelect.filter((parent) => parent.parent)
		if (nodesToSelectWithParents && nodesToSelectWithParents.length > 0) {
			const parents = [...new Set(nodesToSelectWithParents.map((node) => node.parent as IRowNode<TData>))]
			selectNodes(parents, api)
		}
	}

	const removeGroups = (groupNodes: IRowNode<TData>[]) => {
		let i = 0
		while (i < groupNodes.length) {
			const state = groupCheckMarkState.current.find((node) => node.id === groupNodes[i].id)
			if (state) {
				const index = groupCheckMarkState.current.indexOf(state)
				groupCheckMarkState.current.splice(index, 1)
			}

			i++
		}
	}

	return (selectedNodesWithParents: IRowNode<TData>[], api: GridApi<TData>) => {
		if (selectedNodesWithParents.length > 0) {
			const parentsWithSelectedChildren = [
				...new Set(selectedNodesWithParents.map((node) => node.parent as IRowNode<TData>)),
			]
			selectNodes(parentsWithSelectedChildren, api)

			//Deselect all groups that are selected, but has no selected children
			const selectedParentsWithoutSelectedChildren = api
				.getRenderedNodes()
				.filter(
					(node) =>
						node.group &&
						!node.allLeafChildren.some((child) => selectedNodesWithParents.includes(child)) &&
						groupCheckMarkState.current.find((state) => state.id === node.id)
				)
			if (selectedParentsWithoutSelectedChildren.length > 0) {
				api.refreshCells({ rowNodes: selectedParentsWithoutSelectedChildren })
				removeGroups(selectedParentsWithoutSelectedChildren)
			}
		} else if (groupCheckMarkState.current.length > 0) {
			// No rows are selected, but states have been saved from before - update and wipe!
			const groupNodes = api.getRenderedNodes().filter((node) => node.group)
			api.refreshCells({ rowNodes: groupNodes })
			groupCheckMarkState.current = []
		}
	}
}
