import type { IRowNode, ColDef } from '@ag-grid-community/core'
import type { CellData, TData } from '../Table.types'
import type { FormatterTValue } from './colDefUtils'

export function hasCellAggregatedData(
	node: IRowNode<TData> | null,
	colDef: ColDef<TData, FormatterTValue> | ColDef<TData, CellData | string>
) {
	if (!node?.aggData) {
		return false
	}
	return colDef.field ? !!node.aggData[colDef.field] : false
}
