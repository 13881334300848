import React, { useEffect, useState } from 'react'
import {
	IColumnDef,
	RowData,
	e_RenderType,
	e_RowSelectionType,
} from '@genusbiz/web-ui/controls/AgGridTable/Table.types'
import { IPod } from 'src/interfaces/IPod'
import { e_ResourceKind } from 'src/enums/e_ResourceKinds'
import { convertDateTime, convertUTCtoRelativeTime } from 'src/utils/dateTimeUtils'
import { e_PodPhaseName } from 'src/enums/e_PodPhase'
import { useTranslation } from 'react-i18next'
import { PodDetails } from './PodDetails'
import { useDispatch } from 'react-redux'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { AgGridTable, Icon } from '@genusbiz/web-ui/controls'
import classNames from 'clsx'
import { createStyle } from '@genusbiz/web-ui/theming'
import { defaultColors } from 'src/consts/defaultColors'
import { e_DataType } from '@genusbiz/web-ui/controls/AgGridTable/enums/e_DataType'

interface IProps {
	k8sRuntimeName: string
	pods: IPod[]
}
const formatReady = (pod: IPod) => {
	if (pod.status.phaseName === e_PodPhaseName.succeeded) {
		return '-'
	}
	return pod.readyCount + '/' + pod.status.containerStatuses.length
}
const getStatus = (pod: IPod) => {
	if (pod.objectMetadata.deletionTimestamp) {
		return e_PodPhaseName.terminating
	}
	if (!pod.status) {
		return e_PodPhaseName.unknown
	}
	return pod.status.phaseName
}

const formatMetric = (value?: number) => {
	if (!value) {
		return '-'
	}
	return Math.round(value).toString()
}

const classes = createStyle({
	statusRunning: {
		backgroundColor: defaultColors.successBackground,
	},
	statusTerminating: {
		backgroundColor: defaultColors.purpleBackground,
	},
	statusPending: {
		backgroundColor: defaultColors.warningBackground,
	},
	statusFailed: {
		backgroundColor: defaultColors.errorBackground,
	},
	statusSucceeded: {
		backgroundColor: defaultColors.successBackground,
	},
	statusReadyNotRunning: {
		backgroundColor: defaultColors.warningBackground,
	},
	resourceWarning: {
		color: defaultColors.warningText,
	},
})

export const PodTable = (props: IProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [rowData, setRowData] = useState<RowData[]>([])
	const [showPodDetails, setShowPodDetails] = useState(false)
	const [activePod, setActivePod] = useState<IPod | undefined>(undefined)

	const getStatusCellClass = (nodeId: string | undefined): string => {
		const pod = props.pods.find((pod) => pod.name === nodeId)
		if (!pod) {
			return ''
		}
		const status = getStatus(pod)
		const allPodsReady = pod.readyCount === pod.status.containerStatuses.length
		switch (status) {
			case e_PodPhaseName.running:
				if (!allPodsReady) {
					return classNames(classes.statusReadyNotRunning)
				} else {
					return classNames(classes.statusRunning)
				}
			case e_PodPhaseName.pending:
				return classNames(classes.statusPending)
			case e_PodPhaseName.failed:
				return classNames(classes.statusFailed)
			case e_PodPhaseName.succeeded:
				return classNames(classes.statusSucceeded)
			case e_PodPhaseName.terminating:
				return classNames(classes.statusTerminating)
			default:
				return ''
		}
	}

	const renderResourceCell = (warningThreshold: number) => {
		// eslint-disable-next-line react/display-name, react/no-multi-comp
		return (rowData: RowData, columnId: string) => {
			const value = parseInt(rowData.columns[columnId]?.value?.toString() ?? '') || 0
			return (
				<span style={{ color: value > warningThreshold ? defaultColors.warningText : '' }}>
					{rowData.columns[columnId]?.formattedValue}
				</span>
			)
		}
	}

	const columns: IColumnDef[] = [
		{
			headerName: t('TABLE_HEADERS:DESCRIPTION'),
			field: 'description',
			children: [
				{
					headerName: t('GENERAL:NAME'),
					field: 'name',
				},
				{
					headerName: t('GENERAL:MICROSERVICE'),
					field: 'appName',
					hide: true,
				},
				{
					headerName: t('GENERAL:OS'),
					field: 'runsOnWindows',
					hide: !props.pods.some((pod) => pod.runtimeConfig.runOnWindows === true),
				},
				{
					headerName: t('TABLE_HEADERS:KIND'),
					field: 'kind',
					hide: true,
				},
				{
					headerName: t('TABLE_HEADERS:CREATION_TIMESTAMP'),
					field: 'created',
					width: 145,
					dataType: e_DataType.date,
				},
				{
					headerName: t('TABLE_HEADERS:AGE'),
					field: 'age',
					getTooltip: (nodeId) => {
						// Dette virker tungvint. Er det noen måte å få pod.status.startTime på uten å måtte finde den i pods?
						return convertDateTime(props.pods.find((pod) => pod.name === nodeId)?.status.startTime ?? '', 'LT L')
					},
					width: 100,
					dataType: e_DataType.date,
				},
				{
					headerName: t('GENERAL:VERSION'),
					field: 'version',
					width: 130,
					onRenderCell: (rowData: RowData, columnId: string) => {
						const cellData = rowData.columns[columnId] as { value: string; podVersions: string[] }
						const version = cellData?.value
						const versions = cellData?.podVersions
						if (versions && versions.length > 1) {
							return (
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<span>{version}</span>
									<Icon
										iconClassName={'Fluent-CircleFill'}
										color={defaultColors.warningBackground}
										screenTip={t('PODS:MULTIPLE_VERSIONS_WARNING')}
									/>
								</div>
							)
						}
						return <span>{version}</span>
					},
				},
			],
		},
		{
			headerName: t('GENERAL:STATUS'),
			field: 'status',
			children: [
				{
					headerName: t('GENERAL:STATUS'),
					field: 'status',
					fillVariant: 'pill',
					pillWidth: 'stretch',
					getCellClassNameOnRender: getStatusCellClass,
					width: 125,
				},
				{
					headerName: t('TABLE_HEADERS:RESTARTS'),
					field: 'restarts',
					renderType: e_RenderType.number,
					textAlignment: 'center',
					dataType: e_DataType.int,
				},
				{
					headerName: t('GENERAL:READY'),
					field: 'ready',
					textAlignment: 'center',
					dataType: e_DataType.int,
				},
			],
		},
		{
			headerName: t('GENERAL:RESOURCES'),
			field: 'resources',
			children: [
				{
					headerName: 'CPU',
					field: 'cpu',
					textAlignment: 'center',
					screenTip: t('PODS:SHOWN_IN_CPU'),
					dataType: e_DataType.int,
				},

				{
					headerName: '%CPU/R',
					field: 'cpuRequest',
					textAlignment: 'center',
					screenTip: t('PODS:CPU_REQUEST_PERCENTAGE'),
					dataType: e_DataType.int,
				},
				{
					headerName: '%CPU/L',
					field: 'cpuLimit',
					textAlignment: 'center',
					screenTip: t('PODS:CPU_LIMIT_PERCENTAGE'),
					onRenderCell: renderResourceCell(100),
					dataType: e_DataType.int,
				},
				{
					headerName: t('GENERAL:MEMORY'),
					field: 'memory',
					textAlignment: 'center',
					screenTip: t('PODS:SHOWN_IN_MEMORY'),
					dataType: e_DataType.int,
				},
				{
					headerName: '%MEM/R',
					field: 'memoryRequest',
					textAlignment: 'center',
					screenTip: t('PODS:MEMORY_REQUEST_PERCENTAGE'),
					onRenderCell: renderResourceCell(200),
					dataType: e_DataType.int,
				},
				{
					headerName: '%MEM/L',
					field: 'memoryLimit',
					textAlignment: 'center',
					screenTip: t('PODS:MEMORY_LIMIT_PERCENTAGE'),
					onRenderCell: renderResourceCell(90),
					dataType: e_DataType.int,
				},
			],
		},
	]

	useEffect(() => {
		const podVersions = props.pods.reduce(
			(accumulator, pod) => {
				if (!accumulator[pod.appName]) {
					accumulator[pod.appName] = [pod.objectMetadata.version]
				} else if (!accumulator[pod.appName].includes(pod.objectMetadata.version)) {
					accumulator[pod.appName].push(pod.objectMetadata.version)
				}
				return accumulator
			},
			{} as { [key: string]: string[] }
		)

		const rows = props.pods.map((pod) => {
			return {
				id: pod.name,
				columns: {
					name: { value: pod.name },
					appName: { value: pod.runtimeConfig.runOnWindows ? 'windows-' : '' + pod.appName },
					runsOnWindows: { value: pod.runtimeConfig.runOnWindows ? 'windows' : 'linux' },
					kind: { value: e_ResourceKind.pod },
					created: {
						value: pod.objectMetadata.creationTimestamp,
						formattedValue: convertDateTime(pod.objectMetadata.creationTimestamp, 'LT L'),
					},
					age: {
						value: pod.status.startTime,
						formattedValue: pod.status.startTime ? convertUTCtoRelativeTime(pod.status.startTime, false) : '-',
					},
					version: { value: pod.objectMetadata.version, podVersions: podVersions[pod.appName] },
					status: {
						value: getStatus(pod),
					},
					restarts: {
						value: pod.status.containerStatuses.reduce(
							(accumulator, containerStatus) => accumulator + containerStatus.restartCount,
							0
						),
						isBold: pod.status.containerStatuses.some((containerStatus) => containerStatus.restartCount > 0),
					},
					ready: {
						value: pod.readyCount,
						formattedValue: formatReady(pod),
						isBold: pod.readyCount !== pod.status.containerStatuses.length,
					},
					cpu: {
						value: pod.podMetrics?.totalContainerCpuUsageInMilliVCores,
						formattedValue: formatMetric(pod.podMetrics?.totalContainerCpuUsageInMilliVCores),
					},
					cpuLimit: {
						value: pod.podMetrics?.cpuLimitPercentage,
						formattedValue: formatMetric(pod.podMetrics?.cpuLimitPercentage),
					},
					cpuRequest: {
						value: pod.podMetrics?.cpuRequestPercentage,
						formattedValue: formatMetric(pod.podMetrics?.cpuRequestPercentage),
					},
					memory: {
						value: pod.podMetrics?.totalContainerMemoryUsageInMiB,
						formattedValue: formatMetric(pod.podMetrics?.totalContainerMemoryUsageInMiB),
					},
					memoryLimit: {
						value: pod.podMetrics?.memoryLimitPercentage,
						formattedValue: formatMetric(pod.podMetrics?.memoryLimitPercentage),
					},
					memoryRequest: {
						value: pod.podMetrics?.memoryRequestPercentage,
						formattedValue: formatMetric(pod.podMetrics?.memoryRequestPercentage),
					},
				},
			}
		})
		setRowData(rows)
	}, [props.pods])

	const onActivate = (row: RowData) => {
		setShowPodDetails(true)
		setActivePod(props.pods.find((pod) => pod.name === row.id))
	}

	const onSelectionChanged = (ids: string[]) => {
		dispatch(operatorFrontendActions.setSelectedPods(props.pods.filter((pod) => ids.includes(pod.name))))
	}

	return (
		<>
			{showPodDetails && (
				<PodDetails
					isOpen={showPodDetails}
					onClose={() => {
						setShowPodDetails(false)
					}}
					pod={activePod}
				/>
			)}
			<AgGridTable
				columnDefs={columns}
				rowData={rowData}
				onDblClick={onActivate}
				compact
				onSelectionChange={onSelectionChanged}
				rowSelectionType={e_RowSelectionType.multi}
				zebraStripes
				zebraColorSet={{
					foreground: 'black',
					background: 'white',
					primary: '#fafafa',
				}}
			/>
		</>
	)
}
