import React from 'react'
import { Icon } from '../Icon'
import clsx from 'clsx'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	button: {
		background: 'unset',
		color: theme.colors.list.text,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		border: '1px solid transparent',
		outline: 'none',
		padding: '4px',
		gap: '8px',

		fontWeight: theme.controls.button.fontWeight,
		fontSize: theme.controls.button.fontSize,
		borderRadius: theme.controls.button.borderRadius,
	},

	iconLeft: {
		flexDirection: 'row',
	},
	iconRight: {
		flexDirection: 'row-reverse',
	},
	iconTop: {
		flexDirection: 'column',
	},
	iconBottom: {
		flexDirection: 'column-reverse',
	},

	largePadding: {
		padding: '6px 18px',
	},

	buttonStates: {
		'&:focus-visible': {
			outline: 'none',
			border: '1px solid ' + theme.colors.button.focusBorder,
		},
		'&:hover': {
			background: theme.colors.button.hoveredBackground,
		},
		'&:active': {
			background: theme.colors.button.pressedBackground,
		},
	},
	disabled: {
		color: theme.colors.button.disabledText,
	},
	checked: {
		backgroundColor: theme.colors.button.checkedBackground,
		color: theme.colors.button.checkedText,
	},
}))

interface IGenericButtonProps {
	label?: string
	onClick: (e: React.MouseEvent) => void
	className?: string
	screenTip?: string
	icon?: string
	isDefault?: boolean
	disabled?: boolean
	isChecked?: boolean

	iconPosition?: 'left' | 'right' | 'top' | 'bottom'
	largePadding?: boolean

	dataAttributes?: Record<string, string>
}

const getIconPositionClassName = (iconPosition?: 'left' | 'right' | 'top' | 'bottom') => {
	if (!iconPosition) {
		return undefined
	}

	switch (iconPosition) {
		case 'left':
			return classes.iconLeft
		case 'right':
			return classes.iconRight
		case 'top':
			return classes.iconTop
		case 'bottom':
			return classes.iconBottom
	}
}

const GenericButton = React.forwardRef((props: IGenericButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
	const iconPositionClassName = getIconPositionClassName(props.iconPosition)
	return (
		<button
			ref={ref}
			onClick={props.onClick}
			className={clsx(
				classes.button,
				!props.disabled && classes.buttonStates,
				props.disabled && classes.disabled,
				props.isChecked && classes.checked,
				iconPositionClassName,
				props.largePadding && classes.largePadding,
				props.className
			)}
			title={props.screenTip || props.label}
			disabled={props.disabled}
			data-isdefault={props.isDefault || undefined}
			{...props.dataAttributes}
		>
			{props.icon && <Icon iconClassName={props.icon} />}
			{props.label}
		</button>
	)
})

GenericButton.displayName = 'GenericButton'

export { GenericButton }
