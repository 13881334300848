import { defaultColors, defaultBorderRadius, defaultTypography } from '../theming/defaults'
import { transitions } from '../theming/themeProperties/transitions'
import { zIndexes } from '../theming/themeProperties/zIndexes'
import type { Palette } from '../theming/themeProperties/colors/paletteGenerator'
import { paletteGenerator } from '../theming/themeProperties/colors/paletteGenerator'
import type { ShadowTypes } from '../theming/themeProperties/shadows'
import { generateShadows } from '../theming/themeProperties/shadows'
import type { SemanticColors } from './semanticMapping'
import { mapSemanticColorsFromPalette } from './semanticMapping'
import { cssVar } from './themingManager'
import { generateAppBarColorsFromPalette } from './generateControlColorsFromPalette/generateAppBarColorsFromPalette'
import { generateButtonColorsFromSemanticPalette } from './generateControlColorsFromPalette/generateButtonColorsFromSemanticPalette'
import { generateInputColorsFromSemanticPalette } from './generateControlColorsFromPalette/generateInputColorsFromSemanticPalette'
import { generateNavigationPaneColorsFromPalette } from './generateControlColorsFromPalette/generateNavigationPaneColorsFromPalette'
import { generateListColorsFromPalette } from './generateControlColorsFromPalette/generateListColorsFromPalette'
import { getTypographyStyles, type ITypographicStyles } from '../theming/themeProperties/typography'
import { generateChipColorsFromSemanticPalette } from './generateControlColorsFromPalette/generateChipColorsFromSemanticPalette'

const generateControlStyles = (
	palette: Palette,
	semanticPalette: SemanticColors,
	typography: ITypographicStyles,
	shadows: ShadowTypes
) => {
	return {
		general: {
			dialogBorderRadius: '2px',
			navigationPanePadding: undefined,
		},
		dialog: {
			borderRadius: '2px',
			borderWidth: undefined,
			borderColor: cssVar(semanticPalette.body, 'line'),
			backdropColor: 'rgba(0, 0, 0, 0.2)',
			headerFontSize: '20px',
			headerFontWeight: 600,
			smallHeaderFontSize: '17px',
			smallHeaderFontWeight: 600,
		},
		button: {
			...generateButtonColorsFromSemanticPalette(semanticPalette),
			backgroundClip: undefined,
			borderRadius: '2px',
			borderStyle: 'solid',
			borderWidth: '1px',
			iconSize: 'small',
			boxShadow: 'none',
			outlineOffset: '-2px',
			outlineWidth: '1px',
			outlineStyle: 'solid',
			focusBorderStyle: 'solid',
			padding: undefined,
			margin: undefined,
			fontSize: '14px',
			fontWeight: 600,
			textDecoration: 'none',
		},
		chip: {
			...generateChipColorsFromSemanticPalette(semanticPalette),
			borderRadius: '2px',
			borderStyle: 'solid',
			borderWidth: '0px',
			borderWidthStrong: '2px',
			boxShadow: undefined,
			outlineOffset: '-2px',
			outlineWidth: '1px',
			outlineStyle: 'solid',
			focusBorderStyle: 'solid',
			textDecoration: undefined,
			padding: '2px 4px',
			fontSize: undefined,
			fontWeight: undefined,
		},
		input: {
			...generateInputColorsFromSemanticPalette(palette, semanticPalette),
			borderRadius: '2px',
			borderWidth: '1px',
			focusBorderWidth: '2px',
			padding: '6px 4px 5px 4px',
			editControlAlignment: 'stretch',
			editControlWidth: '100%',
			dropShadow: cssVar(shadows, 'none'),
			readOnlyFontStyle: undefined,
			placeholderTextColor: cssVar(semanticPalette.input, 'placeholderText'),
			placeholderTextFontStyle: undefined,
			subText: {
				spacing: '4px',
				padding: '4px',
				borderWidth: '1px',
				colors: {
					background: undefined,
					color: cssVar(semanticPalette.input, 'text'),
					border: cssVar(semanticPalette.input, 'border'),
				},
				warningColors: {
					background: undefined,
					color: cssVar(palette.warning, 'warningPrimary'),
					border: cssVar(palette.warning, 'warningPrimary'),
				},
				errorColors: {
					background: undefined,
					color: cssVar(palette.error, 'errorPrimary'),
					border: cssVar(palette.error, 'errorPrimary'),
				},
			},
		},
		appBar: {
			...generateAppBarColorsFromPalette(palette),

			height: undefined,
			padding: undefined,

			appModelNameFontSize: cssVar(typography.styles.h4, 'fontSize'),
			appModelNameFontWeight: cssVar(typography.styles.h4, 'fontWeight'),
			appModelNameMaxWidth: undefined,

			appNameFontSize: cssVar(typography.styles.h4, 'fontSize'),
			appNameFontWeight: undefined,
			appNameMaxWidth: undefined,

			appContextFontSize: cssVar(typography.styles.h4, 'fontSize'),
			appContextFontWeight: cssVar(typography.styles.h4, 'fontWeight'),
			appContextMaxWidth: undefined,

			navigationButtonFontSize: cssVar(typography.styles.h5, 'fontSize'),
			navigationButtonFontWeight: cssVar(typography.styles.h5, 'fontWeight'),

			showHelp: undefined,
			showSettings: undefined,
			showUserProfile: undefined,
		},
		navigationPane: {
			...generateNavigationPaneColorsFromPalette(palette, semanticPalette),
		},
		list: {
			...generateListColorsFromPalette(palette),
		},
	}
}

export function generateTheme(customColors?: Partial<{ [key in keyof typeof defaultColors]: string }>) {
	const themeColors = { ...defaultColors, ...customColors }

	const palette = paletteGenerator(themeColors)
	const typography = getTypographyStyles(defaultTypography)

	const semanticColors = mapSemanticColorsFromPalette(palette)
	const shadows = generateShadows('#000000')
	const theme = {
		palette: palette,
		colors: semanticColors,
		shadows: shadows,
		borderRadius: defaultBorderRadius,
		transitions,
		zIndex: zIndexes,
		typography: typography,
		controls: generateControlStyles(palette, semanticColors, typography, shadows),
	}

	return theme
}
