import React, { useCallback, useMemo } from 'react'
import type { LexicalEditor } from 'lexical'
import { Dropdown } from '../../Dropdown'
import { defaultFont, defaultFonts } from '../plugins'
import { getHandleChangeFont } from './getHandleChangeFont'
import { useTranslation } from '../../../translation'

const fontFamilyOptions = Object.entries(defaultFonts).map(([displayName, fontName]) => ({
	label: displayName,
	value: fontName,
	// eslint-disable-next-line
	render: () => <span style={{ fontFamily: fontName }}>{displayName}</span>,
}))

export function useFontFamilyDropdownRenderFn(
	editor: LexicalEditor,
	value: string | null,
	inheritTheme: boolean | undefined,
	isDisabled?: boolean
) {
	const { tcvi } = useTranslation()

	const options = useMemo(
		() => (inheritTheme ? [{ label: tcvi('GENERAL:INHERIT'), value: null }, ...fontFamilyOptions] : fontFamilyOptions),
		[inheritTheme, tcvi]
	)

	const handleChangeFontFamily = getHandleChangeFont(editor, 'font-family')
	return useCallback(
		(renderedIn: 'CommandBar' | 'Menu') => {
			return (
				<span style={{ width: renderedIn === 'CommandBar' ? '128px' : '100%' }}>
					<Dropdown<string | null>
						options={options}
						value={value || (inheritTheme ? null : defaultFont)}
						onChange={handleChangeFontFamily}
						disabled={isDisabled}
						stretch
						dataAttributes={{ 'data-cy': 'font-family-dropdown' }}
					/>
				</span>
			)
		},
		[handleChangeFontFamily, inheritTheme, isDisabled, options, value]
	)
}
