/**
 * Create a dashed separated string from strings, aka. kebab-case
 * e.g. ["a", "b", "c"] => "a-b-c"
 */
export const cssVarNameFormat = (...s: string[]) => s.join('-')

/**
 * Prefix a css variable name with double dash, making it a valid custom css property
 * e.g. "my-var" => "--my-var"
 */
export const cssVarDeclareFormat = (s: string) => `--${s}`

/**
 * Format variable name to use as CSS property values by wrapping it in a var() statement.
 * Optional single CSS variable fallback can also be added.
 * e.g. "my-var" => "var(--my-var)"
 */
export const cssVarUseFormat = (varName: string, fallback?: string) =>
	fallback ? `var(${cssVarDeclareFormat(varName)}, ${fallback})` : `var(${cssVarDeclareFormat(varName)})`
