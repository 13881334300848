import { Store } from '@reduxjs/toolkit'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { e_OperatorApiService, IOperatorApiConfig } from './types'
import { getBaseURLs } from './utils'
import { e_Header } from 'src/enums/e_Header'

// Empty Genus API config
const apiConfig: IOperatorApiConfig = {
	store: undefined, // redux store of client application
	baseURLs: undefined, // base URLs for each API service
}

/**
 * Initializes Operator API config with mode and an (optional) store instance.
 */
export const initializeOperatorApiConfig = (store: Store | undefined) => {
	// Set config properties
	apiConfig.store = store

	// Get and set base URLs based on genusApiMode
	apiConfig.baseURLs = getBaseURLs()
}

/**
 * Make Axios request config. Set Base URL from provided service, and add necessary headers
 */
export const makeRequestConfig = (apiService: e_OperatorApiService) => {
	if (!apiConfig.baseURLs) {
		throw Error('baseURLs must be initialized before using operatorAPI')
	}

	const requestConfig: AxiosRequestConfig = {
		headers: {
			[e_Header.requestedWith]: 'XMLHttpRequest',
		},
		baseURL: apiConfig.baseURLs[apiService],
	}

	return requestConfig
}

export const createProcessResponse = () => {
	return (response: AxiosResponse) => {
		return Promise.resolve(response.data)
	}
}
