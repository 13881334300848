import type { RefObject } from 'react'
import { useEffect, useState } from 'react'
import type { IMasterDetailPreakpoint } from '../types/IMasterDetailBreakpoint'

export const useWidthChange = (
	elementRef: RefObject<HTMLDivElement>,
	breakpoints: IMasterDetailPreakpoint[],
	callback: (breakpointName: string, width: number) => void
): number | null => {
	const [width, setWidth] = useState<number | null>(null)

	useEffect(() => {
		const observedElement = elementRef.current
		const observer = new ResizeObserver((entries) => {
			if (entries[0] && entries[0].contentRect) {
				const newWidth = entries[0].contentRect.width
				setWidth(newWidth)

				breakpoints.forEach((breakpoint) => {
					if (newWidth >= (breakpoint.minWidth || 0) && newWidth <= (breakpoint.maxWidth || Number.MAX_VALUE)) {
						callback(breakpoint.name, newWidth)
					}
				})
			}
		})

		if (observedElement) {
			observer.observe(observedElement)
		}

		return () => {
			if (observedElement) {
				observer.unobserve(observedElement)
			}
		}
	}, [breakpoints, callback, elementRef])

	return width
}
