import React from 'react'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils'
import { VERTICAL_HEADER_BASE_HEIGHT, MAX_HEADER_HEIGHT, VERTICAL_HEADER_PADDING } from '../../../consts'
import { ColumnHeaderInnerContent } from './ColumnHeaderContentInner'
import { createStyle } from '../../../../../theming'
import { SortArrow } from './SortArrow'

const classes = createStyle({
	columnHeaderTiltedBase: {
		position: 'absolute',
		width: '100%',
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: 4,
	},
	columnHeaderTiltedWrapper: {
		position: 'absolute',
		overflow: 'visible',
		left: 'calc(50% - 0.15em)',
	},
	columnHeaderTilted: { transformOrigin: 'left center', overflow: 'hidden', textOverflow: 'ellipsis' },
})

interface IProps {
	sorting?: 'asc' | 'desc'
	displayName: string
	onClick?: (e: React.MouseEvent | React.TouchEvent | KeyboardEvent) => void
	screenTip?: string
	hideLabel?: boolean
	iconName?: string
	iconPlacement?: 'before' | 'after'
}

export const VerticalColumnHeader = (props: IProps) => {
	return (
		<div
			style={{ position: 'relative', width: '100%', height: '100%' }}
			onClick={props.onClick}
			onTouchEnd={props.onClick}
			title={props.screenTip || props.displayName}
		>
			<div className={classes.columnHeaderTiltedBase} style={{ height: VERTICAL_HEADER_BASE_HEIGHT }}>
				<SortArrow sorting={props.sorting} />
			</div>

			<div className={classes.columnHeaderTiltedWrapper} style={{ bottom: VERTICAL_HEADER_BASE_HEIGHT }}>
				<div
					className={classes.columnHeaderTilted}
					style={{
						transform: `rotate(-${90}deg)`,
						width: `${MAX_HEADER_HEIGHT - VERTICAL_HEADER_BASE_HEIGHT - VERTICAL_HEADER_PADDING}px`,
					}}
				>
					<ColumnHeaderInnerContent
						displayName={props.displayName}
						hideLabel={!!props.hideLabel}
						iconName={props.iconName as IIconNames}
						iconPlacement={props.iconPlacement}
					/>
				</div>
			</div>
		</div>
	)
}
