import type { IKeyboardShortcutListenerContext } from './IKeyboardShortcutListenerContext'
import { rootKeyboardShortcutListener } from './KeyboardShortcutListenerContext'

const listenerContexts: IKeyboardShortcutListenerContext[] = [rootKeyboardShortcutListener]

export const addKeyboardListenerContext = (
	contextId: string,
	parentContextId: string | undefined,
	isRootContext: boolean | undefined
): IKeyboardShortcutListenerContext => {
	const result: IKeyboardShortcutListenerContext = {
		contextId: contextId,
		parentContextId: parentContextId,
		isRootContext: isRootContext,
	}

	listenerContexts.push(result)

	return result
}

export const getKeyboardListenerContext = (contextId: string): IKeyboardShortcutListenerContext | undefined => {
	return listenerContexts.find((context) => context.contextId === contextId)
}

export const getParentListenerContext = (contextId: string): IKeyboardShortcutListenerContext | undefined => {
	const listenerContext = getKeyboardListenerContext(contextId)

	if (listenerContext && listenerContext.parentContextId && !listenerContext.isRootContext) {
		return getKeyboardListenerContext(listenerContext.parentContextId)
	}

	return undefined
}

export const getOrCreateKeyboardListenerContext = (
	contextId: string,
	parentContextId: string | undefined,
	isRootContext: boolean | undefined
): IKeyboardShortcutListenerContext => {
	const existingContext = getKeyboardListenerContext(contextId)

	if (existingContext) {
		return existingContext
	}

	return addKeyboardListenerContext(contextId, parentContextId, isRootContext)
}

export const removeKeyboardListenerContext = (contextId: string) => {
	const contextIndex = listenerContexts.findIndex((context) => context.contextId === contextId)

	if (contextIndex !== -1) {
		listenerContexts.splice(contextIndex, 1)
	}
}
