import React from 'react'
import { IDetailItemSurfaceProps } from '@genusbiz/web-ui/surfaces/MasterDetail/types/IDetailItemSurfaceProps'
import { FieldContainer } from '@genusbiz/web-ui/surfaces'
import { e_Overflow } from '@genusbiz/web-ui/enums/e_Overflow'
import { e_TextAlignment } from '@genusbiz/web-ui/enums/e_TextAlignment'
import { DisplayField } from '@genusbiz/web-ui/controls/Field/DisplayField'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { useTranslation } from 'react-i18next'

interface OperatorEndpointDetailProps extends IDetailItemSurfaceProps {
	k8sRuntimes: IK8sRuntime[]
}

export const OperatorEndpointDetail = (props: OperatorEndpointDetailProps) => {
	const item = props.dataItem
	const k8sRuntimes = props.k8sRuntimes

	const { t } = useTranslation()

	const EMPTY_CONTENT = '---'

	const hostName = item['hostName'] ?? EMPTY_CONTENT

	const blueK8sRuntimeId = item['blueK8sRuntimeId'] as string | null
	const blueK8sRuntime = k8sRuntimes.find((runtime) => runtime.id === blueK8sRuntimeId)
	const blueK8sRuntimeName = blueK8sRuntime?.name

	const greenK8sRuntimeId = item['greenK8sRuntimeId'] as string | null
	const greenK8sRuntime = k8sRuntimes.find((runtime) => runtime.id === greenK8sRuntimeId)
	const greenK8sRuntimeName = greenK8sRuntime?.name

	return (
		<FieldContainer groupId="all" data-gridname={'outer-grid'} padding={14} gap={4}>
			<DisplayField
				label={t('ENDPOINTS:HOSTNAME')}
				content={hostName}
				overflow={e_Overflow.noScrollbars}
				textAlignment={e_TextAlignment.left}
				disableBorder
				labelBold
			/>
			<DisplayField
				label={t('ADMINISTRATION:BLUE_RUNTIME')}
				content={blueK8sRuntimeName}
				overflow={e_Overflow.noScrollbars}
				textAlignment={e_TextAlignment.left}
				disableBorder
				labelBold
			/>
			<DisplayField
				label={t('ADMINISTRATION:GREEN_RUNTIME')}
				content={greenK8sRuntimeName}
				overflow={e_Overflow.noScrollbars}
				textAlignment={e_TextAlignment.left}
				disableBorder
				labelBold
			/>
		</FieldContainer>
	)
}
