import React from 'react'
import Transition from 'react-transition-group/Transition'

const DURATION = 150

const defaultStyle = {
	transition: `opacity ${DURATION}ms ease-in`,
	willChange: 'opacity',
	opacity: 0,
}

const stateStyles = {
	entering: { opacity: 1 },
	entered: { opacity: 1 },
}

interface IFade {
	children: React.ReactElement
	show?: boolean
	onEntered?: () => void
	onEntering?: () => void
	onExited?: () => void
	onExiting?: () => void
}

export const Fade = (props: IFade) => {
	const styles = {
		...(props.children.props.style as React.CSSProperties | undefined),
		...defaultStyle,
	}

	return (
		<Transition
			in={props.show}
			onEntering={props.onEntering}
			onEntered={props.onEntered}
			onExiting={props.onExiting}
			onExited={props.onExited}
			timeout={DURATION}
			unmountOnExit
		>
			{(state) => {
				return React.cloneElement(props.children, {
					style: {
						...styles,
						...(state === 'entering' || state === 'entered' ? stateStyles[state] : undefined),
					},
				})
			}}
		</Transition>
	)
}
