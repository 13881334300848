import type { RowEvent } from '@ag-grid-community/core'
import React from 'react'
import type { ITableRowData, TData } from '../Table.types'
import { tDataToRowData } from '../utils'

export interface IDetailCellRenderer {
	onDetailCellRenderer?: ITableRowData['onDetailCellRenderer']
}

export const DetailCellRenderer = (props: IDetailCellRenderer & RowEvent<TData>) => {
	const rowData = tDataToRowData(props.data)

	const content = rowData && props.onDetailCellRenderer ? props.onDetailCellRenderer(rowData) : null

	//By wrapping the content in a div, the ag grid auto height works
	return <div style={{ margin: 5 }}>{content}</div>
}
