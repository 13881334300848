import { useMemo } from 'react'
import { MINUTES_PER_DAY, TICKS_PER_MINUTE } from './consts'

export const useOptionResolutionMinutes = (optionsResolutionMinutes: number | undefined) => {
	return useMemo(() => optionsResolutionMinutes || 15, [optionsResolutionMinutes])
}

export const useMinValueMinutes = (minValueTicksMS: number | undefined) => {
	return useMemo(() => (minValueTicksMS ? minValueTicksMS / TICKS_PER_MINUTE : 0), [minValueTicksMS])
}

export const useMaxValueMinutes = (maxValueTicksMS: number | undefined, isIntegerTime: boolean | undefined) => {
	return useMemo(() => {
		const minutesPerDay = isIntegerTime ? MINUTES_PER_DAY - 1 : undefined
		const actualMaxValueMinutes = maxValueTicksMS ? maxValueTicksMS / TICKS_PER_MINUTE : minutesPerDay

		if (isIntegerTime && actualMaxValueMinutes && actualMaxValueMinutes > MINUTES_PER_DAY - 1) {
			return MINUTES_PER_DAY - 1 // clamp max to 23:59 for integer time
		}
		return actualMaxValueMinutes
	}, [isIntegerTime, maxValueTicksMS])
}
