export enum e_DateFormat {
	default = 'default',
	longDateTime = 'longDateTime',
	shortDateTime = 'shortDateTime',
	longDate = 'longDate',
	shortDate = 'shortDate',
	longDateTimeWithoutSeconds = 'longDateTimeWithoutSeconds',
	shortDateTimeWithoutSeconds = 'shortDateTimeWithoutSeconds',
	shortDateWithWeekday = 'shortDateWithWeekday',
	shortDateWithShortWeekday = 'shortDateWithShortWeekday',
	relative = 'relative',
	relativeDate = 'relativeDate',
	dayMonth = 'dayMonth',
}
