import React from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'

import { Spinner } from '../Spinner'
import { Icon } from '../Icon'
import { useDataAttributes } from '../utils/useDataAttributes'

const classes = createStyle((theme) => ({
	controlButton: {
		padding: '1px 6px 1px 6px',
		margin: 0,
		border: 'none',
		outline: 'none',
		display: 'flex',
		alignItems: 'center',

		background: 'inherit',
		color: theme.colors.input.text,
		cursor: 'pointer',

		'&:hover, &:focus': { background: theme.colors.button.hoveredBackground },
		'&:active': { background: theme.colors.button.pressedBackground },
		'&:disabled, &:disabled:hover': {
			background: 'inherit',
			color: theme.colors.button.disabledText,
			cursor: 'default',
		},
	},

	extraSmallIcon: {
		padding: '1px 8px 1px 8px',
	},

	active: { background: theme.colors.button.pressedBackground },
	noIcon: {
		width: 12,
	},
	spinner: {
		width: 14,
		height: 14,
		cursor: 'progress',
	},
}))

interface IFormControlButtonProps {
	onClick?: (e: React.MouseEvent) => void
	onMouseDown?: (e: React.MouseEvent) => void
	dataAttributes?: Record<string, string>
	iconClassName?: string | null
	className?: string
	screenTip?: string
	showSpinner?: boolean
	disabled?: boolean
	isActive?: boolean
	tabStop?: boolean
	onTouchStart?: () => void
	onBlur?: (e: React.FocusEvent) => void
	setLogicalHover?: (focus: boolean) => void
	iconSize?: 'extraSmall' | 'small'
}

export const FormControlButton = React.forwardRef(
	(props: IFormControlButtonProps, ref: React.Ref<HTMLButtonElement>) => {
		const { disabled = false, showSpinner = false, tabStop = true } = props

		const dataAttributes = useDataAttributes(props.dataAttributes, 'form-control-button')

		const onMouseOver = () => {
			props.setLogicalHover?.(true)
		}

		const onMouseOut = () => {
			props.setLogicalHover?.(false)
		}

		return (
			<button
				{...dataAttributes}
				onKeyDown={(e) => e.key === 'Enter' && e.stopPropagation()}
				tabIndex={tabStop && !disabled ? undefined : -1} // The button is within a control that has (/should always have) a strategy for triggering the button action so tab stop should never be enabled on this type of button
				onClick={props.onClick && !disabled ? props.onClick : undefined}
				onMouseDown={props.onMouseDown && !disabled ? props.onMouseDown : undefined}
				disabled={disabled || !(props.onClick || props.onMouseDown)}
				className={classNames(props.className, classes.controlButton, {
					[classes.active]: props.isActive,
					[classes.extraSmallIcon]: props.iconSize === 'extraSmall',
				})}
				ref={ref}
				type="button"
				onTouchStart={props.onTouchStart}
				onBlur={props.onBlur}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
				title={props.screenTip}
			>
				{!showSpinner && props.iconClassName && (
					<Icon iconClassName={props.iconClassName} size={props.iconSize || 'small'} />
				)}
				{!showSpinner && props.iconClassName === null && <span className={classes.noIcon}>{'\u00A0'}</span>}
				{(showSpinner || props.iconClassName === undefined) && <Spinner className={classes.spinner} />}
			</button>
		)
	}
)

FormControlButton.displayName = 'FormControlButton'
