import React from 'react'

import { SignInWindow } from '.'
import { useDispatch } from 'react-redux'
import { authenticationActions } from 'src/features/Authentication/duck'
import { useTranslation } from 'react-i18next'
import { createStyle } from '@genusbiz/web-ui/theming'
import { getLoginResponse } from 'src/features/Authentication/utils/getLoginResponse'
import { defaultColors } from 'src/consts/defaultColors'

const classes = createStyle((theme) => ({
	authenticationPage: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.colors.body.background,
	},

	authenticationPageContents: {
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'stretch',
	},

	appBar: {
		display: 'flex',
		backgroundColor: theme.palette.primary.themePrimary,
		color: theme.colors.body.background,
		alignItems: 'center',
		padding: '10px',
	},

	appBarTitle: {
		paddingTop: '3px',
		paddingBottom: '3px',
	},
}))

export const OperatorAuthentication = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const loginHeader = t('SIGN_IN_WINDOW:LOGIN_HEADER')
	const loginSubHeader = (window.env.MODEL_IDENTIFIER as string) || ''

	const identityProviders = [
		{
			displayName: 'Username and Password',
			description: t('SIGN_IN_WINDOW:SIGN_IN'),
			providerColor: defaultColors.signInButton,
			providerStartUrl: '/authentication',
			isFormAuth: true,
		},
		{
			displayName: 'MS Entra ID',
			description: t('SIGN_IN_WINDOW:SIGN_IN_ENTRA_ID'),
			providerColor: defaultColors.signInButton,
			providerStartUrl: '/authentication/entraid',
			isFormAuth: true,
		},
	]

	const onValidSignIn = () => {
		dispatch(authenticationActions.setSignedIn(true))
		location.reload()
	}

	const onInvalidSignIn = () => {
		dispatch(authenticationActions.setSignedIn(false))
	}

	const loginResponse = getLoginResponse()

	return (
		<div className={classes.authenticationPage}>
			<div className={classes.authenticationPageContents}>
				<SignInWindow
					identityProviders={identityProviders}
					loginHeader={loginHeader}
					loginSubHeader={loginSubHeader}
					onValidSignIn={onValidSignIn}
					onInvalidSignIn={onInvalidSignIn}
					loginResponse={loginResponse}
				/>
			</div>
		</div>
	)
}
