import { useEffect } from 'react'
// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

// actually use them.
export const AutoFocusPlugin = () => {
	const [editor] = useLexicalComposerContext()

	useEffect(() => {
		// Focus the editor when the effect fires!
		editor.focus()
		//editor not included in the dependency array because we do not need to refocus when the value is changed from the outside
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return null
}
