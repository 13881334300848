import { useMemo } from 'react'

export const useDataAttributes = (
	dataAttributes: Record<string, string> | undefined = undefined,
	postfix: string | number | undefined
) => {
	return useMemo(() => {
		if (!dataAttributes) {
			return undefined
		}

		const dataAttributeWithoutPostfix = ['data-autofocus']

		const extendedDataAttributes: Record<string, string> = {}
		Object.entries(dataAttributes).forEach(([key, value]) => {
			if (dataAttributeWithoutPostfix.includes(key)) {
				extendedDataAttributes[key] = value
			} else {
				extendedDataAttributes[key] = `${value}${postfix !== undefined ? '-' + postfix : ''}`
			}
		})

		return extendedDataAttributes
	}, [dataAttributes, postfix])
}
