import React, { useRef, useContext, useMemo } from 'react'

import classNames from 'clsx'

import {
	CLASS_NAME_CONTENT,
	type DialogCommonButtonSet,
	type ICommonButtonTypes,
	type DialogButtonDescription,
	type DialogDimension,
	type DialogDimensionType,
	PADDING_WIDTH,
	MOBILE_WIDTH,
} from './DialogTypes'
import { topWindow } from '../utils/topWindow'
import { Modal } from '../Modal'
import { ResizableOverlay } from '../Resizable'
import { useTranslation } from '../../translation'
import type { CommandBarItem } from '../CommandBar'
import { KeyboardShortcutListenerContext, useRegisterKeyboardShortcutListenerContext } from '../utils/keyboard'
import { createStyle } from '../../theming'
import { generateGuid } from '../utils/generateGuid'
import { DialogButtons, DialogConfirmationMessage, DialogTitle } from './components'
import {
	useConfirmationMessageCallbacks,
	useDialogResize,
	useDialogSizing,
	useDialogInitialFocus,
	useModalRootSelector,
} from './hooks'
import { getButtons, getPointerDown } from './utils'

const classes = createStyle((theme) => {
	return {
		dialog: {
			color: theme.colors.list.text,
			background: theme.palette.background.white,
			flex: 1,
			height: '100%',
			borderRadius: theme.controls.dialog.borderRadius,
			display: 'flex',
			flexDirection: 'column',
			[`@media (max-width:${MOBILE_WIDTH}px)`]: { borderWidth: 0, height: '100%' },
		},

		resizableBorder: {
			borderRadius: theme.controls.dialog.borderRadius,
		},

		severityHeader: {
			height: '4px',
		},

		severityHeaderError: {
			background: theme.palette.error.errorPrimary,
		},

		severityHeaderWarning: {
			background: theme.palette.warning.warningPrimary,
		},

		dropShadow: {
			boxShadow: theme.shadows.strong,
		},
		dialogHeader: {
			display: 'inline-flex',
			paddingTop: 12,
			paddingLeft: 12,
			paddingRight: 12,
			paddingBottom: 12,
			marginBottom: 8,
		},

		dialogHeaderSmall: {
			paddingTop: 8,
			paddingLeft: 8,
			paddingRight: 8,
			paddingBottom: 8,
			marginBottom: 0,
		},
		dialogHeaderContent: {
			flex: '1',
			display: 'flex',
			cursor: 'move',
			overflow: 'hidden',
			touchAction: 'none',
			alignItems: 'start',
			[`@media (max-width:${MOBILE_WIDTH}px)`]: { cursor: 'default' },
		},
		dialogHeaderButtons: {
			display: 'flex',
			alignItems: 'center',
		},
		dialogHeaderText: {
			flex: 1,
			fontSize: theme.controls.dialog.headerFontSize,
			fontWeight: theme.controls.dialog.headerFontWeight,
		},
		dialogHeaderTextSmall: {
			flex: 1,
			fontSize: theme.controls.dialog.smallHeaderFontSize,
			fontWeight: theme.controls.dialog.smallHeaderFontWeight,
		},
		textOverflowEllipsis: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },

		dialogBorder: {
			borderWidth: theme.controls.dialog.borderWidth,
			borderColor: theme.controls.dialog.borderColor,
			borderStyle: 'solid',
		},
		severityErrorBorder: {
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: theme.palette.error.errorPrimary,
		},
		dialogContents: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			overflow: 'auto',
			paddingLeft: PADDING_WIDTH,
			paddingRight: PADDING_WIDTH,
			marginBottom: 8,
		},
		dialogContentsSmall: {
			paddingLeft: 8,
			paddingRight: 8,
			marginBottom: 8,
		},

		dialogContentsWithoutHeader: {
			paddingTop: 12,
		},
		dialogContentsWithoutHeaderSmall: {
			paddingTop: 8,
		},
		dialogAutoHeight: {
			flex: 'unset',
		},
	}
})

export interface IDialogProps {
	id?: string
	title?: string
	width?: DialogDimension
	widthType?: DialogDimensionType
	position?: 'center' | 'cascade'
	height?: DialogDimension
	heightType?: DialogDimensionType
	isOpen: boolean
	isModified?: boolean
	isValid?: boolean
	children?: React.ReactNode
	stack?: string
	disableCancelOnClickOutside?: boolean
	disableCancelOnEscape?: boolean
	hideBackdrop?: boolean
	hideDialogHeader?: boolean
	stayOnTop?: boolean
	disableShowConfirmationMessageIfModified?: boolean
	commonButtonSet?: DialogCommonButtonSet
	buttonDescriptions?: DialogButtonDescription[]
	handleClose?: (commonButton: ICommonButtonTypes) => void
	onKeyDown?: (e: React.KeyboardEvent) => void
	updatePosition?: boolean
	positionUpdated?: () => void
	severity?: string
	headerCommands?: CommandBarItem[]
	resizable?: boolean
	onResize?: (width: number, height: number) => void
	classes?: object
	variant?: 'normal' | 'small'
	modalClassName?: string
	backdropClassName?: string
	wrapTitle?: boolean
	isAlertDialog?: boolean
	dataAttributes?: Record<string, string>
	disableInitialAutoFocus?: boolean
	autoFocusTarget?: 'dialog' | 'descendent'
}

export const Dialog = (props: IDialogProps) => {
	const autoFocusTarget = props.autoFocusTarget ?? 'descendent'

	const domRef = useRef<HTMLDivElement>(null)
	const domDialog = useRef<HTMLDivElement>(null)
	const fullScreen = useRef(topWindow.innerWidth <= MOBILE_WIDTH)
	const previousActiveElement = useRef<HTMLElement | null>(topWindow.document.activeElement as HTMLElement | null)

	const { tcvi } = useTranslation()

	const id = useMemo(() => props.id ?? generateGuid(), [props.id])
	const ariaDescribedBy = `${id}-desc`
	const ariaLabeledBy = `${id}-label`
	const hideHeader = props.hideDialogHeader === true

	const parentContext = useContext(KeyboardShortcutListenerContext)
	const dialogKeyboardShortcutContext = useRegisterKeyboardShortcutListenerContext(id, true)

	const { showConfirmationMessage, setShowConfirmationMessage, handleYes, handleNo, handleCancel } =
		useConfirmationMessageCallbacks(previousActiveElement, props.handleClose)

	const modalRootSelector = useModalRootSelector(domDialog)

	const handlePointerDown = getPointerDown(domRef, domDialog)

	const { shallFitInnerSize, resizeOptions, handleResize } = useDialogResize(
		props.width,
		props.height,
		fullScreen,
		props.onResize
	)

	const dialogStyle = {
		maxHeight: fullScreen.current ? undefined : topWindow.innerHeight,
		overflow: shallFitInnerSize.height || shallFitInnerSize.width ? 'hidden' : 'auto',
	}

	const dialogIsFocusTarget = autoFocusTarget === 'dialog'
	useDialogInitialFocus(domRef, props.isOpen, dialogIsFocusTarget)

	const hasCancelButton = () => {
		return (
			props.commonButtonSet === 'okCancel' || props.commonButtonSet === 'yesNoCancel' || props.handleClose !== undefined
		)
	}

	const handleCancelKeyDown = (e: React.KeyboardEvent) => {
		e.stopPropagation()
		handleClose('cancel')
	}

	const handleBackdropClick = (e: React.MouseEvent) => {
		e.stopPropagation()

		if (!props.disableCancelOnClickOutside && hasCancelButton()) {
			handleClose('cancel')
		}

		// if user has clicked outside, the user should get a notification, for instance flash dialog border
	}

	const handleClose = (commonButton: ICommonButtonTypes) => {
		if (
			!props.disableShowConfirmationMessageIfModified &&
			props.commonButtonSet === 'okCancel' &&
			commonButton === 'cancel' &&
			props.isModified
		) {
			setShowConfirmationMessage(true)
		} else {
			previousActiveElement.current?.focus && previousActiveElement.current?.focus()

			props.handleClose?.(commonButton)
		}
	}

	const handleCloseButtonClick = () => {
		handleClose('cancel')
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (props.onKeyDown) {
			props.onKeyDown(e)
		}
	}

	const { leftButtons, rightButtons } = getButtons(
		id,
		props.commonButtonSet,
		props.buttonDescriptions,
		handleClose,
		tcvi,
		props.isValid
	)
	const { width, height, isAutoHeight, innerSizing } = useDialogSizing(domDialog, props, shallFitInnerSize, fullScreen)
	const dialogAutoHeight = isAutoHeight ? classes.dialogAutoHeight : undefined

	return (
		<>
			{showConfirmationMessage && (
				<DialogConfirmationMessage
					id={id}
					onCancel={handleCancel}
					onYes={handleYes}
					onNo={handleNo}
					isValid={props.isValid === undefined || props.isValid}
				/>
			)}
			<KeyboardShortcutListenerContext.Provider value={dialogKeyboardShortcutContext}>
				<Modal
					id={id}
					hideBackdrop={props.hideBackdrop}
					stayOnTop={props.stayOnTop}
					modalRootSelector={modalRootSelector}
					fullScreen={fullScreen.current}
					isOpen={props.isOpen}
					ref={domDialog}
					onBackdropClick={handleBackdropClick}
					onEscapePressed={!props.disableCancelOnEscape && hasCancelButton() ? handleCancelKeyDown : undefined}
					modalClassName={props.modalClassName}
					backdropClassName={props.backdropClassName}
					blocking={props.disableCancelOnClickOutside}
					ariaLabel={hideHeader ? props.title : undefined}
					ariaDescribedBy={ariaDescribedBy}
					ariaLabeledBy={ariaLabeledBy}
					isAlertDialog={props.isAlertDialog}
				>
					<div
						className={classNames(
							classes.dialog,
							classes.dialogBorder,
							classes.dropShadow,
							props.severity === 'error' && classes.severityErrorBorder
						)}
						style={dialogStyle}
						onKeyDown={onKeyDown}
						ref={domRef}
						data-keyboardlistener={
							dialogKeyboardShortcutContext !== parentContext ? dialogKeyboardShortcutContext.contextId : undefined
						}
						tabIndex={0}
						{...props.dataAttributes}
					>
						{props.severity && props.severity !== 'info' && (
							<div
								className={classNames(
									classes.severityHeader,
									props.severity === 'error' && classes.severityHeaderError,
									props.severity === 'warning' && classes.severityHeaderWarning
								)}
							/>
						)}
						{!hideHeader && (
							<DialogTitle
								title={props.title}
								classes={classes}
								handlePointerDown={handlePointerDown}
								handleCloseButtonClick={handleCloseButtonClick}
								hasCancelButton={hasCancelButton()}
								severity={props.severity}
								disableCancelOnEscape={props.disableCancelOnEscape}
								headerCommands={props.headerCommands}
								variant={props.variant}
								wrapTitle={props.wrapTitle}
								id={ariaLabeledBy}
							/>
						)}

						<div
							className={classNames(
								CLASS_NAME_CONTENT,
								classes.dialogContents,
								dialogAutoHeight,
								props.variant === 'small' && classes.dialogContentsSmall,
								hideHeader && props.variant !== 'small' && classes.dialogContentsWithoutHeader,
								hideHeader && props.variant === 'small' && classes.dialogContentsWithoutHeaderSmall
							)}
							style={innerSizing}
							id={ariaDescribedBy}
						>
							{props.children}
						</div>

						{(leftButtons.length > 0 || rightButtons.length > 0) && (
							<DialogButtons leftButtons={leftButtons} rightButtons={rightButtons} variant={props.variant} />
						)}
					</div>
					<ResizableOverlay
						id={id}
						fullScreen={fullScreen.current}
						enable={props.resizable && !fullScreen.current ? resizeOptions : {}}
						defaultSize={{ width, height }}
						onResize={handleResize}
						targetElement={domDialog}
					/>
				</Modal>
			</KeyboardShortcutListenerContext.Provider>
		</>
	)
}
