import React, { useState, useRef, useCallback, useLayoutEffect, useEffect, useContext } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

import { CommandBarButton } from './CommandBarButton'
import { CommandBarSubmenu } from './CommandBarSubmenu'
import { CommandBarDivider } from './CommandBarDivider'
import { CommandBarCustom } from './CommandBarCustom'
import { CommandBarOverflowMenu } from './CommandBarOverflowMenu'
import { useResizeObserver } from '../utils/useResizeObserver'

import type { CommandBarItem } from './CommandBar.types'
import { e_MenuItemType } from './../Menu/MenuItem.types'
import type { IKeyboardShortcutListenerContext } from '../utils/keyboard'
import { KeyboardShortcutListenerContext } from '../utils/keyboard'
import { registerKeyboardShortcut, removeRegisteredKeyboardShortcut } from '../utils/keyboard/registerKeyboardShortcut'

const classes = createStyle((theme) => ({
	commandBar: {
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		userSelect: 'none',
		'& > *': { flexShrink: 0 },
	},
	commandBarSmall: { fontSize: 12 },
	rightAligned: {
		justifyContent: 'flex-end',
	},
	commandBarItem: {
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		padding: 7, // plus 1 pixel for focus border
		border: '1px solid transparent',
		pointerEvents: 'auto',
		'&:focus-visible': {
			borderColor: theme.colors.body.focusBorder,
			outline: 'none',
		},
		borderRadius: theme.controls.button.borderRadius,
		margin: '0 1px',
	},
	commandBarItemSmall: { padding: 4 },
	enabled: {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.button.hoveredBackground,
			color: theme.colors.button.hoveredText,
		},
		'&:active': {
			backgroundColor: theme.colors.button.pressedBackground,
			color: theme.colors.button.pressedText,
		},
	},
	active: {
		backgroundColor: theme.colors.button.pressedBackground,
		color: theme.colors.button.pressedText,
		'&:hover': {
			backgroundColor: theme.colors.button.checkedHoveredBackground,
			color: theme.colors.button.checkedHoveredText,
		},
	},
	isChecked: {
		backgroundColor: theme.colors.button.checkedBackground,
		color: theme.colors.button.checkedText,
		'&:hover': {
			backgroundColor: theme.colors.button.checkedHoveredBackground,
			color: theme.colors.button.checkedHoveredText,
		},
		'&:active': {
			backgroundColor: theme.colors.button.pressedBackground,
			color: theme.colors.button.pressedText,
		},
	},
	disabled: {
		cursor: 'default',
		opacity: 0.5,
	},
	buttonIcon: {
		display: 'flex',
		alignItems: 'center',
	},
	buttonIconWithText: {
		display: 'flex',
		alignItems: 'center',
		marginRight: '0.3em',
	},
	commandBarItemCustom: {
		display: 'flex',
		padding: '2px 4px',
	},
	submenuIcon: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '2px',
		marginLeft: '4px',
		fontSize: '12px',
	},
	separator: {
		padding: '0.6em 0.3em',
		'&::before': {
			content: "' '",
			width: '1px',
			height: '100%',
			display: 'block',
			background: theme.colors.button.border,
		},
	},
	hidden: {
		visibility: 'hidden',
	},
	forceWidth: {
		width: '100%',
	},
	handleOverflow: {
		flex: 1,
	},
	iconString: {
		fontSize: 14,
		lineHeight: '16px',
	},
}))

export type ICommandBarClasses = typeof classes

function flattenSections(items?: CommandBarItem[]) {
	if (!items) {
		return []
	}

	return (
		items &&
		items.reduce((acc, item) => {
			if (!item.hidden) {
				acc.push(item)
			}

			return acc
		}, [] as CommandBarItem[])
	)
}

/**
 * Remove dividers from beginning, end and dividers that comes after another divider
 */
function limitDividers(items: CommandBarItem[]) {
	let prevItemWasDivider = true

	return items.filter((item, index) => {
		if (item.type !== e_MenuItemType.divider) {
			prevItemWasDivider = false
			return true
		}

		if (item.hidden) {
			return true
		}

		if (prevItemWasDivider) {
			return false
		}

		if (index === 0 || index === items.length - 1) {
			return false
		}

		prevItemWasDivider = true
		return true
	})
}

const doesCommandBarItemsDifferVisually = (aItems: CommandBarItem[], bItems: CommandBarItem[]) => {
	if (aItems.length !== bItems.length) {
		return true
	}

	return aItems.some((a, i) => {
		const b = bItems[i]

		if (a.type !== b.type || a.iconClassName !== b.iconClassName || a.name !== b.name || a.hidden !== b.hidden) {
			return true
		}

		return false
	})
}

const checkHasId = (item: CommandBarItem, context: string) => {
	if (!item.id) {
		throw new Error(
			`${context}: CommandBar items used with keyboard shortcuts requires an id. Source ${item.name || ''}`
		)
	}
}

const registerCommandBarItemKeyboardShortcuts = (
	items: CommandBarItem[],
	context: IKeyboardShortcutListenerContext
) => {
	items.forEach((item) => {
		if (item.keyboardShortcut && item.onClick) {
			checkHasId(item, 'registerCommandBarItemKeyboardShortcuts')
			item.id &&
				registerKeyboardShortcut(item.keyboardShortcut, context.contextId, 'commandBarItem_' + item.id, () => {
					if (item.disabled) {
						return
					}
					item.onClick && item.onClick(undefined)
				})
		}

		if (item.type === e_MenuItemType.section && item.items) {
			registerCommandBarItemKeyboardShortcuts(item.items, context)
		}
	})
}

const unRegisterCommandBarItemKeyboardShortcuts = (
	items: CommandBarItem[],
	context: IKeyboardShortcutListenerContext
) => {
	items.forEach((item) => {
		if (item.keyboardShortcut && item.onClick) {
			checkHasId(item, 'unRegisterCommandBarItemKeyboardShortcuts')
			item.id && removeRegisteredKeyboardShortcut(context.contextId, 'commandBarItem_' + item.id)
		}

		if (item.type === e_MenuItemType.section && item.items) {
			unRegisterCommandBarItemKeyboardShortcuts(item.items, context)
		}
	})
}

interface ICommandBarProps {
	items?: CommandBarItem[]
	className?: string
	leftDivider?: boolean
	rightDivider?: boolean
	handleOverflow?: boolean
	visibleItemsCount?: number
	size?: 'medium' | 'small'
	onToggleHasSubMenuOpen?: (isOpen: boolean) => void
	dataAttributes?: Record<string, string>
	rightAligned?: boolean
	limitVisibleItemsCount?: boolean
	padding?: {
		paddingTop: number
		paddingRight: number
		paddingBottom: number
		paddingLeft: number
	}
	forceWidth?: boolean
	onItemClick?: (item: CommandBarItem) => void
}

export const CommandBar = (props: ICommandBarProps) => {
	const ref = useRef<HTMLDivElement>(null)

	const [numberOfItemsToDisplay, setNumberOfItemsToDisplay] = useState<number | undefined>(props.visibleItemsCount)
	const [itemWidths, setItemWidths] = useState([] as number[])

	const measuredItems = useRef([] as CommandBarItem[])
	const shouldMeasureItems = useRef(true)
	shouldMeasureItems.current = doesCommandBarItemsDifferVisually(props.items || [], measuredItems.current)

	const getOverflowIndex = useCallback(
		(items: CommandBarItem[], visibleItemsCount: number) => {
			if (!props.limitVisibleItemsCount) {
				return items.length
			}

			const itemsWithoutDividers = items.filter((item) => item.type !== e_MenuItemType.divider && !item.hidden)

			const lastVisibleItem =
				itemsWithoutDividers.length > visibleItemsCount
					? itemsWithoutDividers[visibleItemsCount - 1]
					: itemsWithoutDividers[itemsWithoutDividers.length - 1]

			return items.indexOf(lastVisibleItem) + 1
		},
		[props.limitVisibleItemsCount]
	)

	let itemsToDisplay = limitDividers(flattenSections(props.items))
	let overflowItems: CommandBarItem[] = []

	// If handling overflow: only render items that fit inside parent div, if item widths have not been measured
	if (!shouldMeasureItems.current && numberOfItemsToDisplay !== undefined && props.handleOverflow) {
		overflowItems = limitDividers(itemsToDisplay.slice(numberOfItemsToDisplay))
		itemsToDisplay = limitDividers(itemsToDisplay.slice(0, numberOfItemsToDisplay))
		// If not handling overflow, just use the number specified by the user
	} else if (props.visibleItemsCount !== undefined) {
		const overflowIndex = getOverflowIndex(itemsToDisplay, props.visibleItemsCount)

		overflowItems = limitDividers(itemsToDisplay.slice(overflowIndex))
		itemsToDisplay = limitDividers(itemsToDisplay.slice(0, overflowIndex))
	}

	const shouldRenderOverflowButton = (props.handleOverflow && shouldMeasureItems.current) || overflowItems.length > 0

	const onResize = useCallback(
		(rect: DOMRectReadOnly | { width: number }) => {
			if (
				!props.items ||
				props.items.length === 0 ||
				!props.handleOverflow ||
				// skip calculating items that should be hidden, if measurements haven't been made yet
				shouldMeasureItems.current
			) {
				return
			}

			const parentNode = ref.current
			if (!parentNode) {
				return
			}

			const parentWidth = Math.ceil(rect.width)

			// Either overflow button, or divider (in case props.rightDivider)
			const lastElement = parentNode.childNodes[parentNode.childNodes.length - 1] as HTMLElement | undefined
			const overflowButtonWidth = shouldRenderOverflowButton ? 32 : 0
			const rightDivider = props.rightDivider ? lastElement : undefined
			const rightDividerWidth = rightDivider?.offsetWidth || 0

			const staticElementsWidth = overflowButtonWidth + rightDividerWidth

			let visibleItems = 0
			itemWidths.some((width) => {
				if (width + staticElementsWidth <= parentWidth) {
					visibleItems++
					return false
				} else {
					return true
				}
			})

			setNumberOfItemsToDisplay(
				props.visibleItemsCount
					? Math.min(
							getOverflowIndex(limitDividers(flattenSections(props.items)), props.visibleItemsCount),
							visibleItems
					  )
					: visibleItems
			)
		},
		[
			props.items,
			props.handleOverflow,
			props.rightDivider,
			props.visibleItemsCount,
			shouldRenderOverflowButton,
			itemWidths,
			getOverflowIndex,
		]
	)

	const keyboardShortcutListenerContext = useContext(KeyboardShortcutListenerContext)

	useEffect(() => {
		if (props.items) {
			registerCommandBarItemKeyboardShortcuts(props.items, keyboardShortcutListenerContext)
		}
		return () => {
			if (props.items) {
				unRegisterCommandBarItemKeyboardShortcuts(props.items, keyboardShortcutListenerContext)
			}
		}
	}, [props.items, keyboardShortcutListenerContext])

	useResizeObserver(onResize, ref)

	useLayoutEffect(() => {
		void document.fonts.ready.then(() => {
			// Measure width of rendered items
			if (!props.items || props.items.length === 0 || !props.handleOverflow || !shouldMeasureItems.current) {
				return
			}
			if (ref.current) {
				const items = [...ref.current.childNodes].filter((node) => {
					if (node.nodeName === 'DIV') {
						return true
					}
				}) as HTMLDivElement[]
				let offsetWidth = 0
				const newItemWidths = items.slice(0, -1).map((node) => {
					offsetWidth += node.offsetWidth
					return offsetWidth
				})

				setItemWidths(newItemWidths)
				// Reference till next re-render, to compare if new array of items needs to be measured
				measuredItems.current = props.items

				// Force trigger onResize, as initial resize observer might execute before fonts have loaded
				onResize({ width: ref.current.clientWidth })
			}
		})
	}, [props.items, props.handleOverflow, props.visibleItemsCount, ref, onResize])

	const onClick = (item: CommandBarItem) => (e: React.MouseEvent | React.KeyboardEvent) => {
		item.onClick?.(e)

		props.onItemClick?.(item)
	}

	return (
		<div
			style={props.padding || {}}
			className={classNames(
				{
					[classes.rightAligned]: props.rightAligned,
					[classes.forceWidth]: props.forceWidth,
					[classes.commandBarSmall]: props.size === 'small',
					[classes.handleOverflow]: props.handleOverflow,
				},
				classes.commandBar,
				props.className
			)}
			{...props.dataAttributes}
			ref={ref}
		>
			{props.leftDivider && <CommandBarDivider classes={classes} />}
			{itemsToDisplay.map((item, index) => {
				const key = `${item.type}_${index}`
				const itemType = item.type

				switch (itemType) {
					case e_MenuItemType.action:
						return (
							<CommandBarButton
								key={key}
								id={item.id}
								dataAttributes={item.dataAttributes}
								classes={classes}
								name={item.name}
								iconClassName={item.iconClassName}
								iconColorClass={item.iconColorClass}
								onClick={onClick(item)}
								disabled={item.disabled}
								isChecked={item.isChecked}
								hidden={item.hidden}
								screenTip={item.screenTip}
								keyboardShortcut={item.keyboardShortcut}
								size={props.size}
								badgeValue={item.badgeValue}
								contentLayout={item.contentLayout}
								iconString={item.iconString}
							/>
						)
					case e_MenuItemType.custom:
						return (
							<CommandBarCustom
								key={key}
								id={item.id}
								dataAttributes={item.dataAttributes}
								classes={classes}
								contentRenderer={item.render}
							/>
						)
					case e_MenuItemType.section:
						return (
							<CommandBarSubmenu
								key={key}
								id={item.id}
								dataAttributes={item.dataAttributes}
								classes={classes}
								name={item.name}
								iconClassName={item.iconClassName}
								iconColorClass={item.iconColorClass}
								items={item.items}
								disabled={item.disabled}
								hidden={item.hidden}
								screenTip={item.screenTip}
								size={props.size}
								showDropdownButton={item.showDropdownButton}
								contentLayout={item.contentLayout}
								onToggleIsOpen={props.onToggleHasSubMenuOpen}
							/>
						)
					case e_MenuItemType.divider:
						return <CommandBarDivider classes={classes} key={key} id={item.id} />
					default: {
						const unknownItem = item as { type: string | number }
						throw new Error(`Unhandled item type ${unknownItem.type} (type: ${typeof unknownItem.type}) in CommandBar`)
					}
				}
			})}
			{shouldRenderOverflowButton && (
				<CommandBarOverflowMenu classes={classes} items={overflowItems} size={props.size} />
			)}
			{props.rightDivider && <CommandBarDivider classes={classes} />}
		</div>
	)
}
