import { DurationInput } from '../../../../DurationInput'
import { e_Interpretation } from '../../../enums/e_Interpretation'
import React, { useEffect, useRef } from 'react'
import type { ICellRenderer, ITableContext } from '../../../Table.types'

interface ICellRendererDuration extends ICellRenderer {
	value: number | undefined
	interpretation: e_Interpretation
	initialKey?: string
	context: ITableContext
	onClose?: () => void
}

export const CellRendererDuration = (props: ICellRendererDuration) => {
	const valueRef = useRef<number | null | undefined>(props.value)
	const inputRef = useRef<HTMLInputElement>(null)

	const onChange = (value: number | null | undefined) => {
		valueRef.current = value
		props.setValue?.(value)
	}

	const isIntegerTime = props.interpretation === e_Interpretation.integerTime

	useEffect(() => {
		if (!props.context.initEditorWithPopper) {
			inputRef.current?.focus()
		}
		props.context.initEditorWithPopper = false
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<DurationInput
			ref={inputRef}
			value={props.value}
			onChange={onChange}
			onClose={props.onClose}
			onBlur={props.onClose}
			enableOptionDropdown
			isIntegerTime={isIntegerTime}
			dropdownOpen={props.context.initEditorWithPopper}
			reserveHelperTextSpace="off"
			className={props.className}
			error={props.hasError}
			initialValue={props.initialKey}
		/>
	)
}
