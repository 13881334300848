export function setsAreEqual<T>(a: Set<T> | undefined, b: Set<T> | undefined) {
	if (a === b) {
		return true
	}

	if (a === undefined || b === undefined) {
		return false
	}

	if (a.size !== b.size) {
		return false
	}

	return Array.from(a).every((element) => {
		return b.has(element)
	})
}
