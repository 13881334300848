import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	inputHeight: {
		width: '0px !important',
		opacity: '0 !important',
		flex: '0 !important',
		paddingRight: '0 !important',
		paddingLeft: '0 !important',
		marginTop: '0 !important',
		marginRight: '0 !important',
		marginBottom: '0 !important',
		marginLeft: '0 !important',
		padding: theme.controls.input.padding,
		borderStyle: 'none',
	},
	border: {
		borderWidth: `${theme.controls.input.borderWidth} 0px !important`,
		borderStyle: 'solid !important',
		borderColor: 'transparent !important',
	},
}))

/**
 * Utility component to keep consistent FormControl heights when a control does not contain an actual <input>
 *
 */

interface IInputHeightProps {
	border?: boolean
}

export const InputHeight = (props: IInputHeightProps) => {
	// A bug in Safari causes <inputs> without an intrinsic width to be ignored towards calculating line-height
	// https://github.com/philipwalton/flexbugs/issues/270
	// which is why we add the string " " as a placeholder

	return (
		<input
			className={classNames(classes.inputHeight, props.border && classes.border)}
			type="text"
			disabled
			tabIndex={-1}
			aria-hidden="true"
			placeholder=" "
		/>
	)
}
