export const FOCUSABLE_ELEMENTS = `
	a:not([hidden]),
	select:not([disabled]):not([hidden]),
	button:not([disabled]):not([hidden]),
	input:not([disabled]):not([hidden]),
	textarea:not([disabled]):not([hidden]),
	[tabindex]:not([tabindex="-1"]):not([aria-disabled="true"]):not([aria-hidden="true"]):not([disabled]):not([hidden])`

export const getFirstFocusable = (rootElement: HTMLElement | null) => {
	if (!rootElement) {
		return
	}

	return (rootElement.querySelector(FOCUSABLE_ELEMENTS) as HTMLElement) || undefined
}
