import type { ReactNode } from 'react'
import React, { useRef, useEffect } from 'react'
import clamp from 'lodash/clamp'
import { createStyle } from '../../theming'
import clsx from 'clsx'

enum Cursors {
	top = 'row-resize',
	right = 'col-resize',
	bottom = 'row-resize',
	left = 'col-resize',
	corner = 'se-resize',
}

const classes = createStyle({
	resizer: { position: 'relative' },
	resizeHorizontal: { overflowX: 'auto' },
	resizeVertical: { overflowY: 'auto' },
	resizeBothAxis: { overflow: 'auto' },
	sizer: {
		position: 'absolute',
		touchAction: 'none',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
	},
	top: {
		height: 8,
		bottom: 'unset',
		cursor: Cursors['top'],
	},
	bottom: {
		height: 8,
		top: 'unset',
		cursor: Cursors['bottom'],
	},
	left: {
		width: 8,
		right: 'unset',
		cursor: Cursors['left'],
	},
	right: {
		width: 8,
		left: 'unset',
		cursor: Cursors['right'],
	},

	corner: {
		width: 20,
		height: 20,
		top: 'unset',
		left: 'unset',
		background:
			"url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
		'background-position': 'bottom right',
		padding: '0 3px 3px 0',
		'background-repeat': 'no-repeat',
		'background-origin': 'content-box',
		'box-sizing': 'border-box',
		cursor: Cursors['corner'],
	},
})

interface IResizableProps {
	children: ReactNode | ReactNode[]
	fullScreen?: boolean
	defaultSize?: { width?: number; height?: number }
	minSize?: { width: number; height: number }
	maxSize?: { width: number; height: number }
	enable?: { corner?: boolean; top?: boolean; right?: boolean; bottom?: boolean; left?: boolean }
	isAutoHeight?: boolean
	suppressPositioning?: boolean
	style?: React.CSSProperties
	className?: string
	dropShadowClass?: string
	onResize?: (width: number, height: number) => void
}

export const Resizable = (props: IResizableProps) => {
	const { minSize = { width: 50, height: 50 }, maxSize = { width: 3000, height: 3000 } } = props
	const ref = useRef<HTMLDivElement>(null)

	const direction = useRef<'corner' | 'top' | 'right' | 'bottom' | 'left'>()
	const flexDirection = useRef<'vertical' | 'horizontal'>()

	const defaultWidth = props.defaultSize?.width
	const defaultHeight = props.defaultSize?.height

	const initialWidth = useRef(defaultWidth)
	const width = useRef(defaultWidth)
	const height = useRef(defaultHeight)
	const initialHeight = useRef(defaultHeight)
	const initialLeft = useRef('')
	const initialTop = useRef('')
	const initialX = useRef(0)
	const initialY = useRef(0)
	const resizing = useRef(false) //to prevent MouseMove from running after MouseUp

	useEffect(() => {
		if (ref.current) {
			ref.current.style.top = '0'
			ref.current.style.left = '0'
		}
	}, [props.style])

	useEffect(() => {
		const parentElement = ref.current?.parentElement
		if (!parentElement) {
			return
		}

		const dir = getComputedStyle(parentElement).flexDirection
		if (dir.startsWith('column')) {
			flexDirection.current = 'vertical'
		} else if (dir.startsWith('row')) {
			flexDirection.current = 'horizontal'
		}
	}, [])

	useEffect(() => {
		//Update initial sizes and ref if changed from the outside when resizing is not active
		if ((defaultWidth || defaultHeight) && !resizing.current && !props.fullScreen) {
			initialWidth.current = width.current = defaultWidth
			initialHeight.current = height.current = defaultHeight
			if (ref.current) {
				if (defaultWidth) {
					ref.current.style.width = `${defaultWidth}px`
				} else {
					ref.current.style.width = 'auto'
				}

				if (defaultHeight) {
					ref.current.style.height = `${defaultHeight}px`
				} else {
					ref.current.style.height = 'auto'
				}
			}
		}
	}, [defaultWidth, defaultHeight, props.fullScreen])

	const completePointerOperation = (event: Event) => {
		resizing.current = false
		setTimeout(() => {
			const pointerEvent = event as PointerEvent

			const sizerElement = pointerEvent.target as Element

			sizerElement.removeEventListener('pointerup', handlePointerUp)
			sizerElement.removeEventListener('pointermove', handlePointerMove)
			sizerElement.removeEventListener('pointercancel', handlePointerCancel)
		}, 0)
	}

	const handlePointerUp = (event: Event) => {
		completePointerOperation(event)

		event.preventDefault()
		event.stopPropagation()
	}

	const handlePointerMove = (event: Event) => {
		event.preventDefault()
		event.stopPropagation()

		if (!resizing.current) {
			return
		}

		const pointerEvent = event as PointerEvent
		// clamp mouse coordinates to window
		const mousePos = {
			x: clamp(pointerEvent.clientX, 0, window.innerWidth - 1),
			y: clamp(pointerEvent.clientY, 0, window.innerHeight - 1),
		}

		if (direction.current === 'left' || direction.current === 'right' || direction.current === 'corner') {
			const deltaX = direction.current === 'left' ? initialX.current - mousePos.x : mousePos.x - initialX.current

			width.current = clamp((initialWidth.current || 0) + deltaX, minSize.width, maxSize.width)

			if (ref.current) {
				ref.current.style.width = `${width.current}px`

				if (direction.current === 'left' && !props.suppressPositioning) {
					ref.current.style.left = `${
						parseFloat(initialLeft.current?.replace('px', '')) + (mousePos.x - initialX.current)
					}px`
				}
				if (flexDirection.current === 'horizontal') {
					ref.current.style.flexBasis = `${width.current}px`
				}
			}
		}

		if (direction.current === 'top' || direction.current === 'bottom' || direction.current === 'corner') {
			const deltaY = direction.current === 'top' ? initialY.current - mousePos.y : mousePos.y - initialY.current

			height.current = clamp((initialHeight.current || 0) + deltaY, minSize.height, maxSize.height)
			if (ref.current) {
				ref.current.style.height = `${height.current}px`

				if (direction.current === 'top' && !props.suppressPositioning) {
					ref.current.style.top = `${
						parseFloat(initialTop.current.replace('px', '')) + (mousePos.y - initialY.current)
					}px`
				}
				if (flexDirection.current === 'vertical') {
					ref.current.style.flexBasis = `${height.current}px`
				}
			}
		}

		//Set width and size to current window if undefined
		if (width.current === undefined) {
			width.current = ref.current?.clientWidth
		}
		if (height.current === undefined) {
			height.current = ref.current?.clientHeight
		}

		//If still undefined, do not send onResize callback
		if (width.current === undefined || height.current === undefined) {
			return
		}

		props.onResize?.(width.current, height.current)
	}

	const handlePointerCancel = (event: Event) => {
		completePointerOperation(event)
	}

	const startResize = (event: React.PointerEvent, dir: 'corner' | 'top' | 'right' | 'bottom' | 'left') => {
		const sizerElement = event.target as Element
		if (!sizerElement) {
			return
		}

		direction.current = dir
		event.preventDefault()
		event.stopPropagation()

		sizerElement.setPointerCapture(event.pointerId)
		sizerElement.addEventListener('pointerup', handlePointerUp)
		sizerElement.addEventListener('pointermove', handlePointerMove)
		sizerElement.addEventListener('pointercancel', handlePointerCancel)

		initialLeft.current = ref.current?.style.left || ''
		initialTop.current = ref.current?.style.top || ''
		initialX.current = event.clientX
		initialY.current = event.clientY

		resizing.current = true
		initialWidth.current = ref.current?.clientWidth
		initialHeight.current = ref.current?.clientHeight
	}

	const resizableClassNames = clsx(classes.resizer, props.className, props.dropShadowClass, {
		[classes.resizeVertical]: props.enable?.top || props.enable?.bottom,
		[classes.resizeHorizontal]: props.enable?.right || props.enable?.left,
		[classes.resizeBothAxis]: props.enable?.corner,
	})

	const style: Record<string, string | number | undefined> = {
		...props.style,
		width: props.fullScreen ? '100%' : `${width.current!}px`,
		height: getHeight(height.current!, props.fullScreen, props.isAutoHeight),
		overflow: 'auto',
	}

	const resizeVertical = props.enable?.top || props.enable?.bottom || props.enable?.corner
	const resizeHorizontal = props.enable?.left || props.enable?.right || props.enable?.corner

	if (resizeVertical && flexDirection.current === 'vertical') {
		style.flexBasis = `${height.current!}px`
	} else if (resizeHorizontal && flexDirection.current === 'horizontal') {
		style.flexBasis = `${width.current!}px`
	}
	return (
		<div ref={ref} className={resizableClassNames} style={style}>
			{props.children}
			{props.enable?.top && (
				<div onPointerDown={(e) => startResize(e, 'top')} className={clsx(classes.sizer, classes.top)} />
			)}
			{props.enable?.right && (
				<div onPointerDown={(e) => startResize(e, 'right')} className={clsx(classes.sizer, classes.right)} />
			)}
			{props.enable?.bottom && (
				<div onPointerDown={(e) => startResize(e, 'bottom')} className={clsx(classes.sizer, classes.bottom)} />
			)}
			{props.enable?.left && (
				<div onPointerDown={(e) => startResize(e, 'left')} className={clsx(classes.sizer, classes.left)} />
			)}
			{props.enable?.corner && (
				<div onPointerDown={(e) => startResize(e, 'corner')} className={clsx(classes.sizer, classes.corner)} />
			)}
		</div>
	)
}

const getHeight = (height: number, fullscreen: boolean | undefined, isAutoHeight: boolean | undefined) => {
	if (fullscreen) {
		return '100%'
	}

	if (isAutoHeight) {
		return undefined
	}

	return `${height}px`
}
