import type { SemanticColors } from '../semanticMapping'
import { cssVar } from '../../theming/themingManager'

export const generateChipColorsFromSemanticPalette = (semanticPalette: SemanticColors) => {
	return {
		colors: {
			background: cssVar(semanticPalette.button, 'disabledBackground'),
			color: cssVar(semanticPalette.button, 'primary'),
			border: cssVar(semanticPalette.button, 'border'),
		},
		hoverColors: {
			background: cssVar(semanticPalette.button, 'hoveredBackground'),
			color: cssVar(semanticPalette.button, 'primary'),
			border: cssVar(semanticPalette.button, 'border'),
		},
		focusedColors: {
			background: cssVar(semanticPalette.button, 'disabledBackground'),
			color: cssVar(semanticPalette.button, 'primary'),
			border: cssVar(semanticPalette.button, 'focusBorder'),
			outline: cssVar(semanticPalette.button, 'background'),
		},
		pressedColors: {
			background: cssVar(semanticPalette.button, 'pressedBackground'),
			color: cssVar(semanticPalette.button, 'primary'),
			border: cssVar(semanticPalette.button, 'pressedBackground'),
		},
		checkedColors: {
			background: cssVar(semanticPalette.button, 'primary'),
			color: cssVar(semanticPalette.button, 'background'),
			border: cssVar(semanticPalette.button, 'primary'),
		},
		checkedHoverColors: {
			background: cssVar(semanticPalette.button, 'primaryHoverBackground'),
			color: cssVar(semanticPalette.button, 'background'),
			border: cssVar(semanticPalette.button, 'primaryHoverBackground'),
		},
		checkedPressedColors: {
			background: cssVar(semanticPalette.button, 'primaryPressedBackground'),
			color: cssVar(semanticPalette.button, 'background'),
			border: cssVar(semanticPalette.button, 'primaryPressedBackground'),
		},
		checkedFocusedColors: {
			background: cssVar(semanticPalette.button, 'primary'),
			color: cssVar(semanticPalette.button, 'background'),
			border: cssVar(semanticPalette.button, 'background'),
		},
		disabledColors: {
			background: cssVar(semanticPalette.button, 'disabledBackground'),
			color: cssVar(semanticPalette.button, 'disabledText'),
			border: undefined,
		},
	}
}
