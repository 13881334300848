import React from 'react'
import type { LocaleUtils } from 'react-day-picker'
import { createStyle } from '../../theming'
import { DatePickerButton } from './DatePickerButton'

export interface IYearPickerProps {
	tabIndex: number
	localeUtils: LocaleUtils
	date: Date
	classNames?: string
	onMonthClick: (day: Date) => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
	locale: string
}

const classes = createStyle({
	months: {
		display: 'flex',
		width: '100%',
		flexWrap: 'wrap',
	},
	month: {
		width: '25%',
		height: 50,
	},
})

export const MonthPicker = (props: IYearPickerProps) => {
	return (
		<div className={classes.months}>
			{[...Array(12).keys()].map((i) => (
				<DatePickerButton
					className={classes.month}
					onClick={() => {
						props.onMonthClick(new Date(props.date.getFullYear(), i))
					}}
					label={props.localeUtils.getMonths(props.locale)[i].slice(0, 3)}
					key={i}
					dataAttributes={i === 0 ? { ['data-defaultmonth']: 'true' } : undefined}
				/>
			))}
		</div>
	)
}
