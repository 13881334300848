import type { ReactElement } from 'react'
import React from 'react'

import { createStyle } from '../../theming'

interface ITableBodyProps {
	children: ReactElement | ReactElement[]
}

const classes = createStyle({
	body: {
		display: 'grid',
		padding: '6px 16px',
		textAlign: 'left',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
})

export const TableBody = (props: ITableBodyProps) => {
	return (
		<div className={classes.body} tabIndex={0}>
			{React.Children.map(props.children, (child: React.ReactElement<any>, i) => {
				if (React.isValidElement(child)) {
					return React.cloneElement(child as React.ReactElement<any>, {
						//@ts-ignore
						//@ts-ignore
						isSelected: props.selectedRowIds?.includes(child.props.rowId),
						index: i,
					})
				}
			})}
		</div>
	)
}
