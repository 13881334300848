import { Reducer } from '@reduxjs/toolkit'
import { IEndpoint } from 'src/interfaces/IEndpoint'
import { IBaseK8sRuntime, IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import {
	IAvailabilityGroup,
	IBufferConfiguration,
	IEnvironmentAvailability,
	IEnvironmentAvailabilitySchedule,
} from 'src/interfaces/IAvailabilityWindow'
import { IClusterEvent } from 'src/interfaces/IClusterEvent'
import { IDeleteAvailabilityGroup, IKubernetesActions } from './IKubernetesActions'
import { kubernetesTypes } from './kubernetesTypes'
import { compareWeekDayTimeForGroup } from 'src/utils/availabilityUtils'
import { generateGuid } from 'src/utils/generateGuid'
import { IEnvironmentResourceOverview } from 'src/interfaces/IEnvironmentResourceOverview'
import { e_EnvironmentOperatingTypeName } from 'src/enums/e_EnvironmentOperatingTypes'
import cloneDeep from 'lodash/cloneDeep'
import { e_LifespanName } from 'src/enums/e_LifespanName'

export interface IKubernetesState {
	k8sRuntimes: IK8sRuntime[]
	k8sRuntimesIncludingOperator: IBaseK8sRuntime[]
	endpoints: IEndpoint[]
	environmentAvailabilitySchedule: IEnvironmentAvailabilitySchedule
	environmentResourceOverview: { [environmentOperatorType: number]: IEnvironmentResourceOverview }
	clusterEvents: IClusterEvent[]
}

const initialState: IKubernetesState = {
	k8sRuntimes: [],
	k8sRuntimesIncludingOperator: [],
	endpoints: [],
	environmentResourceOverview: {},
	environmentAvailabilitySchedule: {
		id: generateGuid(),
		bufferConfiguration: {
			enableAvailabilityWindowBuffering: false,
			enableOperatingRoleSwitchBuffering: false,
			startupBufferInMinutes: 0,
			shutdownBufferInMinutes: 0,
		},
		environmentAvailabilities: [
			{
				environmentOperatingType: 0,
				environmentOperatingTypeName: e_EnvironmentOperatingTypeName.active,
				isAlwaysUp: true,
				availabilityWindows: [],
				availabilityGroups: [],
			},
			{
				environmentOperatingType: 1,
				environmentOperatingTypeName: e_EnvironmentOperatingTypeName.origin,
				isAlwaysUp: true,
				availabilityWindows: [],
				availabilityGroups: [],
			},
			{
				environmentOperatingType: 2,
				environmentOperatingTypeName: e_EnvironmentOperatingTypeName.passive,
				isAlwaysUp: true,
				availabilityWindows: [],
				availabilityGroups: [],
			},
		],
	},
	clusterEvents: [],
}

const mergeEnvironmentAvailabilities = (
	currentEnvironmentAvailabilities: IEnvironmentAvailability[],
	newEnvironmentAvailabilities: IEnvironmentAvailability[]
): IEnvironmentAvailability[] => {
	const mergedArray: IEnvironmentAvailability[] = []
	currentEnvironmentAvailabilities.forEach((currentEnvironmentAvailability) => {
		let newIndex = -1
		newEnvironmentAvailabilities.forEach((newEnvironmentAvailability, index) => {
			if (
				newEnvironmentAvailability.environmentOperatingType === currentEnvironmentAvailability.environmentOperatingType
			) {
				newIndex = index
			}
		})
		if (newIndex >= 0) {
			mergedArray.push(newEnvironmentAvailabilities[newIndex])
		} else {
			mergedArray.push(currentEnvironmentAvailability)
		}
	})
	return mergedArray
}

export const kubernetesReducer: Reducer<IKubernetesState, IKubernetesActions> = (
	state = initialState,
	action: IKubernetesActions
) => {
	switch (action.type) {
		case kubernetesTypes.SET_RUNTIME_CONFIGURATIONS: {
			return {
				...state,
				k8sRuntimes: action.k8sRuntimes,
				k8sRuntimesIncludingOperator: [...action.k8sRuntimes, action.operatorRuntime],
			}
		}
		case kubernetesTypes.SET_ENDPOINTS_CONFIGURATIONS: {
			return {
				...state,
				endpoints: action.endpoints,
			}
		}
		case kubernetesTypes.SET_ALWAYS_UP: {
			return {
				...state,
				environmentAvailabilitySchedule: {
					...state.environmentAvailabilitySchedule,
					bufferConfiguration: state.environmentAvailabilitySchedule.bufferConfiguration,
					environmentAvailabilities: state.environmentAvailabilitySchedule.environmentAvailabilities.map(
						(environmentAvailability) => {
							if (environmentAvailability.environmentOperatingType === action.environmentOperatingType) {
								return {
									...environmentAvailability,
									isAlwaysUp: action.isAlwaysUp,
								}
							} else {
								return { ...environmentAvailability }
							}
						}
					),
				},
			}
		}
		case kubernetesTypes.SET_ENVIRONMENT_RESOURCE_OVERVIEW: {
			return {
				...state,
				environmentResourceOverview: {
					...state.environmentResourceOverview,
					[action.environment]: action.resourceOverview,
				},
			}
		}
		case kubernetesTypes.SET_DEPLOYMENT_AVAILABILITY_SCHEDULE: {
			return {
				...state,
				environmentAvailabilitySchedule: {
					id: action.environmentAvailabilitySchedule.id,
					bufferConfiguration: action.environmentAvailabilitySchedule.bufferConfiguration,
					environmentAvailabilities: mergeEnvironmentAvailabilities(
						state.environmentAvailabilitySchedule.environmentAvailabilities,
						action.environmentAvailabilitySchedule.environmentAvailabilities
					),
				},
			}
		}
		case kubernetesTypes.SET_DEPLOYMENT_AVAILABILITY_SCHEDULE_BUFFER: {
			let newBufferConfiguration: IBufferConfiguration
			if (action.startupBufferConfiguration || action.shutdownBufferConfiguration) {
				newBufferConfiguration = {
					enableAvailabilityWindowBuffering: true,
					enableOperatingRoleSwitchBuffering: true,
					startupBufferInMinutes: -action.startupBufferConfiguration,
					shutdownBufferInMinutes: action.shutdownBufferConfiguration,
				}
			} else {
				newBufferConfiguration = {
					enableAvailabilityWindowBuffering: false,
					enableOperatingRoleSwitchBuffering: false,
					startupBufferInMinutes: -action.startupBufferConfiguration,
					shutdownBufferInMinutes: action.shutdownBufferConfiguration,
				}
			}
			return {
				...state,
				environmentAvailabilitySchedule: {
					...state.environmentAvailabilitySchedule,
					bufferConfiguration: newBufferConfiguration,
				},
			}
		}
		case kubernetesTypes.ADD_OR_UPDATE_AVAILABILITY_GROUP: {
			const addOrUpdateAvailabilityGroup = (availabilityGroups: IAvailabilityGroup[]) => {
				const index = availabilityGroups.findIndex(
					(availabilityGroup) => availabilityGroup.id === action.availabilityGroup.id
				)
				const clonedAvailabilityGroups = cloneDeep(availabilityGroups)
				if (index >= 0) {
					// Update existing group
					clonedAvailabilityGroups[index] = action.availabilityGroup
				} else {
					// New group
					clonedAvailabilityGroups.push(action.availabilityGroup)
				}
				clonedAvailabilityGroups.sort(compareWeekDayTimeForGroup)
				return clonedAvailabilityGroups
			}

			return {
				...state,
				environmentAvailabilitySchedule: {
					...state.environmentAvailabilitySchedule,
					environmentAvailabilities: state.environmentAvailabilitySchedule.environmentAvailabilities.map(
						(environmentAvailability) => {
							if (environmentAvailability.environmentOperatingType === action.environmentOperatingType) {
								return {
									...environmentAvailability,
									availabilityGroups: addOrUpdateAvailabilityGroup(environmentAvailability.availabilityGroups),
								}
							} else {
								return { ...environmentAvailability }
							}
						}
					),
				},
			}
		}
		case kubernetesTypes.DELETE_AVAILABILITY_GROUP: {
			const deleteAvailability = (array: IAvailabilityGroup[], deleteAvailabilityGroup: IDeleteAvailabilityGroup) => {
				const index = array.findIndex((availabilityGroup) => availabilityGroup.id === deleteAvailabilityGroup.id)
				const newArray = array.slice()
				newArray.splice(index, 1)
				return newArray
			}

			return {
				...state,
				environmentAvailabilitySchedule: {
					...state.environmentAvailabilitySchedule,
					bufferConfiguration: state.environmentAvailabilitySchedule.bufferConfiguration,
					environmentAvailabilities: state.environmentAvailabilitySchedule.environmentAvailabilities.map(
						(environmentAvailability) => {
							if (environmentAvailability.environmentOperatingType === action.environmentOperatingType) {
								return {
									...environmentAvailability,
									availabilityGroups: deleteAvailability(environmentAvailability.availabilityGroups, action),
								}
							} else {
								return { ...environmentAvailability }
							}
						}
					),
				},
			}
		}
		case kubernetesTypes.TURN_OFF_ENVIRONMENT: {
			return {
				...state,
				environmentAvailabilitySchedule: {
					...state.environmentAvailabilitySchedule,
					bufferConfiguration: state.environmentAvailabilitySchedule.bufferConfiguration,
					environmentAvailabilities: state.environmentAvailabilitySchedule.environmentAvailabilities.map(
						(environmentAvailability) => {
							if (environmentAvailability.environmentOperatingType === action.environmentOperatingType) {
								return {
									...environmentAvailability,
									availabilityGroups: environmentAvailability.availabilityGroups
										.map((availabilityGroup) => {
											return {
												...availabilityGroup,
												enabled: false,
											}
										})
										.filter((availabilityGroup) => {
											return availabilityGroup.windowType.lifespanName === e_LifespanName.continuous
										}),
								}
							} else {
								return { ...environmentAvailability }
							}
						}
					),
				},
			}
		}
		case kubernetesTypes.SET_CLUSTER_EVENTS: {
			return {
				...state,
				clusterEvents: action.clusterEvents,
			}
		}
		default:
			return state
	}
}
