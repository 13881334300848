import type { DOMConversion, DOMConversionMap, DOMConversionOutput, SerializedTextNode } from 'lexical'
import { $applyNodeReplacement, $isTextNode, TextNode } from 'lexical'
import { defaultFont } from '../plugins'

const styledTextNodes = new Set(['b', 'code', 'em', 'i', 's', 'span', 'strong', 'sub', 'sup', 'u'])

export class CustomTextNode extends TextNode {
	static getType() {
		return 'custom-text'
	}

	static clone(node: CustomTextNode) {
		return new CustomTextNode(node.__text, node.__key)
	}

	static importJSON(serializedNode: SerializedTextNode): TextNode {
		const node = $createCustomTextNode(serializedNode.text)
		node.setFormat(serializedNode.format)
		node.setDetail(serializedNode.detail)
		node.setMode(serializedNode.mode)
		node.setStyle(serializedNode.style)
		return node
	}

	static importDOM(): DOMConversionMap | null {
		const parentReturn = super.importDOM()
		if (!parentReturn) {
			return null
		}
		return Object.entries(parentReturn).reduce((newReturn, [nodeName, fn]) => {
			if (!styledTextNodes.has(nodeName)) {
				newReturn[nodeName] = fn
				return newReturn
			}
			const newConversion = (node: HTMLElement): DOMConversion | null => {
				const r = fn(node)
				if (!r) {
					return null
				}
				const { conversion } = r
				const newConversionFn = (domNode: Node): DOMConversionOutput | null => {
					const typedNode = domNode as HTMLElement
					const r1 = conversion(typedNode)
					const fontFamily = typedNode.style.fontFamily
					const fontSize = typedNode.style.fontSize
					return {
						node: null,
						...r1,
						forChild: (lexicalNode, parentLexicalNode) => {
							if (r1?.forChild) {
								const newNode = r1.forChild(lexicalNode, parentLexicalNode)
								if (newNode) {
									lexicalNode = newNode
								}
							}

							if (fontFamily && $isTextNode(lexicalNode) && fontFamily !== defaultFont) {
								lexicalNode.setStyle(`font-family: ${fontFamily}`)
							}
							if (fontSize && $isTextNode(lexicalNode)) {
								lexicalNode.setStyle(`font-size: ${fontSize}`)
							}
							return lexicalNode
						},
					}
				}

				return {
					conversion: newConversionFn,
					priority: 1 as const,
				}
			}
			newReturn[nodeName] = newConversion
			return newReturn
		}, {} as DOMConversionMap)
	}

	exportJSON() {
		return {
			...super.exportJSON(),
			type: 'custom-text',
			version: 1,
		}
	}
}

export function $createCustomTextNode(text = ''): TextNode {
	return $applyNodeReplacement(new CustomTextNode(text))
}
