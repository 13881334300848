import React from 'react'

import classNames from 'clsx'
import { createStyle } from '../../theming'

import { InputHeight } from '../utils/InputHeight'
import { Link, e_LinkTarget } from '../Link'
import { FormControl, e_LabelContentLayout, type IFormControl, type IFormControlLabel } from '../FormControl'

import { e_LabelPosition } from '../../enums/e_LabelPosition'
import { e_Overflow } from '../../enums/e_Overflow'
import { e_TextAlignment } from '../../enums/e_TextAlignment'

import { useStyleContext } from '../utils/Style.context'
import { useForwardedRef } from '../utils/useForwardedRef'
import { Icon } from '../Icon'
import type { IIconProps } from '../Icon'
import { useIncludeFieldContainerProps } from '../../surfaces/FieldContainer/FieldContainerContext'

const classes = createStyle((theme) => ({
	text: {
		flex: 1,
		display: 'flex',
		whiteSpace: 'pre',
		borderStyle: 'solid',
		borderColor: 'transparent',
		borderWidth: 1,
		// Must be margin for Gecko to calculate baseline correctly
		margin: theme.controls.input.padding,
		lineHeight: 'normal',
	},
	alignRight: { justifyContent: 'flex-end' },
	alignCenter: { justifyContent: 'center' },
	textWrap: {
		whiteSpace: 'pre-wrap',
		wordWrap: 'break-word',
		overflow: 'hidden',
	},
	hideOverflow: {
		overflow: 'hidden',
	},
	textEllipsis: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	textScrollbar: {
		overflowX: 'scroll',
	},
	textBold: {
		fontWeight: 'bold !important',
	},
	textItalic: {
		fontStyle: 'italic !important',
	},
	textStrikethrough: {
		textDecoration: 'line-through !important',
	},
	textUnderline: {
		textDecoration: 'underline !important',
	},
	textUnderlineAndStrikethrough: {
		textDecoration: 'line-through underline !important',
	},
	noLeftMargin: {
		marginLeft: '0px',
		borderLeftWidth: '0px',
	},
	disabled: {
		opacity: 0.4,
	},
	output: {
		WebkitTextStrokeWidth: 0,
	},
	outputDynamicsStyle: { WebkitTextStrokeWidth: '0.4px' },
	placeholderText: {
		color: theme.colors.input.placeholderText,
		fontStyle: theme.controls.input.placeholderTextFontStyle,
	},
	baselineWrapper: {
		display: 'flex',
		alignItems: 'baseline',
	},

	alignIcon: {
		alignSelf: 'center',
		padding: '0px 4px',
	},
}))

interface IContentFormat {
	wrapText?: boolean
	isBold?: boolean
	isItalic?: boolean
	hasUnderline?: boolean
	hasStrikethrough?: boolean
	overflow: e_Overflow
	textAlignment: e_TextAlignment
	textColor?: string
	backgroundColor?: string
}

type ILinkInterpretation = 'internetUrl' | 'email' | 'phone'

type IDisplayFieldContent = string | number | boolean | JSX.Element

interface IContent {
	placeholder?: string
	content?: IDisplayFieldContent
	linkInterpretation?: ILinkInterpretation
	openUrlInNewTab?: boolean
	onActivate?: () => void
	linkClassName?: string
	icon?: IIconProps
}

type IDisplayField = IContent & IContentFormat & IFormControl

const NON_BREAKING_SPACE = String.fromCharCode(160)

export const DisplayField = React.forwardRef<HTMLAnchorElement, IDisplayField>(
	(props: IDisplayField, forwardedRef: React.Ref<HTMLAnchorElement>) => {
		const combinedProps = useIncludeFieldContainerProps(props)

		const { placeholder = NON_BREAKING_SPACE, dynamicsStyle = false } = useStyleContextInput()
		const suppliedContent = useFormatContent(combinedProps, forwardedRef)

		const formControlClassName = classNames(combinedProps.className, classes.baselineWrapper)

		const contentWrapperClassName = classNames(
			compileContentWrapperClassName(combinedProps),
			compileOutputClassNames(combinedProps, dynamicsStyle),
			{ [classes.placeholderText]: !suppliedContent }
		)

		const { dataAttributes, ...formControlProps } = combinedProps

		const contentWrapperStyle = computeContentWrapperStyle(combinedProps)
		const contentDataAttributes = { ...dataAttributes, 'data-autofocus': undefined }

		const contentOrPlaceholder = getContentOrPlaceholder(suppliedContent, combinedProps.placeholder ?? placeholder)

		const iconClassName = classNames(props.icon?.className, classes.alignIcon)

		return (
			<FormControl {...formControlProps} className={formControlClassName}>
				<InputHeight border />
				<span className={contentWrapperClassName} style={contentWrapperStyle} {...contentDataAttributes}>
					{contentOrPlaceholder}
				</span>
				{props.icon && <Icon {...props.icon} className={iconClassName} />}
			</FormControl>
		)
	}
)

DisplayField.displayName = 'DisplayField'

const useStyleContextInput = () => useStyleContext().style?.input ?? {}

function getContentOrPlaceholder(content: string | JSX.Element | undefined, placeholder: string) {
	if (content === undefined) {
		return placeholder
	}

	if (typeof content === 'string' && content.length === 0) {
		return placeholder
	}

	return content
}

const isLinkInterpretation = (value: string | undefined) =>
	value === 'onActivate' || value === 'internetUrl' || value === 'email' || value === 'phone'

const useFormatContent = (props: IContent & IFormControl, forwardedRef: React.Ref<HTMLAnchorElement>) => {
	const linkRef = useForwardedRef<HTMLAnchorElement>(forwardedRef)
	const linkInterpretation = props.onActivate ? 'onActivate' : props.linkInterpretation
	const isLink = isLinkInterpretation(linkInterpretation)
	const isLinkContent = (props.onActivate || (props.content && isLink && !props.disabled)) as boolean

	if (isLinkContent) {
		const target = props.openUrlInNewTab ? e_LinkTarget.blank : e_LinkTarget.self
		const autoFocus = { 'data-autofocus': props.dataAttributes?.['data-autofocus'] === 'true' ? 'true' : 'false' }

		const url = formatURL(linkInterpretation!, props.content as string)

		return (
			<Link
				url={url}
				displayName={props.content as string}
				contentLayout={e_LabelContentLayout.text}
				target={target}
				onActivate={props.onActivate}
				ref={props.onActivate ? linkRef : undefined}
				className={props.linkClassName}
				dataAttributes={{ ...autoFocus }}
			/>
		)
	} else if (typeof props.content === 'boolean' || typeof props.content === 'number') {
		return props.content.toString()
	}

	return props.content
}

const formatURL = (value: 'onActivate' | ILinkInterpretation, content: string) => {
	switch (value) {
		case 'onActivate':
			return '#'
		case 'internetUrl':
			return content.startsWith('http://') || content.startsWith('https://') ? content : '//' + content
		case 'email':
			return 'mailto:' + content
		case 'phone':
			return 'tel:' + content
		default:
			return ''
	}
}

const compileOutputClassNames = (props: IFormControlLabel, dynamicsStyle: boolean) => {
	const colorAndStyleClassNames = classNames(classes.text, {
		[classes.output]: props.label !== undefined,
		[classes.outputDynamicsStyle]: props.label !== undefined && dynamicsStyle,
	})

	return classNames(colorAndStyleClassNames)
}

const compileContentWrapperClassName = (props: IDisplayField) => {
	const colorAndStyleClassNames = classNames(classes.text, {
		[classes.textBold]: props.isBold,
		[classes.textItalic]: props.isItalic,
		[classes.textUnderline]: props.hasUnderline && !props.hasStrikethrough,
		[classes.textStrikethrough]: props.hasStrikethrough && !props.hasUnderline,
		[classes.textUnderlineAndStrikethrough]: props.hasUnderline && props.hasStrikethrough,
		[classes.textWrap]: props.wrapText,
		[classes.hideOverflow]: props.overflow === e_Overflow.noScrollbars,
		[classes.textEllipsis]: props.overflow && !props.wrapText && props.overflow === e_Overflow.showEllipsis,
		[classes.textScrollbar]: props.overflow && !props.wrapText && props.overflow === e_Overflow.showScrollbars,
		[classes.noLeftMargin]: props.labelPosition === e_LabelPosition.top || props.label === undefined,
		[classes.disabled]: props.disabled,
		[classes.alignRight]: props.textAlignment === e_TextAlignment.right,
		[classes.alignCenter]: props.textAlignment === e_TextAlignment.center,
	})

	return classNames(colorAndStyleClassNames)
}

const computeContentWrapperStyle = (props: IContentFormat) => ({
	color: props.textColor,
	background: props.backgroundColor,
	textAlign: props.textAlignment,
})
