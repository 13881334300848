import type { ReactPortal } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import type { TableCellNode } from '@lexical/table'
import { $getTableCellNodeFromLexicalNode } from '@lexical/table'
import { $getSelection, $isRangeSelection } from 'lexical'
import { createPortal } from 'react-dom'
import { createStyle } from '../../../../theming'
import { useElementResizeObserver } from '../../../utils/useResizeObserver'
import { TableActionMenuContent } from './TableActionMenuContent'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

type TableCellActionMenuProps = Readonly<{
	contextRef: { current: null | HTMLElement }
	onClose: () => void
	setIsMenuOpen: (isOpen: boolean) => void
	tableCellNode: TableCellNode
	anchorElement: HTMLElement
}>

const classes = createStyle({
	dropdown: {
		zIndex: 10,
		display: 'block',
		position: 'absolute',
		boxShadow:
			'0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
		borderRadius: '8px',
		minHeight: '40px',
		backgroundColor: '#fff',
		'& .item': {
			margin: '0 8px 0 8px',
			padding: '8px',
			color: '#050505',
			cursor: 'pointer',
			lineHeight: '16px',
			fontSize: '15px',
			display: 'flex',
			alignContent: 'center',
			flexDirection: 'row',
			flexShrink: 0,
			justifyContent: 'space-between',
			backgroundColor: '#fff',
			borderRadius: '8px',
			border: 0,
			maxWidth: '250px',
			minWidth: '100px',
		},

		'& .item.fontsize-item, & .item.fontsize-item .text': {
			minWidth: 'unset',
		},
		'& .item .active': {
			display: 'flex',
			width: '20px',
			height: '20px',
			backgroundSize: 'contain',
		},

		'& .item:first-child': {
			marginTop: '8px',
		},

		'& .item:last-child': {
			marginBottom: '8px',
		},

		'& .item:hover': {
			backgroundColor: '#eee',
		},

		'& .item .text': {
			display: 'flex',
			lineHeight: '20px',
			flexGrow: 1,
			minWidth: '150px',
		},

		'& .item .icon': {
			display: 'flex',
			width: '20px',
			height: '20px',
			userSelect: 'none',
			marginRight: '12px',
			lineHeight: '16px',
			backgroundSize: 'contain',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
		},

		'& .divider': {
			width: 'auto',
			backgroundColor: '#eee',
			margin: '4px 8px',
			height: '1px',
		},
	},
})

function TableActionMenu(props: TableCellActionMenuProps) {
	const { setIsMenuOpen, contextRef, anchorElement } = props

	const dropDownRef = useRef<HTMLDivElement | null>(null)
	const repositionDropdown = useCallback(() => {
		const menuButtonElement = contextRef.current
		const dropDownElement = dropDownRef.current

		if (menuButtonElement != null && dropDownElement != null) {
			const menuButtonRect = menuButtonElement.getBoundingClientRect()

			if (
				menuButtonRect.top - anchorElement.getBoundingClientRect().top < 0 ||
				anchorElement.getBoundingClientRect().bottom - menuButtonRect.bottom < 0
			) {
				setIsMenuOpen(false)
			}

			dropDownElement.style.opacity = '1'

			let dropdownLeft = menuButtonRect.right + window.scrollX + 5

			if (dropdownLeft > window.innerWidth - dropDownElement.clientWidth) {
				dropdownLeft = menuButtonRect.left - dropDownElement.clientWidth + window.scrollX - 5
			}
			dropDownElement.style.left = `${dropdownLeft}px`

			let dropdownTop = menuButtonRect.top + window.scrollY

			if (dropdownTop > window.innerHeight - dropDownElement.clientHeight) {
				dropdownTop = menuButtonRect.bottom - dropDownElement.clientHeight + window.scrollY
			}

			dropDownElement.style.top = `${dropdownTop}px`
		}
	}, [anchorElement, contextRef, setIsMenuOpen])

	useEffect(() => {
		repositionDropdown()
	}, [repositionDropdown])

	useElementResizeObserver(
		() => {
			repositionDropdown()
		},
		anchorElement,
		0
	)

	useEffect(() => {
		anchorElement.addEventListener('scroll', repositionDropdown)
		return () => {
			anchorElement.removeEventListener('scroll', repositionDropdown)
		}
	}, [anchorElement, repositionDropdown])

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (
				dropDownRef.current != null &&
				contextRef.current != null &&
				!dropDownRef.current.contains(event.target as Node) &&
				!contextRef.current.contains(event.target as Node)
			) {
				setIsMenuOpen(false)
			}
		}

		window.addEventListener('click', handleClickOutside)

		return () => window.removeEventListener('click', handleClickOutside)
	}, [setIsMenuOpen, contextRef])

	return createPortal(
		<div
			className={classes.dropdown}
			ref={dropDownRef}
			onClick={(e) => {
				e.stopPropagation()
			}}
		>
			<TableActionMenuContent tableCellNode={props.tableCellNode} onClose={props.onClose} />
		</div>,
		document.body
	)
}

interface ITableCellActionMenuContainerProps {
	anchorElem: HTMLElement
}

function TableCellActionMenuContainer(props: ITableCellActionMenuContainerProps): JSX.Element {
	const { anchorElem } = props
	const [editor] = useLexicalComposerContext()

	const menuButtonRef = useRef(null)
	const menuRootRef = useRef(null)
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const [tableCellNode, setTableMenuCellNode] = useState<TableCellNode | null>(null)

	const moveMenu = useCallback(() => {
		const menu = menuButtonRef.current
		const selection = $getSelection()
		const nativeSelection = window.getSelection()
		const activeElement = document.activeElement

		if (selection == null || menu == null) {
			setTableMenuCellNode(null)
			return
		}

		const rootElement = editor.getRootElement()

		if (
			$isRangeSelection(selection) &&
			rootElement !== null &&
			nativeSelection !== null &&
			rootElement.contains(nativeSelection.anchorNode)
		) {
			const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(selection.anchor.getNode())

			if (tableCellNodeFromSelection == null) {
				setTableMenuCellNode(null)
				return
			}

			const tableCellParentNodeDOM = editor.getElementByKey(tableCellNodeFromSelection.getKey())

			if (tableCellParentNodeDOM == null) {
				setTableMenuCellNode(null)
				return
			}

			setTableMenuCellNode(tableCellNodeFromSelection)
		} else if (!activeElement) {
			setTableMenuCellNode(null)
		}
	}, [editor])

	useEffect(() => {
		return editor.registerUpdateListener(() => {
			editor.getEditorState().read(() => {
				moveMenu()
			})
		})
	})

	const changeActionMenuButtonPosition = useCallback(() => {
		const menuButtonDOM = menuButtonRef.current as HTMLButtonElement | null

		if (menuButtonDOM != null && tableCellNode != null) {
			const tableCellNodeDOM = editor.getElementByKey(tableCellNode.getKey())

			if (tableCellNodeDOM != null) {
				const tableCellRect = tableCellNodeDOM.getBoundingClientRect()
				const menuRect = menuButtonDOM.getBoundingClientRect()
				const anchorRect = anchorElem.getBoundingClientRect()

				menuButtonDOM.style.opacity = '1'

				menuButtonDOM.style.left = `${
					tableCellRect.right - menuRect.width - 10 - anchorRect.left + anchorElem.scrollLeft
				}px`

				menuButtonDOM.style.top = `${tableCellRect.top - anchorRect.top + anchorElem.scrollTop + 4}px`
			} else {
				menuButtonDOM.style.opacity = '0'
			}
		}
	}, [menuButtonRef, tableCellNode, editor, anchorElem])

	useEffect(() => {
		changeActionMenuButtonPosition()
	}, [changeActionMenuButtonPosition])

	useElementResizeObserver(
		() => {
			// setIsMenuOpen(false)
			changeActionMenuButtonPosition()
		},
		anchorElem,
		0
	)

	const prevTableCellDOM = useRef(tableCellNode)

	useEffect(() => {
		if (prevTableCellDOM.current !== tableCellNode) {
			setIsMenuOpen(false)
		}

		prevTableCellDOM.current = tableCellNode
	}, [prevTableCellDOM, tableCellNode])

	return (
		<div className="Editor__tableCellActionButtonContainer" ref={menuButtonRef}>
			{tableCellNode != null && (
				<>
					<button
						className="Editor__tableCellActionButton chevron-down"
						onClick={(e) => {
							e.stopPropagation()
							setIsMenuOpen(!isMenuOpen)
						}}
						ref={menuRootRef}
						data-cy="table-cell-action-button"
					>
						<i className="chevron-down" />
					</button>
					{isMenuOpen && (
						<TableActionMenu
							contextRef={menuRootRef}
							setIsMenuOpen={setIsMenuOpen}
							onClose={() => setIsMenuOpen(false)}
							tableCellNode={tableCellNode}
							anchorElement={anchorElem}
						/>
					)}
				</>
			)}
		</div>
	)
}

interface ITableActionMenuProps {
	anchorElem?: HTMLElement
}

export function TableActionMenuPlugin(props: ITableActionMenuProps): ReactPortal {
	const { anchorElem = document.body } = props
	return createPortal(<TableCellActionMenuContainer anchorElem={anchorElem} />, anchorElem)
}
