import React, { useEffect, useState } from 'react'
import { Button, Icon, Text } from '@genusbiz/web-ui/controls'
import { Card } from '@genusbiz/web-ui/surfaces'
import { createStyle } from '@genusbiz/web-ui/theming'
import { IAvailabilityGroup, e_RelativeWeekDay } from 'src/interfaces/IAvailabilityWindow'
import { useDispatch, useSelector } from 'react-redux'
import { defaultIcons } from 'src/consts/defaultIcons'
import { useTranslation } from 'react-i18next'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { e_LifespanName } from 'src/enums/e_LifespanName'
import classNames from 'clsx'
import { convertWeekdayTimeFromOneTimezoneToAnother, formatIntegerTime } from 'src/utils/dateTimeUtils'
import { EditAvailabilityWindowGroup } from './AvailabilityWindowEditor/EditAvailabilityWindowGroup'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { defaultColors } from 'src/consts/defaultColors'
import { kubernetesActions } from 'src/features/Kubernetes/duck/kubernetesActions'
import { userSettingsSelectors } from 'src/features/UserSettings/duck/userSettingsSelectors'
import { isMomentInAvailabilityGroup } from 'src/utils/availabilityUtils'
import moment from 'moment-timezone'

const classes = createStyle({
	top: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	topButtons: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		justifyContent: 'flex-end',
	},
	button: { marginLeft: '4px' },
	availabilityWindow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		height: '69%',
	},
	disabledGroup: {
		filter: 'opacity(0.5)',
	},
	activeGroup: {
		border: '4px solid ' + defaultColors.primary,
	},
	from: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '85px' },
	divider: {
		height: '100%',
		width: '1px',
		borderLeft: '1px solid black',
	},
	to: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '85px' },
	availabilityWindowCard: {
		display: 'flex',
		marginRight: '8px',
		marginBottom: '8px',
		padding: '8px',
		flexDirection: 'column',
		height: '200px',
		justifyContent: 'space-between',
		width: '264px',
	},
	footer: {
		display: 'flex',
		justifyContent: 'center',
	},
	comment: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
})

interface IProps {
	availabilityGroup: IAvailabilityGroup
	selectedEnvironment: e_EnvironmentOperatingType
}
export const AvailabilityWindowGroup = (props: IProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const selectedTimezone = useSelector(userSettingsSelectors.selectSelecedTimezone)

	const [isActive, setIsActive] = useState(false)

	const promptDeleteAvailabilityWindow = () => {
		dispatch(
			modalManagerActions.showConfirmationModal({
				title: t('GENERAL:DELETE'),
				message: `${t('AVAILABILITY_WINDOW:DELETE_AVAILABILITY_WINDOW')}`,
				declineText: t('GENERAL:NO'),
				confirmText: t('GENERAL:YES'),
				onConfirm: () => {
					deleteAvailabilityWindow()
				},
			})
		)
	}
	useEffect(() => {
		const updateIsActive = () => {
			if (isMomentInAvailabilityGroup(moment(), props.availabilityGroup)) {
				setIsActive(true)
			} else {
				setIsActive(false)
			}
		}
		const interval = setInterval(() => {
			updateIsActive()
		}, 10000)
		updateIsActive()
		return () => {
			clearInterval(interval)
		}
	}, [props.availabilityGroup])

	const deleteAvailabilityWindow = () => {
		dispatch(kubernetesActions.deleteAvailabilityGroup(props.selectedEnvironment, props.availabilityGroup.id))
	}

	const windowType = props.availabilityGroup.windowType

	const fromWeekdayTimes = props.availabilityGroup.fromDays.map((fromDay) => {
		return convertWeekdayTimeFromOneTimezoneToAnother(
			fromDay,
			props.availabilityGroup.fromTime,
			props.availabilityGroup.timezone,
			selectedTimezone
		)
	})
	const toWeekdayTime = convertWeekdayTimeFromOneTimezoneToAnother(
		props.availabilityGroup.toDay,
		props.availabilityGroup.toTime,
		props.availabilityGroup.timezone,
		selectedTimezone
	)
	const showToDay =
		(fromWeekdayTimes.length > 1 && toWeekdayTime.weekDayName === e_RelativeWeekDay.nextDay) ||
		(fromWeekdayTimes.length === 1 && fromWeekdayTimes[0].weekDayName !== toWeekdayTime.weekDayName) ||
		false

	return (
		<div data-cy="availability-window-group">
			<Card
				className={classNames(
					classes.availabilityWindowCard,
					!props.availabilityGroup.enabled && classes.disabledGroup,
					props.availabilityGroup.enabled && isActive && classes.activeGroup
				)}
				key={props.availabilityGroup.id}
			>
				<div className={classes.top}>
					{windowType.lifespanName === e_LifespanName.temporary && (
						<Icon
							className={classes.button}
							style={{
								display: 'flex',
								alignItems: 'center',
								padding: '4px',
								color: 'gray',
							}}
							iconName="Basic-Delay"
							screenTip={t('AVAILABILITY_WINDOW:TEMP_WINDOW') + '.\n' + t('AVAILABILITY_WINDOW:TEMP_WINDOW_DESC') + '.'}
						/>
					)}
					<div className={classes.topButtons}>
						{windowType.lifespanName !== e_LifespanName.temporary && (
							<EditAvailabilityWindowGroup
								availabilityGroup={props.availabilityGroup}
								selectedEnvironment={props.selectedEnvironment}
							/>
						)}
						<Button
							className={classes.button}
							variant="inline"
							size="small"
							icon={defaultIcons.delete}
							dataAttributes={{ 'data-cy': 'delete-availability-window-button' }}
							onClick={() => {
								promptDeleteAvailabilityWindow()
							}}
							screenTip={t('GENERAL:DELETE')}
						/>
					</div>
				</div>
				<div className={classes.availabilityWindow}>
					<div className={classes.from}>
						{fromWeekdayTimes.map((fromWeekdayTime) => {
							return (
								<span key={fromWeekdayTime.weekDayName}>
									{t('WEEKDAY:' + fromWeekdayTime.weekDayName.toUpperCase())}
								</span>
							)
						})}
					</div>
					<div className={classes.divider} />
					<div className={classes.to}>
						<span>{formatIntegerTime(fromWeekdayTimes[0].time)}</span>
						<span>-</span>
						{showToDay && toWeekdayTime.weekDayName && (
							<span>{t('WEEKDAY:' + toWeekdayTime.weekDayName.toUpperCase())}</span>
						)}
						<span>{typeof toWeekdayTime.time === 'number' && formatIntegerTime(toWeekdayTime.time)}</span>
					</div>
				</div>
				<div className={classes.footer} title={props.availabilityGroup.comment}>
					<Text className={classes.comment}>{props.availabilityGroup.comment}</Text>
				</div>
			</Card>
		</div>
	)
}
