import type { RefObject } from 'react'
import { useRef, useEffect } from 'react'

export const useObserveVisibilityChange = (
	ref: RefObject<HTMLElement>,
	callbackFn: (intersectionRatio: number) => void,
	shouldObserve = true
) => {
	const intersectionObserver = useRef<IntersectionObserver>()
	const anchorElement = ref.current
	useEffect(() => {
		if (!shouldObserve) {
			return
		}
		const anchorElementVisibilityChanged = (entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				callbackFn(entry.intersectionRatio)
			})
		}

		if (anchorElement) {
			intersectionObserver.current = new IntersectionObserver(anchorElementVisibilityChanged)
			intersectionObserver.current.observe(anchorElement)
		}
		return () => {
			if (intersectionObserver.current) {
				intersectionObserver.current.disconnect()

				intersectionObserver.current = undefined
			}
		}
	}, [anchorElement, callbackFn, shouldObserve])
}
