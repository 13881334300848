import { topWindow } from './topWindow'

const documents: Document[] = [topWindow.document]

const loop = (document: Document) => {
	const windowContainerElements = [
		...document.getElementsByTagName('iframe'),
		...document.getElementsByTagName('object'),
	]
	for (const frameElement of windowContainerElements) {
		if (frameElement.contentWindow) {
			try {
				documents.push(frameElement.contentWindow.document)
			} catch (err) {
				return
			}

			loop(frameElement.contentWindow.document)
		}
	}
}

export const getAllDocuments = () => {
	loop(documents[0])

	return documents
}
