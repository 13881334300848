import type { FocusEvent } from 'react'
import React, { useState, useRef, useLayoutEffect, useEffect, useCallback } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

import { Chip } from '../Chip'
import { DropdownList } from '../Dropdown'
import type { IFormControl } from '../FormControl'
import { FormControl, FormControlButton } from '../FormControl'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useId } from '../hooks/useId'
import { useStyleContext } from '../utils/Style.context'
import { useTranslation } from '../../translation'
import type { DropdownListMenuItem } from '../Dropdown'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'
import { useFilterAndOptions } from './utils/useFilterAndOptions'

const classes = createStyle({
	chipAndInputWrapper: {
		display: 'flex',
		flex: 1,
		paddingLeft: 4,
		alignItems: 'center',
	},
	input: { fontWeight: 'inherit' },
	hideInput: { visibility: 'hidden' },
})

function tryFocus(input: React.RefObject<HTMLElement>, chip?: React.RefObject<HTMLElement>) {
	window.setTimeout(() => {
		if ([document.body, input.current].includes(document.activeElement as HTMLElement)) {
			// Try to focus chip if present, otherwise default to input.
			// If an outside element has focus, chip/input should not take focus.
			;(chip?.current ?? input.current)?.focus()
		}
	}, 0)
}

export interface ILookupInputOption<T> {
	value: T
	label: string
}

export interface IFilterEventOptions {
	triggeredByBlur: boolean
	triggeredByButton: boolean
}

export interface IFilterEvent {
	filterTerm: string
	minLength: number
	filterOptions: IFilterEventOptions
}

interface ILookupInputProps<T> extends IFormControl {
	placeholder?: string
	isLoading?: boolean
	minSearchTermLength?: number
	options?: ILookupInputOption<T>[]
	displayValue?: string
	value?: T | null
	screenTip?: string
	initialFilterTerm?: string
	onChange?: (value: T | null) => void
	onCtrlClick?: (value: T | null | undefined) => void
	onFilter?: (event: IFilterEvent) => void
	onClose?: () => void
	headerLeftItems?: DropdownListMenuItem[]
	headerRightItems?: DropdownListMenuItem[]
	footerLeftItems?: DropdownListMenuItem[]
	footerRightItems?: DropdownListMenuItem[]
	// Fake direct prop "ref" interface that should have been extended by React.forwardRef,
	// but because of proper lacking higher-order type inference generics are lost
	// so typecasting of wrapped component is necessary
	ref?: React.Ref<HTMLInputElement>
	searchAsYouType?: boolean
	noDropdown?: boolean
	actionButtonRef?: React.Ref<HTMLButtonElement>
}

const LookupInputForwardRef = <T,>(props: ILookupInputProps<T>, ref: React.Ref<HTMLInputElement>) => {
	const { minSearchTermLength = 0, searchAsYouType, onFilter, noDropdown } = props

	const id = useId('lookup-input', props.id)
	const { tcvvi } = useTranslation()

	const anchorElementRef = useRef(null)
	const inputRef = useForwardedRef<HTMLInputElement>(ref)
	const chipRef = useRef<HTMLDivElement>(null)
	const focusChipOrInput = useRef(false)

	const isEnterClicked = useRef(false)
	const [isOpen, setIsOpen] = useState(!!props.initialFilterTerm)
	const [focusedOrHovered, setFocusedOrHovered] = useState(false)

	const openDropdown = () => {
		setIsOpen(true)
	}

	const { options, dropdownOptions, statusText, filterTerm, setFilterTerm, updateFilter } = useFilterAndOptions(
		props.initialFilterTerm,
		minSearchTermLength,
		searchAsYouType,
		props.options,
		!!props.isLoading,
		onFilter,
		openDropdown
	)

	const onBlur = useCallback(
		(e: FocusEvent) => {
			const onBlurTriggeredFromSearchButton = e.relatedTarget?.parentNode === e.target.parentNode?.parentNode

			if (filterTerm.length > 0 && !isEnterClicked.current && !onBlurTriggeredFromSearchButton) {
				isEnterClicked.current = false
				setFilterTerm('')
				onFilter?.({
					filterTerm,
					minLength: minSearchTermLength,
					filterOptions: { triggeredByBlur: true, triggeredByButton: false },
				})
			}
		},
		[filterTerm, minSearchTermLength, onFilter, setFilterTerm]
	)

	const closeDropdown = useCallback(() => {
		setIsOpen(false)

		setFilterTerm('')
	}, [setFilterTerm])

	const closeDropdownAndFocus = () => {
		closeDropdown()

		tryFocus(inputRef, chipRef)
	}

	const closeByKey = () => {
		closeDropdownAndFocus()

		props.onClose?.()
	}

	const onChipClick = () => {
		props.onCtrlClick?.(props.value)
	}

	const onChange = (val: T | null) => {
		if (val === props.value) {
			closeDropdownAndFocus()

			return
		}

		// Try to refocus chip/input when new value is set.
		focusChipOrInput.current = true

		closeDropdown()

		props.onChange?.(val)
	}

	const onOptionClick = (value: T | null) => {
		onChange(value)

		props.onClose?.()
	}

	const onChipKeyDown = (e: React.KeyboardEvent) => {
		if (['Delete', 'Backspace'].includes(e.key)) {
			onChange(null)
		}

		if (e.key.length === 1) {
			if (props.searchAsYouType) {
				updateFilter(e.key, { triggeredByBlur: false, triggeredByButton: false })
			} else {
				setFilterTerm(e.key)
				setIsOpen(false)
			}
			tryFocus(inputRef)
		}
	}

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!props.searchAsYouType) {
			if (e.target.value.length) {
				setFilterTerm(e.target.value)
				setIsOpen(false)
			} else {
				closeDropdownAndFocus()
			}
		} else {
			e.target.value.length
				? updateFilter(e.target.value, { triggeredByBlur: false, triggeredByButton: false })
				: closeDropdownAndFocus()
		}
	}

	const onInputKeyUp = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && !props.searchAsYouType) {
			if (noDropdown) {
				onFilter?.({
					filterTerm,
					minLength: minSearchTermLength,
					filterOptions: { triggeredByBlur: false, triggeredByButton: false },
				})
				isEnterClicked.current = true
				setFilterTerm('')
			} else {
				filterTerm.length && updateFilter(filterTerm, { triggeredByBlur: false, triggeredByButton: false })
			}
		}
	}

	useLayoutEffect(() => {
		if (focusChipOrInput.current) {
			tryFocus(inputRef, chipRef)

			focusChipOrInput.current = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value])

	const displayTextCandidate = props.options?.find((val) => val.value === props.value)?.label ?? props.displayValue

	useEffect(() => {
		if (isEnterClicked.current && displayTextCandidate) {
			isEnterClicked.current = false
			setFilterTerm('')
			tryFocus(inputRef, chipRef)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayTextCandidate])

	const handleSearchButtonPress = () => {
		const filterOptions = { triggeredByBlur: false, triggeredByButton: true }
		if (noDropdown) {
			onFilter?.({
				filterTerm,
				minLength: minSearchTermLength,
				filterOptions: filterOptions,
			})
			setFilterTerm('')
		} else {
			isOpen ? closeDropdownAndFocus() : updateFilter(filterTerm, filterOptions)
		}
	}

	const displayText = !filterTerm ? displayTextCandidate : undefined

	const indexFallback = options && options.length > 0 ? 0 : -1
	const selectedOptionIndex = options?.findIndex((opt) => opt.value === props.value)
	const preselectedIndex = selectedOptionIndex !== -1 ? selectedOptionIndex : indexFallback

	const { dynamicsStyle, placeholder, readOnlyIndicatorStyle } = { ...useStyleContext().style?.input }

	const showActionButtons = !props.disabled && !props.readOnly && (!dynamicsStyle || focusedOrHovered)

	const inputClassName = classNames(classes.input, displayText !== undefined && classes.hideInput)

	return (
		<React.Fragment>
			<FormControl
				ref={anchorElementRef}
				id={id}
				label={props.label}
				labelPosition={props.labelPosition}
				labelProps={props.labelProps}
				labelIcon={props.labelIcon}
				labelIconColor={props.labelIconColor}
				labelBold={props.labelBold}
				labelContentLayout={props.labelContentLayout}
				hideLabel={props.hideLabel}
				screenTip={props.screenTip}
				labelSubText={props.labelSubText}
				validationText={props.validationText}
				validationTextPosition={props.validationTextPosition}
				subText={props.subText}
				reserveHelperTextSpace={props.reserveHelperTextSpace}
				disabled={props.disabled}
				readOnly={props.readOnly}
				required={props.required}
				margin={props.margin}
				error={props.error}
				warning={props.warning}
				className={props.className}
				stretch={props.stretch}
				disableBorder={props.disableBorder}
				onFocusedOrHovered={setFocusedOrHovered}
			>
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
				)}

				<div className={classes.chipAndInputWrapper} onClick={() => chipRef.current?.focus()}>
					{displayText !== undefined && (
						<Chip
							label={displayText}
							clickable={!!props.onCtrlClick}
							isLink={!!props.onCtrlClick}
							onClick={props.onCtrlClick ? onChipClick : undefined}
							onKeyDown={onChipKeyDown}
							onDelete={() => onChange(null)}
							ref={chipRef}
							showTextOnly={!showActionButtons}
							disabled={props.disabled}
							tabStop
						/>
					)}
					<input
						value={filterTerm}
						id={id}
						{...props.dataAttributes}
						placeholder={props.placeholder ?? placeholder}
						onChange={onInputChange}
						onBlur={!props.searchAsYouType ? onBlur : undefined}
						onFocus={() => {
							if (isEnterClicked.current) {
								isEnterClicked.current = false
							}
						}}
						className={inputClassName}
						autoComplete="off"
						spellCheck="false"
						aria-expanded={isOpen}
						aria-haspopup="listbox"
						aria-hidden={displayText !== undefined}
						disabled={props.disabled}
						ref={inputRef}
						name={props.name}
						onKeyUp={onInputKeyUp}
					/>
				</div>

				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
				)}

				{showActionButtons && (
					<FormControlButton
						onClick={filterTerm === '' && !noDropdown ? undefined : handleSearchButtonPress}
						iconClassName={isOpen && filterTerm !== '' ? 'Fluent-Clear' : 'Fluent-Search'}
						disabled={props.disabled || props.readOnly}
						showSpinner={props.isLoading}
						screenTip={isOpen && filterTerm !== '' ? tcvvi('GENERAL:CLOSE') : tcvvi('GENERAL:SEARCH')}
						ref={props.actionButtonRef}
					/>
				)}
			</FormControl>
			<DropdownList
				isOpen={isOpen}
				anchorElement={anchorElementRef}
				options={dropdownOptions}
				value={props.value}
				preselectedIndex={preselectedIndex}
				onChange={onOptionClick}
				onClose={closeByKey}
				multiSelect={false}
				isLoadingOptions={props.isLoading}
				statusText={statusText}
				disableSelectWithSpace
				headerLeftItems={props.headerLeftItems}
				headerRightItems={props.headerRightItems}
				footerLeftItems={props.footerLeftItems}
				footerRightItems={props.footerRightItems}
				className={props.className}
			/>
		</React.Fragment>
	)
}

export const LookupInput = React.forwardRef(LookupInputForwardRef) as typeof LookupInputForwardRef
