import React, { useState } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

import type {
	INavigationPaneItem,
	INavigationPaneButton,
	INavigationPaneHeader,
	INavigationPaneGroup,
} from './NavigationPane.types'
import { NavigationPaneItemType } from './NavigationPane.types'
import { Icon } from '../Icon'
import { Divider } from '../Divider'
import { Badge } from '../Badge'
import { useTranslation } from '../../translation'
import { calculateOffset as calculateOffset } from './utils'

const classes = createStyle((theme) => ({
	navBar: {
		display: 'flex',
		flexDirection: 'column',
		flexShrink: 0,
		background: theme.controls.navigationPane.colors.background,
		color: theme.controls.navigationPane.colors.color,
		listStyle: 'none',
		padding: theme.controls.general.navigationPanePadding,
		margin: 0,
	},
	navBarBorderRight: { borderRight: '1px solid ' + theme.controls.navigationPane.colors.border },
	navBarHeaderGroup: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	navBarHeaderGroupRight: { display: 'flex' },
	navBarHeaderGroupLeftAlign: {
		justifyContent: 'flex-start',
	},
	navBarHeaderGroupRightAlign: {
		justifyContent: 'flex-end',
	},
	navBarItemHeader: {
		padding: '6px 8px 6px 16px',
		alignItems: 'center',
		display: 'flex',
		fontWeight: 600,
		'&::before': {
			content: 'none',
		},
	},
	navBarItemHeaderWithoutInset: {
		paddingLeft: '0px',
	},
	navBarItem: {
		position: 'relative',
		padding: '6px 8px 6px 0px',
		alignItems: 'center',
		minHeight: 40,
		display: 'flex',
		cursor: 'pointer',
		outline: 0,
		border: '1px solid transparent',
		background: 'transparent',
		color: theme.controls.navigationPane.colors.color,
		'&:hover': {
			background: theme.controls.navigationPane.hoverColors.background,
			color: theme.controls.navigationPane.hoverColors.color,
		},
		'&:active': {
			background: theme.controls.navigationPane.pressedColors.background,
			color: theme.controls.navigationPane.pressedColors.color,
		},
		'&:focus-visible': {
			border: '1px solid ' + theme.colors.body.focusBorder,
		},
		'&::before': {
			content: 'none',
		},
	},
	navBarItemToggle: { alignSelf: 'flex-start', paddingLeft: 16 },
	navBarItemActive: {
		background: theme.controls.navigationPane.checkedColors.background,
		color: theme.controls.navigationPane.checkedColors.color,
		'&:hover': {
			background: theme.controls.navigationPane.checkedHoverColors.background,
			color: theme.controls.navigationPane.checkedHoverColors.color,
		},
	},
	navBarItemActiveBold: { fontWeight: 600 },
	navBarItemActiveIndicator: {
		margin: 4,
		marginLeft: 8,
		width: 4,
		background: theme.palette.primary.themePrimary,
		alignSelf: 'stretch',
		borderRadius: 2,
	},
	navBarItemSymbol: {
		marginRight: 8,
	},

	navBarGroupSymbol: {
		transform: 'rotate(0deg)',
		transition: 'transform 100ms ease-out',
		margin: '4px',
	},

	navBarGroupSymbolExpanded: {
		transform: 'rotate(90deg)',
	},

	icon: {
		transform: 'rotate(0deg)',
		transition: 'transform 300ms ease',
	},

	iconExpanded: {
		transform: 'rotate(180deg)',
	},

	navBarItemGroup: {
		flexDirection: 'column',
		alignItems: 'stretch',
	},

	navBarItemGroupContents: {
		display: 'flex',
		userSelect: 'none',
		alignItems: 'center',
		fontWeight: 600,
		padding: '6px 8px 6px 0px',
		minHeight: '40px',
	},

	navBarItemGroupChildList: {
		marginLeft: 16,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	navBarItemContents: {
		margin: '0 4px',
		display: 'flex',
		userSelect: 'none',
		alignItems: 'center',
		textAlign: 'start',
	},
}))

interface INavigationPaneProps {
	entries: INavigationPaneItem[]
	onItemClick: (itemId: string, e: React.MouseEvent | React.KeyboardEvent) => void
	onItemContextMenu?: (itemId: string, e: React.MouseEvent) => void
	activeItemId: string | undefined

	showBorderRight?: boolean
	collapsible?: boolean
	pinnable?: boolean
	defaultCollapsed?: boolean
	defaultPinned?: boolean
	onToggleCollapse?: (collapsed: boolean) => void
	onTogglePin?: (pinned: boolean) => void
	onClose?: () => void
	hideActiveItemIndicator?: boolean
	boldActiveItem?: boolean
	className?: string
	separatorClassName?: string
}

export const NavigationPane = React.forwardRef((props: INavigationPaneProps, ref: React.Ref<HTMLButtonElement>) => {
	const { tcvi } = useTranslation()

	const [isCollapsed, setIsCollapsed] = useState(!!props.defaultCollapsed)
	const [isPinned, setIsPinned] = useState(!!props.defaultPinned)

	const toggleCollapse = (collapsed: boolean) => {
		setIsCollapsed(collapsed)
		props.onToggleCollapse?.(collapsed)
	}

	const togglePin = (pinned: boolean) => {
		setIsPinned(pinned)
		props.onTogglePin?.(pinned)
	}

	const [expandedItemIds, setExpandedItemIds] = useState<string[]>([])

	const navBarHeaderGroupClassName = classNames(classes.navBarHeaderGroup, {
		[classes.navBarHeaderGroupLeftAlign]: props.collapsible && !props.pinnable,
		[classes.navBarHeaderGroupRightAlign]: !props.collapsible && props.pinnable,
	})

	const expandItem = (itemId: string) => {
		if (expandedItemIds.includes(itemId)) {
			return
		}

		setExpandedItemIds([...expandedItemIds, itemId])
	}

	const collapseItem = (itemId: string) => {
		if (!expandedItemIds.includes(itemId)) {
			return
		}

		setExpandedItemIds(expandedItemIds.filter((expandedItemId) => expandedItemId !== itemId))
	}

	const toggleItemExpanded = (itemId: string) => {
		if (expandedItemIds.includes(itemId)) {
			collapseItem(itemId)
		} else {
			expandItem(itemId)
		}
	}

	const renderNavigationPaneButton = (item: INavigationPaneButton, index: number) => {
		const badgeValue = item.badgeValue
		return (
			<button
				key={index}
				id={item.id}
				ref={item.id === props.activeItemId ? ref : undefined}
				{...item.dataAttributes}
				className={classNames(
					classes.navBarItem,
					item.id === props.activeItemId && classes.navBarItemActive,
					item.id === props.activeItemId && props.boldActiveItem && classes.navBarItemActiveBold,
					item.className
				)}
				onClick={(e) => {
					props.onItemClick(item.id, e)
				}}
				onKeyDown={(e) => e.key === 'Enter' && props.onItemClick(item.id, e)}
				onContextMenu={(e) => props.onItemContextMenu?.(item.id, e)}
				title={item.screenTip ? item.screenTip : item.title}
				role="link"
				tabIndex={0}
			>
				{!props.hideActiveItemIndicator && item.id === props.activeItemId && (
					<span className={classes.navBarItemActiveIndicator} />
				)}
				<Badge
					value={isCollapsed ? badgeValue : undefined}
					offsetRight={calculateOffset(badgeValue, isCollapsed)}
					offsetTop={isCollapsed ? -8 : 6}
				>
					<span
						className={classes.navBarItemContents}
						style={!props.hideActiveItemIndicator && item.id !== props.activeItemId ? { marginLeft: 20 } : undefined}
					>
						{item.iconClassName && <Icon iconClassName={item.iconClassName} className={classes.navBarItemSymbol} />}
						{!isCollapsed && item.title}
					</span>
					<Badge value={!isCollapsed ? badgeValue : undefined} className={item.badgeClassName} positionInline />
				</Badge>
			</button>
		)
	}

	const renderNavigationPaneHeader = (item: INavigationPaneHeader, index: number) => {
		const divider = index > 0 ? <Divider key={index} /> : undefined

		return isCollapsed ? (
			divider
		) : (
			<div
				key={index}
				className={classNames(
					classes.navBarItemHeader,
					props.hideActiveItemIndicator && classes.navBarItemHeaderWithoutInset
				)}
				title={item.title}
			>
				<span className={classes.navBarItemContents}>
					{item.iconClassName && <Icon iconClassName={item.iconClassName} className={classes.navBarItemSymbol} />}
					{!isCollapsed && item.title}
				</span>
			</div>
		)
	}

	const renderNavigationPaneGroup = (item: INavigationPaneGroup, index: number, level: number) => {
		const badgeValue = item.badgeValue

		const isExpanded = expandedItemIds.includes(item.id)

		return (
			<div
				key={index}
				id={item.id}
				{...item.dataAttributes}
				className={classNames(
					classes.navBarItemGroup,
					item.id === props.activeItemId && classes.navBarItemActive,
					item.id === props.activeItemId && props.boldActiveItem && classes.navBarItemActiveBold,
					item.className
				)}
				title={item.screenTip ? item.screenTip : item.title}
				role="link"
				tabIndex={0}
			>
				<button
					onClick={() => {
						toggleItemExpanded(item.id)
					}}
					onKeyDown={(e) => e.key === 'Enter' && toggleItemExpanded(item.id)}
				>
					<Badge
						value={isCollapsed ? badgeValue : undefined}
						offsetRight={calculateOffset(badgeValue, isCollapsed)}
						offsetTop={isCollapsed ? -8 : 6}
					>
						<span className={classes.navBarItemGroupContents}>
							<Icon
								iconClassName={'Fluent-ChevronRight'}
								size="size12"
								className={classNames(classes.navBarGroupSymbol, isExpanded && classes.navBarGroupSymbolExpanded)}
							/>
							{!isCollapsed && item.title}
						</span>
						<Badge value={!isCollapsed ? badgeValue : undefined} className={item.badgeClassName} positionInline />
					</Badge>
				</button>
				{item.children && item.children.length > 0 && isExpanded && (
					<div className={classNames(classes.navBar, classes.navBarItemGroupChildList)}>
						{item.children.map((childItem, i) => {
							return renderNavigationPaneItem(childItem, i, level + 1)
						})}
					</div>
				)}
			</div>
		)
	}

	const renderNavigationPaneItem = (item: INavigationPaneItem, index: number, level: number) => {
		switch (item.type) {
			case NavigationPaneItemType.button: {
				return renderNavigationPaneButton(item, index)
			}
			case NavigationPaneItemType.header: {
				return renderNavigationPaneHeader(item, index)
			}

			case NavigationPaneItemType.group: {
				return renderNavigationPaneGroup(item, index, level)
			}

			case NavigationPaneItemType.separator:
				return <Divider className={props.separatorClassName} key={index} />
		}
	}

	return (
		<div className={classNames(classes.navBar, props.showBorderRight && classes.navBarBorderRight, props.className)}>
			<div className={navBarHeaderGroupClassName}>
				{props.collapsible && (
					<button
						className={classNames(classes.navBarItem, classes.navBarItemToggle)}
						onClick={() => toggleCollapse(!isCollapsed)}
						// onKeyDown={(e) => e.key === 'Enter' && props.toggleCompactMode?.(!isCompact)}
						title={isCollapsed ? tcvi('GENERAL:EXPAND') : tcvi('GENERAL:COLLAPSE')}
						aria-label={isCollapsed ? tcvi('GENERAL:EXPAND') : tcvi('GENERAL:COLLAPSE')}
						tabIndex={0}
					>
						<span className={classes.navBarItemContents}>
							<Icon
								iconName={'Basic-AngleDoubleRight'}
								className={classNames(classes.navBarItemSymbol, classes.icon, !isCollapsed && classes.iconExpanded)}
							/>
						</span>
					</button>
				)}
				<div className={classes.navBarHeaderGroupRight}>
					{props.pinnable && !isCollapsed && (
						<button
							className={classNames(classes.navBarItem, classes.navBarItemToggle)}
							onClick={() => togglePin(!isPinned)}
							// onKeyDown={(e) => e.key === 'Enter' && seIsPinned(!isPinned)}
							title={!isPinned ? tcvi('GENERAL:PIN') : tcvi('GENERAL:UNPIN')}
							tabIndex={0}
						>
							<span className={classes.navBarItemContents}>
								<Icon iconName={!isPinned ? 'Fluent-Pinned' : 'Fluent-Unpin'} className={classes.navBarItemSymbol} />
							</span>
						</button>
					)}
					{props.onClose && (
						<button
							className={classNames(classes.navBarItem, classes.navBarItemToggle)}
							onClick={() => props.onClose?.()}
							// onKeyDown={(e) => e.key === 'Enter' && props.onClose?.()}
							title={tcvi('GENERAL:CLOSE')}
							tabIndex={0}
						>
							<span className={classes.navBarItemContents}>
								<Icon iconName={'Fluent-ChromeClose'} className={classes.navBarItemSymbol} />
							</span>
						</button>
					)}
				</div>
			</div>

			{props.entries.map((item, i) => {
				return renderNavigationPaneItem(item, i, 0)
			})}
		</div>
	)
})

NavigationPane.displayName = 'NavigationPane'
