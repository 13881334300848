import React from 'react'
import { type IItemInteractions } from './utils/deriveBaseActions'
import { type IEditableDetailComponent } from './types/detailConfigTypes'
import { useEditableDetailModes } from './hooks/useEditableDetailModes'
import { MasterDetailSurface, type IMasterDetailSurfaceProps } from '../MasterDetail/MasterDetailSurface'
import { useForwardedRef } from '../../controls/utils/useForwardedRef'

interface IEditableMasterDetailSurfaceProps extends IMasterDetailSurfaceProps {
	interactions: IItemInteractions
	viewComponent: IEditableDetailComponent
	editComponent: IEditableDetailComponent
	createComponent: IEditableDetailComponent
	componentDeps?: readonly unknown[]
}

export const EditableMasterDetailSurface = (props: IEditableMasterDetailSurfaceProps) => {
	const {
		data,
		interactions,
		viewComponent,
		editComponent,
		createComponent,
		componentDeps = [],
		callbackAPI,
		handleSingleItemAction: propsHandleSingleItemAction,
		handleGeneralAction: propsHandleGeneralAction,
		singleItemActions: propsItemActions = [],
		generalActions: propsGeneralActions = [],
		...masterDetailProps
	} = props

	const apiRef = useForwardedRef(callbackAPI)

	const components = {
		view: viewComponent,
		edit: editComponent,
		create: createComponent,
	}

	const currentModeConfig = useEditableDetailModes(interactions, apiRef, components, componentDeps, props.giveFeedback)
	const { DetailComponent, integrateLocalUserChanges, itemActions, generalActions, lockSelection, formatItemHeader } =
		currentModeConfig

	const itemActionEntries = [...propsItemActions, ...itemActions.entries]
	const generalActionsEntries = [...propsGeneralActions, ...generalActions.entries]

	const handleSingleItemAction = (actionId: string, itemId: string) => {
		propsHandleSingleItemAction?.(actionId, itemId)
		itemActions.handle(actionId, itemId)
	}

	const handleGeneralAction = (actionId: string) => {
		propsHandleGeneralAction?.(actionId)
		generalActions.handle(actionId)
	}

	const editedData = integrateLocalUserChanges(data)

	return (
		<MasterDetailSurface
			{...masterDetailProps}
			data={editedData}
			callbackAPI={apiRef}
			detailComponent={DetailComponent}
			singleItemActions={itemActionEntries}
			generalActions={generalActionsEntries}
			handleSingleItemAction={handleSingleItemAction}
			handleGeneralAction={handleGeneralAction}
			lockSelection={lockSelection}
			itemName={formatItemHeader}
		/>
	)
}
