import type { IKeyboardShortcut } from '../../utils/keyboard/IKeyboardShortcut'
import { getKeyboardShortcutText } from '../../utils/keyboard/getKeyboardShortcutText'

export const getScreenTip = (screenTip?: string, keyboardShortcut?: IKeyboardShortcut) => {
	const screenTipShortcutText = getKeyboardShortcutText(keyboardShortcut)
	const combinedScreenTip = []

	if (screenTip) {
		combinedScreenTip.push(screenTip)
	}
	if (screenTipShortcutText) {
		combinedScreenTip.push(screenTipShortcutText)
	}

	return combinedScreenTip
}
