import React, { useRef } from 'react'
import type { CellData, ITableContext, Value } from '../../Table.types'
import { e_RenderType } from '../../Table.types'
import { Icon } from '../../../Icon'
import { Link } from '../../../Link'
import { e_LabelContentLayout } from '../../../FormControl'
import { CellRendererAvatar, CellRendererCheckbox } from './cellRenderers'
import { CellRendererLink } from './cellRenderers/CellRendererLink'
import type { e_Interpretation } from '../../enums/e_Interpretation'

interface IProps {
	value: string | number | boolean | null | undefined
	nodeId: string
	pushChanges: (value: Value) => void
	cellData: CellData | null | undefined
	cellDataOnRender: Partial<Pick<CellData, 'iconName' | 'avatarLabel' | 'iconColor' | 'readOnly'>> | undefined
	isCheckMarkCell: boolean
	isAvatarCell: boolean
	isDefaultActionCell: boolean
	isEditableCell: boolean
	disabled: boolean
	displayLabel: string
	renderType: e_RenderType
	onLinkClick?: (linkRef: React.RefObject<HTMLElement>) => void
	avatarLabel: string | undefined
	interpretation: e_Interpretation
	context: ITableContext
}
export const ViewerContent = (props: IProps) => {
	const linkRef = useRef<HTMLAnchorElement>(null)
	const { onLinkClick } = props
	if (onLinkClick && props.renderType !== e_RenderType.none) {
		return (
			<Link
				ref={linkRef}
				url={'#'}
				displayName={props.displayLabel || '\xA0'}
				contentLayout={e_LabelContentLayout.text}
				onActivate={() => onLinkClick(linkRef)}
				className="synced-hover-skmqu"
			/>
		)
	}
	if (props.isCheckMarkCell) {
		return props.isEditableCell ? (
			<CellRendererCheckbox
				key={props.nodeId}
				value={(props.value as boolean) || false}
				setValue={props.pushChanges}
				disabled={props.disabled}
				hasError={!!props.cellData?.error}
				newCheckbox={!!props.context.utilizeNewCheckmarks}
			/>
		) : (
			<Icon
				key={props.nodeId}
				iconClassName={props.cellData?.iconName || props.cellData?.value === true ? 'Fluent-CheckMark' : undefined}
			/>
		)
	}
	if (props.isAvatarCell) {
		return (
			<CellRendererAvatar
				value={(props.cellData?.value ?? undefined) as string | undefined}
				label={props.cellData?.avatarLabel ?? props.cellDataOnRender?.avatarLabel ?? props.avatarLabel}
			/>
		)
	}
	if (props.isDefaultActionCell) {
		return (
			<CellRendererLink
				value={(props.cellData?.value ?? undefined) as string | undefined}
				interpretation={props.interpretation}
			/>
		)
	}
	return props.renderType !== e_RenderType.none ? <>{props.displayLabel || '\xA0'}</> : null
}
