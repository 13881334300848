import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	dividerVertical: {
		alignSelf: 'stretch',
		marginLeft: '2px',
		marginRight: '2px',
		border: 'none',
		flexShrink: 0,
		width: '1px',
		background: theme.colors.body.divider,
	},
	dividerHorizontal: {
		alignSelf: 'stretch',
		marginTop: '2px',
		marginBottom: '2px',
		border: 'none',
		flexShrink: 0,
		height: '1px',
		background: theme.colors.body.divider,
	},
}))

interface IDividerProps {
	vertical?: boolean
	className?: string
	style?: React.CSSProperties
}

export const Divider = (props: IDividerProps) => {
	return (
		<div
			className={classNames(props.className, props.vertical ? classes.dividerVertical : classes.dividerHorizontal)}
			style={props.style}
		/>
	)
}
