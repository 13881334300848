import type { Palette } from '../themeProperties/colors/paletteGenerator'
import { hex2Hsl } from '../themeProperties/colors/colorUtils'

export const generateAppBarColorsFromPalette = (palette: Palette) => {
	const { primary, foreground, background } = palette

	// AppBar text should always be 'light', even if background is light/dark
	const primaryLightness = hex2Hsl(primary.themePrimary).l
	const primaryIsDark = hex2Hsl(primary.themePrimary).l < 0.8

	const foregroundLightness = hex2Hsl(foreground.neutralPrimary).l
	const backgroundLightness = hex2Hsl(background.white).l
	const deltaLightnessPrimaryForeground = Math.abs(primaryLightness - foregroundLightness)
	const deltaLightnessPrimaryBackground = Math.abs(primaryLightness - backgroundLightness)
	const primaryTextContrastColor =
		deltaLightnessPrimaryForeground > deltaLightnessPrimaryBackground ? foreground.neutralPrimary : background.white

	return {
		colors: {
			background: primary.themePrimary,

			color: primaryTextContrastColor,
			border: undefined,
		},
		hoverColors: {
			background: primaryIsDark ? primary.themeDarkAlt : primary.themeLighterAlt,
			color: primaryTextContrastColor,
			border: undefined,
		},
		focusedColors: {
			background: primaryIsDark ? primary.themeDarkAlt : primary.themeLighterAlt,
			color: primaryTextContrastColor,
			border: undefined,
		},
		pressedColors: {
			background: primaryIsDark ? primary.themeDark : primary.themeTertiary,
			color: primaryTextContrastColor,
			border: undefined,
		},
		checkedColors: {
			background: primaryIsDark ? primary.themeDark : primary.themeLighterAlt,
			color: primaryTextContrastColor,
			border: undefined,
		},
		checkedHoverColors: {
			background: primaryIsDark ? primary.themeDarker : primary.themeLight,
			color: primaryTextContrastColor,
			border: undefined,
		},
		disabledColors: {
			background: undefined,
			color: undefined,
			border: undefined,
		},
	}
}
