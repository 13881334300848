import { topWindow } from './topWindow'
import { getCurrentAbsolutePosition } from './getCurrentAbsolutePosition'

export class Measurer {
	private node: HTMLElement
	private box: DOMRect

	constructor(node: HTMLElement) {
		this.node = node
		this.box = node.getBoundingClientRect()

		// In case element is inside nested frame (iframe/object)
		// we need to adjust x & y with the frame offset accordingly.
		// Properties .top and .left are readonly properties using .x and .y and thus
		// not need be set
		if (topWindow !== window) {
			const absolutePosition = getCurrentAbsolutePosition(node)
			this.box.x = absolutePosition.x
			this.box.y = absolutePosition.y
		}
	}

	update() {
		this.box = this.node.getBoundingClientRect()
	}

	get width() {
		return this.box.width
	}

	get height() {
		return this.box.height
	}

	get top() {
		return this.box.top
	}

	get right() {
		return this.box.right
	}

	get bottom() {
		return this.box.bottom
	}

	get left() {
		return this.box.left
	}

	get screenSpaceTop() {
		const screenHeight = topWindow.visualViewport?.height || topWindow.document.documentElement.offsetHeight
		return Math.max(0, Math.min(screenHeight, this.top))
	}

	get screenSpaceRight() {
		const screenWidth = topWindow.document.documentElement.offsetWidth
		return Math.max(0, Math.min(screenWidth, screenWidth - this.right))
	}

	get screenSpaceBottom() {
		const screenHeight = topWindow.visualViewport?.height || topWindow.document.documentElement.offsetHeight
		return Math.max(0, Math.min(screenHeight, screenHeight - this.bottom))
	}

	get screenSpaceLeft() {
		const screenWidth = topWindow.document.documentElement.offsetWidth
		return Math.max(0, Math.min(screenWidth, this.left))
	}

	get actualScreenSpaceTop() {
		return this.top
	}

	get actualScreenSpaceRight() {
		const screenWidth = topWindow.document.documentElement.offsetWidth
		return screenWidth - this.right
	}

	get actualScreenSpaceBottom() {
		const screenHeight = topWindow.visualViewport?.height || topWindow.document.documentElement.offsetHeight
		return screenHeight - this.bottom
	}

	get actualScreenSpaceLeft() {
		return this.left
	}

	isVisible() {
		if (
			this.left > 0 &&
			this.left < topWindow.document.documentElement.offsetWidth &&
			this.top > 0 &&
			this.top < topWindow.document.documentElement.offsetHeight
		) {
			return true
		}

		return false
	}

	isCompletelyVisible() {
		if (
			this.left > 0 &&
			this.right < topWindow.document.documentElement.offsetWidth &&
			this.top > 0 &&
			this.bottom < topWindow.document.documentElement.offsetHeight
		) {
			return true
		}

		return false
	}

	distanceBetween(node: HTMLElement) {
		const m = new Measurer(node)
		return {
			x: this.top - m.top,
			y: this.left - m.left,
		}
	}
}
