import type { Moment } from 'moment'
import moment from 'moment'

export const formatDateWithFormatType = (
	value: Moment,
	format: string | undefined,
	tcvi: (t: string) => string,
	type?: 'date' | 'dateTime'
): string | undefined => {
	if (!format) {
		return undefined
	}

	if (!value) {
		return ''
	}

	switch (format) {
		case 'longDate':
			return value.format('LL') // 22. august 2019
		case 'longDateTime':
			return value.format('LL LTS') // 22. august 2019 13:53:01
		case 'longDateTimeWithoutSeconds':
			return value.format('LL LT') // 22. august 2019 13:53
		case 'shortDate':
			return value.format('L') // 22.08.2019
		case 'shortDateWithWeekday':
			return value.format('dddd L') // Tuesday, 29.06.2021
		case 'shortDateWithShortWeekday':
			return value.format('ddd L') // Tue, 29.06.2021
		case 'shortDateTime':
			return value.format('L LTS') // 22.08.2019 13:53:51
		case 'shortDateTimeWithoutSeconds':
			return value.format('L LT') // 22.08.2019 13:53
		case 'relative':
			if (type === 'date') {
				const compare = moment().hours(12)
				value.hours(12)
				if (compare.isSame(value, 'day')) {
					return tcvi('GENERAL:DATE_TODAY')
				}
				return value.from(compare)
			}
			return value.fromNow()
		case 'relativeDate':
			return value.calendar(undefined, {
				sameDay: '[' + tcvi('GENERAL:DATE_TODAY') + ']',
				nextDay: '[' + tcvi('GENERAL:DATE_TOMORROW') + ']',
				nextWeek: 'dddd',
				lastDay: '[' + tcvi('GENERAL:DATE_YESTERDAY') + ']',
				lastWeek: '[' + tcvi('GENERAL:LAST') + '] dddd',
				sameElse: 'L LTS',
			}) // i dag // i morgen
		case 'dayMonth':
			return value.format('DD.MM') //22.08
	}

	return undefined
}

export const formatDateWithFieldFormatter = (
	value: Moment,
	fieldFormatter?: (value: any) => string
): string | undefined => {
	if (!fieldFormatter) {
		return undefined
	}

	return fieldFormatter(value)
}

export function formatDate(
	value: string | Moment,
	format: string,
	tcvi: (t: string) => string,
	fieldFormatter?: (value: any) => string
) {
	const momentDate = moment(value)

	if (!momentDate.isValid()) {
		return value
	}

	if (format) {
		const formattedDate = formatDateWithFormatType(momentDate, format, tcvi)
		if (formattedDate !== undefined) {
			return formattedDate
		}
	}

	if (fieldFormatter) {
		return formatDateWithFieldFormatter(momentDate, fieldFormatter)
	}

	return momentDate.format('L LTS') // shortDateTime
}
