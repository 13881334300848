import React from 'react'
import { Icon } from '../Icon'
import clsx from 'clsx'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	button: {
		background: 'unset',
		color: theme.colors.list.text,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		border: '1px solid transparent',
		outline: 'none',
		height: '28px',
		'&:focus': {
			background: theme.colors.list.hoveredBackground,
		},
		'&:focus-visible': {
			outline: 'none',
			border: '1px solid ' + theme.colors.body.focusBorder,
		},
		'&:hover': {
			background: theme.colors.list.hoveredBackground,
		},
		'&:active': {
			background: theme.colors.list.pressedBackground,
		},
	},
}))

interface IDatePickerButtonProps {
	label?: string
	onClick: () => void
	className?: string
	screenTip?: string
	icon?: string
	isDefault?: boolean

	dataAttributes?: Record<string, string>
}

export const DatePickerButton = (props: IDatePickerButtonProps) => {
	return (
		<button
			// label={props.label}
			onClick={props.onClick}
			className={clsx(classes.button, props.className)}
			title={props.screenTip || props.label}
			data-isdefault={props.isDefault || undefined}
			{...props.dataAttributes}
		>
			{props.icon && <Icon iconClassName={props.icon} />}
			{props.label}
		</button>
	)
}
