import React, { useState } from 'react'
import { Button, Dialog, DurationInput } from '@genusbiz/web-ui/controls'
import { useDispatch } from 'react-redux'
import { kubernetesActions } from 'src/features/Kubernetes/duck/kubernetesActions'
import { generateGuid } from 'src/utils/generateGuid'
import moment from 'moment'
import { getCurrentWeekday, getWeekDayTimeFromDuration } from 'src/utils/dateTimeUtils'
import { useTranslation } from 'react-i18next'
import { e_LifespanName } from 'src/enums/e_LifespanName'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { createStyle } from '@genusbiz/web-ui/theming'

const classes = createStyle({
	modal: {
		padding: '8px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '300px',
		height: '200px',
	},
	header: {},
	buttonGroup: { marginTop: '4px', display: 'flex', justifyContent: 'flex-end' },
	button: { marginLeft: '8px' },
})

interface IProps {
	selectedEnvironment: e_EnvironmentOperatingType
	label: string
}

export const CreateTempAvailabilityGroup = (props: IProps) => {
	const dispatch = useDispatch()

	const { t } = useTranslation()

	const [isOpen, setIsOpen] = useState(false)
	const [duration, setDuration] = useState<undefined | null | number>(undefined) // Duration is in ms
	const selectedEnvironment = props.selectedEnvironment
	const maxDuration = 86400000 // In ms, set to 24 hours
	const onCreateOneTimeAvailabilityWindowClick = () => {
		if (duration && duration <= maxDuration) {
			// props.onStartTempWindow(duration)
			const from = {
				weekDayName: getCurrentWeekday(),
				time: moment.utc().hours() * 100 + moment.utc().minutes(), //now
				timezone: 'UTC',
			}
			const durationInMinutes = duration / 1000 / 60
			const to = getWeekDayTimeFromDuration(from, durationInMinutes)
			dispatch(
				kubernetesActions.addOrUpdateAvailabilityGroup(
					{
						comment: t('AVAILABILITY_WINDOW:TEMP_WINDOW'),
						fromDays: [from.weekDayName],
						fromTime: from.time,
						toDay: to.weekDayName,
						toTime: to.time,
						id: generateGuid(),
						enabled: true,
						windowIds: { [from.weekDayName]: generateGuid() },
						timezone: 'UTC',
						windowType: {
							lifespanName: e_LifespanName.temporary,
							effectiveFromUtc: moment.utc().format(),
							effectiveToUtc: moment.utc().add(duration).format(),
						},
					},
					selectedEnvironment
				)
			)
			setDuration(undefined)
			setIsOpen(false)
		}
	}

	return (
		<>
			<Button
				label={props.label}
				dataAttributes={{ 'data-cy': 'temp-window-start-now-button' }}
				onClick={() => setIsOpen(true)}
			/>

			<Dialog
				isOpen={isOpen}
				variant="small"
				modalClassName={classes.modal}
				title={t('AVAILABILITY_WINDOW:TEMP_WINDOW')}
				buttonDescriptions={[
					{
						title: t('GENERAL:OK'),
						onClick: onCreateOneTimeAvailabilityWindowClick,
						isEnabled: !!duration,
						isDefault: true,
						dataAttributes: { 'data-cy': 'temp-window-save-button' },
					},
					{
						title: t('GENERAL:CANCEL'),
						onClick: () => {
							setIsOpen(false)
							setDuration(undefined)
						},
						isEnabled: true,
						isCancel: true,
						dataAttributes: { 'data-cy': 'temp-window-cancel-button' },
					},
				]}
			>
				<div>
					<DurationInput
						onChange={(value) => {
							setDuration(value)
						}}
						value={duration as number | undefined} // ??? value must be number | undefined, but onChange-value is number | null | undefined
						placeholder={'00:00'}
						label={t('GENERAL:DURATION')}
						dataAttributes={{ 'data-cy': 'temp-window-duration-input' }}
						onKeyDown={(event) => {
							if (event.key === 'Enter') {
								// Cancel the default action, if needed
								event.preventDefault()
								// Trigger the button element with a click
								onCreateOneTimeAvailabilityWindowClick()
							}
						}}
						maxValueTicksMS={maxDuration}
					/>
				</div>
			</Dialog>
		</>
	)
}
