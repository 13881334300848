import type { SerializedTableCellNode } from '@lexical/table'
import { TableCellNode, TableCellHeaderStates } from '@lexical/table'

import type {
	DOMConversionMap,
	DOMConversionOutput,
	DOMExportOutput,
	EditorConfig,
	LexicalEditor,
	LexicalNode,
	NodeKey,
	Spread,
} from 'lexical'
import { $applyNodeReplacement, $isElementNode, $isLineBreakNode } from 'lexical'
import { $createCustomParagraphNode } from './CustomParagraphNode'

type TableCellHeaderState = (typeof TableCellHeaderStates)[keyof typeof TableCellHeaderStates]

type TableCellBorderWidth = { top: number | null; right: number | null; bottom: number | null; left: number | null }

export type SerializedCustomTableCellNode = Spread<
	{ borderWidth: TableCellBorderWidth | null },
	SerializedTableCellNode
>

export const e_BorderWidthPosition = {
	top: 'top',
	right: 'right',
	bottom: 'bottom',
	left: 'left',
} as const

export type e_BorderWidthPosition = (typeof e_BorderWidthPosition)[keyof typeof e_BorderWidthPosition]

export class CustomTableCellNode extends TableCellNode {
	/** @internal */
	__borderWidth: TableCellBorderWidth | null

	static getType() {
		return 'custom-table-cell'
	}

	static clone(node: CustomTableCellNode): CustomTableCellNode {
		const cellNode = new CustomTableCellNode(node.__headerState, node.__colSpan, node.__width, node.__key)
		cellNode.__rowSpan = node.__rowSpan
		cellNode.__backgroundColor = node.__backgroundColor
		cellNode.__borderWidth = node.__borderWidth
		return cellNode
	}

	static importDOM(): DOMConversionMap | null {
		return {
			td: () => ({
				conversion: convertCustomTableCellNodeElement,
				priority: 0,
			}),
			th: () => ({
				conversion: convertCustomTableCellNodeElement,
				priority: 0,
			}),
		}
	}

	static importJSON(serializedNode: SerializedCustomTableCellNode): CustomTableCellNode {
		const colSpan = serializedNode.colSpan || 1
		const rowSpan = serializedNode.rowSpan || 1
		const cellNode = $createCustomTableCellNode(serializedNode.headerState, colSpan, serializedNode.width || undefined)
		cellNode.__rowSpan = rowSpan
		cellNode.__backgroundColor = serializedNode.backgroundColor || null
		cellNode.__borderWidth = serializedNode.borderWidth ?? null
		return cellNode
	}

	constructor(headerState?: TableCellHeaderState, colSpan?: number, width?: number, key?: NodeKey) {
		super(headerState, colSpan, width, key)
		this.__borderWidth = null
	}

	createDOM(config: EditorConfig): HTMLElement {
		const element = super.createDOM(config)

		if (this.__borderWidth !== null) {
			assignBorderWidthToElement(this.__borderWidth, element, e_BorderWidthPosition.top)
			assignBorderWidthToElement(this.__borderWidth, element, e_BorderWidthPosition.right)
			assignBorderWidthToElement(this.__borderWidth, element, e_BorderWidthPosition.bottom)
			assignBorderWidthToElement(this.__borderWidth, element, e_BorderWidthPosition.left)
		}
		const rowSpanHeight =
			this.__rowSpan > 1
				? `${(this.__borderWidth?.top ?? 0) + (this.__borderWidth?.bottom ?? 0) + this.__rowSpan * 30}px`
				: ''
		element.style.height = rowSpanHeight
		return element
	}

	exportDOM(editor: LexicalEditor): DOMExportOutput {
		const { element } = super.exportDOM(editor)

		if (element) {
			const element_ = element as HTMLTableCellElement

			element_.style.removeProperty('background-color')
			if (this.__borderWidth !== null) {
				if (this.__borderWidth.top !== null) {
					element_.style.borderTopWidth = `${this.__borderWidth.top}pt`
				}
				if (this.__borderWidth.right !== null) {
					element_.style.borderRightWidth = `${this.__borderWidth.right}pt`
				}
				if (this.__borderWidth.bottom !== null) {
					element_.style.borderBottomWidth = `${this.__borderWidth.bottom}pt`
				}
				if (this.__borderWidth.left !== null) {
					element_.style.borderLeftWidth = `${this.__borderWidth.left}pt`
				}
			}
			// PBU 24.05.23: Keep this for reference in case we choose to allow generating inline styles through settings
			// const backgroundColor = this.getBackgroundColor()
			// if (backgroundColor !== null) {
			// 	element_.style.backgroundColor = backgroundColor
			// } else if (this.hasHeader()) {
			// }
			// else if (this.hasHeader()) {
			// 	element_.style.backgroundColor = '#f2f3f5'
			// }
		}

		return {
			element,
		}
	}

	exportJSON(): SerializedCustomTableCellNode {
		return {
			...super.exportJSON(),
			type: 'custom-table-cell',
			width: this.getWidth(),
			borderWidth: this.getBorderWidth(),
		}
	}

	updateDOM(prevNode: CustomTableCellNode): boolean {
		return super.updateDOM(prevNode) || prevNode.__borderWidth !== this.__borderWidth
	}

	getBorderWidth() {
		return this.__borderWidth
	}

	setBorderWidth(width: number | null, pos?: e_BorderWidthPosition) {
		if (width !== null && width < 0) {
			throw new Error('TableCellNode.setBorderWidth: width must be greater than or equal to 0')
		}
		if (pos) {
			const writable = this.getWritable()
			if (!writable.__borderWidth) {
				writable.__borderWidth = splitBorderWidth(null)
			}
			writable.__borderWidth = { ...writable.__borderWidth, [pos]: width }
		} else {
			this.getWritable().__borderWidth = width === 1 ? null : splitBorderWidth(width)
		}
	}
}

function splitBorderWidth(width: number | null) {
	return { top: width, right: width, bottom: width, left: width }
}

function getBorderWidthFromString(borderWidthString: string, defaultWidth: number) {
	if (borderWidthString) {
		const parsedWidth = parseFloat(borderWidthString)
		if (Number.isNaN(parsedWidth)) {
			return defaultWidth
		}
		return parsedWidth
	}
	return defaultWidth
}

function addBorderWidth(tableCellNode: CustomTableCellNode, domNode: HTMLTableCellElement) {
	const defaultBorderWidth = domNode.classList.contains('RichTextTheme__tableCell') ? 1 : 0

	const display = domNode.style.display
	domNode.style.display = 'none'
	const parent = domNode.parentElement
	if (!parent) {
		throw new Error('Expected table cell to have parent')
	}
	const sibling = domNode.nextSibling
	document.body.appendChild(domNode)

	tableCellNode.__borderWidth = {
		top: getBorderWidthFromString(
			getComputedStyle(domNode).borderTopWidth || domNode.style.borderTopWidth,
			defaultBorderWidth
		),
		right: getBorderWidthFromString(
			getComputedStyle(domNode).borderRightWidth || domNode.style.borderRightWidth,
			defaultBorderWidth
		),
		bottom: getBorderWidthFromString(
			getComputedStyle(domNode).borderBottomWidth || domNode.style.borderBottomWidth,
			defaultBorderWidth
		),
		left: getBorderWidthFromString(
			getComputedStyle(domNode).borderLeftWidth || domNode.style.borderLeftWidth,
			defaultBorderWidth
		),
	}

	domNode.style.display = display
	if (sibling) {
		parent.insertBefore(domNode, sibling)
	} else {
		parent.appendChild(domNode)
	}
}

export function convertCustomTableCellNodeElement(domNode: Node): DOMConversionOutput {
	const domNode_ = domNode as HTMLTableCellElement
	const nodeName = domNode.nodeName.toLowerCase()

	const tableCellNode = $createCustomTableCellNode(
		nodeName === 'th' ? TableCellHeaderStates.ROW : TableCellHeaderStates.NO_STATUS
	)
	tableCellNode.__colSpan = domNode_.colSpan
	tableCellNode.__rowSpan = domNode_.rowSpan
	addBorderWidth(tableCellNode, domNode_)

	return {
		forChild: (lexicalNode, parentLexicalNode) => {
			if ($isCustomTableCellNode(parentLexicalNode) && !$isElementNode(lexicalNode)) {
				const paragraphNode = $createCustomParagraphNode()
				if ($isLineBreakNode(lexicalNode) && lexicalNode.getTextContent() === '\n') {
					return null
				}
				paragraphNode.append(lexicalNode)
				return paragraphNode
			}

			return lexicalNode
		},
		node: tableCellNode,
	}
}

export function $createCustomTableCellNode(
	headerState: TableCellHeaderState,
	colSpan = 1,
	width?: number
): CustomTableCellNode {
	return $applyNodeReplacement(new CustomTableCellNode(headerState, colSpan, width))
}

export function $isCustomTableCellNode(node: LexicalNode | null | undefined): node is CustomTableCellNode {
	return node instanceof CustomTableCellNode
}

const positionToCapitalizedPosition = {
	top: 'Top',
	right: 'Right',
	bottom: 'Bottom',
	left: 'Left',
} as const

function assignBorderWidthToElement(
	borderWidth: TableCellBorderWidth,
	element: HTMLElement,
	position: e_BorderWidthPosition
) {
	const bw = borderWidth[position]
	if (bw !== null) {
		const capitalizedPosition = positionToCapitalizedPosition[position]
		if (borderWidth[position] === 0) {
			element.style[`border${capitalizedPosition}`] = 'none'
		} else {
			element.style[`border${capitalizedPosition}Width`] = `${bw}pt`
		}
	}
}
