import type {
	IBaseActions,
	IDetailComponent,
	EditableDetailMode,
	IEditableDetailComponent,
	IEditableItem,
} from '../types/detailConfigTypes'
import { constructGeneralActionConfig, constructItemActionConfigs } from '../utils/constructItemActionConfig'
import { constructDetailSurfaceDataLocalChangesUpdaters as constructLocalChangesDataIntegrator } from '../utils/formatDetailRows'
import { useMemo, useState } from 'react'
import { type IItemInteractions, deriveBaseActions as deriveDetailEditorActions } from '../utils/deriveBaseActions'
import { useEditableItem } from './useEditableItem'
import { addUpdateChangesToComponents } from '../utils/presetComponentProps'
import { constructEditableItemHeaderFormatters } from '../utils/itemHeaderFormating'
import type { IMasterDetailCallbackAPI } from '../../MasterDetail/types/IMasterDetailCallbackAPI'

const getEditableDetailModeConfig = (
	mode: EditableDetailMode,
	components: { [mode in EditableDetailMode]: IDetailComponent },
	state: { editItem: IEditableItem | undefined },
	baseActions: IBaseActions,
	sendFeedback?: (message: string) => void
) => {
	const { viewModeItemActions, editModeItemActions, createModeItemActions } = constructItemActionConfigs(baseActions)
	const { viewModeGeneralActions, editModeGeneralActions, createModeGeneralActions } = constructGeneralActionConfig(
		baseActions,
		sendFeedback
	)
	const { prefixNothing, prefixEdit, prefixDraft } = constructEditableItemHeaderFormatters(state.editItem?.id)
	const { leaveUnchanged, mergeChanges, appendDraft } = constructLocalChangesDataIntegrator(state.editItem)

	switch (mode) {
		case 'view':
			return {
				component: components.view,
				itemActions: viewModeItemActions,
				generalActions: viewModeGeneralActions,
				lockSelection: false,
				integrateLocalUserChanges: leaveUnchanged,
				formatItemHeader: prefixNothing,
			}
		case 'edit':
			return {
				component: components.edit,
				itemActions: editModeItemActions,
				generalActions: editModeGeneralActions,
				lockSelection: true,
				integrateLocalUserChanges: mergeChanges,
				formatItemHeader: prefixEdit,
			}
		case 'create':
			return {
				component: components.create,
				itemActions: createModeItemActions,
				generalActions: createModeGeneralActions,
				lockSelection: true,
				integrateLocalUserChanges: appendDraft,
				formatItemHeader: prefixDraft,
			}
	}
}

export const useEditableDetailModes = (
	itemInteractions: IItemInteractions,
	api: React.MutableRefObject<IMasterDetailCallbackAPI | undefined>,
	editableDetailComponents: { [mode in EditableDetailMode]: IEditableDetailComponent },
	componentDeps: readonly unknown[],
	sendFeedback?: (message: string) => void
) => {
	const itemEditor = useEditableItem<IEditableItem>()
	const [detailMode, setDetailMode] = useState<EditableDetailMode>('view')

	const systemInteractions = { api, itemEditor, setDetailMode, ...itemInteractions }
	const baseActions = deriveDetailEditorActions(systemInteractions)
	const detailState = { editItem: itemEditor.item }
	const components = addUpdateChangesToComponents(editableDetailComponents, baseActions.updateLocalChanges)

	const { component, ...rest } = getEditableDetailModeConfig(
		detailMode,
		components,
		detailState,
		baseActions,
		sendFeedback
	)

	// Avoid redefining component to keep it stateful
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const DetailComponent = useMemo(() => component, [detailMode, ...componentDeps])

	return {
		DetailComponent,
		...rest,
	}
}
