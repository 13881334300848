import clsx from 'clsx'
import { createStyle } from '../../theming'
import { Icon } from '../Icon'
import React from 'react'

interface IExpandIconProps {
	isExpanded: boolean
	toggleExpanded?: (expanded: boolean) => void
	className?: string
}

const classes = createStyle({
	icon: {
		transform: 'rotate(0deg)',
		transition: 'transform 100ms ease-out',
	},

	iconExpanded: {
		transform: 'rotate(90deg)',
	},

	expandButton: {
		background: 'transparent',
		font: 'inherit',
		border: 'none',
		padding: '2px',
		display: 'flex',
	},
})

export const ExpandIcon = (props: IExpandIconProps) => {
	const renderIcon = () => {
		return (
			<Icon
				className={clsx(
					classes.icon,
					props.isExpanded && classes.iconExpanded,
					!props.toggleExpanded && props.className
				)}
				iconName={'Fluent-ChevronRight'}
				size="small"
			/>
		)
	}

	if (props.toggleExpanded) {
		return (
			<button
				className={clsx(classes.expandButton, props.className)}
				onClick={() => props.toggleExpanded!(!props.isExpanded)}
			>
				{renderIcon()}
			</button>
		)
	}

	return renderIcon()
}
