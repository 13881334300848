import { useLayoutEffect } from 'react'

let modalRootSelector: string | undefined

export const setModalRootSelector = (selector: string | undefined) => {
	modalRootSelector = selector
}

/**
 * Reapply styling when react portal domNode changes
 * @returns the current modal root selector
 */
export const useModalRootSelector = (domDialog: React.RefObject<HTMLDivElement>) => {
	const currentStyle = domDialog?.current?.style

	useLayoutEffect(() => {
		if (!domDialog.current || !currentStyle) {
			return
		}

		const style = domDialog.current.style

		style.width = currentStyle.width
		style.height = currentStyle.height

		style.setProperty('--width', currentStyle.width)
		style.setProperty('--height', currentStyle.height)

		style.left = currentStyle.left
		style.top = currentStyle.top

		if (currentStyle.transform === '') {
			style.setProperty('--left', currentStyle.getPropertyValue('--left'))
			style.setProperty('--top', currentStyle.getPropertyValue('--top'))
		} else {
			style.transform = currentStyle.transform
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalRootSelector])

	return modalRootSelector
}
