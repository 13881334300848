import React, { useEffect, useMemo, useState } from 'react'

// Components
import { CellIcon } from './CellIcon'

// Web UI
import { createStyle } from '../../../../theming'
import { FormControlButton } from '../../../FormControl'

// Utils
import { tDataToRowData } from '../../utils'

// Hooks
import { useCellRendererEventListeners } from './useCellRendererEventListeners'
import { useCellStyles } from './useCellStyles'

// Enums and Interfaces
import type { CellData, ICellRendererProps, ITableContext, TData } from '../../Table.types'
import { e_RenderType } from '../../Table.types'
import type { CustomCellRendererProps } from '@ag-grid-community/react'
import { getEarlyReturn, getOnLinkClick } from './utils'
import { getRowNodeId } from './getRowNodeId'
import { CellSelectionCheckMark } from '../CellCheckMark'
import { Viewer } from './Viewer'
import { useCellFlags, usePushChanges } from './hooks'
import { IconButtonMenu } from '../../../Menu'
import { e_Placement } from '../../../../enums/e_Placement'
import { useContextMenuItems } from '../../hooks/useContextMenuItems'

const classes = createStyle({
	gridCellNoRightPadding: { paddingRight: `0px !important` },
	formControlButton: { alignSelf: 'stretch', color: 'inherit' },
})

export const CellRenderer = (props: ICellRendererProps & CustomCellRendererProps<TData, CellData, ITableContext>) => {
	const cellData = props.value
	const { renderType, node, fillVariant, textAlignment = 'left' } = props

	const isEditing = !!props.api
		.getEditingCells()
		.find((cell) => cell.rowIndex === node.rowIndex && cell.column.getColId() === props.column?.getColId())

	const cellDataOnRender = useMemo(() => {
		return props.getCellDataOnRender?.(props.node.id, props.column?.getId())
	}, [props])

	const nodeId = getRowNodeId(node)

	const disabled = cellData?.disabled ?? false

	const [value, setValue] = useState(cellData?.value)
	const displayLabel = ![e_RenderType.dropdown, e_RenderType.lookup].includes(renderType)
		? props.valueFormatted ?? (props.formatCellValue?.(value) ?? value ?? '')?.toString()
		: props.valueFormatted ?? ''

	const onLinkClick = getOnLinkClick(props.node.data, props.createOnClick?.(props.node.data), props.colDef)

	const [isButtonContextMenuOpen, setIsButtonContextMenuOpen] = useState(false)

	const {
		isAvatarCell,
		isDefaultActionCell,
		isSummaryCell,
		isEditableCell,
		isCheckMarkCell,
		isRenderingPillOrCircle,
		isMultiSelectColumn,
		isPlainTextCell,
		isLinkCell,
		isFirstColumnWithGrouping,
		iconPlacement,
		showContextMenu,
		editActionIconName,
		hoverOrFocus,
		isFirstColumnWithFileDrag,
		isGroupColumnCell,
	} = useCellFlags(props, cellDataOnRender, value, isEditing, displayLabel, onLinkClick, isButtonContextMenuOpen)

	const { contextMenuItems, onOpenContextMenuWithButton } = useContextMenuItems(props.getContextMenuItems)

	useEffect(() => {
		if ([e_RenderType.checkMark, e_RenderType.lookup].includes(renderType) || isDefaultActionCell || !isEditing) {
			setValue(cellData?.value)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cellData?.value])

	const pushChanges = usePushChanges(props, setValue)

	useCellRendererEventListeners(
		nodeId,
		node,
		cellData,
		value,
		isEditing,
		isEditableCell,
		props,
		pushChanges,
		props.context
	)

	useCellStyles(
		props.eGridCell,
		isMultiSelectColumn,
		isEditing,
		isEditableCell,
		showContextMenu,
		isPlainTextCell,
		isLinkCell,
		isFirstColumnWithGrouping,
		isFirstColumnWithFileDrag,
		isGroupColumnCell,
		textAlignment
	)

	useEffect(() => {
		if (isFirstColumnWithFileDrag) {
			const onStartDrag = (e: DragEvent) => node.data && props.context.onFileDragStart?.(e, node.data)

			props.eGridCell.setAttribute('draggable', 'true')
			props.eGridCell.addEventListener('dragstart', onStartDrag)

			return () => {
				props.eGridCell.setAttribute('draggable', 'false')
				props.eGridCell.removeEventListener('dragstart', onStartDrag)
			}
		}
	}, [isFirstColumnWithFileDrag])

	if (props.onRenderCell) {
		const rowData = tDataToRowData(node.data)
		if (rowData) {
			return props.onRenderCell(rowData, props.column!.getId())
		}
	}

	if (isPlainTextCell && !isLinkCell) {
		props.eGridCell.classList.remove(classes.gridCellNoRightPadding)

		return getEarlyReturn(
			renderType,
			isCheckMarkCell,
			isSummaryCell,
			cellData?.value,
			props.formatCellValue,
			displayLabel
		)
	}

	if ((editActionIconName && hoverOrFocus) || showContextMenu) {
		props.eGridCell.classList.add(classes.gridCellNoRightPadding)
	} else {
		props.eGridCell.classList.remove(classes.gridCellNoRightPadding)
	}

	const colId = props.column?.getId()
	const possiblyUndefinedNodeId = node.id

	return (
		<>
			{isFirstColumnWithFileDrag && (
				<CellIcon
					nodeId={nodeId}
					iconColor={cellData?.iconColor ?? cellDataOnRender?.iconColor ?? props.iconColor}
					iconName={'Fluent-GripperDotsVertical'}
					renderType={renderType}
					position="before"
					dataAttributes={{ 'data-cy': 'file-drag-handle' }}
				/>
			)}
			{isMultiSelectColumn && (
				<CellSelectionCheckMark
					api={props.api}
					node={props.node}
					disabled={disabled}
					type={props.context.utilizeSquareSelectionCheckmarks ? 'square' : 'circle'}
					newCheckmark={props.context.utilizeNewCheckmarks}
				/>
			)}
			{iconPlacement === 'before' && (
				<CellIcon
					nodeId={nodeId}
					iconColor={cellData?.iconColor ?? cellDataOnRender?.iconColor ?? props.iconColor}
					iconName={cellData?.iconName ?? cellDataOnRender?.iconName ?? props.iconName}
					renderType={renderType}
					position="before"
					onIconClick={onLinkClick}
				/>
			)}
			{!isEditing && (
				<Viewer
					avatarLabel={props.avatarLabel}
					cellClassName={props.cellClassName}
					cellData={cellData}
					cellDataOnRender={cellDataOnRender}
					column={props.column}
					compact={props.compact}
					disabled={disabled}
					displayLabel={displayLabel}
					editingMode={isEditing}
					eGridCell={props.eGridCell}
					fillVariant={fillVariant}
					getCellClassNameOnRender={props.getCellClassNameOnRender}
					getCellStyleOnRender={props.getCellStyleOnRender}
					interpretation={props.interpretation}
					isAvatarCell={isAvatarCell}
					isCheckMarkCell={isCheckMarkCell}
					isDefaultActionCell={isDefaultActionCell}
					isEditableCell={isEditableCell}
					isRenderingPillOrCircle={isRenderingPillOrCircle}
					node={node}
					nodeId={nodeId}
					pillWidth={props.pillWidth}
					renderType={renderType}
					textAlignment={textAlignment}
					value={value}
					onLinkClick={onLinkClick}
					pushChanges={pushChanges}
					tableContext={props.context}
				/>
			)}
			{iconPlacement === 'after' && (
				<CellIcon
					nodeId={nodeId}
					iconColor={cellData?.iconColor ?? cellDataOnRender?.iconColor ?? props.iconColor}
					iconName={cellData?.iconName ?? cellDataOnRender?.iconName ?? props.iconName}
					renderType={renderType}
					position="after"
					onIconClick={onLinkClick}
				/>
			)}
			{showContextMenu && possiblyUndefinedNodeId && colId && (
				<IconButtonMenu
					key={`${nodeId}-contextMenu`}
					iconClassName="Fluent-More"
					screenTip={'More'}
					disabled={disabled}
					menuItems={contextMenuItems}
					menuPlacement={e_Placement.bottomStart}
					withGenericButton
					onOpen={() => {
						onOpenContextMenuWithButton(possiblyUndefinedNodeId, colId)
						setIsButtonContextMenuOpen(true)
					}}
					onClose={() => {
						setIsButtonContextMenuOpen(false)
					}}
				/>
			)}
			{hoverOrFocus && editActionIconName && (
				<FormControlButton
					className={classes.formControlButton}
					iconClassName={editActionIconName}
					onClick={() => {
						props.context.initEditorWithPopper = true
						// Editing will be started by createHandleCellClicked which is registered on the top level of the grid
						// This requires this button to be a descendant of the grid cell
					}}
					iconSize={editActionIconName === 'Fluent-ChevronDown' ? 'extraSmall' : 'small'}
				/>
			)}
		</>
	)
}
