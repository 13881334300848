import { Dialog } from '@genusbiz/web-ui/controls/Dialog'

import PropTypes from 'prop-types'

/**
 * Error modal. Displays an error (title and optional message) and one or more actions
 */
import React from 'react'
import { createStyle } from '@genusbiz/web-ui/theming'

const classes = createStyle({
	dialogRoot: {
		userSelect: 'text',
	},
	dialogContentTextRoot: {
		whiteSpace: 'pre-wrap',
		wordWrap: 'break-word',
	},
	detailsRoot: {
		whiteSpace: 'pre-wrap',
		overflow: 'auto',
	},

	content: {
		display: 'flex',
		alignItems: 'stretch',
		flexDirection: 'column',
	},
	message: {
		whiteSpace: 'pre-line',
	},
	expander: {
		marginTop: '16px',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		alignSelf: 'flex-start',
	},
	expanderText: {
		marginRight: '8px',
	},

	detailContent: {
		marginTop: '8px',
		whiteSpace: 'pre-wrap',
		wordWrap: 'break-word',
		padding: '4px',
		border: '1px solid silver',
		overflowY: 'auto',
		maxHeight: '300px',
	},
})

export const ErrorModal = (props) => {
	// execute action - close modal and execute action's onExecute if defined
	const executeAction = (onExecuteAction) => () => {
		props.hideModal()
		if (onExecuteAction) {
			onExecuteAction()
		}
	}

	return (
		<Dialog
			isOpen
			title={props.title}
			width={500}
			height={'auto'}
			severity={'error'}
			buttonDescriptions={props.actions.map((action) => {
				return {
					title: action.name,
					isEnabled: true,
					isDefault: false,
					isCancel: false,
					onClick: executeAction(action.onExecute),
				}
			})}
			disableCancelOnClickOutside
			disableCancelOnEscape
		>
			<div className={classes.content}>
				<span className={classes.message}>{props.message}</span>
			</div>
		</Dialog>
	)
}

ErrorModal.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string,
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			onExecute: PropTypes.func, // if not defined, this will simply be a "close modal" action
		})
	).isRequired,
	hideModal: PropTypes.func.isRequired,
}
