import React, { useEffect, useRef, useState } from 'react'
import { Button, Link, Text, e_LabelContentLayout } from '@genusbiz/web-ui/controls'
import { IControlLoopStatus, IControlLoopStatusTaskReport } from 'src/interfaces/IControlLoopStatus'
import { Callout } from '@genusbiz/web-ui/surfaces'
import { createStyle } from '@genusbiz/web-ui/theming'
import { useTranslation } from 'react-i18next'
import { e_ControlLoopStatusEventType } from 'src/enums/e_ControlLoopStatusEventType'
import { ControlLoopStatusTaskCard } from './ControlLoopStatusTaskCard'

interface IProps {
	controlLoopStatus: IControlLoopStatus
}
const classes = createStyle({
	header: { marginTop: '0px', marginBottom: '0px' },
	eventCard: { marginTop: '4px', padding: '4px', maxWidth: '500px' },
	eventCardList: { marginTop: '8px', marginBottom: '4px' },
	detailsCard: { marginTop: '12px' },
	calloutButton: {
		marginLeft: '12px',
	},
	infoText: { display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '10px' },
	info: { marginLeft: '25px' },
})

export const ControlLoopStatusCallout = (props: IProps) => {
	const status = props.controlLoopStatus || {}

	const { t } = useTranslation()
	const calloutButton = useRef<HTMLButtonElement>(null)

	const [isCalloutOpen, setIsCalloutOpen] = useState(false)
	const [isDetailsOpen, setIsDetailsOpen] = useState(false)
	const [showErrorIcon, setShowErrorIcon] = useState(false)
	const [showWarningIcon, setShowWarningIcon] = useState(false)
	const [notableTaskReports, setNotableTaskReports] = useState<IControlLoopStatusTaskReport[]>([])
	const [infoTaskReports, setInfoTaskReports] = useState<IControlLoopStatusTaskReport[]>([])

	useEffect(() => {
		setShowErrorIcon(
			!status.successful ||
				status.taskReports.some((report) =>
					report.events.some((event) => {
						return event.type === e_ControlLoopStatusEventType.error
					})
				)
		)
		setShowWarningIcon(
			status.taskReports.some(
				(report) =>
					!report.events.some((event) => event.type === e_ControlLoopStatusEventType.error) &&
					report.events.some((event) => event.type === e_ControlLoopStatusEventType.warning)
			)
		)
		setInfoTaskReports(
			status.taskReports.filter((taskReport) => {
				return taskReport.events.some((event) => {
					if (event.type === e_ControlLoopStatusEventType.error) {
						return true
					}
					return false
				})
			})
		)
		const infoTaskReportsTmp: IControlLoopStatusTaskReport[] = []
		const notableTaskReportsTmp: IControlLoopStatusTaskReport[] = []
		status.taskReports.forEach((taskReport) => {
			if (
				taskReport.events.some((event) => {
					if (
						event.type === e_ControlLoopStatusEventType.warning ||
						event.type === e_ControlLoopStatusEventType.error
					) {
						return true
					}
					return false
				})
			) {
				notableTaskReportsTmp.push(taskReport)
			} else {
				infoTaskReportsTmp.push(taskReport)
			}
		})
		setInfoTaskReports(infoTaskReportsTmp)
		setNotableTaskReports(notableTaskReportsTmp)
	}, [props.controlLoopStatus])

	const notableControlLoopStatusCards = notableTaskReports.map((report) => {
		return <ControlLoopStatusTaskCard key={report.heading} report={report} />
	})
	const infoControlLoopStatusCards = infoTaskReports.map((report) => {
		return <ControlLoopStatusTaskCard key={report.heading} report={report} />
	})
	return (
		<>
			{showErrorIcon && (
				<Button
					icon={'Fluent-Error'}
					className={classes.calloutButton}
					variant="inline"
					size="large"
					ref={calloutButton}
					style={{ color: '#cc3300' }}
					onClick={() => setIsCalloutOpen(!isCalloutOpen)}
				/>
			)}
			{showWarningIcon && (
				<Button
					icon={'Fluent-Warning'}
					className={classes.calloutButton}
					variant="inline"
					size="large"
					ref={calloutButton}
					style={{ color: '#ffcc00' }}
					onClick={() => setIsCalloutOpen(!isCalloutOpen)}
				/>
			)}
			{!showWarningIcon && !showErrorIcon && (
				<Button
					icon={'Fluent-CheckMark'}
					className={classes.calloutButton}
					variant="inline"
					size="large"
					ref={calloutButton}
					style={{ color: 'white' }}
					onClick={() => setIsCalloutOpen(!isCalloutOpen)}
				/>
			)}
			<Callout
				anchorElement={calloutButton}
				isOpen={isCalloutOpen}
				onClose={() => {
					setIsCalloutOpen(false)
					setIsDetailsOpen(false)
				}}
			>
				<Text className={classes.header} variant="h4">
					{status.successful && t('CONTROL_LOOP:CONTROL_LOOP') + ': ' + t('CONTROL_LOOP:SUCCESSFUL')}
					{!status.successful && t('CONTROL_LOOP:CONTROL_LOOP') + ': ' + t('CONTROL_LOOP:ERRORS_DETECTED')}
				</Text>
				<div className={classes.eventCardList}>
					{notableTaskReports.length > 0
						? notableControlLoopStatusCards
						: !isDetailsOpen && <span>{t('CONTROL_LOOP:NO_WARNINGS_OR_ERRORS')}</span>}
				</div>
				<div style={{ marginTop: '8px' }}>
					{!isDetailsOpen && (
						<Link
							onActivate={() => {
								setIsDetailsOpen(!isDetailsOpen)
							}}
							url="#"
							contentLayout={e_LabelContentLayout.text}
							displayName={'Show ' + t('CONTROL_LOOP:DETAILS')}
						/>
					)}
				</div>
				{isDetailsOpen && infoControlLoopStatusCards}
			</Callout>
		</>
	)
}
