import type { ReactElement } from 'react'
import React from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'
import type { ITableMultiselect } from '.'

const classes = createStyle((theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'center',
		height: 40,
		borderBottom: '1px solid ' + theme.colors.list.border,
	},
	headerContent: {
		color: theme.colors.list.text,
		fontWeight: 600,
		userSelect: 'text',
		'&:hover': {
			background: theme.colors.list.hoveredBackground,
		},
		'&:active': {
			background: theme.colors.list.pressedBackground,
		},
	},
}))

interface ITableHeaderProps {
	children: ReactElement | ReactElement[]
	columnSortOnClick?: (colId: string, multiSelect?: ITableMultiselect) => void
}

export const TableHeader = (props: ITableHeaderProps) => {
	return (
		<div className={classes.header}>
			{React.Children.map(props.children, (tableCell) =>
				React.cloneElement(tableCell, {
					columnSortOnClick: props.columnSortOnClick,
					className: classNames(tableCell.props.className, classes.headerContent),
				})
			)}
		</div>
	)
}
