import { createStyle } from '@genusbiz/web-ui/theming'
import React from 'react'
import { e_ReplicaExplanation } from 'src/enums/e_ReplicaExplanation'
import { useTranslation } from 'react-i18next'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'
const classes = createStyle({
	content: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		backgroundColor: 'white',
		//overflowY: 'scroll',
	},
	status: {
		display: 'flex',
		flexDirection: 'column',
		margin: '12px',
	},
	podData: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
	},
	podDetails: {
		display: 'flex',
		flexDirection: 'column',
	},
	resources: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '50px',
	},
	containers: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
	},
	containerStatus: {
		display: 'flex',
		flexDirection: 'column',
		margin: '6px',
	},
	label: {
		width: '105px',
		display: 'inline-block',
	},
	labelAndValue: {
		display: 'flex',
		flexDirection: 'row',
	},
	valueWithDesc: {
		display: 'flex',
		flexDirection: 'column',
	},
	labelResource: {
		width: '85px',
		display: 'inline-block',
	},
	resourceDetails: {
		marginLeft: '20px',
		display: 'flex',
		flexDirection: 'column',
	},
})

interface IWorkloadDetailsProps {
	workloadController: IWorkloadController
}

export const WorkloadControllerDescription = (props: IWorkloadDetailsProps) => {
	const { t } = useTranslation()
	const { workloadController } = props
	return (
		<div className={classes.content}>
			<h4>{t('GENERAL:DESCRIPTION')}</h4>
			<div className={classes.status}>
				<b>{t('WORKLOADS:DEPLOYMENT_DETAILS')}</b>
				<div className={classes.labelAndValue}>
					<label className={classes.label}>{t('TABLE_HEADERS:SCALED_BY')}:</label>
					{(workloadController.replicaExplanation === e_ReplicaExplanation.environmentAvailabilitySchedule ||
						workloadController.replicaExplanation === e_ReplicaExplanation.restoreAfterOperatorRestart ||
						workloadController.replicaExplanation === e_ReplicaExplanation.optimizeRestart ||
						workloadController.replicaExplanation === e_ReplicaExplanation.restart ||
						workloadController.replicaExplanation === e_ReplicaExplanation.resetToDefault) && (
						<div className={classes.valueWithDesc}>
							<span>{t('WORKLOAD_RESOURCE_PLAN:ENVIRONMENT_AVAILABILITY')}</span>
							<span>{t('WORKLOAD_RESOURCE_PLAN:ENVIRONMENT_AVAILABILITY_DESC')}</span>
						</div>
					)}
					{workloadController.replicaExplanation === e_ReplicaExplanation.gServiceEnabling && (
						<div className={classes.valueWithDesc}>
							<span>{t('WORKLOAD_RESOURCE_PLAN:SERVICE_ENABLING')}</span>
							<span>{t('WORKLOAD_RESOURCE_PLAN:SERVICE_ENABLING_DESC')}</span>
						</div>
					)}
					{workloadController.replicaExplanation === e_ReplicaExplanation.workloadsResourcePlan && (
						<div className={classes.valueWithDesc}>
							<span>{t('WORKLOAD_RESOURCE_PLAN:WORKLOAD_RES_PLAN')}</span>
							<span>{t('WORKLOAD_RESOURCE_PLAN:WORKLOAD_RES_PLAN_DESC')}</span>
						</div>
					)}
					{workloadController.replicaExplanation === e_ReplicaExplanation.horizontalPodAudoscaler && (
						<div className={classes.valueWithDesc}>
							<span>{t('WORKLOAD_RESOURCE_PLAN:HORIZONTAL_POD_AUTOSCALER')}</span>
							<span>{t('WORKLOAD_RESOURCE_PLAN:HORIZONTAL_POD_HPA_DESC')}</span>
						</div>
					)}
				</div>

				<b>{t('TABLE_HEADERS:REPLICAS')}</b>
				<div className={classes.labelAndValue}>
					<label className={classes.label}>{t('TABLE_HEADERS:DESIRED')}:</label>
					<span>{props.workloadController.desiredReplicas}</span>
				</div>
				<div className={classes.labelAndValue}>
					<label className={classes.label}>{t('TABLE_HEADERS:READY')}:</label>
					<span>{props.workloadController.readyReplicas}</span>
				</div>
				<div className={classes.labelAndValue}>
					<label className={classes.label}>{t('TABLE_HEADERS:AVAILABLE')}:</label>
					<span>{props.workloadController.availableReplicas}</span>
				</div>
				<div className={classes.labelAndValue}>
					<label className={classes.label}>{t('TABLE_HEADERS:UNAVAILABLE')}:</label>
					<span>{props.workloadController.unavailableReplicas}</span>
				</div>
			</div>
		</div>
	)
}
