import type { IDropdownOption } from '../../Dropdown'
import { DropdownOptionType } from '../../Dropdown'
import { TICKS_PER_MINUTE } from './consts'

export const generateOptionsForDropdown = (
	resolutionMinutes: number,
	minMinutes: number,
	maxMinutes: number,
	formatOption: (durationTicksMS: number | undefined) => string
) => {
	const options = []
	for (let current = minMinutes; current <= maxMinutes; current += resolutionMinutes) {
		const option: IDropdownOption<number> = {
			type: DropdownOptionType.item,
			label: formatOption(current * TICKS_PER_MINUTE),
			value: current * TICKS_PER_MINUTE,
		}
		options.push(option)
	}

	return options
}
