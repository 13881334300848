import React, { useContext } from 'react'

import { translations } from './translations'

type ITranslationContext = {
	tcvi: (t: string) => string
	tcvvi: (t: string, ...values: any[]) => string
} | null

export const TranslationContext = React.createContext<ITranslationContext>(null)
TranslationContext.displayName = 'TranslationContext'

export const TranslationProvider = TranslationContext.Provider

export const useTranslation = () => {
	const translators = useContext(TranslationContext)

	return translators || { tcvi: fallbackTcvi, tcvvi: fallbackTcvvi }
}

// Translate code variant-invariant
const fallbackTcvi = (t: string) => {
	if (t in translations) {
		return translations[t]
	}
	return t
}

// Translate code values variant-invariant
const fallbackTcvvi = (t: string, ...values: any[]) => {
	if (t in translations) {
		let translation = translations[t]
		values.forEach((value, i) => {
			translation = translation.replace('{' + i + '}', String(value))
		})
		return translation
	}
	return t
}
