import { type IDataWithIdentifier } from '../../MasterDetail'
import type { IEditableItem, INamedDataWithIdentifier } from '../types/detailConfigTypes'

const swapInDetailItem = (rowdata: IDataWithIdentifier[], swapItem: INamedDataWithIdentifier) =>
	rowdata.map((row) => (row.id === swapItem.id ? swapItem : row))

const appendDetailItem = (rowdata: IDataWithIdentifier[], editItem: INamedDataWithIdentifier) => [...rowdata, editItem]

export const constructDetailSurfaceDataLocalChangesUpdaters = (item: IEditableItem = { id: '' }) => {
	const leaveUnchanged = (rowdata: IEditableItem[]) => rowdata
	const mergeChanges = (rowdata: IEditableItem[]) => swapInDetailItem(rowdata, item)
	const appendDraft = (rowdata: IEditableItem[]) => appendDetailItem(rowdata, item)

	return { leaveUnchanged, mergeChanges, appendDraft }
}
