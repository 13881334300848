import React, { useState } from 'react'
import { Button } from '../../../Button'
import { Icon } from '../../../Icon'
import type { LexicalEditor } from 'lexical'
import { $getNearestNodeFromDOMNode, $getSelection, $setSelection } from 'lexical'
import { $isCodeNode } from '@lexical/code'
import { createStyle } from '../../../../theming'
import { useTimeout } from '../../../utils/useTimeout'
import clsx from 'clsx'

interface IProps {
	editor: LexicalEditor
	getCodeDOMNode: () => HTMLElement | undefined
}
export const buttonClasses = createStyle({
	button: {
		color: 'rgba(0,0,0,0.5)',
		padding: '4px 12px',
		border: '1px solid rgba(0, 0, 0, 0.2)',
		backdropFilter: 'blur(6px)',
		'&:hover': {
			background: 'rgba(234,234,234, 0.8)',
		},
	},
	copyButton: {
		width: 29,
		height: 29,
	},
})

const classes = createStyle({
	container: {
		width: 29,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
})

export const CopyButton = (props: IProps) => {
	const { editor, getCodeDOMNode } = props
	const [copyCompleted, setCopyCompleted] = useState(false)

	const { fn: removeSuccessIcon } = useTimeout(function () {
		setCopyCompleted(false)
	}, 2000)

	async function handleCopy(): Promise<void> {
		const codeDOMNode = getCodeDOMNode()

		if (!codeDOMNode) {
			return
		}

		let content = ''

		editor.update(() => {
			const codeNode = $getNearestNodeFromDOMNode(codeDOMNode)

			if ($isCodeNode(codeNode)) {
				content = codeNode.getTextContent()
			}

			const selection = $getSelection()
			$setSelection(selection)
		})

		try {
			await navigator.clipboard.writeText(content)
			setCopyCompleted(true)
			removeSuccessIcon()
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('Failed to copy: ', err)
		}
	}

	return (
		<div className={classes.container}>
			{copyCompleted ? (
				<Icon iconClassName="Fluent-CheckMark" color="seagreen" />
			) : (
				<Button
					className={clsx(buttonClasses.button, buttonClasses.copyButton)}
					size="extraSmall"
					icon="Basic-ClipboardCopy"
					variant="inline"
					onClick={() => {
						void handleCopy()
					}}
				/>
			)}
		</div>
	)
}
