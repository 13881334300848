import type React from 'react'
import { useCallback } from 'react'

export function useCombineRefs<T>(refA?: React.Ref<T>, refB?: React.Ref<T>) {
	return useCallback(
		(refValue: T) => {
			if (refValue !== undefined) {
				refA && setRef(refA, refValue)
				refB && setRef(refB, refValue)
			}
		},
		[refA, refB]
	)
}

function setRef<T>(ref: React.RefObject<T> | ((instance: T | null) => void) | null | undefined, value: T | null) {
	if (typeof ref === 'function') {
		ref(value)
	} else if (ref) {
		// Cast-hack to be able to manipulate read-only ref-objects (that are set by React on component mount)
		const castedRef = ref as React.MutableRefObject<typeof ref.current>
		castedRef.current = value
	}
}
