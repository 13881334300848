import type { ReactNode } from 'react'
import React, { useRef, useState } from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'

import { Icon } from '../Icon'
import { useMergeStyles } from '../utils/useMergeStyles'
import { Resizable } from '../Resizable'
import { Callout } from '../../surfaces/Callout'
import { e_Placement } from '../../enums/e_Placement'
import { useTranslation } from '../../translation'

const classes = createStyle((theme) => ({
	contentPane: {
		display: 'flex',
		overflow: 'hidden',
		height: '100%',
		background: theme.palette.background.white,
	},

	contentPaneTopBottom: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		background: theme.palette.background.white,
	},

	borderTop: { borderTop: '1px solid ' + theme.colors.body.divider },
	borderRight: { borderRight: '1px solid ' + theme.colors.body.divider },
	borderBottom: { borderBottom: '1px solid ' + theme.colors.body.divider },
	borderLeft: { borderLeft: '1px solid ' + theme.colors.body.divider },
	header: {
		display: 'flex',
		alignItems: 'center',
		height: 40,
		lineHeight: '40px',
		borderBottom: '1px solid ' + theme.colors.panel.border,
		background: theme.colors.panel.background,
	},

	headerIcon: {
		marginLeft: 8,
	},

	calloutHeader: { fontWeight: 600, paddingBottom: '12px' },
	calloutBody: { whiteSpace: 'pre-wrap' },
	headerTitle: {
		display: 'flex',
		fontSize: theme.typography.styles.h5.fontSize,
		fontWeight: theme.typography.styles.h5.fontWeight,
		height: 40,
		margin: '0 8px',
		flexGrow: 1,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	button: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 40,
		lineHeight: '40px',
		width: 42,
		padding: 0,
		outline: 0,
		cursor: 'pointer',
		background: 'transparent',
		color: 'inherit',
		border: '1px solid transparent',
		'&:hover': {
			background: theme.colors.button.hoveredBackground,
		},
		'&:active': {
			background: theme.colors.button.pressedBackground,
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		overflow: 'auto',
	},
	resizer: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	flexColumn: {
		flexDirection: 'column',
	},
}))

interface IContentPaneProps {
	header?: string
	headerIcon?: string
	headerIconColor?: string
	headerButtonIcon?: string

	headerToolTip?: string

	children?: ReactNode | ReactNode[]
	resizable?: boolean

	isTopBottomPositioned?: boolean
	leftResize?: boolean
	width?: number
	minWidth?: number
	maxWidth?: number

	topResize?: boolean
	height?: number
	minHeight?: number
	maxHeight?: number

	isOpen?: boolean
	className?: string
	border?: { top?: boolean; right?: boolean; bottom?: boolean; left?: boolean }
	onHeaderButtonClick?: () => void
	onLearnMoreClick?: () => void
	onClose?: () => void
	onResize?: (width: number, height: number) => void
	onRenderHeader?: () => JSX.Element
}

export const ContentPane = (props: IContentPaneProps) => {
	const { isOpen = true, minWidth = 150, maxWidth = 3000 } = props
	const { tcvi } = useTranslation()

	const helpButton = useRef<HTMLButtonElement>(null)

	const [toolTipOpen, setToolTipOpen] = useState(false)

	const style = useMergeStyles(
		{
			width: !props.resizable ? props.width : undefined,
			height: !props.resizable ? props.height : undefined,
		},
		[props.width, props.height]
	)

	const contentPaneClassNames = classNames(
		{
			[classes.contentPane]: !props.isTopBottomPositioned,
			[classes.contentPaneTopBottom]: props.isTopBottomPositioned,
			[classes.borderTop]: props.border?.top,
			[classes.borderRight]: props.border?.right,
			[classes.borderBottom]: props.border?.bottom,
			[classes.borderLeft]: props.border?.left,
			[classes.flexColumn]: !props.resizable,
		},
		props.className
	)

	const resizeEnabling = !props.isTopBottomPositioned
		? { left: props.leftResize, right: !props.leftResize }
		: { top: props.topResize, bottom: !props.topResize }

	const defaultSize = !props.isTopBottomPositioned ? { width: props.width } : { height: props.height }

	const minWidthNot0 = minWidth ? { width: minWidth, height: 0 } : undefined
	const minHeightNot0 = props.minHeight ? { width: 0, height: props.minHeight } : undefined
	const minSize = !props.isTopBottomPositioned ? minWidthNot0 : minHeightNot0

	const maxWidthNot0 = maxWidth ? { width: maxWidth, height: 0 } : undefined
	const maxHeightNot0 = props.maxHeight ? { width: 0, height: props.maxHeight } : undefined
	const maxSize = !props.isTopBottomPositioned ? maxWidthNot0 : maxHeightNot0

	const hasHeader = props.onRenderHeader ?? props.header

	return isOpen ? (
		<>
			<Callout
				isOpen={toolTipOpen}
				anchorElement={helpButton}
				placement={e_Placement.bottomEnd}
				beakWidth={15}
				maxWidth={300}
				onClose={() => setToolTipOpen(false)}
			>
				<>
					<div className={classes.calloutHeader}>{props.header}</div>
					<div className={classes.calloutBody}>{props.headerToolTip}</div>
				</>
			</Callout>

			{props.resizable ? (
				<div className={contentPaneClassNames} style={style}>
					<Resizable
						enable={resizeEnabling}
						defaultSize={defaultSize}
						minSize={minSize}
						maxSize={maxSize}
						className={classes.resizer}
						suppressPositioning
						onResize={props.onResize}
						isAutoHeight={props.isTopBottomPositioned ? undefined : true}
					>
						{hasHeader && (
							<div className={classes.header}>
								{props.onRenderHeader?.() ?? (
									<>
										{props.headerIcon && (
											<Icon
												iconClassName={props.headerIcon}
												color={props.headerIconColor}
												className={classes.headerIcon}
											/>
										)}
										<div className={classes.headerTitle} title={props.header}>
											{props.header}
											{props.headerToolTip && (
												<button
													className={classes.button}
													style={{ marginLeft: 8 }}
													title={tcvi('GENERAL:LEARN_MORE')}
													ref={helpButton}
													onClick={() => setToolTipOpen(!toolTipOpen)}
												>
													<Icon iconName="Fluent-Info" />
												</button>
											)}
										</div>

										{props.headerButtonIcon && (
											<button className={classes.button} onClick={props.onHeaderButtonClick}>
												<Icon iconClassName={props.headerButtonIcon} />
											</button>
										)}

										{props.onClose && (
											<button className={classes.button} onClick={props.onClose}>
												<Icon iconName="Fluent-ChromeClose" />
											</button>
										)}
									</>
								)}
							</div>
						)}

						<div className={classes.content}>{props.children}</div>
					</Resizable>
				</div>
			) : (
				<div className={contentPaneClassNames} style={style}>
					{hasHeader && (
						<div className={classes.header}>
							{props.onRenderHeader?.() ?? (
								<>
									{props.headerIcon && (
										<Icon
											iconClassName={props.headerIcon}
											color={props.headerIconColor}
											className={classes.headerIcon}
										/>
									)}
									<div className={classes.headerTitle} title={props.header}>
										{props.header}
									</div>
									{props.headerButtonIcon && (
										<button className={classes.button} onClick={props.onHeaderButtonClick}>
											<Icon iconClassName={props.headerButtonIcon} />
										</button>
									)}
									{props.onLearnMoreClick && (
										<button className={classes.button} onClick={props.onLearnMoreClick}>
											<Icon iconName="Fluent-Info" />
										</button>
									)}
									{props.onClose && (
										<button className={classes.button} onClick={props.onClose}>
											<Icon iconName="Fluent-ChromeClose" />
										</button>
									)}
								</>
							)}
						</div>
					)}

					<div className={classes.content}>{props.children}</div>
				</div>
			)}
		</>
	) : null
}
