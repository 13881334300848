import { type IEditableItem } from '../types/detailConfigTypes'

const getItemName = (item: IEditableItem) => (item['name'] as string) ?? 'Unknown'

const formatEditableItemHeader = (item: IEditableItem, editItemId: string, prefix?: string) => {
	const name = getItemName(item)
	const isEditItem = prefix && item.id === editItemId

	return isEditItem ? `${prefix} ${name}` : name
}

export const constructEditableItemHeaderFormatters = (id: string = '') => {
	const prefixNothing = (item: IEditableItem) => formatEditableItemHeader(item, id)
	const prefixEdit = (item: IEditableItem) => formatEditableItemHeader(item, id, '(Edit)')
	const prefixDraft = (item: IEditableItem) => formatEditableItemHeader(item, id, '(Draft)')

	return { prefixNothing, prefixEdit, prefixDraft }
}
