export enum e_ReplicaExplanation {
	undefined = 0,
	environmentAvailabilitySchedule = 1,
	gServiceEnabling = 2,
	workloadsResourcePlan = 3,
	restart = 4,
	optimizeRestart = 5,
	restoreAfterOperatorRestart = 6,
	horizontalPodAudoscaler = 7,
	resetToDefault = 8,
}
