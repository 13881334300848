import { useRef, useLayoutEffect } from 'react'
import { topWindow } from '../topWindow'

const COMPONENT_PREFIX =
	topWindow !== window ? '-' + (window.frameElement?.id || String(Math.floor(Math.random() * 1000000))) : ''
const PREFIX_GROUP = 'genusSyncWidthGroup' + COMPONENT_PREFIX
const PREFIX_ITEM = 'genusSyncWidthItem' + COMPONENT_PREFIX

const sheetElement = document.createElement('style')
sheetElement.setAttribute('data-meta', 'genus-sync-width')
document.head.append(sheetElement)
const styleSheet = sheetElement.sheet as CSSStyleSheet

let topWindowStyleSheet: typeof styleSheet
if (topWindow !== window) {
	const topWindowSheetElement = topWindow.document.createElement('style')
	topWindowSheetElement.setAttribute('data-meta', `genus-sync-width-${COMPONENT_PREFIX}`)
	topWindow.document.head.append(topWindowSheetElement)
	topWindowStyleSheet = topWindowSheetElement.sheet as CSSStyleSheet
}

type ISyncWidthGroup = {
	index: number
	className: string
	cssRule: CSSStyleRule | undefined
	cssRuleIndex: number | undefined
	maxWidth: number
	widestItemId: number | undefined
}

const groups: Map<string, ISyncWidthGroup> = new Map<string, ISyncWidthGroup>()

let itemCounter = 0

// disable? is used for performance; since hooks cannot be called conditionally
// avoid querySelect for all elements that conditionally does not need to sync width

const setWidth = (rule: CSSStyleRule, width: number) => {
	rule.style.setProperty('width', `${width}px`, 'important')
	rule.style.setProperty('min-width', `${width}px`, 'important')
}

const clearWidth = (rule: CSSStyleRule) => {
	rule.style.removeProperty('width')
	rule.style.removeProperty('min-width')
}

const findMaxWidth = (cssClass: string) => {
	let maxWidth = 0
	document.querySelectorAll(cssClass).forEach((node) => {
		if (maxWidth < node.scrollWidth) {
			maxWidth = node.scrollWidth
		}
	})

	return maxWidth
}

const findNodeByItemClass = (itemClass: string) => {
	const node = document.querySelector('.' + itemClass)

	if (node) {
		return node as HTMLElement | null
	}

	if (document !== topWindow.document) {
		return topWindow.document.querySelector('.' + itemClass) as HTMLElement
	}

	return null
}

const initializeGroupRuleInSheet = (groupId: string, sheet: CSSStyleSheet) => {
	const group = groups.get(groupId)
	if (group && group.cssRule === undefined) {
		const insertedRuleIndex = sheet.insertRule(`.${group.className} {}`)
		group.cssRule = sheet.cssRules[insertedRuleIndex] as CSSStyleRule
	}
}

export function useSyncWidth(id: string | undefined, deps: unknown[], disable?: boolean) {
	const itemId = useRef<number>()

	if (!disable && id && !groups.has(id)) {
		const groupClassName = `${PREFIX_GROUP}-${groups.size}`
		groups.set(id, {
			className: groupClassName,
			index: groups.size,
			cssRule: undefined,
			cssRuleIndex: undefined,
			maxWidth: 0,
			widestItemId: undefined,
		})
	}

	if (!disable && itemId.current === undefined) {
		itemId.current = itemCounter++
	}

	const currentGroup = !disable && id ? groups.get(id) : undefined

	const groupClass = currentGroup ? currentGroup.className : ''
	const itemClass = !disable && id ? `${PREFIX_ITEM}${itemId.current!}` : ''

	// There will be a 'leak' of lingering style sheets not unmounting when a inline page/component unmounts
	// But instead of running effects on every control using useSyncWidth, this is regarded as the 'cheaper' option
	// as mounting and unmounting of inline pages probably won't be frequent enough for empty style sheets to be a memory problem

	const visibilityObserver = useRef<IntersectionObserver>()

	useLayoutEffect(() => {
		if (!disable && id) {
			const group = groups.get(id)!

			const node = findNodeByItemClass(itemClass)
			const isPortalActive = node !== null && node.ownerDocument && node.ownerDocument !== document
			const sheet = isPortalActive ? topWindowStyleSheet : styleSheet

			// make sure the rule is created for the group
			initializeGroupRuleInSheet(id, sheet)

			const isNodeVisible = !!node && !!(node.offsetWidth || node.offsetHeight || node.getClientRects().length)
			if (isNodeVisible) {
				addWidthToStyleSheet(node, group, sheet)
			} else if (node) {
				visibilityObserver.current = respondToVisibility(node, () => addWidthToStyleSheet(node, group, sheet))
			}
		}

		return () => {
			if (visibilityObserver.current) {
				visibilityObserver.current.disconnect()
			}
			if (!disable && id) {
				const group = groups.get(id)
				// If widest item unmounts, find second widest node width
				if (group && group.cssRule && group.widestItemId === itemId.current) {
					// Clear rule
					clearWidth(group.cssRule)

					group.maxWidth = findMaxWidth(`.${groupClass}:not(.${itemClass})`)
					group.widestItemId = -1
					setWidth(group.cssRule, group.maxWidth)
				}
			}
		}

		function addWidthToStyleSheet(node: HTMLElement | null, group: ISyncWidthGroup, sheet: CSSStyleSheet) {
			const isNodeVisible = !!node && !!(node.offsetWidth || node.offsetHeight || node.getClientRects().length)
			if (isNodeVisible && visibilityObserver.current) {
				visibilityObserver.current.disconnect()
			}
			// Current node is wider than the previously registered widest node
			if (node && group.cssRule && group.maxWidth < node.scrollWidth) {
				group.widestItemId = itemId.current
				group.maxWidth = node.scrollWidth

				if (group.cssRuleIndex && group.cssRule && group.cssRuleIndex + 1 <= sheet.rules.length) {
					clearWidth(group.cssRule)
				}

				setWidth(group.cssRule, group.maxWidth)
				// Previously widest node has shrunk, need to find new widest
			} else if (node && group.cssRule && group.widestItemId === itemId.current) {
				clearWidth(group.cssRule)
				group.maxWidth = findMaxWidth(`.${groupClass}`)
				setWidth(group.cssRule, group.maxWidth)
			}
		}
	}, [id, ...deps, disable])

	return groupClass + ' ' + itemClass
}

// https://stackoverflow.com/a/44670818
function respondToVisibility(element: HTMLElement, callback: (isVisible: boolean) => void) {
	const observer = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				callback(entry.intersectionRatio > 0)
			})
		},
		{
			root: document.documentElement,
		}
	)

	observer.observe(element)
	return observer
}
