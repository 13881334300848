import moment from 'moment-timezone'
import { DropdownOptionType, IDropdownOption } from '@genusbiz/web-ui/controls/Dropdown/Dropdown.types'

export const getTimezoneOptions = () => {
	const browserTimezone = moment.tz.guess()
	const groupedTimezones = moment.tz.names().reduce(
		(accumulator, timezone) => {
			const group = timezone.split('/')[0]
			if (!accumulator[group]) {
				accumulator[group] = []
			}
			timezone && accumulator[group].push(timezone)
			return accumulator
		},
		{} as { [group: string]: string[] }
	)

	const timezoneOptions: IDropdownOption<string>[] = [
		{ label: 'UTC', value: 'UTC' },
		{ label: 'Browser Time (' + browserTimezone + ')', value: browserTimezone },
	]

	Object.keys(groupedTimezones)
		.filter((group) => group !== 'UTC') //Avoid two UTCs
		.forEach((group) => {
			timezoneOptions.push({ label: group, type: DropdownOptionType.header })
			groupedTimezones[group].forEach((timezone) => {
				timezoneOptions.push({ label: timezone, value: timezone })
			})
		})

	return timezoneOptions
}
