import { useState } from 'react'
import type { ICommonButtonTypes } from '../DialogTypes'

export const useConfirmationMessageCallbacks = (
	previousActiveElement: React.MutableRefObject<HTMLElement | null>,
	onClose?: (commonButton: ICommonButtonTypes) => void
) => {
	const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)

	const handleClose = (commonButton: ICommonButtonTypes) => {
		setShowConfirmationMessage(false)
		previousActiveElement.current?.focus && previousActiveElement.current?.focus()
		onClose?.(commonButton)
	}

	const handleYes = () => {
		handleClose('ok')
	}

	const handleNo = () => {
		handleClose('cancel')
	}

	const handleCancel = () => {
		setShowConfirmationMessage(false)
	}

	return {
		showConfirmationMessage,
		setShowConfirmationMessage,
		handleYes,
		handleNo,
		handleCancel,
	}
}
