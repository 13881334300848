export enum e_ResourceKind {
	deployment = 'Deployment',
	statefulSet = 'StatefulSet',
	daemonSet = 'DaemonSet',
	ingress = 'Ingress',
	service = 'Service',
	pod = 'Pod',
	configMap = 'ConfigMap',
	serviceMonitor = 'ServiceMonitor',
	job = 'Job',
}
