import { Button } from '@genusbiz/web-ui/controls'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { defaultIcons } from 'src/consts/defaultIcons'
import { AvailabilityWindowEditor } from './AvailabilityWindowEditor'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { IAvailabilityGroup } from 'src/interfaces/IAvailabilityWindow'
import { createStyle } from '@genusbiz/web-ui/theming'

const classes = createStyle({
	button: {
		marginLeft: '4px',
	},
})
interface IProps {
	selectedEnvironment: e_EnvironmentOperatingType
	availabilityGroup: IAvailabilityGroup
}
export const EditAvailabilityWindowGroup = (props: IProps) => {
	const { t } = useTranslation()

	const [showEditor, setShowEditor] = useState(false)
	return (
		<>
			<Button
				className={classes.button}
				variant="inline"
				size="small"
				icon={defaultIcons.edit}
				onClick={() => {
					setShowEditor(true)
				}}
				screenTip={t('GENERAL:EDIT')}
			/>
			<AvailabilityWindowEditor
				isOpen={showEditor}
				onClose={() => setShowEditor(false)}
				availabilityGroup={props.availabilityGroup}
				selectedEnvironment={props.selectedEnvironment}
				availabilityWindows={[]}
			/>
		</>
	)
}
