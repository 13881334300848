import type { RowData, TData } from '../Table.types'

export const tDataToRowData = (data: TData | undefined): RowData | undefined => {
	if (data === undefined) {
		return undefined
	}
	const localData = { ...data }
	const id = getProperty(localData, 'id')
	return {
		id: id as unknown as string,
		columns: {
			...localData,
		},
	}
}

const getProperty = (data: TData, propertyName: string) => {
	const property = data[propertyName]
	delete data[propertyName]
	return property
}
