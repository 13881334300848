import { e_LoginResponse } from 'src/enums/e_LoginResponse'
import { queryStringUtils } from 'src/utils/queryStringUtils'

export const getLoginResponse = () => {
	// Find and delete any loginresponse from query string. Send potential loginresponse to Authentication
	const loginResponse = queryStringUtils.getQueryStringValue('loginResponse')

	//Login is valid. Remove the response from URL
	if (loginResponse === e_LoginResponse.VALID_LOGIN) {
		queryStringUtils.removeQueryStringItem('loginResponse')
	}

	return loginResponse as e_LoginResponse
}
