import { useState } from 'react'
import type { XYCoord } from 'react-dnd'
import type { IListNode } from '../List/IListNode'

export interface IInsertIndex {
	index: number
	groupIndex?: number
}

const getInsertIndexFromPosition = (
	clientOffset: XYCoord | null,
	items: { id?: string; children?: { id: string }[] }[],
	getInsertIndexFromId?: (id: string) => IInsertIndex
) => {
	if (!clientOffset) {
		return
	}
	const htmlDomElementsfromPoint = document.elementsFromPoint(clientOffset.x, clientOffset.y)
	const itemElement = htmlDomElementsfromPoint.find((el) => {
		return el instanceof HTMLElement && el.dataset.rowid
	}) as HTMLElement | undefined
	if (!itemElement) {
		return
	}

	let groupIndex: number | undefined = undefined
	let hoverIndex: number
	if (getInsertIndexFromId) {
		const insertIndexFromId = getInsertIndexFromId(itemElement.dataset.rowid!)
		groupIndex = insertIndexFromId.groupIndex
		hoverIndex = insertIndexFromId.index
	} else {
		const groupElement = htmlDomElementsfromPoint.find((el) => {
			return el instanceof HTMLElement && el.dataset.groupIndex
		}) as HTMLElement | undefined
		if (groupElement) {
			groupIndex = parseInt(groupElement.dataset.groupIndex!)
			const groupItem = items[groupIndex]
			hoverIndex =
				'children' in groupItem && groupItem.children?.length
					? groupItem.children.findIndex((item) => item.id === itemElement.dataset.rowid)
					: 0
		} else {
			hoverIndex = items.findIndex((item) => item.id === itemElement.dataset.rowid)
		}
	}

	const itemElementRect = itemElement.getBoundingClientRect()
	//if in the top half of the drop element
	if (clientOffset.y < itemElementRect.y + itemElementRect.height / 2) {
		return { groupIndex, index: hoverIndex }
	}
	// if in the bottom half of the element
	if (clientOffset.y >= itemElementRect.y + itemElementRect.height / 2) {
		return { groupIndex, index: hoverIndex + 1 }
	}
}

export const useDropObject = <T>(
	items: { id?: string; children?: { id: string }[] }[],
	onDrop?: (insertIndex: IInsertIndex, itemType: string | symbol, item: IListNode<T>) => void,
	onCanDrop?: (insertIndex: IInsertIndex, itemType: string | symbol | null, item: IListNode<T>) => boolean,
	getInsertIndexFromId?: (id: string) => IInsertIndex
) => {
	const [insertIndex, setInsertIndex] = useState<IInsertIndex>()

	const handleDropHover = (
		_itemType: string | symbol | null,
		_item: IListNode,
		_isOver: boolean,
		_isDirectlyOver: boolean,
		clientOffset: XYCoord | null
	) => {
		const dropPosition = getInsertIndexFromPosition(clientOffset, items, getInsertIndexFromId)
		setInsertIndex(dropPosition)
	}

	const handleDrop = (itemType: string | symbol | null, item: IListNode<T>) => {
		if (!onDrop) {
			return
		}
		const returnValue = items.length === 0 ? { index: 0 } : insertIndex
		if (returnValue !== undefined) {
			onDrop(returnValue, itemType as string, item)
		}
		setInsertIndex(undefined)
	}

	const handleCanDrop = (itemType: string | symbol | null, item: IListNode<T>) => {
		if (!onDrop || insertIndex === undefined) {
			return false
		}
		if (!onCanDrop) {
			return true
		}
		return onCanDrop(insertIndex, itemType, item)
	}

	const handleDragLeave = () => {
		setInsertIndex(undefined)
	}

	return [insertIndex, handleDropHover, handleDrop, handleDragLeave, handleCanDrop] as const
}
