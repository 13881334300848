export enum e_ButtonSet {
	okCancel = 'okCancel',
	yesNo = 'yesNo',
	yesNoCancel = 'yesNoCancel',
}

export enum e_ConfirmationButtons {
	ok = 'ok',
	cancel = 'cancel',
	yes = 'yes',
	no = 'no',
}

export const buttonCombos = {
	[e_ButtonSet.okCancel]: {
		[e_ConfirmationButtons.ok]: e_ConfirmationButtons.ok,
		[e_ConfirmationButtons.cancel]: e_ConfirmationButtons.cancel,
	},
	[e_ButtonSet.yesNo]: {
		[e_ConfirmationButtons.yes]: e_ConfirmationButtons.yes,
		[e_ConfirmationButtons.no]: e_ConfirmationButtons.no,
	},
	[e_ButtonSet.yesNoCancel]: {
		[e_ConfirmationButtons.yes]: e_ConfirmationButtons.yes,
		[e_ConfirmationButtons.no]: e_ConfirmationButtons.no,
		[e_ConfirmationButtons.cancel]: e_ConfirmationButtons.cancel,
	},
}
