import type { IDataWithIdentifier, IDetailFieldDescription } from '../types'
import { FieldContainerMapper } from '../FieldContainerMapper'
import type { IFieldData, IFieldGroup } from '../types/Field.types'
import { formatPropertyValue } from './valueUtils'
import type { FormatDataValueEventHandler } from '../types/MasterDetailEventHandlers'

const emptyContentPlaceholder = '---'

const formatColumnData = (
	descriptions: IDetailFieldDescription[],
	activeItem: IDataWithIdentifier,
	formatDataValue: FormatDataValueEventHandler | undefined,
	tcvi: (t: string) => string
) => {
	const formatedData = descriptions.map((description) => ({
		displayName: description.displayName,
		displayValue:
			formatPropertyValue(activeItem, description.propertyId, description, formatDataValue, tcvi) ??
			emptyContentPlaceholder,
	}))

	return formatedData
}

const groupFields = (fields: IFieldData[]) => {
	const groupStyle = {
		padding: 8,
		gap: 8,
		labelBold: true,
	}
	const displayData: IFieldGroup = {
		children: fields,
		syncWidthType: 'inherit',
		style: groupStyle,
	}
	return displayData
}

export const getComponentFromColumnData = (
	activeItem: IDataWithIdentifier,
	fieldDescriptions: IDetailFieldDescription[],
	formatDataValue: FormatDataValueEventHandler | undefined,
	tcvi: (t: string) => string
) => {
	const fields = formatColumnData(fieldDescriptions, activeItem, formatDataValue, tcvi)
	const groupedFields = groupFields(fields)
	const content = FieldContainerMapper(groupedFields, `fieldContainer_${activeItem.id}`)

	return content
}
