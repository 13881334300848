import { type IMasterDetailCallbackAPI } from '../../MasterDetail/types/IMasterDetailCallbackAPI'
import type { IBaseActions, EditableDetailMode, IEditableEntry, IEditableItem } from '../types/detailConfigTypes'

export interface IItemInteractions {
	getItem: (itemId: string) => IEditableItem | undefined
	getDraftBaseItem: () => IEditableItem
	commitItemUpdate: (item: IEditableItem | undefined, onConfirm: () => void, onCancel: () => void) => void
	commitItemCreation: (item: IEditableItem | undefined, onConfirm: () => void, onCancel: () => void) => void
	commitItemDeletion: (itemId: string, onConfirm: () => void, onCancel: () => void) => void
}

interface IItemEditorMethods {
	setBaseItem: (item?: IEditableItem) => void
	edit: (newValues: IEditableEntry) => void
	item?: IEditableItem
}
interface IMasterDetailSystemInteractions extends IItemInteractions {
	api: React.MutableRefObject<IMasterDetailCallbackAPI | undefined>
	itemEditor: IItemEditorMethods
	setDetailMode: (mode: EditableDetailMode) => void
}

export const deriveBaseActions = (interactions: IMasterDetailSystemInteractions): IBaseActions => {
	const showEditMode = (id: string) => {
		const item = interactions.getItem(id)
		interactions.itemEditor.setBaseItem(item)
		interactions.setDetailMode('edit')
	}

	const showViewMode = () => {
		interactions.setDetailMode('view')
	}

	const showCreateMode = () => {
		const draftItem = interactions.getDraftBaseItem()
		interactions.itemEditor.setBaseItem(draftItem)
		interactions.setDetailMode('create')
		showDetailView(draftItem.id)
	}

	const showDetailView = (id: string) => {
		interactions.api.current?.showItem(id)
	}

	const showMasterView = () => {
		showViewMode()
		interactions.api.current?.showTable()
	}

	const updateLocalChanges = (value: IEditableEntry) => {
		interactions.itemEditor.edit(value)
	}

	const discardLocalChanges = () => {
		interactions.itemEditor.setBaseItem()
	}

	const commitItemUpdate = (itemId: string, onConfirm: () => void, onCancel: () => void) => {
		const item = interactions.itemEditor.item
		interactions.commitItemUpdate(item, onConfirm, onCancel)
	}

	const commitItemCreation = (itemId: string, onConfirm: () => void, onCancel: () => void) => {
		const item = interactions.itemEditor.item
		interactions.commitItemCreation(item, onConfirm, onCancel)
	}

	const commitItemDeletion = (itemId: string, onConfirm: () => void, onCancel: () => void) => {
		interactions.commitItemDeletion(itemId, onConfirm, onCancel)
	}

	const baseActions = {
		showEditMode,
		showViewMode,
		showCreateMode,
		showDetailView,
		showMasterView,
		updateLocalChanges,
		discardLocalChanges,
		commitItemUpdate,
		commitItemCreation,
		commitItemDeletion,
	}

	return baseActions
}
