import type { IKeyboardShortcut } from './IKeyboardShortcut'

const getNameFromKeyCode = (keyCode: string) => {
	return keyCode
}

export const getKeyboardShortcutText = (keyboardShortcut: IKeyboardShortcut | undefined) => {
	if (!keyboardShortcut) {
		return undefined
	}

	const parts = []

	const keyName = getNameFromKeyCode(keyboardShortcut.keyCode)

	if (!keyName) {
		return undefined
	}

	if (keyboardShortcut.ctrlOrMetaKey) {
		parts.push('Ctrl')
	}
	if (keyboardShortcut.altKey) {
		parts.push('Alt')
	}
	if (keyboardShortcut.shiftKey) {
		parts.push('Shift')
	}
	parts.push(keyName)

	return parts.join('+')
}
