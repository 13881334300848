import React from 'react'
import { DateInput as DateInputWebUI } from '../../DateInput'
import { e_DataType } from '../enums/e_DataType'
import clsx from 'clsx'
import { zIndexes } from '../../../theming/themeProperties/zIndexes'
import { createStyle } from '../../../theming'
import { POPUP_ANCHOR_CLASS_NAME } from '../hooks/usePopupAnchor'
import type { CustomDateProps } from '@ag-grid-community/react'

const DATE_INPUT_CLASS_NAME = 'date-input-EJHrxO4v'

const classes = createStyle({
	datePicker: {
		zIndex: zIndexes.popper + 1,
		// AgGrid does not unmount the component when it is closed due to hover inside popup, so we need to hide it manually
		[`.${POPUP_ANCHOR_CLASS_NAME}:has(.${DATE_INPUT_CLASS_NAME}) ~ &`]: {
			visibility: 'visible',
		},
		visibility: 'hidden',
	},
})

//Used for filtering dates
export const DateInput = (props: CustomDateProps) => {
	const onChange = (changedDate?: string | null) => {
		if (!changedDate) {
			return
		}

		const newDate = new Date(changedDate)
		window.setTimeout(() => {
			props.onDateChange(newDate)
		})
	}

	return (
		<DateInputWebUI
			value={props.date?.toISOString()}
			onChange={onChange}
			type={e_DataType.date}
			dateFormat={'shortDate'}
			placeholder={'DD.MM.YYYY'}
			datePickerClassName={clsx('ag-custom-component-popup', classes.datePicker)}
			className={DATE_INPUT_CLASS_NAME}
			shouldHideWithObserver
		/>
	)
}
