export enum e_ControlLoopStatusEventType {
	info = 10,
	warning = 20,
	error = 30,
}

export enum e_ControlLoopStatusEventTypeName {
	Info = 'Info',
	Warning = 'Warning',
	Error = 'Error',
}
