import React from 'react'
import type { GridApi } from '@ag-grid-community/core'
import type { ITableContext, TData } from '../../../Table.types'
import { isLeftColumn } from '../../../utils/isLeftColumn'
import { HeaderCheckMark } from '../../HeaderCheckMark'

interface IProps {
	multiSelect: boolean | undefined
	api: GridApi<TData>
	eGridHeader: HTMLElement
	inPreviewMode: boolean | undefined
	context: ITableContext
	columnId: string
}
export const HeaderCheckMarkWrapper = (props: IProps) => {
	if (props.multiSelect && isLeftColumn(props.api, props.columnId)) {
		return (
			<HeaderCheckMark
				api={props.api}
				eGridHeader={props.eGridHeader}
				inPreviewMode={props.inPreviewMode}
				tableContext={props.context}
			/>
		)
	}
}
