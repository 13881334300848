import type { CreateStyle } from '../../theming/themingManager/createStyle'
import { createStyle } from '../../theming/themingManager/createStyle'
import { initTheming } from '../../theming/themingManager/initTheming'
import type { PartialTheme, ThemeDeclaration } from '../../theming/themingManager/themingManager.types'
import {
	createThemeVariation,
	createThemeVariations,
	getThemeVariation,
	hasThemeVariation,
	useDynamicThemeVariation,
	useGetComputedThemeVariation,
} from '../../theming/themingManager/createThemeVariation'
import { extendTheme } from './extendTheme'
import { cssVar } from './cssVar'

/**
 * Get theme utils, typed specifically to a subtype of BaseTheme
 */
export const getTypedThemeUtils = <T extends ThemeDeclaration>() => ({
	initTheming, // Does not need narrowing to specific type
	createStyle: createStyle as CreateStyle<T>,
	createThemeVariation: createThemeVariation as (
		themeVariation: PartialTheme<T>,
		id: string,
		selector?: string
	) => string,
	createThemeVariations: createThemeVariations as (themeVariations: [PartialTheme<T>, string][]) => string[],
	hasThemeVariation, // Does not need narrowing to specific type
	getThemeVariation, // Does not need narrowing to specific type
	useDynamicThemeVariation: useDynamicThemeVariation as (themeVariation: PartialTheme<T>) => React.CSSProperties,
	useGetComputedThemeVariation, // Does not need narrowing to specific type
	extendTheme, // Does not need narrowing to specific type
	cssVar, // Does not need narrowing to specific type
})
