import type { ColDef, ColumnState, GridApi } from '@ag-grid-community/core'
import {
	deleteColumnStateFromLocalStorage,
	deleteFilterModelFromSessionStorage,
	deleteExpandedRowNodesFromSessionStorage,
	readColumnStateFromLocalStorage,
} from '../utils/storageUtils'
import type { e_InitialSelection } from '../enums/e_InitialSelection'
import { useCallback } from 'react'
import type { IGroupingField, ISortedField, ITableContextRef, TData } from '../Table.types'
import { setInitialSelection } from '../utils/selectionUtils'
import { e_SortOrder } from '../enums/e_SortOrder'

// Reset Columns, filter and row groups to initial state and delete from session storage
export const useResetCustomView = (
	id: string | undefined,
	initialColumnDefs: ColDef<TData>[],
	grouping: IGroupingField[] | undefined,
	initialSelection: e_InitialSelection | undefined,
	onColumnStateChanged: (columnState: ColumnState[], resizedColumnIds: string[]) => void,
	tableContextRef: ITableContextRef,
	updateFirstColumn?: (api: GridApi<TData>) => void,
	onsortedColumnsChanged?: (columns: ISortedField[]) => void
) => {
	return useCallback(
		(gridApi: GridApi<TData> | undefined) => {
			// Clear active filters
			gridApi?.setFilterModel(null)

			const columnStateFromStorage = id ? readColumnStateFromLocalStorage(id)?.originalColumnState : undefined
			if (columnStateFromStorage && gridApi) {
				const hasPinnedColumn = gridApi.getColumnState().some((col) => col.pinned)

				if (hasPinnedColumn) {
					// Reset to original Column State (pinned columns)
					gridApi.applyColumnState({ state: columnStateFromStorage, applyOrder: true })
				}
			}

			// Reset to initial Column Definitions
			gridApi?.setGridOption('columnDefs', initialColumnDefs)

			// Set groups expanded and closed
			gridApi?.forEachNode((node) => {
				if (node.group) {
					const expand = grouping?.find((group) => group.id === node.field)?.expanded ?? node.level === 1
					node.setExpanded(expand)
				}
			})

			// Reset context
			tableContextRef.current.openGroupNodes = []

			// Reset sorting to initial sorting
			gridApi?.getColumns()?.forEach((column) => {
				const initialColumn = initialColumnDefs.find((ic) => ic.colId === column.getId())

				if (initialColumn) {
					column.setSort(initialColumn.initialSort, 'api')
					column.setSortIndex(initialColumn.initialSortIndex)
				}
			})

			// report sorting changed, set to sort order defined in initial column defs
			if (onsortedColumnsChanged) {
				const sortedColumns = initialColumnDefs.filter(
					(col) => col.initialSortIndex !== null && col.initialSortIndex !== undefined
				)

				sortedColumns.sort((col1, col2) => col1.sortIndex! - col2.sortIndex!)

				const sortSetup: ISortedField[] = sortedColumns.map((sortCol) => ({
					id: sortCol.colId!,
					order: sortCol.initialSort === 'desc' ? e_SortOrder.desc : e_SortOrder.asc,
				}))

				onsortedColumnsChanged(sortSetup)
			}

			setInitialSelection(gridApi, initialSelection, true)

			if (gridApi) {
				const gridColumnDefs = gridApi.getColumnDefs() as ColDef<TData>[]
				gridApi.getColumns()?.forEach((col) => {
					const colDef = col.getColDef() as ColDef<TData>

					const gridColDef = gridColumnDefs.find((gcd) => gcd.field === colDef.field)
					if (gridColDef) {
						gridColDef.minWidth = colDef.cellRendererParams.minWidthFromProps
						gridColDef.maxWidth = colDef.cellRendererParams.maxWidthFromProps
					}
				})

				gridApi.setGridOption('columnDefs', gridColumnDefs)
			}

			window.setTimeout(() => {
				updateColumnDefs(gridApi)
			})

			// Ensure that storage is not deleted too early, as column state changes on reset will recreate them.
			window.setTimeout(() => {
				deleteStorage(id, gridApi, onColumnStateChanged, updateFirstColumn)
			}, 100)
		},
		[grouping, id, initialColumnDefs, updateFirstColumn]
	)
}

const updateColumnDefs = (gridApi: GridApi<TData> | undefined) => {
	if (gridApi) {
		const columns = gridApi.getColumns()
		columns?.forEach((col) => {
			const colId = col.getId()
			const colDef = col.getColDef() as ColDef<TData>

			const width = colDef.cellRendererParams.widthFromProps

			if (typeof width !== 'number') {
				gridApi?.autoSizeColumns([colId], width !== 'fitToLabelAndContent')
			}
		})

		gridApi.autoSizeColumns(['ag-Grid-AutoColumn'], false)

		const updatedGridColumnDefs = gridApi.getColumnDefs() as ColDef<TData>[]
		gridApi.getColumns()?.forEach((col) => {
			const colDef = col.getColDef()

			if (col.isVisible()) {
				const gridColDef = updatedGridColumnDefs.find((gcd) => gcd.field === colDef.field)
				if (gridColDef) {
					// Remove max width after initial resize (as it is "initial max width")
					gridColDef.minWidth = undefined
					gridColDef.maxWidth = undefined
				}
			}
		})

		gridApi.setGridOption('columnDefs', updatedGridColumnDefs)
	}
}

const deleteStorage = (
	id: string | undefined,
	gridApi: GridApi<TData> | undefined,
	onColumnStateChanged: (columnState: ColumnState[], resizedColumnIds: string[]) => void,
	updateFirstColumn: ((api: GridApi<TData>) => void) | undefined
) => {
	if (id) {
		if (gridApi) {
			const columns = gridApi.getColumns()
			const allVisibleColIds: string[] = []
			columns?.forEach((col) => {
				const colId = col.getId()

				// Autosize visible columns (that have width = fitToContent/fitToLabelAndContent)
				if (col.isVisible()) {
					allVisibleColIds.push(colId)
				}
			})

			onColumnStateChanged(gridApi.getColumnState(), allVisibleColIds)

			// Update first column
			if (updateFirstColumn) {
				updateFirstColumn(gridApi)
			}
		}

		// Delete session storage
		deleteColumnStateFromLocalStorage(id)
		deleteFilterModelFromSessionStorage(id)
		deleteExpandedRowNodesFromSessionStorage(id)
	}
}
