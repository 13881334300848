import React, { useRef, useState } from 'react'
import { e_Placement } from '../../enums/e_Placement'
import { createStyle } from '../../theming'
import { Callout } from '../Callout'

interface ITooltipProps {
	children: React.ReactNode | React.ReactNodeArray
	content: React.ReactNode | React.ReactNodeArray
	placement?: e_Placement
	delay?: 0 | 300 | 500
}

const classes = createStyle({
	tooltipHost: { display: 'inline-block', width: '100%' },
	tooltip: { fontSize: 12 },
})

export const TooltipHost = (props: ITooltipProps) => {
	const { delay = 0, placement = e_Placement.bottom } = props

	const tooltipHost = useRef(null)

	const openTimeout = useRef(0)

	const shouldDisplayTooltip = useRef(false)

	const [isOpen, setIsOpen] = useState(false)

	const onFocus = () => {
		shouldDisplayTooltip.current = true

		toggleTooltip()
	}

	const onBlur = () => {
		shouldDisplayTooltip.current = false

		toggleTooltip(0)
	}

	const onMouseEnter = () => {
		shouldDisplayTooltip.current = true

		toggleTooltip()
	}

	const onMouseLeave = () => {
		shouldDisplayTooltip.current = false

		toggleTooltip(100)
	}

	const onTooltipMouseEnter = () => {
		shouldDisplayTooltip.current = true

		isOpen && toggleTooltip(0)
	}

	const onTooltipMouseLeave = () => {
		shouldDisplayTooltip.current = false

		toggleTooltip(100)
	}

	const toggleTooltip = (wait: number = delay) => {
		clearTimeout(openTimeout.current)
		openTimeout.current = window.setTimeout(() => {
			setIsOpen(shouldDisplayTooltip.current)
		}, wait)
	}

	return (
		<>
			<div
				ref={tooltipHost}
				className={classes.tooltipHost}
				onFocus={onFocus}
				onBlur={onBlur}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{props.children}
			</div>

			<Callout
				isOpen={isOpen}
				anchorElement={tooltipHost}
				onClose={() => setIsOpen(false)}
				placement={placement}
				beakWidth={20}
				className={classes.tooltip}
				padding={0}
			>
				<div
					style={{ padding: 8 }}
					role="tooltip"
					onMouseEnter={onTooltipMouseEnter}
					onMouseLeave={onTooltipMouseLeave}
				>
					{props.content}
				</div>
			</Callout>
		</>
	)
}
