import React from 'react'
import { Avatar } from '../../../../Avatar'
import type { ICellRenderer } from '../../../Table.types'
import { createStyle } from '../../../../../theming'

const classes = createStyle({
	avatar: {
		display: 'flex',
		overflow: 'hidden',
		alignItems: 'center',
		gap: 4,
	},
	label: { textOverflow: 'ellipsis', overflow: 'hidden' },
})

interface ICellRendererAvatar extends ICellRenderer {
	value?: string
	label?: string
}

export const CellRendererAvatar = (props: ICellRendererAvatar) => {
	return (
		<span className={classes.avatar}>
			{props.value && <Avatar imageUrl={props.value} size={'extraSmall'} />}
			<span className={classes.label}>{props.label}</span>
		</span>
	)
}
