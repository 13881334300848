import React, { useRef, useEffect, useState, useCallback } from 'react'
import classNames from 'clsx'

import type { IFormControl } from '../FormControl'
import { FormControl, FormControlButton } from '../FormControl'
import { useEventListener } from '../utils/useEventListener'
import { useId } from '../hooks/useId'
import { useForwardedRef } from '../utils/useForwardedRef'
import { formatDateWithFormatType, formatDateWithFieldFormatter } from '../utils/formatDate'
import { useDataAttributes } from '../utils/useDataAttributes'
import { useStyleContext } from '../utils/Style.context'
import type { Moment } from 'moment'
import moment from 'moment'
import { useTranslation } from '../../translation'
import { DatePickerPopup } from '../DatePicker/DatePickerPopup'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { createStyle } from '../../theming'
import { useObserveVisibilityChange } from '../utils/useObserveVisibilityChange'
import { e_TextAlignment } from '../../enums/e_TextAlignment'

const classes = createStyle((theme) => ({
	alignRight: { textAlign: 'right' },
	alignCenter: { textAlign: 'center' },
	focus: {
		outlineColor: theme.controls.input.focusedColors.border,
		outlineWidth: theme.controls.input.focusBorderWidth,
	},
	focusError: { outlineColor: theme.controls.input.errorColors.border },
	input: { fontWeight: 'inherit' },
}))

const dateMasks = {
	inputMask: 'DD.MM.YYYY',
	outputMask: 'LL',
	saveMask: 'YYYY-MM-DD',
}

const dateTimeMasks = {
	inputMask: 'DD-MM-YYYY HH:mm:ss',
	outputMask: 'DD-MM-YYYY HH:mm:ss',
	saveMask: 'YYYY-MM-DDTHH:mm:ss.SSS',
}

enum DateUnits {
	day = 'd',
	month = 'M',
	year = 'y',
}

type DateInputValue = string | null | undefined

interface IDateInputProps extends IFormControl {
	value: DateInputValue
	placeholder?: string
	dateFormat: string
	type?: 'date' | 'dateTime'
	textAlignment?: e_TextAlignment
	datePickerClassName?: string
	datePickerOpen?: boolean
	onChange?: (value: string | null) => void
	onBlur?: (value: string | null) => void
	onClose?: () => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
	formatValue?: (value: any) => any
	fromYear?: number
	toYear?: number
	actionButtonRef?: React.Ref<HTMLButtonElement>
	initialInputValue?: string
	/** If true, the date picker popup will close if the input is no longer visible.This is done through an intersection observer.*/
	shouldHideWithObserver?: boolean
}

export interface IInternalDateTimeValue {
	year?: number
	month?: number // obs: 0-indeksert!
	day?: number
	hour?: number
	minute?: number
	seconds?: number
	millisecond?: number
}

export const getInternalFormatFromDateTimeValue = (
	dateTimeValue: DateInputValue
): IInternalDateTimeValue | undefined => {
	if (dateTimeValue === undefined || dateTimeValue === null) {
		return undefined
	}

	const momentDateTime = moment(dateTimeValue)

	if (!momentDateTime || !momentDateTime.isValid()) {
		return undefined
	}

	return {
		year: momentDateTime.year(),
		month: momentDateTime.month(), // obs: 0-indeksert!
		day: momentDateTime.date(),
		hour: momentDateTime.hour(),
		minute: momentDateTime.minute(),
		seconds: momentDateTime.second(),
		millisecond: momentDateTime.millisecond(),
	}
}

const getInternalFormatFromDateValue = (dateTimeValue: DateInputValue): IInternalDateTimeValue | undefined => {
	if (dateTimeValue === undefined || dateTimeValue === null) {
		return undefined
	}

	const momentDateTime = moment(dateTimeValue)

	if (!momentDateTime || !momentDateTime.isValid()) {
		return undefined
	}

	return {
		year: momentDateTime.year(),
		month: momentDateTime.month(), // obs: 0-indeksert!
		day: momentDateTime.date(),
	}
}

export const getDateTimeFromInternalFormat = (
	internalValue: IInternalDateTimeValue | undefined | null
): string | undefined => {
	if (internalValue === undefined || internalValue === null) {
		return undefined
	}

	const momentDateTime = moment({
		year: internalValue.year,
		month: internalValue.month, // obs: 0-indeksert!
		date: internalValue.day,
		hour: internalValue.hour,
		minute: internalValue.minute,
		seconds: internalValue.seconds,
		millisecond: internalValue.millisecond,
	})

	if (!momentDateTime || !momentDateTime.isValid()) {
		return undefined
	}

	return momentDateTime.format(dateTimeMasks.saveMask)
}

export const getDateFromInternalFormat = (
	internalValue: IInternalDateTimeValue | undefined | null
): string | undefined => {
	if (internalValue === undefined || internalValue === null) {
		return undefined
	}

	const momentDateTime = moment({
		year: internalValue.year,
		month: internalValue.month, // obs: 0-indeksert!
		date: internalValue.day,
	})

	if (!momentDateTime || !momentDateTime.isValid()) {
		return undefined
	}

	return momentDateTime.format(dateMasks.saveMask)
}

const formatDateTimeValue = (internalValue: IInternalDateTimeValue | undefined): string => {
	if (internalValue === undefined) {
		return ''
	}

	const momentDateTime = moment({
		year: internalValue.year,
		month: internalValue.month, // obs: 0-indeksert!
		date: internalValue.day,
		hour: internalValue.hour,
		minute: internalValue.minute,
		seconds: internalValue.seconds,
		millisecond: internalValue.millisecond,
	})

	if (!momentDateTime || !momentDateTime.isValid()) {
		return ''
	}

	return momentDateTime.format('L LTS')
}

const formatDateValue = (internalValue: IInternalDateTimeValue | undefined): string => {
	if (internalValue === undefined) {
		return ''
	}

	const momentDateTime = moment({
		year: internalValue.year,
		month: internalValue.month, // obs: 0-indeksert!
		date: internalValue.day,
	})

	if (!momentDateTime || !momentDateTime.isValid()) {
		return ''
	}

	return momentDateTime.format('L')
}

const parseDateTimeTextValue = (textValue: string): IInternalDateTimeValue | undefined => {
	const date = moment(
		textValue,
		[
			dateTimeMasks.inputMask,
			dateTimeMasks.outputMask,
			'L LTS',
			'L LT',
			'L',
			'LL LTS',
			'LL LT',
			'LL',
			moment.HTML5_FMT.DATETIME_LOCAL,
			moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
			'DD MM YYYY HH:mm:ss',
			'DD MM YYYY HH:mm',
			'DD MM YY HH:mm:ss',
			'DD MM YY HH:mm',
			'DDMMYYYY HHmmss',
			'DDMMYYYY HHmm',
			'DDMMYY HHmmss',
			'DDMMYY HHmm',
			'YYYY-MM-DD HH:mm:ss',
			'YYYY-MM-DD HH:mm',
			'YYYYMMDD HHmmss',
			'YYYYMMDD HHmm',
			'DDMMYYYY',
			'DDMMYY',
			'YYYY-MM-DD',
			'YYYY-MM-DD',
			'YYYYMMDD',
			'YYYYMMDD',
			'D.M HH:mm',
			'D.M HHmm',
			'D.M HH',
			'D M HH:mm',
			'D M HHmm',
			'D M HH',
			'D.M',
			'D M',
			'D HHmm',
			'D HH:mm',
			'D',
		],
		true
	)

	if (!date) {
		return undefined
	}

	if (!date.isValid()) {
		return undefined
	}

	return {
		year: date.year(),
		month: date.month(), //0-11, zero indexed
		day: date.date(),
		hour: date.hour(),
		minute: date.minute(),
		seconds: date.second(),
		millisecond: date.millisecond(),
	}
}

const parseDateTextValue = (textValue: string): IInternalDateTimeValue | undefined => {
	const date = moment(
		textValue,
		[
			dateMasks.inputMask,
			dateMasks.outputMask,
			'L',
			'LL',
			moment.HTML5_FMT.DATETIME_LOCAL,
			moment.HTML5_FMT.DATE,
			'D M YY',
			'D M YYYY',
			'D MM YY',
			'D MM YYYY',
			'DD M YY',
			'DD M YYYY',
			'DD MM YY',
			'DD MM YYYY',
			'DDMMYY',
			'DDMMYYYY',
			'D.M.YY',
			'D.M.YYYY',
			'D.MM.YY',
			'D.MM.YYYY',
			'DD.M.YY',
			'DD.M.YYYY',
			'DD.MM.YY',
			'DD.MM.YYYY',
			'D/M/YY',
			'D/M/YYYY',
			'D/MM/YY',
			'D/MM/YYYY',
			'DD/M/YY',
			'DD/M/YYYY',
			'DD/MM/YY',
			'DD/MM/YYYY',
			'D-M-YY',
			'D-M-YYYY',
			'D-MM-YY',
			'D-MM-YYYY',
			'DD-M-YY',
			'DD-M-YYYY',
			'DD-MM-YY',
			'DD-MM-YYYY',
			'YYYY-MM-DD',
			'YYYYMMDD',
			'D.M',
			'D M',
			'D',
		],
		true
	)

	if (!date) {
		return undefined
	}

	if (!date.isValid()) {
		return undefined
	}

	return {
		year: date.year(),
		month: date.month(), //0-11, zero indexed
		day: date.date(),
	}
}

const datePickerDateToInternalDateValue = (date: Date | undefined): IInternalDateTimeValue | undefined => {
	if (!date) {
		return undefined
	}

	return {
		year: date.getFullYear(),
		month: date.getMonth(), //0-11, zero indexed
		day: date.getDate(),
	}
}

const internalDateValueToDatePickerDate = (dateValue: IInternalDateTimeValue | undefined): Date | undefined => {
	if (!dateValue) {
		return undefined
	}

	const momentDateTime = moment({
		year: dateValue.year,
		month: dateValue.month, // obs: 0-indeksert!
		date: dateValue.day,
	})

	if (!momentDateTime) {
		return undefined
	}

	if (!momentDateTime.isValid()) {
		return undefined
	}

	return momentDateTime.toDate()
}

const internalDateValueToMomentValue = (dateValue: IInternalDateTimeValue | undefined): Moment | undefined => {
	if (!dateValue) {
		return undefined
	}

	const momentDateTime = moment({
		year: dateValue.year,
		month: dateValue.month, // obs: 0-indeksert!
		date: dateValue.day,
		hour: dateValue.hour,
		minute: dateValue.minute,
		seconds: dateValue.seconds,
		millisecond: dateValue.millisecond,
	})

	if (!momentDateTime) {
		return undefined
	}

	if (!momentDateTime.isValid()) {
		return undefined
	}

	return momentDateTime
}

const momentValueToInternalDateValue = (dateValue: Moment | undefined): IInternalDateTimeValue | undefined => {
	if (!dateValue) {
		return undefined
	}

	if (!dateValue.isValid()) {
		return undefined
	}

	return {
		year: dateValue.year(),
		month: dateValue.month(), //0-11, zero indexed
		day: dateValue.date(),
		hour: dateValue.hour(),
		minute: dateValue.minute(),
		seconds: dateValue.second(),
		millisecond: dateValue.millisecond(),
	}
}

export const DateInput = React.forwardRef((props: IDateInputProps, forwardedRef: React.Ref<HTMLInputElement>) => {
	const { actionIcon = 'Fluent-Forward' } = props
	const { tcvi } = useTranslation()

	const convertToInternalFormatFunc =
		props.type === 'dateTime' ? getInternalFormatFromDateTimeValue : getInternalFormatFromDateValue
	const convertToExternalFormatFunc =
		props.type === 'dateTime' ? getDateTimeFromInternalFormat : getDateFromInternalFormat

	const formatInternalValueFunc = props.type === 'dateTime' ? formatDateTimeValue : formatDateValue
	const parseTextValueFunc = props.type === 'dateTime' ? parseDateTimeTextValue : parseDateTextValue

	const internalValue = useRef<IInternalDateTimeValue | undefined>()
	const isEnteredValueValid = useRef<boolean>(true)

	const [internalErrorMessage, setInternalErrorMessage] = useState<string | undefined>()

	const [displayValue, setDisplayValue] = useState(props.initialInputValue ?? '')
	const userIsWriting = useRef(false)
	const formatNotParsableWhileEditing = useRef(false)

	const inputRef = useForwardedRef<HTMLInputElement>(forwardedRef)
	const borderRef = useRef<HTMLDivElement>(null)
	const hasHadFocus = useRef(false)

	const id = useId('date-input', props.id)

	const dataAttributesDatePicker = useDataAttributes(props.dataAttributes, 'date-picker')

	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

	const checkIfDisplayedValueIsParsable = () => {
		//Check whether current format selected is editable. i.e. "relative" may not be edited, and must change to a short date
		if (parseTextValueFunc(displayValue) === undefined) {
			formatNotParsableWhileEditing.current = true
			updateInternalValue(internalValue.current, true, false, null)
		}
	}

	useEffect(() => {
		window.setTimeout(() => {
			props.datePickerOpen && setIsDatePickerOpen(props.datePickerOpen)
		}, 0)
	}, [props.datePickerOpen])

	const formatInternalFormatValue = useCallback(
		(value: IInternalDateTimeValue | undefined): string => {
			// rules are as follows:
			// 1. if a predefined format is defined, apply format
			// 2. if a formatter is provided, use the formatter to specify output
			// 3. use the internal formatting function

			if (!value) {
				return ''
			}

			const momentDate = internalDateValueToMomentValue(value)

			if (!momentDate) {
				return ''
			}

			let format = props.dateFormat
			if (
				(parseTextValueFunc(displayValue) === undefined && inputRef.current === document.activeElement) ||
				formatNotParsableWhileEditing.current
			) {
				format = props.type === 'dateTime' ? 'shortDateTime' : 'shortDate'
			}

			const formattedValue =
				formatDateWithFormatType(momentDate, format, tcvi, props.type) ||
				formatDateWithFieldFormatter(momentDate, props.formatValue)

			if (formattedValue !== undefined) {
				return formattedValue
			}

			return formatInternalValueFunc(value)
		},
		[formatInternalValueFunc, props.dateFormat, props.formatValue, displayValue]
	)

	useEffect(() => {
		// a new value has been passed in
		const newValue = convertToInternalFormatFunc(props.value)
		updateInternalValue(newValue, userIsWriting.current === false, false, false)
	}, [props.required, props.value, props.error])

	useEffect(() => {
		if (inputRef.current === document.activeElement && !userIsWriting.current) {
			selectInputContent()
		}
	}, [displayValue])

	const parseTextValue = (textValue: string) => {
		if (!textValue) {
			isEnteredValueValid.current = true
			updateInternalValue(undefined, false, true, false)
			setDisplayValue(textValue)

			return
		}
		const parsedValue = parseTextValueFunc(textValue)

		updateInternalValue(parsedValue, false, true, parsedValue === undefined)
		setDisplayValue(textValue)
	}

	useEffect(() => {
		if (props.initialInputValue) {
			parseTextValue(props.initialInputValue)
		}
	}, [])

	const onTextValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const textValue = event.target.value
		userIsWriting.current = true

		parseTextValue(textValue)
	}

	const validateValue = (value: IInternalDateTimeValue | undefined): string | undefined => {
		if (value === undefined && props.required && props.error) {
			return tcvi('GENERAL:DATA_VALIDATION_ERROR_VALUE_CANNOT_BE_BLANK')
		}

		return undefined
	}

	const updateInternalValue = (
		value: IInternalDateTimeValue | undefined,
		updateTextValue: boolean,
		raiseValueChanged: boolean,
		isTextValueInvalid: boolean | null
	) => {
		internalValue.current = value

		if (isTextValueInvalid !== null) {
			const validationMessage = validateValue(internalValue.current)

			setInternalErrorMessage(isTextValueInvalid ? tcvi('GENERAL:DATA_VALIDATION_INVALID_VALUE') : validationMessage)
		}

		isEnteredValueValid.current = true

		if (!hasHadFocus.current && props.initialInputValue) {
			return
		}

		if (updateTextValue) {
			setDisplayValue(formatInternalFormatValue(value))
		}

		if (raiseValueChanged) {
			const externalFormat = convertToExternalFormatFunc(value) || null

			props.onChange?.(externalFormat)
		}
	}

	const onBlur = (e: React.FocusEvent) => {
		userIsWriting.current = false
		if (!isDatePickerOpen) {
			props.setLogicalFocus?.(false)
		}

		if (isDatePickerOpen) {
			return
		}

		//Table needs this in order to be able to open date picker
		if (borderRef.current?.contains(e.relatedTarget as Node)) {
			return
		}

		//Do not update value if the format is not parsable (for instance "relative"), when date picker is opened
		if (borderRef.current?.contains(e.relatedTarget as Node) && formatNotParsableWhileEditing.current) {
			return
		} else {
			formatNotParsableWhileEditing.current = false
		}

		updateInternalValue(internalValue.current, true, true, false)

		const externalFormat = convertToExternalFormatFunc(internalValue.current) || null
		props.onBlur?.(externalFormat)
	}

	const selectInputContent = useCallback(() => {
		if (hasHadFocus.current || !props.initialInputValue) {
			inputRef.current?.select()
		}
	}, [inputRef, props.initialInputValue])

	const openDatePicker = () => {
		checkIfDisplayedValueIsParsable()
		setIsDatePickerOpen(true)
	}

	const { onClose } = props
	const closeDatePicker = useCallback(
		(intersectionRatio = 0, skipFocusAfterClose = false) => {
			if (intersectionRatio === 1) {
				return
			}
			selectInputContent()
			setIsDatePickerOpen(false)

			if (!skipFocusAfterClose) {
				inputRef.current?.select()
				inputRef.current?.focus()
			}

			onClose?.()
		},
		[inputRef, onClose, selectInputContent]
	)

	const onInputElementVisibilityChanged = useCallback(
		(intersectionRatio: number) => {
			if (!isDatePickerOpen) {
				return
			}

			if (intersectionRatio === 1) {
				return
			}

			closeDatePicker(0, true)
		},
		[closeDatePicker, isDatePickerOpen]
	)

	const toggleDatePicker = () => {
		props.setLogicalFocus?.(true)
		if (isDatePickerOpen) {
			formatNotParsableWhileEditing.current = false

			closeDatePicker()
		} else {
			openDatePicker()
		}
	}

	const handleDateSelectedInDatePicker = (date: Date) => {
		const datePickerValue = datePickerDateToInternalDateValue(date)

		const newValue: IInternalDateTimeValue = {
			...internalValue.current,
			...datePickerValue,
		}

		updateInternalValue(newValue, true, true, false)

		closeDatePicker()
	}

	const adjustDate = (amount: number, unit: DateUnits) => {
		const momentDate = internalDateValueToMomentValue(internalValue.current)

		const modifiedDate = momentDate ? momentDate.add(amount, unit) : moment()

		const updatedInternalValue = momentValueToInternalDateValue(modifiedDate)

		updateInternalValue(updatedInternalValue, true, true, false)
	}

	const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (props.readOnly || props.disabled) {
			return
		}

		switch (event.key) {
			case 'Enter':
				if (!props.defaultAction || event.ctrlKey) {
					event.stopPropagation()
					event.preventDefault()

					openDatePicker()
				} else if (props.defaultAction) {
					props.defaultAction()
				}
				break
			case 'ArrowUp':
			case 'ArrowDown': {
				event.preventDefault()

				const amount = event.key === 'ArrowDown' ? 1 : -1
				if (event.ctrlKey) {
					adjustDate(amount, DateUnits.year)
				} else if (event.shiftKey) {
					adjustDate(amount, DateUnits.month)
				} else {
					adjustDate(amount, DateUnits.day)
				}
				break
			}
		}
		props.onKeyDown?.(event)
	}

	const onInputClick = () => {
		setIsDatePickerOpen(false)
	}

	const onMouseOver = () => {
		props.setLogicalHover?.(true)
	}

	const onMouseOut = () => {
		props.setLogicalHover?.(false)
	}

	const onFocus = () => {
		selectInputContent()
		props.setLogicalFocus?.(true)

		checkIfDisplayedValueIsParsable()
		hasHadFocus.current = true
	}

	useEventListener('keydown', onKeyDown, inputRef)

	const { placeholder, readOnlyIndicatorStyle } = {
		...useStyleContext().style?.input,
	}

	const contentCSS = classNames(
		{
			[classes.alignRight]: props.textAlignment === e_TextAlignment.right,
			[classes.alignCenter]: props.textAlignment === e_TextAlignment.center,
		},
		classes.input
	)

	useObserveVisibilityChange(inputRef, onInputElementVisibilityChanged, props.shouldHideWithObserver)

	return (
		<FormControl
			id={id}
			label={props.label}
			labelPosition={props.labelPosition}
			labelProps={props.labelProps}
			labelIcon={props.labelIcon}
			labelIconColor={props.labelIconColor}
			labelBold={props.labelBold}
			labelContentLayout={props.labelContentLayout}
			hideLabel={props.hideLabel}
			disabled={props.disabled}
			labelSubText={props.labelSubText}
			validationText={props.validationText}
			validationTextPosition={props.validationTextPosition}
			subText={props.subText}
			reserveHelperTextSpace={props.reserveHelperTextSpace}
			ref={borderRef}
			error={props.error || internalErrorMessage !== undefined}
			warning={props.warning}
			margin={props.margin}
			readOnly={props.readOnly}
			required={props.required}
			className={props.className}
			borderClassName={classNames(props.borderClassName, {
				[classes.focus]: isDatePickerOpen,
				[classes.focusError]: isDatePickerOpen && props.error,
			})}
			disableBorder={props.disableBorder}
			logicalHover={props.logicalHover}
			logicalFocus={props.logicalFocus}
			screenTip={props.screenTip}
			onClick={props.onClick}
			onMouseDown={props.onMouseDown}
		>
			{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
				<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
			)}
			<input
				disabled={props.disabled}
				className={contentCSS}
				id={id}
				{...props.dataAttributes}
				ref={inputRef}
				placeholder={props.placeholder ?? placeholder}
				value={displayValue}
				type="text"
				autoComplete="off" // autoComplete="off" does now work with Chrome
				onChange={onTextValueChange}
				onBlur={onBlur}
				readOnly={props.readOnly}
				name={props.name}
				onClick={onInputClick}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
				onFocus={onFocus}
			/>
			{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
				<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
			)}

			<FormControlButton
				disabled={props.disabled || props.readOnly}
				onMouseDown={(e) => isDatePickerOpen && e.preventDefault()}
				onClick={toggleDatePicker}
				iconClassName="Fluent-Event"
				isActive={isDatePickerOpen}
				onBlur={onBlur}
				setLogicalHover={props.setLogicalHover}
				tabStop={false}
				screenTip={tcvi('GENERAL:SELECT_A_DATE')}
			/>
			<DatePickerPopup
				onOutsideClickOrEscapePress={closeDatePicker}
				onDateSelected={handleDateSelectedInDatePicker}
				selectedDay={internalDateValueToDatePickerDate(internalValue.current) || moment().toDate()}
				isOpen={isDatePickerOpen}
				className={props.datePickerClassName}
				anchorElement={borderRef}
				dataAttributes={dataAttributesDatePicker}
			/>
			{props.defaultAction && !props.hideActionButton && (
				<FormControlButton
					iconClassName={actionIcon}
					screenTip={props.actionScreenTip}
					onClick={!props.disabled ? props.defaultAction : undefined}
					disabled={props.disabled}
					ref={props.actionButtonRef}
					dataAttributes={props.dataAttributes}
				/>
			)}
		</FormControl>
	)
})

DateInput.displayName = 'DateInput'
