export const TICKS_PER_MINUTE = 60000
export const TICKS_PER_HOUR = TICKS_PER_MINUTE * 60
export const TICKS_PER_DAY = TICKS_PER_HOUR * 24
export const MINUTES_PER_DAY = 24 * 60

// We are using the name of the group to build the result in case of match
// (if these are not as expected, the parsed values may end up wrong)
// for integer time, the groups should be named "hour" and "minute" + eventually "ampm"
// for durations, the groups are named "days", "hours", "minutes, "seconds", "milliseconds" (year and month can not be counted in a duration)

// If more formats are required, add new parse patterns below.

const PARSE_PATTERN_INTEGER_TIME_WITH_SEPARATOR = /^(?<hour>\d|[0-1]\d|2[0-4])[ :.,](?<minute>[d]|[0-5]?\d)?$/
const PARSE_PATTERN_INTEGER_TIME_WITH_SEPARATOR_AM_PM =
	/^(?<hour>\d|[0-1][0-2])[ :.,](?<minute>[d]|[0-5]?\d)[ ]?(?<ampm>AM|PM|am|pm|Am|Pm)$/
const PARSE_PATTERN_INTEGER_TIME_HOURS = /^(?<hour>\d|[0-1]\d|2[0-4])$/
const PARSE_PATTERN_INTEGER_TIME_HOURS_AM_PM = /^(?<hour>\d|[0-1][0-2])[ ]?(?<ampm>AM|PM|am|pm|Am|Pm)$/
const PARSE_PATTERN_INTEGER_TIME_WITHOUT_SEPARATOR = /^(?<hour>\d|[0-1]\d|2[0-4])(?<minute>[d]|[0-5]?\d)$/
const PARSE_PATTERN_INTEGER_TIME_WITHOUT_SEPARATOR_AM_PM =
	/^(?<hour>\d|[0-1]\d|2[0-4])(?<minute>[d]|[0-5]?\d)[ ]?(?<ampm>AM|PM|am|pm|Am|Pm)$/
const PARSE_PATTERN_INTEGER_TIME_MINUTES = /^(?<minute>\d|[0-5]\d)$/

const PARSE_PATTERN_DURATION_H_M_WITH_SEPARATOR = /^(?<hours>\d*)[ :.,](?<minutes>[d]|[0-5]?\d)?$/
const PARSE_PATTERN_DURATION_D_H_M_WITH_SEPARATOR =
	/^(?<days>\d*)[ :.,](?<hours>\d|[0-1]\d|2[0-3])[ :.,](?<minutes>[d]|[0-5]?\d)$/
const PARSE_PATTERN_DURATION_H_M_WITHOUT_SEPARATOR = /^(?<hours>\d|[0-1]\d|2[0-3])(?<minutes>[d]|[0-5]\d)$/
const PARSE_PATTERN_DURATION_HOURS = /^(?<hours>\d*)$/

export const IntegerTimeParsePatterns = [
	PARSE_PATTERN_INTEGER_TIME_WITH_SEPARATOR,
	PARSE_PATTERN_INTEGER_TIME_WITH_SEPARATOR_AM_PM,
	PARSE_PATTERN_INTEGER_TIME_HOURS,
	PARSE_PATTERN_INTEGER_TIME_HOURS_AM_PM,
	PARSE_PATTERN_INTEGER_TIME_WITHOUT_SEPARATOR,
	PARSE_PATTERN_INTEGER_TIME_WITHOUT_SEPARATOR_AM_PM,
	PARSE_PATTERN_INTEGER_TIME_MINUTES,
]

export const DurationParsePatterns = [
	PARSE_PATTERN_DURATION_H_M_WITH_SEPARATOR,
	PARSE_PATTERN_DURATION_D_H_M_WITH_SEPARATOR,
	PARSE_PATTERN_DURATION_H_M_WITHOUT_SEPARATOR,
	PARSE_PATTERN_DURATION_HOURS,
]
