import React from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'
import type { StyleObject } from '../utils/useMergeStyles'

const classes = createStyle((theme) => ({
	'@keyframes rotate': {
		from: { transform: 'rotate(0deg)' },
		to: { transform: 'rotate(360deg)' },
	},
	spinner: {
		borderRadius: '50%',
		boxSizing: 'border-box',
		borderWidth: '1.5px',
		borderStyle: 'solid',
		borderColor:
			theme.palette.primary.themePrimary +
			' ' +
			theme.palette.primary.themeLight +
			' ' +
			theme.palette.primary.themeLight,
		animation: theme.transitions.duration.veryLong + ' ' + theme.transitions.easing.rotate + ' infinite $rotate',
	},
	small: {
		width: '15px',
		height: '15px',
	},
	medium: {
		width: '25px',
		height: '25px',
	},
	large: {
		width: '40px',
		height: '40px',
	},
}))

interface ISpinner {
	size?: 'small' | 'medium' | 'large'
	className?: string
	style?: StyleObject
	as?: 'div' | 'span'
}

export const Spinner = React.forwardRef((props: ISpinner, ref: React.Ref<HTMLDivElement>) => {
	const { size = 'medium' } = props

	const As = props.as || 'div'
	return <As className={classNames(classes.spinner, classes[size], props.className)} style={props.style} ref={ref} />
})

Spinner.displayName = 'Spinner'
