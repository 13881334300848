import React from 'react'
import { Checkbox } from '../../../../Checkbox'
import type { ICellRenderer } from '../../../Table.types'

interface ICellRendererCheckbox extends ICellRenderer {
	value: boolean
	disabled: boolean
	newCheckbox?: boolean
}

export const CellRendererCheckbox = (props: ICellRendererCheckbox) => {
	const onChange = (value: boolean | null) => {
		props.setValue?.(value)
	}

	return (
		<div onClick={(e) => e.preventDefault()} onDoubleClick={(e) => e.preventDefault()}>
			<Checkbox
				checked={props.value}
				onChange={onChange}
				disabled={props.disabled}
				hasCheckMarkOnly
				newCheckmark={props.newCheckbox}
				disableUniformHeight
			/>
		</div>
	)
}
