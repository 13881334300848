/**
 * @remarks
 * This function finds the first item where the callback maps to a defined value and returns the mapped value
 */
export const findMapped = <T, M>(entries: T[], callback: (entry: T, index?: number) => M | undefined) => {
	for (let i = 0; i < entries.length; i++) {
		const entry = entries[i]
		const mappedValue = callback(entry, i)
		if (mappedValue !== undefined) {
			return mappedValue
		}
	}
}
