import { useTranslation } from '../../../translation'
import { TICKS_PER_MINUTE } from './consts'
import { getFormatDurationFunc } from './formatUtils'

export const useValidateValue = (
	internalValue: number | undefined,
	displayValue: string,
	isIntegerTime: boolean | undefined,
	error: boolean | undefined,
	required: boolean | undefined,
	minValueMinutes: number,
	maxValueMinutes: number | undefined
) => {
	const { tcvvi, tcvi } = useTranslation()

	const formatDurationFunc = getFormatDurationFunc(isIntegerTime)

	if (displayValue && internalValue === undefined) {
		return 'Something is wrong. Change this text'
	}

	const validateValue = (valueTicksMS: number | undefined): string | undefined => {
		if (valueTicksMS === undefined && required && error) {
			return tcvi('GENERAL:DATA_VALIDATION_ERROR_VALUE_CANNOT_BE_BLANK')
		}
		// check if value is within range
		if (valueTicksMS !== undefined) {
			if (minValueMinutes && valueTicksMS < minValueMinutes * TICKS_PER_MINUTE) {
				return tcvvi('GENERAL:DATA_VALIDATION_VALUE_BELOW_MIN', formatDurationFunc(minValueMinutes * TICKS_PER_MINUTE))
			}

			if (maxValueMinutes && valueTicksMS > maxValueMinutes * TICKS_PER_MINUTE) {
				return tcvvi('GENERAL:DATA_VALIDATION_VALUE_ABOVE_MAX', formatDurationFunc(maxValueMinutes * TICKS_PER_MINUTE))
			}
		}

		return undefined
	}

	return validateValue(internalValue)
}
