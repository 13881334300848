import React from 'react'

import { SpinnerOverlay } from './SpinnerOverlay'
import { useTranslation } from '../../../translation'

export const LoadingOverlay = () => {
	const { tcvi } = useTranslation()
	return (
		<div className="ag-custom-loading-cell" style={{ paddingLeft: '10px', lineHeight: '25px' }}>
			<SpinnerOverlay delay={0} message={tcvi('INVARIANT_GENERAL:LOADING')} />
		</div>
	)
}
