import type { HEX } from './colors/colorFormats.types'
import { hex2Rgb } from './colors/colorUtils'

export const generateShadows = (hex: HEX) => {
	const rgb = hex2Rgb(hex)
	const color = `${rgb.r}, ${rgb.g}, ${rgb.b}`

	return {
		none: '0 0 0 0 transparent',
		extraLight: `0 1.6px 3.6px 0 rgba(${color}, 0.132), 0 0.3px 0.9px 0 rgba(${color}, 0.108)`,
		light: `0 3.2px 7.2px 0 rgba(${color}, 0.132), 0 0.6px 1.8px 0 rgba(${color}, 0.108)`,
		medium: `0 6.4px 14.4px 0 rgba(${color}, 0.132), 0 1.2px 3.6px 0 rgba(${color}, 0.108)`,
		strong: `0 5px 10px 0 rgba(${color}, 0.22), 0 4.8px 14.4px 0 rgba(${color}, 0.18)`,
	}
}

export type ShadowTypes = ReturnType<typeof generateShadows>
