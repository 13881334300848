import { useEffect, useLayoutEffect, useRef, type RefObject } from 'react'
import { topWindow } from '../../utils/topWindow'
import { MOBILE_WIDTH } from '../../Dialog/DialogTypes'

export const useModalPositioning = (ref: RefObject<HTMLDivElement>, isOpen: boolean, modalRootSelector: string) => {
	const handleUnresolvedPosition = useHandleUnresolvedPosition(isOpen)

	useResolvePositionOnUserInteraction(ref, handleUnresolvedPosition, isOpen, modalRootSelector)

	if (ref.current) {
		establishDefaultCSSSize(ref.current)
	}

	if (ref.current) {
		fixSubpixelTranslation(ref.current)
	}
}

const useResolvePositionOnUserInteraction = (
	ref: RefObject<HTMLDivElement>,
	resolve: (element: HTMLDivElement) => void,
	isOpen: boolean,
	rootSelector: string
) => {
	useLayoutEffect(() => {
		const modalRef = ref.current

		if (!modalRef || !isOpen) {
			return
		}

		const resolveDesktopView = () => {
			if (topWindow.innerWidth <= MOBILE_WIDTH) {
				return false
			}
			resolve(modalRef)
			return true
		}
		const resizingWindow = getEventListenerTarget(topWindow, 'resize')
		const clickingOnModal = getEventListenerTarget(modalRef, 'pointerdown')
		addSelfRemovingEventListeners([resizingWindow, clickingOnModal], resolveDesktopView)
	}, [isOpen, rootSelector])
}

const getEventListenerTarget = (element: EventTarget, type: string) => ({ element, type })

//Eslint rule is removed in next version
const addSelfRemovingEventListeners = (targets: { element: EventTarget; type: string }[], listener: () => boolean) => {
	const selfRemovingListener = () => {
		const shallRemove = listener()
		shallRemove && targets.forEach(({ element, type }) => element.removeEventListener(type, selfRemovingListener)) // eslint-disable-line
	}

	targets.forEach(({ element, type }) => element.addEventListener(type, selfRemovingListener)) // eslint-disable-line
}

const useHandleUnresolvedPosition = (isOpen: boolean) => {
	const isUnresolvedPosition = useResolvedPositionFlag(isOpen)
	const resolvePosition = (element: HTMLDivElement) => {
		establishAbsolutePositioning(element)
		clearTransformation(element)
		isUnresolvedPosition.current = false
	}
	const handleUnresolvedPosition = (element: HTMLDivElement) => isUnresolvedPosition.current && resolvePosition(element)

	return handleUnresolvedPosition
}

const useResolvedPositionFlag = (isOpen: boolean) => {
	const isUnresolvedPosition = useRef(true)

	useEffect(() => {
		if (!isOpen) {
			isUnresolvedPosition.current = true
		}
	}, [isOpen])

	return isUnresolvedPosition
}

const establishAbsolutePositioning = (element: HTMLDivElement) => {
	const translation = extractTranslation(element)
	const size = extractModalSize(element)

	const style = element.style

	style.setProperty(MODAL_LEFT, formatPixel(translation.x))
	style.setProperty(MODAL_TOP, formatPixel(translation.y))

	style.setProperty(MODAL_WIDTH, formatPixel(size.width))
	style.setProperty(MODAL_HEIGHT, formatPixel(size.height))

	style.left = RESTRICTED_MODAL_LEFT
	style.top = RESTRICTED_MODAL_TOP
}

// Fallback if css does not support 'round()'
const fixSubpixelTranslation = (element: HTMLDivElement) => {
	const translation = extractTranslation(element)
	const subpixelTranslationX = translation.x % 1
	const subpixelTranslationY = translation.y % 1

	if (subpixelTranslationX || subpixelTranslationY) {
		const prevTranslate = formatTranslation(translation.x, translation.y)
		const subPixelCompensation = formatTranslation(subpixelTranslationX, subpixelTranslationY)

		element.style.transform = prevTranslate + ' ' + subPixelCompensation
	}
}

const establishDefaultCSSSize = (element: HTMLDivElement) => {
	const style = element.style
	const size = extractModalSize(element)

	updateStyleIfDifferent(style, MODAL_WIDTH, formatPixel(size.width))
	updateStyleIfDifferent(style, MODAL_HEIGHT, formatPixel(size.height))
}

// Utils

const extractTranslation = (element: HTMLDivElement) => {
	const transform = window.getComputedStyle(element).transform
	const x = parseInt(transform.split(', ')[4])
	const y = parseInt(transform.split(', ')[5])

	return { x, y }
}
const extractModalSize = (element: HTMLDivElement) => {
	const width = element.getBoundingClientRect().width
	const height = element.getBoundingClientRect().height

	return { width, height }
}
const formatPixel = (number: number) => `${number}px`
const clearTransformation = (element: HTMLDivElement) => (element.style.transform = formatTranslation(0, 0))
const formatTranslation = (x: number, y: number) => `translate(${x}px, ${y}px)`

const updateStyleIfDifferent = (style: CSSStyleDeclaration, property: string, value: string) =>
	value !== style.getPropertyValue(property) && style.setProperty(property, value)

// Consts

const MODAL_LEFT = '--left'
const MODAL_TOP = '--top'
const MODAL_WIDTH = '--width'
const MODAL_HEIGHT = '--height'

const MIN_POSITION = '0px'
const MAX_LEFT_POSITION = `calc(100% - var(${MODAL_WIDTH}))`
const MAX_TOP_POSITION = `calc(100% - var(${MODAL_HEIGHT}))`
const RESTRICTED_MODAL_LEFT = `clamp(${MIN_POSITION}, var(${MODAL_LEFT}), ${MAX_LEFT_POSITION})`
const RESTRICTED_MODAL_TOP = `clamp(${MIN_POSITION}, var(${MODAL_TOP}), ${MAX_TOP_POSITION})`
