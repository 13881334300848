import { Button, Text } from '@genusbiz/web-ui/controls'
import { createStyle } from '@genusbiz/web-ui/theming'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { e_ScalingEventType } from 'src/enums/e_ScalingEvent'
import { e_UpDueToReason } from 'src/enums/e_UpDueToReason'
import { userSettingsSelectors } from 'src/features/UserSettings/duck/userSettingsSelectors'
import { IEnvironmentResourceOverview } from 'src/interfaces/IEnvironmentResourceOverview'
import { CreateTempAvailabilityGroup } from './AvailabilityWindowEditor/CreateTempAvailabilityGroup'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { kubernetesActions } from 'src/features/Kubernetes/duck/kubernetesActions'
import { IEnvironmentAvailability } from 'src/interfaces/IAvailabilityWindow'
import { e_EnvironmentAvailabilityType } from 'src/enums/e_EnvironmentAvailabilityType'
import { defaultColors } from 'src/consts/defaultColors'

const classes = createStyle({
	buttonGroup: {
		display: 'flex',
		flexDirection: 'column',
		width: '200px',
	},
	warningButton: {
		borderColor: defaultColors.errorText,
		backgroundColor: defaultColors.errorBackground,
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '8px',
		marginTop: '8px',
	},
})
interface IProps {
	environmentResourceOverview: IEnvironmentResourceOverview
	selectedEnvironment: e_EnvironmentOperatingType
	environmentAvailability: IEnvironmentAvailability
	selectedAvailabilityType: e_EnvironmentAvailabilityType
}

export const EnvironmentAvailabilityStatus = (props: IProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const preferredLanguage = useSelector(userSettingsSelectors.selectPreferredLanguge)

	const preferredFormattingCulture = useSelector(userSettingsSelectors.selectPreferredFormattingCulture)
	const nextScalingEvent = props.environmentResourceOverview.nextAvailabilityEvent
	const nextScalingEventAt = nextScalingEvent && moment(props.environmentResourceOverview.nextAvailabilityEvent.at)
	const nextScalingEventType = nextScalingEvent && nextScalingEvent.typeOfEvent
	const upDueToReason = props.environmentResourceOverview.upDueToReason
	const confirmTurnOffEnvironment = () => {
		dispatch(
			modalManagerActions.showConfirmationModal({
				title: t('AVAILABILITY_WINDOW:CONFIRM_TURN_OFF'),
				confirmText: t('GENERAL:YES'),
				cancelText: t('GENERAL:CANCEL'),
				message: t('AVAILABILITY_WINDOW:CONFIRM_TURN_OFF_DESC'),
				onConfirm: () => {
					dispatch(kubernetesActions.turnOffEnvironment(props.selectedEnvironment))
				},
			})
		)
	}
	const noAvailabilityWindowsExists = props.environmentAvailability.availabilityGroups.length === 0
	return (
		<div className={classes.content}>
			{props.selectedAvailabilityType !== e_EnvironmentAvailabilityType.SCHEDULE && (
				<Text variant="body1">{t('AVAILABILITY_WINDOW:' + props.selectedAvailabilityType + '_AVAILABLE_DESC')}</Text>
			)}
			{props.selectedAvailabilityType === e_EnvironmentAvailabilityType.SCHEDULE &&
				props.environmentResourceOverview && (
					<>
						{noAvailabilityWindowsExists && (
							<Text variant="body1">{t('AVAILABILITY_WINDOW:NO_AVAILABILITY_GROUPS')}</Text>
						)}
						{!noAvailabilityWindowsExists && (
							<>
								{props.environmentResourceOverview.shouldBeUp && (
									<Text variant="body1">
										{t('AVAILABILITY_WINDOW:ENVIRONMENT_IS_CURRENTLY') + ' ' + t('AVAILABILITY_WINDOW:UP') + '.'}
									</Text>
								)}
								{!props.environmentResourceOverview.shouldBeUp && (
									<Text variant="body1">
										{t('AVAILABILITY_WINDOW:ENVIRONMENT_IS_CURRENTLY') + ' ' + t('AVAILABILITY_WINDOW:DOWN') + '.'}
									</Text>
								)}

								{nextScalingEvent && upDueToReason !== e_UpDueToReason.operatingRoleSwitchBuffering && (
									<Text variant="body1">
										{nextScalingEventType === e_ScalingEventType.down
											? t('AVAILABILITY_WINDOW:SCHEDULED_TO_GO_DOWN')
											: t('AVAILABILITY_WINDOW:SCHEDULED_TO_GO_UP')}{' '}
										<b>{moment().locale(preferredLanguage).to(nextScalingEventAt)}</b> (
										{moment(nextScalingEventAt).locale(preferredFormattingCulture).format('LLLL')}).
									</Text>
								)}
								{!noAvailabilityWindowsExists && !nextScalingEvent && (
									<Text variant="body1">{t('AVAILABILITY_WINDOW:NOT_SCHEDULED_TO_COME_UP')}</Text>
								)}
							</>
						)}
						<div className={classes.buttonGroup}>
							<CreateTempAvailabilityGroup
								selectedEnvironment={props.selectedEnvironment}
								label={
									props.environmentResourceOverview.shouldBeUp
										? t('AVAILABILITY_WINDOW:EXTEND_UPTIME')
										: t('AVAILABILITY_WINDOW:START_NOW')
								}
							/>
							{props.environmentResourceOverview.shouldBeUp && (
								<Button
									style={{ marginTop: '8px' }}
									label={t('AVAILABILITY_WINDOW:SHUT_DOWN')}
									onClick={() => confirmTurnOffEnvironment()}
									className={classes.warningButton}
									screenTip={t('AVAILABILITY_WINDOW:CONFIRM_TURN_OFF_DESC')}
								/>
							)}
						</div>
					</>
				)}
		</div>
	)
}
