import React from 'react'
import classNames from 'clsx'

import { createStyle } from '../../../theming'
import { Spinner } from '../../Spinner'

// Enums and Interfaces
import type { ITableRowData, ITableTreeData } from '../Table.types'

const classes = createStyle({
	spinnerOverlay: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	centeredSpinner: { flex: 1 },
	alignStretch: { alignSelf: 'stretch' },
})

export const SuspenseFallback = (props: ITableRowData | ITableTreeData) => {
	return (
		<div
			className={classNames(classes.spinnerOverlay, {
				[classes.centeredSpinner]: props.width === 'stretch' || props.width === 'fitToContents',
				[classes.alignStretch]: props.height === 'stretch' || props.height === 'fitToContents',
			})}
			style={{
				width: typeof props.width === 'number' ? props.width : undefined,
				height: typeof props.height === 'number' ? props.height : undefined,
			}}
		>
			<Spinner />
		</div>
	)
}
