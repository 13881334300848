import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { operatorFrontendSelectors } from 'src/features/OperatorFrontend/duck/operatorFrontendSelectors'
import { IDeployment } from 'src/interfaces/IDeployment'
import { IPod } from 'src/interfaces/IPod'
import { IStatefulSet } from 'src/interfaces/IStatefulSet'
import { operatorApi } from 'src/modules/operatorApi'
import { WorkloadCommandBar } from './WorkloadCommandBar'
import { WorkloadControllerTable } from './WorkloadControllers/WorkloadControllerTable'
import sortByValue from 'src/utils/sortByValue'
import { Spinner, TabControlHeader, TabControlHeaderItem } from '@genusbiz/web-ui/controls'
import { createStyle } from '@genusbiz/web-ui/theming'
import { useTranslation } from 'react-i18next'
import { e_TableContentType } from 'src/enums/e_TableContentType'
import { IJob } from 'src/interfaces/IJob'
import { e_ReplicaExplanation } from 'src/enums/e_ReplicaExplanation'
import { e_ResourceKind } from 'src/enums/e_ResourceKinds'
import ClusterDiagramContainer from '../ClusterDiagram/ClusterDiagramContainer'
import { IWorkloadsResourcePlan } from 'src/interfaces/IWorkloadsResourcePlan'
import { PodTable } from './Pods/PodTable'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'

const classes = createStyle({
	header: { margin: '8px 0px 8px 8px' },
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	spinner: {
		margin: ' 0 auto',
	},
})

export const Workloads = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [tableFilter, setTableFilter] = useState<e_TableContentType>(e_TableContentType.pods)
	const selectedK8sRuntimeName = useSelector(operatorFrontendSelectors.selectSelectedK8sRuntimeName)
	const [allWorkloadControllers, setAllWorkloadControllers] = useState<IWorkloadController[]>([])
	const [pods, setPods] = useState<IPod[]>([])
	const [workloadsResourcePlan, setWorkloadsResourcePlan] = useState<IWorkloadsResourcePlan>()
	const [showSpinner, setShowSpinner] = useState<boolean>(false)

	/**
	 *  Pods
	 */
	useEffect(() => {
		if (selectedK8sRuntimeName && tableFilter === e_TableContentType.pods) {
			fetchPods().catch((err) => console.log(err)) // eslint-disable-line no-console
		}
	}, [selectedK8sRuntimeName, tableFilter])
	const fetchPods = (clearBeforeRefresh: boolean = true) => {
		return new Promise<void>((resolve, reject) => {
			if (!selectedK8sRuntimeName) {
				return
			}
			if (clearBeforeRefresh) {
				setShowSpinner(true)
				setPods([])
			}
			operatorApi
				.fetchOperatorPods(selectedK8sRuntimeName)
				.then((response: IPod[]) => {
					const pods: IPod[] = response.map((pod) => {
						return {
							...pod,
							kind: e_ResourceKind.pod,
							readyCount: pod.status.containerStatuses
								? pod.status.containerStatuses.filter((containerStatus) => containerStatus.ready).length
								: 0,
							restartCount: pod.status.containerStatuses.reduce(
								(accumulator, containerStatus) => accumulator + containerStatus.restartCount,
								0
							),
						}
					})
					setPods(pods)
					resolve()
				})
				.catch((err) => {
					dispatch(modalManagerActions.showErrorModal(err as Error))
					reject()
				})
				.finally(() => {
					setShowSpinner(false)
				})
		})
	}

	/**
	 *  Workload Controllers
	 */
	useEffect(() => {
		if (selectedK8sRuntimeName && tableFilter === e_TableContentType.workloadControllers) {
			fetchWorkloadControllers().catch((err) => console.log(err)) // eslint-disable-line no-console
		}
	}, [selectedK8sRuntimeName, tableFilter])

	const fetchWorkloadControllers = (emptyBeforeRefresh: boolean = true) => {
		return new Promise<void>((resolve, reject) => {
			if (!selectedK8sRuntimeName) {
				return
			}
			if (emptyBeforeRefresh) {
				setShowSpinner(true)
				setAllWorkloadControllers([])
			}
			const operatorApiPromises: [
				Promise<IWorkloadsResourcePlan>,
				Promise<IDeployment[]>,
				Promise<IStatefulSet[]>,
				Promise<IJob[]>,
			] = [
				operatorApi.fetchOperatorWorkloadsResourcePlan(),
				operatorApi.fetchOperatorDeployments(selectedK8sRuntimeName),
				operatorApi.fetchOperatorStatefulSets(selectedK8sRuntimeName),
				operatorApi.fetchOperatorJobs(selectedK8sRuntimeName),
			]
			Promise.all(operatorApiPromises)
				.then((apiResponseArray) => {
					const workloadsResourcePlan = apiResponseArray[0] || {}
					setWorkloadsResourcePlan(workloadsResourcePlan)
					const deployments = apiResponseArray[1].map((deployment) => {
						return {
							...deployment,
							kind: e_ResourceKind.deployment,
							includedInWorkloadsResourcePlan: workloadsResourcePlan.scalableResourceConfigurations.some(
								(resourceConfig) => resourceConfig.name === deployment.name
							),
							desiredReplicas: deployment.replicaInfo.current.values.replicas || 0,
							availableReplicas: deployment.replicaInfo.current.values.availableReplicas || 0,
							unavailableReplicas: deployment.replicaInfo.current.values.unavailableReplicas || 0,
							readyReplicas: deployment.replicaInfo.current.values.readyReplicas || 0,
							updatedReplicas: deployment.replicaInfo.current.values.updatedReplicas || 0,
							replicaExplanation: deployment.replicaInfo.current.explanation || e_ReplicaExplanation.undefined,
						}
					})

					const statefulSets = apiResponseArray[2].map((statefulSet) => {
						return {
							...statefulSet,
							kind: e_ResourceKind.statefulSet,
							includedInWorkloadsResourcePlan: workloadsResourcePlan.scalableResourceConfigurations.some(
								(resourceConfig) => resourceConfig.name === statefulSet.name
							),
							desiredReplicas: statefulSet.replicaInfo.current.values.replicas || 0,
							availableReplicas: statefulSet.replicaInfo.current.values.availableReplicas || 0,
							unavailableReplicas: statefulSet.replicaInfo.current.values.unavailableReplicas || 0,
							readyReplicas: statefulSet.replicaInfo.current.values.readyReplicas || 0,
							updatedReplicas: statefulSet.replicaInfo.current.values.updatedReplicas || 0,
							replicaExplanation: statefulSet.replicaInfo.current.explanation || e_ReplicaExplanation.undefined,
						}
					})

					const jobs = apiResponseArray[3].map((job) => {
						return {
							...job,
							kind: e_ResourceKind.job,
							active: job.status.active || 0,
							completionTime: job.status.completionTime,
							failed: job.status.failed || 0,
							succeeded: job.status.succeeded || 0,
						}
					})

					const rows = [...deployments, ...statefulSets, ...jobs]
					const sortedRows = sortByValue(rows, 'name')
					setAllWorkloadControllers(sortedRows)
					resolve()
				})
				.catch((error) => {
					console.log(error) // eslint-disable-line no-console
					dispatch(modalManagerActions.showErrorModal(error as Error))
					setAllWorkloadControllers([])
					reject()
				})
				.finally(() => {
					setShowSpinner(false)
				})
		})
	}

	return (
		<>
			<WorkloadCommandBar
				reload={(emptyBeforeRefresh?: boolean) => {
					if (selectedK8sRuntimeName && tableFilter === e_TableContentType.pods) {
						fetchPods(emptyBeforeRefresh).catch((err) => console.log(err)) // eslint-disable-line no-console
					}
					if (selectedK8sRuntimeName && tableFilter === e_TableContentType.workloadControllers) {
						fetchWorkloadControllers(emptyBeforeRefresh).catch((err) => console.log(err)) // eslint-disable-line no-console
					}
				}}
				tableFilter={tableFilter}
			/>
			<div className={classes.container}>
				<h2 className={classes.header}>{selectedK8sRuntimeName}</h2>
				<TabControlHeader>
					<TabControlHeaderItem
						isActive={tableFilter === e_TableContentType.pods}
						text={t('GENERAL:PODS')}
						onActivate={() => setTableFilter(e_TableContentType.pods)}
					/>
					<TabControlHeaderItem
						isActive={tableFilter === e_TableContentType.workloadControllers}
						text={`${t('GENERAL:WORKLOAD_CONTROLLERS')}`}
						onActivate={() => setTableFilter(e_TableContentType.workloadControllers)}
					/>
					<TabControlHeaderItem
						isActive={tableFilter === e_TableContentType.clusterDiagram}
						text={t('GENERAL:DIAGRAM')}
						onActivate={() => setTableFilter(e_TableContentType.clusterDiagram)}
					/>
				</TabControlHeader>
				{showSpinner && <Spinner className={classes.spinner} />}
				{!showSpinner && (
					<>
						{tableFilter === e_TableContentType.pods && (
							<PodTable pods={pods} k8sRuntimeName={selectedK8sRuntimeName || ''} />
						)}
						{tableFilter === e_TableContentType.workloadControllers && workloadsResourcePlan && (
							<WorkloadControllerTable
								allWorkloadControllers={allWorkloadControllers}
								k8sRuntimeName={selectedK8sRuntimeName}
								refresh={() => fetchWorkloadControllers(false)}
								workloadsResourcePlan={workloadsResourcePlan}
							/>
						)}
						{tableFilter === e_TableContentType.clusterDiagram && (
							<ClusterDiagramContainer
								pods={pods}
								workloadControllers={allWorkloadControllers}
								k8sRuntimeName={selectedK8sRuntimeName}
							/>
						)}
					</>
				)}
			</div>
		</>
	)
}
