import clsx from 'clsx'
import React from 'react'
import { useTranslation } from '../../translation'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	readOnlyIndicator: {
		textDecoration: 'unset',
		marginLeft: '2px',
		marginRight: '2px',
	},
	readOnlyIndicatorAlignLeft: {
		alignSelf: 'center',
		paddingLeft: theme.controls.input.padding,
		paddingRight: '4px',
	},
	readOnlyIndicatorAlignRight: {
		alignSelf: 'center',
		paddingLeft: '4px',
		paddingRight: theme.controls.input.padding,
	},
	readOnlyIndicatorNotVisible: {
		visibility: 'hidden',
	},
}))

interface IReadOnlyIndicatorProps {
	isReadOnly: boolean
	alwaysVisible?: boolean
	customTitle?: string
	alignLeft?: boolean
	alignRight?: boolean
}

export const ReadOnlyIndicator = (props: IReadOnlyIndicatorProps) => {
	const { tcvi } = useTranslation()

	if (!props.isReadOnly && !props.alwaysVisible) {
		return null
	}

	return (
		<i
			className={clsx(
				'Basic-Padlock',
				classes.readOnlyIndicator,
				!props.isReadOnly && classes.readOnlyIndicatorNotVisible,
				props.alignLeft && classes.readOnlyIndicatorAlignLeft,
				props.alignRight && classes.readOnlyIndicatorAlignRight
			)}
			title={
				props.isReadOnly
					? props.customTitle || tcvi('GENERAL:THIS_FIELD_IS_READ_ONLY_AND_CANNOT_BE_MODIFIED')
					: undefined
			}
		/>
	)
}
