import React, { useMemo } from 'react'
import {
	gridCellNameKey,
	type IStrictGridAreaProps,
	type GridAreaElement as CellElement,
	type IGridLayout,
	type IGridArea,
} from './gridTypes'

const getCellName = (element: CellElement) => element.props[gridCellNameKey]

const isElementName = (areaName: string, cell: CellElement) => getCellName(cell) === areaName

const getAreaNames = (layout: IGridLayout) => Object.keys(layout.areas)

//---- Element Filter

const filterValidCellElements = (children?: React.ReactNode | React.ReactNode[]) =>
	React.Children.toArray(children).filter((child) => React.isValidElement<IStrictGridAreaProps>(child)) as CellElement[]

//---- Cell Filter

const layoutContainsCell = (layout: IGridLayout, element: CellElement) =>
	getAreaNames(layout).find((areaName) => isElementName(areaName, element))

const filterCurrentLayoutCells = (cells: CellElement[], layout: IGridLayout) =>
	cells.filter((child) => layoutContainsCell(layout, child))

//---- Sorter

const findCellIndex = (cell: CellElement, layout: IGridLayout) =>
	getAreaNames(layout).findIndex((name) => name === getCellName(cell))

const getCellIndexComparator = (layout: IGridLayout) => (a: CellElement, b: CellElement) =>
	findCellIndex(a, layout) - findCellIndex(b, layout)

const sortCells = (cells: CellElement[], layout: IGridLayout) => cells.toSorted(getCellIndexComparator(layout))

//---- Styler

const formatTrackStart = (number?: number) => (typeof number === 'number' ? `${number}` : undefined)
const formatTrackSpan = (number?: number) => (typeof number === 'number' ? `span ${number}` : undefined)

const computeGridTrackProperties = (area: IGridArea) => ({
	gridRowStart: formatTrackStart(area.row),
	gridColumnStart: formatTrackStart(area.col),
	gridRowEnd: formatTrackSpan(area.rowSpan),
	gridColumnEnd: formatTrackSpan(area.colSpan),
})

// min-width and -height defaults to auto, thus content size overrides specified fraction size if larger
// https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
const SHARED_CELL_TRACK_PROPERTIES = {
	overflow: 'hidden',
	minWidth: '0',
	minHeight: '0',
}

const computeCellStyle = (cell: CellElement, layout: IGridLayout) => {
	const name = getCellName(cell)
	const cellDefinition = layout.areas[name]
	const trackProperties = cellDefinition && computeGridTrackProperties(cellDefinition)
	const oldStyle = cell.props.style

	const style = {
		...SHARED_CELL_TRACK_PROPERTIES,
		...oldStyle,
		...trackProperties,
	}
	return style
}

const computeCellProps = (cell: CellElement, layout: IGridLayout) => ({
	style: computeCellStyle(cell, layout),
})

const getCellLayoutUpdater = (layout: IGridLayout) => (cell: CellElement) =>
	React.cloneElement(cell, computeCellProps(cell, layout))

const styleCells = (cells: CellElement[], layout: IGridLayout) => cells.map(getCellLayoutUpdater(layout))

export const useStyledCells = (children?: React.ReactNode | React.ReactNode[], layout?: IGridLayout) =>
	useMemo(() => {
		if (!layout) {
			return []
		}
		const validElements = filterValidCellElements(children)
		const LayoutCells = filterCurrentLayoutCells(validElements, layout)
		const sortedCells = sortCells(LayoutCells, layout)

		const styledChildren = styleCells(sortedCells, layout)

		return styledChildren
	}, [layout, children])
