import i18n from 'i18next'
import moment from 'moment'
import { initReactI18next } from 'react-i18next'
import { setFormattingLocale } from '@genusbiz/web-ui/controls/utils/setFormattingLocale'
import { e_StandardTimezones } from 'src/enums/e_StandardTimezones'
declare module 'i18next' {
	interface CustomTypeOptions {
		returnNull: false
	}
}

const nb = require('./nb-NO.json')
const en = require('./en-US.json')

// the translations
const resources = {
	en,
	nb,
}
const initialize = (
	preferredLanguage = 'en',
	preferredFormattingCulture = 'en',
	preferredTimezone = e_StandardTimezones.UTC.toString()
) => {
	return new Promise((resolve, reject) => {
		changeFormattingCulture(preferredFormattingCulture as 'nb' | 'en-gb' | 'en')
		changeTimezone(preferredTimezone)

		i18n
			.use(initReactI18next) // passes i18n down to react-i18next
			.init({
				resources,
				returnNull: false,
				lng: preferredLanguage, // if you're using a language detector, do not define the lng option
				fallbackLng: 'en',

				interpolation: {
					escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
				},
			})
			.then(resolve)
			.catch(reject)
	})
}

const translate = (key: string) => {
	return i18n.t(key)
}

const changeLanguage = (language: 'nb' | 'en') => {
	return new Promise((resolve, reject) => {
		i18n.changeLanguage(language).then(resolve).catch(reject)
	})
}

const getLanguage = () => {
	return i18n.language
}

const changeFormattingCulture = (formattingCultureLocale: 'nb' | 'en-gb' | 'en') => {
	moment.locale(formattingCultureLocale)
	setFormattingLocale(formattingCultureLocale)
}

const changeTimezone = (timezone: string) => {
	timezone = timezone.replaceAll(' ', '_')
	moment.tz.setDefault(timezone)

	const lang = getLanguage() as 'nb' | 'en'
	lang && void changeLanguage(lang)
}

const translator = {
	initialize,
	translate,
	changeLanguage,
	getLanguage,
	changeFormattingCulture,
	changeTimezone,
}

export { translator }
