import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createStyle } from '@genusbiz/web-ui/theming'
import { Icon, RadioButtonGroup, Text } from '@genusbiz/web-ui/controls'
import { Card } from '@genusbiz/web-ui/surfaces'

import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { kubernetesActions } from 'src/features/Kubernetes/duck/kubernetesActions'
import { useTranslation } from 'react-i18next'
import { e_MenuItemType, MenuItem } from '@genusbiz/web-ui/controls/Menu'
import { OperatorCommandBar } from '../CommandBar/OperatorCommandBar'
import { DATA_RELOAD_INTERVAL } from 'src/consts/constants'
import { operatorApi } from 'src/modules/operatorApi'
import { IEnvironmentResourceOverview } from 'src/interfaces/IEnvironmentResourceOverview'
import { AxiosError } from 'axios'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { BufferConfigurationModal } from './BufferConfigurationModal'
import { useLocation, useNavigate } from 'react-router-dom'
import { capitalize } from 'src/utils/capitalize'
import { defaultColors } from 'src/consts/defaultColors'
import { e_EnvironmentAvailabilityType } from 'src/enums/e_EnvironmentAvailabilityType'
import { EnvironmentAvailabilityStatus } from './EnvironmentAvailabilityStatus'
import { EnvironmentAvailabilitySchedule } from './EnvironmentAvailabilitySchedule'
import classNames from 'clsx'

const classes = createStyle({
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		padding: '8px',
		height: '100%',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	statusIcon: {
		marginLeft: '8px',
		padding: '2px',
	},
	availabilityScheduling: {
		display: 'flex',
		flexDirection: 'column',
	},
	buttonGroup: {
		flex: 1,
		flexDirection: 'row',
		display: 'flex',
	},
	button: {
		width: '150px',
		margin: '8px',
	},
	availabilityWindows: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
	},
	availabilityToggle: { display: 'flex', flexDirection: 'row', padding: '8px', marginTop: '1em' },
	availabilityTypeButtons: { marginLeft: '4px' },
})

export const EnvironmentAvailability = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useLocation()
	const navigate = useNavigate()

	// Set selected environment based on the last part of the path
	const environmentTypeFromPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
	const selectedEnvironment =
		e_EnvironmentOperatingType[environmentTypeFromPath as keyof typeof e_EnvironmentOperatingType]

	const environmentAvailability = useSelector(
		kubernetesSelectors.selectCurrentEnvironmentAvailability(selectedEnvironment)
	)
	const environmentResourceOverview = useSelector(
		kubernetesSelectors.selectCurrentEnvironmentResourceOverview(selectedEnvironment)
	)

	const setAlwaysUp = (isAlwaysUp: boolean) => {
		dispatch(kubernetesActions.setAlwaysUp(isAlwaysUp, selectedEnvironment))
	}

	const commandBarItems: MenuItem[] = []

	const [selectedAvailabilityType, setSelectedAvailabilityType] = useState<e_EnvironmentAvailabilityType>(
		e_EnvironmentAvailabilityType.SCHEDULE
	)

	const handleUpdateError = (error: AxiosError) => {
		dispatch(modalManagerActions.showErrorModal(error))
	}
	const [isBufferConfigurationOpen, setIsBufferConfigurationOpen] = useState(false)

	const updateResourceOverview = async (environment: e_EnvironmentOperatingType) => {
		const data = await operatorApi.fetchEnvironmentResourceOverview(environment)
		const resourceOverview = data as IEnvironmentResourceOverview
		dispatch(kubernetesActions.setEnvironmentResourceOverview(environment, resourceOverview))
	}

	useEffect(() => {
		let defaultSelectedAvailabilityType: e_EnvironmentAvailabilityType
		if (environmentAvailability.isAlwaysUp) {
			defaultSelectedAvailabilityType = e_EnvironmentAvailabilityType.ALWAYS
		} else {
			defaultSelectedAvailabilityType = e_EnvironmentAvailabilityType.SCHEDULE
		}
		setSelectedAvailabilityType(defaultSelectedAvailabilityType)
	}, [environmentAvailability])

	useEffect(() => {
		let resourceOverviewReloadTimer: NodeJS.Timeout

		if (selectedEnvironment !== undefined) {
			updateResourceOverview(selectedEnvironment).catch((error) => {
				handleUpdateError(error)
			})

			resourceOverviewReloadTimer = setInterval(() => {
				updateResourceOverview(selectedEnvironment).catch((error) => {
					handleUpdateError(error)
					clearInterval(resourceOverviewReloadTimer)
				})
			}, DATA_RELOAD_INTERVAL * 1000)
		}

		return () => {
			clearInterval(resourceOverviewReloadTimer)
		}
	}, [selectedEnvironment])

	commandBarItems.push({
		type: e_MenuItemType.action,
		name: t('AVAILABILITY_WINDOW:BUFFER_CONFIG'),
		iconClassName: 'Fluent-BufferTimeBoth',
		onClick: () => {
			setIsBufferConfigurationOpen(true)
		},
	})

	// If the path does not point to a valid environment, redirect to active
	if (selectedEnvironment === undefined) {
		navigate('/availability/active')
		return null
	}

	return (
		<>
			<OperatorCommandBar items={commandBarItems} />
			{isBufferConfigurationOpen && <BufferConfigurationModal setIsBufferModalOpen={setIsBufferConfigurationOpen} />}
			<Card className={classes.container}>
				<div className={classes.header}>
					<Text variant="h2" style={{ marginTop: '0.5em', marginBottom: '0.5em' }}>
						{capitalize(t('GENERAL:ENVIRONMENT'))}:{' '}
						{t('GENERAL:' + e_EnvironmentOperatingType[selectedEnvironment].toUpperCase())}
					</Text>
					{environmentResourceOverview &&
						(environmentResourceOverview.shouldBeUp ? (
							<Icon
								className={classNames(classes.statusIcon, 'status-icon-up')}
								iconClassName="Fluent-SkypeCircleCheck"
								size="size24"
								screenTip={t('AVAILABILITY_WINDOW:ENVIRONMENT_IS_CURRENTLY') + ' ' + t('AVAILABILITY_WINDOW:UP')}
								color={defaultColors.successText}
							/>
						) : (
							<Icon
								className={classNames(classes.statusIcon, 'status-icon-down')}
								iconClassName="Fluent-DrillDownSolid"
								screenTip={t('AVAILABILITY_WINDOW:ENVIRONMENT_IS_CURRENTLY') + ' ' + t('AVAILABILITY_WINDOW:DOWN')}
								size="size24"
								color="gray"
							/>
						))}
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '8px' }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'start',
							width: '100%',
						}}
					>
						<Text variant="h3">{t('AVAILABILITY_WINDOW:AVAILABILITY')}</Text>
						{
							<RadioButtonGroup
								value={selectedAvailabilityType}
								className={classes.availabilityTypeButtons}
								onChange={(value) => {
									switch (value) {
										case e_EnvironmentAvailabilityType.ALWAYS:
											setAlwaysUp(true)
											setSelectedAvailabilityType(value)
											break
										case e_EnvironmentAvailabilityType.SCHEDULE:
											setAlwaysUp(false)
											setSelectedAvailabilityType(value)
											break
									}
								}}
								options={Object.keys(e_EnvironmentAvailabilityType).map((type) => {
									return {
										label: t('AVAILABILITY_WINDOW_TYPE:' + type),
										value: type,
									}
								})}
							/>
						}
						{environmentResourceOverview && (
							<div>
								<EnvironmentAvailabilityStatus
									selectedEnvironment={selectedEnvironment}
									environmentResourceOverview={environmentResourceOverview}
									environmentAvailability={environmentAvailability}
									selectedAvailabilityType={selectedAvailabilityType}
								/>
								{selectedAvailabilityType === e_EnvironmentAvailabilityType.SCHEDULE && (
									<EnvironmentAvailabilitySchedule
										selectedEnvironment={selectedEnvironment}
										environmentAvailability={environmentAvailability}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			</Card>
		</>
	)
}
