import { getWorkloadResourceStatusColorType } from 'src/components/Workloads/getWorkloadResourceStatusColorType'
import { defaultColors } from 'src/consts/defaultColors'
import { ID3ClusterDiagramEdge, ID3ClusterDiagramNode, ID3ClusterDiagramPod } from 'src/interfaces/IClusterDiagram'

export const universalStyling = {
	nodeBackgroundColor: '#FFFFFF',
	boldFontWeight: '600',
	regularFontWeight: '400',
}

const podStatusToColorMapper = {
	error: defaultColors.errorBackground,
	warning: defaultColors.warningBackground,
	success: defaultColors.successBackground,
	running: '#19AB81',
}

const edgeStatusToColorMapper = {
	MUTUAL_UNIVERSAL_SELECTION: '#CCCCCC',
	UNIVERSAL_INGRESS_SPECIFIC_EGRESS: '#CCCCCC',
	UNIVERSAL_EGRESS_SPECIFIC_INGRESS: '#CCCCCC',
	MUTUAL_SPECIFIC_SELECTION: '#CCCCCC',
	UNIVERSAL_INGRESS_NO_EGRESS: '#CCCCCC',
	UNIVERSAL_EGRESS_NO_INGRESS: '#CCCCCC',
	SPECIFIC_INGRESS_NO_EGRESS: '#B94E48',
	SPECIFIC_EGRESS_NO_INGRESS: '#B94E48',
	MUTUAL_NO_SELECTION: '#CCCCCC',
	TO_NAMESPACE: '#CCCCCC',
	FROM_NAMESPACE: '#CCCCCC',
}

const nodeTypeToColorMapper = {
	DEPLOYMENT: '#CCCCCC',
	STATEFUL_SET: '#083D77',
	NAMESPACE: '#BD4F6C',
}

const colorRange = (color: string) => ({
	color: color,
	dark: lightenColor(color, -40),
	light: lightenColor(color, 15),
	veryDark: lightenColor(color, -70),
})

export const getColorForNode = (node: ID3ClusterDiagramNode) => colorRange(nodeTypeToColorMapper[node.nodeType])

export const getColorForPod = (pod: ID3ClusterDiagramPod) =>
	colorRange(podStatusToColorMapper[getWorkloadResourceStatusColorType(pod.pod) || 'running'])

export const getColorForEdge = (edge: ID3ClusterDiagramEdge) => colorRange(edgeStatusToColorMapper[edge.edgeType])

/**
 * @param color Must be on the format #RRGGBB
 * @param percent Whole number. Positive number will lighten, negative will darken
 * @returns Modified color
 */
function lightenColor(color: string, percent: number) {
	let R = parseInt(color.substring(1, 3), 16)
	let G = parseInt(color.substring(3, 5), 16)
	let B = parseInt(color.substring(5, 7), 16)

	R = (R * (100 + percent)) / 100
	G = (G * (100 + percent)) / 100
	B = (B * (100 + percent)) / 100

	R = R < 255 ? R : 255
	G = G < 255 ? G : 255
	B = B < 255 ? B : 255

	R = Math.round(R)
	G = Math.round(G)
	B = Math.round(B)

	const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
	const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
	const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

	return '#' + RR + GG + BB
}

export interface IColorRange {
	color: string
	dark: string
	light: string
	veryDark: string
}
