import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import classNames from 'clsx'

// Web UI
import { createStyle } from '@genusbiz/web-ui/theming'
import { WaitIndicator } from './WaitIndicator'

const classes = createStyle({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		justifyContent: 'center',
		alignContent: 'flex-start',
		alignItems: 'center',
		position: 'absolute',
		height: '100%',
		width: '100%',
		zIndex: 2147483647,
		// pointerEvents: 'none', // turn off if we do not want to block
	},

	rootShaded: {
		background: 'rgba(255,255,255, 0.4)',
	},

	spinner: {
		margin: '8px',
	},

	message: {
		marginBottom: '8px',
	},

	buttonContainer: {
		// paddingTop: '8px',
	},

	button: {
		margin: '4px',
		pointerEvents: 'all',
	},
})

interface ISpinnerOverlayProps {
	onCancel?: () => void
	message?: string
}

export const SpinnerOverlay = (props: ISpinnerOverlayProps) => {
	const [displayProgress, setDisplayProgress] = useState(false)

	useEffect(() => {
		const timeoutHandle = window.setTimeout(() => {
			setDisplayProgress(true)
		}, 2000)

		return () => {
			window.clearTimeout(timeoutHandle)
		}
	}, [])

	return (
		<div className={classNames(classes.root, displayProgress && classes.rootShaded)}>
			<WaitIndicator message={props.message} onCancel={props.onCancel} isVisible={displayProgress} />
		</div>
	)
}

SpinnerOverlay.propTypes = {
	classes: PropTypes.object,
	onCancel: PropTypes.func,
}
