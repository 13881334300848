import React from 'react'
import { createStyle } from '../../../theming'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils'

const HORIZONTAL_PLACEMENT_OUTSIDE_USER_VIEW = '-10000px'

const classes = createStyle({
	CustomDragPreview: { transform: `translate(${HORIZONTAL_PLACEMENT_OUTSIDE_USER_VIEW}, 0px)`, position: 'absolute' },
})

const elementID = 'Custom-Preview-Element'

export const getDndPreviewElement = () => {
	const previewElement = document.getElementById(elementID)

	return previewElement
}

export const setDndPreviewText = (text: string, icon?: IIconNames) => {
	const previewElement = getDndPreviewElement()

	if (previewElement) {
		if (icon) {
			previewElement.innerHTML = `<div style="display: flex; align-items: center;"><i class="${icon}" style="font-size: 12px;"></i><span style="margin-left: 5px;">${text}<span></div>`
		} else {
			previewElement.innerText = text
		}
	}
}
const emptyDndPreviewElement = () => setDndPreviewText('')

export const CustomDragPreview = () => {
	document.body.addEventListener('dragend', emptyDndPreviewElement)

	return (
		<div id={elementID} className={classes.CustomDragPreview} aria-hidden="true">
			{/*Empty string avoids delay of adding text node on initial preview*/}
			{''}
		</div>
	)
}
