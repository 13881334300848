import { useRef, useState } from 'react'

export const useEditableItem = <T extends object>() => {
	const [item, setItem] = useState<T>()
	const isDirtyRef = useRef(false)
	const isDirty = isDirtyRef.current

	const setBaseItem = (newItem?: T) => {
		setItem(newItem)
		isDirtyRef.current = false
	}
	const combineValues = (oldItem: T, newValues: Partial<T>) => {
		isDirtyRef.current = true
		return { ...oldItem, ...newValues }
	}
	const edit = (newValues: Partial<T>) => setItem((oldItem) => oldItem && combineValues(oldItem, newValues))

	return { item, isDirty, setBaseItem, edit }
}
