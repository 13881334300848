import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import classNames from 'clsx'

import { createStyle } from '../../../../theming'
import { WaitIndicator } from './WaitIndicator'
import { zIndexes } from '../../../../theming/themeProperties/zIndexes'
import { useTranslation } from '../../../../translation'

const classes = createStyle({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		justifyContent: 'center',
		alignContent: 'flex-start',
		alignItems: 'center',
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: zIndexes.modal + 50,
	},

	rootShaded: {
		background: 'rgba(255,255,255, 0.4)',
	},

	spinner: {
		margin: '8px',
	},

	message: {
		marginBottom: '8px',
	},

	button: {
		margin: '4px',
		pointerEvents: 'all',
	},
})

interface ISpinnerOverlayProps {
	onCancel?: () => void
	message?: string
	delay?: number
}

export const SpinnerOverlay = (props: ISpinnerOverlayProps) => {
	const { tcvi } = useTranslation()

	const [displayProgress, setDisplayProgress] = useState(false)

	const { delay = 700 } = props

	useEffect(() => {
		const timeoutHandle = window.setTimeout(() => {
			setDisplayProgress(true)
		}, delay)

		return () => {
			window.clearTimeout(timeoutHandle)
		}
	}, [])

	return (
		<div className={classNames(classes.root, displayProgress && classes.rootShaded)}>
			<WaitIndicator
				message={props.message || tcvi('GENERAL:WAIT')}
				onCancel={props.onCancel}
				isVisible={displayProgress}
			/>
		</div>
	)
}

SpinnerOverlay.propTypes = {
	classes: PropTypes.object,
	onCancel: PropTypes.func,
}
