import { useLayoutEffect, useRef, useState } from 'react'
import { ID3ClusterDiagramEdge, ID3ClusterDiagramNode, ID3ClusterDiagramPod } from 'src/interfaces/IClusterDiagram'
import { select, drag, forceSimulation, forceCollide, forceCenter, forceLink } from 'd3'
import { IPod } from 'src/interfaces/IPod'
import { drawNodes } from './drawNodes'
import { getColorForEdge, getColorForNode, getColorForPod } from './ClusterDiagramD3Styling'
import { drawEdges } from './drawEdges'
import partition from 'lodash/partition'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'

const ARROW_SIZE = 6

const initalizeForceSimulation = () => {
	return forceSimulation([] as ID3ClusterDiagramNode[]).force(
		'link',
		forceLink().id((d: any) => d.id)
		//.distance(100)
	)
}

export const useDiagramD3 = (
	nodeDataSet: ID3ClusterDiagramNode[] | null,
	edgeDataSet: ID3ClusterDiagramEdge[] | null,
	selectedPods: IPod[],
	setSelectedPods: (pod: IPod[]) => void,
	selectedScaleableResource: IWorkloadController | null,
	setSelectedWorkloadController: (resource: IWorkloadController | null) => void,
	needRefresh: React.MutableRefObject<boolean>,
	animateRefresh: React.MutableRefObject<boolean>
) => {
	const containerElementRef = useRef<HTMLDivElement>(null)
	const forceRef = useRef(initalizeForceSimulation())

	const [hoveredNode, setHoveredNode] = useState<ID3ClusterDiagramNode | null>(null)
	const hoveredNodeRef = useRef<SVGGElement | null>(null)
	const [hoveredPod, setHoveredPod] = useState<ID3ClusterDiagramPod | null>(null)
	const hoveredPodRef = useRef<SVGGElement | null>(null)

	const [isDragging, setIsDragging] = useState(false)

	useLayoutEffect(() => {
		if (!containerElementRef.current || !forceRef.current || !nodeDataSet) {
			return
		}
		const isNodeSelected = (d: ID3ClusterDiagramNode) => selectedScaleableResource?.id === d.id
		const isPodSelected = (d: ID3ClusterDiagramPod) => selectedPods.map((p) => p.id).includes(d.pod.id)

		const { width, height } = containerElementRef.current.getBoundingClientRect()

		const force = forceRef.current

		const existingNodes = force.nodes()
		const existingNodesObj = existingNodes.reduce<{ [id: string]: ID3ClusterDiagramNode }>((obj, node) => {
			obj[node.id] = node
			return obj
		}, {})
		const nodes = nodeDataSet.map((node) => {
			if (node.id in existingNodesObj) {
				const existingNode = existingNodesObj[node.id]
				node.x = existingNode.x
				node.y = existingNode.y
			}
			return node
		})
		const [edgesOnTop, otherEdges] = partition(
			edgeDataSet,
			(d) =>
				d.source.id === selectedScaleableResource?.id ||
				d.target.id === selectedScaleableResource?.id ||
				d.source.id === hoveredNode?.id ||
				d.target.id === hoveredNode?.id
		)
		const edges = [...otherEdges, ...edgesOnTop]

		const wrapper = select(containerElementRef.current)
		const svg = wrapper.select('svg').attr('width', width).attr('height', height)

		addColorsToGraphElements(nodes, edges)

		// Adding arrows for all edge colors, so that each arrow matches their respective edge
		const allEdgeColors = edges.flatMap((edge) => [edge.color!.color, edge.color!.dark, edge.color!.light])
		const uniqueEdgeColors = [...new Set(allEdgeColors)]

		svg
			.select('defs')
			.selectAll('marker')
			.data(uniqueEdgeColors)
			.join((enter) =>
				enter
					.append('marker')
					.attr('id', (d) => d)
					.attr('fill', (d) => d)
					.attr('viewBox', '0 -5 10 10')
					.attr('refX', ARROW_SIZE * 1.5)
					.attr('refY', 0)
					.attr('markerWidth', ARROW_SIZE)
					.attr('markerHeight', ARROW_SIZE)
					.attr('orient', 'auto')
					.append('svg:path')
					.attr('d', 'M0,-5L10,0L0,5')
			)

		const scaleGroup = svg.select<SVGGElement>('g')
		const edgeGroup = scaleGroup.select<SVGGElement>('g#edge-group')
		const nodeGroup = scaleGroup.select<SVGGElement>('g#node-group')
		force
			.nodes(nodes)
			.force('center', forceCenter(width / 2, height / 2).strength(0.2))
			.force(
				'collide',
				forceCollide<ID3ClusterDiagramNode>((d) => d.r + 20 + Math.min(width, height) / nodes.length).strength(0.8)
			)
			.force<d3.ForceLink<ID3ClusterDiagramNode, ID3ClusterDiagramEdge>>('link')!
			.links(edges)

		svg.on('click', () => {
			setSelectedPods([])
			setSelectedWorkloadController(null)
		})

		const { node, squareNode, updateNodePositions } = drawNodes(
			nodeGroup,
			nodes,
			isNodeSelected,
			setSelectedWorkloadController,
			isPodSelected,
			hoveredNode,
			hoveredNodeRef,
			setHoveredNode,
			selectedPods,
			setSelectedPods,
			hoveredPodRef,
			setHoveredPod
		)
		const { updateEdgePositions } = drawEdges(edgeGroup, edges, isNodeSelected, hoveredNode)

		const updatePositions = () => {
			updateNodePositions(width, height)
			updateEdgePositions()
		}
		if (needRefresh.current) {
			if (animateRefresh.current) {
				force.on('tick', updatePositions)
				force.alpha(0.1).alphaTarget(0).restart()
				animateRefresh.current = false
			} else {
				force.stop()
				force.alpha(1).alphaTarget(0)
				while (force.alpha() > force.alphaMin()) {
					force.tick()
				}
			}
			needRefresh.current = false
		}
		updatePositions()

		function handleNodeDragged(this: SVGGElement, event: GraphNodeDragEvent, d: ID3ClusterDiagramNode) {
			force.restart()
			// update position of a single node
			d.fx = event.x
			d.fy = event.y
			updatePositions()
			setIsDragging(true)
		}
		function handleNodeDragEnd(this: SVGGElement, _event: GraphNodeDragEvent, d: ID3ClusterDiagramNode) {
			d.fx = null
			d.fy = null
			updatePositions()
			setIsDragging(false)
		}

		node.call(drag<SVGGElement, ID3ClusterDiagramNode>().on('drag', handleNodeDragged).on('end', handleNodeDragEnd))
		squareNode.call(
			drag<SVGGElement, ID3ClusterDiagramNode>().on('drag', handleNodeDragged).on('end', handleNodeDragEnd)
		)
	}, [
		nodeDataSet,
		edgeDataSet,
		hoveredNode,
		selectedPods,
		setSelectedPods,
		setSelectedWorkloadController,
		selectedScaleableResource?.id,
		needRefresh,
		animateRefresh,
	])
	return { containerElementRef, hoveredPod: hoveredPod, hoveredPodRef, hoveredNode, hoveredNodeRef, isDragging }
}

const addColorsToGraphElements = (nodes: ID3ClusterDiagramNode[], edges: ID3ClusterDiagramEdge[]) => {
	nodes.forEach((node) => {
		node.strokeColor = getColorForNode(node)
		node.children.forEach((pod) => (pod.color = getColorForPod(pod)))
	})
	edges.forEach((edge) => (edge.color = getColorForEdge(edge)))
}

type GraphNodeDragEvent =
	| d3.D3DragEvent<SVGCircleElement, ID3ClusterDiagramPod, ID3ClusterDiagramPod>
	| d3.D3DragEvent<SVGCircleElement, ID3ClusterDiagramNode, ID3ClusterDiagramNode>
	| d3.D3DragEvent<SVGRectElement, ID3ClusterDiagramNode, ID3ClusterDiagramNode>
