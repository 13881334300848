import { useCallback, useEffect, useState } from 'react'
import type { IFilterEvent, IFilterEventOptions, ILookupInputOption } from '../LookupInput'
import { DropdownOptionsHandler } from '../../Dropdown'
import { useTranslation } from '../../../translation'

export const useFilterAndOptions = <T>(
	initialFilterTerm: string | undefined,
	minSearchTermLength: number,
	searchAsYouType: boolean | undefined,
	propsOptions: ILookupInputOption<T>[] | undefined,
	isLoading: boolean,
	onFilter: ((event: IFilterEvent) => void) | undefined,
	openDropdown: () => void
) => {
	const { tcvvi } = useTranslation()
	const [filterTerm, setFilterTerm] = useState(initialFilterTerm ?? '')

	const [options, setOptions] = useState(propsOptions)

	useEffect(() => {
		setOptions(propsOptions)
	}, [propsOptions])

	const updateFilter = useCallback(
		(newFilterTerm: string, filterOptions: IFilterEventOptions) => {
			openDropdown()

			setFilterTerm(newFilterTerm)

			// Stops from doing a new search if only adding trailing spaces etc
			if (searchAsYouType && filterTerm.trim() === newFilterTerm.trim()) {
				return
			}

			// Clear options
			setOptions(undefined)

			onFilter?.({
				filterTerm: newFilterTerm,
				minLength: minSearchTermLength,
				filterOptions: filterOptions,
			})
		},
		[filterTerm, minSearchTermLength, onFilter, openDropdown, searchAsYouType, setOptions]
	)

	const validFilterTerm = filterTerm.length >= minSearchTermLength
	const dropdownOptions = new DropdownOptionsHandler(validFilterTerm ? options : undefined, false)

	const searchingResultText = isLoading
		? tcvvi('GENERAL:SEARCHING_FOR', filterTerm)
		: tcvvi('GENERAL:RESULTS_FOR', filterTerm)
	const statusText = validFilterTerm
		? searchingResultText
		: tcvvi('GENERAL:ENTER_AT_LEAST_CHARACTERS_TO_SEARCH', minSearchTermLength)

	return { options, dropdownOptions, statusText, filterTerm, setFilterTerm, updateFilter }
}
