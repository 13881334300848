import {
	Checkbox,
	Dropdown,
	DurationInput,
	Text,
	TextInput,
	Link,
	e_LabelContentLayout,
	DropdownInput,
	Dialog,
} from '@genusbiz/web-ui/controls'
import { createStyle } from '@genusbiz/web-ui/theming'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { e_Weekday } from 'src/enums/e_Weekday'
import {
	AvailabilityGroupCreating,
	IAvailability,
	IAvailabilityGroup,
	e_RelativeWeekDay,
} from 'src/interfaces/IAvailabilityWindow'
import { kubernetesActions } from 'src/features/Kubernetes/duck/kubernetesActions'
import { compareWeekdays } from 'src/utils/availabilityUtils'
import { getTimezoneOptions } from 'src/utils/getTimezoneOptions'
import { userSettingsSelectors } from 'src/features/UserSettings/duck/userSettingsSelectors'
import { defaultColors } from 'src/consts/defaultColors'
import { e_LabelPosition } from '@genusbiz/web-ui/enums/e_LabelPosition'

const classes = createStyle({
	container: {
		display: 'flex',
		padding: '12px',
		flexDirection: 'column',
		justifyContent: 'space-around',
		width: '450px',
	},
	cardFooter: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: '4px',
	},
	top: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	timezoneSelector: {
		width: '250px',
		maxHeight: '300px',
	},
	timezoneWarning: {
		color: defaultColors.warningText,
	},
	from: { marginTop: '8px' },
	fromDays: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		height: '165px',
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	to: {
		display: 'flex',
		flexDirection: 'row',
	},
	dropdownInput: {
		width: '130px',
	},
	weekdayTime: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		margin: '12px 0px 12px 0px',
		width: '96px',
	},
	comment: {},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'row',
	},
	bottom: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
})

interface IProps {
	availabilityWindows: IAvailability[]
	availabilityGroup: AvailabilityGroupCreating
	isOpen: boolean
	onClose: () => void
	groupId?: string
	selectedEnvironment: e_EnvironmentOperatingType
}
const usePrevious = <T,>(value: T): T | undefined => {
	const ref = useRef<T>()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}

export const AvailabilityWindowEditor = (props: IProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [fromDays, setFromDays] = useState<e_Weekday[]>([])
	const [fromTime, setFromTime] = useState<number>(0)
	const [toDay, setToDay] = useState<e_Weekday | e_RelativeWeekDay>()
	const [toTime, setToTime] = useState<number>()
	const [comment, setComment] = useState<string>('')
	const [enabled, setEnabled] = useState<boolean>(true)
	const [enableSave, setEnableSave] = useState<boolean>()
	const [windowIds, setWindowIds] = useState<{ [weekday: string]: string }>({})
	const [timezone, setTimezone] = useState('')
	const [showTimezonePicker, setShowTimezonePicker] = useState(false)
	const prevFromDays = usePrevious(fromDays) || []
	const selectedTimezone = useSelector(userSettingsSelectors.selectSelecedTimezone)

	useEffect(() => {
		if (props.availabilityGroup) {
			setFromDays(props.availabilityGroup.fromDays)
			setFromTime(props.availabilityGroup.fromTime)
			setToDay(props.availabilityGroup.toDay)
			setToTime(props.availabilityGroup.toTime)
			setComment(props.availabilityGroup.comment)
			setEnabled(props.availabilityGroup.enabled)
			setWindowIds(props.availabilityGroup.windowIds)
			setTimezone(props.availabilityGroup.timezone)
			setShowTimezonePicker(false)
		}
	}, [props.availabilityGroup, props.isOpen])

	useEffect(() => {
		if (fromDays.length > 1 && prevFromDays.length === 1) {
			setToDay(e_RelativeWeekDay.sameDay)
		} else if (fromDays.length === 1 && prevFromDays.length > 1) {
			setToDay(fromDays[0])
		} else if (toDay === undefined) {
			setToDay(fromDays[0])
		}
	}, [fromDays])

	useEffect(() => {
		const startAndEndTimeIsEqual = fromDays.length === 1 && fromDays[0] === toDay && fromTime === toTime
		const startAndEndTimesAreEqual = fromDays.length > 1 && toDay === e_RelativeWeekDay.sameDay && fromTime === toTime
		const earlierSameDay = toDay === e_RelativeWeekDay.sameDay && typeof toTime === 'number' && toTime < fromTime
		if (
			fromDays.length > 0 &&
			typeof fromTime === 'number' &&
			!!toDay &&
			typeof toTime === 'number' &&
			!startAndEndTimeIsEqual &&
			!startAndEndTimesAreEqual &&
			!earlierSameDay
		) {
			setEnableSave(true)
		} else {
			setEnableSave(false)
		}
	}, [fromDays, fromTime, toDay, toTime])

	const weekdays = Object.values(e_Weekday).map((weekday) => {
		return { label: t('WEEKDAY:' + weekday.toUpperCase()), value: weekday }
	})
	const relativeWeekdays = Object.values(e_RelativeWeekDay).map((relativeWeekday) => {
		return { label: t('WEEKDAY:' + relativeWeekday.toUpperCase()), value: relativeWeekday }
	})

	const saveAvailabilityWindowGroup = () => {
		if (enableSave) {
			const updatedAvailabilityGroup: IAvailabilityGroup = {
				id: props.availabilityGroup.id,
				windowIds: windowIds,
				comment: comment,
				enabled: enabled,
				fromDays: fromDays,
				fromTime: fromTime,
				toDay: toDay as e_Weekday | e_RelativeWeekDay,
				toTime: toTime as number,
				timezone: timezone,
				windowType: props.availabilityGroup.windowType,
			}
			dispatch(kubernetesActions.addOrUpdateAvailabilityGroup(updatedAvailabilityGroup, props.selectedEnvironment))
			props.onClose()
		}
	}

	return (
		<Dialog
			isOpen={props.isOpen}
			title={
				props.availabilityGroup.fromDays.length > 0
					? t('AVAILABILITY_WINDOW:EDIT_WINDOW')
					: t('AVAILABILITY_WINDOW:NEW_WINDOW')
			}
			modalClassName={classes.container}
			handleClose={props.onClose}
			buttonDescriptions={[
				{ title: t('GENERAL:SAVE'), onClick: saveAvailabilityWindowGroup, isEnabled: enableSave, isDefault: true },
				{ title: t('GENERAL:CANCEL'), onClick: props.onClose, isEnabled: true, isCancel: true },
			]}
			disableCancelOnClickOutside
		>
			<div>
				<div title={t('AVAILABILITY_WINDOW:TIMEZONE_FOR_WINDOW')}>
					{showTimezonePicker ? (
						<DropdownInput
							style={{
								width: '100%',
							}}
							className={classes.timezoneSelector}
							label={t('AVAILABILITY_WINDOW:TIMEZONE')}
							labelPosition={e_LabelPosition.top}
							value={timezone}
							options={getTimezoneOptions()}
							onChange={(value: string) => {
								setTimezone(value)
							}}
						/>
					) : (
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '4px' }}>
							<Text>
								{t('AVAILABILITY_WINDOW:TIMEZONE')}: <br />
								{timezone}
							</Text>
							<Link
								onActivate={() => {
									setShowTimezonePicker(true)
								}}
								url="#"
								contentLayout={e_LabelContentLayout.text}
								displayName={t('AVAILABILITY_WINDOW:CHANGE_TIMEZONE')}
							/>
						</div>
					)}
					{timezone !== selectedTimezone && (
						<div className={classes.timezoneWarning}>
							<span>{t('AVAILABILITY_WINDOW:DIFFERENT_TIMEZONE_WARNING')}</span>
						</div>
					)}
				</div>
				<div className={classes.from}>
					<Text variant="h4">{t('AVAILABILITY_WINDOW:FROM')}</Text>
					<Text>{t('AVAILABILITY_WINDOW:DAYS') + ':'}</Text>
					<div className={classes.fromDays}>
						{weekdays.map((weekday) => {
							const checked = fromDays.includes(weekday.value)
							return (
								<Checkbox
									valueLabel={weekday.label}
									key={weekday.value}
									checked={checked}
									labelPosition={e_LabelPosition.right}
									onChange={() => {
										if (fromDays.includes(weekday.value)) {
											setFromDays(fromDays.filter((fromDay) => fromDay !== weekday.value).sort(compareWeekdays))
										} else {
											const fromDaysTmp = [...fromDays, weekday.value].sort(compareWeekdays)
											setFromDays(fromDaysTmp)
										}
									}}
								/>
							)
						})}
					</div>
					<DurationInput
						labelPosition={e_LabelPosition.top}
						onChange={(value) => setFromTime(value as number)}
						value={fromTime}
						isIntegerTime
						enableOptionDropdown
						dataAttributes={{ 'data-cy': 'availability-window-start-time-input' }}
						placeholder={t('AVAILABILITY_WINDOW:START_UTC')}
						className={classes.dropdownInput}
						label={t('AVAILABILITY_WINDOW:TIME')}
					/>
				</div>
				<Text style={{ marginTop: '12px' }} variant="h4">
					{t('AVAILABILITY_WINDOW:TO')}
				</Text>
				<div className={classes.to}>
					<div style={{ marginRight: '8px' }}>
						<Dropdown
							labelPosition={e_LabelPosition.top}
							value={toDay}
							className={classes.dropdownInput}
							options={fromDays.length > 1 ? relativeWeekdays : weekdays}
							onChange={(value) => setToDay(value)}
							dataAttributes={{ 'data-cy': 'availability-window-to-day-dropdown' }}
							label={t('AVAILABILITY_WINDOW:DAY')}
						/>
					</div>
					<DurationInput
						labelPosition={e_LabelPosition.top}
						onChange={(value) => setToTime(value as number)}
						value={toTime}
						className={classes.dropdownInput}
						isIntegerTime
						enableOptionDropdown
						dataAttributes={{ 'data-cy': 'availability-window-to-utc-input' }}
						label={t('AVAILABILITY_WINDOW:TIME')}
					/>
				</div>
				<hr style={{ marginTop: '12px', marginBottom: '12px' }} />
				<TextInput
					value={comment}
					placeholder={t('AVAILABILITY_WINDOW:COMMENT')}
					onChange={setComment}
					stretch
					className={classes.comment}
				/>
			</div>
			<div className={classes.cardFooter}>
				<Checkbox
					valueLabel={t('GENERAL:ENABLED')}
					labelPosition={e_LabelPosition.left}
					checked={enabled}
					onChange={setEnabled}
				/>
			</div>
		</Dialog>
	)
}
