import { useEffect } from 'react'
import * as reactDnd from 'react-dnd'
import { DEFAULT_DRAG_ITEM_TYPE, FILE_NAME_PREVIEW_OFFSET } from './DragDropTypes'
import type { IDragObject, IDragSourceMonitor } from './DragDropTypes'
import { getEmptyImage } from './getEmptyImage'
import { getDndPreviewElement } from './CustomDragPreview'

/**
 * Reusable dragging hook that encapsulates the boilerplate and genus spesific
 * functionality of react-dnd.
 *
 * Use: Add the returned ref to the react element you want to add dragging functionality to.
 *
 * @param onDragStart
 * @param onDragEnd
 * @param enableDrag either a boolean or a monitor function to allow for more grandular drag control
 * of when darggability is allowed.
 * @param customPreview boolean determining whether to use the preview element found in DragDropProvider.
 * @param dragSourceType string type used by react-dnd to determine if dropping is allowed.
 * @returns the ref of the draggable target element
 */

export function useDrag(
	onDragStart: (monitor: IDragSourceMonitor) => void | IDragObject | undefined,
	onDragEnd: (monitor: IDragSourceMonitor) => void,
	enableDrag: boolean | ((monitor: IDragSourceMonitor) => boolean) = true,
	customPreview: boolean = false,
	dragSourceType: string = DEFAULT_DRAG_ITEM_TYPE
) {
	const [, dragRef, preview] = reactDnd.useDrag({
		item: { type: dragSourceType },
		canDrag: enableDrag,
		begin: onDragStart,
		end: (_item, monitor) => onDragEnd(monitor),
	})

	useEffect(() => {
		if (!preview) {
			return
		}
		if (customPreview) {
			const customElement = getDndPreviewElement() // Currently only designed to preview text

			preview(customElement, FILE_NAME_PREVIEW_OFFSET)
		} else {
			// Force the browser-made drag preview to be an empty image, i.e. not visible
			preview(getEmptyImage(), { captureDraggingState: true })
		}
	}, [preview, customPreview])

	return dragRef
}
