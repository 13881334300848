import React from 'react'
import { e_SortOrder } from '../../../enums/e_SortOrder'
import { useTranslation } from '../../../../../translation'

export const SortArrow = (props: { sorting?: 'asc' | 'desc' }) => {
	const { tcvi } = useTranslation()

	if (props.sorting === e_SortOrder.desc) {
		return <i className="Fluent-SortDown" title={tcvi('CONTROL:AG_ARIADROPZONECOLUMNCOMPONENTSORTDESCENDING')} />
	}
	if (props.sorting === e_SortOrder.asc) {
		return <i className="Fluent-SortUp" title={tcvi('CONTROL:AG_ARIADROPZONECOLUMNCOMPONENTSORTASCENDING')} />
	}
	return <i className="Fluent-Transparent" />
}
