import moment from 'moment-timezone'
import { IPublication } from 'src/interfaces/IPublication'

export const isActive = (publication: IPublication | null): boolean => {
	if (publication == null || publication.activeDateTime == null) {
		return false
	}

	const utcNow = moment.utc()

	const isActiveNow =
		utcNow.isAfter(moment.utc(publication.activeDateTime)) &&
		(publication.passiveDateTime == null || utcNow.isBefore(moment.utc(publication.passiveDateTime)))

	return isActiveNow
}
