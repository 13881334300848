import numeral from 'numeral'

export const isPercentFormat = (formatString: string | undefined, isPercentNumber: boolean | undefined) => {
	if (isPercentNumber) {
		return true
	}

	if (!formatString) {
		return false
	}

	return formatString.includes('NFP')
}

export const clampValue = (value: number, min: number | undefined, max: number | undefined) => {
	if (min !== undefined && value < min) {
		return min
	}

	if (max !== undefined && value > max) {
		return max
	}

	return value
}

export function getParseNumber(
	type?: 'int' | 'float',
	formatString?: string,
	isPercentNumber?: boolean,
	min?: number,
	max?: number
) {
	return (value: string) => {
		// undefined means invalid value, and returning a number or null means value has been parsed
		if (!value.length) {
			// value null is a valid value
			return null
		}

		if (type === 'int' && (value.includes(',') || value.includes('.') || value.includes(' '))) {
			// thousand separator or decimal separator is not valid in an int
			return undefined
		}

		const localeData = numeral.localeData()

		// use a regular expression to pre-validate the number string
		let genericNumberString = value
		if (type === 'int') {
			// required format: (-)123456
			const intPattern = /^-?\d+$/

			if (!intPattern.test(value.trim())) {
				return undefined
			}
		} else {
			let floatValue = value
			const firstCharacterIsCommaOrPeriod = /^[,.]/.test(floatValue.trim())
			if (firstCharacterIsCommaOrPeriod) {
				floatValue = `0${value}`
			}
			// required format: (-)123(.456)
			const floatPattern = new RegExp(`^-?[0-9]+(\\${localeData.delimiters.decimal}[0-9]+)?$`)

			if (!floatPattern.test(floatValue.trim())) {
				return undefined
			}

			genericNumberString = floatValue.replace(localeData.delimiters.decimal, '.')
		}

		// Make sure we have a value left to parse after conversion to generic number string
		if (
			!genericNumberString.length ||
			genericNumberString === localeData.delimiters.thousands ||
			genericNumberString === localeData.delimiters.decimal
		) {
			return undefined
		}

		if (isNaN(Number(genericNumberString))) {
			return undefined
		}

		const number = type === 'float' ? parseFloat(genericNumberString) : parseInt(genericNumberString)

		if (isPercentFormat(formatString, isPercentNumber)) {
			return numeral(number).divide(100).value()
		}

		return clampValue(number, min, max)
	}
}
