import { createStyle } from '@genusbiz/web-ui/theming'
import React from 'react'
import isNil from 'lodash/isNil'
import { e_ResourceKind } from 'src/enums/e_ResourceKinds'
import { IContainerStatus, IPod } from 'src/interfaces/IPod'
import { useTranslation } from 'react-i18next'
import { WorkloadDetailsContainerStatus } from '../WorkloadDetailsContainerStatus'
const classes = createStyle({
	content: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		backgroundColor: 'white',
		//overflowY: 'scroll',
	},
	status: {
		display: 'flex',
		flexDirection: 'column',
		margin: '12px',
	},
	podData: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
	},
	podDetails: {
		display: 'flex',
		flexDirection: 'column',
	},
	resources: {
		display: 'flex',
		flexDirection: 'column',
	},
	containers: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
	},
	containerStatus: {
		display: 'flex',
		flexDirection: 'column',
		margin: '6px',
	},
	label: {
		width: '105px',
		display: 'inline-block',
	},
	labelResource: {
		width: '85px',
		display: 'inline-block',
	},
	resourceDetails: {
		marginLeft: '20px',
		display: 'flex',
		flexDirection: 'column',
	},
})

interface IWorkloadDetailsProps {
	pod: IPod
}

export const PodDescription = (props: IWorkloadDetailsProps) => {
	const { t } = useTranslation()
	const { pod } = props

	const getMemoryValueInCorrectUnit = (value: number) => {
		value = Math.round(value)
		if (value >= 1024) {
			return `${value * Math.pow(2, -10)}GiB`
		}

		return `${value}MiB`
	}

	return (
		<div className={classes.content}>
			{pod.kind === e_ResourceKind.pod && (
				<div className={classes.status}>
					<b>{t('WORKLOADS:POD_DETAILS')}</b>
					<div className={classes.podData}>
						<div className={classes.podDetails}>
							<div>
								<label className={classes.label}>{t('WORKLOADS:STATE')}:</label>
								<span>{pod.status.phaseName + (pod.status.reason ? '(' + pod.status.reason + ')' : '')}</span>
							</div>
							{pod.status.message && (
								<div>
									<label className={classes.label}>{t('WORKLOADS:MESSAGE')}:</label>
									<span>{pod.status.message}</span>
								</div>
							)}
							<div>
								<label className={classes.label}>{t('WORKLOADS:RESTARTS')}:</label>
								<span>{pod.restartCount}</span>
							</div>
							<div>
								<label className={classes.label}>{t('WORKLOADS:VERSION')}:</label>
								<span>{pod.objectMetadata.version}</span>
							</div>
							<div>
								<label className={classes.label}>{t('WORKLOADS:PRODUCT_VERSION')}:</label>
								<span>{pod.runtimeConfig.genusVersion || pod.runtimeConfig.genusOperatorVersion}</span>
							</div>
							<br />
						</div>
						<div className={classes.resources}>
							{t('GENERAL:MEMORY')}:
							<div className={classes.resourceDetails}>
								{!isNil(pod.memoryRequestsInMiB) && (
									<div>
										<label className={classes.labelResource}>{t('WORKLOADS:REQUEST')}:</label>
										<span>{getMemoryValueInCorrectUnit(pod.memoryRequestsInMiB)}</span>
									</div>
								)}
								{!isNil(pod.memoryLimitsInMiB) && (
									<div>
										<label className={classes.labelResource}>{t('WORKLOADS:LIMIT')}:</label>
										<span>{getMemoryValueInCorrectUnit(pod.memoryLimitsInMiB)}</span>
									</div>
								)}
							</div>
							CPU:
							<div className={classes.resourceDetails}>
								{!isNil(pod.cpuRequestInMilliVCpu) && (
									<div>
										<label className={classes.labelResource}>{t('WORKLOADS:REQUEST')}:</label>
										<span>{Math.round(pod.cpuRequestInMilliVCpu)}m</span>
									</div>
								)}
								{!isNil(pod.cpuLimitsInMilliVCpu) && (
									<div>
										<label className={classes.labelResource}>{t('WORKLOADS:LIMIT')}:</label>
										<span>{Math.round(pod.cpuLimitsInMilliVCpu)}m</span>
									</div>
								)}
							</div>
						</div>
					</div>
					{pod.status.initContainerStatuses.length > 0 && (
						<div>
							<b>{t('WORKLOADS:INIT_CONTAINERS')}</b>
							<div className={classes.containers}>
								{pod.status.initContainerStatuses?.map((containerStatus: IContainerStatus) => {
									return (
										<WorkloadDetailsContainerStatus
											key={containerStatus.name}
											containerStatus={containerStatus}
											phaseName={pod.status.phaseName}
										/>
									)
								})}
							</div>
						</div>
					)}
					<b>{t('WORKLOADS:CONTAINERS')}</b>
					<div className={classes.containers}>
						{pod.status.containerStatuses?.map((containerStatus: IContainerStatus) => {
							return (
								<WorkloadDetailsContainerStatus
									key={containerStatus.name}
									containerStatus={containerStatus}
									phaseName={pod.status.phaseName}
								/>
							)
						})}
					</div>
				</div>
			)}
		</div>
	)
}
