export const mapObjectValues = <T extends { [K in keyof T]: E }, E, R>(obj: T, map: (val: E) => R) => {
	const updateImage = (image: { [K in keyof T]: R }, key: keyof T) => {
		const x = obj[key]
		const y = map(x)
		image[key] = y
		return image
	}

	const emptyImage = {} as { [K in keyof T]: R }

	const completeImage = Object.keys(obj).reduce(
		(partialImage, key) => updateImage(partialImage, key as keyof T),
		emptyImage
	)

	return completeImage
}
