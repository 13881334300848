import type { CellData, RowData, TData } from '../../../Table.types'
import { tDataToRowData } from '../../../utils'
import type { ColDef } from '@ag-grid-community/core'

/**
 * Returns the onClick function for a cell if it should be used as a link.
 * @param nodeData the data of the row.
 * @param onClick The onClick function from `colDef`.
 * @param colDef The column definition.
 */
export const getOnLinkClick = (
	nodeData: TData | undefined,
	onClick: ((rowData: RowData, targetEl: React.RefObject<HTMLElement>) => void) | undefined,
	colDef: ColDef<TData, CellData> | undefined
) => {
	const rowDataOnClick = getRowDataOnClick(colDef, nodeData)
	return onClick || rowDataOnClick
		? (targetEl: React.RefObject<HTMLElement>) => {
				const rowData = tDataToRowData(nodeData)
				if (rowData) {
					onClick?.(rowData, targetEl)
					rowDataOnClick?.(rowData, targetEl)
				}
		  }
		: undefined
}

/**
 * Returns the onClick function from nodeData if it should be used as a link.
 */
function getRowDataOnClick(colDef: ColDef<TData, CellData> | undefined, nodeData: TData | undefined) {
	if (colDef && colDef.field) {
		return nodeData?.[colDef.field]?.onClick
	}
}
