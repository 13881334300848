import React, { useEffect, useState } from 'react'
import type { IAccordionContext } from './AccordionContext'
import { AccordionContext } from './AccordionContext'
import { createStyle } from '../../theming'

const classes = createStyle({
	accordion: {
		display: 'flex',
		flexDirection: 'column',
	},
})

interface IAccordionProps {
	expandMode?: 'multiple' | 'single'
	children: React.ReactNode[]
	initialExpandedItemIds?: string[]
	level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export const Accordion = (props: IAccordionProps) => {
	const [expandedItemIds, setExpandedItemIds] = useState<string[]>(props.initialExpandedItemIds || [])

	const updateExpandedItemIds = (itemIds: string[]) => {
		setExpandedItemIds(itemIds)
	}

	useEffect(() => {
		if (props.initialExpandedItemIds) {
			updateExpandedItemIds(props.initialExpandedItemIds)
		}
	}, [props.initialExpandedItemIds])

	const onItemHeaderClick = (itemId: string) => {
		if (expandedItemIds.includes(itemId)) {
			const nextExpandedIds = expandedItemIds.filter((id) => id !== itemId)
			updateExpandedItemIds(nextExpandedIds)
		} else {
			const nextExpandedIds = props.expandMode === 'single' ? [itemId] : [...expandedItemIds, itemId]
			updateExpandedItemIds(nextExpandedIds)
		}
	}

	const accordionContext: IAccordionContext = {
		expandedItemIds: expandedItemIds,
		level: props.level || undefined,
		onHeaderClick: onItemHeaderClick,
	}
	return (
		<div className={classes.accordion}>
			<AccordionContext.Provider value={accordionContext}>{props.children}</AccordionContext.Provider>
		</div>
	)
}
