import type { ReactNode } from 'react'
import React, { useEffect } from 'react'
import type { DragSourceMonitor } from 'react-dnd'
import { useDrag } from 'react-dnd'
import { FILE_NAME_PREVIEW_OFFSET } from './dragdrop/DragDropTypes'

export interface IDragSourceProps {
	initiateDrag?: (monitor: DragSourceMonitor) => void // called when a drag operation is started, return the drag object
	dragCompleted?: (result: any) => void // called twhen a drag is completed, includes the state of the drag
	disableDrag?: boolean // disables dragging
	dragSourceType?: string // sets the type of the drag operation
	children?: ReactNode | ReactNode[]
	canInitiateDrag?: (monitor: DragSourceMonitor) => boolean
	setIsDragging?: (isDragging: boolean) => void
	className?: string
	customPreview?: { element: Element; offsetX?: number; offsetY?: number }
}

let emptyImage: HTMLImageElement

const getEmptyImage = () => {
	if (!emptyImage) {
		emptyImage = new Image()
		emptyImage.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
	}

	return emptyImage
}

export const DragSource = (props: IDragSourceProps) => {
	const getDragSourceType = () => {
		return props.dragSourceType ? props.dragSourceType : 'GenusDragDropItem'
	}

	const canDrag = (monitor: DragSourceMonitor) => {
		if (props.disableDrag) {
			return false
		}

		if (!props.initiateDrag) {
			return false
		}

		if (!props.canInitiateDrag) {
			return true
		}

		return props.canInitiateDrag(monitor)
	}

	const dragCompleted = (_item: any, monitor: DragSourceMonitor) => {
		// Send callback to owner when drag is ended
		if (props.dragCompleted) {
			props.dragCompleted(monitor.getDropResult())
		}
	}

	const begin = (monitor: DragSourceMonitor) => {
		// Send callback to owner when drag is started
		if (props.setIsDragging) {
			props.setIsDragging(true)
		}

		if (!props.initiateDrag) {
			return undefined
		}

		return props.initiateDrag(monitor)
	}

	const [, drag, preview] = useDrag({
		item: { type: getDragSourceType() },
		canDrag,
		end: dragCompleted,
		begin,
		collect: (monitor) => ({ isDragging: monitor.isDragging() }),
	})

	useEffect(() => {
		if (!preview) {
			return
		}
		if (props.customPreview) {
			const customElement = props.customPreview.element

			preview(customElement, FILE_NAME_PREVIEW_OFFSET)
		} else {
			// Force the browser-made drag preview to be an empty image, i.e. not visible
			preview(getEmptyImage(), { captureDraggingState: true })
		}
	}, [preview, props.customPreview])

	if (props.disableDrag) {
		return <>{props.children}</>
	}

	return (
		<span className={props.className} ref={drag}>
			{props.children}
		</span>
	)
}
