import type { e_BooleanFormat } from './enums/e_BooleanFormat'
import type { e_DataType } from './enums/e_DataType'
import type { e_DateFormat } from './enums/e_DateFormat'
import type { e_FilterOperator } from './components/CellRenderer/cellRenderers/CellRendererDropdown'
import type { e_InitialSelection } from './enums/e_InitialSelection'
import type { e_Interpretation } from './enums/e_Interpretation'
import type { e_SortOrder } from './enums/e_SortOrder'
import type { e_TableColumnSummaryFunction } from './enums/e_TableColumnSummaryFunction'
import type {
	PostSortRowsParams,
	IRowNode,
	RowClassRules,
	GridApi,
	IAggFunc,
	ExcelExportParams,
	ColDef,
	Column,
	ProcessCellForExportParams,
} from '@ag-grid-community/core'
import type { MenuItem } from '../Menu'
import type { CSSProperties, RefObject } from 'react'

export type Value = string | boolean | number | undefined | null
export type CheckMarkValue = boolean | 'mixed'
export type Option = { value: string | number | boolean; label: string }
export type ColumnOptions = { [columnId: string]: Option[] }
export type CellClickHandler = (
	rowData: RowData,
	targetEl?: RefObject<HTMLElement>,
	getTargetElement?: () => HTMLElement | undefined
) => void

export type CellData = {
	value: Value
	formattedValue?: Value
	/**
	 * Callback that will be triggered when a cell is clicked.
	 */
	onClick?: CellClickHandler
	formatValue?: (value: Value) => string
	iconName?: string
	avatarLabel?: string
	iconColor?: string
	screenTip?: string
	readOnly?: boolean
	disabled?: boolean
	error?: string
	sortValue?: string | number | null

	color?: string
	background?: string
	isBold?: boolean | 'inherit'
	isItalic?: boolean | 'inherit'
	hasUnderline?: boolean | 'inherit'
	hasStrikethrough?: boolean | 'inherit'
}

type Columns = {
	[columnId: string]: CellData | undefined
}

export enum e_RenderType {
	avatar = 'avatar',
	checkMark = 'checkMark',
	text = 'text',
	none = 'none',
	dropdown = 'dropdown',
	lookup = 'lookup',
	date = 'date',
	duration = 'duration',
	number = 'number',
}

type BaseData = {
	id: string
	createdDate?: string
	isCreating?: boolean
	columns: Columns
	disableSelection?: boolean
	isBold?: boolean
	isItalic?: boolean
	hasUnderline?: boolean
	hasStrikethrough?: boolean
	color?: string
	background?: string
	rowClassName?: string
}

export type RowData = BaseData & {
	rowHierarchy?: undefined
	rowName?: undefined
}

export type RowTreeData = BaseData & {
	rowHierarchy: string[]
	rowName?: string
}

export type TData = Omit<RowData | RowTreeData, 'columns'> & Columns

export type ColWidth = number | 'fitToContents' | 'fitToLabelAndContent'
type PillWidth = 'fitToContents' | 'stretch' | number

export interface IColumnDef {
	autoHeight?: boolean
	field: string
	headerName?: string
	headerComponentParams?: {
		iconName?: string
		iconPlacement?: 'before' | 'after'
		textAlignment?: 'right' | 'left' | 'center'
		hideLabel?: boolean
		disableMenu?: boolean
		includesFileData?: boolean
		alwaysShowMenu?: boolean
	}
	screenTip?: string
	sort?: e_SortOrder
	sortIndex?: number

	allowNull?: boolean
	isPercentNumber?: boolean
	renderType?: e_RenderType
	avatarLabel?: string
	iconName?: string
	iconColor?: string
	iconPlacement?: 'before' | 'after'
	dataType?: e_DataType
	interpretation?: e_Interpretation
	format?: e_DateFormat | e_BooleanFormat
	formatValue?: (value: Value) => Value
	numberFormat?: string
	formatOnExport?: boolean
	columnSummaryFunction?: IAggFunc<TData> | e_TableColumnSummaryFunction
	hide?: boolean
	children?: IColumnDef[]
	rowGroup?: boolean
	rowGroupIndex?: number
	prefixGroup?: boolean
	enableGrouping?: boolean
	/**
	 * Set to false to disable sorting which is enabled by default.
	 * @default true
	 */
	enableSorting?: boolean
	hasContextMenu?: boolean
	lockContextMenu?: boolean
	pinned?: e_Pinned
	tooltipField?: string
	readOnly?: boolean
	disabled?: boolean
	width?: ColWidth
	initialWidth?: ColWidth
	initialMaxWidth?: number
	initialMinWidth?: number
	flashCellsOnNewValue?: boolean
	lockPosition?: 'right' | 'left'
	headerStyle?: React.CSSProperties
	textAlignment?: 'right' | 'left' | 'center'
	fillVariant?: 'pill' | 'circle' | 'default'
	pillWidth?: PillWidth
	onRenderCell?: (rowData: RowData, columnId: string) => JSX.Element | null
	/**
	 * Set to false to disable filtering which is enabled by default.
	 * @default true
	 */
	enableFilter?: boolean
	controlProps?: { filterOperator?: e_FilterOperator }
	onCellClick?: CellClickHandler

	cellClassName?: string
	cellScreenTip?: string
	getCellClassNameOnRender?: (nodeId: string | undefined, colId: string | undefined) => string | undefined
	getCellStyleOnRender?: (
		nodeId: string | undefined,
		colId: string | undefined,
		targetElement: 'pill' | 'circle' | 'avatar' | 'cell'
	) => CSSProperties | undefined
	getCellDataOnRender?: (
		nodeId: string | undefined,
		colId: string | undefined
	) => Partial<Pick<CellData, 'readOnly' | 'iconName' | 'iconColor' | 'avatarLabel'>> | undefined
	getTooltip?: (nodeId: string | undefined, colId: string | undefined) => string | undefined
}

export type ColumnWidthType = { key: string; newWidth: number | 'auto' }[] | 'auto' | 'fit'

interface IColDef<TData = any, TValue = any> extends ColDef<TData, TValue> {
	cellRendererParams?: {
		dataType?: e_DataType
		formatting?: e_DateFormat
		formatOnExport: boolean
		interpretation?: e_Interpretation
	}
}

interface ProcessCellCallbackColumn extends Column {
	getUserProvidedColDef: () => IColDef | null
}

export interface ProcessCellCallbackArgs extends ProcessCellForExportParams<TData> {
	column: ProcessCellCallbackColumn
}

interface ExportDataAsExcelArgs extends ExcelExportParams {
	author?: string
	fileName?: string
	sheetName?: string
	processCellCallback?: (args: ProcessCellCallbackArgs) => string
	columnKeys?: string[]
}

export interface ITableApi {
	/**
	 * This is the ag-grid gridApi. It is exposed here to allow for more advanced operations on the grid.
	 * Be careful using it, it can have unintended side effects.
	 * It is mainly exposed to allow for more advanced testing.
	 */
	_gridApi: GridApi
	setQuickFilter: (newFilter: string) => void
	setColumnWidth: (columnWidths: ColumnWidthType) => void
	autoSizeColumns: () => void
	resetView: () => void
	showLoadingOverlay: () => void
	hideOverlay: () => void
	exportDataAsExcel: (exportArgs?: ExportDataAsExcelArgs) => void
	redrawRows: () => void
	setFocusToFirstSelectedRowOrFirstRow: () => void
	scrollToBottom: () => void
	scrollToTop: () => void
	scrollToLeft: () => void
	scrollToRight: () => void
	setFilterModel: (filterModel: { [key: string]: any }) => void
}

export type IGroupColumnDef = {
	enableSorting?: boolean
	width?: number | null
	minWidth?: number
}

export interface ITableBase {
	id?: string
	columnDefs: IColumnDef[]
	placeFirstColumnInGroupColumn?: boolean
	filterText?: string
	disableMovableColumns?: boolean
	disableColumnHeaderMenu?: boolean
	onContextMenu?: (id: string, columnId: string, contextMenuCallback: (contextMenuItems: MenuItem[]) => void) => void
	onClick?: (data: RowData, columnId?: string) => void
	onDblClick?: (data: RowData, columnId?: string) => void
	onDelete?: () => void
	onEscape?: (data: RowData) => void
	onSelectionChange?: (ids: string[]) => void
	onGridReady?: (customApi: ITableApi) => void
	onRowDataUpdated?: () => void
	onColumnStateChanged?: () => void
	onColumnSortChanged?: (columns: ISortedField[]) => void
	onFilter?: (visibleRows: RowData[]) => void
	onCellMouseDown?: (data: RowData, columnId: string) => void
	onInitialAutosizeFinished?: () => void
	onCopyCell?: (value: Value) => void
	rowSelectionType?: e_RowSelectionType
	initialSelection?: e_InitialSelection
	disableRowClickSelection?: boolean
	toggleSelectionOnRowClick?: boolean
	selection?: string[]
	noRowsOverlayComponent?: () => JSX.Element
	noRowsMessage?: string
	loadingOverlayComponent?: () => JSX.Element
	postSortRows?: (params: PostSortRowsParams<TData>) => void
	contextMenuButton?: 'firstColumn' | 'lastColumn' | 'inline'
	singleClickEdit?: boolean
	verticalHeader?: boolean
	groupLevelsToExpand?: number
	enableGrouping?: boolean
	/**
	 * Set to false to disable filtering for the whole table which is enabled by default.
	 * @default true
	 */
	enableFiltering?: boolean
	/**
	 * Set to false to disable sorting for the whole table which is enabled by default.
	 * @default true
	 */
	enableSorting?: boolean
	grouping?: IGroupingField[]
	masterDetail?: boolean
	onDetailCellRenderer?: (data: RowData) => JSX.Element | undefined
	enableCellTextSelection?: boolean
	initialColumnSizing?: e_InitialColumnSizing
	cellEditingProps?: ICellEditingProps
	compact?: boolean
	width?: number | 'stretch' | 'fitToContents'
	height?: number | 'stretch' | 'fitToContents'
	autoWidth?: boolean
	autoHeight?: boolean
	focusFirstCellOnRender?: boolean
	zebraStripes?: boolean
	zebraColorSet?: { foreground?: string; background?: string; primary?: string }
	groupRowColorSet?: { foreground?: string; background?: string }
	rowClassName?: string
	onFileDragStart?: (event: DragEvent, cellData: TData) => void

	sortOrder?: ISortedField[]
	/**
	 * This callback will only be called when the row element is drawn for the first time.
	 * When data changes it will only add classes but not remove previously added classes.
	 * See ag-grid documentation on getRowClass for more information.
	 * @param nodeId The ID of the row being rendered
	 * @returns a class name to be applied to the row
	 */
	getRowClassNameOnRender?: (nodeId: string | undefined) => string | undefined
	/**
	 * Exposes the ag-grid rowClassRules property. This can be used to apply custom classes to rows based on their data.
	 * These rules will be applied every time the data changes.
	 * Check the ag-grid documentation for more information.
	 */

	getRowStyleOnRender?: (nodeId: string | undefined) => CSSProperties | undefined

	rowClassRules?: RowClassRules<TData>
	onExportToFileClick?: () => void
	pageSize?: number | string
	/**
	 * Callback to check which rows are included in the page when using page break
	 * @param visibleIds A string array containing the IDs to each RowData included in the current page
	 * @returns void
	 */
	onPageBreakChange?: (visibleIds: string[]) => void
	/**
	 * This will disable the virtualisation of rows in the table and will thus impact performance.
	 * Auto height can sometimes not work properly when virtualisation is enabled. This can be used to disable it.
	 */
	suppressRowVirtualisation?: boolean
	groupColumnDef?: IGroupColumnDef
	stickyGroupingRows?: boolean
	utilizeSquareSelectionCheckmarks?: boolean
	utilizeNewCheckmarks?: boolean
}

export interface ITableRowData extends ITableBase {
	treeData?: false
	rowData: RowData[]
	groupHeaderName?: undefined
}
export interface ITableTreeData extends ITableBase {
	treeData: true
	rowData: RowTreeData[]
	groupHeaderName?: string
}

export enum e_Pinned {
	left = 'left',
	right = 'right',
}

export enum e_RowSelectionType {
	none = 'none',
	single = 'single',
	multi = 'multi',
}

export enum e_InitialColumnSizing {
	auto = 'auto',
	fit = 'fit',
}

export interface ICellRenderer {
	onClick?: (targetEl: React.RefObject<HTMLElement>) => void
	setValue?: (value: Value) => void
	className?: string
	hasError?: boolean
}

export interface ICellRendererProps {
	node?: IRowNode<TData>
	textAlignment: IColumnDef['textAlignment']
	renderType: e_RenderType
	iconPlacement: IColumnDef['iconPlacement']
	hasColumnContextMenu: boolean
	lockColumnContextMenu: boolean
	hasContextMenuButton: boolean
	multiSelect: boolean
	isFirstColumn: boolean
	readOnly?: boolean
	avatarLabel?: string
	iconName?: string
	iconColor?: string
	compact?: boolean
	selectable: boolean
	dataType: e_DataType
	interpretation: e_Interpretation
	filterOperator: e_FilterOperator
	formatOnExport: boolean
	clearCellOnDelete: boolean
	getContextMenuItems?: (
		id: string,
		columnId: string,
		contextMenuCallback: (contextMenuItems: MenuItem[]) => void
	) => void
	fillVariant: 'pill' | 'circle' | 'default'
	pillWidth?: PillWidth
	onRenderCell: IColumnDef['onRenderCell']
	cellEditingProps?: ICellEditingProps
	createOnClick: (data: TData | undefined) => CellClickHandler | undefined
	format: e_DateFormat | e_BooleanFormat | undefined
	allowNull?: boolean
	isPercentNumber?: boolean
	formatCellValue?: (value: Value) => Value

	cellClassName?: IColumnDef['cellClassName']
	getCellClassNameOnRender?: IColumnDef['getCellClassNameOnRender']
	getCellStyleOnRender?: IColumnDef['getCellStyleOnRender']
	getCellDataOnRender?: IColumnDef['getCellDataOnRender']
	widthFromProps?: ColWidth
	minWidthFromProps: number | undefined
	maxWidthFromProps: number | undefined
	masterDetail: boolean
	prefixGroup: boolean
	onFileDragStart?: (event: DragEvent, data: TData) => void
}

export interface ICellEditingProps {
	onChange: (rowData: RowData, columnId: string, newValue: Value) => void
	onNeedOptions?: (
		nodeId: string,
		columnId: string,
		query: string | undefined,
		onLoaded: (options: Option[]) => void
	) => void
}

export interface IGroupingField {
	id: string
	expanded: boolean
	displayColumnName: boolean
}

export interface ISortedField {
	id: string
	order: e_SortOrder
}

export interface ITableContext {
	_checkMarkValue: CheckMarkValue
	checkMarkValue: CheckMarkValue
	_openGroupNodes: string[]
	openGroupNodes: string[]
	initEditorWithPopper?: boolean
	onFileDragStart: ((event: DragEvent, cellData: TData) => void) | undefined
	placeFirstColumnInGroupColumn: boolean
	utilizeSquareSelectionCheckmarks: boolean
	utilizeNewCheckmarks: boolean
	onClick?: (data: RowData, columnId?: string) => void
	onDblClick?: (data: RowData, columnId?: string) => void
}

export type ITableContextRef = React.MutableRefObject<ITableContext>
