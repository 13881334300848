const sortByValue = (array: any[], value: string) => {
	const sortedArrary = array
	sortedArrary.sort((a, b) => {
		let valueA = a[value]
		if (valueA && typeof valueA === 'string') {
			valueA = valueA.toUpperCase() // ignore upper and lowercase
		}
		let valueB = b[value]
		if (valueB && typeof valueB === 'string') {
			valueB = valueB.toUpperCase() // ignore upper and lowercase
		}
		if (valueA < valueB) {
			return -1
		}
		if (valueA > valueB) {
			return 1
		}

		// values must be equal
		return 0
	})

	return sortedArrary
}

export default sortByValue
