import type { IKeyboardShortcut } from '../utils/keyboard/IKeyboardShortcut'

export enum e_MenuItemType {
	action,
	section,
	divider,
	link,
	custom,
}

export enum e_ItemContentLayout {
	iconAndText = 'iconAndText',
	icon = 'icon',
	text = 'text',
}

export type IContentLayout = e_ItemContentLayout | ((renderedIn: 'CommandBar' | 'Menu') => e_ItemContentLayout)

export function getContentLayout(
	contentLayout: IContentLayout | undefined,
	renderedIn: 'CommandBar' | 'Menu' = 'Menu'
): e_ItemContentLayout | undefined {
	return typeof contentLayout === 'function' ? contentLayout(renderedIn) : contentLayout
}

export type MenuItemBase = Partial<
	Readonly<{
		name: string
		id?: string
		iconClassName: string
		iconColorClass: string
		iconString: string
		disabled: boolean
		hidden: boolean
		isChecked: boolean
		onClick: (e: React.MouseEvent | React.KeyboardEvent | undefined) => void
		screenTip: string
		badgeValue: number
		contentLayout: IContentLayout
		dataAttributes?: Record<string, string>
		keyboardShortcut?: IKeyboardShortcut
		isMechanic?: boolean
		available: boolean
	}>
>

export type MenuItemDivider = {
	type: e_MenuItemType.divider
} & { [key in keyof MenuItemBase]: undefined }

export type MenuItemAction = {
	type: e_MenuItemType.action
} & MenuItemBase

export type MenuItemSection = {
	type: e_MenuItemType.section
	showDropdownButton?: boolean
	items?: MenuItem[]
} & MenuItemBase

export type MenuItemLink = {
	type: e_MenuItemType.link
	href: string
	openInNewTab?: boolean
	/**
	 * If specified, the filename of the downloaded document will be this value. Empty string will use the files current filename.
	 */
	download?: string
} & MenuItemBase

export type MenuItemCustom = {
	type: e_MenuItemType.custom
	render: (renderedIn: 'CommandBar' | 'Menu') => React.ReactNode
	hidden?: boolean
} & { [key in Exclude<keyof MenuItemBase, 'hidden'>]?: undefined } & { dataAttributes?: Record<string, string> }

export type MenuItem = MenuItemAction | MenuItemSection | MenuItemDivider | MenuItemCustom | MenuItemLink

export type MenuRoot = {
	items: MenuItem[]
	visibleItemsCount?: number
}
