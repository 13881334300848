import React from 'react'
import type { MenuItem, MenuRoot } from './MenuItem.types'
import { PopupMenuControl } from './PopupMenuControl'
import type { e_Placement } from '../../enums/e_Placement'

export interface IMenuProps {
	rootItem?: MenuRoot
	items?: MenuItem[]
	/**
	 * Ref to the element the menu should be attached to.
	 *
	 * In most cases this is the element used to open the menu, but another element can also be used.
	 */
	anchorEl?: React.RefObject<HTMLElement | undefined>
	/**
	 * Close event that is run whenever something happens that would close the menu,
	 * i.e. click outside, activation of element, esc-click, etc.
	 *
	 * The menu does not close automatically, so visibility is controlled by the parent component.
	 * The menu should thus probably be hidden as a response to the onClose event.
	 */
	onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void
	/**
	 * The menu will return focus automatically **if `anchorEl` is provided**.
	 *
	 * This can be overridden by setting `blockReturnFocus`.
	 */
	blockReturnFocus?: boolean
	/** Controls whether the menu is open or not. */
	open?: boolean
	position?: { x: number; y: number }
	placement?: e_Placement
	iconColorClass?: string
	popperClassName?: string
	popperStyle?: React.CSSProperties
	menuStyle?: React.CSSProperties
	popperOffset?: string
	dataAttributes?: Record<string, string>
}

export const Menu = (props: IMenuProps) => {
	const menuItems = props.rootItem?.items ?? props.items

	return (
		<PopupMenuControl
			items={menuItems ?? []}
			isTopLevelMenu
			anchorEl={props.anchorEl}
			onClose={props.onClose}
			blockReturnFocus={props.blockReturnFocus}
			isOpen={props.open}
			position={props.position}
			shouldFocus
			placement={props.placement}
			popperClassName={props.popperClassName}
			popperStyle={props.popperStyle}
			menuStyle={props.menuStyle}
			popperOffset={props.popperOffset}
			dataAttributes={props.dataAttributes}
		/>
	)
}
