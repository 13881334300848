import React from 'react'

import { DisplayField } from '../../controls/Field'
import { e_Overflow } from '../../enums/e_Overflow'
import { e_LabelPosition } from '../../enums/e_LabelPosition'
import { e_TextAlignment } from '../../enums/e_TextAlignment'
import { FieldContainer } from '../FieldContainer/FieldContainer'
import type { IFieldData, IFieldGroup } from './types/Field.types'

const mapDataToComponent = (data: IFieldData | IFieldGroup, id: string, groupId: string) => {
	const isValidField = 'displayName' in data && 'displayValue' in data
	if (isValidField) {
		return (
			<DisplayField
				key={id}
				id={id}
				overflow={e_Overflow.showEllipsis}
				content={data.displayValue}
				label={data.displayName}
				labelPosition={e_LabelPosition.left}
				textAlignment={e_TextAlignment.left}
				wrapText
				disableBorder
				disableLabelIdLinking
			/>
		)
	}

	const isValidGroup = 'children' in data && 'syncWidthType' in data
	if (isValidGroup) {
		const inferedGroupId = data.syncWidthType === 'inherit' ? groupId : id
		const children = data.children.map((child, index) => mapDataToComponent(child, `${id}_${index}`, inferedGroupId))
		return (
			<FieldContainer groupId={inferedGroupId} key={id} {...data.style}>
				{children}
			</FieldContainer>
		)
	}

	return <></>
}

export const FieldContainerMapper = (data: IFieldGroup, id: string, groupId?: string) => {
	return mapDataToComponent(data, id, groupId ?? id)
}
