import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ISnackbarProps } from 'src/interfaces/IModalProps'
import { ConfirmationModal } from './ConfirmationModal'
import { modalManagerActions, modalManagerSelectors } from './duck'
import { ErrorModal } from './ErrorModal'
import { MetaModelManagerModal } from './MetaModelManagerModal'
import { modalTypes } from './modalTypes'
import { NotificationModal } from './NotificationModal'
import { ProgressModal } from './ProgressModal'

import { SnackModal } from './SnackModal'

const MODAL_COMPONENTS = {
	[modalTypes.NOTIFICATION]: NotificationModal,
	[modalTypes.META_MODEL_MANAGER]: MetaModelManagerModal,
	[modalTypes.CONFIRMATION]: ConfirmationModal,
	[modalTypes.ERROR]: ErrorModal,
	[modalTypes.SNACKBAR]: SnackModal,
	[modalTypes.PROGRESS]: ProgressModal,
}

/**
 * A modal manager container component - renders open modals/dialogs, e.g. error modals, confirmation modals etc.
 */
export const ModalManager = () => {
	const modals = useSelector(modalManagerSelectors.selectModals)
	const progressModals = useSelector(modalManagerSelectors.selectProgressModals)

	const dispatch = useDispatch()
	const hideModal = (id: string) => dispatch(modalManagerActions.hideModal(id))

	// Convert all modal objects to same format - add type and unique id to Page Modal and Progress.
	// The "ID" is only used as React key in Page Modal and Progress.
	const allModals = [
		...Object.entries(modals).map(([modalId, modal]) => ({ ...modal, id: modalId })),
		...progressModals.map((pageModal, index) => ({ ...pageModal, type: modalTypes.PROGRESS, id: 'Progress' + index })),
	]

	// Only show progress modals if there are no modals "on top" of it, i.e. no modals (except snackbars) that have a more recent timestamp
	const filteredModals = allModals
		.filter((progressModal, progressIndex) => {
			if (progressModal.type !== modalTypes.PROGRESS) {
				return true
			}

			const progressIsObstructedByModal = (modal: { timestamp: number; type: modalTypes }, index: number) =>
				modal.timestamp >= progressModal.timestamp && modal.type !== modalTypes.SNACKBAR && index !== progressIndex

			if (allModals.find(progressIsObstructedByModal)) {
				return false
			}
			return true
		})
		.sort((a, b) => a.timestamp - b.timestamp) // Ensure modals are presented in the order they arrive

	const modalSnackbars = filteredModals
		.filter((modal) => modal.type === 'MODAL_TYPE_SNACKBAR')
		.map((modal) => {
			return {
				...(modal.props as ISnackbarProps),
				hideModal: () => hideModal(modal.id),
			} // hideModal is used by neither ModalPage nor ProgressModal
		})

	const modalComponents = filteredModals
		.filter((modal) => modal.type !== 'MODAL_TYPE_SNACKBAR')
		.map((modal) => {
			const ModalComponent = MODAL_COMPONENTS[modal.type]

			return <ModalComponent key={modal.id} {...modal.props} hideModal={() => hideModal(modal.id)} /> // hideModal is used by neither ModalPage nor ProgressModal
		})

	modalComponents.push(<SnackModal key="snackModal" snackbarModalProps={modalSnackbars} />)

	return <>{modalComponents}</>
}
