import type { ColumnState } from '@ag-grid-community/core'
import { COLUMN_STATE_PREFIX, FILTER_MODEL_PREFIX, EXPANDED_ROW_NODES_PREFIX } from '../consts'
import { localStorageService, sessionStorageService } from '../../utils/storageService'

type ColumnStateInStorage =
	| { columnState: ColumnState[]; originalColumnState: ColumnState[]; resizedColumnIds: string[] }
	| undefined
type FilterModelInStorage = { [key: string]: any } | undefined
type ExpandedRowNodesInStorage = { [id: string]: boolean } | undefined

/* Column State */
export const readColumnStateFromLocalStorage = (id: string) =>
	localStorageService.read<ColumnStateInStorage>(`${COLUMN_STATE_PREFIX}_${id}`)

export const writeColumnStateToLocalStorage = (id: string, state: ColumnStateInStorage) =>
	localStorageService.write(`${COLUMN_STATE_PREFIX}_${id}`, state)

export const deleteColumnStateFromLocalStorage = (id: string) =>
	localStorageService.delete(`${COLUMN_STATE_PREFIX}_${id}`)

/* Filter Model */
export const readFilterModelFromSessionStorage = (id: string) =>
	sessionStorageService.read<FilterModelInStorage>(`${FILTER_MODEL_PREFIX}_${id}`)

export const writeFilterModelToSessionStorage = (id: string, filterModel: FilterModelInStorage) =>
	sessionStorageService.write(`${FILTER_MODEL_PREFIX}_${id}`, filterModel)

export const deleteFilterModelFromSessionStorage = (id: string) =>
	sessionStorageService.delete(`${FILTER_MODEL_PREFIX}_${id}`)

/* Expaned Row Nodes */
export const readExpandedRowNodesFromSessionStorage = (id: string) =>
	sessionStorageService.read<ExpandedRowNodesInStorage>(`${EXPANDED_ROW_NODES_PREFIX}_${id}`)

export const writeExpandedRowNodesToSessionStorage = (id: string, rowNodes: ExpandedRowNodesInStorage) => {
	const existingNodes = sessionStorageService.read<ExpandedRowNodesInStorage>(`${EXPANDED_ROW_NODES_PREFIX}_${id}`)
	sessionStorageService.write(`${EXPANDED_ROW_NODES_PREFIX}_${id}`, { ...existingNodes, ...rowNodes })
}

export const deleteExpandedRowNodesFromSessionStorage = (id: string) =>
	sessionStorageService.delete(`${EXPANDED_ROW_NODES_PREFIX}_${id}`)
