import React, { useCallback, useEffect } from 'react'
import { SelectionCheckMark } from '.'
import type { GridApi, IRowNode } from '@ag-grid-community/core'
import type { ITableContext, TData } from '../Table.types'
import { refreshCheckMarkColumn } from '../utils/selectionUtils'

interface IProps {
	api: GridApi<TData>
	eGridHeader: HTMLElement
	node?: IRowNode<TData>
	inPreviewMode?: boolean
	tableContext: ITableContext
}

/**
 * We don't want to render this at all if we don't use multiselect.
 * We should therefore assume that multiSelect is true when we are in the context of this component.
 */
export const HeaderCheckMark = (props: IProps) => {
	const isMultiSelectColumn = true

	const handleCheckboxColumnClicked = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent | KeyboardEvent) => {
			e.stopPropagation()
			e.preventDefault()

			switch (props.tableContext.checkMarkValue) {
				case true:
					props.api.deselectAllFiltered()
					props.tableContext.checkMarkValue = false
					break
				case false:
				case 'mixed':
					props.api.selectAllFiltered()
					props.tableContext.checkMarkValue = true
					break
			}
			refreshCheckMarkColumn(props.api)
		},
		[props.api, props.tableContext]
	)

	const handleSelectionCheckmarkClicked = useCallback(
		(event: { originalEvent: React.MouseEvent | React.KeyboardEvent | KeyboardEvent }) => {
			handleCheckboxColumnClicked(event.originalEvent)
		},
		[handleCheckboxColumnClicked]
	)

	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			if (e.key === ' ') {
				isMultiSelectColumn && handleCheckboxColumnClicked(e)
			}
		}
		props.eGridHeader.addEventListener('keydown', onKeyDown)
		return () => {
			props.eGridHeader.removeEventListener('keydown', onKeyDown)
		}
	}, [isMultiSelectColumn, handleCheckboxColumnClicked, props.eGridHeader])

	return (
		<SelectionCheckMark
			node={props.node}
			value={props.tableContext.checkMarkValue ?? props.inPreviewMode}
			onClick={handleSelectionCheckmarkClicked}
			type={props.tableContext.utilizeSquareSelectionCheckmarks ? 'square' : 'circle'}
			newCheckmark={props.tableContext.utilizeNewCheckmarks}
		/>
	)
}
