import { NumberInput } from '../../../../NumberInput'
import React, { useEffect, useRef } from 'react'
import type { ICellRenderer } from '../../../Table.types'
import { e_TextAlignment } from '../../../../../enums/e_TextAlignment'

interface ICellRendererNumber extends ICellRenderer {
	value: number | null | undefined
	dataType: 'int' | 'float'
	isPercentNumber: boolean
	onBlur: () => void
	initialKey?: string
}

export const CellRendererNumber = (props: ICellRendererNumber) => {
	const ref = useRef<HTMLInputElement>(null)

	useEffect(() => {
		ref.current?.focus()
		ref.current?.select()
	}, [])

	return (
		<NumberInput
			ref={ref}
			value={props.value}
			type={props.dataType}
			className={props.className}
			onChange={props.setValue}
			textAlignment={e_TextAlignment.right}
			error={props.hasError}
			isPercentNumber={props.isPercentNumber}
			onBlur={props.onBlur}
			initialInputValue={props.initialKey}
		/>
	)
}
