export const gridCellNameKey = 'data-gridname'

export interface IStrictGridAreaProps {
	[gridCellNameKey]: string
	style: React.CSSProperties
}
export type IGridAreaProps = Partial<IStrictGridAreaProps>

export type GridAreaElement = React.ReactElement<IStrictGridAreaProps>

export enum e_GridTrackType {
	fraction = 'fraction',
	pixel = 'pixel',
	auto = 'auto',
}

interface IGridFractions {
	type: e_GridTrackType.fraction
	length: number
	repeat?: number
}
interface IGridPixel {
	type: e_GridTrackType.pixel
	length: number
	repeat?: number
}
interface IGridAuto {
	type: e_GridTrackType.auto
	repeat?: number
}

export type IGridTrack = IGridFractions | IGridPixel | IGridAuto

export type IGridArea = {
	row?: number
	col?: number
	rowSpan?: number
	colSpan?: number
}
type IGridAreaObject = { [name: string]: IGridArea | undefined }

export interface IGridLayout {
	maxWidth?: number
	columns: IGridTrack[]
	rows: IGridTrack[]
	areas: IGridAreaObject
}
