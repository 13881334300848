import React from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'

import { DragSource } from '../utils/DragSource'
import { Checkbox } from '../Checkbox'
import { Icon } from '../Icon'
import type { DragSourceMonitor } from 'react-dnd'
import { DropIndicator } from '../utils/DropIndicator'
import { DefaultListItemRenderer } from './DefaultListItemRenderer'
import type { IListItem, IListNode } from './IListNode'
import { e_ListNode } from './IListNode'

interface IListNodeProps<T> {
	item: IListNode<T>
	onClick?: (id: string, e: React.MouseEvent) => void
	onDoubleClick?: (id: string, e: React.MouseEvent) => void
	onFocus?: (id: string, e: React.FocusEvent) => void

	onCheck?: (id: string) => void
	displayCheckbox?: boolean
	checkboxRounded?: boolean
	placeCheckboxRight?: boolean

	selected?: boolean
	focused?: boolean
	disabled?: boolean
	tabStop?: boolean

	borderBelowItem?: boolean
	hideSelectionColor?: boolean
	zebraStriped?: boolean
	// usikker på disse:
	focusStyle?: 'background' | 'border' | 'borderAndBackground' // 'none' 'default'?
	multiLine?: number

	dragSourceType?: string
	startDrag?: (id: string, monitor: DragSourceMonitor) => void
	customPreview?: { element: Element; offsetX?: number; offsetY?: number }
	dragCompleted?: () => void
	enableDrag?: boolean
	useDragHandle?: boolean
	placeDragHandleLeft?: boolean
	dropIndicator?: 'top' | 'bottom'

	siblingIndex?: number
	siblingCount?: number

	itemRenderer?: (item: IListItem<T> & { isSelected: boolean; isDisabled: boolean; isFocused?: boolean }) => JSX.Element
	children?: React.ReactNode[]

	nodeRef?: React.Ref<HTMLDivElement>
}

const classes = createStyle((theme) => ({
	item: {
		flex: 1,
		display: 'grid',
		gridTemplateColumns:
			'[left-draghandle-start] auto [left-draghandle-end left-checkbox-start] auto [left-checkbox-end main-start] 1fr [main-end right-checkbox-start] auto [right-checkbox-end right-draghandle-start] auto [right-draghandle-end]',
		padding: 'var(--item-padding, 2px 8px)',
		cursor: 'pointer',
		position: 'relative',
		outline: 'none',
		'&:hover': {
			background: theme.colors.list.hoveredBackground,
		},
		'&$zebraStriped:hover': {
			background: `color-mix(in srgb, ${theme.colors.list.hoveredBackground} 30%, transparent)`,
		},
	},
	outlineFocusIndicator: {
		'&:focus::before': {
			content: "''",
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			borderWidth: theme.controls.input.borderWidth,
			borderColor: theme.controls.input.focusedColors.border,
			borderStyle: 'solid',
			pointerEvents: 'none',
		},
	},
	zebraStriped: {
		background: theme.palette.background.neutralLighter,
	},
	borderBelowItem: {
		borderBottom: '1px solid ' + theme.colors.list.border,
	},
	selected: {
		background: theme.controls.list.checkedColors.background,
		color: theme.controls.list.checkedColors.color,
		'&:hover': {
			background: theme.controls.list.checkedHoverColors.background,
			color: theme.controls.list.checkedHoverColors.color,
		},
	},
	disabled: {
		pointerEvents: 'none',
	},

	leftCheckbox: {
		gridColumn: 'left-checkbox',
		marginRight: 8,
		alignSelf: 'center',
	},
	rightCheckbox: {
		gridColumn: 'right-checkbox',
		marginLeft: 12,
		alignSelf: 'center',
	},

	groupHeader: {
		gridColumn: 'fullwidth',
		color: theme.palette.primary.themePrimary,
		fontWeight: '600',
		marginTop: '8px',
		padding: '8px',
	},
	borderTop: {
		borderTop: '1px solid ' + theme.colors.list.border,
	},
	dragHandleLeft: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'grab',
		gridColumn: 'left-draghandle',
		marginRight: 8,
	},
	dragHandleRight: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'grab',
		gridColumn: 'right-draghandle',
		marginLeft: 8,
	},
	fullWidth: {
		gridColumn: 'fullwidth',
	},
	itemRendererWrapper: {
		gridColumn: 'main',
		display: 'flex',
		alignItems: 'center',
		overflowX: 'hidden',
	},
	dropIndicator: {
		display: 'flex',
	},
}))

const ListNodeBase = <T,>(props: IListNodeProps<T>) => {
	if (props.item.type === e_ListNode.section) {
		return (
			<div
				className={classNames(classes.fullWidth, props.siblingIndex !== 0 && classes.borderTop)}
				role="group"
				data-group-index={props.siblingIndex}
			>
				{props.item.caption && <label className={classes.groupHeader}>{props.item.caption}</label>}
				{props.children}
			</div>
		)
	}

	const content = (
		<div className={classes.itemRendererWrapper}>
			{props.itemRenderer ? (
				props.itemRenderer({
					...props.item,
					isDisabled: !!props.disabled,
					isSelected: !!props.selected,
					isFocused: props.focused,
				})
			) : (
				<DefaultListItemRenderer item={props.item} multiLineText={props.multiLine} />
			)}
		</div>
	)

	const checkbox = getItemCheckbox(props)
	const dragHandle = getDragHandle(props)

	const { id, screenTip } = props.item

	return (
		<>
			<DragSource
				dragSourceType={props.dragSourceType}
				initiateDrag={props.startDrag ? (monitor) => props.startDrag!(id, monitor) : undefined}
				dragCompleted={props.dragCompleted}
				disableDrag={props.disabled || !props.enableDrag || props.useDragHandle}
				customPreview={props.customPreview}
			>
				<DropIndicator
					height={2}
					hoveredPos={props.dropIndicator || 'bottom'}
					visible={!!props.dropIndicator}
					className={classes.dropIndicator}
				>
					<div
						ref={props.nodeRef}
						className={classNames(
							classes.item,
							props.zebraStriped && classes.zebraStriped,
							props.selected && !props.hideSelectionColor && classes.selected,
							classes.outlineFocusIndicator,
							props.borderBelowItem && classes.borderBelowItem,
							props.disabled && classes.disabled
						)}
						tabIndex={props.tabStop ? 0 : -1}
						onClick={props.onClick && ((e) => props.onClick!(id, e))}
						onDoubleClick={props.onDoubleClick && ((e) => props.onDoubleClick!(id, e))}
						onFocus={props.onFocus && ((e) => props.onFocus!(id, e))}
						aria-selected={props.selected}
						title={screenTip}
						role="option"
						data-rowid={id}
					>
						{props.placeDragHandleLeft && dragHandle}
						{!props.placeCheckboxRight && checkbox}
						{content}
						{props.placeCheckboxRight && checkbox}
						{!props.placeDragHandleLeft && dragHandle}
					</div>
				</DropIndicator>
			</DragSource>
		</>
	)
}

ListNodeBase.displayName = 'ListNode'
export const ListNode = React.memo(ListNodeBase) as typeof ListNodeBase

const roundedCheckboxClasses = createStyle((theme) => ({
	// Custom checkbox
	checkbox: {
		display: 'flex',
		position: 'relative',
		height: '100%',
		flexShrink: 0,
		alignItems: 'center',
		justifyContent: 'center',
		border: '1px solid transparent',
		outline: 'none',
		'&:focus': { border: '1px solid ' + theme.colors.body.focusBorder },
		'&[aria-disabled="true"]': { cursor: 'default' },
		'&[aria-disabled="false"]': { cursor: 'pointer' },
	},
	check: {
		width: 18,
		height: 18,
		lineHeight: 1,
		verticalAlign: 'top',
		position: 'relative',
		userSelect: 'none',
		'&::before': {
			content: "''",
			position: 'absolute',
			top: 1,
			right: 1,
			bottom: 1,
			left: 1,
			opacity: 0,
			borderRadius: '50%',
			background: theme.palette.primary.themePrimary,
			'$checkbox[aria-checked="true"] &, $checkbox[aria-checked="mixed"] &': {
				opacity: 1,
			},
			'$checkbox[aria-disabled="true"]&': {
				background: theme.colors.input.disabledBackground,
			},
			'$checkbox[aria-disabled="true"][aria-checked="true"] &, $checkbox[aria-disabled="true"][aria-checked="true"] &':
				{
					background: theme.colors.input.disabledText,
				},
		},
	},
	checkMark: {
		fontSize: 16,
		position: 'absolute',
		left: 0.5,
		top: 0,
		width: 18,
		height: 18,
		textAlign: 'center',
		verticalAlign: 'middle',
		opacity: 0,
		color: theme.palette.background.white,
		'$checkbox[aria-disabled="false"][aria-checked="true"] &': {
			opacity: 1,
			color: theme.palette.background.white + ' !important',
		},
		'$checkbox[aria-disabled="true"][aria-checked="true"] &': {
			opacity: 1,
			color: theme.colors.input.disabledBackground,
		},
		'$checkbox:hover[aria-disabled="false"][aria-checked="false"] &': {
			opacity: 1,
			color: theme.colors.list.text,
		},
	},
	circle: {
		fontSize: 18,
		left: 0,
		top: 0,
		width: 18,
		height: 18,
		color: theme.colors.body.focusBorder,
		'$checkbox[aria-disabled="false"][aria-checked="true"] &, $checkbox[aria-disabled="false"][aria-checked="mixed"] &':
			{
				color: theme.palette.background.white,
			},
		'$checkbox[disabled] &': {
			color: theme.colors.input.disabledText,
		},
		'$checkbox[aria-disabled="true"][aria-checked="true"] &, $checkbox[aria-disabled="true"][aria-checked="mixed"] &': {
			color: theme.colors.input.disabledBackground,
		},
	},
	mixedMark: {
		fontSize: 17,
		position: 'absolute',
		left: 0.2,
		top: 0,
		width: 18,
		height: 18,
		textAlign: 'center',
		verticalAlign: 'middle',
		opacity: 0,
		color: theme.palette.background.white,
		'$checkbox[aria-disabled="false"][aria-checked="mixed"] &': {
			opacity: 1,
			color: theme.palette.background.white + ' !important',
		},
		'$checkbox[aria-disabled="true"][aria-checked="mixed"] &': {
			opacity: 1,
			color: theme.colors.input.disabledBackground,
		},
	},
}))
const getItemCheckbox = (props: IListNodeProps<any>) => {
	if (props.displayCheckbox && props.item.id) {
		if (props.checkboxRounded) {
			return (
				<div
					role="checkbox"
					aria-checked={props.selected}
					aria-disabled="false"
					className={classNames(
						roundedCheckboxClasses.checkbox,
						props.placeCheckboxRight ? classes.rightCheckbox : classes.leftCheckbox
					)}
					onClick={() => props.onCheck?.(props.item.id!)}
				>
					<div className={roundedCheckboxClasses.check}>
						<i aria-hidden="true" className={roundedCheckboxClasses.checkMark + ' Fluent-StatusCircleCheckmark'} />
						<i aria-hidden="true" className={roundedCheckboxClasses.mixedMark + ' Fluent-StatusCircleBlock2'} />
						<i aria-hidden="true" className={roundedCheckboxClasses.circle + ' Fluent-CircleRing'} />
					</div>
				</div>
			)
		} else {
			return (
				<Checkbox
					checked={props.selected}
					onChange={() => props.onCheck?.(props.item.id!)}
					hasCheckMarkOnly
					className={props.placeCheckboxRight ? classes.rightCheckbox : classes.leftCheckbox}
					checkmarkSize={16}
				/>
			)
		}
	}
}

const getDragHandle = (props: IListNodeProps<any>) =>
	props.useDragHandle &&
	props.enableDrag &&
	'id' in props.item && (
		<DragSource
			dragSourceType={props.dragSourceType}
			initiateDrag={props.startDrag ? (monitor) => props.startDrag!(props.item.id!, monitor) : undefined}
			dragCompleted={props.dragCompleted}
			customPreview={props.customPreview}
			className={props.placeDragHandleLeft ? classes.dragHandleLeft : classes.dragHandleRight}
		>
			<Icon iconName="Fluent-GripperDotsVertical" />
		</DragSource>
	)
