import type { IIconSize } from '../../Icon'

export const useIconAndLabelUtils = (
	iconPosition: 'left' | 'right' | 'top' | 'bottom',
	size: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge' | 'none',
	iconSize?: IIconSize,
	label?: string,
	icon?: string
) => {
	const hasLabel = label && label !== ''
	const hasIcon = icon !== undefined && icon !== ''

	const positionIconAfterText = iconPosition === 'right' || iconPosition === 'bottom'
	const isContentVertical = iconPosition === 'top' || iconPosition === 'bottom'

	const sizeIsLarge = ['large', 'extraLarge'].includes(size)

	const fallbackIconSize = !hasLabel && sizeIsLarge ? 'size32' : 'size16'

	return {
		hasLabel,
		hasIcon,
		positionIconAfterText,
		isContentVertical,
		iconSize: iconSize ?? fallbackIconSize,
		sizeIsLarge,
	}
}
