import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStyle } from '@genusbiz/web-ui/theming'
import { Button, Text, DurationInput, Dialog } from '@genusbiz/web-ui/controls'
import { kubernetesActions } from 'src/features/Kubernetes/duck/kubernetesActions'
import { useTranslation } from 'react-i18next'
import { Callout } from '@genusbiz/web-ui/surfaces'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { defaultIcons } from 'src/consts/defaultIcons'
import { modalManagerActions } from 'src/features/ModalManager/duck'

const classes = createStyle({
	container: {
		display: 'flex',
		margin: '8px',
		padding: '8px',
		flexDirection: 'column',
		justifyContent: 'space-around',
		width: '500px',
	},
	inputFields: {
		display: 'flex',
		padding: '5px',
		flexDirection: 'row',
		alignItems: 'center',
	},
	availabilityWindowBufferInput: {
		margin: '5px',
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'row',
		margin: '8px',
		justifyContent: 'flex-end',
	},
	button: {
		marginLeft: '8px',
	},
})

interface IProps {
	setIsBufferModalOpen: (isBufferModalOpen: boolean) => void
}

export const BufferConfigurationModal = (props: IProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const helpButton = useRef<HTMLButtonElement>(null)
	const [isHelpCalloutOpen, setIsHelpCalloutOpen] = useState(false)

	const environmentAvailabilitySchedule = useSelector(kubernetesSelectors.selectEnvironmentAvailabilitySchedule)
	const [startupBufferDuration, setStartupBufferDuration] = useState<number>(
		environmentAvailabilitySchedule.bufferConfiguration.startupBufferInMinutes * 60 * 1000
	)
	const [shutdownBufferDuration, setShutdownBufferDuration] = useState<number>(
		environmentAvailabilitySchedule.bufferConfiguration.shutdownBufferInMinutes * 60 * 1000
	)

	const updateAvailabilitiyScheduleBuffer = () => {
		let startupBufferDurationInMinutes, shutdownBufferDurationInMinutes
		if (startupBufferDuration) {
			startupBufferDurationInMinutes = startupBufferDuration / 60 / 1000
		} else {
			startupBufferDurationInMinutes = 0
		}
		if (shutdownBufferDuration) {
			shutdownBufferDurationInMinutes = shutdownBufferDuration / 60 / 1000
		} else {
			shutdownBufferDurationInMinutes = 0
		}
		dispatch(
			kubernetesActions.setEnvironmentAvailabilityScheduleBuffer(
				startupBufferDurationInMinutes,
				shutdownBufferDurationInMinutes
			)
		)
		props.setIsBufferModalOpen(false)

		dispatch(
			modalManagerActions.showSnackbar({
				message: `Buffer configuration set to ${Math.abs(
					startupBufferDurationInMinutes
				)} minute(s) before, and ${shutdownBufferDurationInMinutes} minute(s) availability windows`,
				icon: defaultIcons.check,
			})
		)
	}
	return (
		<Dialog isOpen>
			<div className={classes.container}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginBottom: '0.5em',
						marginTop: '-0.5em',
					}}
				>
					<Text variant="h2">{t('AVAILABILITY_WINDOW:BUFFER_CONFIG')}</Text>
					<Button
						ref={helpButton}
						icon="Basic-QuestionMark"
						variant="inline"
						onClick={() => setIsHelpCalloutOpen(!isHelpCalloutOpen)}
					/>
					<Callout
						anchorElement={helpButton}
						isOpen={isHelpCalloutOpen}
						onClose={() => setIsHelpCalloutOpen(!isHelpCalloutOpen)}
					>
						<p>
							<Text>{t('AVAILABILITY_WINDOW:BUFFER_CALLOUT_MESSAGE_1')}</Text>
						</p>
						<p>
							<Text>{t('AVAILABILITY_WINDOW:BUFFER_CALLOUT_MESSAGE_2')}</Text>
						</p>
					</Callout>
				</div>
				<Text className={classes.availabilityWindowBufferInput}>{t('AVAILABILITY_WINDOW:BUFFER_DESCRIPTION')}</Text>
				<div className={classes.inputFields}>
					<div className={classes.availabilityWindowBufferInput}>
						<DurationInput
							onChange={(value) => {
								if (!value) {
									value = 0
								}
								setStartupBufferDuration(value)
							}}
							value={Math.abs(startupBufferDuration) as number | undefined}
							label={t('AVAILABILITY_WINDOW:STARTUP_BUFFER_DURATION')}
							onKeyDown={(event) => {
								if (event.key === 'Enter') {
									// Cancel the default action, if needed
									event.preventDefault()
									// Trigger the button element with a click

									updateAvailabilitiyScheduleBuffer()
								}
							}}
						/>
					</div>
					<div className={classes.availabilityWindowBufferInput}>
						<DurationInput
							onChange={(value) => {
								if (!value) {
									value = 0
								}
								setShutdownBufferDuration(value)
							}}
							value={shutdownBufferDuration as number | undefined}
							label={t('AVAILABILITY_WINDOW:SHUTDOWN_BUFFER_DURATION')}
							onKeyDown={(event) => {
								if (event.key === 'Enter') {
									// Cancel the default action, if needed
									event.preventDefault()
									// Trigger the button element with a click

									updateAvailabilitiyScheduleBuffer()
								}
							}}
						/>
					</div>
				</div>
				<div className={classes.buttonGroup}>
					<Button
						className={classes.button}
						label={t('GENERAL:SAVE')}
						onClick={updateAvailabilitiyScheduleBuffer}
						variant="primary"
					/>
					<Button
						className={classes.button}
						label={t('GENERAL:CANCEL')}
						onClick={() => props.setIsBufferModalOpen(false)}
					/>
				</div>
			</div>
		</Dialog>
	)
}
