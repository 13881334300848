import type { ReactNode } from 'react'
import React, { useEffect, useRef } from 'react'
import type { DropTargetMonitor } from 'react-dnd'
import { useDrop } from 'react-dnd'
import { usePrevious } from '../utils/usePrevious'
import type { IDragDropCoordinates } from './dragdrop'

interface IDropTargetProps {
	targetElementRef?: React.RefObject<HTMLElement>
	onHover?: (
		itemType: string | symbol | null,
		item: any,
		isOver: boolean,
		isDirectlyOver: boolean,
		clientOffset: IDragDropCoordinates | null,
		targetElement: Element
	) => void
	dropTargetTypes?: string[]
	onDrop?: (
		itemType: string | symbol | null,
		item: any,
		isOver: boolean,
		isDirectlyOver: boolean,
		clientOffset: IDragDropCoordinates | null,
		dropResult: any,
		id?: string | number
	) => void
	onCanDrop?: (
		itemType: string | symbol | null,
		item: any,
		isOver: boolean,
		isDirectlyOver: boolean,
		clientOffset: IDragDropCoordinates | null
	) => boolean
	id?: string | number
	disableDrop?: boolean
	children?: ReactNode | ReactNode[]
	onDragLeave?: (isDirectlyOver: boolean) => void
	onCanDropChange?: (canDrop: boolean) => void
	onDraggedItemTypeChange?: (itemType: string) => void
	className?: string
}

export const DropTarget = (props: IDropTargetProps) => {
	// The drop target can either work on an element provided in props or by rendering a local div.
	// Rendering with a local div may have side effects sunch as breaking flex layouts so it is encouranged to use it with element provided from outside.

	const dropTargetElementRef = useRef<HTMLDivElement>(null)

	const collectDropProps = (monitor: DropTargetMonitor) => {
		return {
			isOver: monitor.isOver(),
			isDirectlyOver: monitor.isOver({ shallow: true }),
			canDrop: monitor.canDrop(),
			itemType: monitor.getItemType(),
			item: monitor.getItem(),
		}
	}

	const handleCanDrop = (_item: any, monitor: DropTargetMonitor) => {
		if (props.disableDrop) {
			return false
		}

		if (!props.onCanDrop) {
			return false
		}

		return props.onCanDrop(
			monitor.getItemType(),
			monitor.getItem(),
			monitor.isOver(),
			monitor.isOver({ shallow: true }),
			monitor.getClientOffset()
		)
	}

	const handleHover = (_item: any, monitor: DropTargetMonitor) => {
		if (props.disableDrop) {
			return
		}

		if (!props.onHover) {
			return
		}

		return props.onHover(
			monitor.getItemType(),
			monitor.getItem(),
			monitor.isOver(),
			monitor.isOver({ shallow: true }),
			monitor.getClientOffset(),
			dropTargetElementRef.current as Element
		)
	}

	const handleDrop = (_item: any, monitor: DropTargetMonitor) => {
		if (props.disableDrop) {
			return undefined // the owner do not want to process drops
		}

		if (monitor.didDrop()) {
			return undefined // handled by nested component
		}

		if (!props.onDrop) {
			return undefined // onDrop is not defined
		}

		return props.onDrop(
			monitor.getItemType(),
			monitor.getItem(),
			monitor.isOver(),
			monitor.isOver({ shallow: true }),
			monitor.getClientOffset(),
			monitor.getDropResult(),
			props.id
		)
	}

	const [collectedProps, drop] = useDrop({
		accept: props.dropTargetTypes ? props.dropTargetTypes : ['GenusDragDropItem'],
		drop: handleDrop,
		hover: handleHover,
		canDrop: handleCanDrop,
		collect: collectDropProps,
	})

	const prevIsOver = usePrevious<boolean>(collectedProps.isOver)

	const { onDragLeave } = props

	useEffect(() => {
		if (onDragLeave && prevIsOver && !collectedProps.isOver) {
			onDragLeave(collectedProps.isDirectlyOver)
		}
	}, [collectedProps.isDirectlyOver, collectedProps.isOver, prevIsOver, onDragLeave])

	const { onCanDropChange, onDraggedItemTypeChange } = props

	useEffect(() => {
		onCanDropChange?.(collectedProps.canDrop)
	}, [collectedProps.canDrop, onCanDropChange])

	useEffect(() => {
		if (typeof collectedProps.itemType === 'string') {
			onDraggedItemTypeChange?.(collectedProps.itemType)
		}
	}, [collectedProps.itemType, onDraggedItemTypeChange])

	if (props.disableDrop) {
		return <>{props.children}</>
	}

	drop(props.targetElementRef ? props.targetElementRef : dropTargetElementRef)

	if (props.targetElementRef) {
		return <> {props.children}</>
	}

	return (
		<div className={props.className} ref={dropTargetElementRef}>
			{props.children}
		</div>
	)
}
