export const DATA_PORTAL_ELEMENT = 'data-portal-element'
export const DATA_BLOCKING_MODAL_ELEMENT = 'data-blocking-modal-element'

const getPortalElements = () => document.querySelectorAll(`[${DATA_PORTAL_ELEMENT}]`)

export const getTopmostPortalElement = () => {
	const modals = document.querySelectorAll(`[${DATA_PORTAL_ELEMENT}]`)
	return modals[modals.length - 1] as Element | undefined
}

export const getTopmostBlockingElement = () => {
	const modals = document.querySelectorAll(`[${DATA_BLOCKING_MODAL_ELEMENT}]`)
	return modals[modals.length - 1] as Element | undefined
}

export const isTopmostPortalElement = (element: HTMLDivElement) => getTopmostPortalElement() === element
export const isBehindBlockingElement = (element: HTMLDivElement) => {
	const portalElements = getPortalElements()

	const portalElementsArray = Array.from(portalElements)
	const index = Array.from(portalElementsArray).findIndex((portalEl) => portalEl === element)
	if (index !== -1) {
		return portalElementsArray.slice(index).some((el) => el.hasAttribute(DATA_BLOCKING_MODAL_ELEMENT))
	}

	return false
}

export const isNotChildOfTopmostBlockingElement = (element: HTMLElement) => {
	const topmostPortalElement = getTopmostBlockingElement()
	return topmostPortalElement && !topmostPortalElement.contains(element)
}
