/**
 * Reducer for managing modals
 */
import { Reducer } from '@reduxjs/toolkit'
import { IModalProps, IProgressModalProps } from 'src/interfaces/IModalProps'
import { generateGuid } from 'src/utils/generateGuid'
import { modalTypes } from '../modalTypes'
import { IModelManagerActions } from './IModelManagerActions'
import { modalManagerTypes } from './modalManagerTypes'

interface IModal {
	type: modalTypes
	props: IModalProps
	timestamp: number
}
interface IProgressModal {
	props: IProgressModalProps
	timestamp: number
}

export interface IModalManagerState {
	modals: { [id: string]: IModal }
	progressModals: IProgressModal[]
}

const initialState: IModalManagerState = {
	modals: {},
	progressModals: [],
}

export const modalManagerReducer: Reducer<IModalManagerState, IModelManagerActions> = (
	state = initialState,
	action: IModelManagerActions
) => {
	switch (action.type) {
		case modalManagerTypes.SHOW_MODAL: {
			return {
				...state,
				modals: {
					...state.modals,
					[generateGuid()]: {
						type: action.modalType,
						props: action.modalProps,
						timestamp: Date.now(),
					},
				},
			}
		}

		case modalManagerTypes.HIDE_MODAL: {
			const newModals = { ...state.modals }

			delete newModals[action.id]

			return {
				...state,
				modals: newModals,
			}
		}

		case modalManagerTypes.SHOW_PROGRESS: {
			return {
				...state,
				progressModals: [...state.progressModals, { props: action.modalProps, timestamp: Date.now() }],
			}
		}

		case modalManagerTypes.HIDE_PROGRESS: {
			const newProgressModals = state.progressModals.slice(0, -1)

			return {
				...state,
				progressModals: newProgressModals,
			}
		}

		default:
			return state
	}
}
