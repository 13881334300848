import type { DOMConversionMap, DOMConversionOutput, ElementFormatType, LexicalNode } from 'lexical'
import { $applyNodeReplacement, ParagraphNode } from 'lexical'

export class CustomParagraphNode extends ParagraphNode {
	static getType() {
		return 'custom-paragraph'
	}

	static clone(node: CustomParagraphNode) {
		return new CustomParagraphNode(node.__key)
	}

	static importDOM(): DOMConversionMap | null {
		return {
			p: () => ({
				conversion: convertCustomParagraphElement,
				priority: 1,
			}),
		}
	}

	exportJSON() {
		return {
			...super.exportJSON(),
			type: 'custom-paragraph',
			version: 1,
		}
	}
}

export function $createCustomParagraphNode(): CustomParagraphNode {
	return $applyNodeReplacement(new CustomParagraphNode())
}

function convertCustomParagraphElement(element: HTMLElement): DOMConversionOutput {
	const node = $createCustomParagraphNode()
	if (element.style) {
		node.setFormat(element.style.textAlign as ElementFormatType)
		const indent = parseInt(element.style.textIndent, 10) / 20
		if (indent > 0) {
			node.setIndent(indent)
		}
	}
	if (element.className) {
		const classNames = element.className.split(' ')
		const classes = new Set(classNames)
		if (classes.has('ql-align-right')) {
			node.setFormat('right')
		} else if (classes.has('ql-align-center')) {
			node.setFormat('center')
		} else if (classes.has('ql-align-justify')) {
			node.setFormat('justify')
		} else if (classes.has('ql-indent-1')) {
			node.setIndent(1)
		} else if (classes.has('ql-indent-2')) {
			node.setIndent(2)
		} else if (classes.has('ql-indent-3')) {
			node.setIndent(3)
		}
		const indentClassName = classNames.find((className) => className.startsWith('ql-indent-'))
		if (indentClassName) {
			const indent = parseInt(indentClassName.split('-')[2], 10)
			if (indent > 0) {
				node.setIndent(indent)
			}
		}
	}
	return { node }
}

export function $isCustomParagraphNode(node: LexicalNode | null | undefined): node is CustomParagraphNode {
	return node instanceof CustomParagraphNode
}
