import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../theming'

const classes = createStyle({
	scrollableArea: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignSelf: 'stretch',
	},
	scrollbox: {
		overflow: 'auto',
		flex: '1 1 0',
	},
})

interface IScrollableAreaProps {
	className?: string
	scrollContentClassName?: string
	children?: React.ReactElement | React.ReactElement[] | null
}

export const ScrollableArea = (props: IScrollableAreaProps) => {
	return (
		<div className={classNames(classes.scrollableArea, props.className)}>
			<div className={classNames(classes.scrollbox, props.scrollContentClassName)}>{props.children}</div>
		</div>
	)
}
