import React from 'react'
import type { AgGridReact } from '@ag-grid-community/react'
import type { IPaginationDisplay } from '../hooks/usePagination'
import type { TData } from '../Table.types'
import { PaginationBar } from '../../PaginationBar/PaginationBar'

export interface IPaginationStatusBar {
	gridRef: React.RefObject<AgGridReact<TData>>
	paginationDisplay: IPaginationDisplay
	dataAttributes?: Record<string, string>
}

export const PaginationStatusBar = (props: IPaginationStatusBar) => {
	const paginationDisplay = props.paginationDisplay
	const showingFirstPage = paginationDisplay.currentPage === 0
	const showingLastPage = paginationDisplay.currentPage === paginationDisplay.totalNumberOfPages - 1 //seemingly a bug in ag grid that returns total number of pages + 1

	const handleNextClick = () => {
		props.gridRef.current?.api.paginationGoToNextPage()
	}
	const handlePreviousClick = () => {
		props.gridRef.current?.api.paginationGoToPreviousPage()
	}
	const handleResetClick = () => {
		props.gridRef.current?.api.paginationGoToFirstPage()
	}
	const handleLastClick = () => {
		props.gridRef.current?.api.paginationGoToLastPage()
	}

	const objectFrom = paginationDisplay.currentPage * paginationDisplay.numberOfObjects
	const objectTo = Math.min(
		(paginationDisplay.currentPage + 1) * paginationDisplay.numberOfObjects,
		paginationDisplay.totalNumberOfObjects
	)
	return (
		<PaginationBar
			dataAttributes={props.dataAttributes}
			objectCount={paginationDisplay.totalNumberOfObjects}
			objectFrom={objectFrom}
			objectTo={objectTo}
			showTotal
			nextEnabled={!showingLastPage}
			onNextClick={handleNextClick}
			previousEnabled={!showingFirstPage}
			onPreviousClick={handlePreviousClick}
			firstEnabled={!showingFirstPage}
			onFirstClick={handleResetClick}
			lastEnabled={!showingLastPage}
			onLastClick={handleLastClick}
		/>
	)
}
