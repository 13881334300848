import type { KeyboardEvent } from 'react'
import React, { useRef } from 'react'
import classNames from 'clsx'
import { FormControl } from '../FormControl'
import { Checkbox, type ICheckmarkSize } from './Checkbox'
import { createStyle } from '../../theming'
import type { IFormControl } from '../FormControl/FormControl.types'
import { useId } from '../hooks/useId'
import type { ICheckboxOption } from './Checkbox.types'

const classes = createStyle({
	horizontal: {
		display: 'flex',
		flexDirection: 'row',
		'& > *:not(:last-child)': {
			marginRight: 16,
		},
		marginRight: 4,
		marginLeft: 4,
		minHeight: 18,
		alignItems: 'center',
	},
	vertical: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: 18,
	},

	wrap: {
		flexWrap: 'wrap',
	},
})

interface ICheckboxGroup<T> extends IFormControl {
	values: T[] | undefined | null
	options?: ICheckboxOption<T>[]
	onChange?: (values: T[]) => void
	orientation?: 'vertical' | 'horizontal' | 'horizontalMultiline'
	screenTip?: string
	autoFocus?: boolean
	checkmarkSize?: ICheckmarkSize
}

export function CheckboxGroup<T extends string | number>(props: ICheckboxGroup<T>) {
	const id = useId('checkbox-group', props.id)

	const selectedIndex = props.options?.findIndex((option) => props.values?.includes(option.value))
	const firstSelectableIndex = props.options?.findIndex((option) => !option.disabled)
	const noSelectedIndex = selectedIndex === -1
	const focusedIndex = useRef<number | undefined>(
		selectedIndex === -1 || selectedIndex === undefined ? firstSelectableIndex : selectedIndex
	)

	const orientationClassName = classNames(
		props.orientation === 'vertical' && classes.vertical,
		(props.orientation === 'horizontal' || props.orientation === 'horizontalMultiline') && classes.horizontal,
		props.orientation === 'horizontalMultiline' && classes.wrap,
		'checkboxGroup'
	)

	const updateFocusedIndex = (clickedId: T) => {
		const clickedIndex = props.options?.findIndex((option) => option.value === clickedId)
		focusedIndex.current = clickedIndex
	}

	const changeValue = (newId: T, value: boolean) => {
		updateFocusedIndex(newId)
		if (!props.onChange) {
			return
		}

		if (value && (!props.values || !props.values.includes(newId))) {
			const nextValues = props.values ? [...props.values] : []
			nextValues.push(newId)

			props.onChange(nextValues)

			return
		}

		if (!value && props.values) {
			const nextValues = props.values ? [...props.values] : []
			nextValues.push(newId)
			props.onChange(props.values.filter((valueId) => valueId !== newId))
		}
	}

	const getCurrentIndex = () => {
		if (focusedIndex.current !== undefined) {
			return focusedIndex.current
		}

		if ((selectedIndex === -1 || selectedIndex === undefined) && firstSelectableIndex) {
			return firstSelectableIndex
		}

		return selectedIndex ?? 0
	}

	const handleKeyDown = (e: KeyboardEvent) => {
		if (
			(e.key === 'ArrowDown' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowLeft') &&
			!props.readOnly &&
			!props.disabled &&
			props.options &&
			props.onChange
		) {
			const currentIndex = getCurrentIndex()
			const adjustment = e.key === 'ArrowUp' || e.key === 'ArrowLeft' ? -1 : +1
			const adjustedIndex = currentIndex + adjustment
			const nextIndex = adjustedIndex > 0 ? adjustedIndex : 0
			const checkbox = document.getElementById(`${id}-${nextIndex}`)
			checkbox?.focus()
			focusedIndex.current = checkbox ? nextIndex : focusedIndex.current
		}
	}

	return (
		<FormControl
			id={id}
			label={props.label}
			labelPosition={props.labelPosition}
			hideLabel={props.hideLabel}
			labelProps={props.labelProps}
			labelBold={props.labelBold}
			labelIcon={props.labelIcon}
			labelIconColor={props.labelIconColor}
			labelContentLayout={props.labelContentLayout}
			disabled={props.disabled}
			error={props.error}
			warning={props.warning}
			labelSubText={props.labelSubText}
			validationText={props.validationText}
			validationTextPosition={props.validationTextPosition}
			subText={props.subText}
			reserveHelperTextSpace={props.reserveHelperTextSpace}
			disableBorder
			margin={props.margin}
			readOnly={props.readOnly}
			required={props.required}
			className={props.className}
			screenTip={props.screenTip}
			onClick={props.onClick}
			onMouseDown={props.onMouseDown}
			dataAttributes={props.dataAttributes}
		>
			<div className={orientationClassName} id={id} role="group" onKeyDown={handleKeyDown}>
				{props.options &&
					props.options.map((option, i) => {
						const isChecked = props.values ? props.values.includes(option.value) : false
						return (
							<Checkbox
								key={option.value} //If the data does not have unique values, the control will behave unexpectedly anyways. Thus we can assume the value is unique for the purposes of key
								id={`${id}-${i}`}
								index={i}
								dataAttributes={{
									...props.dataAttributes,
									'data-autofocus': String(
										props.autoFocus && (i === selectedIndex || (noSelectedIndex && i === firstSelectableIndex))
									),
								}}
								valueLabel={option.label}
								onChange={(value: boolean) => changeValue(option.value, value)}
								checked={isChecked}
								disabled={props.disabled || option.disabled}
								readOnly={props.readOnly}
								disableUniformHeight={props.orientation === 'vertical'}
								wordWrapValueLabel={props.orientation === 'vertical'}
								checkmarkSize={props.checkmarkSize}
							/>
						)
					})}
			</div>
		</FormControl>
	)
}
