import React from 'react'

import { useDispatch } from 'react-redux'
import { Icon } from '@genusbiz/web-ui/controls/Icon'
import { Dialog } from '@genusbiz/web-ui/controls/Dialog'
import { createStyle } from '@genusbiz/web-ui/theming'
import { modalTypes } from './modalTypes'
import { modalManagerActions } from './duck'

const classes = createStyle({
	notificationIcon: {
		display: 'table-cell',
		verticalAlign: 'top',
		paddingRight: 8,
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		padding: '1px 0px',
	},
	messageArea: {
		whiteSpace: 'pre-wrap',
	},
})

interface INotificationModal {
	title?: string
	icon?: string
	iconColor?: string
	message: string
	hideModal: (type: modalTypes) => void
}

export const NotificationModal = (props: INotificationModal) => {
	const dispatch = useDispatch()

	const onClose = () => dispatch(modalManagerActions.confirm())

	return (
		<Dialog
			isOpen
			title={props.title}
			width={500}
			height={'auto'}
			buttonDescriptions={[
				{
					title: 'OK',
					isEnabled: true,
					isDefault: true,
					isCancel: false,
					onClick: () => {
						props.hideModal(modalTypes.NOTIFICATION)
						onClose()
					},
				},
			]}
			disableCancelOnClickOutside
			disableCancelOnEscape
		>
			<div className={classes.content}>
				{props.icon && (
					<Icon iconClassName={props.icon + ' ' + classes.notificationIcon} size="medium" color={props.iconColor} />
				)}
				<div className={classes.messageArea}>{props.message}</div>
			</div>
		</Dialog>
	)
}
