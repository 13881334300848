import React, { useMemo, useRef } from 'react'
import type { CSSProperties } from 'react'
import type { CellData, IColumnDef, ISortedField, RowData } from '../../../controls/AgGridTable/Table.types'
import { e_RenderType, e_RowSelectionType } from '../../../controls/AgGridTable/Table.types'
import { createStyle } from '../../../theming'
import type { ITableFieldDescription, IDataWithIdentifier, ISortData } from '../types'
import { AgGridTable } from './../../../controls/AgGridTable'
import type { IActionEntry } from '../types/IActionDescription'
import { e_actionType } from '../types/IActionDescription'
import type { IMasterViewColumnItemDescription } from '../types/IMasterViewColumnItemDescription'
import type { MenuItem } from '../../../controls/Menu'
import { e_MenuItemType } from '../../../controls/Menu'
import type { e_SortOrder } from '../../../controls/AgGridTable/enums/e_SortOrder'
import isEqual from 'lodash/isEqual'
import { e_DataType } from '../../../controls/AgGridTable/enums/e_DataType'
import type {
	FormatDataValueEventHandler,
	GetFormattingStylesEventHandler,
	GetIconValueEventHandler,
} from '../types/MasterDetailEventHandlers'
import { useTranslation } from '../../../translation'
import { formatPropertyValue, validateValue } from '../utils/valueUtils'
import { e_MasterDetailTargetElement } from '../types/e_MasterDetailTargetElement'
import { e_MasterDetailPropertyVisualization } from '../types/e_MasterDetailPropertyVisualization'
import { e_MasterDetailDataInterpretation, e_MasterDetailValueDataType } from '../types/MasterDetailDataType'

const classes = createStyle({
	masterTable: {
		display: 'grid',
		flex: 1,
		flexDirection: 'column',
		overflow: 'auto',
		contain: 'strict',
		padding: '16px',
	},
})

interface IMasterDetailAgTableViewProps {
	id: string
	items: IDataWithIdentifier[]

	selectedIds: string[]
	setSelectedIds: (ids: string[]) => void

	activeId: string | undefined
	setActiveId: (id: string | undefined) => void
	columnDescriptions: ITableFieldDescription[]
	columnGroups?: IMasterViewColumnItemDescription[]

	sortedColumns?: ISortData[]
	sortColumns?: (sortedColumns: ISortData[]) => void

	isMultiSelect?: boolean

	itemActions?: IActionEntry[]

	handleItemAction?: (actionId: string, itemId: string) => void

	getFormattingStyles?: GetFormattingStylesEventHandler
	formatDataValue?: FormatDataValueEventHandler
	getIconValue?: GetIconValueEventHandler

	useZebraStripes?: boolean
	useCompactView?: boolean
	enableItemFormatting?: boolean
	emptyListMessage?: string
}

const createColumnGroupFromDescription = (
	columnDescription: IMasterViewColumnItemDescription,
	columns: ITableFieldDescription[],
	lookupDataRow: (id: string) => IDataWithIdentifier | undefined,
	getFormattingStyles: GetFormattingStylesEventHandler | undefined,
	formatDataValue: FormatDataValueEventHandler | undefined,
	getIconValue: GetIconValueEventHandler | undefined
): IColumnDef => {
	if (typeof columnDescription === 'string') {
		const column = columns.find((item) => item.propertyId === columnDescription)

		if (!column) {
			throw new Error(`Failed to create column, did not find column with id ${columnDescription}`)
		}

		return createColumnFromDescription(column, lookupDataRow, getFormattingStyles, formatDataValue, getIconValue)
	}

	return {
		field: columnDescription.groupId,
		headerName: columnDescription.displayName,
		children: columnDescription.children?.map((child) =>
			createColumnGroupFromDescription(
				child,
				columns,
				lookupDataRow,
				getFormattingStyles,
				formatDataValue,
				getIconValue
			)
		),
	}
}

const createColumnFromDescription = (
	columnDescription: ITableFieldDescription,
	lookupDataRow: (id: string) => IDataWithIdentifier | undefined,
	getFormattingStyles: GetFormattingStylesEventHandler | undefined,
	formatDataValue: FormatDataValueEventHandler | undefined,
	getIconValue: GetIconValueEventHandler | undefined
): IColumnDef => {
	return {
		field: columnDescription.propertyId,
		headerName: columnDescription.displayName,
		width: columnDescription.widthHintPx,

		dataType: getDataTypeFromColumnDescription(columnDescription),

		textAlignment: getAlignmentFromColumnDescription(columnDescription),
		renderType: getRenderTypeFromColumnDescription(columnDescription),
		iconPlacement: 'before',
		fillVariant: getFillVariantFromColumnDescription(columnDescription),

		getCellStyleOnRender: getCellStyleOnRenderFromColumnDescription(
			columnDescription,
			lookupDataRow,
			getFormattingStyles
		),

		getCellDataOnRender: getCellDataOnRender(getIconValue, lookupDataRow),
		// getTooltip: (nodeId: string | undefined, colId: string | undefined) => 'Hello',
	}
}

const getCellDataOnRender = (
	iconValueHandler: GetIconValueEventHandler | undefined,
	lookupDataRow: (id: string) => IDataWithIdentifier | undefined
) => {
	return (
		nodeId: string | undefined,
		colId: string | undefined
	): Partial<Pick<CellData, 'readOnly' | 'iconName' | 'iconColor' | 'avatarLabel'>> | undefined => {
		if (!nodeId) {
			return undefined
		}

		if (!colId) {
			return undefined
		}

		if (iconValueHandler) {
			const dataRow = lookupDataRow(nodeId)

			if (!dataRow) {
				return undefined
			}

			const iconData = iconValueHandler(dataRow, colId, e_MasterDetailTargetElement.cell)

			if (iconData) {
				return {
					iconName: iconData.iconName,
					iconColor: iconData.iconColor,
				}
			}
		}

		return undefined
	}
}

const getMasterDetailTargetElement = (targetElement: 'pill' | 'circle' | 'avatar' | 'cell') => {
	switch (targetElement) {
		case 'pill':
			return e_MasterDetailTargetElement.pill
		case 'circle':
			return e_MasterDetailTargetElement.circle
		case 'avatar':
			return e_MasterDetailTargetElement.avatar
		case 'cell':
			return e_MasterDetailTargetElement.cell
	}

	return undefined
}

export const MasterDetailAgTableView = (props: IMasterDetailAgTableViewProps) => {
	const tableElement = useRef<HTMLDivElement>(null)

	const { tcvi } = useTranslation()

	const handleItemDoubleClick = (rowData: RowData) => {
		props.setActiveId(rowData.id)
	}

	const itemsRef = useRef(props.items)

	const lookupDataById = useMemo(() => {
		itemsRef.current = props.items
		return (id: string) => {
			return itemsRef.current.find((item) => item.id === id)
		}
	}, [props.items])

	const columnDefs: IColumnDef[] = useMemo(() => {
		if (props.columnGroups && props.columnGroups.length > 0) {
			return props.columnGroups.map((groupItem) =>
				createColumnGroupFromDescription(
					groupItem,
					props.columnDescriptions,
					lookupDataById,
					props.getFormattingStyles,
					props.formatDataValue,
					props.getIconValue
				)
			)
		} else {
			return props.columnDescriptions.map((columnDescription) =>
				createColumnFromDescription(
					columnDescription,
					lookupDataById,
					props.getFormattingStyles,
					props.formatDataValue,
					props.getIconValue
				)
			)
		}
	}, [props.columnGroups, props.columnDescriptions, lookupDataById])

	const tableData = useMemo(() => {
		return props.items.map((item) => {
			const data: RowData = {
				id: item.id,
				columns: {},
			}

			props.columnDescriptions.forEach((colDesc) => {
				const propertyId = colDesc.propertyId

				validateValue(item, propertyId, colDesc, 'raise')

				data.columns[propertyId] = {
					value: item[propertyId],
					formattedValue: formatPropertyValue(item, propertyId, colDesc, props.formatDataValue, tcvi),
				}
			})

			return data
		})
	}, [props.items])

	const sortedTableColumns = useMemo(() => {
		return props.sortedColumns
			? props.sortedColumns.map((sortedColumn) => ({
					id: sortedColumn.propertyId,
					order: sortedColumn.order as e_SortOrder,
			  }))
			: undefined
	}, [props.sortedColumns])

	const handleContextMenu = (
		id: string,
		columnId: string,
		contextMenuCallback: (contextMenuItems: MenuItem[]) => void
	) => {
		if (props.isMultiSelect) {
			return
		}

		const contextMenuItems = props.itemActions
			? props.itemActions.map((itemAction) => {
					if (itemAction.type === e_actionType.action) {
						return {
							type: e_MenuItemType.action as const,
							name: itemAction.displayName,
							iconClassName: itemAction.iconClassName,
							onClick: () => {
								props.handleItemAction && props.handleItemAction(itemAction.id, id)
							},
							disabled: itemAction.disabled,
						}
					} else {
						//if (itemAction.type === e_actionType.separator) {

						return {
							type: e_MenuItemType.divider as const,
						}
					}
			  })
			: undefined

		if (contextMenuItems) {
			contextMenuCallback(contextMenuItems)
		}
	}

	const handleSortOrderChanged = (sortedColumns: ISortedField[]) => {
		if (!props.sortColumns) {
			return
		}

		const nextSortedColumns: ISortData[] = sortedColumns.map((tableColumn) => ({
			propertyId: tableColumn.id,
			order: tableColumn.order,
		}))

		if (isEqual(nextSortedColumns, props.sortedColumns)) {
			return
		}

		props.sortColumns(nextSortedColumns)
	}

	const getRowStyle = (nodeId: string | undefined): CSSProperties | undefined => {
		if (!props.getFormattingStyles) {
			return undefined
		}

		const dataItem = nodeId ? lookupDataById(nodeId) : undefined

		if (!dataItem) {
			return
		}

		const formattingStyles = props.getFormattingStyles(dataItem, undefined, e_MasterDetailTargetElement.row)
		return formattingStyles
			? {
					background: formattingStyles.background,
					color: formattingStyles.text,
					fontWeight: formattingStyles.isBold ? 600 : undefined,
			  }
			: undefined
	}

	return (
		<div className={classes.masterTable} ref={tableElement}>
			<AgGridTable
				id={props.id}
				columnDefs={columnDefs}
				rowData={tableData}
				onContextMenu={handleContextMenu}
				rowSelectionType={props.isMultiSelect ? e_RowSelectionType.multi : e_RowSelectionType.single}
				onDblClick={handleItemDoubleClick}
				onSelectionChange={props.setSelectedIds}
				selection={props.selectedIds}
				contextMenuButton={props.itemActions && props.itemActions.length > 0 ? 'inline' : undefined}
				disableMovableColumns
				onColumnSortChanged={handleSortOrderChanged}
				sortOrder={sortedTableColumns}
				zebraStripes={props.useZebraStripes}
				compact={props.useCompactView}
				getRowStyleOnRender={props.enableItemFormatting && props.getFormattingStyles ? getRowStyle : undefined}
				noRowsMessage={props.emptyListMessage}
			/>
		</div>
	)
}

const getRenderTypeFromColumnDescription = (columnDescription: ITableFieldDescription): e_RenderType | undefined => {
	switch (columnDescription.datatype) {
		case e_MasterDetailValueDataType.number:
			return e_RenderType.number
		case e_MasterDetailValueDataType.boolean:
			return e_RenderType.checkMark
	}

	switch (columnDescription.interpretation) {
		case e_MasterDetailDataInterpretation.date:
			return e_RenderType.date
		case e_MasterDetailDataInterpretation.datetime:
			return e_RenderType.date
	}

	return e_RenderType.text
}

const getFillVariantFromColumnDescription = (columnDescription: ITableFieldDescription) => {
	if (columnDescription.visualization === e_MasterDetailPropertyVisualization.pill) {
		return 'pill'
	}
	if (columnDescription.visualization === e_MasterDetailPropertyVisualization.circle) {
		return 'circle'
	}

	return undefined
}

const getCellStyleOnRenderFromColumnDescription = (
	columnDescription: ITableFieldDescription,
	lookupDataRow: (id: string) => IDataWithIdentifier | undefined,
	getFormattingStyles: GetFormattingStylesEventHandler | undefined
) => {
	if (!getFormattingStyles) {
		return undefined // if formatting styles are not assigned, we will not generate styles
	}

	if (
		columnDescription.enableFormatting ||
		(columnDescription.visualization &&
			[e_MasterDetailPropertyVisualization.circle, e_MasterDetailPropertyVisualization.pill].includes(
				columnDescription.visualization
			))
	) {
		// if visualiation is cell or pill, return a function that queries for pill or circle styles
		return (
			nodeId: string | undefined,
			colId: string | undefined,
			targetElement: 'pill' | 'circle' | 'avatar' | 'cell' // cell types
		): CSSProperties | undefined => {
			const dataRow = nodeId ? lookupDataRow(nodeId) : undefined

			const masterDetailTargetElement = getMasterDetailTargetElement(targetElement)

			if (
				dataRow &&
				masterDetailTargetElement &&
				[
					e_MasterDetailTargetElement.pill,
					e_MasterDetailTargetElement.circle,
					e_MasterDetailTargetElement.cell,
				].includes(masterDetailTargetElement)
			) {
				const formattingStyles = getFormattingStyles(dataRow, columnDescription.propertyId, masterDetailTargetElement)

				return formattingStyles
					? {
							background: formattingStyles.background,
							color: formattingStyles.text,
							fontWeight: formattingStyles.isBold ? 600 : undefined,
					  }
					: undefined
			}
			return undefined
		}
	}

	return undefined
}

// const getCellClassNames = (columnDescription: ITableFieldDescription) => {
// 	if (columnDescription.visualization === 'pill') {
// 		return classes.pillColor
// 	}
// 	if (columnDescription.visualization === 'circle') {
// 		return classes.pillColor
// 	}

// 	return undefined
// }

const getDataTypeFromColumnDescription = (columnDescription: ITableFieldDescription): e_DataType | undefined => {
	switch (columnDescription.datatype) {
		case 'number':
			return e_DataType.float
		case 'boolean':
			return e_DataType.bool
	}

	switch (columnDescription.interpretation) {
		case e_MasterDetailDataInterpretation.datetime:
			return e_DataType.dateTime
		case e_MasterDetailDataInterpretation.date:
			return e_DataType.date
	}

	return undefined
}

const getAlignmentFromColumnDescription = (columnDescription: ITableFieldDescription) => {
	switch (columnDescription.alignment) {
		case 'left':
		case 'center':
		case 'right':
			return columnDescription.alignment
	}

	if (columnDescription.datatype === e_MasterDetailValueDataType.number) {
		return 'right'
	}

	if (columnDescription.datatype === e_MasterDetailValueDataType.boolean) {
		return 'center'
	}

	// if (columnDescription.presentation === 'checkmark') {
	// 	return 'center'
	// }
	// in all other cases, the alignment is handled by data type
	return undefined
}

// const getFormatFromColumnDescription = (columnDescription: ITableFieldDescription) => {
// 	switch (columnDescription.presentation) {
// 		case 'checkmark':
// 			return e_BooleanFormat.checkMark
// 		case 'truefalse':
// 			return e_BooleanFormat.trueFalse
// 		case 'onoff':
// 			return e_BooleanFormat.onOff
// 		case 'yesno':
// 			return e_BooleanFormat.yesNo
// 	}
// 	return undefined
// }
