import React from 'react'
import { createStyle } from '../../theming'
import { DatePickerButton } from './DatePickerButton'

export interface IYearPickerProps {
	tabIndex: number
	date: Date
	classNames?: string
	onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
	onYearClick: (day: Date) => void
	onChange: (date: Date) => void
	startYear: number
}

const classes = createStyle({
	years: {
		display: 'flex',
		width: '100%',
		flexWrap: 'wrap',
		// padding: '16px',
	},
	year: {
		width: '25%',
		height: 50,
	},
})

export const YearPicker = (props: IYearPickerProps) => {
	return (
		<div className={classes.years}>
			{[...Array(12).keys()].map((i) => (
				<DatePickerButton
					className={classes.year}
					onClick={() => {
						props.onYearClick(new Date(i + props.startYear, props.date.getMonth()))
					}}
					label={(i + props.startYear).toString()}
					key={i + props.startYear}
					dataAttributes={i === 0 ? { ['data-defaultyear']: 'true' } : undefined}
				/>
			))}
		</div>
	)
}
