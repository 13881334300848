import React from 'react'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	divider: {
		background: theme.colors.menu.divider,
		height: 1,
		margin: 5,
	},
}))

export const PopupMenuDivider = () => {
	return <div className={classes.divider} />
}
