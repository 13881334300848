import { userSettingsTypes } from './userSettingTypes'

const setPreferedLanguage = (language: 'en' | 'nb') => ({ type: userSettingsTypes.SET_PREFERRED_LANGUAGE, language })
const setPreferedFormattingCulture = (formattingCulture: 'en' | 'en-gb' | 'nb') => ({
	type: userSettingsTypes.SET_PREFERRED_FORMATTING_CULTURE,
	formattingCulture,
})
const setSelectedTimezone = (selectedTimezone: string) => ({
	type: userSettingsTypes.SET_TIME_ZONE,
	selectedTimezone,
})

export const userSettingsActions = {
	setPreferedLanguage,
	setPreferedFormattingCulture,
	setSelectedTimezone,
}
