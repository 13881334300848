import React, { useRef, useEffect } from 'react'

import { FocusTrap } from '../utils/FocusTrap'
import { Popper } from '../utils/Popper'
import { e_Placement } from '../../enums/e_Placement'
import classNames from 'clsx'
import { DatePicker } from '.'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	pickerContainer: {
		backgroundColor: theme.palette.background.white,
		boxShadow: theme.shadows.medium,
		padding: '16px',
	},
}))

export interface IDatePickerProps {
	selectedDay: Date
	anchorElement?: React.RefObject<HTMLDivElement>
	isOpen?: boolean
	className?: string
	onDateSelected: (date: Date) => void
	onOutsideClickOrEscapePress?: () => void
	dataAttributes?: Record<string, string>
	fade?: boolean
}

export const DatePickerPopup = (props: IDatePickerProps) => {
	const dayRef = useRef<HTMLDivElement>(null)

	const focusSelectedDay = () => {
		const focusableElements = 'div'
		if (!dayRef.current) {
			return
		}

		const selectedDay = Array.prototype.filter.call(dayRef.current.querySelectorAll(focusableElements), (element) => {
			return element.className.includes('day') && element.className.includes('selected')
		})

		if (!selectedDay) {
			const today = Array.prototype.filter.call(dayRef.current.querySelectorAll(focusableElements), (element) => {
				return element.className.includes('day') && element.className.includes('today')
			})

			today?.[0].focus({
				preventScroll: true,
			})

			return
		}

		selectedDay?.[0]?.focus({
			preventScroll: true,
		})
	}

	useEffect(() => {
		if (props.isOpen) {
			focusSelectedDay()
		}
	}, [props.isOpen])

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Escape') {
			event.stopPropagation()
			onOutsideClickOrEscapePress()
		}
	}

	const onOutsideClickOrEscapePress = () => {
		props.onOutsideClickOrEscapePress?.()
	}

	return (
		<Popper
			anchorElement={props.anchorElement}
			open={props.isOpen}
			placement={e_Placement.bottomEnd}
			onOutsideClick={onOutsideClickOrEscapePress}
			enablePortal
			className={classNames(props.className)}
			fade={props.fade}
		>
			<FocusTrap preventScrollOnFocus>
				<div
					onMouseDown={(e) => e.stopPropagation()}
					onKeyDown={handleKeyDown}
					ref={dayRef}
					{...props.dataAttributes}
					className={classes.pickerContainer}
				>
					<DatePicker
						selectedDay={props.selectedDay}
						onDateSelected={props.onDateSelected}
						dataAttributes={props.dataAttributes}
					/>
				</div>
			</FocusTrap>
		</Popper>
	)
}
