import type { CSSProperties, KeyboardEvent, MouseEvent } from 'react'
import React from 'react'
import type { IRowNode } from '@ag-grid-community/core'
import { createStyle } from '../../../theming'
import type { TData, Value } from '../Table.types'
import { CheckboxInternal } from '../../Checkbox/CheckboxInternal'
import { SelectionCheckMarkCircle } from './SelectionCheckMarkCircle'
import { useTranslation } from '../../../translation'

const classes = createStyle({
	newCheckMark: {
		marginRight: 4,
	},
})

interface ISelectionCheckMark {
	value: Value | 'mixed'
	node?: IRowNode<TData>
	style?: CSSProperties
	disableSelection?: boolean
	onClick?: (event: { originalEvent: KeyboardEvent | MouseEvent }) => void
	type?: 'square' | 'circle'
	newCheckmark?: boolean
}

export const SelectionCheckMark = (props: ISelectionCheckMark) => {
	const { tcvi } = useTranslation()
	if (props.node?.group && props.node.footer) {
		return <div />
	}
	if (props.type === 'circle') {
		return (
			<SelectionCheckMarkCircle
				value={props.value}
				disableSelection={props.disableSelection}
				onClick={props.onClick}
				style={props.style}
			/>
		)
	}
	return (
		<CheckboxInternal
			checked={props.value === 'mixed' ? null : !!props.value}
			disabled={props.disableSelection}
			checkmarkSize={16}
			style={props.style}
			onChange={!props.disableSelection ? props.onClick : undefined}
			suppressAnimation={props.newCheckmark}
			suppressBackground
			className={classes.newCheckMark}
			isRounded={props.newCheckmark}
			screenTip={getScreenTip(props.value, tcvi)}
			disableUniformHeight
		/>
	)
}

const getScreenTip = (value: Value, tcvi: (t: string) => string) => {
	if (value === 'mixed') {
		return tcvi('CONTROL:AG_MIXED_SELECTION')
	} else if (value) {
		return tcvi('CONTROL:AG_SELECTEDROWS')
	} else {
		return tcvi('CONTROL:AG_NOT_SELECTEDROWS')
	}
}
