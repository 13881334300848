import { getParser } from './parsingUtils'
import type { IUpdateInternalValue } from './useUpdateInternalValue'

export const getParseValueAndUpdateInternal = (
	updateInternalValue: IUpdateInternalValue,
	setDisplayValue: (value: React.SetStateAction<string>) => void,
	isIntegerTime: boolean | undefined
) => {
	const parseTextValueFunc = getParser(isIntegerTime)

	return (textValue: string) => {
		if (!textValue) {
			updateInternalValue(undefined, { updateDisplayValue: false, raiseValueChanged: true })
			setDisplayValue(textValue)

			return
		}

		const parsedValue = parseTextValueFunc(textValue)

		updateInternalValue(parsedValue, { updateDisplayValue: false, raiseValueChanged: true })
		if (parsedValue === undefined) {
			setDisplayValue('')
		} else {
			setDisplayValue(textValue)
		}
	}
}
