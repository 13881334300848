import { useMemo } from 'react'

type Value = string | number | undefined
export type StyleObject = { [key: string]: Value | StyleObject }

export const useMergeStyles = (style: StyleObject, deps: (Value | StyleObject)[]) => {
	return useMemo(() => {
		let obj: StyleObject = {}
		Object.entries(style).forEach(([key, value]) => {
			if (value === undefined) {
				return
			} else if (typeof value === 'object') {
				obj = { ...obj, ...value }
			} else {
				obj[key] = value
			}
		})
		return obj
	}, deps)
}
