import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	tabControl: {
		position: 'relative',
		display: 'flex',
		gap: 4,
	},

	tabIndicator: {
		width: 0,
		height: 3,
		left: 0,
		background: theme.palette.primary.themePrimary,
		pointerEvents: 'none',
		marginTop: -3,
		willChange: 'width, left',
		position: 'relative',
	},

	tabControlWithSeparator: {
		marginTop: 8,
		marginBottom: 8,
		borderBottom: '1px solid ' + theme.colors.body.divider,
	},
	'flex-start': {
		justifyContent: 'flex-start',
	},
	center: {
		justifyContent: 'center',
	},
	'flex-end': {
		justifyContent: 'flex-end',
	},
	stretch: {
		justifyContent: 'stretch',
	},
	wrap: {
		flexWrap: 'wrap',
	},
}))

interface ITabControlHeader {
	children: React.ReactElement | React.ReactElement[]
	displaySeparatorBelow?: boolean
	alignment?: 'flex-start' | 'center' | 'flex-end' | 'stretch'
	wrap?: boolean
}

export const TabControlHeader = React.forwardRef((props: ITabControlHeader, ref: React.Ref<HTMLDivElement>) => {
	return (
		<div
			ref={ref}
			className={classNames(
				classes.tabControl,
				props.alignment && classes[props.alignment],
				props.displaySeparatorBelow && classes.tabControlWithSeparator,
				props.wrap && classes.wrap
			)}
		>
			{props.children}
		</div>
	)
})

TabControlHeader.displayName = 'TabControlHeader'
