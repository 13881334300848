import { e_ScaledBy } from 'src/enums/e_ScaledBy'
import { IEnvironmentResourceOverview } from 'src/interfaces/IEnvironmentResourceOverview'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'
import { IWorkloadsResourcePlan } from 'src/interfaces/IWorkloadsResourcePlan'

export const getWorkloadControllerScaledBy = (workloadController: IWorkloadController) => {
	const hasAutoScaler = workloadController.hasAutoScaler
	const includedInWorkloadsResourcePlan = workloadController.includedInWorkloadsResourcePlan
	if (hasAutoScaler && includedInWorkloadsResourcePlan) {
		return e_ScaledBy.conflict
	} else if (hasAutoScaler) {
		return e_ScaledBy.hpa
	} else if (includedInWorkloadsResourcePlan) {
		return e_ScaledBy.genusOperator
	}
	return e_ScaledBy.default
}

export const getFormattedDesiredReplicas = (
	workloadController: IWorkloadController,
	workloadsResourcePlan: IWorkloadsResourcePlan,
	environmentResourceOverview?: IEnvironmentResourceOverview
) => {
	if (!environmentResourceOverview || environmentResourceOverview.shouldBeUp) {
		return workloadController.desiredReplicas
	} else {
		let result = workloadController.desiredReplicas + ' ('
		if (workloadController.includedInWorkloadsResourcePlan) {
			result += workloadsResourcePlan.scalableResourceConfigurations.find(
				(src) => src.name === workloadController.name
			)?.prescribedReplicas
		} else if (workloadController.replicaInfo.isDisabled) {
			result += '0'
		} else if (workloadController.hasAutoScaler) {
			result += '-' //TODO: Show min and max value from HPA
		} else {
			result += workloadController.replicaInfo.defaultReplicaCount
		}
		result += ')'
		return result
	}
}
