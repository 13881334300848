import { Reducer } from '@reduxjs/toolkit'
import { IUserSettingsActions } from './IUserSettingsActions'
import { userSettingsTypes } from './userSettingTypes'

export interface IUserSettingsState {
	language: 'en' | 'nb'
	formattingCulture: 'en' | 'en-gb' | 'nb'
	timezone: string
}

export const userSettings: IUserSettingsState = {
	language: 'en',
	formattingCulture: 'nb',
	timezone: '',
}

const userSettingsReducer: Reducer<IUserSettingsState, IUserSettingsActions> = (
	state = userSettings,
	action: IUserSettingsActions
): IUserSettingsState => {
	switch (action.type) {
		case userSettingsTypes.SET_PREFERRED_LANGUAGE:
			return {
				...state,
				language: action.language,
			}
		case userSettingsTypes.SET_PREFERRED_FORMATTING_CULTURE:
			return {
				...state,
				formattingCulture: action.formattingCulture,
			}
		case userSettingsTypes.SET_TIME_ZONE: {
			return {
				...state,
				timezone: action.selectedTimezone,
			}
		}

		default:
			return state
	}
}

export const userSettingsRootReducer: Reducer<IUserSettingsState, IUserSettingsActions> = (state, action) => {
	return userSettingsReducer(state, action)
}
