import { useEffect, useRef, useState } from 'react'
import { getExternalFormatConverter, getFormatDurationFunc, getInternalFormatConverter } from './formatUtils'
import type { DurationValueType } from '../DurationInput'

export type IUpdateInternalValue = (
	value: number | undefined,
	options: { updateDisplayValue: boolean; raiseValueChanged: boolean }
) => void

export const useUpdateInternalValue = (
	inputRef: React.RefObject<HTMLInputElement>,
	propValue: DurationValueType,
	isIntegerTime: boolean | undefined,
	isInputFocused: React.MutableRefObject<boolean>,
	onChange: (value?: DurationValueType) => void
) => {
	const convertToExternalFormatFunc = getExternalFormatConverter(isIntegerTime)
	const convertToInternalFormatFunc = getInternalFormatConverter(isIntegerTime)
	const internalValue = useRef<number | undefined>(convertToInternalFormatFunc(propValue))

	const formatDurationFunc = getFormatDurationFunc(isIntegerTime)
	const [displayValue, setDisplayValue] = useState(formatDurationFunc(internalValue.current))
	const setFormattedDisplayValue = (value: number | undefined) => {
		setDisplayValue(formatDurationFunc(value))
	}
	useEffect(() => {
		// a new value has been passed in
		const oldValue = internalValue.current
		if (oldValue === undefined && propValue === undefined) {
			return
		}
		if (oldValue !== undefined && oldValue === convertToInternalFormatFunc(propValue)) {
			return
		}

		internalValue.current = convertToInternalFormatFunc(propValue)

		setDisplayValue(formatDurationFunc(internalValue.current))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [propValue])

	useEffect(() => {
		if (inputRef.current === document.activeElement && !isInputFocused.current) {
			inputRef.current?.select()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayValue])

	const updateInternalValue: IUpdateInternalValue = (
		value: number | undefined,
		options: { updateDisplayValue: boolean; raiseValueChanged: boolean }
	) => {
		internalValue.current = value

		if (options.updateDisplayValue) {
			setDisplayValue(formatDurationFunc(value))
		}

		if (options.raiseValueChanged) {
			const externalFormat = convertToExternalFormatFunc(value)

			onChange?.(externalFormat)
		}
	}

	return {
		updateInternalValue,
		convertToExternalFormatFunc,
		displayValue,
		internalValue,
		setDisplayValue,
		userIsWriting: isInputFocused,
		setFormattedDisplayValue,
	}
}
