import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	h1: {
		fontSize: theme.typography.styles.h1.fontSize,
		fontWeight: theme.typography.styles.h1.fontWeight,
	},
	h2: {
		fontSize: theme.typography.styles.h2.fontSize,
		fontWeight: theme.typography.styles.h2.fontWeight,
	},
	h3: {
		fontSize: theme.typography.styles.h3.fontSize,
		fontWeight: theme.typography.styles.h3.fontWeight,
	},
	h4: {
		fontSize: theme.typography.styles.h4.fontSize,
		fontWeight: theme.typography.styles.h4.fontWeight,
	},
	h5: {
		fontSize: theme.typography.styles.h5.fontSize,
		fontWeight: theme.typography.styles.h5.fontWeight,
	},
	h6: {
		fontSize: theme.typography.styles.h6.fontSize,
		fontWeight: theme.typography.styles.h6.fontWeight,
	},
	subtitle1: {
		fontSize: theme.typography.styles.subtitle1.fontSize,
		fontWeight: theme.typography.styles.subtitle1.fontWeight,
	},
	subtitle2: {
		fontSize: theme.typography.styles.subtitle2.fontSize,
		fontWeight: theme.typography.styles.subtitle2.fontWeight,
	},
	body1: {
		fontSize: theme.typography.styles.body1.fontSize,
		fontWeight: theme.typography.styles.body1.fontWeight,
	},
	body2: {
		fontSize: theme.typography.styles.body2.fontSize,
		fontWeight: theme.typography.styles.body2.fontWeight,
	},
	caption: {
		fontSize: theme.typography.styles.caption.fontSize,
		fontWeight: theme.typography.styles.caption.fontWeight,
	},
	error: { color: theme.colors.body.errorText },
	warning: { color: theme.colors.body.warningText },
	success: { color: theme.colors.body.successText },
}))

export enum e_TextHTMLVariant {
	h1 = 'h1',
	h2 = 'h2',
	h3 = 'h3',
	h4 = 'h4',
	h5 = 'h5',
	h6 = 'h6',
	subtitle1 = 'subtitle1',
	subtitle2 = 'subtitle2',
	body1 = 'body1',
	body2 = 'body2',
	caption = 'caption',
}

const textVariants = Object.keys(e_TextHTMLVariant)

export const narrowFontStyleToBuiltinTextVariant = (fontStyle?: string | undefined) => {
	if (!fontStyle || fontStyle === 'inherit') {
		return undefined
	}

	if (textVariants.includes(fontStyle)) {
		return fontStyle as keyof typeof e_TextHTMLVariant
	}

	return undefined
}

const getElementFromVariant = (variant: keyof typeof e_TextHTMLVariant) => {
	switch (variant) {
		case e_TextHTMLVariant.h1:
			return 'h1'
		case e_TextHTMLVariant.h2:
			return 'h2'
		case e_TextHTMLVariant.h3:
			return 'h3'
		case e_TextHTMLVariant.h4:
			return 'h4'
		case e_TextHTMLVariant.h5:
			return 'h5'
		case e_TextHTMLVariant.h6:
			return 'h6'
		case e_TextHTMLVariant.body1:
			return 'p'
		case e_TextHTMLVariant.body2:
			return 'p'
		default:
			return 'span'
	}
}

const getElement = (renderAs: RenderElement | undefined, variant: keyof typeof e_TextHTMLVariant | undefined) => {
	if (renderAs) {
		return renderAs
	}

	if (variant) {
		return getElementFromVariant(variant)
	}

	return 'span'
}

type RenderElement = 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
interface ITextProps {
	children?: React.ReactNode
	id?: string
	className?: string
	variant?: keyof typeof e_TextHTMLVariant
	style?: React.CSSProperties
	statusColor?: 'error' | 'warning' | 'success'
	align?: 'left' | 'right' | 'center'
	dataAttributes?: Record<string, string>
	renderAs?: RenderElement
	disableTypographyStyle?: boolean
}

export const Text = (props: ITextProps) => {
	const css = classNames(
		!props.disableTypographyStyle && props.variant && classes[props.variant],
		{
			[classes.error]: props.statusColor === 'error',
			[classes.warning]: props.statusColor === 'warning',
			[classes.success]: props.statusColor === 'success',
			'text-align-left': props.align === 'left',
			'text-align-center': props.align === 'center',
			'text-align-right': props.align === 'right',
		},
		props.className
	)

	const Component = getElement(props.renderAs, props.variant)

	return (
		<Component
			className={css}
			style={props.style}
			{...props.dataAttributes}
			tabIndex={props.dataAttributes?.['data-autofocus'] === 'true' ? 0 : undefined}
			id={props.id}
		>
			{props.children}
		</Component>
	)
}
