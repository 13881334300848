// Get the topmost window that belongs to web-frontend
let currentWindow: Window = window
let topmostWindow: Window | undefined = undefined
while (!topmostWindow) {
	try {
		// and we only want to attach Portals inside the iframe/<object> being targeted for tests
		if (
			currentWindow !== currentWindow.parent &&
			// @ts-ignore Cypress is attached to top window during integration testing,
			!currentWindow.parent?.Cypress &&
			// @ts-ignore Storybook is attached to top window during component development
			!currentWindow.parent?.__STORYBOOKAPI__
		) {
			currentWindow = currentWindow.parent
		} else {
			topmostWindow = currentWindow
			break
		}
	} catch {
		topmostWindow = currentWindow
		break
	}
}

export const topWindow = topmostWindow
