import React from 'react'
import type { CSSProperties } from 'react'
import type { CellData, ITableContext, TData, Value } from '../../Table.types'
import { e_RenderType } from '../../Table.types'
import { ViewerContent } from './ViewerContent'
import classNames from 'clsx'
import type { e_Interpretation } from '../../enums/e_Interpretation'
import { useTranslation } from '../../../../translation'
import type { IRowNode, Column } from '@ag-grid-community/core'
import { createStyle } from '../../../../theming'

const classes = createStyle({
	content: {
		gap: 4,
		alignItems: 'center',
		flex: 1,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	cellCheckmark: { display: 'flex' },
	circleWrapper: { display: 'flex', margin: '0 4px' },
	pillWrapper: { display: 'flex', margin: '0 4px' },
	compactPillAndCircleWrapper: { marginTop: 2, marginBottom: 2 },
	pillVariant: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		width: '100%',
		justifyContent: 'center',
		borderRadius: '13px',
		padding: '0 8px',
		height: '25px',
		lineHeight: '25px',
		textAlign: 'center',
	},
	pillCompact: {
		height: '20px',
		lineHeight: '20px',
	},
	pillVariantFitToContent: {
		width: 'unset',
		maxWidth: '100%',
		flexGrow: 0,
	},
	circleVariant: {
		width: '30px',
		height: '30px',
		borderRadius: '50%',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px 2px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		lineHeight: '30px',
	},
	circleVariantCompact: { width: '21px' },
	alignStart: { alignSelf: 'start' },
	alignCenter: { alignSelf: 'center' },
	alignEnd: { alignSelf: 'end' },
})

interface IProps {
	avatarLabel: string | undefined
	cellClassName: string | undefined
	cellData: CellData | null | undefined
	cellDataOnRender: Partial<Pick<CellData, 'iconName' | 'avatarLabel' | 'iconColor' | 'readOnly'>> | undefined
	column: Column<CellData> | undefined
	compact: boolean | undefined
	disabled: boolean
	displayLabel: string
	editingMode: boolean
	eGridCell: HTMLElement
	fillVariant: 'default' | 'pill' | 'circle'
	getCellClassNameOnRender: ((nodeId: string | undefined, colId: string | undefined) => string | undefined) | undefined
	getCellStyleOnRender?: (
		nodeId: string | undefined,
		colId: string | undefined,
		targetElement: 'pill' | 'circle' | 'avatar' | 'cell'
	) => CSSProperties | undefined
	interpretation: e_Interpretation
	isAvatarCell: boolean
	isCheckMarkCell: boolean
	isDefaultActionCell: boolean
	isEditableCell: boolean
	isRenderingPillOrCircle: boolean
	node: IRowNode<TData>
	nodeId: string
	pillWidth: number | 'stretch' | 'fitToContents' | undefined
	renderType: e_RenderType
	textAlignment: 'left' | 'center' | 'right'
	tableContext: ITableContext
	value: string | number | boolean | null | undefined
	onLinkClick?: (linkRef: React.RefObject<HTMLElement>) => void
	pushChanges: (value: Value) => void
}

export const CELL_RENDERER_VIEWER_DATA_CY = '463408f1-c794-4be7-862b-07e48a2c31d5'
export const CELL_RENDERER_PILL_OR_CIRCLE_DATA_CY = 'fd948da5-208f-46af-9bde-b23a1e8f3f66'

const Viewer = (props: IProps) => {
	const {
		value,
		nodeId,
		pushChanges,
		cellData,
		cellDataOnRender,
		isCheckMarkCell,
		isAvatarCell,
		isDefaultActionCell,
		isEditableCell,
		disabled,
		displayLabel,
		renderType,
		onLinkClick,
		fillVariant,
		editingMode,
		isRenderingPillOrCircle,
		textAlignment,
	} = props
	const { tcvi } = useTranslation()

	const contentClassnames = classNames(classes.content, {
		[classes.cellCheckmark]: isCheckMarkCell,
		[classes.circleWrapper]: fillVariant === 'circle' && !editingMode,
		[classes.pillWrapper]: fillVariant === 'pill' && !editingMode,
		[classes.compactPillAndCircleWrapper]: isRenderingPillOrCircle && props.compact,
	})

	const pillWidth =
		fillVariant === 'pill' && typeof props?.pillWidth === 'number' && !editingMode ? props?.pillWidth : undefined

	const pillOrCircleClassnames = isRenderingPillOrCircle
		? classNames(
				{
					[classes.cellCheckmark]: isCheckMarkCell,
					[classes.pillVariant]: fillVariant === 'pill',
					[classes.pillCompact]: fillVariant === 'pill' && props.compact,
					[classes.pillVariantFitToContent]: fillVariant === 'pill' && props.pillWidth === 'fitToContents',
					[classes.circleVariant]: fillVariant === 'circle',
					[classes.circleVariantCompact]: fillVariant === 'circle' && props.compact,
					[classes.alignStart]: props.textAlignment === 'left',
					[classes.alignCenter]: (props.textAlignment ?? 'center') === 'center',
					[classes.alignEnd]: props.textAlignment === 'right',
				},
				props.cellClassName,
				props.getCellClassNameOnRender?.(props.node.id, props.column?.getId())
		  )
		: undefined

	const cellStyle =
		isRenderingPillOrCircle && props.getCellStyleOnRender
			? props.getCellStyleOnRender(props.node.id, props.column?.getId(), fillVariant as 'pill' | 'circle')
			: undefined

	if (isCheckMarkCell) {
		props.eGridCell.ariaLabel = value ? tcvi('GENERAL:YES') : tcvi('GENERAL:NO')
	}
	return props.renderType !== e_RenderType.none ? (
		<>
			<div
				className={contentClassnames}
				style={{
					justifyContent: isCheckMarkCell ? textAlignment : undefined,
					textAlign: textAlignment,
				}}
				data-cy={CELL_RENDERER_VIEWER_DATA_CY}
			>
				{isRenderingPillOrCircle ? (
					<div
						style={{
							display: 'flex',
							width: '100%',
							justifyContent: textAlignment,
						}}
					>
						<div
							data-cy={CELL_RENDERER_PILL_OR_CIRCLE_DATA_CY}
							className={pillOrCircleClassnames}
							style={{ width: pillWidth, ...cellStyle }}
						>
							<ViewerContent
								value={value}
								nodeId={nodeId}
								pushChanges={pushChanges}
								cellData={cellData}
								cellDataOnRender={cellDataOnRender}
								isCheckMarkCell={isCheckMarkCell}
								isAvatarCell={isAvatarCell}
								isDefaultActionCell={isDefaultActionCell}
								isEditableCell={isEditableCell}
								disabled={disabled}
								displayLabel={displayLabel}
								renderType={renderType}
								onLinkClick={onLinkClick}
								avatarLabel={props.avatarLabel}
								interpretation={props.interpretation}
								context={props.tableContext}
							/>
						</div>
					</div>
				) : (
					<ViewerContent
						value={value}
						nodeId={nodeId}
						pushChanges={pushChanges}
						cellData={cellData}
						cellDataOnRender={cellDataOnRender}
						isCheckMarkCell={isCheckMarkCell}
						isAvatarCell={isAvatarCell}
						isDefaultActionCell={isDefaultActionCell}
						isEditableCell={isEditableCell}
						disabled={disabled}
						displayLabel={displayLabel}
						renderType={renderType}
						onLinkClick={onLinkClick}
						avatarLabel={props.avatarLabel}
						interpretation={props.interpretation}
						context={props.tableContext}
					/>
				)}
			</div>
		</>
	) : null
}

Viewer.displayName = 'Viewer'

export { Viewer }
