import { useCallback, useState } from 'react'
import type { MenuItem } from '../../Menu'

export const useContextMenuItems = (
	fn?: (id: string, columnId: string, contextMenuCallback: (contextMenuItems: MenuItem[]) => void) => void
) => {
	const [contextMenuItems, setMenuItems] = useState<MenuItem[] | undefined>(undefined)
	return {
		contextMenuItems,
		onOpenContextMenuWithButton: useCallback(
			(id: string | undefined, columnId: string | undefined) => {
				if (!id || !columnId) {
					return
				}

				const contextCallback = (items: MenuItem[]) => setMenuItems(items)

				fn?.(id, columnId, contextCallback)
			},
			[fn]
		),
	}
}
