export enum e_TableColumnSummaryFunction {
	none = 'none',
	sum = 'sum',
	average = 'average',
	median = 'median',
	min = 'min',
	max = 'max',
	count = 'count',
	distinctCount = 'distinctCount',
}
