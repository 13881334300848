import { createContext } from 'react'
import type { IKeyboardShortcutListenerContext } from './IKeyboardShortcutListenerContext'

export const rootKeyboardShortcutListener: IKeyboardShortcutListenerContext = {
	contextId: 'root',
	parentContextId: undefined,
	isRootContext: true,
}
export const KeyboardShortcutListenerContext =
	createContext<IKeyboardShortcutListenerContext>(rootKeyboardShortcutListener)
