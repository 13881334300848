import { createStyle } from '@genusbiz/web-ui/theming'
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, TabControlHeader, TabControlHeaderItem } from '@genusbiz/web-ui/controls'
import { useTranslation } from 'react-i18next'
import { IPod } from 'src/interfaces/IPod'
import { operatorApi } from 'src/modules/operatorApi'
import { LogContainer } from './LogContainer'
import { defaultIcons } from 'src/consts/defaultIcons'
import { IContainer } from 'src/interfaces/IContainer'
import { e_LabelPosition } from '@genusbiz/web-ui/enums/e_LabelPosition'

const classes = createStyle({
	dialog: {
		height: '90%',
		width: '90%',
	},
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		height: '100%',
		backgroundColor: 'white',
	},
	actions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	actionButton: {
		marginLeft: '8px',
		width: '150px',
		height: '35px',
	},
	topContent: {
		padding: '4px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	podContainerSelector: {
		width: '300px',
		marginTop: '12px',
	},
})

interface IProps {
	pod: IPod
}

export const PodLog = (props: IProps) => {
	const { t } = useTranslation()
	const [log, setLog] = useState<string>('')
	const [includeTimestamps, setIncludeTimestamps] = useState(true)
	const [previousContainer, setPreviousContainer] = useState(false)
	const [podHasPreviousContainer, setPodHasPreviousContainer] = useState(false)
	const [podContainerName, setContainerName] = useState<string>('')
	const [logErrorOccured, setLogErrorOccured] = useState(false)
	const [allContainers, setAllContainers] = useState<IContainer[]>([])

	const onCommandbarSetIncludeTimestamps = () => {
		setIncludeTimestamps(!includeTimestamps)
	}

	useEffect(() => {
		setContainerName(props.pod.containers[0]?.name ?? '')
	}, [])

	useEffect(() => {
		setAllContainers([...props.pod.containers, ...props.pod.initContainers])
		if (
			props.pod.status.containerStatuses
				.filter((containerStatus) => containerStatus.name === podContainerName)
				.some((containerStatus) => containerStatus.restartCount > 0)
		) {
			setPodHasPreviousContainer(true)
		} else {
			setPodHasPreviousContainer(false)
		}
	}, [podContainerName, props.pod])

	useEffect(() => {
		if (podContainerName) {
			fetchPodLog()
		}
	}, [includeTimestamps, previousContainer, podContainerName])

	const fetchPodLog = () => {
		void operatorApi
			.fetchPodLog(
				props.pod.runtimeConfig.k8sRuntimeId,
				props.pod.name,
				podContainerName,
				includeTimestamps,
				previousContainer
			)
			.then((podLog) => {
				setLog(podLog)
			})
			.catch((err: Error) => {
				setLog(`Failed to load logs:  ${err.message}`)
				setLogErrorOccured(true)
			})
	}

	const podContainerNameOptions = () => {
		if (allContainers.length > 0) {
			return allContainers.map((container) => {
				return { label: container.name, value: container.name }
			})
		}
	}

	return (
		<>
			{allContainers.length === 0 ? (
				<h3>{t('PODS:CONTAINER_NOT_FOUND')}</h3>
			) : (
				<div className={classes.container}>
					<div className={classes.actions}>
						<Button
							onClick={() => {
								fetchPodLog()
							}}
							variant="inline"
							className={classes.actionButton}
							icon="Fluent-Refresh"
							disabled={logErrorOccured}
						>
							{t('GENERAL:REFRESH')}
						</Button>
						<Button
							onClick={() => {
								onCommandbarSetIncludeTimestamps()
							}}
							variant="inline"
							className={classes.actionButton}
							icon={defaultIcons.analysisTimer}
							disabled={logErrorOccured}
						>
							{includeTimestamps ? t('PODS:HIDE_TIMESTAMPS') : t('PODS:SHOW_TIMESTAMPS')}
						</Button>
					</div>
					<div className={classes.topContent}>
						<div className={classes.podContainerSelector}>
							<Dropdown
								label={t('PODS:CONTAINER') + ':'}
								labelPosition={e_LabelPosition.left}
								value={allContainers[0]?.name ?? ''}
								options={podContainerNameOptions()}
								onChange={(name) => {
									setPreviousContainer(false)
									setContainerName(name)
								}}
								dataAttributes={{ 'data-cy': 'pod-log-container-dropdown' }}
								placeholder={allContainers[0]?.name ?? ''}
								stretch={false}
							/>
						</div>
						<div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
							<h4 style={{ color: '#cc3300' }}>{t('PODS:NOTE_SERVER_TIME')}</h4>
						</div>
					</div>
					<TabControlHeader>
						<TabControlHeaderItem
							text={'Previous'}
							onActivate={() => {
								setPreviousContainer(true)
							}}
							isActive={previousContainer}
							disabled={!podHasPreviousContainer}
						/>
						<TabControlHeaderItem
							text={'Current'}
							onActivate={() => {
								setPreviousContainer(false)
							}}
							isActive={!previousContainer}
						/>
					</TabControlHeader>

					<LogContainer log={log} />
				</div>
			)}
		</>
	)
}
