import React, { useState } from 'react'

import { AppBar, DropdownInput } from '@genusbiz/web-ui/controls'

import { useTranslation } from 'react-i18next'
import { ControlLoopStatusCallout } from './ControlLoopStatusCallout'
import { useDispatch, useSelector } from 'react-redux'
import { operatorFrontendSelectors } from 'src/features/OperatorFrontend/duck/operatorFrontendSelectors'
import { useNavigate } from 'react-router-dom'
import { e_StandardTimezones } from 'src/enums/e_StandardTimezones'
import moment from 'moment-timezone'
import { convertIntegerToHoursMinutes } from 'src/utils/dateTimeUtils'
import { userSettingsActions } from 'src/features/UserSettings/duck/userSettingsActions'
import { userSettingsSelectors } from 'src/features/UserSettings/duck/userSettingsSelectors'
import { getTimezoneOptions } from 'src/utils/getTimezoneOptions'
import { createStyle } from '@genusbiz/web-ui/theming'
import { defaultColors } from 'src/consts/defaultColors'
import { TABLET_WIDTH } from 'src/consts/cssBreakpoints'
import { e_LabelPosition } from '@genusbiz/web-ui/enums/e_LabelPosition'

const classes = createStyle({
	appBarRight: {
		display: 'flex',
		flexGrow: 100,
		justifyContent: 'flex-end',
	},
	controlLoopStatus: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	timezonePicker: {
		display: 'flex',
		flexDirection: 'row',
		[`@media (max-width:${TABLET_WIDTH}px)`]: {
			display: 'none',
		},
	},
	switchTimezone: {
		alignItems: 'center',
		width: '350px',
		maxHeight: '400px',
		'& button': {
			backgroundColor: defaultColors.primary,
			color: 'white',
		},
		'& input[type=text]': {
			backgroundColor: defaultColors.primary,
			color: 'white',
		},
	},
})

interface IAppBarProps {
	showUserSettings: boolean
	toggleShowUserSettings: () => void
}

export const OperatorAppBar = (props: IAppBarProps) => {
	const dispatch = useDispatch()

	const { t } = useTranslation()
	const navigate = useNavigate()
	const controlLoopStatus = useSelector(operatorFrontendSelectors.selectControlLoopStatus)

	const modelName = window.env.MODEL_IDENTIFIER || ''

	const selectedTimezone = useSelector(userSettingsSelectors.selectSelecedTimezone)
	const [utcOffset, setUtcOffset] = useState<string>(convertIntegerToHoursMinutes(moment().utcOffset()))

	const setTimezone = (timezone: string) => {
		let offset = 0
		if (timezone === e_StandardTimezones.browserTime) {
			dispatch(userSettingsActions.setSelectedTimezone(moment.tz.guess()))
			offset = moment().utcOffset()
		} else {
			dispatch(userSettingsActions.setSelectedTimezone(timezone))
			timezone = timezone.replaceAll(' ', '_')
			offset = moment.tz(timezone).utcOffset()
		}
		setUtcOffset(convertIntegerToHoursMinutes(offset))
	}

	return (
		<AppBar
			title={`${t('APP_BAR:TITLE')} | ${modelName}`}
			userInformationOpen={props.showUserSettings}
			onTitleClick={() => {
				navigate('/')
			}}
			settingsOpen={false}
			onSettingsClick={props.toggleShowUserSettings}
		>
			<div className={classes.appBarRight}>
				<div className={classes.timezonePicker}>
					<DropdownInput
						style={{
							width: '100%',
						}}
						className={classes.switchTimezone}
						label={t('APP_BAR:TIME_ZONE')}
						labelPosition={e_LabelPosition.left}
						value={selectedTimezone}
						options={getTimezoneOptions()}
						onChange={(value: string) => setTimezone(value)}
					/>
					<div style={{ alignItems: 'center', display: 'flex', margin: '5px' }}>{`UTC${utcOffset}`}</div>
				</div>
				{controlLoopStatus && <ControlLoopStatusCallout controlLoopStatus={controlLoopStatus} />}
			</div>
		</AppBar>
	)
}
