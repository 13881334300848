import React, { useMemo, useState } from 'react'
import classNames from 'clsx'

import { Avatar } from '../Avatar'
import type { IIconSize } from '../Icon'
import { Icon } from '../Icon'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useChipClassNames } from './utils/useChipClassNames'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils/IIconNames'
import { createStyle } from '../../theming'
import { useGetStyle } from '../utils/Style.context'
import { useCombineRefs } from '../utils/useCombineRefs'
import { useDrag } from '../utils/dragdrop/useDrag'
import type { IDragSourceMonitor } from '../utils/dragdrop/DragDropTypes'
import { useDataAttributes } from '../utils/useDataAttributes'

const classes = createStyle((theme) => {
	return {
		clickableLink: { '&:hover:not([aria-disabled="true"])': { textDecoration: 'underline' } },
		avatar: {
			display: 'flex',
			justifyContent: 'center',
			height: '100%',
			overflow: 'hidden',
			userSelect: 'none',
			flexBasis: '20px',
		},
		content: {
			userSelect: 'none',
			alignItems: 'center',
			display: 'flex',
			flex: 1,
			justifyContent: 'center',
			gap: 6,
		},
		textContent: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			alignSelf: 'center',
		},
		deleteButton: {
			display: 'flex',
			alignItems: 'center',
			fontSize: '0.5em',
			background: 'transparent',
			border: '1px solid transparent',
			color: 'inherit',
			cursor: 'pointer',
			padding: theme.controls.chip.padding,
			paddingLeft: 4,
			outline: 0,
			'&:focus': {
				border: '1px solid ' + theme.controls.chip.focusedColors.border,
			},
		},
	}
})

export interface AvatarInfo {
	initials: string
}

interface IChipProps {
	id?: string
	index?: number
	label: string
	dataId?: string | number
	screenTip?: string
	deleteScreenTip?: string
	avatar?: AvatarInfo
	isActive?: boolean
	tabStop?: boolean
	disabled?: boolean
	readOnly?: boolean
	clickable?: boolean
	isLink?: boolean
	collapsible?: boolean
	defaultCollapsed?: boolean
	className?: string
	style?: React.CSSProperties
	enableDrag?: boolean
	onClick?: (e: React.MouseEvent, index?: number) => void
	onKeyDown?: (e: React.KeyboardEvent<Element>) => void
	onFileDragStart?: (activeItemId?: string, setData?: (format: string, data: string) => void) => void
	onDelete?: (index?: number) => void
	showTextOnly?: boolean
	dataAttributes?: Record<string, string>
	icon?: IIconNames
	iconSize?: IIconSize
}

export const Chip = React.forwardRef((props: IChipProps, ref: React.Ref<HTMLDivElement>) => {
	const clickableProp = props.clickable ?? true
	const chipRef = useForwardedRef<HTMLDivElement | null>(ref)

	const [isCollapsed, setIsCollapsed] = useState(props.collapsible && props.defaultCollapsed)

	const dataAttributes = useDataAttributes(props.dataAttributes, props.index)

	const onClick = (e: React.MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
		if (!clickableProp || props.disabled || props.readOnly) {
			return
		}

		props.collapsible && setIsCollapsed(!isCollapsed)
		props.onClick?.(e, props.index)
	}

	const onDelete = (e: React.MouseEvent) => {
		e.stopPropagation()

		if (props.disabled || props.readOnly) {
			return
		}

		props.onDelete?.(props.index)
	}

	const clickable = !props.disabled && !props.readOnly && (clickableProp || !!props.onClick)

	const chipClassNames = useChipClassNames(
		props.disabled,
		props.readOnly,
		props.isActive,
		clickable,
		props.showTextOnly,
		props.className,
		isCollapsed
	)

	const styleIconSize = useGetStyle().button?.iconSize

	const onDragStart = (monitor: IDragSourceMonitor) => {
		const setData = monitor.internalMonitor?.setData
		props.onFileDragStart?.(props.dataId as string, setData)
	}

	// required parameter of useDrag
	const onDragEnd = () => {}

	const dragRef = useDrag(onDragStart, onDragEnd, props.enableDrag, !!props.onFileDragStart)

	const elementAndDragRef = useCombineRefs<HTMLDivElement | null>(dragRef, chipRef)

	const iconOrAvatarExists = props.icon || props.avatar

	const content = useMemo(() => {
		const childElements: (JSX.Element | string)[] = []

		if (props.avatar) {
			childElements.push(<Avatar initials={props.avatar.initials} className={classes.avatar} size="extraSmall" />)
		}

		if (props.icon) {
			childElements.push(<Icon iconName={props.icon} size={props.iconSize || styleIconSize || 'extraSmall'} />)
		}
		const textContent = iconOrAvatarExists ? <span className={classes.textContent}>{props.label}</span> : props.label

		childElements.push(textContent)
		return childElements
	}, [iconOrAvatarExists, props.avatar, props.icon, props.iconSize, props.label, styleIconSize])

	return (
		<div
			className={chipClassNames}
			style={props.style}
			ref={elementAndDragRef}
			tabIndex={getTabIndex(props.isActive, props.tabStop, props.disabled)}
			onKeyDown={props.onKeyDown}
			title={props.screenTip}
			role={!props.disabled ? 'button' : undefined}
			aria-disabled={props.disabled}
			onClick={clickableProp ? onClick : undefined}
			data-id={props.dataId}
			id={props.id}
			{...dataAttributes}
		>
			<span
				className={classNames({
					[classes.textContent]: !iconOrAvatarExists,
					[classes.content]: iconOrAvatarExists,
					[classes.clickableLink]: clickable && props.isLink,
				})}
			>
				{content}
			</span>
			{props.onDelete && !props.showTextOnly && !props.readOnly && (
				<button
					onClick={onDelete}
					onKeyDown={(e) => e.key === 'Enter' && e.stopPropagation()}
					className={classes.deleteButton}
					aria-hidden="true"
					disabled={props.disabled}
					title={props.deleteScreenTip}
					tabIndex={-1}
				>
					<Icon iconName="Fluent-ChromeClose" size="extraSmall" style={{ fontSize: '8px' }} />
				</button>
			)}
		</div>
	)
})

Chip.displayName = 'Chip'

function getTabIndex(
	isActive: boolean | undefined,
	tabStop: boolean | undefined,
	isDisabled: boolean | undefined
): number | undefined {
	if (isDisabled) {
		return undefined
	}
	return isActive || tabStop ? 0 : -1
}
