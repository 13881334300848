import React, { useEffect, useRef, useState } from 'react'

// Selectors
import { useDispatch, useSelector } from 'react-redux'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { useTranslation } from 'react-i18next'
import {
	EditableMasterDetailSurface,
	presetAdditionalDetailSurfaceProps,
} from '@genusbiz/web-ui/surfaces/EditableMasterDetail'
import { IMasterDetailCallbackAPI } from '@genusbiz/web-ui/surfaces'
import { OperatorEndpointDetail } from './OperatorEndpointDetail'
import { OperatorEndpointDetailEdit } from './OperatorEndpointDetailEdit'
import { e_MasterDetailValueDataType } from '@genusbiz/web-ui/surfaces/MasterDetail/types/MasterDetailDataType'
import { IEndpoint } from 'src/interfaces/IEndpoint'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { operatorApi } from 'src/modules/operatorApi'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { IEditableEntry, IEditableItem } from '@genusbiz/web-ui/surfaces/EditableMasterDetail/types/detailConfigTypes'
import { IConfirmationModalProps } from 'src/interfaces/IModalProps'
import { generateGuid } from 'src/utils/generateGuid'
import { AxiosError } from 'axios'
import { isValidUrl } from 'src/utils/urlUtils'

export const OperatorEndpointTable = () => {
	const { t } = useTranslation()

	const endpoints = useSelector(kubernetesSelectors.selectEndpoints)

	const k8sRuntimes = useK8sRuntimes()

	const { columnDescriptions, columnGroups } = useEndpointsTableDataDescriptions()

	const rowdata = deriveRowData(endpoints, k8sRuntimes)
	const itemMethods = useEndpointItemMethods(endpoints, rowdata)

	const components = {
		view: presetAdditionalDetailSurfaceProps(OperatorEndpointDetail, { k8sRuntimes }),
		edit: presetAdditionalDetailSurfaceProps(OperatorEndpointDetailEdit, { k8sRuntimes }),
		create: presetAdditionalDetailSurfaceProps(OperatorEndpointDetailEdit, { k8sRuntimes }),
	}

	const apiRef = useRef<IMasterDetailCallbackAPI | undefined>()

	const showMessage = useNotification()

	return (
		<EditableMasterDetailSurface
			callbackAPI={apiRef}
			interactions={itemMethods}
			viewComponent={components.view}
			editComponent={components.edit}
			createComponent={components.create}
			componentDeps={[k8sRuntimes]}
			detailFieldDescriptions={columnDescriptions}
			data={rowdata}
			contentName={t('ENDPOINTS:ENDPOINT')}
			contentNamePlural={t('ENDPOINTS:ENDPOINTS')}
			columnDescriptions={columnDescriptions}
			columnGroups={columnGroups}
			giveFeedback={showMessage}
			useZebraStripes
		/>
	)
}

const useEndpointsTableDataDescriptions = () => {
	const { t } = useTranslation()

	const columnDescriptions = [
		{
			displayName: t('GENERAL:ID'),
			propertyId: 'id',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: t('ENDPOINTS:HOSTNAME'),
			propertyId: 'name',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: t('ENDPOINTS:HOSTNAME'),
			propertyId: 'hostName',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: t('ADMINISTRATION:BLUE_RUNTIME'),
			propertyId: 'blueK8sRuntimeId',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: t('ADMINISTRATION:BLUE_RUNTIME'),
			propertyId: 'blueK8sRuntimeName',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: t('ADMINISTRATION:GREEN_RUNTIME'),
			propertyId: 'greenK8sRuntimeId',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: t('ADMINISTRATION:GREEN_RUNTIME'),
			propertyId: 'greenK8sRuntimeName',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
	]

	const columnGroups = ['name', 'blueK8sRuntimeName', 'greenK8sRuntimeName']

	return { columnDescriptions, columnGroups }
}

const useNotification = () => {
	const dispatch = useDispatch()

	const showMessage = (message: string) =>
		dispatch(modalManagerActions.showNotificationModal({ title: 'Unsaved changes', message: message }))

	return showMessage
}

const getRuntimeNameFromID = (runtimeId: string | null, allK8sRuntimes: IK8sRuntime[]) => {
	const runtime = allK8sRuntimes.find((selectedRuntime) => selectedRuntime.id === runtimeId)
	return runtime?.name
}

const deriveRowData = (endpoints: IEndpoint[], allK8sRuntimes: IK8sRuntime[]) =>
	endpoints.map((endpoint) => {
		const endpointItem = {
			id: endpoint.id,
			name: endpoint.hostName,
			hostName: endpoint.hostName,
			blueK8sRuntimeId: endpoint.blueK8sRuntimeId,
			blueK8sRuntimeName: getRuntimeNameFromID(endpoint.blueK8sRuntimeId, allK8sRuntimes),
			greenK8sRuntimeId: endpoint.greenK8sRuntimeId,
			greenK8sRuntimeName: getRuntimeNameFromID(endpoint.greenK8sRuntimeId, allK8sRuntimes),
		}

		return Object.assign(endpointItem)
	})

// Custom hooks

const getArrayEntry = <T extends { id: string }>(array: T[], id: string) => array.find((entry) => entry.id === id)

const useEndpointItemMethods = (endpoints: IEndpoint[], items: IEditableItem[]) => {
	const { commitEndpointCreation, commitEndpointUpdate, commitEndpointDeletion } = useEndpointCommits(endpoints)
	const showDeletionModal = useEndpointDeletionModal(commitEndpointDeletion)

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const itemMethods = {
		getItem: (id: string) => getArrayEntry(items, id),
		getDraftBaseItem: () => getEndpointDraftItem(),
		commitItemUpdate: (item: IEditableItem | undefined, onConfirm: () => void) => {
			const validEndpoint = item && commitEndpointUpdate(item)
			if (validEndpoint) {
				onConfirm()
			} else {
				dispatch(
					modalManagerActions.showNotificationModal({
						title: `${t('ENDPOINTS:INVALID_OBJECT_TITLE')}`,
						message: `${t('ENDPOINTS:INVALID_HOSTNAME')} ${t('ENDPOINTS:INVALID_SELECTED_RUNTIME')}`,
					})
				)
			}
		},

		commitItemCreation: (item: IEditableItem | undefined, onConfirm: () => void) => {
			const validEndpoint = item && commitEndpointCreation(item)
			if (validEndpoint) {
				onConfirm()
			} else {
				dispatch(
					modalManagerActions.showNotificationModal({
						title: `${t('ENDPOINTS:INVALID_OBJECT_TITLE')}`,
						message: `${t('ENDPOINTS:INVALID_HOSTNAME')} ${t('ENDPOINTS:INVALID_SELECTED_RUNTIME')}`,
					})
				)
			}
		},

		commitItemDeletion: (itemId: string, onConfirm: () => void) => {
			const item = getArrayEntry(items, itemId)
			const hostName = (item?.hostName ?? t('ENDPOINTS:UNKNOWN')) as string

			showDeletionModal(itemId, hostName, onConfirm)
		},
	}

	return itemMethods
}

const useEndpointDeletionModal = (commitEndpointDeletion: (id: string, hostName?: string) => void) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const commitDeletionModal = (itemId: string, hostName: string, onConfirm: () => void) => {
		const modalprops: IConfirmationModalProps = {
			title: t('GENERAL:WARNING'),
			message: `${t('ENDPOINTS:DELETE_WARNING')}: '${hostName}'? ${t('ENDPOINTS:ACTION_UNDONE')}.`,
			declineText: t('GENERAL:NO'),
			confirmText: t('GENERAL:YES'),
			onConfirm: () => {
				commitEndpointDeletion(itemId, hostName)
				onConfirm()
			},
		}
		dispatch(modalManagerActions.showConfirmationModal(modalprops))
	}

	return commitDeletionModal
}

const useEndpointCommits = (endpoints: IEndpoint[]) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const reload = () => {
		dispatch(operatorFrontendActions.fetchOperatorEndpoints())
	}

	const commitEndpointCreation = (newItem: IEditableItem) => {
		const newEndpoint = completeDraftItem(newItem)

		if (!newEndpoint) {
			return false
		}

		operatorApi
			.createOperatorEndpoint(newEndpoint)
			.then(() => {
				dispatch(
					modalManagerActions.showNotificationModal({
						title: `${t('ENDPOINTS:CREATE_SUCCESSFUL')}`,
						message: `${t('ENDPOINTS:CREATE_SUCCESSFUL')}: '${newEndpoint.hostName}'`,
					})
				)
				reload()
			})
			.catch((error) => {
				dispatch(
					modalManagerActions.showNotificationModal({
						title: `${t('ENDPOINTS:ERROR_TITLE')}`,
						message: error.response.data,
					})
				)
			})

		return true
	}

	const commitEndpointUpdate = (updatedItem: IEditableItem) => {
		const updatedEndpoint = updateEndpoint(endpoints, updatedItem)
		if (!updatedEndpoint) {
			return false
		}

		operatorApi
			.updateOperatorEndpoint(updatedEndpoint)
			.then(() => {
				dispatch(
					modalManagerActions.showNotificationModal({
						title: `${t('ENDPOINTS:UPDATE_SUCCESSFUL')}`,
						message: `${t('ENDPOINTS:UPDATE_SUCCESSFUL')}: '${updatedEndpoint?.hostName}'`,
					})
				)
				reload()
			})
			.catch((error) => {
				dispatch(
					modalManagerActions.showNotificationModal({
						title: `${t('ENDPOINTS:ERROR_TITLE')}`,
						message: error.response.data,
					})
				)
			})

		return true
	}

	const commitEndpointDeletion = (id: string, name?: string) => {
		operatorApi
			.deleteOperatorEndpoint(id)
			.then(() => {
				dispatch(
					modalManagerActions.showNotificationModal({
						title: `${t('ENDPOINTS:DELETE_TITLE')}`,
						message: `${t('ENDPOINTS:ENDPOINT')} '${name ?? t('ENDPOINTS:UNKNOWN')}' ${t('ENDPOINTS:DELETE_SUCCESSFUL')}.`,
					})
				)
				reload()
			})
			.catch((error) => {
				dispatch(
					modalManagerActions.showNotificationModal({
						title: `${t('ENDPOINTS:ERROR_TITLE')}`,
						message: error.response.data,
					})
				)
			})
	}

	return { commitEndpointCreation, commitEndpointUpdate, commitEndpointDeletion }
}

const getEndpointDraftItem = () => {
	const endpointInfo = {
		id: generateGuid(),
		hostName: undefined as undefined | string,
		blueK8sRuntimeId: undefined as undefined | string,
		greenK8sRuntimeId: undefined as undefined | string,
	}

	return { ...endpointInfo }
}

const getNewEndpointEntry = (
	id: string,
	hostName: string,
	blueK8sRuntimeId: string | null,
	greenK8sRuntimeId: string | null
) => {
	const changeSeqNo = 0

	const newEndpoint: IEndpoint = {
		id,
		hostName,
		blueK8sRuntimeId,
		greenK8sRuntimeId,
		changeSeqNo,
	}

	return newEndpoint
}

const useK8sRuntimes = () => {
	const dispatch = useDispatch()
	const [k8sRuntimes, setK8sRuntimes] = useState<IK8sRuntime[]>([])

	useEffect(() => {
		operatorApi
			.fetchOperatorK8sRuntimes()
			.then((fetchedK8sRuntimes: IK8sRuntime[]) => {
				setK8sRuntimes(fetchedK8sRuntimes)
			})
			.catch((error: Error | AxiosError) => {
				dispatch(modalManagerActions.showErrorModal(error))
			})
	}, [])

	return k8sRuntimes
}

const completeDraftItem = (item: IEditableEntry) => {
	const id = item.id
	const hostName = item.hostName
	const blueK8sRuntimeId = item?.blueK8sRuntimeId as string | null
	const greenK8sRuntimeId = item?.greenK8sRuntimeId as string | null

	if (typeof id !== 'string' || typeof hostName !== 'string' || (!blueK8sRuntimeId && !greenK8sRuntimeId)) {
		return
	}

	if (!isValidUrl(item.hostName as string)) {
		return
	}

	const completeItem = getNewEndpointEntry(id, hostName, blueK8sRuntimeId, greenK8sRuntimeId)
	return completeItem
}

const updateEndpoint = (endpoints: IEndpoint[], item: IEditableItem) => {
	const endpoint = getArrayEntry(endpoints, item.id)
	const blueK8sRuntimeId = item?.blueK8sRuntimeId as string | null
	const greenK8sRuntimeId = item?.greenK8sRuntimeId as string | null

	if (!blueK8sRuntimeId && !greenK8sRuntimeId) {
		return
	}

	if (!endpoint || !isValidUrl(item.hostName as string)) {
		return
	}

	endpoint.hostName = item.hostName as string
	endpoint.blueK8sRuntimeId = item.blueK8sRuntimeId as string | null
	endpoint.greenK8sRuntimeId = item.greenK8sRuntimeId as string | null
	return endpoint
}
