import { e_Interpretation } from '../enums/e_Interpretation'

export const checkForInterpretation = (value: e_Interpretation | undefined) => {
	switch (value) {
		case e_Interpretation.internetUrl:
			return ''
		case e_Interpretation.email:
			return 'mailto:'
		case e_Interpretation.phone:
			return 'tel:'
		default:
			return undefined
	}
}
