import React from 'react'
import { Icon, Text } from '@genusbiz/web-ui/controls'
import { createStyle } from '@genusbiz/web-ui/theming'
import { IControlLoopStatusEvent, IControlLoopStatusTaskReport } from 'src/interfaces/IControlLoopStatus'
import { e_ControlLoopStatusEventTypeName } from 'src/enums/e_ControlLoopStatusEventType'
import { defaultIcons } from 'src/consts/defaultIcons'
import { defaultColors } from 'src/consts/defaultColors'

interface IProps {
	report: IControlLoopStatusTaskReport
}
const classes = createStyle({
	eventCard: { marginTop: '4px', padding: '4px', maxWidth: '500px' },
	eventCardList: { marginTop: '8px', marginBottom: '4px' },
	cardHeader: { display: 'flex', justifyContent: 'start', alignContent: 'center' },
	cardBody: { paddingLeft: '29px', paddingRight: '4px', marginTop: '4px' },
	detailsCard: { padding: '4px' },
	statusIcon: { marginRight: '4px' },
	infoIcon: { marginLeft: '4px', padding: '4px' },
})

const groupEventsByType = (
	events: IControlLoopStatusEvent[]
): { [key in e_ControlLoopStatusEventTypeName]: IControlLoopStatusEvent[] } => {
	return events.reduce(
		(accumulator, event) => {
			if (!accumulator[event.typeName]) {
				accumulator[event.typeName] = []
			}
			event && accumulator[event.typeName].push(event)
			return accumulator
		},
		{} as { [key in e_ControlLoopStatusEventTypeName]: IControlLoopStatusEvent[] }
	)
}

export const ControlLoopStatusTaskCard = (props: IProps) => {
	const report = props.report
	const groupedEvents = groupEventsByType(report.events)
	return (
		<div className={classes.eventCard} key={report.heading} id={report.heading}>
			<div className={classes.cardHeader}>
				{report.successful ? (
					<Icon
						iconClassName={defaultIcons.check}
						className={classes.statusIcon}
						size="size24"
						color={defaultColors.successText}
					/>
				) : (
					<Icon
						iconClassName={defaultIcons.error}
						className={classes.statusIcon}
						size="size24"
						color={defaultColors.errorText}
					/>
				)}
				<Text variant="h4">{report.heading}</Text>
				<Icon
					iconClassName={defaultIcons.info}
					size="small"
					style={{}}
					color="gray"
					screenTip={report.description}
					className={classes.infoIcon}
				/>
			</div>
			<div className={classes.cardBody}>
				{Object.keys(groupedEvents).map((eventTypeNameString) => {
					const eventTypeName = eventTypeNameString as e_ControlLoopStatusEventTypeName
					return (
						<div key={eventTypeNameString}>
							<Text variant="h5">{eventTypeName}:</Text>
							<ul>
								{groupedEvents[eventTypeName].map((event) => {
									return (
										<li key={event.id} id={event.id}>
											{event.message}
											<br />
											{event.recommendedAction}
										</li>
									)
								})}
							</ul>
						</div>
					)
				})}
			</div>
		</div>
	)
}
