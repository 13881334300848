import React, { createContext, useContext } from 'react'
import { e_LabelPosition } from '../../enums/e_LabelPosition'

const fieldContainerPropsDefault = {
	labelPosition: e_LabelPosition.left,
	syncWidthProps: { groupId: '' },
	labelBold: false,
}

type IFieldContainerPropsContext = typeof fieldContainerPropsDefault

export const FieldContainerPropsContext = createContext<IFieldContainerPropsContext | undefined>(undefined)

interface FieldContainerPropsContextProviderProps {
	children?: React.ReactNode | React.ReactNode[]
	value: Partial<IFieldContainerPropsContext>
}

export const FieldContainerPropsContextProvider = (props: FieldContainerPropsContextProviderProps) => {
	const inheritedValue = useContext(FieldContainerPropsContext) ?? fieldContainerPropsDefault
	const value = removeUndefinedEntries(props.value)

	const newValue = { ...inheritedValue, ...value }

	return <FieldContainerPropsContext.Provider value={newValue}>{props.children}</FieldContainerPropsContext.Provider>
}

const removeUndefinedEntries = <T extends object>(obj: T) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const definedEntries = Object.entries(obj).filter(([_, value]) => value !== undefined)
	return Object.fromEntries(definedEntries) as T
}

export const useIncludeFieldContainerProps = <T extends object>(props: T) => {
	const inheritedValue = useContext(FieldContainerPropsContext) ?? {}
	const definedEntries = removeUndefinedEntries(props)

	return { ...inheritedValue, ...definedEntries }
}
