export enum e_keyCode {
	// Function Keys
	'F1' = 'F1',
	'F2' = 'F2',
	'F3' = 'F3',
	'F4' = 'F4',
	'F5' = 'F5',
	'F6' = 'F6',
	'F7' = 'F7',
	'F8' = 'F8',
	'F9' = 'F9',
	'F10' = 'F10',
	'F11' = 'F11',
	'F12' = 'F12',

	'Period' = 'Period',
	'Comma' = 'Comma',

	// Navigation Keys
	'Delete' = 'Delete',
	'Home' = 'Home',
	'End' = 'End',
	'PageUp' = 'PageUp',
	'PageDown' = 'PageDown',
	'ArrowLeft' = 'ArrowLeft',
	'ArrowRight' = 'ArrowRight',
	'ArrowUp' = 'ArrowUp',
	'ArrowDown' = 'ArrowDown',

	// Numpad Keys
	'Numpad1' = 'Numpad1',
	'Numpad2' = 'Numpad2',
	'Numpad3' = 'Numpad3',
	'Numpad4' = 'Numpad4',
	'Numpad5' = 'Numpad5',
	'Numpad6' = 'Numpad6',
	'Numpad7' = 'Numpad7',
	'Numpad8' = 'Numpad8',
	'Numpad9' = 'Numpad9',
	'Numpad0' = 'Numpad0',
	'NumpadAdd' = 'NumpadAdd',
	'NumpadSubtract' = 'NumpadSubtract',
	'NumpadMultiply' = 'NumpadMultiply',
	'NumpadDivide' = 'NumpadDivide',

	// Number Keys
	'Digit1' = 'Digit1',
	'Digit2' = 'Digit2',
	'Digit3' = 'Digit3',
	'Digit4' = 'Digit4',
	'Digit5' = 'Digit5',
	'Digit6' = 'Digit6',
	'Digit7' = 'Digit7',
	'Digit8' = 'Digit8',
	'Digit9' = 'Digit9',
	'Digit0' = 'Digit0',

	// Character Keys
	'KeyA' = 'KeyA',
	'KeyB' = 'KeyB',
	'KeyC' = 'KeyC',
	'KeyD' = 'KeyD',
	'KeyE' = 'KeyE',
	'KeyF' = 'KeyF',
	'KeyG' = 'KeyG',
	'KeyH' = 'KeyH',
	'KeyI' = 'KeyI',
	'KeyJ' = 'KeyJ',
	'KeyK' = 'KeyK',
	'KeyL' = 'KeyL',
	'KeyM' = 'KeyM',
	'KeyN' = 'KeyN',
	'KeyO' = 'KeyO',
	'KeyP' = 'KeyP',
	'KeyQ' = 'KeyQ',
	'KeyR' = 'KeyR',
	'KeyS' = 'KeyS',
	'KeyT' = 'KeyT',
	'KeyU' = 'KeyU',
	'KeyV' = 'KeyV',
	'KeyW' = 'KeyW',
	'KeyX' = 'KeyX',
	'KeyY' = 'KeyY',
	'KeyZ' = 'KeyZ',
	'Space' = 'Space',
}

export const keyCodeValues: { [key: string]: string } = {
	// Function Keys
	F1: 'F1',
	F2: 'F2',
	F3: 'F3',
	F4: 'F4',
	F5: 'F5',
	F6: 'F6',
	F7: 'F7',
	F8: 'F8',
	F9: 'F9',
	F10: 'F10',
	F11: 'F11',
	F12: 'F12',

	// Separator Keys
	Period: '.',
	Comma: ',',

	//  Navigation Keys
	Delete: 'Delete',
	Home: 'Home',
	End: 'End',
	PageUp: 'PageUp',
	PageDown: 'PageDown',
	ArrowLeft: 'ArrowLeft',
	ArrowRight: 'ArrowRight',
	ArrowUp: 'ArrowUp',
	ArrowDown: 'ArrowDown',

	//  Numpad Keys
	Numpad1: '1',
	Numpad2: '2',
	Numpad3: '3',
	Numpad4: '4',
	Numpad5: '5',
	Numpad6: '6',
	Numpad7: '7',
	Numpad8: '8',
	Numpad9: '9',
	Numpad0: '0',
	NumpadAdd: '+',
	NumpadSubtract: '-',
	NumpadMultiply: '*',
	NumpadDivide: '/',

	// Number Keys
	Digit1: '1',
	Digit2: '2',
	Digit3: '3',
	Digit4: '4',
	Digit5: '5',
	Digit6: '6',
	Digit7: '7',
	Digit8: '8',
	Digit9: '9',
	Digit0: '0',

	// Character Keys
	KeyA: 'A',
	KeyB: 'B',
	KeyC: 'C',
	KeyD: 'D',
	KeyE: 'E',
	KeyF: 'F',
	KeyG: 'G',
	KeyH: 'H',
	KeyI: 'I',
	KeyJ: 'J',
	KeyK: 'K',
	KeyL: 'L',
	KeyM: 'M',
	KeyN: 'N',
	KeyO: 'O',
	KeyP: 'P',
	KeyQ: 'Q',
	KeyR: 'R',
	KeyS: 'S',
	KeyT: 'T',
	KeyU: 'U',
	KeyV: 'V',
	KeyW: 'W',
	KeyX: 'X',
	KeyY: 'Y',
	KeyZ: 'Z',
	Space: 'Space',
}
