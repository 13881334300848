import React from 'react'
import classNames from 'clsx'
import { CommandBar } from '../CommandBar'
import { createStyle } from '../../theming'
import type { DropdownListMenuItem } from './Dropdown.types'

const classes = createStyle((theme) => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '2px',
	},
	right: {
		float: 'right',
	},
	left: {
		float: 'left',
	},
	header: {
		borderBottom: '1px solid ' + theme.colors.list.border,
	},
	footer: {
		borderTop: '1px solid ' + theme.colors.list.border,
	},
}))
interface IDropdownListMenu {
	leftItems?: DropdownListMenuItem[]
	rightItems?: DropdownListMenuItem[]
	type: 'header' | 'footer'
	onClose?: () => void
}

export const DropdownListMenu = (props: IDropdownListMenu) => {
	const menuClassNames = classNames(classes.wrapper, {
		[classes.header]: props.type === 'header',
		[classes.footer]: props.type === 'footer',
	})

	const onItemClick = (item: DropdownListMenuItem) => {
		!item.stayOpenOnClick && props.onClose?.()
	}

	return (
		<div className={menuClassNames} data-control={props.type}>
			<span className={classes.left}>
				<CommandBar items={props.leftItems} />
			</span>
			<span className={classes.right}>
				<CommandBar items={props.rightItems} onItemClick={onItemClick} />
			</span>
		</div>
	)
}
