import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import { e_LinkTarget } from './Link.types'
import { e_LabelContentLayout } from '../FormControl'
import { Icon } from '../Icon'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useId } from '../hooks/useId'

const classes = createStyle((theme) => ({
	default: {
		gap: 4,
		outline: 'none',
		textDecoration: 'none',
		color: theme.colors.links.text,
		outlineWidth: theme.controls.input.borderWidth,
		outlineStyle: 'solid',
		outlineOffset: `calc(-1*${theme.controls.input.borderWidth})`,
		outlineColor: 'transparent',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'&:hover': {
			textDecoration: 'underline',
			color: theme.colors.links.hoveredText,
		},
		'&:focus-visible': {
			outlineColor: theme.controls.input.focusedColors.border,
		},
		'&:visited': {
			color: theme.palette.primary.themeTertiary,
		},
	},
	defaultFocusThickBorder: { borderWidth: theme.controls.input.focusBorderWidth },
	disabled: {
		color: theme.colors.body.disabledText,
		cursor: 'default',
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'none',
			color: theme.colors.body.disabledText,
		},
		'&:visited': {
			color: theme.colors.body.disabledText,
		},
	},
	icon: {
		alignSelf: 'center',
	},
}))

interface ILinkProps {
	id?: string
	url: string
	displayName: string
	target?: e_LinkTarget
	disabled?: boolean
	screenTip?: string
	dataAttributes?: Record<string, string>
	onActivate?: () => void
	className?: string
	icon?: string
	contentLayout?: e_LabelContentLayout
	color?: string
}

export const Link = React.forwardRef<HTMLAnchorElement, ILinkProps>(
	(props: ILinkProps, forwardRef: React.Ref<HTMLAnchorElement>) => {
		const linkRef = useForwardedRef<HTMLAnchorElement>(forwardRef)
		const { target = e_LinkTarget.self, disabled = false, contentLayout = e_LabelContentLayout.text, icon } = props
		const id = useId('link', props.id)

		const getContent = () => {
			switch (contentLayout) {
				case e_LabelContentLayout.icon:
					return <Icon iconClassName={icon} />
				case e_LabelContentLayout.iconAndText:
					return (
						<>
							<Icon iconClassName={icon} className={classes.icon} />
							{props.displayName}
						</>
					)
				case e_LabelContentLayout.textAndIcon:
					return (
						<>
							{props.displayName}
							<Icon iconClassName={icon} className={classes.icon} />
						</>
					)
				default:
					return <>{props.displayName}</>
			}
		}

		const content = getContent()

		const handleClick = (e: React.MouseEvent | React.TouchEvent) => {
			e.stopPropagation()
			e.preventDefault()

			if (props.onActivate) {
				props.onActivate()
			}
		}

		const linkClasses = classNames(props.className, classes.default, {
			[classes.disabled]: props.disabled,
		})

		return (
			<a
				id={id}
				ref={linkRef}
				role={disabled ? 'link' : undefined}
				href={!disabled ? props.url : undefined}
				target={target}
				className={linkClasses}
				aria-disabled={disabled ? 'true' : undefined}
				aria-label={props.displayName}
				title={props.screenTip}
				style={props.color ? { color: props.color } : undefined}
				rel={target === e_LinkTarget.blank ? 'noreferrer' : undefined}
				{...props.dataAttributes}
				onClick={!props.disabled && props.url === '#' ? handleClick : undefined}
			>
				{content}
			</a>
		)
	}
)

Link.displayName = 'Link'
