import { useCallback, useEffect, useRef } from 'react'
import { topWindow } from './topWindow'

export const useOnScrollOrResize = <T extends HTMLElement | undefined>(
	fn: ((e: Event) => void) | undefined,
	element: React.RefObject<T>,
	isOpen: boolean
) => {
	const isOpenRef = useRef(isOpen)

	useEffect(() => {
		isOpenRef.current = isOpen
	}, [isOpen])

	const onScrollOrResize = useCallback(
		(e: Event) => {
			if (!isOpenRef.current) {
				return
			}

			const target = e.target as Element
			if (target.tagName && (e.target === element.current || element.current?.contains(target))) {
				e.stopPropagation()
			} else {
				fn?.(e)
			}
		},
		[element, fn]
	)

	useEffect(() => {
		const node = element.current

		if (isOpen) {
			if (node) {
				node.addEventListener('scroll', onScrollOrResize, { passive: true })
				node.addEventListener('wheel', onScrollOrResize, { passive: true })
			}

			topWindow.addEventListener('scroll', onScrollOrResize, { passive: true })
			topWindow.addEventListener('wheel', onScrollOrResize, { passive: true })
			topWindow.addEventListener('resize', onScrollOrResize)
		}

		return () => {
			if (node) {
				node.removeEventListener('scroll', onScrollOrResize)
				node.removeEventListener('wheel', onScrollOrResize)
			}

			topWindow.removeEventListener('scroll', onScrollOrResize)
			topWindow.removeEventListener('wheel', onScrollOrResize)
			topWindow.removeEventListener('resize', onScrollOrResize)
		}
	}, [isOpen, element, fn, onScrollOrResize])
}
