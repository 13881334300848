import isEqual from 'lodash/isEqual'
import { Measurer } from '../../../controls/utils/Measurer'
import { e_Placement } from '../../../enums/e_Placement'
import type { BeakWidth, GapSpace } from '../Callout'
import type { ICalloutPosition } from '../calcCalloutPosition'
import { calcCalloutPosition } from '../calcCalloutPosition'

interface ICalloutElementStyles {
	calloutPlacement: string | undefined
	calloutAnchor: string | undefined
	beakSize: number

	calloutElementMarginTop: string | undefined
	calloutElementMarginBottom: string | undefined
	calloutElementMarginLeft: string | undefined
	calloutElementMarginRight: string | undefined
	calloutElementTop: string | undefined
	calloutElementBottom: string | undefined
	calloutElementLeft: string | undefined
	calloutElementRight: string | undefined
	calloutElementTransform: string | undefined
	calloutElementTransformOrigin: string | undefined

	beakElementTop: string | undefined
	beakElementBottom: string | undefined
	beakElementLeft: string | undefined
	beakElementRight: string | undefined
	beakElementWidth: string | undefined
	beakElementHeight: string | undefined

	contentElementMaxWidth: string | undefined
	contentElementMaxHeight: string | undefined
	contentElementOverflowX: string | undefined
	contentElementOverflowY: string | undefined
}

const getCurrentCalloutStyle = (
	calloutElement: HTMLDivElement,
	calloutBeakElement: HTMLDivElement,
	calloutContentElement: HTMLDivElement,
	actualBeakWidth: number
): ICalloutElementStyles => {
	return {
		calloutPlacement: calloutElement.dataset.calloutPlacement,
		calloutAnchor: calloutElement.dataset.calloutAnchor,
		beakSize: actualBeakWidth,

		calloutElementMarginTop: calloutElement.style.getPropertyValue('margin-top') || undefined,
		calloutElementMarginBottom: calloutElement.style.getPropertyValue('margin-bottom') || undefined,
		calloutElementMarginLeft: calloutElement.style.getPropertyValue('margin-left') || undefined,
		calloutElementMarginRight: calloutElement.style.getPropertyValue('margin-right') || undefined,
		calloutElementTop: calloutElement.style.getPropertyValue('top') || undefined,
		calloutElementBottom: calloutElement.style.getPropertyValue('bottom') || undefined,
		calloutElementLeft: calloutElement.style.getPropertyValue('left') || undefined,
		calloutElementRight: calloutElement.style.getPropertyValue('right') || undefined,
		calloutElementTransform: calloutElement.style.getPropertyValue('transform') || undefined,
		calloutElementTransformOrigin: calloutElement.style.getPropertyValue('transform-origin') || undefined,

		beakElementTop: calloutBeakElement.style.getPropertyValue('top') || undefined,
		beakElementBottom: calloutBeakElement.style.getPropertyValue('bottom') || undefined,
		beakElementLeft: calloutBeakElement.style.getPropertyValue('left') || undefined,
		beakElementRight: calloutBeakElement.style.getPropertyValue('right') || undefined,
		beakElementWidth: calloutBeakElement.style.getPropertyValue('width') || undefined,
		beakElementHeight: calloutBeakElement.style.getPropertyValue('height') || undefined,

		contentElementMaxWidth: calloutContentElement.style.getPropertyValue('max-width') || undefined,
		contentElementMaxHeight: calloutContentElement.style.getPropertyValue('max-height') || undefined,
		contentElementOverflowX: calloutContentElement.style.getPropertyValue('overflow-x') || undefined,
		contentElementOverflowY: calloutContentElement.style.getPropertyValue('overflow-y') || undefined,
	}
}

const getCalloutStyleFromCalculatedPosition = (
	calculatedPosition: ICalloutPosition,
	anchorMeasurer: Measurer,
	actualBeakWidth: number
): ICalloutElementStyles => {
	const calloutAnchor = `${calculatedPosition.beakPos}px`

	const windowBounds = {
		left: anchorMeasurer.left - anchorMeasurer.actualScreenSpaceLeft,
		top: anchorMeasurer.top - anchorMeasurer.actualScreenSpaceTop,
		right: anchorMeasurer.right + anchorMeasurer.actualScreenSpaceRight,
		bottom: anchorMeasurer.bottom + anchorMeasurer.actualScreenSpaceBottom,
	}

	const halfBeakWidth = actualBeakWidth / 2

	const calloutStyle: ICalloutElementStyles = {
		calloutPlacement: calculatedPosition.placement,
		calloutAnchor: calloutAnchor,
		beakSize: actualBeakWidth,

		calloutElementMarginTop: undefined,
		calloutElementMarginBottom: undefined,
		calloutElementMarginLeft: undefined,
		calloutElementMarginRight: undefined,
		calloutElementTop: undefined,
		calloutElementBottom: undefined,
		calloutElementLeft: undefined,
		calloutElementRight: undefined,
		calloutElementTransform: undefined,
		calloutElementTransformOrigin: undefined,

		beakElementTop: undefined,
		beakElementBottom: undefined,
		beakElementLeft: undefined,
		beakElementRight: undefined,
		beakElementWidth: `${actualBeakWidth}px`,
		beakElementHeight: `${actualBeakWidth}px`,

		contentElementMaxWidth: undefined,
		contentElementMaxHeight: undefined,
		contentElementOverflowX: undefined,
		contentElementOverflowY: undefined,
	}

	if (calculatedPosition.maxWidth) {
		calloutStyle.contentElementMaxWidth = `${calculatedPosition.maxWidth}px`
		calloutStyle.contentElementOverflowX = 'auto'
	}
	if (calculatedPosition.maxHeight) {
		calloutStyle.contentElementMaxHeight = `${calculatedPosition.maxHeight}px`
		calloutStyle.contentElementOverflowY = 'auto'
	}

	switch (calculatedPosition.edge) {
		// position beak and callout on edge
		case 'bottom': {
			calloutStyle.calloutElementMarginTop = `${actualBeakWidth}px`
			calloutStyle.calloutElementTop = `${windowBounds.top + anchorMeasurer.bottom}px`
			calloutStyle.beakElementTop = `-${halfBeakWidth}px`
			break
		}

		case 'top': {
			calloutStyle.calloutElementMarginBottom = `${actualBeakWidth}px`
			calloutStyle.calloutElementBottom = `${windowBounds.bottom - anchorMeasurer.top}px`
			calloutStyle.beakElementBottom = `-${halfBeakWidth}px`
			break
		}

		case 'left': {
			calloutStyle.calloutElementMarginRight = `${actualBeakWidth}px`
			calloutStyle.calloutElementRight = `${windowBounds.right - anchorMeasurer.left}px`
			calloutStyle.beakElementRight = `-${halfBeakWidth}px`
			break
		}

		case 'right': {
			calloutStyle.calloutElementMarginLeft = `${actualBeakWidth}px`
			calloutStyle.calloutElementLeft = `${anchorMeasurer.right}px`
			calloutStyle.beakElementLeft = `-${halfBeakWidth}px`
			break
		}
	}

	// adjust callout and beak according to beak pos (common for top/bottom and left/right edges)
	switch (calculatedPosition.edge) {
		case 'top':
		case 'bottom': {
			switch (calculatedPosition.alignment) {
				case 'start': {
					calloutStyle.calloutElementTransformOrigin = 'left'
					calloutStyle.calloutElementTransform = `translateX(${-calculatedPosition.beakOffset}px)`
					calloutStyle.calloutElementLeft = `${windowBounds.left + calculatedPosition.beakPos}px`

					calloutStyle.beakElementLeft = `${calculatedPosition.beakOffset - halfBeakWidth}px`

					break
				}

				case 'center': {
					calloutStyle.beakElementLeft = `calc(50% - ${halfBeakWidth}px)`
					calloutStyle.calloutElementTransformOrigin = 'center'
					calloutStyle.calloutElementTransform = 'translateX(-50%)'
					calloutStyle.calloutElementLeft = `${calculatedPosition.beakPos}px`
					break
				}

				case 'end': {
					calloutStyle.calloutElementTransformOrigin = 'right'
					calloutStyle.calloutElementTransform = `translateX(${calculatedPosition.beakOffset}px)`
					calloutStyle.calloutElementRight = `${windowBounds.right - calculatedPosition.beakPos}px`

					calloutStyle.beakElementRight = `${calculatedPosition.beakOffset - halfBeakWidth}px`

					break
				}
			}

			break
		}

		case 'left':
		case 'right': {
			switch (calculatedPosition.alignment) {
				case 'start': {
					calloutStyle.calloutElementTransformOrigin = 'top'
					calloutStyle.calloutElementTransform = `translateY(${-calculatedPosition.beakOffset}px)`
					calloutStyle.calloutElementTop = `${windowBounds.top + calculatedPosition.beakPos}px`
					calloutStyle.beakElementTop = `${calculatedPosition.beakOffset - halfBeakWidth}px`
					break
				}

				case 'center': {
					calloutStyle.beakElementTop = `calc(50% - ${halfBeakWidth}px)`
					calloutStyle.calloutElementTransformOrigin = 'center'
					calloutStyle.calloutElementTransform = 'translateY(-50%)'
					calloutStyle.calloutElementTop = `${calculatedPosition.beakPos}px`
					break
				}

				case 'end': {
					calloutStyle.calloutElementTransformOrigin = 'bottom'
					calloutStyle.calloutElementTransform = `translateY(${calculatedPosition.beakOffset}px)`
					calloutStyle.calloutElementBottom = `${windowBounds.bottom - calculatedPosition.beakPos}px`
					calloutStyle.beakElementBottom = `${calculatedPosition.beakOffset - halfBeakWidth}px`
					break
				}
			}

			break
		}
	}

	return calloutStyle
}

function applyCalloutStyles(
	nextStyles: ICalloutElementStyles,
	calloutElement: HTMLDivElement,
	calloutBeakElement: HTMLDivElement,
	calloutContentElement: HTMLDivElement
) {
	calloutElement.dataset.calloutPlacement = nextStyles.calloutPlacement
	calloutElement.dataset.calloutAnchor = nextStyles.calloutAnchor

	if (nextStyles.calloutElementMarginTop) {
		calloutElement.style.marginTop = nextStyles.calloutElementMarginTop
	} else {
		calloutElement.style.removeProperty('margin-top')
	}

	if (nextStyles.calloutElementMarginBottom) {
		calloutElement.style.marginBottom = nextStyles.calloutElementMarginBottom
	} else {
		calloutElement.style.removeProperty('margin-bottom')
	}

	if (nextStyles.calloutElementMarginLeft) {
		calloutElement.style.marginLeft = nextStyles.calloutElementMarginLeft
	} else {
		calloutElement.style.removeProperty('margin-left')
	}

	if (nextStyles.calloutElementMarginRight) {
		calloutElement.style.marginRight = nextStyles.calloutElementMarginRight
	} else {
		calloutElement.style.removeProperty('margin-right')
	}

	if (nextStyles.calloutElementTop) {
		calloutElement.style.top = nextStyles.calloutElementTop
	} else {
		calloutElement.style.removeProperty('top')
	}

	if (nextStyles.calloutElementBottom) {
		calloutElement.style.bottom = nextStyles.calloutElementBottom
	} else {
		calloutElement.style.removeProperty('bottom')
	}

	if (nextStyles.calloutElementLeft) {
		calloutElement.style.left = nextStyles.calloutElementLeft
	} else {
		calloutElement.style.removeProperty('left')
	}

	if (nextStyles.calloutElementRight) {
		calloutElement.style.right = nextStyles.calloutElementRight
	} else {
		calloutElement.style.removeProperty('right')
	}

	if (nextStyles.calloutElementTransform) {
		calloutElement.style.transform = nextStyles.calloutElementTransform
	} else {
		calloutElement.style.removeProperty('transform')
	}

	if (nextStyles.calloutElementTransformOrigin) {
		calloutElement.style.transformOrigin = nextStyles.calloutElementTransformOrigin
	} else {
		calloutElement.style.removeProperty('transform-origin')
	}

	if (nextStyles.beakElementTop) {
		calloutBeakElement.style.top = nextStyles.beakElementTop
	} else {
		calloutBeakElement.style.removeProperty('top')
	}

	if (nextStyles.beakElementBottom) {
		calloutBeakElement.style.bottom = nextStyles.beakElementBottom
	} else {
		calloutBeakElement.style.removeProperty('bottom')
	}

	if (nextStyles.beakElementLeft) {
		calloutBeakElement.style.left = nextStyles.beakElementLeft
	} else {
		calloutBeakElement.style.removeProperty('left')
	}

	if (nextStyles.beakElementRight) {
		calloutBeakElement.style.right = nextStyles.beakElementRight
	} else {
		calloutBeakElement.style.removeProperty('right')
	}

	if (nextStyles.beakElementWidth) {
		calloutBeakElement.style.width = nextStyles.beakElementWidth
	} else {
		calloutBeakElement.style.removeProperty('width')
	}

	if (nextStyles.beakElementHeight) {
		calloutBeakElement.style.height = nextStyles.beakElementHeight
	} else {
		calloutBeakElement.style.removeProperty('height')
	}

	if (nextStyles.contentElementMaxWidth) {
		calloutContentElement.style.maxWidth = nextStyles.contentElementMaxWidth
	} else {
		calloutContentElement.style.removeProperty('max-width')
	}

	if (nextStyles.contentElementMaxHeight) {
		calloutContentElement.style.maxHeight = nextStyles.contentElementMaxHeight
	} else {
		calloutContentElement.style.removeProperty('max-height')
	}

	if (nextStyles.contentElementOverflowX) {
		calloutContentElement.style.overflowX = nextStyles.contentElementOverflowX
	} else {
		calloutContentElement.style.removeProperty('overflow-x')
	}

	if (nextStyles.contentElementOverflowY) {
		calloutContentElement.style.overflowY = nextStyles.contentElementOverflowY
	} else {
		calloutContentElement.style.removeProperty('overflow-y')
	}
}

export const positionCalloutAndSetEdgeWithTransform = (
	calloutElement: HTMLDivElement,
	calloutAnchorElement: HTMLElement,
	calloutContents: React.RefObject<HTMLDivElement>,
	calloutBeak: React.RefObject<HTMLDivElement>,
	placement: e_Placement | undefined,
	showBeak: boolean | undefined,
	gapSpace: GapSpace | undefined,
	beakWidth: BeakWidth | undefined
) => {
	const actualBeakWidth = showBeak ? beakWidth || 15 : 0
	const beakInsetFromCorner = showBeak ? 20 : 0

	const calloutContentElement = calloutContents.current

	if (!calloutContentElement) {
		return
	}

	const calloutBeakElement = calloutBeak.current

	if (!calloutBeakElement || calloutBeakElement === null) {
		return
	}

	const calloutContentMeasurer = new Measurer(calloutContentElement)
	const anchorMeasurer = new Measurer(calloutAnchorElement)

	const calculatedPosition = calcCalloutPosition(
		calloutContentMeasurer,
		anchorMeasurer,
		placement ?? e_Placement.bottom,
		actualBeakWidth,
		gapSpace || 0,
		beakInsetFromCorner
	)

	if (!calculatedPosition) {
		return
	}

	const currentStyles = getCurrentCalloutStyle(
		calloutElement,
		calloutBeakElement,
		calloutContentElement,
		actualBeakWidth
	)
	const nextStyles = getCalloutStyleFromCalculatedPosition(calculatedPosition, anchorMeasurer, actualBeakWidth)

	if (isEqual(currentStyles, nextStyles)) {
		return
	}

	applyCalloutStyles(nextStyles, calloutElement, calloutBeakElement, calloutContentElement)
}
