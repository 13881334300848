export enum e_actionType {
	action = 'action',
	separator = 'separator',
}

export interface IActionCommand {
	type: e_actionType.action
	id: string
	displayName?: string
	iconClassName?: string
	screenTip?: string
	iconColorClass?: string
	disabled?: boolean
	isChecked?: boolean
	hidden?: boolean
	dataAttributes?: Record<string, string>
}

export interface IActionSeparator {
	type: e_actionType.separator
	id?: string
}

export type IActionEntry = IActionSeparator | IActionCommand
