import type {
	DEPRECATED_GridCellNode,
	ElementNode,
	GridMapType,
	GridMapValueType,
	GridSelection,
	LexicalNode,
} from 'lexical'
import {
	$getSelection,
	$isElementNode,
	$isParagraphNode,
	$isRangeSelection,
	$isTextNode,
	DEPRECATED_$computeGridMap,
	DEPRECATED_$getNodeTriplet,
	DEPRECATED_$isGridSelection,
} from 'lexical'
import type { CustomTableCellNode, e_BorderWidthPosition } from '../nodes/CustomTableCellNode'
import { $isCustomTableCellNode } from '../nodes/CustomTableCellNode'
import type { TableCellNode, TableRowNode } from '@lexical/table'
import { $insertTableColumn__EXPERIMENTAL, $isTableRowNode, $isTableCellNode } from '@lexical/table'

function $getTableGrid() {
	const selection = $getSelection()

	if (!($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection))) {
		throw new Error('Expected a RangeSelection or GridSelection')
	}

	const focus = selection.focus.getNode()

	const [, , grid] = DEPRECATED_$getNodeTriplet(focus)
	return grid
}

export function getSelectionSpan(selection: GridSelection) {
	const shape = selection.getShape()

	return {
		columns: shape.toX - shape.fromX + 1,
		rows: shape.toY - shape.fromY + 1,
	}
}

export function $getTableSelectionCoords() {
	const selection = $getSelection()

	if (!($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection))) {
		throw new Error('Expected a RangeSelection or GridSelection')
	}

	const focus = selection.focus.getNode()
	const anchor = selection.anchor.getNode()

	const [focusCell, , grid] = DEPRECATED_$getNodeTriplet(focus)
	const [anchorCell] = DEPRECATED_$getNodeTriplet(anchor)
	const [gridMap, anchorCellMap, focusCellMap] = DEPRECATED_$computeGridMap(grid, anchorCell, focusCell)
	const startCol = Math.min(anchorCellMap.startColumn, focusCellMap.startColumn)
	const endCol = Math.max(anchorCellMap.startColumn, focusCellMap.startColumn)
	const startRow = Math.min(anchorCellMap.startRow, focusCellMap.startRow)
	const endRow = Math.max(anchorCellMap.startRow, focusCellMap.startRow)

	return { startCol, endCol, startRow, endRow, gridMap }
}

export function $getSelectedTableNodes() {
	const { startCol, endCol, startRow, endRow, gridMap } = $getTableSelectionCoords()
	return gridMap.slice(startRow, endRow + 1).map((row) => row.slice(startCol, endCol + 1))
}

export function $getTableSelectionFrame() {
	const { startCol, endCol, startRow, endRow, gridMap } = $getTableSelectionCoords()

	const topNodes = startRow > 0 ? gridMap[startRow - 1].slice(startCol, endCol + 1) : []
	const rightNodes =
		endCol < gridMap[startRow].length - 1 ? gridMap.slice(startRow, endRow + 1).map((row) => row[endCol + 1]) : []
	const bottomNodes = endRow < gridMap.length - 1 ? gridMap[endRow + 1].slice(startCol, endCol + 1) : []
	const leftNodes = startCol > 0 ? gridMap.slice(startRow, endRow + 1).map((row) => row[startCol - 1]) : []
	return {
		topNodes,
		rightNodes,
		bottomNodes,
		leftNodes,
	}
}

export function $setBorderWidth(width: number): void {
	const selectedCells = $getSelectedTableNodes()

	const setNodes = (cells: GridMapValueType[], pos?: e_BorderWidthPosition) => {
		cells.forEach((cell) => {
			if ($isCustomTableCellNode(cell.cell)) {
				cell.cell.setBorderWidth(width, pos)
			}
		})
	}

	selectedCells.forEach((row) => {
		setNodes(row)
	})
	const { topNodes, rightNodes, bottomNodes, leftNodes } = $getTableSelectionFrame()
	setNodes(topNodes, 'bottom')
	setNodes(rightNodes, 'left')
	setNodes(bottomNodes, 'top')
	setNodes(leftNodes, 'right')
}

export function $insertTableColumnsAtSelection(count: number, shouldInsertAfter: boolean) {
	const { startCol, endCol } = $getTableSelectionCoords()
	for (let i = 0; i < count; i++) {
		$insertTableColumn__EXPERIMENTAL(shouldInsertAfter)
	}
	const grid = $getTableGrid()
	const newCol = shouldInsertAfter ? endCol + 1 : startCol
	const refCol = shouldInsertAfter ? endCol : startCol + 1

	grid.getChildren().forEach((row) => {
		const children = row.getChildren()
		const refCell: CustomTableCellNode = children[refCol]
		const refBorderWidths = refCell.getBorderWidth()
		if (refBorderWidths) {
			for (let ix = 0; ix < count; ix++) {
				const newCell: CustomTableCellNode = children[newCol + (shouldInsertAfter ? ix : -ix)]
				const { top, bottom } = refBorderWidths
				const side = shouldInsertAfter ? refBorderWidths.right : refBorderWidths.left
				newCell.setBorderWidth(top, 'top')
				newCell.setBorderWidth(side, 'right')
				newCell.setBorderWidth(bottom, 'bottom')
				newCell.setBorderWidth(side, 'left')
			}
		}
	})
}

export function $selectLastDescendant(node: ElementNode): void {
	const lastDescendant = node.getLastDescendant()
	if ($isTextNode(lastDescendant)) {
		lastDescendant.select()
	} else if ($isElementNode(lastDescendant)) {
		lastDescendant.selectEnd()
	} else if (lastDescendant !== null) {
		lastDescendant.selectNext()
	}
}

export function $containsEmptyParagraphNode(node: DEPRECATED_GridCellNode): boolean {
	if (node.getChildrenSize() < 1) {
		return false
	}
	const pNode = node.getFirstChildOrThrow()
	if (!$isParagraphNode(pNode) || !pNode.isEmpty()) {
		return false
	}
	return true
}

export function $canUnmerge(): boolean {
	const selection = $getSelection()
	if (
		($isRangeSelection(selection) && !selection.isCollapsed()) ||
		(DEPRECATED_$isGridSelection(selection) && !selection.anchor.is(selection.focus)) ||
		(!$isRangeSelection(selection) && !DEPRECATED_$isGridSelection(selection))
	) {
		return false
	}
	const [cell] = DEPRECATED_$getNodeTriplet(selection.anchor)
	return cell.__colSpan > 1 || cell.__rowSpan > 1
}

export function isGridSelectionRectangular(selection: GridSelection): boolean {
	const focus = selection.focus.getNode()
	const anchor = selection.anchor.getNode()

	const [focusCell, , grid] = DEPRECATED_$getNodeTriplet(focus)
	const [anchorCell] = DEPRECATED_$getNodeTriplet(anchor)
	const [gridMap] = DEPRECATED_$computeGridMap(grid, anchorCell, focusCell)
	const selectedNodes = new Set(
		selection
			.getNodes()
			.filter((n) => n.getType() === 'custom-table-cell')
			.map((n) => n.getKey())
	)
	const { x0, y0, x1, y1 } = findRealAnchorAndFocus(gridMap, selectedNodes)
	for (let y = 0; y < gridMap.length; y++) {
		const row = gridMap[y]
		for (let x = 0; x < row.length; x++) {
			const cell = gridMap[y][x]
			if (selectedNodes.has(cell.cell.getKey())) {
				if (x < x0 || x > x1 || y < y0 || y > y1) {
					return false
				}
			} else {
				if (x0 <= x && x <= x1 && y0 <= y && y <= y1) {
					return false
				}
			}
		}
	}
	return true
}

function findRealAnchorAndFocus(gridMap: GridMapType, selectedNodes: Set<string>) {
	let x0 = Number.MAX_SAFE_INTEGER
	let y0 = Number.MAX_SAFE_INTEGER
	let x1 = -1
	let y1 = -1
	gridMap.forEach((row, y) => {
		row.forEach((cell, x) => {
			if (selectedNodes.has(cell.cell.getKey())) {
				x0 = Math.min(x, x0)
				y0 = Math.min(y, y0)
				x1 = Math.max(x, x1)
				y1 = Math.max(y, y1)
			}
		})
	})
	return { x0, y0, x1, y1 }
}

export function computeSelectionCount(selection: GridSelection): {
	columns: number
	rows: number
} {
	const selectionShape = selection.getShape()
	return {
		columns: selectionShape.toX - selectionShape.fromX + 1,
		rows: selectionShape.toY - selectionShape.fromY + 1,
	}
}

export function $assertIsRowNode(node: LexicalNode): asserts node is TableRowNode {
	if (!$isTableRowNode(node)) {
		throw new Error(`Expected a TableRowNode, got ${node.getType()}`)
	}
}

export function $assertIsCellNode(node: LexicalNode): asserts node is TableCellNode {
	if (!$isTableCellNode(node)) {
		throw new Error(`Expected a TableCellNode, got ${node.getType()}`)
	}
}
