import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import { Avatar } from '../Avatar'
import { Icon } from '../Icon'
import type { IItemSlot, IListItem } from './IListNode'

interface IDefaultListItemRendererProps {
	item: IListItem<never>
	multiLineText?: number
}

const classes = createStyle((theme) => ({
	defaultItemRenderer: {
		flex: 1,
		display: 'grid',
		gridTemplateColumns:
			'[left-slot-start] auto [left-slot-end main-start] 1fr [main-end right-slot-start] auto [right-slot-end]',
	},

	main: {
		display: 'grid',
		gridTemplateRows:
			'[header-start] auto [header-end caption-start] auto [caption-end subcaption-start] auto [subcaption-end text-start] auto [text-end footer-start] auto [footer-end]',
		gridColumn: 'main',
		'& > span': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		height: 'fit-content',
		alignSelf: 'center',
	},

	header: {
		gridRow: 'header',
		color: theme.colors.list.text,
		fontSize: 10,
		display: 'flex',
		alignItems: 'center',
	},
	captionRow: {
		gridRow: 'caption',
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
	},
	caption: {
		color: theme.colors.list.text,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		minWidth: 0,
		maxWidth: '100%',
		flexShrink: 1,
	},
	captionTrail: {
		marginLeft: 'auto',
		paddingLeft: 12,
		color: theme.colors.list.text,
		opacity: 0.5, //theme.colors.list.secondaryText,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		flexShrink: 0,
	},
	subcaption: {
		gridRow: 'subcaption',
		color: theme.colors.list.text,
		fontSize: 12,
	},
	text: {
		gridRow: 'text',
		color: theme.colors.list.text,
		opacity: 0.5, //theme.colors.list.secondaryText,
		fontSize: 12,
	},
	multiLineText: {
		// For wrapping (clamping) text to a number of lines
		display: '-webkit-box',
		'-webkit-box-orient': 'vertical',
		lineClamp: 2, // overriden by style
		whiteSpace: 'normal !important',
	},

	footer: {
		gridRow: 'footer',
		color: theme.colors.list.text,
		opacity: 0.5, //theme.colors.list.secondaryText,
		fontSize: 10,
	},

	leftSlot: {
		gridColumn: 'left-slot',
		display: 'flex',
		alignItems: 'center',
		marginRight: 8,
	},
	rightSlot: {
		gridColumn: 'right-slot',
		marginLeft: 12,
		display: 'flex',
		alignItems: 'center',
	},

	alignAvatarTop: {
		alignSelf: 'start',
		marginTop: 4,
	},
}))

export const DefaultListItemRenderer = (props: IDefaultListItemRendererProps) => {
	// Default Item Rendering, uses CSS grid for alignment.

	const { header, caption, subcaption, text, footer, captionTrail, leftSlot, rightSlot } = props.item
	const textClassName = classNames(classes.text, props.multiLineText && classes.multiLineText)
	const textStyle = props.multiLineText ? { lineClamp: props.multiLineText } : undefined

	const leftSlotContent = leftSlot && getSlotContent(leftSlot, props.item)
	const rightSlotContent = rightSlot && getSlotContent(rightSlot, props.item)

	return (
		<div className={classes.defaultItemRenderer}>
			{leftSlotContent && <div className={classes.leftSlot}>{leftSlotContent}</div>}
			<div className={classes.main}>
				{header && <span className={classes.header}>{header}</span>}
				{caption && (
					<div className={classes.captionRow}>
						<span className={classes.caption}>{caption}</span>
						{captionTrail && <span className={classes.captionTrail}>{captionTrail}</span>}
					</div>
				)}
				{subcaption && <span className={classes.subcaption}>{subcaption}</span>}
				{text && (
					<span className={textClassName} style={textStyle}>
						{text}
					</span>
				)}
				{footer && <span className={classes.footer}>{footer}</span>}
			</div>
			{rightSlotContent && <div className={classes.rightSlot}>{rightSlotContent}</div>}
		</div>
	)
}

const getSlotContent = (slot: IItemSlot, item: IListItem) => {
	if (!slot) {
		return slot
	}
	if (typeof slot === 'object') {
		switch (slot.type) {
			case 'avatar':
				return (
					<Avatar
						imageUrl={slot.imageUrl}
						initials={slot.initials}
						size={slot.size || 'small'}
						className={classNames('text' in item && classes.alignAvatarTop, slot.className)}
					/>
				)
			case 'icon':
				return <Icon {...slot} />
		}
	} else if (typeof slot === 'function') {
		return slot(item)
	} else {
		return slot
	}
}
