import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { IListItem, IListNode, IListSection } from './IListNode'
import { e_ListNode } from './IListNode'
import { useControlledSelection } from './useControlledSelection'

/**
 *  Keyboard Interaction from https://www.w3.org/WAI/ARIA/apg/patterns/listbox/
 *	Supports two types of multiple selection models; rowClickSelectionMode = 'toggle': does not require modifer keys,
 *	and rowClickSelectionMode = 'replace': will unselect all selected items except the focused item, unless Ctrl or Shift is pressed.
 * */
export const useSelectionFocusActivationModel = (props: {
	selectionMode: 'multi' | 'single' | 'none'
	rowClickSelectionMode: 'toggle' | 'replace' | 'none'
	selectedIds?: string[]
	onSelectionChange?: (ids: string[]) => void
	items: IListNode[]
	selectionFollowsFocus?: boolean
	preventEmptySelection: boolean
	onActivate?: (id: string) => void
	activateOnSingleClick?: boolean
	cycleKeyboardNavigation?: boolean
	isSearchMatch?: (item: IListItem, search: string) => boolean
	onListKeyDown?: (e: React.KeyboardEvent) => void
	disabled?: boolean
}) => {
	const {
		rowClickSelectionMode,
		onSelectionChange,
		selectionMode,
		selectionFollowsFocus = selectionMode === 'single' || rowClickSelectionMode === 'replace',
		items,
		cycleKeyboardNavigation,
		isSearchMatch,
		preventEmptySelection,
		onActivate,
		activateOnSingleClick,
	} = props
	const isMultiSelect = selectionMode === 'multi'

	const nodeList = useMemo(() => {
		return getVisibleFlatNodeList(items)
	}, [items])

	const [focusedItemId, setFocusedItemId] = useState<string>()

	const selectedIdBeforeShiftPressedRef = useRef<string>()

	const [scrollToFirstSelected, setScrollToFirstSelected] = useState(true)
	const { setSelection, selectedIdsRef, firstSelectedIdRef } = useControlledSelection(
		props.selectedIds,
		onSelectionChange,
		selectedIdBeforeShiftPressedRef,
		setScrollToFirstSelected,
		nodeList,
		preventEmptySelection
	)

	useEffect(() => {
		// Reset selectedIdBeforeShiftPressed and focusedItemId if no longer in items.
		const ids = getAllItemIds(items)
		const selectedIdBeforeShiftPressed = selectedIdBeforeShiftPressedRef.current
		if (selectedIdBeforeShiftPressed && !ids.includes(selectedIdBeforeShiftPressed)) {
			selectedIdBeforeShiftPressedRef.current = undefined
		}
		if (focusedItemId && !ids.includes(focusedItemId)) {
			setFocusedItemId(undefined)
		}
	}, [items])

	const setSelectionRange = useCallback(
		(fromId?: string, toId?: string) => {
			if (fromId === undefined || toId === undefined) {
				return
			}
			setSelection(getItemIdsBetween(fromId, toId, nodeList))
		},
		[nodeList, setSelection]
	)

	const focusNextItem = (currentId?: string) => {
		const nextItemId = currentId
			? getNextItemId(currentId, nodeList, cycleKeyboardNavigation)
			: getFirstItemId(nodeList)
		if (nextItemId) {
			setFocusedItemId(nextItemId)
			return nextItemId
		}
	}

	const focusPreviousItem = (currentId?: string) => {
		const previousItemId = currentId
			? getPreviousItemId(currentId, nodeList, cycleKeyboardNavigation)
			: getLastItemId(nodeList)
		if (previousItemId) {
			setFocusedItemId(previousItemId)
			return previousItemId
		}
	}

	const toggleItemSelection = useCallback(
		(id: string) => {
			const selectedIds = selectedIdsRef.current
			if (selectionMode === 'multi') {
				if (selectedIds.includes(id)) {
					setSelection(selectedIds.filter((selectedId) => selectedId !== id))
				} else {
					selectedIdBeforeShiftPressedRef.current = id
					setSelection([...selectedIds, id])
				}
			} else if (selectionMode === 'single') {
				if (selectedIds.includes(id)) {
					setSelection([])
				} else {
					selectedIdBeforeShiftPressedRef.current = id
					setSelection([id])
				}
			}
		},
		[selectionMode, setSelection, selectedIdsRef]
	)

	const shiftKeyHasBeenReleased = useRef(true)
	const onKeyUp = (e: React.KeyboardEvent) => {
		if (!e.shiftKey) {
			shiftKeyHasBeenReleased.current = true
		}
	}

	const moveFocus = (key: 'ArrowDown' | 'ArrowUp', shiftPressed: boolean) => {
		const newFocusedItemId = key === 'ArrowDown' ? focusNextItem(focusedItemId) : focusPreviousItem(focusedItemId)
		if (newFocusedItemId) {
			if (isMultiSelect && shiftPressed && focusedItemId) {
				if (shiftKeyHasBeenReleased.current) {
					setSelection([focusedItemId, newFocusedItemId], focusedItemId)
					shiftKeyHasBeenReleased.current = false
				} else {
					setSelectionRange(selectedIdBeforeShiftPressedRef.current, newFocusedItemId)
				}
			} else if (selectionFollowsFocus) {
				setSelection([newFocusedItemId], newFocusedItemId)
			}
		}
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (props.disabled) {
			return
		}
		props.onListKeyDown?.(e)
		const shiftPressed = e.shiftKey
		const ctrlPressed = e.ctrlKey || e.metaKey
		const key = e.key.toLowerCase() === 'a' ? 'a' : e.key
		const selectedIdBeforeShiftPressed = selectedIdBeforeShiftPressedRef.current
		switch (key) {
			case 'ArrowDown':
			case 'ArrowUp': {
				e.preventDefault()
				moveFocus(key, shiftPressed)
				break
			}
			case 'Home': {
				const firstItemId = getFirstItemId(nodeList)
				if (firstItemId) {
					e.preventDefault()
					if (isMultiSelect && shiftPressed && ctrlPressed) {
						setSelectionRange(firstItemId, focusedItemId)
					} else {
						if (selectionFollowsFocus) {
							setSelection([firstItemId], firstItemId)
						}
					}
					setFocusedItemId(firstItemId)
				}
				break
			}
			case 'End': {
				const lastItemId = getLastItemId(nodeList)
				if (lastItemId) {
					e.preventDefault()
					if (isMultiSelect && shiftPressed && ctrlPressed) {
						setSelectionRange(focusedItemId, lastItemId)
					} else {
						if (selectionFollowsFocus) {
							setSelection([lastItemId], lastItemId)
						}
					}
					setFocusedItemId(lastItemId)
				}
				break
			}
			case ' ':
				if (focusedItemId !== undefined && selectionMode !== 'none' && !isTargetCheckbox(e)) {
					e.preventDefault()
					const isAlreadySelected = selectedIdsRef.current.includes(focusedItemId)
					const selectionType = getSelectionType(
						isMultiSelect,
						rowClickSelectionMode,
						ctrlPressed,
						shiftPressed,
						selectedIdBeforeShiftPressed !== undefined,
						true,
						isAlreadySelected
					)
					switch (selectionType) {
						case 'toggle':
							toggleItemSelection(focusedItemId)
							break
						case 'replace':
							setSelection([focusedItemId], focusedItemId)
							break
						case 'range':
							setSelectionRange(selectedIdBeforeShiftPressed, focusedItemId)
					}
				}
				break
			case 'Enter':
				focusedItemId && props.onActivate?.(focusedItemId)
				break
			// @ts-expect-error Fallthrough, to allow searching for 'a'
			case 'a':
				if (ctrlPressed && isMultiSelect) {
					e.preventDefault() // Important if user-select is not none
					const selectableIds = getAllVisibleItemIds(nodeList)
					if (selectedIdsRef.current.length === selectableIds.length) {
						setSelection([])
					} else {
						setSelection(selectableIds)
					}
					break
				}
			// eslint-disable-next-line no-fallthrough
			default:
				if (e.key.length === 1) {
					const itemToFocus = findItemToFocus(e.key.toLowerCase())
					if (itemToFocus) {
						setFocusedItemId(itemToFocus)
						if (selectionFollowsFocus) {
							setSelection([itemToFocus], itemToFocus)
						}
					}
				}
				break
		}
	}

	// Need focusedItemId as a ref so onItemClick stays memoized and in sync.
	const focusedItemIdRef = useRef<string>()
	useEffect(() => {
		focusedItemIdRef.current = focusedItemId
	}, [focusedItemId])

	const onItemClick = useCallback(
		(id: string, e: React.MouseEvent) => {
			if (props.disabled) {
				return
			}
			setFocusedItemId(id)
			if (selectionMode === 'none' || rowClickSelectionMode === 'none' || isTargetCheckbox(e)) {
				activateOnSingleClick && window.setTimeout(() => onActivate?.(id), 0) // SetTimeout to ensure selection is updated before running onActivate, as the selection is often used in the onActivate callback.
				return
			}
			const selectedIdBeforeShiftPressed = selectedIdBeforeShiftPressedRef.current

			const shiftPressed = e.shiftKey
			const ctrlPressed = e.ctrlKey || e.metaKey
			const hasSelectedItemBeforeShiftPressed = selectedIdBeforeShiftPressed !== undefined
			const hasFocusedItem = focusedItemIdRef.current !== undefined
			const isAlreadySelected = selectedIdsRef.current.includes(id)
			const selectionType = getSelectionType(
				isMultiSelect,
				rowClickSelectionMode,
				ctrlPressed,
				shiftPressed,
				hasSelectedItemBeforeShiftPressed,
				hasFocusedItem,
				isAlreadySelected
			)

			switch (selectionType) {
				case 'toggle':
					toggleItemSelection(id)
					break
				case 'replace':
					setSelection([id], id)
					break
				case 'range': {
					const referenceId = hasSelectedItemBeforeShiftPressed
						? selectedIdBeforeShiftPressed
						: focusedItemIdRef.current
					if (referenceId) {
						setSelectionRange(referenceId, id)
					}
				}
			}
			activateOnSingleClick && window.setTimeout(() => onActivate?.(id), 0) // SetTimeout to ensure selection is updated before running onActivate, as the selection is often used in the onActivate callback.
		},
		[
			activateOnSingleClick,
			isMultiSelect,
			onActivate,
			rowClickSelectionMode,
			selectedIdsRef,
			selectionMode,
			setSelection,
			setSelectionRange,
			toggleItemSelection,
			props.disabled,
		]
	)

	const setItemFocusOnListReceivesFocus = () => {
		if (props.disabled) {
			return
		}
		const itemId = selectedIdsRef.current.length ? firstSelectedIdRef.current : getFirstItemId(nodeList)
		setFocusedItemId(itemId)
	}

	const searchClear = useRef<number | null>(null)
	const search = useRef('')
	const findItemToFocus = (character: string) => {
		search.current += character
		clearSearchKeyAfterDelay()
		const searchIndex = nodeList.findIndex((node) => node.id === focusedItemId) + 1

		const isMatch = (item: IListItem) =>
			isSearchMatch ? isSearchMatch(item, search.current) : item.caption?.toLowerCase().startsWith(search.current)

		for (let i = searchIndex; i < nodeList.length; i++) {
			const node = nodeList[i]
			if (node.type === e_ListNode.item && isMatch(node)) {
				return node.id
			}
		}
		for (let i = 0; i < searchIndex; i++) {
			const node = nodeList[i]
			if (node.type === e_ListNode.item && isMatch(node)) {
				return node.id
			}
		}
		search.current = ''
	}
	const clearSearchKeyAfterDelay = () => {
		if (searchClear.current) {
			clearTimeout(searchClear.current)
		}
		searchClear.current = window.setTimeout(() => {
			search.current = ''
			searchClear.current = null
		}, 500)
	}

	return {
		onKeyDown,
		onKeyUp,
		onItemClick,
		focusedItemId,
		selectedIdsRef,
		setFocusedItemId,
		onCheck: toggleItemSelection,
		setItemFocusOnListReceivesFocus,
		firstSelectedIdRef,
		setScrollToFirstSelected,
		scrollToFirstSelected,
	}
}

const getSelectionType = (
	isMultiSelect: boolean,
	rowClickSelectionMode: 'toggle' | 'replace' | 'none',
	ctrlPressed: boolean,
	shiftPressed: boolean,
	hasSelectedItemBeforeShiftPressed: boolean,
	hasFocusedItem: boolean,
	isAlreadySelected: boolean
): 'toggle' | 'replace' | 'range' | undefined => {
	if (isMultiSelect) {
		if (shiftPressed && (hasSelectedItemBeforeShiftPressed || hasFocusedItem)) {
			return 'range'
		} else if (rowClickSelectionMode === 'replace') {
			return ctrlPressed ? 'toggle' : 'replace'
		} else if (rowClickSelectionMode === 'toggle') {
			return 'toggle'
		}
	} else {
		if (rowClickSelectionMode === 'replace') {
			return isAlreadySelected && ctrlPressed ? 'toggle' : 'replace'
		} else {
			return isAlreadySelected ? 'toggle' : 'replace'
		}
	}
}

export type IFlatListNodes = (IListItem | Omit<IListSection, 'children'>)[]

const getVisibleFlatNodeList = (items: IListNode[] /* ,expandedItemIds?: string[] */): IFlatListNodes => {
	const nodeList: IFlatListNodes = []

	const addToList = (item: IListNode) => {
		switch (item.type) {
			case e_ListNode.item:
				nodeList.push({ ...item })
				break

			case e_ListNode.section: {
				const { children, ...flatItem } = item
				nodeList.push({ ...flatItem })
				children?.forEach((child) => addToList(child))
				break
			}

			/* case 'group':
				nodeList.push({ type: item.type, id: item.id })
				if (expandedItemIds?.includes(item.id)) {
					item.children?.forEach((child) => addToList(child, nodeList))
				}
				break */
		}
	}
	items.forEach((item) => {
		addToList(item)
	})
	return nodeList
}

const getNextItemId = (currentItemId: string, nodeList: IFlatListNodes, cycle?: boolean) => {
	const currentIndex = nodeList.findIndex((node) => node.id === currentItemId)
	if (currentIndex === -1) {
		throw new Error(`Item with id ${currentItemId} not found in list.`)
	}

	for (let i = currentIndex + 1; i < nodeList.length; i++) {
		const node = nodeList[i]
		if (node.id) {
			return node.id
		}
	}
	if (cycle) {
		for (let i = 0; i < currentIndex; i++) {
			const node = nodeList[i]
			if (node.id) {
				return node.id
			}
		}
	}
}

const getPreviousItemId = (currentItemId: string, nodeList: IFlatListNodes, cycle?: boolean) => {
	const currentIndex = nodeList.findIndex((node) => node.id === currentItemId)
	if (currentIndex === -1) {
		throw new Error(`Item with id ${currentItemId} not found in list.`)
	}

	for (let i = currentIndex - 1; i >= 0; i--) {
		const node = nodeList[i]
		if (node.id) {
			return node.id
		}
	}

	if (cycle) {
		for (let i = nodeList.length - 1; i > currentIndex; i--) {
			const node = nodeList[i]
			if (node.id) {
				return node.id
			}
		}
	}
}

const getFirstItemId = (nodeList: IFlatListNodes) => {
	const node = nodeList.find((n) => n.id) as IListItem | undefined
	return node?.id
}

const getLastItemId = (nodeList: IFlatListNodes) => {
	const node = nodeList.findLast((n) => n.id) as IListItem | undefined
	return node?.id
}

const getItemIdsBetween = (fromId: string, toId: string, nodeList: IFlatListNodes) => {
	const fromIndex = nodeList.findIndex((node) => node.id === fromId)
	const toIndex = nodeList.findIndex((node) => node.id === toId)
	if (fromIndex === -1 || toIndex === -1) {
		throw new Error(`Item not found in list.`)
	}
	const start = fromIndex < toIndex ? fromIndex : toIndex
	const end = toIndex > fromIndex ? toIndex + 1 : fromIndex + 1

	return nodeList.slice(start, end).reduce<string[]>((acc, curr) => {
		if (curr.id) {
			return [...acc, curr.id]
		}
		return acc
	}, [])
}

const getAllVisibleItemIds = (nodeList: IFlatListNodes) => {
	return nodeList.reduce<string[]>((acc, curr) => {
		if (curr.id) {
			return [...acc, curr.id]
		}
		return acc
	}, [])
}

const getAllItemIds = (items: IListNode[]) => {
	const ids: string[] = []

	const addId = (item: IListNode) => {
		if (item.id) {
			ids.push(item.id)
		}
		if ('children' in item) {
			item.children?.forEach((child) => addId(child))
		}
	}
	items.forEach((item) => {
		addId(item)
	})
	return ids
}

const isTargetCheckbox = (e: React.MouseEvent | React.KeyboardEvent) => {
	if (e.target instanceof HTMLElement) {
		return e.target.role === 'checkbox' || e.target.parentElement?.parentElement?.role === 'checkbox'
	}
	return false
}
