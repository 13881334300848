export type DialogButtonDescription = {
	title: string
	isEnabled?: boolean
	isHidden?: boolean
	isDefault?: boolean
	isCancel?: boolean
	alignLeft?: boolean
	color?: 'auto' | 'inherit' | 'themecolor'
	onClick: () => void
	dataAttributes?: Record<string, string>
}

export const CLASS_NAME_CONTENT = 'dialogContent'
export const CLASS_NAME_BUTTONS_LEFT = 'dialogButtonsLeft'
export const CLASS_NAME_BUTTONS_RIGHT = 'dialogButtonsRight'
export const PADDING_WIDTH = 12
export const MOBILE_WIDTH = 600

export type ICommonButtonTypes = 'ok' | 'cancel' | 'yes' | 'no' | 'close'
export type DialogCommonButtonSet = 'okCancel' | 'yesNo' | 'yesNoCancel' | ICommonButtonTypes

export type DialogDimension = number | 'auto'
export type DialogDimensionType = 'inner' | 'outer'
