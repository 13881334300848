import type { DialogButtonDescription, DialogCommonButtonSet, ICommonButtonTypes } from '../DialogTypes'

export const formatPixel = (number: number) => {
	return `${number}px`
}

const buttonCombos: { [key in DialogCommonButtonSet]: { [key: string]: ICommonButtonTypes } } = {
	['okCancel']: {
		['ok']: 'ok',
		['cancel']: 'cancel',
	},
	['yesNo']: {
		['yes']: 'yes',
		['no']: 'no',
	},
	['yesNoCancel']: {
		['yes']: 'yes',
		['no']: 'no',
		['cancel']: 'cancel',
	},
	['ok']: {
		['ok']: 'ok',
	},
	['yes']: {
		['yes']: 'yes',
	},
	['no']: {
		['no']: 'no',
	},
	['cancel']: {
		['cancel']: 'cancel',
	},
	['close']: {
		['close']: 'close',
	},
}

const generateCommonButtonDescription = (
	id: string,
	commonButton: ICommonButtonTypes,
	handleClose: (commonButton: ICommonButtonTypes) => void,
	translator: (t: string) => string,
	isValid?: boolean
): DialogButtonDescription => {
	switch (commonButton) {
		case 'ok':
			return {
				title: translator('COMMAND:OK'),
				onClick: () => handleClose('ok'),
				isEnabled: isValid !== undefined ? isValid : true,
				isDefault: true,
				isCancel: false,
				alignLeft: false,
				dataAttributes: { 'data-cy': 'dialog-ok-btn-' + id },
			}
		case 'cancel':
			return {
				title: translator('COMMAND:CANCEL'),
				onClick: () => handleClose('cancel'),
				isEnabled: true,
				isDefault: false,
				isCancel: true,
				alignLeft: false,
				dataAttributes: { 'data-cy': 'dialog-cancel-btn-' + id },
			}
		case 'yes':
			return {
				title: translator('COMMAND:YES'),
				onClick: () => handleClose('yes'),
				isEnabled: isValid !== undefined ? isValid : true,
				isDefault: true,
				isCancel: false,
				alignLeft: false,
				dataAttributes: { 'data-cy': 'dialog-yes-btn-' + id },
			}
		case 'no':
			return {
				title: translator('COMMAND:NO'),
				onClick: () => handleClose('no'),
				isEnabled: true,
				isDefault: false,
				isCancel: false,
				alignLeft: false,
				dataAttributes: { 'data-cy': 'dialog-no-btn-' + id },
			}
		case 'close':
			return {
				title: translator('COMMAND:CLOSE'),
				onClick: () => handleClose('cancel'),
				isEnabled: true,
				isDefault: false,
				isCancel: false,
				alignLeft: false,
				dataAttributes: { 'data-cy': 'dialog-close-btn-' + id },
			}
	}
}

const getCommonButtons = (
	id: string,
	commonButtonSet: DialogCommonButtonSet | undefined,
	handleClose: (commonButton: ICommonButtonTypes) => void,
	translator: (t: string) => string,
	isValid?: boolean
) => {
	if (commonButtonSet && buttonCombos[commonButtonSet]) {
		return Object.values(buttonCombos[commonButtonSet]).map((commonButton) =>
			generateCommonButtonDescription(id, commonButton, handleClose, translator, isValid)
		)
	}

	return []
}

export const getButtons = (
	id: string,
	commonButtonSet: DialogCommonButtonSet | undefined,
	buttonDescriptions: DialogButtonDescription[] | undefined,
	handleClose: (commonButton: ICommonButtonTypes) => void,
	translator: (t: string) => string,
	isValid?: boolean
) => {
	const leftButtons = buttonDescriptions
		? buttonDescriptions.filter((description) => description.alignLeft === true)
		: []
	const rightCustomButtons = buttonDescriptions
		? buttonDescriptions.filter((description) => !description.alignLeft)
		: []

	const commonButtons = getCommonButtons(id, commonButtonSet, handleClose, translator, isValid)

	const rightButtons = [...commonButtons, ...rightCustomButtons]

	return { leftButtons, rightButtons }
}
