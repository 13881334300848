import { useEffect, useRef, useState } from 'react'

export const useCellIsHoverOrFocus = (eGridCell: HTMLElement, interactive = false) => {
	const [hoverOrFocus, setHoverOrFocus] = useState(false)

	const hover = useRef(false)
	const focus = useRef(false)

	useEffect(() => {
		if (!interactive) {
			return
		}
		const createOnPointerEnterOrFocus = (action: 'pointerenter' | 'focus') => () => {
			if (action === 'pointerenter') {
				hover.current = true
			} else {
				focus.current = true
			}

			setHoverOrFocus(true)
		}

		const createOnPointerLeaveOrBlur = (action: 'pointerleave' | 'blur') => () => {
			if (action === 'pointerleave') {
				hover.current = false
			} else {
				focus.current = false
			}

			if (!hover.current && !focus.current) {
				setHoverOrFocus(false)
			}
		}

		const onPointerEnter = createOnPointerEnterOrFocus('pointerenter')
		const onPointerLeave = createOnPointerLeaveOrBlur('pointerleave')
		const onPointerFocus = createOnPointerEnterOrFocus('focus')
		const onPointerBlur = createOnPointerLeaveOrBlur('blur')

		eGridCell.addEventListener('pointerenter', onPointerEnter)
		eGridCell.addEventListener('pointerleave', onPointerLeave)

		eGridCell.addEventListener('focus', onPointerFocus)
		eGridCell.addEventListener('blur', onPointerBlur)
		return () => {
			eGridCell.removeEventListener('pointerenter', onPointerEnter)
			eGridCell.removeEventListener('pointerleave', onPointerLeave)

			eGridCell.removeEventListener('focus', onPointerFocus)
			eGridCell.removeEventListener('blur', onPointerBlur)
		}
	}, [eGridCell, interactive])

	if (!interactive) {
		return false
	}

	return hoverOrFocus
}
