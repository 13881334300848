export enum NavigationPaneItemType {
	button = 'button',
	header = 'header',
	separator = 'separator',
	group = 'group',
}

export type ItemData = string | number | boolean | {}

export type INavigationPaneButton = {
	type: NavigationPaneItemType.button
	id: string
	title: string
	className?: string
	badgeClassName?: string
	iconClassName?: string
	screenTip?: string
	badgeValue?: number
	data?: ItemData
	dataAttributes?: Record<string, string>
}

export type INavigationPaneHeader = {
	type: NavigationPaneItemType.header
	title: string
	iconClassName?: string
	dataAttributes?: Record<string, string>
}

export type INavigationPaneGroup = {
	type: NavigationPaneItemType.group
	id: string
	title: string
	className?: string
	badgeClassName?: string
	iconClassName?: string
	screenTip?: string
	badgeValue?: number
	data?: ItemData
	dataAttributes?: Record<string, string>
	children?: INavigationPaneItem[]
}

export type INavigationPaneSeparator = {
	type: NavigationPaneItemType.separator
}

export type INavigationPaneItem =
	| INavigationPaneButton
	| INavigationPaneHeader
	| INavigationPaneSeparator
	| INavigationPaneGroup
