import type { NodeSelection, RangeSelection } from 'lexical'
import { $isNodeSelection } from 'lexical'
import { $isAtNodeEnd } from '@lexical/selection'

export function getSelectedNode(selection: RangeSelection | NodeSelection) {
	if ($isNodeSelection(selection)) {
		return selection.extract()[0]
	}
	const anchor = selection.anchor
	const focus = selection.focus
	const anchorNode = selection.anchor.getNode()
	const focusNode = selection.focus.getNode()
	if (anchorNode === focusNode) {
		return anchorNode
	}
	const isBackward = selection.isBackward()
	if (isBackward) {
		return $isAtNodeEnd(focus) ? anchorNode : focusNode
	} else {
		return $isAtNodeEnd(anchor) ? focusNode : anchorNode
	}
}
