import React, { useEffect, useState } from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

const RADIUS_STANDARD = 8

const classes = createStyle((theme) => ({
	root: {
		position: 'relative',
		verticalAlign: 'middle',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		pointerEvents: 'none',
	},
	badge: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box',
		fontSize: '10px',
		minWidth: RADIUS_STANDARD * 2,
		lineHeight: 1,
		color: theme.colors.badge.text,
		backgroundColor: theme.colors.badge.background,
		padding: '0 6px',
		height: RADIUS_STANDARD * 2,
		borderRadius: RADIUS_STANDARD,
		border: '1px solid transparent',
	},
	badgeOutlined: {
		color: theme.colors.badge.background,
		backgroundColor: theme.colors.badge.text,
		borderColor: theme.colors.badge.background,
	},

	positioning: {
		position: 'absolute',
		top: 0,
		right: 0,
		zIndex: 99, // Render the badge on top of potential ripples.
	},
	wrap: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	fadeEnter: {
		opacity: 0,
		transform: 'scale(0.5,0.5)',
	},
	fadeEnterActive: {
		opacity: 1,
		transform: 'scale(1,1)',
		transition: 'all 200ms',
	},
	fadeExit: {
		opacity: 1,
		transform: 'scale(1,1)',
	},
	fadeExitActive: {
		opacity: 0,
		transform: 'scale(0.5,0.5)',
		transition: 'all 100ms',
	},
}))

interface IBadgeProps {
	children?: any
	value?: number
	maxValue?: number
	className?: string
	alignmentClassName?: string
	offsetRight?: number
	offsetTop?: number
	positionInline?: boolean
	appearance?: 'filled' | 'outline'
}

export const Badge = (props: IBadgeProps) => {
	const { maxValue = 99 } = props
	const [displayValue, setDisplayValue] = useState<string | number | undefined>()

	useEffect(() => {
		const value = props.value && props.value > maxValue ? `${maxValue}+` : props.value
		setDisplayValue(value)
	}, [props.value, maxValue])

	const renderBadgeValue = () => {
		const positionOverrideStyle =
			props.offsetRight || props.offsetTop ? { right: props.offsetRight, top: props.offsetTop } : undefined

		return (
			<SwitchTransition mode="out-in">
				<CSSTransition
					classNames={{
						enter: classes.fadeEnter,
						enterActive: classes.fadeEnterActive,
						exit: classes.fadeExit,
						exitActive: classes.fadeExitActive,
					}}
					addEndListener={(node, done) => {
						node.addEventListener('transitionend', done, false)
					}}
					key={displayValue}
				>
					<span
						className={classNames(
							classes.badge,
							props.className,
							!props.positionInline && classes.positioning,
							props.appearance === 'outline' && classes.badgeOutlined
						)}
						style={positionOverrideStyle}
						data-cy={'badge'}
					>
						{displayValue}
					</span>
				</CSSTransition>
			</SwitchTransition>
		)
	}

	if (props.positionInline) {
		return displayValue !== undefined ? renderBadgeValue() : null
	}

	if (displayValue !== undefined) {
		return (
			<div className={classNames(classes.wrap, props.className)} title={props.value?.toString()} data-cy={'wrapper'}>
				<span className={classNames(classes.root, props.alignmentClassName)} data-cy={'root-span'}>
					{props.children}
					{renderBadgeValue()}
				</span>
			</div>
		)
	}

	return props.children || null
}
