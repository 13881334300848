import { authenticationTypes } from './authenticationTypes'

import { IAuthenticationFailed, ISetSignedIn, ISignIn, ISignOut } from './IAuthenticationActions'

const signOut = (): ISignOut => ({
	type: authenticationTypes.LOG_OUT,
})

const signIn = (): ISignIn => ({
	type: authenticationTypes.SIGN_IN,
})

const setSignedIn = (signedIn: boolean): ISetSignedIn => ({
	type: authenticationTypes.SET_SIGNED_IN,
	signedIn,
})

const authenticationFailed = (): IAuthenticationFailed => ({
	type: authenticationTypes.AUTHENTICATION_FAILED,
})

export const authenticationActions = {
	signOut,
	signIn,
	setSignedIn,
	authenticationFailed,
}
