import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ReactDom from 'react-dom'
import { topWindow } from '../utils/topWindow'
import { FocusTrap } from '../utils/FocusTrap'
import { useForwardedRef } from '../utils/useForwardedRef'
import { createStyle } from '../../theming'
import classNames from 'clsx'
import {
	DATA_BLOCKING_MODAL_ELEMENT,
	DATA_PORTAL_ELEMENT,
	isBehindBlockingElement,
	isTopmostPortalElement,
} from '../utils/portalUtils'
import { useModalPositioning } from './hooks/useModalPositioning'
import { MOBILE_WIDTH } from '../Dialog/DialogTypes'

const floorCssValue = (value: string) => `round(down, ${value}, 1px)`

const classes = createStyle((theme) => ({
	modal: {
		position: 'fixed',
		zIndex: theme.zIndex.modal,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 1,
		transition: `opacity ${theme.transitions.duration.short}`,
		[`@media (max-width:${MOBILE_WIDTH}px)`]: {
			width: '100%!important',
			height: '100%!important',
			top: '0!important',
			left: '0!important',
			transform: 'none!important',
		},
		top: 0,
		left: 0,
		maxHeight: '100%',
		maxWidth: '100%',
	},

	modalDisablePositioning: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		pointerEvents: 'none',
	},

	modalContent: {
		height: '100%',
		outline: 'none',
	},

	beforeInitialized: {
		opacity: 0,
	},

	backdrop: {
		position: 'fixed',
		bottom: 0,
		right: 0,
		top: 0,
		left: 0,
		opacity: 1,
		transition: `opacity ${theme.transitions.duration.standard}`,

		background: theme.controls.dialog.backdropColor || theme.colors.modal.background,
		zIndex: theme.zIndex.modal,
	},

	backdropWithCustomRoot: {
		position: 'absolute',
	},

	invisibleBackdrop: {
		opacity: 0,
	},

	stayOnTop: {
		zIndex: `calc(${theme.zIndex.modal} + 1)`,
	},

	backdropStayOnTop: {
		pointerEvents: 'none',
	},

	modalPosition: {
		transform: `translate(var(--halfScreenWidth), var(--halfScreenHeight))
			 translate(var(--halfModalWidth), var(--halfModalHeight))`,

		[`@supports (width: ${floorCssValue('50vw')})`]: {
			'--halfScreenWidth': floorCssValue('50vw'),
			'--halfScreenHeight': floorCssValue('50vh'),
			'--halfModalWidth': floorCssValue('-50%'),
			'--halfModalHeight': floorCssValue('-50%'),
		},
		[`@supports not (width: ${floorCssValue('50vw')})`]: {
			'--halfScreenWidth': '50vw',
			'--halfScreenHeight': '50vh',
			'--halfModalWidth': '-50%',
			'--halfModalHeight': '-50%',
		},
	},
}))

interface IModalProps {
	id?: string
	fullScreen?: boolean
	isOpen: boolean
	ariaLabel?: string
	ariaLabeledBy?: string
	ariaDescribedBy?: string
	isAlertDialog?: boolean
	children: React.ReactNode
	hideBackdrop?: boolean
	stayOnTop?: boolean
	modalRootSelector?: string
	ref: React.Ref<HTMLDivElement>
	disablePositioning?: boolean
	backdropClassName?: string
	modalClassName?: string
	blocking?: boolean
	onBackdropClick?: (event: React.MouseEvent<HTMLElement>) => void
	onEscapePressed?: (event: React.KeyboardEvent<HTMLElement>) => void
}

const initializeAriaContentHidden = () => {
	const rootElement = topWindow.document.getElementById('app')

	if (!rootElement) {
		return
	}

	rootElement.setAttribute('aria-hidden', 'true')
}

const restoreContentAriaHidden = () => {
	const rootElement = topWindow.document.getElementById('app')

	if (!rootElement) {
		return
	}

	rootElement.removeAttribute('aria-hidden')
}

let openModalCount = 0

const setModalOpen = () => {
	openModalCount++

	if (openModalCount === 1) {
		initializeAriaContentHidden()
	}
}

const setModalClosed = () => {
	openModalCount--

	if (openModalCount === 0) {
		restoreContentAriaHidden()
	}
}

// @ts-ignore
const root = topWindow.parent?.__STORYBOOKAPI__
	? topWindow.document.getElementById('storybook-root') || topWindow.document.body
	: topWindow.document.body

export const Modal = React.forwardRef((props: IModalProps, forwardedRef: React.Ref<HTMLDivElement>) => {
	const ref = useForwardedRef<HTMLDivElement>(forwardedRef)

	const { onEscapePressed } = props

	const isModalOpen = useRef(false)
	const modal = useRef<HTMLDivElement>(null)

	const customRootElement = props.modalRootSelector
		? topWindow.document.querySelector(props.modalRootSelector)
		: undefined
	const modalRootElement = customRootElement || root

	useEffect(() => {
		isModalOpen.current = props.isOpen

		if (props.isOpen) {
			setModalOpen()
		} else {
			if (ref.current) {
				// avoid setting the counter on load
				setModalClosed()
			}
		}
	}, [props.isOpen, ref])

	useEffect(() => {
		return () => {
			// when unmounted, we may experience cases where unmount happens instead of toggling open prop
			if (isModalOpen.current) {
				setModalClosed()
			}
		}
	}, [])

	const onKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLElement>) => {
			if (!props.isOpen) {
				return
			}

			if (e.key === 'Escape' && onEscapePressed) {
				const isTopmost = ref.current && isTopmostPortalElement(ref.current)
				if (isTopmost) {
					e.stopPropagation()
					onEscapePressed(e)
				}
			}
		},
		[onEscapePressed, props.isOpen]
	)

	const onBackdropClick = (e: React.MouseEvent<HTMLElement>) => {
		if (props.blocking) {
			return
		}

		if (modal.current && isBehindBlockingElement(modal.current)) {
			return
		}

		props.onBackdropClick?.(e)
	}

	const dataPortalAttr = useMemo(
		() => ({ [DATA_PORTAL_ELEMENT]: true, [DATA_BLOCKING_MODAL_ELEMENT]: props.blocking ? true : undefined }),
		[props.blocking]
	)

	const isFadingIn = useIsFadingIn(props.isOpen, props.modalRootSelector ?? '')

	useModalPositioning(ref, props.isOpen, props.modalRootSelector ?? '')

	return props.isOpen
		? ReactDom.createPortal(
				<>
					<div
						ref={modal}
						className={classNames(
							classes.backdrop,
							customRootElement && classes.backdropWithCustomRoot,
							props.stayOnTop && classes.stayOnTop,
							props.stayOnTop && props.hideBackdrop && classes.backdropStayOnTop,
							(isFadingIn || props.hideBackdrop) && classes.invisibleBackdrop,

							props.backdropClassName
						)}
						onMouseDown={onBackdropClick}
						data-cy={'modal-backdrop-' + props.id}
					/>
					<div
						role={props.isAlertDialog ? 'alertdialog' : 'dialog'}
						aria-modal="true"
						aria-label={props.ariaLabel}
						aria-labelledby={props.ariaLabeledBy}
						aria-describedby={props.ariaDescribedBy}
						{...dataPortalAttr}
						className={classNames(
							classes.modalPosition,
							classes.modal,
							props.disablePositioning && classes.modalDisablePositioning,
							props.stayOnTop && classes.stayOnTop,
							isFadingIn && classes.beforeInitialized,
							props.modalClassName
						)}
						ref={ref}
						data-cy={'modal-' + props.id}
					>
						<FocusTrap disableInitialAutoFocus>
							<div tabIndex={-1} className={classes.modalContent} onKeyDown={onKeyDown}>
								{props.children}
							</div>
						</FocusTrap>
					</div>
				</>,
				modalRootElement
		  )
		: null
})

Modal.displayName = 'Modal'

const useIsFadingIn = (isOpen: boolean, modalRootSelector: string) => {
	const [playFadeIn, setPlayFadeIn] = useState(true)

	useLayoutEffect(() => {
		if (!isOpen) {
			return
		}

		setPlayFadeIn(false)
	}, [isOpen, modalRootSelector])

	return playFadeIn
}
