import React from 'react'
import { createStyle } from '../../../../../theming'
import classNames from 'clsx'
import { isLeftColumn } from '../../../utils/isLeftColumn'
import type { GridApi, Column } from '@ag-grid-community/core'
import type { TData } from '../../../Table.types'

interface IProps {
	columnId?: string
	multiSelectCheckMark?: JSX.Element
	content: JSX.Element
	filter?: JSX.Element
	api: GridApi<TData>
	disableSorting?: boolean
	includesFileData?: boolean
	filterIsAllowed?: boolean
	column?: Column
}

const classes = createStyle({
	columnHeaderWrapper: {
		display: 'flex',
		height: '100%',
		flexGrow: 1,
		alignItems: 'center',
		gap: 4,
		overflow: 'hidden',
		'&:hover': { '& > :last-child': { visibility: 'visible' } },
		margin: `0 var(--genus-header-cell-padding)`,
	},
	columnHeaderClickable: {
		cursor: 'pointer',
	},
	fileDragColumnPadding: { paddingLeft: '24px' },
})

export const HorizontalColumnHeaderWrapper = (props: IProps) => {
	const headerClassNames = classNames(
		classes.columnHeaderWrapper,
		!props.disableSorting && classes.columnHeaderClickable,
		props.columnId && isLeftColumn(props.api, props.columnId) && props.includesFileData && classes.fileDragColumnPadding
	)
	return (
		<div className={headerClassNames} data-pageelementid={props.columnId}>
			{props.multiSelectCheckMark}
			{props.content}
			{props.filterIsAllowed && props.column && !props.column.getColDef().suppressHeaderContextMenu && props.filter}
		</div>
	)
}
