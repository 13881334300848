import clsx from 'clsx'
import React, { useMemo } from 'react'
import { ColumnSortPanel } from './ColumnSortPanel'
import { FilterTextPanel } from './FilterTextPanel'
import type { ITableFieldDescription } from '../types/IDataPropertyDescription'
import type { ISortData } from '../types/ISortData'
import { createStyle } from '../../../theming'
import { GenericButton } from '../../../controls/GenericButton'
import type { IActionEntry } from '../types/IActionDescription'
import { e_actionType } from '../types/IActionDescription'
import { useTranslation } from '../../../translation'
import type { CommandBarItem } from '../../../controls/CommandBar/CommandBar.types'
import { CommandBar } from '../../../controls/CommandBar/CommandBar'
import { e_MenuItemType } from '../../../controls/Menu/MenuItem.types'

const classes = createStyle((theme) => ({
	headerControl: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},

	headerControlCompact: {},

	headerControlContent: {
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
		padding: '8px',

		borderBottom: `1px solid ${theme.colors.panel.border}`,
	},

	headerTitle: {
		fontSize: '20px',
		fontWeight: 600,
		minHeight: '28px', // prevents layout shift when the buttons are added or removed from header. 28px is the height of button with margin
	},

	headerControlCommandArea: {
		display: 'flex',
		alignItems: 'center',
		// padding: '8px',
		gap: '4px',
	},

	headerControlCommandAreaMain: {
		flex: 1,
		display: 'flex',
		height: '28px',
		alignItems: 'center',
		// padding: '0px 8px',
		justifyContent: 'flex-end',
	},

	headerControlCommandAreaCompact: {
		display: 'flex',
		alignItems: 'center',
		padding: '4px 0px 4px 4px',
		justifyContent: 'flex-end',
		borderBottom: `1px solid ${theme.colors.panel.border}`,
		background: theme.colors.toolbar.background,
	},

	headerButton: {
		font: 'inherit',

		width: '28px',
		height: '28px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '20px',
		padding: 'unset',
		outline: 'none',
		border: 'none',
		background: 'transparent',
		'&:hover': {
			background: 'rgba(0,0,0,0.1)',
		},
		'&:active': {
			background: 'rgba(0,0,0,0.2)',
		},
	},
}))

interface IHeaderControlProps {
	title: string
	count?: number
	back?: () => void
	children?: React.ReactNode | React.ReactNode[]

	filterText?: string
	setFilterText?: (filterTExt: string) => void

	columns?: ITableFieldDescription[]
	sortedColumns?: ISortData[]
	sortColumns?: (sortedColumns: ISortData[]) => void
	compactView?: boolean

	isMultiSelect?: boolean
	setIsMultiSelect?: (multiSelect: boolean) => void

	multiItemActions?: IActionEntry[]

	handleMultiItemAction?: (actionId: string, itemIds: string[]) => void
	generalActions?: IActionEntry[]
	handleGeneralAction?: (actionId: string) => void

	selectedIds?: string[]
}

const showCommandArea = true

export const HeaderControl = (props: IHeaderControlProps) => {
	const { tcvvi } = useTranslation()

	const commandBarItems = useMemo(() => {
		const commands: CommandBarItem[] = []

		props.generalActions?.forEach((actionEntry) => {
			switch (actionEntry.type) {
				case e_actionType.action: {
					commands.push({
						type: e_MenuItemType.action,
						id: actionEntry.id,
						iconClassName: actionEntry.iconClassName,
						iconColorClass: actionEntry.iconColorClass,
						onClick: () => props.handleGeneralAction && props.handleGeneralAction(actionEntry.id),
						name: !props.compactView ? actionEntry.displayName : undefined,
						screenTip: actionEntry.screenTip,
						disabled: actionEntry.disabled,
						hidden: actionEntry.hidden,
						isChecked: actionEntry.isChecked,
						dataAttributes: actionEntry.dataAttributes,
					})
					break
				}
				case e_actionType.separator: {
					commands.push({
						type: e_MenuItemType.divider,
					})
					break
				}
			}
		})

		if (props.selectedIds && props.multiItemActions) {
			props.multiItemActions?.forEach((actionEntry) => {
				switch (actionEntry.type) {
					case e_actionType.action: {
						commands.push({
							type: e_MenuItemType.action,
							id: actionEntry.id,
							iconClassName: actionEntry.iconClassName,
							iconColorClass: actionEntry.iconColorClass,
							onClick: () =>
								props.handleMultiItemAction && props.handleMultiItemAction(actionEntry.id, props.selectedIds!),
							name: !props.compactView ? actionEntry.displayName : undefined,
							screenTip: actionEntry.screenTip,
							disabled: actionEntry.disabled,
							hidden: actionEntry.hidden,
							isChecked: actionEntry.isChecked,
							dataAttributes: actionEntry.dataAttributes,
						})
						break
					}
					case e_actionType.separator: {
						commands.push({
							type: e_MenuItemType.divider,
						})
						break
					}
				}
			})
		}

		if (props.setIsMultiSelect && props.multiItemActions && props.multiItemActions.length > 0) {
			commands.push({
				type: e_MenuItemType.divider,
			})

			commands.push({
				type: e_MenuItemType.action,
				iconClassName: props.isMultiSelect ? 'Fluent-CheckboxComposite' : 'Fluent-Checkbox',
				onClick: () => props.setIsMultiSelect && props.setIsMultiSelect(!props.isMultiSelect),
				isChecked: props.isMultiSelect,
				screenTip: tcvvi('GENERAL:SELECT'),
			})
		}

		return commands
	}, [
		props.generalActions,
		props.handleGeneralAction,
		props.multiItemActions,
		props.handleMultiItemAction,
		props.setIsMultiSelect,
		props.selectedIds,
	])

	return (
		<div className={classes.headerControl}>
			<div className={classes.headerControlContent}>
				{props.back && (
					<GenericButton
						className={classes.headerButton}
						onClick={props.back}
						icon={'Basic-ArrowLeft'}
						dataAttributes={{ 'data-cy': 'master-detail-header-back-btn' }}
					/>
				)}
				<span className={classes.headerTitle}>
					{props.title}
					{props.children}
					{props.count !== undefined && ` (${props.count})`}
				</span>

				{!props.compactView && (
					// All content is on the same row
					<div className={clsx(classes.headerControlCommandArea, classes.headerControlCommandAreaMain)}>
						{props.setFilterText && (
							<FilterTextPanel filterText={props.filterText} setFilterText={props.setFilterText} applyMinWidth />
						)}

						{props.columns && props.sortColumns && (
							<ColumnSortPanel
								columns={props.columns}
								sortedColumns={props.sortedColumns}
								sortColumns={props.sortColumns}
							/>
						)}

						<CommandBar items={commandBarItems} rightAligned />
					</div>
				)}
			</div>

			{props.compactView && showCommandArea && (
				<div className={clsx(classes.headerControlCommandArea, classes.headerControlCommandAreaCompact)}>
					{props.setFilterText && <FilterTextPanel filterText={props.filterText} setFilterText={props.setFilterText} />}

					{props.columns && props.sortColumns && (
						<ColumnSortPanel
							columns={props.columns}
							sortedColumns={props.sortedColumns}
							sortColumns={props.sortColumns}
						/>
					)}

					<CommandBar items={commandBarItems} rightAligned />
				</div>
			)}
		</div>
	)
}
