import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { ICommonRootState } from 'src/interfaces/ICommonRootState'

const selectK8sRuntimes = (state: ICommonRootState) => state.kubernetes.k8sRuntimes
const selectK8sRuntimesIncludingOperator = (state: ICommonRootState) => state.kubernetes.k8sRuntimesIncludingOperator

const selectEndpoints = (state: ICommonRootState) => state.kubernetes.endpoints
const selectEnvironmentAvailabilitySchedule = (state: ICommonRootState) =>
	state.kubernetes.environmentAvailabilitySchedule

const selectCurrentEnvironmentAvailability = (environmentOperatingType: number) => (state: ICommonRootState) => {
	const currentEnvironmentAvailability =
		state.kubernetes.environmentAvailabilitySchedule.environmentAvailabilities.find((environmentAvailability) => {
			return environmentAvailability.environmentOperatingType === environmentOperatingType
		})
	return (
		currentEnvironmentAvailability || {
			isAlwaysUp: true,
			environmentOperatingType: -1,
			environmentOperatingTypeName: 'Unknown',
			availabilityWindows: [],
			availabilityGroups: [],
		}
	)
}

const selectCurrentEnvironmentResourceOverview =
	(environmentOperatingType: e_EnvironmentOperatingType) => (state: ICommonRootState) => {
		return state.kubernetes.environmentResourceOverview[environmentOperatingType]
	}
const selectAllEnironmentResourceOverviews = (state: ICommonRootState) => state.kubernetes.environmentResourceOverview

export const kubernetesSelectors = {
	selectK8sRuntimes,
	selectK8sRuntimesIncludingOperator,
	selectEndpoints,
	selectEnvironmentAvailabilitySchedule,
	selectCurrentEnvironmentAvailability,
	selectCurrentEnvironmentResourceOverview,
	selectAllEnironmentResourceOverviews,
}
