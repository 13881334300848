import React from 'react'
import type { TData } from '../Table.types'
import { refreshCheckMarkColumn } from '../utils/selectionUtils'
import { SelectionCheckMark } from './SelectionCheckMark'
import type { IRowNode, GridApi } from '@ag-grid-community/core'
import { getRowNodeId } from './CellRenderer/getRowNodeId'
import { getIsSelected, selectChildRows } from '../utils/gridCallbacks'

interface IProps {
	api: GridApi<TData>
	node: IRowNode<TData>
	disabled?: boolean
	type: 'square' | 'circle'
	newCheckmark?: boolean
}

export const CellSelectionCheckMark = (props: IProps) => {
	const nodeId = getRowNodeId(props.node)
	const isSelected = getIsSelected(props.node, props.node.allLeafChildren)

	const onClick = (e: { originalEvent: React.MouseEvent | React.KeyboardEvent }) => {
		e.originalEvent.stopPropagation()
		e.originalEvent.preventDefault()

		if (props.node.group) {
			selectChildRows(props.node)
		} else {
			props.node.setSelected(!isSelected)
		}
		refreshCheckMarkColumn(props.api)
	}

	return (
		<SelectionCheckMark
			key={`${nodeId}-multiSelectCheckMark`}
			node={props.node}
			value={isSelected ?? 'mixed'}
			disableSelection={props.disabled}
			onClick={onClick}
			type={props.type}
			newCheckmark={props.newCheckmark}
		/>
	)
}
