import type { ReactElement } from 'react'
import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import type { IColumnProps } from './Table'
import { DragSource } from '../utils/DragSource'
import type { ITableDropPosition } from '.'
import { Divider } from '../Divider'
import { Icon } from '../Icon'

export interface ITableRow {
	children?: ReactElement | ReactElement[]
	columns?: IColumnProps[]
	id: string
	forwardRef?: React.RefObject<HTMLTableRowElement>
	selectedRowIds?: string[]
	setSelection?: (rowIds: string[] | string) => void
	className?: string
	onActivate?: (rowId: string) => void
	dragSourceType?: string
	index?: number
	tableHoverPosition?: ITableDropPosition
	useDragHandle?: boolean
	showCellMenuItemsOnHover?: boolean
}

const allClasses = createStyle((theme) => ({
	row: {
		outline: 'none',
		position: 'relative',
		flex: 1,
	},
	isSelected: {
		backgroundColor: theme.colors.list.selectedBackground,
	},
	divider: {
		position: 'absolute',
		height: 2,
		marginTop: 0,
	},
	rowContent: {
		padding: '3px 0px 3px 0px',
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		borderBottomWidth: 1,
		borderBottomStyle: 'solid ',
		borderColor: theme.colors.list.border,
		outline: 'none',
		'&:hover': {
			backgroundColor: theme.colors.list.hoveredBackground,
		},
	},
	dragHandle: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		cursor: 'grab',
	},
	useDragHandle: { display: 'flex', width: '100%' },
	rowWrapper: {
		position: 'relative',
		'& .cell-menu-hover': {
			visibility: 'hidden',
		},
		'&:hover .cell-menu-hover': {
			visibility: 'visible',
		},
	},
	highlightable: {
		userSelect: 'text',
	},
}))

export const TableRow = (props: ITableRow) => {
	const classes = classNames(
		allClasses.rowContent,
		{
			[allClasses.isSelected]: props.selectedRowIds?.includes(props.id),
		},
		props.className
	)

	const showDropIndicatorAfter =
		props.tableHoverPosition && !props.tableHoverPosition.dropBefore && props.tableHoverPosition.elementId === props.id

	const showDropIndicatorBefore =
		props.tableHoverPosition?.dropBefore && props.tableHoverPosition.elementId === props.id

	const onClick = () => {
		props.setSelection?.([props.id])
	}

	const onDoubleClick = () => {
		props.onActivate?.(props.id)
	}

	const returnRow = (
		<div
			tabIndex={-1}
			className={classNames(allClasses.row, !props.dragSourceType && allClasses.highlightable)}
			onClick={onClick}
			onDoubleClick={onDoubleClick}
		>
			<div className={classes}>
				{React.Children.map(props.children, (tableCell, index) =>
					React.cloneElement(tableCell as React.ReactElement<any>, {
						index: index,
						columnWidth: props.columns![index].width,
						onActivate: props.onActivate,
					})
				)}
			</div>
		</div>
	)

	const dragSourceContent = props.useDragHandle ? (
		<div className={allClasses.rowWrapper} data-rowid={props.id}>
			{showDropIndicatorBefore && <Divider className={allClasses.divider} />}
			<div className={allClasses.useDragHandle}>
				<DragSource
					dragSourceType={props.dragSourceType}
					initiateDrag={() => {
						return { id: props.id }
					}}
				>
					<div className={allClasses.dragHandle}>
						<Icon iconName="Fluent-GripperDotsVertical" />
					</div>
				</DragSource>
				{returnRow}
			</div>
			{showDropIndicatorAfter && <Divider className={allClasses.divider} />}
		</div>
	) : (
		<div className={allClasses.rowWrapper} data-rowid={props.id}>
			<DragSource
				dragSourceType={props.dragSourceType}
				initiateDrag={() => {
					return { id: props.id }
				}}
			>
				{showDropIndicatorBefore && <Divider className={allClasses.divider} />}
				{returnRow}
				{showDropIndicatorAfter && <Divider className={allClasses.divider} />}
			</DragSource>
		</div>
	)

	return <>{props.dragSourceType ? dragSourceContent : <div className={allClasses.rowWrapper}>{returnRow}</div>}</>
}
