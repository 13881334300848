export const ROW_HEIGHT = 40
export const ROW_HEIGHT_COMPACT = 28
export const HEADER_HEIGHT = 40
export const HEADER_HEIGHT_COMPACT = 28
export const MAX_HEADER_HEIGHT = 250
export const SCROLL_BAR_SIZE = 19
export const VERTICAL_HEADER_BASE_HEIGHT = 18
export const VERTICAL_HEADER_PADDING = 16

export const INDENT = 10
export const CELL_PADDING = 4
export const CHECKBOX_GROUP_PADDING = 5

export const COLUMN_STATE_PREFIX = 'column_state'
export const FILTER_MODEL_PREFIX = 'filter_model'
export const EXPANDED_ROW_NODES_PREFIX = 'expanded_row_nodes'

export const SELECTION_SUPPRESS_FINISH_ACTIONS = 'selection_suppress_finish_actions'

export const LEGAL_KEYS_EDIT_MODE = [
	'Key', //Letters are prefixed by this
	'Digit', //Digits are prefixed by this
	'Slash', //-
	'Period', //.
	'Comma', //,
	'Numpad', //Everything at the num pad is prefixed by this, NumpadEnter and  must be excluded explicitly
	'Minus', //+ key on norwegian keyboard
	'Equal', //\ on norwegian keyboard
	'BracketRight', //¨ on norwegian keyboard
	'Backslash', //' on norwegian keyboard
	'Quote', //æ
	'Semicolon', //ø
	'BracketLeft', //å
	'Backquote', //|
	'F2',
	// 'Space', to edit width space, user also has to hold ctrl
	'Backspace',
]
