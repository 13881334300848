import { useLayoutEffect, useCallback } from 'react'
import { e_ChipOverflow } from '../../../enums/e_Overflow'
import type { MultiValueInputItem, BoundingRect, IChipOverflowRefs } from '../MultiValueInput.types'
import { getChipId } from '../utils/stringifyValue'
import { CHIP_DISPLAY_VALUE } from '../../Chip/utils/useChipClassNames'
import { INPUT_MIN_WIDTH } from '../MultiValueInput'
import { useDebounce } from '../../utils/useDebounce'

export const useChipSummaryOverflow = <T>(
	componentId: string,
	chips: MultiValueInputItem<T>[],
	refs: IChipOverflowRefs,
	chipOverflow: e_ChipOverflow,
	onHiddenChips?: (numberOfHiddenChips: number) => void
) => {
	const { wrapperRef, overflowChipRef, inputRef } = refs
	const calculateChipVisibility = useCallback(() => {
		const wrapperRect = wrapperRef.current?.getBoundingClientRect() as BoundingRect | undefined
		const overflowChipWidth = Math.max(overflowChipRef.current?.clientWidth ?? 0, 35)
		const inputWidth = Math.min(inputRef.current?.clientWidth ?? 0, INPUT_MIN_WIDTH) //Since the input field will stretch when there are fewer chips, the width must be limited to the minimum width

		if (chipOverflow === e_ChipOverflow.summary && wrapperRect !== undefined) {
			//The chip list is too big, and we should hide some chips
			let hiddenChips = 0
			chips.forEach((chip, index) => {
				const chipId = getChipId(componentId, chip.value)
				const chipElement = document.getElementById(chipId)
				if (!chipElement) {
					return
				}

				const hideChip = () => {
					hiddenChips++
					chipElement.style.display = 'none'
				}

				if (hiddenChips === 0) {
					//Setting the display to inline-flex to get the correct bounding rect of the chip.
					//Previously hidden chips which now has space will not have have left=0 and right=0 if display is not set to something other than 'none'
					chipElement.style.display = CHIP_DISPLAY_VALUE
				} else {
					//If a chip already has been hidden, we should hide all the following chips
					hideChip()
					return
				}

				//Checking wether the chip is completely visible
				const chipRect = chipElement?.getBoundingClientRect() as BoundingRect
				const chipCompletelyVisible =
					chipRect.left >= wrapperRect.left && chipRect.right <= wrapperRect.right - overflowChipWidth - inputWidth

				if (index === 0 && !chipCompletelyVisible) {
					//First chip should get a max width so that it does not push the input field out of view
					const maxWidth = wrapperRect.right - wrapperRect.left - overflowChipWidth - inputWidth
					chipElement.style.maxWidth = `${maxWidth}px`
				} else if (!chipCompletelyVisible) {
					hideChip()
				}
			})

			onHiddenChips?.(hiddenChips)
		}
		//The function should only update when the chip array, the overflow strategy or the wrapper rect size changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chipOverflow, chips])

	const debouncedCalculateChipVisibility = useDebounce(calculateChipVisibility, 250)

	useLayoutEffect(() => {
		debouncedCalculateChipVisibility()
	}, [debouncedCalculateChipVisibility, chips])

	return calculateChipVisibility
}
