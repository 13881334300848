import React from 'react'
import { createStyle } from '../../../theming'
import type { IDragDropCoordinates, IDragObject } from './DragDropTypes'

import { useDragLayer } from 'react-dnd'

const classes = createStyle({
	dragLayer: {
		position: 'fixed',
		pointerEvents: 'none',
		zIndex: 100,
		left: 0,
		top: 0,
		fontSize: '12px',
		fontWeight: 600,
		opacity: 0.8,
		padding: '8px',
		background: 'silver',
		borderRadius: '3px',
	},
})

//##react-dnd: Hva er forskjellen fra <DragLayer>??

const getTransformStyle = (clientOffset: IDragDropCoordinates) => {
	if (!clientOffset) {
		return {
			display: 'none',
		}
	}

	const transform = `translate(${clientOffset.x}px, ${clientOffset.y}px)`
	return {
		transform: transform,
		WebkitTransform: transform,
	}
}

const getPreview = (item: IDragObject) => {
	if (!item.dragPreview) {
		return null
	}

	if (typeof item.dragPreview === 'function') {
		return item.dragPreview(item)
	}

	return item.dragPreview
}

// Note! Cannot be implemented as a pure component when wrapped in DragLayer.
export const CustomDragLayer = () => {
	const { isDragging, clientOffset, item } = useDragLayer((monitor) => {
		return {
			isDragging: monitor.isDragging(),
			clientOffset: monitor.getClientOffset(),

			item: monitor.getItem(),
			itemType: monitor.getItemType(),
		}
	})

	if (!isDragging) {
		return null
	}

	if (!clientOffset) {
		return null
	}

	const draggedItem = item as IDragObject

	const preview = getPreview(draggedItem)

	return (
		<div className={classes.dragLayer} style={{ ...getTransformStyle(clientOffset) }}>
			{preview}
		</div>
	)
}
