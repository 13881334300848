import { topWindow } from './topWindow'

export const getCurrentAbsolutePosition = (element?: Element) => {
	if (!element) {
		return { y: 0, x: 0 }
	}

	const elementRect = element.getBoundingClientRect()
	const position = {
		y: elementRect.y,
		x: elementRect.x,
	}

	const elementWindow = element.ownerDocument?.defaultView as Window | null
	if (!elementWindow) {
		return position
	}

	let currentWindow = elementWindow

	while (currentWindow && currentWindow !== topWindow) {
		const frameRect = currentWindow.frameElement?.getBoundingClientRect()
		if (!frameRect) {
			break
		}

		position.y += frameRect.y
		position.x += frameRect.x
		currentWindow = currentWindow.parent
	}

	return position
}
