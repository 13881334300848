import { useMemo } from 'react'
import { TICKS_PER_MINUTE } from './consts'
import type { IUpdateInternalValue } from './useUpdateInternalValue'

export const useIncrementCurrentValue = (
	internalValue: React.MutableRefObject<number | undefined>,
	minValueMinutes: number,
	maxValueMinutes: number | undefined,
	optionResolutionMinutes: number,
	isIntegerTime: boolean | undefined,
	updateInternalValue: IUpdateInternalValue
) => {
	const incrementValueTicksMS = useMemo(() => optionResolutionMinutes * TICKS_PER_MINUTE, [optionResolutionMinutes])
	const minValueTicksMS = useMemo(() => minValueMinutes * TICKS_PER_MINUTE, [minValueMinutes])
	const maxValueTicksMs = useMemo(
		() => (isIntegerTime ? 85500000 : maxValueMinutes && maxValueMinutes * TICKS_PER_MINUTE),
		[isIntegerTime, maxValueMinutes]
	) //85500000 = 23:45
	const currentTicksMS = internalValue.current || minValueTicksMS

	return (decrement: boolean) => {
		let nextTicksMS = Math.floor(currentTicksMS / incrementValueTicksMS) * incrementValueTicksMS

		if (decrement) {
			if (nextTicksMS === currentTicksMS) {
				nextTicksMS = nextTicksMS - incrementValueTicksMS
			}
		} else {
			nextTicksMS = nextTicksMS + incrementValueTicksMS
		}

		// clamp between min and max
		if (isIntegerTime) {
			//Loop if integer time. Pressing up from 00:00 will become 23:45
			if (nextTicksMS < minValueTicksMS && maxValueTicksMs) {
				nextTicksMS = maxValueTicksMs
			} else if (nextTicksMS < minValueTicksMS) {
				nextTicksMS = minValueTicksMS
			} else if (maxValueTicksMs && nextTicksMS > maxValueTicksMs) {
				nextTicksMS = minValueTicksMS
			}
		} else {
			nextTicksMS = Math.max(nextTicksMS, minValueTicksMS)

			if (maxValueTicksMs) {
				nextTicksMS = Math.min(nextTicksMS, maxValueTicksMs)
			}
		}

		updateInternalValue(nextTicksMS, { updateDisplayValue: true, raiseValueChanged: true })
	}
}
