import { createKeyboardShortcutFromEvent } from './createKeyboardShortcutFromEvent'
import { isSpecialKeyboardShortcut } from './isSpecialKeyboardShortcut'
import { getParentListenerContext } from './keyboardListenerContexts'
import { findKeyboardShortcut } from './registerKeyboardShortcut'

const findShortcutKeyboardListenerElement = (element: HTMLElement | null): HTMLElement | null => {
	if (!element) {
		return null
	}

	if (element === document.body) {
		return element
	}

	const dataset = element.dataset

	if (dataset && dataset.keyboardlistener) {
		return element
	}

	return findShortcutKeyboardListenerElement(element.parentElement)
}

let currentListenerElement: HTMLElement | null

const keyboardShortcutEventListener = (e: KeyboardEvent) => {
	const shortcut = createKeyboardShortcutFromEvent(e.code, e.ctrlKey || e.metaKey, e.shiftKey, e.altKey)

	if (!shortcut) {
		return
	}

	if (isSpecialKeyboardShortcut(shortcut)) {
		return // since we do not allow it anyway, we don't have to look up if it is registered
	}

	let contextId: string | undefined =
		currentListenerElement && currentListenerElement.dataset && currentListenerElement.dataset.keyboardlistener
			? currentListenerElement.dataset.keyboardlistener
			: 'root'

	while (contextId) {
		const handler = findKeyboardShortcut(contextId, shortcut)

		if (handler) {
			// execute the callback in a timeout - seems to be important in some cases that is is done outside the key event handler
			setTimeout(() => handler.callback(), 0)
			e.preventDefault()
			e.stopPropagation()
			return
		}

		contextId = getParentListenerContext(contextId)?.contextId
	}
}

const attachListererToKeyboardShortcutContextElement = (element: HTMLElement | null) => {
	if (currentListenerElement === element) {
		return
	}

	if (currentListenerElement) {
		currentListenerElement.removeEventListener('keydown', keyboardShortcutEventListener)
	}

	currentListenerElement = element

	if (currentListenerElement) {
		currentListenerElement.addEventListener('keydown', keyboardShortcutEventListener)
	}
}

const reconnectKeyboardShortcutEventHandler = () => {
	// find active keyboard event handler based on current focus (that is, the one that is nearest)

	const listenerContextElement = findShortcutKeyboardListenerElement(document.activeElement as HTMLElement | null)

	attachListererToKeyboardShortcutContextElement(listenerContextElement)
}

document.addEventListener(
	'focusin',
	() => {
		reconnectKeyboardShortcutEventHandler()
	},
	true
)

document.addEventListener(
	'focusout',
	() => {
		reconnectKeyboardShortcutEventHandler()
	},
	true
)

reconnectKeyboardShortcutEventHandler()
