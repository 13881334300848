export const calculateOffset = (badgeValue: number | undefined, useCompactMode: boolean | undefined) => {
	if (!badgeValue || useCompactMode) {
		return -8
	} else if (badgeValue > 99) {
		return -28
	} else if (badgeValue > 9) {
		return -22
	} else {
		return -16
	}
}
