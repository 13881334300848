import type { ChangeEvent } from 'react'
import React, { useState, useRef } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import { MultiValueInputChipList } from './MultiValueInputChipList'
import { useForwardedRef } from '../utils/useForwardedRef'
import type { MultiValueInputItem } from './MultiValueInput.types'
import { e_ChipOverflow } from '../../enums/e_Overflow'
import { Chip } from '../Chip'

const classes = createStyle((theme) => ({
	multiValueInput: {
		flex: 1,
		overflowY: 'auto',
		cursor: 'text',
		display: 'flex',
		alignItems: 'center',
		gap: 4,
	},
	multiValueInputScrollChips: {
		overflowX: 'auto',
	},
	multiValueInputSummary: {
		overflowX: 'hidden',
	},
	multiValueInputWrap: {
		flexWrap: 'wrap',
	},
	multiValueInputDisabled: {
		cursor: 'default',
		background: theme.colors.input.disabledBackground,
	},
	multiValueInputReadOnly: {
		cursor: 'default',
	},
	inputWrapper: {
		display: 'flex',
		flex: 1,
		'&:focus': {
			outline: 'none',
		},
	},
	input: {
		fontWeight: 'inherit',
		flex: 1,
		textOverflow: 'ellipsis',
		'&::placeholder': {
			color: theme.colors.input.placeholderText,
		},
	},
}))

export interface IMultiValueInputProps<T> {
	id?: string
	dataAttributes?: Record<string, string | undefined>
	placeholder?: string
	inputValue: string
	tags: MultiValueInputItem<T>[]
	className?: string
	disabled?: boolean
	readOnly?: boolean
	defaultAction?: () => void
	onSelection: (itemIds: T[]) => void
	onMouseDown?: (e: React.MouseEvent) => void
	onClick?: (e: React.MouseEvent) => void
	onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void
	onInputFocus?: () => void
	onInputBlur?: () => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void
	onCtrlClick?: (e: React.MouseEvent, value: T) => void
	name?: string
	// Fake direct prop "ref" interface that should have been extended by React.forwardRef,
	// but because of lacking proper higher-order type inference generics are lost
	// so typecasting of wrapped component is necessary
	ref?: React.Ref<HTMLInputElement>
	dropdownListId?: string
	dropdownIsOpen?: boolean
	activeDropdownItem?: string
	chipOverflow?: e_ChipOverflow
	showInputField?: boolean
}

export const INPUT_MIN_WIDTH = 100
function MultiValueInputWithRef<T>(props: IMultiValueInputProps<T>, ref: React.Ref<HTMLInputElement>) {
	const { showInputField = true, chipOverflow = e_ChipOverflow.wrap } = props
	const inputRef = useForwardedRef<HTMLInputElement>(ref)
	const wrapperRef = useRef<HTMLInputElement>(null)
	const overflowChipRef = useRef<HTMLDivElement>(null)
	const [overflowChips, setOverflowChips] = useState(0)

	const [currentIndex, setCurrentIndex] = useState(-1)

	const focusInputField = () => {
		inputRef.current?.focus()
	}

	const onMouseDown = (event: React.MouseEvent) => {
		if (props.disabled || props.readOnly) {
			return
		}

		props.onMouseDown?.(event)
	}

	const onClick = (event: React.MouseEvent) => {
		if (props.disabled || props.readOnly) {
			return
		}

		setCurrentIndex(-1)

		props.onClick?.(event)

		scrollToBottom()
	}

	const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		switch (e.key) {
			case 'k':
				if (e.ctrlKey) {
					e.preventDefault()
					props.defaultAction?.()
				}
				break
			case 'Enter':
				e.preventDefault()
				props.defaultAction?.()
				break
			case 'Backspace':
				if (props.tags.length && inputRef.current?.selectionEnd === 0) {
					e.preventDefault()
					deleteItem(props.tags.length - 1)
				}
				break
			case 'ArrowLeft':
				if (props.tags.length && inputRef.current?.selectionEnd === 0) {
					e.preventDefault()
					selectNewItem(props.tags.length - 1)
				}
				break
			case 'Home':
				if (props.tags.length && inputRef.current?.selectionEnd === 0) {
					selectNewItem(0)
				}
				break
			default:
				props.onKeyDown?.(e)
		}
	}

	const selectNewItem = (index: number) => {
		setCurrentIndex(index)
		if (index === -1) {
			focusInputField()
		}
	}

	const deleteItem = (index: number) => {
		const selectedItemValues = props.tags.map((tag) => tag.value)
		if (index > -1 && index <= selectedItemValues.length) {
			setCurrentIndex(-1)
			focusInputField()
			selectedItemValues.splice(index, 1)
			props.onSelection(selectedItemValues)
		}
	}

	const scrollToBottom = () => {
		if (wrapperRef.current !== null) {
			wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight
		}
	}

	const multiValueInputClassNames = classNames(
		classes.multiValueInput,
		{
			[classes.multiValueInputDisabled]: props.disabled,
			[classes.multiValueInputReadOnly]: props.readOnly,
			[classes.multiValueInputScrollChips]: chipOverflow === e_ChipOverflow.showScrollbars,
			[classes.multiValueInputWrap]: chipOverflow === e_ChipOverflow.wrap,
			[classes.multiValueInputSummary]: chipOverflow === e_ChipOverflow.summary,
		},
		props.className
	)

	const showPlaceholder = props.tags.length === 0 ? props.placeholder : undefined

	return (
		<div className={multiValueInputClassNames} onMouseDown={onMouseDown} onClick={onClick} ref={wrapperRef}>
			<MultiValueInputChipList
				id={props.id}
				chips={props.tags}
				currentIndex={currentIndex}
				selectNewChip={selectNewItem}
				onDeleteChip={deleteItem}
				onBlur={() => setCurrentIndex(-1)}
				maxWidth={(wrapperRef.current && `${wrapperRef.current.offsetWidth - 32}px`) || undefined}
				disabled={props.disabled}
				readOnly={props.readOnly}
				onCtrlClick={props.onCtrlClick}
				chipOverflow={chipOverflow}
				refs={{ wrapperRef, overflowChipRef, inputRef }}
				onHiddenChips={setOverflowChips}
			/>
			{overflowChips > 0 && (
				<Chip
					ref={overflowChipRef}
					label={'+' + overflowChips}
					dataAttributes={{ ['data-cy']: props.id + '-overflow-chip' }}
				/>
			)}

			{showInputField && (
				<div className={classes.inputWrapper} tabIndex={-1} style={{ minWidth: INPUT_MIN_WIDTH }}>
					<input
						id={props.id}
						{...props.dataAttributes}
						placeholder={showPlaceholder}
						autoComplete="off" // autoComplete="off" does now work with Chrome
						spellCheck="false"
						role="combobox"
						aria-controls={props.dropdownIsOpen ? props.dropdownListId : undefined}
						aria-activedescendant={props.dropdownIsOpen ? props.activeDropdownItem : undefined}
						className={classes.input}
						value={props.inputValue}
						onChange={props.onInputChange}
						onKeyDown={onKeyDown}
						onFocus={props.onInputFocus}
						onBlur={props.onInputBlur}
						ref={inputRef}
						disabled={props.disabled}
						readOnly={props.readOnly}
						name={props.name}
					/>
				</div>
			)}
		</div>
	)
}

MultiValueInputWithRef.displayName = 'MultiValueInput'

export const MultiValueInput = React.forwardRef(MultiValueInputWithRef) as typeof MultiValueInputWithRef
