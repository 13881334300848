import type { IKeyboardShortcut } from './IKeyboardShortcut'
import type { IKeyboardShortcutHandler } from './IKeyboardShortcutHandler'
import { isEqualKeyboardShortcut } from './isEqualKeyboardShortcut'
import { isSpecialKeyboardShortcut } from './isSpecialKeyboardShortcut'
require('./keyboardShortcutHandler')

interface IkeyboardShortcutHandlersByContext {
	[contextId: string]: IKeyboardShortcutHandler[]
}

const keyboardShortcutHandlersByContext: IkeyboardShortcutHandlersByContext = {}

const getKeyboardShortcutHandlersByContext = (contextId: string) => {
	return keyboardShortcutHandlersByContext[contextId]
}

const getKeyboardShortcutHandlerByContext = (contextId: string, keyboardActionId: string) => {
	const contextHandlers = getKeyboardShortcutHandlersByContext(contextId)

	if (!contextHandlers) {
		return undefined
	}

	return contextHandlers.find((handler) => handler.keyboardActionId === keyboardActionId)
}

export const findKeyboardShortcut = (
	contextId: string,
	keyboardShortcut: IKeyboardShortcut
): IKeyboardShortcutHandler | undefined => {
	const contextHandlers = getKeyboardShortcutHandlersByContext(contextId)

	if (!contextHandlers) {
		return undefined
	}

	return contextHandlers.find((handler) => isEqualKeyboardShortcut(handler.keyboardShortcut, keyboardShortcut))
}

const addKeyboardShortcutHandlerByContext = (contextId: string, keyboardShortcutHandler: IKeyboardShortcutHandler) => {
	if (!keyboardShortcutHandlersByContext[contextId]) {
		keyboardShortcutHandlersByContext[contextId] = []
	}

	keyboardShortcutHandlersByContext[contextId].push(keyboardShortcutHandler)
}

const removeKeyboardShortcutHandlerByContext = (contextId: string, keyboardActionId: string) => {
	if (!keyboardShortcutHandlersByContext[contextId]) {
		return
	}

	const index = keyboardShortcutHandlersByContext[contextId].findIndex(
		(handler) => handler.keyboardActionId === keyboardActionId
	)

	if (index) {
		keyboardShortcutHandlersByContext[contextId].splice(index, 1)
	}
}

export const registerKeyboardShortcut = (
	keyboardShortcut: IKeyboardShortcut,
	contextId: string,
	keyboardActionId: string,
	callback: () => void,
	preventDuplicates = false
): string | undefined => {
	// Check if shortcut is reserved by browers
	if (isSpecialKeyboardShortcut(keyboardShortcut)) {
		throw new Error(
			`Not allowed to register ${keyboardShortcut.ctrlOrMetaKey ? 'CTRL+' : ''}${
				keyboardShortcut.shiftKey ? 'SHIFT+' : ''
			}${keyboardShortcut.altKey ? 'ALT+' : ''}${keyboardShortcut.keyCode} as a shortcut key.`
		)
	}

	const existingShortcutHandler = getKeyboardShortcutHandlerByContext(contextId, keyboardActionId)

	if (
		existingShortcutHandler &&
		existingShortcutHandler.keyboardActionId === keyboardActionId &&
		existingShortcutHandler.callback !== callback
	) {
		// update existing handler with new callback function
		existingShortcutHandler.callback = callback
		return existingShortcutHandler.keyboardActionId
	}

	if (preventDuplicates) {
		// in designers, we can be strict and prevent duplicates to be registered twice
		const registeredHandler = findKeyboardShortcut(contextId, keyboardShortcut)
		if (registeredHandler) {
			throw new Error(
				`Not allowed to register ${keyboardShortcut.ctrlOrMetaKey ? 'CTRL+' : ''}${
					keyboardShortcut.shiftKey ? 'SHIFT+' : ''
				}${keyboardShortcut.altKey ? 'ALT+' : ''}${
					keyboardShortcut.keyCode
				} for action ${keyboardActionId} as it is already in use for action ${registeredHandler.keyboardActionId}.`
			)
		}
	}

	const shortcutHandler: IKeyboardShortcutHandler = {
		keyboardShortcut: keyboardShortcut,
		keyboardActionId: keyboardActionId,
		callback: callback,
	}

	addKeyboardShortcutHandlerByContext(contextId, shortcutHandler)

	return keyboardActionId
}

export const removeRegisteredKeyboardShortcut = (contextId: string, keyboardActionId: string) => {
	removeKeyboardShortcutHandlerByContext(contextId, keyboardActionId)
}
