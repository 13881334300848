import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { OperatorAuthentication } from 'src/components/Authentication/OperatorAuthentication'
import { useSelector } from 'react-redux'
import { authenticationSelectors } from '../Authentication/duck'
import { OperatorFrontend } from './OperatorFrontend'
import { operatorFrontendSelectors } from './duck/operatorFrontendSelectors'

const getFormattedNavigationItem = (str: string) => {
	if (!str) {
		return ''
	}
	str = str.replace('/', ' | ')
	return `${str[0].toUpperCase()}${str.slice(1)}`
}

const OperatorFrontendRouter = (): React.JSX.Element => {
	const item = useSelector(operatorFrontendSelectors.selectNavigationItem)

	const isAuthenticated = useSelector(authenticationSelectors.selectSignedIn)

	const modelName = window.env.MODEL_IDENTIFIER || ''

	useEffect(() => {
		document.title = `${getFormattedNavigationItem(item)} | ${modelName} | Genus Operator`
	})

	return (
		<>
			{!isAuthenticated && <OperatorAuthentication />}
			{isAuthenticated && (
				<>
					<BrowserRouter>
						<OperatorFrontend />
					</BrowserRouter>
				</>
			)}
		</>
	)
}

export { OperatorFrontendRouter }
