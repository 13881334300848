export enum e_Placement {
	bottomEnd = 'bottom-end',
	bottomStart = 'bottom-start',
	bottom = 'bottom',
	leftEnd = 'left-end',
	leftStart = 'left-start',
	left = 'left',
	rightEnd = 'right-end',
	rightStart = 'right-start',
	right = 'right',
	topEnd = 'top-end',
	topStart = 'top-start',
	top = 'top',
}
