import React, { useEffect, useRef, useState } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../../../../theming'
import { useTranslation } from '../../../../../translation'
import type { Column } from '@ag-grid-community/core'
import { GenericButton } from '../../../../GenericButton'

const classes = createStyle({
	menuButtonWrapper: {
		display: 'flex',
		alignSelf: 'stretch',
		visibility: 'hidden',
		position: 'absolute',
		height: '100%',
	},
	menuButtonWrapperActiveFilter: { visibility: 'visible' },
	menuButton: {
		margin: '5px',
	},
	menuButtonVisible: {
		'& > :last-child': { visibility: 'visible' },
	},
})
interface IColumnHeaderFilterProps {
	column: Column
	alwaysShowMenu?: boolean
	textAlignment?: 'right' | 'left' | 'center'
	onColumnMenuClick: () => void
}

export const ColumnHeaderFilter = React.forwardRef(
	(props: IColumnHeaderFilterProps, ref: React.Ref<HTMLDivElement>) => {
		const { tcvi } = useTranslation()

		const mounted = useRef(true)

		useEffect(() => {
			return () => {
				mounted.current = false
			}
		}, [])

		const [filterActive, setFilterActive] = useState(props.column.isFilterActive())

		useEffect(() => {
			const onFilterChange = () => {
				const filterIsActive = props.column.isFilterActive()
				if (filterIsActive) {
					mounted.current && setFilterActive(true)
				} else {
					mounted.current && setFilterActive(false)
				}
			}

			props.column.addEventListener('filterChanged', onFilterChange)

			return () => {
				props.column.removeEventListener('filterChanged', onFilterChange)
			}
		}, [props.column])

		return (
			<div
				className={classNames(
					classes.menuButtonWrapper,
					filterActive && classes.menuButtonWrapperActiveFilter,
					props.alwaysShowMenu && classes.menuButtonVisible
				)}
				style={props.textAlignment === 'right' ? { left: 0 } : { right: 0 }}
				ref={ref}
			>
				<GenericButton
					icon={filterActive ? 'Fluent-Filter' : 'Fluent-More'}
					screenTip={tcvi('GENERAL:MORE')}
					onClick={props.onColumnMenuClick}
					dataAttributes={{ 'data-cy': 'table-header-filter-btn' }}
					className={classes.menuButton}
				/>
			</div>
		)
	}
)

ColumnHeaderFilter.displayName = 'ColumnHeaderFilter'
