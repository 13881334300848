import React from 'react'

// Web UI
import { Button } from '@genusbiz/web-ui/controls'

// Enums, Constants and Interfaces
import { MOBILE_WIDTH } from 'src/consts/cssBreakpoints'
import { e_ButtonVariant } from 'src/enums/e_ButtonVariant'
import { RuntimeUserSettingsPanel } from './RuntimeUserSettingsPanel'
import { useTranslation } from 'react-i18next'
import { createStyle } from '@genusbiz/web-ui/theming'

interface IRuntimeRightPaneProps {
	showUserSettings: boolean
	closeRightBar: () => void
	navigateToProfileApp?: (appId: string, openInNewTab: boolean) => void
	signOut: () => void
	appIndex?: { id: string }[]
	activeAppId?: string
}

const classes = createStyle((theme) => ({
	rightSideBarContents: {
		display: 'flex',
		flex: 1,
		overflowY: 'auto',
		flexDirection: 'column',
		padding: 16,
		paddingTop: 0,
		[`@media (max-width:${MOBILE_WIDTH}px)`]: { width: '100%' },
	},
	rightSideBar: {
		background: theme.colors.panel.background,
		minWidth: 300,
		zIndex: theme.zIndex.drawer + 1,
		borderLeft: '1px solid ' + theme.colors.panel.border,
		display: 'flex',
		flexDirection: 'column',
		[`@media (max-width:${MOBILE_WIDTH}px)`]: { borderWidth: 0, width: '100%' },
	},

	rightSideBarHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: 16,
	},

	rightSideBarTitle: {
		flex: 1,
	},
}))

export const RuntimeRightPane = (props: IRuntimeRightPaneProps) => {
	const { t } = useTranslation()

	return (
		<div className={classes.rightSideBar}>
			<div className={classes.rightSideBarHeader}>
				<div className={classes.rightSideBarTitle}>
					<h4>{t('APP_BAR:USER_SETTINGS_TITLE')}</h4>
				</div>
				<div>
					<Button icon="Fluent-ChromeClose" variant={e_ButtonVariant.inline} onClick={props.closeRightBar} />
				</div>
			</div>
			{props.showUserSettings && (
				<div className={classes.rightSideBarContents}>
					<RuntimeUserSettingsPanel
						close={props.closeRightBar}
						navigateToProfileApp={props.navigateToProfileApp}
						signOut={props.signOut}
						activeAppId={props.activeAppId}
						appIndex={props.appIndex}
					/>
				</div>
			)}
		</div>
	)
}
