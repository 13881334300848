export const defaultFont = 'Calibri, Helvetica, sans-serif'

export const defaultFonts = {
	Arial: 'Arial, Helvetica, sans-serif',
	'Arial Black': '"Arial Black", Arial, sans-serif',
	Calibri: defaultFont,
	'Calibri Light': '"Calibri Light", "Helvetica Light", sans-serif',
	'Courier New': '"Courier New", monospace',
	Georgia: 'Georgia, serif',
	Helvetica: 'Helvetica, sans-serif',
	Lucida: '"Lucida Console", Monaco, monospace',
	'Segoe UI': '"Segoe UI", "Helvetica Neue", sans-serif',
	Tahoma: 'Tahoma, Geneva, sans-serif',
	'Times New Roman': 'Times, "Times New Roman", serif',
	Trebuchet: '"Trebuchet MS", Trebuchet, sans-serif',
	Verdana: 'Verdana, Geneva, sans-serif',
}

export const defaultFontMappings = Object.entries(defaultFonts).reduce(
	(reversedMappings, [key, value]) => {
		reversedMappings[value] = key
		return reversedMappings
	},
	{} as Record<string, string>
)

// const microsoftWebFontMappings = {
// 	'Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif': defaultFont,
// } as Record<string, string>

export const defaultFontSize = 11
export const defaultFontSizes = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 24, 26, 28, 36, 48, 72]
