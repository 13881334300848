import { put, all, takeLatest } from 'redux-saga/effects'
import { operatorApi } from 'src/modules/operatorApi'
import { sessionStorageService } from 'src/modules/sessionStorageService/sessionStorageService'
import { authenticationActions } from './authenticationActions'
import { authenticationTypes } from './authenticationTypes'
import { e_authMetod } from 'src/enums/e_authMethod'
import { getAuthMethod } from '../utils/getAuthMethod'

const authMethod = getAuthMethod()

function* onAuthenticationFailed() {
	yield put(authenticationActions.setSignedIn(false))
}

function* onLogOut() {
	if (authMethod === e_authMetod.cookie) {
		yield operatorApi.logOut()
		sessionStorageService.clear()
		location.reload()
	} else if (authMethod === e_authMetod.entraID) {
		yield put(authenticationActions.setSignedIn(false))
		window.location.href = window.location.origin + '/api/authentication/entraid/signout'
	}
}

function* onSignIn() {
	yield put(authenticationActions.setSignedIn(true))
}

function* onSignedIn() {}

function* watcherSagas() {
	yield all([
		takeLatest(authenticationTypes.AUTHENTICATION_FAILED, onAuthenticationFailed),
		takeLatest(authenticationTypes.LOG_OUT, onLogOut),
		takeLatest(authenticationTypes.SIGN_IN, onSignIn),
		takeLatest(authenticationTypes.SET_SIGNED_IN, onSignedIn),
	])
}

export const authenticationSagas = {
	watcherSagas,
}
