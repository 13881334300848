import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import classNames from 'clsx'
import numeral from 'numeral'

import type { IFormControl } from '../FormControl'
import { FormControl, FormControlButton } from '../FormControl'
import { createStyle } from '../../theming'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useId } from '../hooks/useId'
import { useStyleContext } from '../utils/Style.context'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'
import { type e_TextAlignment } from '../../enums/e_TextAlignment'
import { clampValue, getParseNumber, isPercentFormat } from '../utils/numberUtils'
import { DropdownList, DropdownOptionsHandler } from '../Dropdown'
import type { IDropdownOption } from '../Dropdown'
import { useTranslation } from '../../translation'

const classes = createStyle({
	alignRight: { textAlign: 'right' },
	alignCenter: { textAlign: 'center' },
	input: { fontWeight: 'inherit' },
	suffix: {
		display: 'flex',
		paddingRight: '6px',
		paddingLeft: '6px',
		alignItems: 'center',
		opacity: 0.5,
	},
})

interface INumberInputProps extends IFormControl {
	value?: number | null
	incrementInterval?: number
	placeholder?: string
	formatString?: string
	valueFormatter?: (value: any) => any
	isPercentNumber?: boolean
	formatting?: any
	type?: 'int' | 'float'
	textAlignment?: e_TextAlignment
	min?: number
	max?: number
	onChange?: (value: number | null) => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>, parsedNumber?: number | null) => void
	onBlur?: (value: number | null) => void
	actionButtonRef?: React.Ref<HTMLButtonElement>
	suffix?: string
	initialInputValue?: string
	valueOptions?: number[]
}

enum Modes {
	display,
	edit,
}

export const NumberInput = React.forwardRef((props: INumberInputProps, forwardedRef: React.Ref<HTMLInputElement>) => {
	const { actionIcon = 'Fluent-Forward' } = props
	const id = useId('number-input', props.id)
	const inputRef = useForwardedRef<HTMLInputElement>(forwardedRef)
	const anchorElementRef = useRef<HTMLDivElement>(null)
	const inputNumber = useRef<number | null | undefined>(props.value)

	const [editValue, setEditValue] = useState<string>()
	const [displayValue, setDisplayValue] = useState<string>()
	const userIsWriting = useRef(false)

	const [isOpen, setIsOpen] = useState(false)

	const [invalidEditValue, setInvalidEditValue] = useState(false)

	const [mode, setMode] = useState(Modes.display)

	const { tcvi } = useTranslation()

	const formatEditValue = useCallback(
		(value?: number | null) => {
			if (value === null || value === undefined) {
				return
			}

			if (isPercentFormat(props.formatString, props.isPercentNumber)) {
				return numeral(value).multiply(100).value().toString().replace('.', numeral.localeData().delimiters.decimal)
			}

			return value.toString().replace('.', numeral.localeData().delimiters.decimal)
		},
		[props.formatString]
	)

	const formatDisplayValue = useCallback(
		(value?: number | null) => {
			if (value === null || value === undefined) {
				return
			}

			if (props.valueFormatter) {
				return props.valueFormatter(value)
			}

			return formatEditValue(value)
		},
		[props.valueFormatter, props.formatting, formatEditValue]
	)

	useEffect(() => {
		// We should not update the edit value while the input is focused

		if (userIsWriting.current === true) {
			return
		}

		inputNumber.current = props.value
		setEditValue(formatEditValue(props.value))
		setDisplayValue(formatDisplayValue(props.value))
	}, [props.value, formatDisplayValue])

	const parseNumber = getParseNumber(props.type, props.formatString, props.isPercentNumber, props.min, props.max)

	const changeValue = (value: string) => {
		const parsedValue = parseNumber(value)

		setEditValue(value)
		setInvalidEditValue(parsedValue === undefined)

		if (parsedValue !== undefined) {
			if (props.value === parsedValue) {
				return
			}

			inputNumber.current = parsedValue

			props.onChange?.(parsedValue)
		}
	}

	useEffect(() => {
		if (props.initialInputValue) {
			changeValue(props.initialInputValue)
		}
	}, [])

	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		changeValue(event.target.value)
	}

	const handleClick = () => {
		userIsWriting.current = true
	}

	const options = useMemo(() => {
		if (props.readOnly) {
			return undefined
		}

		if (props.disabled) {
			return undefined
		}

		if (!props.valueOptions || props.valueOptions.length === 0) {
			return undefined
		}

		const dropdownOptions: IDropdownOption<number>[] = props.valueOptions.map((value) => {
			return { label: value.toString(), value: value }
		})

		return new DropdownOptionsHandler(dropdownOptions, false, tcvi, undefined)
	}, [props.disabled, props.readOnly, props.valueOptions, tcvi])

	const selectInputValueOnRender = useRef(false)
	const onFocus = () => {
		setMode(Modes.edit)
		setEditValue(formatEditValue(props.value))
		setInvalidEditValue(props.value === undefined)
		selectInputValueOnRender.current = true
	}

	useEffect(() => {
		if (selectInputValueOnRender.current) {
			inputRef.current?.select()
			selectInputValueOnRender.current = false
		}
	})

	const onBlur = () => {
		userIsWriting.current = false
		setMode(Modes.display)
		setDisplayValue(formatDisplayValue(props.value))
		setEditValue(formatEditValue(props.value))
		setInvalidEditValue(props.value === undefined)

		if (inputNumber.current === undefined) {
			return
		}

		props.onBlur?.(inputNumber.current)
	}

	const adjustValue = (value: string, increment: number) => {
		inputRef.current?.select()

		const parsedValue = parseNumber(value)

		let incrementedValue = clampValue((parsedValue || 0) + increment, props.min, props.max)

		if (props.type === 'float') {
			incrementedValue = parseFloat(incrementedValue.toPrecision(12))
		}

		inputNumber.current = incrementedValue

		setEditValue(formatEditValue(incrementedValue))
		setInvalidEditValue(incrementedValue === undefined)

		props.onChange?.(incrementedValue)
	}

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (props.readOnly || props.disabled) {
			return
		}

		switch (e.key) {
			case 'Enter':
				props.defaultAction?.()
				break
			case 'ArrowUp':
				e.preventDefault()
				props.incrementInterval && adjustValue((e.target as HTMLInputElement).value, props.incrementInterval)
				break
			case 'ArrowDown':
				e.preventDefault()
				props.incrementInterval && adjustValue((e.target as HTMLInputElement).value, -props.incrementInterval)
		}

		props.onKeyDown?.(e, inputNumber.current)
	}

	const inputValue = mode === Modes.display ? displayValue : editValue

	const { placeholder, readOnlyIndicatorStyle } = { ...useStyleContext().style?.input }

	const numberInputMode = props.type === 'int' ? 'numeric' : 'decimal'
	const inputMode = props.min !== undefined && props.min >= 0 ? numberInputMode : 'text'

	const contentCSS = classNames(
		{
			[classes.alignRight]: props.textAlignment === 'right',
			[classes.alignCenter]: props.textAlignment === 'center',
		},
		classes.input
	)

	const openDropdown = () => {
		setIsOpen(true)
	}

	const closeDropdown = useCallback(() => {
		setIsOpen(false)
	}, [setIsOpen])

	const onOptionSelect = (value: number | null) => {
		props.onChange?.(value)
		closeDropdown()
	}

	return (
		<>
			<FormControl
				id={id}
				label={props.label}
				labelPosition={props.labelPosition}
				labelIcon={props.labelIcon}
				labelIconColor={props.labelIconColor}
				labelBold={props.labelBold}
				labelContentLayout={props.labelContentLayout}
				hideLabel={props.hideLabel}
				labelSubText={props.labelSubText}
				validationText={props.validationText}
				validationTextPosition={props.validationTextPosition}
				subText={props.subText}
				reserveHelperTextSpace={props.reserveHelperTextSpace}
				disabled={props.disabled}
				readOnly={props.readOnly}
				required={props.required}
				error={props.error || invalidEditValue}
				warning={props.warning}
				margin={props.margin}
				labelProps={props.labelProps}
				className={props.className}
				disableBorder={props.disableBorder}
				screenTip={props.screenTip}
				onClick={props.onClick}
				onMouseDown={props.onMouseDown}
				borderClassName={props.borderClassName}
				ref={anchorElementRef}
			>
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
				)}
				<input
					ref={inputRef}
					className={contentCSS}
					id={id}
					{...props.dataAttributes}
					placeholder={props.placeholder ?? placeholder}
					value={inputValue !== undefined ? inputValue : ''}
					onKeyDown={onKeyDown}
					type="text"
					autoComplete="off" // autoComplete="off" does now work with Chrome
					spellCheck="false"
					onChange={onInputChange}
					onFocus={onFocus}
					onBlur={onBlur}
					onClick={handleClick}
					disabled={props.disabled}
					readOnly={props.readOnly}
					name={props.name}
					inputMode={inputMode}
					min={props.min}
					max={props.max}
				/>
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
				)}
				{props.suffix && <div className={classes.suffix}>{props.suffix}</div>}
				{props.defaultAction && !props.hideActionButton && (
					<FormControlButton
						iconClassName={actionIcon}
						screenTip={props.actionScreenTip}
						onClick={!props.disabled ? props.defaultAction : undefined}
						disabled={props.disabled}
						ref={props.actionButtonRef}
						dataAttributes={props.dataAttributes}
					/>
				)}

				{options && (
					<FormControlButton
						disabled={props.disabled || props.readOnly}
						iconClassName="Fluent-ChevronDown"
						onMouseDown={(e) => isOpen && e.preventDefault()}
						onClick={isOpen ? closeDropdown : openDropdown}
						tabStop={false}
						iconSize="extraSmall" // to avoid massive chevron
						// className={classNames(classes.buttonWrapper, { [classes.hidden]: !renderDropdownButton })}
						dataAttributes={props.dataAttributes}
					/>
				)}
			</FormControl>

			{options && (
				<DropdownList<number>
					anchorElement={anchorElementRef}
					// maxHeight={100}
					isOpen={isOpen}
					options={options}
					value={props.value}
					onChange={onOptionSelect}
					onClose={closeDropdown}
					disableSelectWithSpace
				/>
			)}
		</>
	)
})

NumberInput.displayName = 'NumberInput'
