import React from 'react'
import '@genusbiz/web-ui/theming/style.css'
import '@genusbiz/icon-set/dist/fonts/'

import { SignInWindowContent } from './SignInWindowContent'
import { IIdentityProvider } from 'src/interfaces/IIdentityProvider'

interface ISignInWindowProps {
	identityProviders: IIdentityProvider[]
	loginHeader: string
	loginSubHeader?: string
	border?: boolean
	onValidSignIn: () => void
	onInvalidSignIn: (loginResponse: string) => void
	loginResponse?: string
}

export const SignInWindow = (props: ISignInWindowProps) => <SignInWindowContent {...props} />
