import React from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { TreeView } from '@lexical/react/LexicalTreeView'
import { createStyle } from '../../../theming'

const classes = createStyle((theme) => ({
	button: {
		justifyContent: 'center',
		alignItems: 'center',
		outline: 0,
		position: 'relative',
		cursor: 'pointer',
		overflow: 'hidden',
		fontWeight: theme.controls.button.fontWeight,
		fontSize: theme.controls.button.fontSize,
		padding: '4px 16px',
		margin: 4,
		border: 0,
		borderRadius: theme.controls.button.borderRadius,
		borderStyle: theme.controls.button.borderStyle,
		borderWidth: theme.controls.button.borderWidth,
		boxShadow: theme.controls.button.boxShadow,
		backgroundColor: theme.controls.button.colors.background,
		borderColor: theme.controls.button.colors.border,
		outlineOffset: theme.controls.button.outlineOffset,

		'&:focus-visible': {
			outlineColor: theme.controls.button.focusedColors.border,
			outlineStyle: theme.controls.button.focusBorderStyle,
			outlineWidth: theme.controls.button.outlineWidth,
			background: theme.controls.button.focusedColors.background,
			color: theme.controls.button.focusedColors.color,
		},

		'&:disabled, &:disabled:hover': {
			cursor: 'default',
			backgroundColor: theme.controls.button.disabledColors.background,
			color: theme.controls.button.disabledColors.color,
			borderColor: theme.controls.button.disabledColors.border,
		},
	},
	view: {
		flex: 1,
		overflow: 'auto',
	},
}))

export default function TreeViewPlugin() {
	const [editor] = useLexicalComposerContext()
	return (
		<TreeView
			viewClassName={classes.view}
			timeTravelPanelClassName="debug-timetravel-panel"
			timeTravelButtonClassName={classes.button}
			timeTravelPanelSliderClassName="debug-timetravel-panel-slider"
			timeTravelPanelButtonClassName={classes.button}
			treeTypeButtonClassName={classes.button}
			editor={editor}
		/>
	)
}
