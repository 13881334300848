import React, { useEffect, useRef, useState } from 'react'
import type { ICellRenderer, Option, Value } from '../../../Table.types'
import { useDebounce } from '../../../../utils/useDebounce'
import type { IFilterEvent } from '../../../../LookupInput'
import { LookupInput } from '../../../../LookupInput'

interface ICellRendererLookup extends ICellRenderer {
	value: string | undefined
	displayLabel: string | undefined
	options: Option[]
	isLoadingOptions: boolean
	onNeedOptions: (event: IFilterEvent) => void
	initialFilterTerm: string | undefined
	onClose: () => void
}

export const CellRendererLookup = (props: ICellRendererLookup) => {
	const ref = useRef<HTMLInputElement>(null)

	const [displayValue, setDisplayValue] = useState(getDisplayValue(props.value, props.displayLabel, props.options))

	useEffect(() => {
		if (props.initialFilterTerm) {
			props.onNeedOptions({
				filterTerm: props.initialFilterTerm,
				minLength: 0,
				filterOptions: { triggeredByBlur: false, triggeredByButton: false },
			})
		}
	}, [])

	const debounceSearch = useDebounce(props.onNeedOptions, 250)
	const onChange = (value: Value) => {
		setDisplayValue(props.options.find((opt) => opt.value === value)?.label)
		props.setValue?.(value)
	}

	useEffect(() => {
		ref.current?.focus()
		ref.current?.click()
	}, [])

	return (
		<LookupInput
			ref={ref}
			value={props.value}
			displayValue={displayValue}
			onFilter={debounceSearch}
			options={props.options as { value: string; label: string }[]}
			isLoading={props.isLoadingOptions}
			onChange={onChange}
			onClose={props.onClose}
			className={props.className}
			searchAsYouType
			error={props.hasError}
			initialFilterTerm={props.initialFilterTerm}
		/>
	)
}
function getDisplayValue(
	value: string | undefined,
	displayLabel: string | undefined,
	options: Option[]
): string | (() => string | undefined) | undefined {
	return value && !options?.some((opt) => opt.value === value) ? displayLabel : undefined
}
