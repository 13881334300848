import { guessFormattingCulture } from 'src/utils/locale/guessFormattingCulture'
import { IUserSettingsState } from './duck/userSettingsReducer'
import moment from 'moment-timezone'
export const loadState = (): { userSettings: IUserSettingsState } | undefined => {
	try {
		const serializedState: string | null = localStorage.getItem('state')
		const storedState: { userSettings: Partial<IUserSettingsState> } = (serializedState &&
			(JSON.parse(serializedState) as { userSettings: Partial<IUserSettingsState> })) || {
			userSettings: {},
		}
		const state: { userSettings: IUserSettingsState } = {
			...storedState,
			userSettings: {
				language: storedState.userSettings.language || 'en',
				formattingCulture: storedState.userSettings.formattingCulture || guessFormattingCulture(),
				timezone: storedState.userSettings.timezone || moment.tz.guess(),
			},
		}
		return state as { userSettings: IUserSettingsState }
	} catch (err) {
		return undefined
	}
}

export const saveState = (state: unknown) => {
	try {
		const serializedState = JSON.stringify(state)
		localStorage.setItem('state', serializedState)
	} catch {
		// ignore write errors
	}
}
