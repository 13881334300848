import moment from 'moment'
import { e_Weekday } from 'src/enums/e_Weekday'
import { IAvailability, IAvailabilityGroup } from 'src/interfaces/IAvailabilityWindow'
import { getMomentFromWeekDayTime, getWeekdayFromRelativeDay } from './dateTimeUtils'

const weekdayArray = Object.values(e_Weekday)

export const compareWeekdays = (day1: e_Weekday, day2: e_Weekday) => {
	const day1Value = weekdayArray.indexOf(day1)
	const day2Value = weekdayArray.indexOf(day2)

	return day1Value - day2Value
}
export const compareWeekDayTime = (avail1: IAvailability, avail2: IAvailability) => {
	const avail1FromValue = weekdayArray.indexOf(avail1.from.weekDayName) * 10000 + avail1.from.time
	const avail2FromValue = weekdayArray.indexOf(avail2.from.weekDayName) * 10000 + avail2.from.time

	let avail1ToValue, avail2ToValue

	if ('to' in avail1) {
		avail1ToValue = weekdayArray.indexOf(avail1.to.weekDayName) * 10000 + avail1.to.time
	}
	if ('to' in avail2) {
		avail2ToValue = weekdayArray.indexOf(avail2.to.weekDayName) * 10000 + avail2.to.time
	}

	if (avail1FromValue > avail2FromValue) {
		return 1
	} else if (avail2FromValue > avail1FromValue) {
		return -1
	} else if (avail1ToValue && avail2ToValue) {
		if (avail1ToValue > avail2ToValue) {
			return 1
		} else if (avail2ToValue > avail1ToValue) {
			return -1
		} else {
			return 0
		}
	} else {
		return 0
	}
}

export const compareWeekDayTimeForGroup = (avail1: IAvailabilityGroup, avail2: IAvailabilityGroup) => {
	const avail1FromValue = weekdayArray.indexOf(avail1.fromDays[0]) * 10000 + avail1.fromTime
	const avail2FromValue = weekdayArray.indexOf(avail2.fromDays[0]) * 10000 + avail2.fromTime

	if (avail1FromValue > avail2FromValue) {
		return 1
	} else if (avail2FromValue > avail1FromValue) {
		return -1
	} else {
		return 0
	}
}

export const isMomentInAvailabilityGroup = (time: moment.Moment, availabilityGroup: IAvailabilityGroup) => {
	let isInAvailabilityWindow = false
	availabilityGroup.fromDays.forEach((fromDay) => {
		const windowStart = getMomentFromWeekDayTime({
			weekDayName: fromDay,
			time: availabilityGroup.fromTime,
			timezone: availabilityGroup.timezone,
		})

		const windowEnd = getMomentFromWeekDayTime({
			weekDayName: getWeekdayFromRelativeDay(fromDay, availabilityGroup.toDay),
			time: availabilityGroup.toTime,
			timezone: availabilityGroup.timezone,
		})

		if (time.isBetween(windowStart, windowEnd, 'second', '[]')) {
			isInAvailabilityWindow = true
		}
	})
	return isInAvailabilityWindow
}

export const isMomentInAvailabilityWindow = (time: moment.Moment, availabilityWindows: IAvailability[]) => {
	let isInAvailabilityWindow = false
	availabilityWindows.forEach((availabilityWindow) => {
		const windowStart = getMomentFromWeekDayTime(availabilityWindow.from)

		const windowEnd = getMomentFromWeekDayTime(availabilityWindow.to)

		if (time.isBetween(windowStart, windowEnd, 'minute', '()')) {
			isInAvailabilityWindow = true
		}
	})
	return isInAvailabilityWindow
}
