export enum e_DataType {
	binary = 'binary',
	bool = 'bool',
	date = 'date',
	dateTime = 'dateTime',
	float = 'float',
	guid = 'guid',
	int = 'int',
	string = 'string',
	object = 'object',
	'object[]' = 'object[]',
}
