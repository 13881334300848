const isValidUrl = (urlString: string) => {
	const protocols = ['http:', 'https:']
	try {
		const url = new URL(urlString)
		return protocols.includes(url.protocol) && urlString.includes('.') && !urlString.endsWith('.')
	} catch (err) {
		return false
	}
}

export { isValidUrl }
