import React from 'react'
import { HeaderControl } from './HeaderControl'
import type { ITableFieldDescription } from '../types/IDataPropertyDescription'
import type { ISortData } from '../types/ISortData'
import { createStyle } from '../../../theming'
import type { IDataWithIdentifier } from '../types/IDataWithIdentifier'
import { MasterDetailAgTableView } from './MasterDetailAgTableView'
import type { IActionEntry } from '../types/IActionDescription'
import type { IMasterViewColumnItemDescription } from '../types/IMasterViewColumnItemDescription'
import type {
	FormatDataValueEventHandler,
	GetFormattingStylesEventHandler,
	GetIconValueEventHandler,
} from '../types/MasterDetailEventHandlers'

const classes = createStyle({
	masterview: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		minHeight: 0,
	},
})

interface IMasterViewProps {
	id: string
	title: string
	items: IDataWithIdentifier[]

	selectedIds: string[]
	setSelectedIds: (ids: string[]) => void

	activeId: string | undefined
	setActiveId: (id: string | undefined) => void
	columnDescriptions: ITableFieldDescription[]
	columnGroups?: IMasterViewColumnItemDescription[]

	sortedColumns?: ISortData[]
	sortColumns?: (sortedColumns: ISortData[]) => void

	filterText?: string
	setFilterText?: (filterText: string) => void

	isMultiSelect?: boolean
	setIsMultiSelect?: (multiSelect: boolean) => void

	itemActions?: IActionEntry[]
	selectedItemsActions?: IActionEntry[]

	handleItemAction?: (actionId: string, itemId: string) => void
	handleSelectedItemsAction?: (actionId: string, itemIds: string[]) => void
	globalActions?: IActionEntry[]
	handleGlobalAction?: (actionId: string) => void

	getFormattingStyles?: GetFormattingStylesEventHandler
	formatDataValue?: FormatDataValueEventHandler

	getIconValue?: GetIconValueEventHandler
	useZebraStripes?: boolean
	useCompactView?: boolean
	enableItemFormatting?: boolean
	emptyListMessage?: string
}

export const MasterView = React.forwardRef((props: IMasterViewProps, forwardedRef: React.Ref<HTMLDivElement>) => {
	return (
		<div className={classes.masterview} ref={forwardedRef}>
			<HeaderControl
				title={props.title}
				filterText={props.filterText}
				setFilterText={props.setFilterText}
				columns={props.columnDescriptions}
				sortedColumns={props.sortedColumns}
				sortColumns={props.sortColumns}
				isMultiSelect={props.isMultiSelect}
				setIsMultiSelect={props.setIsMultiSelect}
				count={props.items.length}
				selectedIds={props.selectedIds}
				multiItemActions={props.selectedItemsActions}
				generalActions={props.globalActions}
				handleGeneralAction={props.handleGlobalAction}
				handleMultiItemAction={props.handleSelectedItemsAction}
			/>
			<MasterDetailAgTableView
				id={props.id}
				items={props.items}
				columnDescriptions={props.columnDescriptions}
				columnGroups={props.columnGroups}
				sortedColumns={props.sortedColumns}
				sortColumns={props.sortColumns}
				activeId={props.activeId}
				setActiveId={props.setActiveId}
				selectedIds={props.selectedIds}
				setSelectedIds={props.setSelectedIds}
				isMultiSelect={props.isMultiSelect}
				itemActions={props.itemActions}
				handleItemAction={props.handleItemAction}
				getFormattingStyles={props.getFormattingStyles}
				formatDataValue={props.formatDataValue}
				getIconValue={props.getIconValue}
				useZebraStripes={props.useZebraStripes}
				useCompactView={props.useCompactView}
				enableItemFormatting={props.enableItemFormatting}
				emptyListMessage={props.emptyListMessage}
			/>
		</div>
	)
})

MasterView.displayName = 'MasterView'
