import { authenticationTypes } from './authenticationTypes'
import { IAuthenticationActions } from './IAuthenticationActions'
import { Reducer } from '@reduxjs/toolkit'

export interface IAuthenticationState {
	signedIn: boolean
}

const initialState: IAuthenticationState = {
	signedIn: true,
}

export const authenticationReducer: Reducer<IAuthenticationState, IAuthenticationActions> = (
	state = initialState,
	action: IAuthenticationActions
) => {
	switch (action.type) {
		case authenticationTypes.SET_SIGNED_IN: {
			return {
				...state,
				signedIn: action.signedIn,
			}
		}

		default:
			return state
	}
}
