import { type IActionEntry, e_actionType } from '../../MasterDetail/types/IActionDescription'
import type { IBaseActions } from '../types/detailConfigTypes'

const editItemAction: IActionEntry = {
	id: 'edit',
	type: e_actionType.action,
	displayName: 'Edit',
	iconClassName: 'Fluent-Edit',
	screenTip: 'Edit Item',
}
const applyItemAction: IActionEntry = {
	id: 'save',
	type: e_actionType.action,
	displayName: 'Save',
	iconClassName: 'Basic-Save',
	screenTip: 'Save Changes',
}
const discardItemAction: IActionEntry = {
	id: 'discard',
	type: e_actionType.action,
	displayName: 'Discard',
	iconClassName: 'Fluent-Cancel',
	screenTip: 'Discard Changes',
}
const deleteItemAction: IActionEntry = {
	id: 'delete',
	type: e_actionType.action,
	displayName: 'Delete',
	iconClassName: 'Basic-Trash',
	screenTip: 'Delete Item',
}

const constructConfirmationActions = (baseActions: IBaseActions) => {
	const onConfirmDelete = () => {
		baseActions.discardLocalChanges()
		baseActions.showMasterView()
	}
	const onConfirmEdit = () => {
		baseActions.discardLocalChanges()
		baseActions.showViewMode()
	}
	const onConfirmCreate = () => {
		baseActions.discardLocalChanges()
		baseActions.showMasterView() // Can (Should?) be changed to show the new object, but this requires the object to be available in the externally provided dataset
	}

	return { onConfirmDelete, onConfirmEdit, onConfirmCreate }
}

export const constructItemActionConfigs = (baseActions: IBaseActions) => {
	const { onConfirmDelete, onConfirmEdit, onConfirmCreate } = constructConfirmationActions(baseActions)

	const handleViewItemAction = (actionId: string, itemId: string) => {
		if (actionId === 'edit') {
			baseActions.showDetailView(itemId)
			baseActions.showEditMode(itemId)
		}
		if (actionId === 'delete') {
			baseActions.commitItemDeletion(itemId, onConfirmDelete, () => {})
		}
	}
	const viewModeItemActions = { entries: [editItemAction, deleteItemAction], handle: handleViewItemAction }

	const handleEditItemAction = (actionId: string, itemId: string) => {
		if (actionId === 'discard') {
			baseActions.discardLocalChanges()
			baseActions.showViewMode()
		}
		if (actionId === 'save') {
			baseActions.commitItemUpdate(itemId, onConfirmEdit, () => {})
		}
		if (actionId === 'delete') {
			baseActions.commitItemDeletion(itemId, onConfirmDelete, () => {})
		}
	}
	const editModeItemActions = {
		entries: [applyItemAction, discardItemAction, deleteItemAction],
		handle: handleEditItemAction,
	}

	const handleCreateItemAction = (actionId: string, itemId: string) => {
		if (actionId === 'save') {
			baseActions.commitItemCreation(itemId, onConfirmCreate, () => {})
		}
		if (actionId === 'discard') {
			baseActions.discardLocalChanges()
			baseActions.showMasterView()
		}
	}

	const createModeItemActions = {
		entries: [applyItemAction, discardItemAction],
		handle: handleCreateItemAction,
	}

	return { viewModeItemActions, editModeItemActions, createModeItemActions }
}

const newItemAction: IActionEntry = {
	id: 'new',
	type: e_actionType.action,
	displayName: 'New',
	iconClassName: 'Basic-Add',
	screenTip: 'New Item',
}

export const constructGeneralActionConfig = (baseActions: IBaseActions, sendFeedback?: (message: string) => void) => {
	const handleViewGeneralAction = (actionId: string) => {
		if (actionId === 'new') {
			baseActions.showCreateMode()
		}
	}
	const viewModeGeneralActions = {
		entries: [newItemAction],
		handle: handleViewGeneralAction,
	}

	const handleEditGeneralAction = (actionId: string) => {
		if (actionId === 'new') {
			sendFeedback?.('You must finish editing before drafting a new object')
		}
	}
	const editModeGeneralActions = {
		entries: [newItemAction],
		handle: handleEditGeneralAction,
	}
	const handleCreateGeneralAction = (actionId: string) => {
		if (actionId === 'new') {
			sendFeedback?.('You can only draft one object at a time')
		}
	}
	const createModeGeneralActions = {
		entries: [newItemAction],
		handle: handleCreateGeneralAction,
	}

	return { viewModeGeneralActions, editModeGeneralActions, createModeGeneralActions }
}
