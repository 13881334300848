import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../../../../theming'
import { ColumnHeaderInnerContent } from './ColumnHeaderContentInner'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils'
import { SortArrow } from './SortArrow'

interface IProps {
	isAutoGroup?: boolean
	textAlignment: 'right' | 'left' | 'center'
	onClick?: (e: React.MouseEvent | React.TouchEvent | KeyboardEvent) => void
	displayName: string
	cellStyle?: React.CSSProperties
	hideLabel?: boolean
	screenTip?: string
	sorting?: 'asc' | 'desc'
	iconName?: string
	iconPlacement?: 'before' | 'after'
}

const classes = createStyle({
	columnHeader: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		flexGrow: 1,
		overflow: 'hidden',
	},
	columnHeaderAuto: { alignItems: 'end', margin: '0 0 8px 28px' },
	textAlignCenter: { justifyContent: 'center' },
	textAlignRight: { justifyContent: 'flex-end' },
})

export const ColumnHeaderContent = (props: IProps) => {
	const textAlignmentClassName = classNames(classes.columnHeader, props.isAutoGroup && classes.columnHeaderAuto, {
		[classes.textAlignCenter]: props.textAlignment === 'center',
		[classes.textAlignRight]: props.textAlignment === 'right',
	})
	return (
		<div
			className={textAlignmentClassName}
			style={props.cellStyle}
			title={props.screenTip || props.displayName}
			onClick={props.onClick}
			onTouchEnd={props.onClick}
		>
			{props.textAlignment === 'right' && <SortArrow sorting={props.sorting} />}
			<ColumnHeaderInnerContent
				displayName={props.displayName}
				hideLabel={!!props.hideLabel}
				iconName={props.iconName as IIconNames}
				iconPlacement={props.iconPlacement}
			/>

			{props.textAlignment !== 'right' && <SortArrow sorting={props.sorting} />}
		</div>
	)
}
