import type { MenuItem, MenuItemAction } from '../Menu'

export enum DropdownOptionType {
	divider = 'divider',
	header = 'header',
	item = 'item',
	custom = 'custom',
}

export type IDropdownDivider = {
	type: DropdownOptionType.divider
}

export type IDropdownHeader = {
	type: DropdownOptionType.header
	label: string
	iconClassName?: string
}

export type IDropdownItem<T> = {
	type?: DropdownOptionType.item
	label: string
	value: T
	disabled?: boolean
	iconClassName?: string
	iconColor?: string
	screenTip?: string
}

export type IDropdownCustom<T> = {
	type: DropdownOptionType.custom
	label: string
	value: T
	render: (label?: string) => React.ReactNode
}

export type IDropdownOption<T> = IDropdownDivider | IDropdownHeader | IDropdownItem<T> | IDropdownCustom<T>

// Utility type to get 'value' type from list of DropdownOptions
// Usage: type T = DropdownValueExtract<typeof optionList>
export type DropdownValueExtract<T> = T extends any[] ? NonNullable<T[0]['value']> : never

// Params directly used in dropdown list menu
interface IDropdownListMenuCommandParams {
	stayOpenOnClick?: boolean
}
export type DropdownListMenuItem = MenuItem & IDropdownListMenuCommandParams

// Params that are abstracted away in dropdown list before being sent to dropdown list menu
export interface IDropdownListCommandParams<T> {
	visibleItems: T[]
}

interface IDropdownListCommand<T> extends MenuItemAction, IDropdownListMenuCommandParams {
	onAdvancedClick: (e: React.MouseEvent | React.KeyboardEvent, params: IDropdownListCommandParams<T>) => void
}

export type DropdownMenuItem<T> = DropdownListMenuItem | IDropdownListCommand<T>
