export enum e_ClusterDiagramLinkType {
	mutualUniversalSelection = 'MUTUAL_UNIVERSAL_SELECTION',
	universalIngressSpecificEgress = 'UNIVERSAL_INGRESS_SPECIFIC_EGRESS',
	universalEgressSpecificIngress = 'UNIVERSAL_EGRESS_SPECIFIC_INGRESS',
	mutualSpecificSelection = 'MUTUAL_SPECIFIC_SELECTION',
	universalIngressNoEgress = 'UNIVERSAL_INGRESS_NO_EGRESS',
	universalEgressNoIngress = 'UNIVERSAL_EGRESS_NO_INGRESS',
	specificIngressNoEgress = 'SPECIFIC_INGRESS_NO_EGRESS',
	specificEgressNoIngress = 'SPECIFIC_EGRESS_NO_INGRESS',
	mutualNoSelection = 'MUTUAL_NO_SELECTION',
	toNameSpace = 'TO_NAMESPACE',
	fromNameSpace = 'FROM_NAMESPACE',
}

const ClusterDiagramLinkTypeMapper = new Map<number, e_ClusterDiagramLinkType>([
	[10, e_ClusterDiagramLinkType.mutualUniversalSelection],
	[20, e_ClusterDiagramLinkType.universalIngressSpecificEgress],
	[30, e_ClusterDiagramLinkType.universalEgressSpecificIngress],
	[40, e_ClusterDiagramLinkType.mutualSpecificSelection],
	[50, e_ClusterDiagramLinkType.universalIngressNoEgress],
	[60, e_ClusterDiagramLinkType.universalEgressNoIngress],
	[70, e_ClusterDiagramLinkType.specificIngressNoEgress],
	[80, e_ClusterDiagramLinkType.specificEgressNoIngress],
	[90, e_ClusterDiagramLinkType.mutualNoSelection],
	[100, e_ClusterDiagramLinkType.toNameSpace],
	[110, e_ClusterDiagramLinkType.fromNameSpace],
])

export const getClusterDiagramLinkType = (linkTypeValue: number) => ClusterDiagramLinkTypeMapper.get(linkTypeValue)
