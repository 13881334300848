import React, { useEffect, useRef, useState } from 'react'
import { createStyle } from '../../../../theming'
import type { CodeNode } from '@lexical/code'
import { $isCodeNode } from '@lexical/code'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import type { LexicalEditor } from 'lexical'
import { $getNearestNodeFromDOMNode } from 'lexical'
import { CopyButton, buttonClasses } from './CopyButton'
import type { MenuItemAction } from '../../../Menu'
import { DropdownButton } from '../../../Button'
import { e_MenuItemType } from '../../../Menu'
import { topWindow } from '../../../utils/topWindow'
import { useDebounce } from '../../../utils/useDebounce'
import clsx from 'clsx'
import { getLanguageFriendlyName, getLanguageItems } from './getLanguageItems'

const classes = createStyle({
	codeActionMenu: {
		position: 'absolute',
		display: 'flex',
		gap: 6,
		alignItems: 'center',
		padding: 6,
		fontSize: 10,
		height: 36,
	},
	language: {
		minWidth: 91,
		'& i': {
			paddingRight: 0,
		},
	},
	friendlyLanguage: {
		marginRight: 12,
	},
})

interface IProps {
	anchorElement: HTMLElement
}

const GAP_RIGHT = 4

export default function CodeActionMenuPlugin(props: IProps): JSX.Element | null {
	const [editor] = useLexicalComposerContext()
	const [position, setPosition] = useState({ top: '0px', right: '0px' })
	const [isVisible, setIsVisible] = useState(false)
	const [lang, setLang] = useState('')
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const domRef = useRef<HTMLElement>()
	const [lexicalCodeNode, setLexicalCodeNode] = useState<CodeNode>()
	const hasTriggeredSetVisible = useRef(false)

	const handleMousemove = useDebounce(
		(e: MouseEvent): void => {
			function hideActionMenu() {
				if (isMenuOpen) {
					return
				}
				setIsVisible(false)
			}
			if (hasTriggeredSetVisible.current) {
				return
			}
			const target = e.target
			if (!target || !(target instanceof HTMLElement)) {
				hideActionMenu()
				return
			}
			const codeDOMNode = target.closest<HTMLElement>('code.RichTextTheme__code')
			if (!codeDOMNode) {
				const actionMenuNode = target.closest<HTMLElement>('div.code-action-menu-container')
				if (!actionMenuNode) {
					hideActionMenu()
				}
				return
			}
			domRef.current = codeDOMNode
			if (!isInside(e, codeDOMNode)) {
				setIsVisible(false)
				return
			}
			const { codeNode, language } = getCodeNode(editor, codeDOMNode)

			setLexicalCodeNode(codeNode ?? undefined)

			if (!codeNode) {
				return
			}
			const { right, y } = codeDOMNode.getBoundingClientRect()
			const { right: anchorRight, y: anchorY } = props.anchorElement.getBoundingClientRect()
			hasTriggeredSetVisible.current = true
			setTimeout(() => {
				hasTriggeredSetVisible.current = false
			}, 200)
			setLang(language ?? '')
			setPosition({ top: `${y - anchorY}px`, right: `${anchorRight - right + GAP_RIGHT}px` })
			setIsVisible(true)
		},
		50,
		1000
	)

	useEffect(() => {
		topWindow.document.addEventListener('mousemove', handleMousemove)
		handleMousemove.cancel()
		return () => topWindow.document.removeEventListener('mousemove', handleMousemove)
	}, [editor, handleMousemove, isMenuOpen, props.anchorElement])

	function getCodeDOMNode() {
		return domRef.current
	}

	const friendlyLanguage = getLanguageFriendlyName(lang)

	const languageMenuItems = getLanguageMenuItems(editor, function (language: string) {
		if (!lexicalCodeNode) {
			return
		}
		lexicalCodeNode.setLanguage(language)
		setLang(language)
	})

	return isVisible ? (
		<div className={`${classes.codeActionMenu} code-action-menu-container`} style={{ ...position }}>
			<DropdownButton
				className={clsx(classes.language, buttonClasses.button)}
				variant="inline"
				menuItems={languageMenuItems}
				menuButtonClick={() => {
					setIsMenuOpen(true)
				}}
				onMenuClose={() => {
					setIsMenuOpen(false)
				}}
				dropdownIconSize="extraSmall"
			>
				<span className={classes.friendlyLanguage}>{friendlyLanguage}</span>
			</DropdownButton>
			<CopyButton editor={editor} getCodeDOMNode={getCodeDOMNode} />
		</div>
	) : null
}

function getLanguageMenuItems(editor: LexicalEditor, onLanguageChanged: (language: string) => void) {
	return getLanguageItems().map((e): MenuItemAction & { value: string } => {
		return {
			type: e_MenuItemType.action,
			onClick: () => {
				editor.update(() => {
					onLanguageChanged(e.value)
				})
			},
			...e,
		}
	})
}

function getCodeNode(
	editor: LexicalEditor,
	codeDOMNode: HTMLElement
): { codeNode: CodeNode | null; language: string | null | undefined } {
	let codeNode: CodeNode | null = null
	let language: string | null | undefined = null
	editor.update(() => {
		const node = $getNearestNodeFromDOMNode(codeDOMNode)
		if ($isCodeNode(node)) {
			codeNode = node
			language = codeNode.getLanguage()
		}
	})
	return { codeNode, language }
}

function isInside(event: MouseEvent, codeDOMNode: HTMLElement) {
	const { clientX, clientY } = event
	const { left, top, right, bottom } = codeDOMNode.getBoundingClientRect()
	return clientX >= left && clientX <= right && clientY >= top && clientY <= bottom
}
