import { useMemo, useReducer, useRef } from 'react'
import xor from 'lodash/xor'
import type { IFlatListNodes } from './useSelectionFocusActivationModel'
const findFirstSelected = (selectedIds: string[], nodeList: IFlatListNodes) => {
	for (let i = 0; i < nodeList.length; i++) {
		const node = nodeList[i]
		if (node.id && selectedIds.includes(node.id)) {
			return node.id
		}
	}
}
export const useControlledSelection = (
	selectedIdsProp: string[] | undefined,
	onSelectionChange: ((ids: string[]) => void) | undefined,
	selectedIdBeforeShiftPressedRef: React.MutableRefObject<string | undefined>,
	setScrollToFirstSelected: (id: boolean) => void,
	nodeList: IFlatListNodes,
	preventEmptySelection: boolean
) => {
	const selectedIdsRef = useRef(selectedIdsProp || [])
	const firstSelectedIdRef = useRef<string | undefined>(findFirstSelected(selectedIdsRef.current, nodeList))

	const [, rerender] = useReducer((x: number) => x + 1, 0)
	const setSelection = (ids: string[], selectedIdBeforeShiftPressed?: string) => {
		if (preventEmptySelection && ids.length === 0) {
			return
		}
		selectedIdsRef.current = ids
		onSelectionChange?.(ids)
		firstSelectedIdRef.current = findFirstSelected(ids, nodeList)

		if (selectedIdBeforeShiftPressed !== undefined) {
			selectedIdBeforeShiftPressedRef.current = selectedIdBeforeShiftPressed
		}
		// To update the list visually after the selection change
		rerender()
	}

	// useMemo is used instead of useEffect in order for the ref to be updated before rendering
	useMemo(() => {
		const currentSelection = selectedIdsRef.current
		const newSelection = selectedIdsProp || []
		selectedIdsRef.current = newSelection

		firstSelectedIdRef.current = findFirstSelected(newSelection, nodeList)
		const selectionHasChanged = xor(currentSelection, newSelection).length !== 0

		if (selectionHasChanged) {
			setScrollToFirstSelected(true)
		}
	}, [selectedIdsProp, setScrollToFirstSelected, firstSelectedIdRef, nodeList])

	return {
		selectedIdsRef,
		setSelection,
		firstSelectedIdRef,
	}
}
